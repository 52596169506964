import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as Responses from './Saga/ResponseList';
import '../../Css/Grid.css';
import './Css/Line.css';
import LineSchedule from "./ResponseLineSendScheduleComponent";

import { Button } from 'reactstrap';

import FormControl from '@mui/material/FormControl';

import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css'


import IconButton from '@mui/material/IconButton';

import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MaterialUiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';








class ResponseLineSendComponent extends Component {
  constructor(props) {
    super(props);

    var data = new Date()
    data.setMonth(data.getMonth() - 1)
    this.state = {
      // height: window.innerHeight,
      refresh: false,
      text: "",
      modal_del: false,

      anchorEl: null,
      disp_detail: false,

      select_section_id: this.props.user_details.amSectionId,
    };

    // this.togglemail = this.togglemail.bind(this);
    this.refresh = this.refresh.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);

    this.props.get_rent_line_templates_list(this.state.select_section_id)

    this.props.change_rent_line_template({ id: '', title: '', section_id: this.props.user_details.amSectionId, is_deleted: 0 })
    this.props.change_rent_line_template_detail([])
    // alert(this.props.mail_id)
  }

  handleSelect(id) {
    let rent_line_schedule = this.props.rent_line_schedule
    rent_line_schedule.rent_line_template_id = id
    rent_line_schedule.schedule_at = moment().add(30, 'm').format("YYYY-MM-DD HH:mm:00")

    this.props.get_rent_line_schedule_template_detail(id)

    this.props.change_rent_line_schedule(rent_line_schedule)
    this.props.change_line_schedule_open(true)

    this.refresh()
  }

  refresh() {
    this.setState({
      refresh: !this.state.refresh
    });
  }



  handleUpdate(id, delflg) {

    let rent_line_template = this.props.rent_line_template
    rent_line_template["is_deleted"] = delflg
    this.props.change_rent_line_template(rent_line_template)

    if (id) {
      this.props.update_rent_line_template()

    } else {
      this.props.insert_rent_line_template()

    }

    this.refresh()

  }


  handleClick = (event) => {
    // alert(event.currentTarget)
    this.setState({
      anchorEl: event.currentTarget,
      disp_detail: true,
    });
    // setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      disp_detail: false,
    });
  };

  onClickClose() {
    this.props.change_line_template_open(false)

    let formData = this.props.formData
    formData.line_send = false
    this.props.change_formData(formData)

    this.props.search_response_list2()

    this.refresh()
  }

  handleChangeSection(e) {

    this.props.get_rent_line_templates_list(e.target.value)

    this.setState({ select_section_id: e.target.value });

  }


  render() {

    let tenpo = [{ value: 0, label: '全店' }]
    this.props.section_select.filter((a) => a.value > 0 && (Number(a.value) <= 6 || Number(a.value) === 10 || Number(a.value) === 17 || Number(a.value) === 26 || Number(a.value) === 37 || Number(a.value) === 47 || Number(a.value) === 55)).map(function (item, i) {
      tenpo.push({ value: item.value, label: item.label })
      return item
    })


    return (
      <div>
        <div>
          <div style={{ padding: 3 }}>
            {/* <i className="fas fa-times" onClick={() => this.onClickClose()} style={{ float: "right", margin: 12 }} ></i> */}
            <IconButton
              aria-label="close"
              style={{ position: 'absolute', right: 1, top: 1 }}
              onClick={() => this.onClickClose()}
              size="large">
              <CloseIcon />
            </IconButton>

            {/* <Select
                style={{zIndex:4000}}
                value={this.state.select_section_id}
                onChange={this.handleChangeSection}
                label="店舗"
                options={tenpo}
                >

                </Select> */}

            <FormControl variant="outlined" style={{ width: 200 }} fullWidth>
              <InputLabel id="sectionId-label">店舗</InputLabel>
              <MaterialUiSelect
                labelId="sectionId-label"
                id="section_id"
                name="section_id"
                value={this.state.select_section_id}
                onChange={this.handleChangeSection}
                // disabled={this.props.formData.id}
                label="section_id"
                margin='dense'
              >
                <MenuItem key={-1} value={0}>全店</MenuItem>
                {this.props.section_select.filter((a) => a.value > 0 && a.value <= 6 || a.value == 10 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47 || a.value == 55).map(function (item, i) {
                  return (
                    <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                  );

                })}

              </MaterialUiSelect>
            </FormControl>


            <Table rowHeight={40} style={{ zIndex: 3000 }} rowsCount={this.props.rent_line_templates_list.length} headerHeight={40}
              width={380}
              height={450}
              // height={100}
              // onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
            >

              <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell style={{ padding: 0 }} {...props}>
                <Button style={{ marginTop: 0 }} onClick={() => this.handleSelect(this.props.rent_line_templates_list[rowIndex].id)} size="sm" variant="contained" color="primary">選択</Button>
              </Cell>)} width={70} />

              <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell>
                {(() => {
                  if (this.props.rent_line_templates_list[rowIndex].section_id === 1) {
                    return <div style={{}}><span style={{ fontSize: "16px" }}><span className="badge badge-success">番</span></span></div >
                  } else if (this.props.rent_line_templates_list[rowIndex].section_id === 2) {
                    return <div style={{}}><span style={{ fontSize: "16px" }}><span className="badge badge-warning">レ</span></span></div >
                  } else if (this.props.rent_line_templates_list[rowIndex].section_id === 3) {
                    return <div style={{}}><span style={{ fontSize: "16px" }}><span className="badge badge-danger">駅</span></span></div >
                  } else if (this.props.rent_line_templates_list[rowIndex].section_id === 4) {
                    return <div style={{}}><span style={{ fontSize: "16px" }}><span className="badge badge-dark">東</span></span></div >
                  } else if (this.props.rent_line_templates_list[rowIndex].section_id === 6) {
                    return <div style={{}}><span style={{ fontSize: "16px" }}><span className="badge badge-light">公</span></span></div >
                  } else if (this.props.rent_line_templates_list[rowIndex].section_id === 17) {
                    return <div style={{}}><span style={{ fontSize: "16px" }}><span className="badge badge-info">サ</span></span></div >
                  } else if (this.props.rent_line_templates_list[rowIndex].section_id === 47) {
                    return <div style={{}}><span style={{ fontSize: "16px" }}><span className="badge badge-secondary">通</span></span></div >
                  } else {
                    return <div style={{}}><span style={{ fontSize: "16px" }}><span className="badge badge-primary">
                      {this.props.rent_line_templates_list[rowIndex].section_id > 0 && this.props.shops.length > 0 ? this.props.shops.filter((a) => a.tenpoNo == this.props.rent_line_templates_list[rowIndex].section_id)[0].tenpoName.charAt(0) : ""}
                    </span></span></div >
                  }
                })()}

              </Cell>)} width={40} />
              <Column isResizable={true} columnKey="column4" header={<Cell>タイトル</Cell>} cell={({ rowIndex, ...props }) => (
                <Cell>
                  {this.props.rent_line_templates_list[rowIndex].title}
                </Cell>)} width={280} />
            </Table>

            {this.props.line_schedule_open ? <div id="LineSchedule"><LineSchedule /> </div> : ""}

            {/* <Button aria-describedby={id} variant="contained" onClick onMouseOver={this.handleClick}> */}
            {/* {this.state.disp_detail}
                {this.state.anchorEl} */}
            {/* <Button aria-describedby={"simple-popover"} variant="contained" onClick={(e)=>this.handleClick(e)}>
                  Open Popover
                </Button>
                <Popover
                  id={"simple-popover"}
                  open={this.state.disp_detail}
                  anchorEl={this.state.anchorEl}
                  onClose={()=>this.handleClose()}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div style={{zIndex:4000}}>The content of the Popover.</div>
                </Popover> */}


          </div>

        </div >


      </div>
    );
  }


}



const mapStateToProps = (state) => {
  return {
    response_search_list: state.responselist.response_search_list,

    line_schedule_detail: state.responselist.line_schedule_detail,

    rent_line_templates_list: state.responselist.rent_line_templates_list,
    rent_line_template: state.responselist.rent_line_template,
    rent_line_template_detail: state.responselist.rent_line_template_detail,
    line_schedule_open: state.responselist.line_schedule_open,
    rent_line_schedule: state.responselist.rent_line_schedule,

    user_details: state.masterReducer.user_details,
    formData: state.responselist.formData,
    shops: state.masterReducer.shops,
    section_select: state.masterReducer.section_select,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_line_schedule_detail(state) {
      dispatch(Responses.change_line_schedule_detail(state))
    },

    get_rent_line_templates_list(state) {
      dispatch(Responses.get_rent_line_templates_list(state))
    },
    get_rent_line_schedule_template_detail(state) {
      dispatch(Responses.get_rent_line_schedule_template_detail(state))
    },
    change_rent_line_template_detail(state) {
      dispatch(Responses.change_rent_line_template_detail(state))
    },
    change_rent_line_template(state) {
      dispatch(Responses.change_rent_line_template(state))
    },
    insert_rent_line_template(state) {
      dispatch(Responses.insert_rent_line_template(state))
    },
    update_rent_line_template(state) {
      dispatch(Responses.update_rent_line_template(state))
    },
    change_formData(state) {
      dispatch(Responses.change_formData(state))
    },
    search_response_list2(state) {
      dispatch(Responses.search_response_list2(state))
    },


    change_line_template_open(state) {
      dispatch(Responses.change_line_template_open(state))
    },
    change_line_schedule_open(state) {
      dispatch(Responses.change_line_schedule_open(state))
    },
    change_rent_line_schedule(state) {
      dispatch(Responses.change_rent_line_schedule(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseLineSendComponent);