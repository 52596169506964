import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as MapSearch from './Saga/MapSearch';

import '../../Css/GridRooms.css';
import moment from 'moment';

import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function mise(row){

  // 管理とサブのみ
  if(row.kanri_kubun_no == 1 || row.kanri_kubun_no == 4){
  }else{
    return false
  }

  // 入居可能状況が募集停止、家主使用、未補修、入居中以外
  if(row.kuushitsu_joukyou_no == 6 || row.kuushitsu_joukyou_no == 100 || row.kuushitsu_joukyou_no == 101 || row.kuushitsu_joukyou_no == 4){
    return false
  }

  // 鍵番設置日が今日より未来なら見れない。
  if(row.kagiban_setti_date){
    // if(moment(row.io_keiyaku_date) > moment(row.kagiban_setti_date)){
      if(moment(row.kagiban_setti_date).format("YYYYMMDD") >= moment().format("YYYYMMDD")){
        return false
      }
  // } else {
    
  // } 
  }


  // if(row.room_name == "B101"){
  //   alert(row.io_keiyaku_date)
  //   alert(row.kaiyaku_date)
  // }
  

  let keiyakumae = false
  // 契約日がない場合OK
  // 
  if(row.io_keiyaku_date){
    if(moment(row.io_keiyaku_date).format("YYYYMMDD") < moment(row.kaiyaku_date).format("YYYYMMDD")) {
      keiyakumae = true
    }else{
      // 次の契約が決まってて、契約日は今日より未来
      if (moment(row.io_keiyaku_date).format("YYYYMMDD") > moment().format("YYYYMMDD")) {
        keiyakumae = true
      }
    }
  }else{
    keiyakumae = true
  }
  if (!keiyakumae) return false
  
  // 鍵番撤去日が入ってて
  if(row.kagiban_tekkyo_date){
    if(row.kaiyaku_date) {
      if(moment(row.kaiyaku_date).format("YYYYMMDD") < moment(row.kagiban_tekkyo_date).format("YYYYMMDD")){
      // 撤去日が解約日より未来で、撤去日が今日より過去
        if(moment(row.kagiban_tekkyo_date).format("YYYYMMDD") <= moment().format("YYYYMMDD")){
          return false
        }
      }
    }else{
      // 撤去日が今日より過去
      if(moment(row.kagiban_tekkyo_date).format("YYYYMMDD") <= moment().format("YYYYMMDD")){
        return false
      }
    }
  }
  


  let nyuukyo_kanou

  if(row.nyuukyo_kanou_year == "" || row.nyuukyo_kanou_year  == "-" || row.nyuukyo_kanou_month == "" || row.nyuukyo_kanou_month == "-" || row.nyuukyo_kanou_day == "" || row.nyuukyo_kanou_day == "-"){
    nyuukyo_kanou = ""
  }else{
    nyuukyo_kanou = row.nyuukyo_kanou_year+row.nyuukyo_kanou_month+row.nyuukyo_kanou_day.replace("上旬","08").replace("中旬","18").replace("下旬","28")
  }

  // alert(row.kaiyaku_date)
  // alert(row.room_name)
  // 解約日がない
  if(!row.kaiyaku_date){
    // 入居可能日が過ぎている。
    if(nyuukyo_kanou){
      if(moment(nyuukyo_kanou).format("YYYYMMDD") < moment().format("YYYYMMDD")){
        return true
      }
    }
  }else{
    // 完工日を過ぎている。
    if(row.completion_at && moment(row.completion_at).format("YYYYMMDD") < moment().format("YYYYMMDD")){
      return true
    }
    // 解約日を過ぎている。
    if(moment(row.kaiyaku_date).format("YYYYMMDD") < moment().format("YYYYMMDD")){
      return true
    }
    // 鍵番設置日を過ぎている。
    if(moment(row.kagiban_setti_date).format("YYYYMMDD") < moment().format("YYYYMMDD")){
      return true
    }
  }
  
  return false
  
}



function RoomName ({ column, props, rowIndex }){
  return <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    {props.map_room_details_all[rowIndex].room_name}[{props.map_room_details_all[rowIndex].madori_name}]({props.map_room_details_all[rowIndex].kanri_kubun_name})</Cell>;
}
const KuushitsuJoukyou = ({ column, props, rowIndex }) =>
  <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    {props.map_room_details_all[rowIndex].kuushitsu_joukyou_name}
    </Cell>;

const KaiyakuDate = ({ column, props, rowIndex }) =>
  <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    {props.map_room_details_all[rowIndex].kaiyaku_date ? moment(props.map_room_details_all[rowIndex].kaiyaku_date).format("YYYY/MM/DD") : ""}
    </Cell>;

const KagibanSettiDate = ({ column, props, rowIndex }) =>
  <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    {props.map_room_details_all[rowIndex].kagiban_setti_date ? moment(props.map_room_details_all[rowIndex].kagiban_setti_date).format("YYYY/MM/DD") : ""}
    </Cell>;

const HoshuuDate = ({ column, props, rowIndex }) =>
  <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    {props.map_room_details_all[rowIndex].hoshuu_date ? moment(props.map_room_details_all[rowIndex].hoshuu_date).format("YYYY/MM/DD") : ""}
    </Cell>;

const CompletionAt = ({ column, props, rowIndex }) =>
  <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    {props.map_room_details_all[rowIndex].completion_at ? 
      props.map_room_details_all[rowIndex].kaiyaku_date && moment(props.map_room_details_all[rowIndex].completion_at) < moment(props.map_room_details_all[rowIndex].kaiyaku_date) ? "" : moment(props.map_room_details_all[rowIndex].completion_at).format("YYYY/MM/DD") : ""}
    </Cell>;

const KagibanTekkyoDate = ({ column, props, rowIndex }) =>
  <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    {props.map_room_details_all[rowIndex].kagiban_tekkyo_date ? props.map_room_details_all[rowIndex].kaiyaku_date ? moment(props.map_room_details_all[rowIndex].kaiyaku_date).format("YYYY/MM/DD") < moment(props.map_room_details_all[rowIndex].kagiban_tekkyo_date).format("YYYY/MM/DD") ? moment(props.map_room_details_all[rowIndex].kagiban_tekkyo_date).format("YYYY/MM/DD") : "" : moment(props.map_room_details_all[rowIndex].kagiban_tekkyo_date).format("YYYY/MM/DD") : ""}
    </Cell>;

const IoKeiyakuDate = ({ column, props, rowIndex }) =>
  <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    {props.map_room_details_all[rowIndex].io_keiyaku_date ? props.map_room_details_all[rowIndex].kaiyaku_date && (moment(props.map_room_details_all[rowIndex].io_keiyaku_date).format("YYYY/MM/DD") < moment(props.map_room_details_all[rowIndex].kaiyaku_date).format("YYYY/MM/DD")) ? "" : moment(props.map_room_details_all[rowIndex].io_keiyaku_date).format("YYYY/MM/DD") : ""}
    </Cell>;

const TantouTenpo = ({ column, props, rowIndex }) =>
<Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    {props.map_room_details_all[rowIndex].io_keiyaku_date ? props.map_room_details_all[rowIndex].kaiyaku_date && (moment(props.map_room_details_all[rowIndex].io_keiyaku_date).format("YYYY/MM/DD") < moment(props.map_room_details_all[rowIndex].kaiyaku_date).format("YYYY/MM/DD")) ? "" : props.map_room_details_all[rowIndex].tenpo_name_short : ""}
    </Cell>;

const Detail = ({ column, props, rowIndex }) =>
  <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: mise(props.map_room_details_all[rowIndex]) ? "#fff396" : ""}} >
    </Cell>;


const columns =
[
  {key: 'room_name', name:'部屋名', width: 150, resizeable:true},
  {key: 'kuushitsu_joukyou_name', name:'空状況', width: 110, resizeable:true},
  {key: 'kaiyaku_date', name:'解約日', width: 110, resizeable:true},
  {key: 'kagiban_setti_date', name:'鍵番設置', width: 110, resizeable:true},
  {key: 'hoshuu_date', name:'完工予定', width: 110, resizeable:true},
  {key: 'completion_at', name:'完工日', width: 110, resizeable:true},
  {key: 'kagiban_tekkyo_date', name:'鍵番撤去', width: 110, resizeable:true},
  {key: 'io_keiyaku_date', name:'契約日', width: 110, resizeable:true},
  {key: 'tenpo_name_short', name:'担当店舗', width: 110, resizeable:true},
]

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}






class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: columns,
      reflesh: false,
      colSortDirs: {},
    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);

  }
  

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }

  _onSortChange(columnKey, sortDir) {

    let map_room_details_all = this.props.map_room_details_all;

    map_room_details_all.sort((indexA, indexB) => {
      // alert(indexA)

      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.props.change_map_room_details_all(map_room_details_all)
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  // }

  render() {
    //     // console.log(this.props.customerlists)
    //     // alert(this.props.customerlists+"sss")
    //     // const ss:any=[];
    return (
      <div>

        <Table
          className='showRoomList'
          rowHeight={28}
          rowsCount={this.props.map_room_details_all.length}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          isColumnResizing={false}
          touchScrollEnabled={true}
          width={window.innerWidth - 110}
          height={window.innerHeight - 250}
          headerHeight={20}>

          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
              cell={({ rowIndex, ...props }) => (

                value.key === "room_name" ? <RoomName column={value} props={this.props} rowIndex={rowIndex}></RoomName>
                 : value.key === "kuushitsu_joukyou_name" ? <KuushitsuJoukyou column={value} props={this.props} rowIndex={rowIndex}></KuushitsuJoukyou>
                 : value.key === "kaiyaku_date" ? <KaiyakuDate column={value} props={this.props} rowIndex={rowIndex}></KaiyakuDate>
                 : value.key === "kagiban_setti_date" ? <KagibanSettiDate column={value} props={this.props} rowIndex={rowIndex}></KagibanSettiDate>
                 : value.key === "kagiban_tekkyo_date" ? <KagibanTekkyoDate column={value} props={this.props} rowIndex={rowIndex}></KagibanTekkyoDate>
                 : value.key === "io_keiyaku_date" ? <IoKeiyakuDate column={value} props={this.props} rowIndex={rowIndex}></IoKeiyakuDate>
                 : value.key === "tenpo_name_short" ? <TantouTenpo column={value} props={this.props} rowIndex={rowIndex}></TantouTenpo>
                 : value.key === "hoshuu_date" ? <HoshuuDate column={value} props={this.props} rowIndex={rowIndex}></HoshuuDate>
                 : value.key === "completion_at" ? <CompletionAt column={value} props={this.props} rowIndex={rowIndex}></CompletionAt>
                  : <Detail column={value} props={this.props} rowIndex={rowIndex} contract_follow_editer={this.contract_follow_editer}></Detail>
              )}

              width={columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}

            />
          }, this)}

        </Table >

      </div>
    )
  }

}


// }

const mapStateToProps = (state) => {
  return {
    map_room_details_all: state.mapsearch.map_room_details_all,


  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_map_room_details_all(state) {
        dispatch(MapSearch.change_map_room_details_all(state))
    },


  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);