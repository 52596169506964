import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import '../../Css/Table.css';
import * as CustomerContacts from './Saga/CustomerContacts';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialUiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from 'moment';


// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (values.endResponseReason === "0") {
        errors.endResponseReason = "理由を選択してください。";
    }
    // alert(values.endResponseReason)
    if (values.endResponseReason === "3" && !values.endResponseRemarks) {
        errors.endResponseRemarks = "理由を入力してください。";
    }
    if (!values.endResponseDate) {
        errors.endResponseDate = "追客終了日を入力してください。";
    }
    if (!values.endResponseUserId) {
        errors.endResponseUserId = "担当を入力してください。";
    }
    // if (values.mail1) {
    //     if (!values.mail1.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
    //         errors.mail1 = "メールアドレスの形式が正しくありません。";
    //     }
    // }
    // if (values.mail2) {
    //     if (!values.mail2.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
    //         errors.mail2 = "メールアドレスの形式が正しくありません。";
    //     }
    // }

    // if (values.tel1) {
    //     if (!values.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel1.match(/^\d{7,13}$/)) {
    //         errors.tel1 = "電話番号の形式が正しくありません。";
    //     }
    // }

    // if (values.tel2) {
    //     if (!values.tel2.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel2.match(/^\d{7,13}$/)) {
    //         errors.tel2 = "電話番号の形式が正しくありません。";
    //     }
    // }

    // if (values.fax) {
    //     if (!values.fax.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.fax.match(/^\d{7,13}$/)) {
    //         errors.fax = "FAXの形式が正しくありません。";
    //     }
    // }

    if (values.post) {
        //if (!values.post.match(/^\d{3}-\d{4}$/)) {
        //    errors.post = "郵便番号の形式が正しくありません。";
        //}
    }
    return errors;
};

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, style, meta: { touched, error } }) => (
//     <div >
//         <input {...input} type={type} id={id} placeholder={placeholder} style={{ width: "80" }} className={touched && error ? 'alert-danger ' + className : className} />
//         {touched && error && <span className="text-danger">{error}</span>}
//     </div>
// );

export class RenderField extends React.Component {
    render() {
        const { className, type, input, disabled, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea className={className} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select className={className} {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input className={className} {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};

const ReduxFormDateRange = (props) => {


    console.log(props)
    return (

        <DatePicker

            selected={props.input.value !== "" ? new Date(props.input.value) : ""}
            //selected={moment(props.input.value, "YYYY-MM-DD").format("YYYY-MM-DD") || null}
            onChange={props.input.onChange}
            //showTimeSelect
            dateFormat="yyyy/MM/dd"
            className="form-control"
        />

    )
}


const RenderSelectInput = ({ input, options, name, id }) => (

    <Select
        {...input}
        id={id}
        name={name}
        options={options}
        value={input.value}
        onChange={(a) => input.onChange(a.value)}
        onBlur={(a) => input.onBlur(a.value)}
    // placeholder="店舗を選択"
    />


)


class ResponseEndComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reflash: false
            // setintend : this.props.contactstate.intend===0 || this.props.contactstate.intend===false?false:true
        };
        // this.changeIntend = this.changeIntend.bind(this)

        // var contactdetail = {
        //     rentResponseId: this.props.responsedetail.id,
        //     sectionId: this.props.responsedetail.sectionId,

        // }
        // this.props.contactstate.way= String(this.props.contactstate.way);
        // this.props.contactstate.intend= this.props.contactstate.intend===0 || this.props.contactstate.intend===false?false:true;

        // this.props.initialize({ endResponseUserId: this.props.user_id, endResponseDate: new Date(), rentResponseId: this.props.responsedetail.id, sectionId: this.props.responsedetail.sectionId, endResponseReason: this.props.responsedetail.endResponseReason === 0 ? 1 : this.props.responsedetail.endResponseReason, endResponseRemarks: this.props.responsedetail.endResponseRemarks })

        this.props.change_rent_customer_contacts_end_responses_form({ end_response_user_id: this.props.user_id, end_response_date: new Date(), rent_response_id: this.props.responsedetail.id, section_id: this.props.responsedetail.section_id, end_response_reason: this.props.responsedetail.end_response_reason === 0 ? 1 : this.props.responsedetail.end_response_reason, end_response_remarks: this.props.responsedetail.end_response_remarks ? this.props.responsedetail.end_response_remarks : "" })
        // this.props.initialize(contactdetail);//フォームに初期値を反映させる


        this.handleChangeDate = this.handleChangeDate.bind(this)
    }

    handleChange = (event) => {
        console.log(event)
        // alert(event.target.name)
        // alert(event.target.value)
        let rent_customer_contacts_end_responses_form = this.props.rent_customer_contacts_end_responses_form
        rent_customer_contacts_end_responses_form[event.target.name] = event.target.value;



        this.props.change_rent_customer_contacts_end_responses_form(rent_customer_contacts_end_responses_form);


        this.setState({ reflash: !this.state.reflash });

        this.props.end_form_change()
    }

    handleChangeDate(value) {

        let rent_customer_contacts_end_responses_form = this.props.rent_customer_contacts_end_responses_form
        rent_customer_contacts_end_responses_form.end_response_date = moment(value).format('YYYY/MM/DD')
        this.props.change_rent_customer_contacts_end_responses_form(rent_customer_contacts_end_responses_form);
    
    
        this.setState({
            refresh: !this.state.refresh    //再描画
        });

      }

    //   componentDidMount() {
    //     // This method is called when the component is first added to the document
    //       this.ensureDataFetched();

    //   }

    //   componentDidUpdate() {
    //     // This method is called when the route parameters change
    //     this.ensureDataFetched();
    //   }

    //   ensureDataFetched() {
    //       //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
    //       //console.log(startDateIndex)

    //       //this.props.requestWeatherForecasts(0);

    //     }


    // onChangeName(property, event) {

    //     if (property === "tatemonoKana") {
    //         this.props.change(property, autokana1.getFurigana())
    //     } else if (property === "kana2") {

    //     }


    // }


    // changeIntend(e) {


    //         this.props.contactstate.intendTime=1;
    //         this.props.contactstate.intendTime2=1;

    //     this.setState({"setintend" : this.state.setintend===true?false:true})
    //     // alert(e.target.value)
    //     // alert(this.state.setintend)
    //     // this.setState={setintend: e.target.value}
    //     // alert(this.state.setintend)
    // }


    render() {


        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit}  >

                <div className="row">
                    <div className="item-outer col-lg-12">
                        <div className="item">

                            {/* <table>
                                <colgroup width='30%' />
                                <colgroup width='70%' />
                                <tbody>
                                    <tr>
                                        <th>担当</th>
                                        <td> */}
                            <Card style={{ margin: 20, padding: 10 }}>

                                <CardContent style={{ padding: 10 }}>
                                    <Autocomplete
                                        id="combo-box-demo"

                                        options={this.props.users_select.length === 0 && this.props.rent_customer_contacts_end_responses_form.response_user_id ? [this.props.users_select.filter(a => a.value === this.props.rent_customer_contacts_end_responses_form.end_response_user_id)[0]]
                                            : this.props.users_select}
                                        getOptionLabel={(option) => option.label ? option.label : ''}

                                        style={{ width: "100%" }}
                                        name="userId"
                                        loading={this.props.users_loading}

                                        value={this.props.rent_customer_contacts_end_responses_form.end_response_user_id ? this.props.users_select.filter(a => a.value === this.props.rent_customer_contacts_end_responses_form.end_response_user_id)[0] : {}}

                                        onChange={this.change_response_user_id}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            variant="outlined"
                                            name="response_user_id"

                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>

                                                        {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            // onChange={this.change_response_user_id}
                                            label="反響担当" />}

                                    />
                                    {/* <Field
                                                name="sectionId"
                                                component="input"
                                                type="hidden"

                                            />
                                            <Field
                                                name="rentResponseId"
                                                component="input"
                                                type="hidden"
                                            /> */}
                                    {/* <Field
                                                name="endResponseUserId"
                                                onChange={this.handleChange}
                                                value={this.props.rent_customer_contacts_end_responses_form.endResponseUserId}
                                                component={RenderSelectInput} options={this.props.users_select} /> */}

                                    {/* </td>
                                    </tr>
                                    <tr>
                                        <th>対応日</th>
                                        <td> */}
                                </CardContent>
                                <CardContent style={{ padding: 10,textAlign:"left" }}>
                                    <TextField
                                        label="追客終了日"
                                        name="end_response_date"
                                        id="end_response_date"
                                        variant="standard"
                                        size="small"
                                        onChange={this.handleChange}
                                        style={{ marginTop: 0, marginRight:30 }}
                                        type="date"
                                        value={this.props.rent_customer_contacts_end_responses_form.end_response_date}
                                        error={!this.props.rent_customer_contacts_end_responses_form.end_response_date}
                                    />
                                {/* </CardContent>
                                <CardContent style={{ padding: 10 }}> */}

                                    {/* <Field
                                                name="endResponseDate"
                                                id="endResponseDate"
                                                onChange={this.handleChange}
                                                value={this.props.rent_customer_contacts_end_responses_form.endResponseDate}
                                                component={ReduxFormDateRange}
                                                type="text"
                                                placeholder=""
                                                className="form-control"
                                            /> */}
                                    {/* </td>
                                    </tr> */}






                                    {/* <tr>
                                        <th>理由</th>
                                        <td> */}

                                    <FormControl variant="outlined" className={{}}>
                                        <InputLabel id="rank-outlined-label">理由</InputLabel>
                                        <MaterialUiSelect
                                            labelId="rank-label"
                                            id="rank" style={{ width: 150 }}
                                            value={this.props.rent_customer_contacts_end_responses_form.end_response_reason}
                                            name="end_response_reason"
                                            // onChange={this.onChange.bind(this, "setRentMMediumId")}
                                            onChange={this.handleChange}
                                            label="end_response_reason"
                                        >
                                            {this.props.rent_m_response_end_reasons.length > 0 ? this.props.rent_m_response_end_reasons.map(function (value) {
                                                return <MenuItem value={value.id}>{value.name}</MenuItem>
                                                })
                                            :""}

                                            {/* <MenuItem value={0}>-</MenuItem> */}
                                            {/* <MenuItem value={1}>他決</MenuItem>
                                            <MenuItem value={2}>引越中止</MenuItem>

                                            <MenuItem value={4}>規定回数終了</MenuItem>
                                            <MenuItem value={5}>審査NG</MenuItem>
                                            <MenuItem value={6}>音信不通</MenuItem>
                                            <MenuItem value={7}>他店舗で対応</MenuItem>
                                            <MenuItem value={8}>エリア外</MenuItem>
                                            <MenuItem value={9}>売買</MenuItem>
                                            <MenuItem value={10}>集約のため</MenuItem>
                                            <MenuItem value={3}>その他</MenuItem> */}

                                        </MaterialUiSelect>
                                    </FormControl>

                                    {/* <Field name="endResponseReason"
                                            id="endResponseReason"
                                            onChange={this.handleChange}
                                            value={this.props.rent_customer_contacts_end_responses_form.endResponseReason}
                                            className="form-control"
                                            component={RenderField}
                                            type="select">
                                            <option value="0">-</option>
                                            <option value="1">他決</option>
                                            <option value="2">引越中止</option>
                                            <option value="3">その他</option>

                                        </Field> */}
                                    {/* </td>
                                    </tr>

                                    <tr>
                                        <th>備考</th>
                                        <td> */}

                                </CardContent>
                                <CardContent style={{ padding: 10 }}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="備考"
                                        multiline
                                        rows={4}
                                        name="end_response_remarks"
                                        onChange={this.handleChange}
                                        value={this.props.rent_customer_contacts_end_responses_form.end_response_remarks}
                                        variant="outlined"
                                        style={{ width: "100%", padding: 10 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {/* <Field
                                                name="endResponseRemarks"
                                                id="endResponseRemarks"
                                                onChange={this.handleChange}
                                                value={this.props.rent_customer_contacts_end_responses_form.endResponseRemarks}
                                                component={RenderField}
                                                type="textarea"
                                                placeholder=""
                                                className="form-control"
                                            /> */}
                                    {/* </td>
                                    </tr>

                                </tbody>
                            </table> */}
                                </CardContent></Card>


                        </div>
                    </div>
                </div>
            </form>
        );
    }
}







const mapStateToProps = state => {
    return {
        rent_customer_contacts_end_responses_form: state.customerContactsReducer.rent_customer_contacts_end_responses_form,
        rent_m_response_end_reasons: state.responsesReducer.rent_m_response_end_reasons,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_rent_customer_contacts_end_responses_form(state) {
            dispatch(CustomerContacts.change_rent_customer_contacts_end_responses_form(state))
        },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ResponseEndComponent);




// export default reduxForm({
//     form: 'responseendform', // a unique identifier for this form
//     validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ResponseEndComponent)

// export default connect(mapStateToProps, mapDispatchToProps)(ContactEditComponent);