import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as Responses from './Saga/ResponseList';
import '../../Css/Grid.css';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import Grid from '../StockRooms/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css'
import Fab from '@mui/material/Fab';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import WebIcon from '@mui/icons-material/Web';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import SendIcon from '@mui/icons-material/Send';
import ResponseLineDispComponent from './ResponseLineDispComponent';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ResponseLineSendFileUpload from './ResponseLineSendFileUpload';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MaterialUiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';



interface Props {
  row: any,
  mail_id: number,
  response_matching_list_loading: number,
  response_matching_list: any
  get_response_matching_list: any,
  selectedrow: any,
  select_response_matching: any,
  delete_mail: any,
  section_select: any,
  users_select: any,
  user_details: any,
  response_line_matching_list: any,
  get_response_line_matching_list: any,
  line_user_id: any,
  hearing_sheet_id: any,

  response_search_list: any,
  line_schedule: any,
  line_schedule_users: any,
  line_schedule_detail: any,

  change_line_schedule_detail: any,
  rent_line_templates_list: any,
  get_rent_line_templates_list: any,
  get_rent_line_template_detail: any,
  rent_line_template_detail: any,
  change_rent_line_template_detail: any,
  insert_rent_line_template_detail: any,
  change_rent_line_template: any,
  rent_line_template: any,
  insert_rent_line_template: any,
  update_rent_line_template: any,

  file_update_disp: any,
  change_file_update_disp: any,
  shops: any,
  stock_rooms:any,
}

interface State {

  refresh: boolean,

  text:string,
  modal_del: boolean,
  // customerdetail:any,

  // responsedetail:any,
  // state types
  fileid: number,
    name: string,
    columnWidths:any,
  type: number,
  select_section_id:number,

  stock_modal:boolean,

}










class ResponseLineSendComponent extends Component<Props, State>{
  constructor(props: Readonly<Props>) {
    super(props);

    var data = new Date()
    data.setMonth(data.getMonth() - 1)
    this.state = {
      // height: window.innerHeight,
      refresh: false,
      text:"",
      modal_del:false,
      fileid:0,
      name:"",
      columnWidths:{
        title:300,
      },
      type:0,
      select_section_id:this.props.user_details.amSectionId,
      stock_modal: false,
    };

    // this.togglemail = this.togglemail.bind(this);
    this.refresh = this.refresh.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.onAdd = this.onAdd.bind(this);
    this.onStockAdd = this.onStockAdd.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
    this.handleRoomListSend = this.handleRoomListSend.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    

    this.props.get_rent_line_templates_list(this.state.select_section_id)

    this.props.change_rent_line_template({id: '', title:'', section_id: this.props.user_details.amSectionId, is_deleted: 0 })
    this.props.change_rent_line_template_detail([])
    // alert(this.props.mail_id)
  }

  onAdd = () => {
    if(this.state.text != ""){
      let rent_line_template_detail = this.props.rent_line_template_detail
      let maxNo = rent_line_template_detail.length > 0 ? Math.max.apply(null,rent_line_template_detail.map(function(o:any){return o.no;})) : -1
      // alert(maxNo)
      let detail = {no:maxNo+1,
        message_type:"text",
        mime_type:null,
        mime:null,
        file_id:null,
        message_text:this.state.text,}
      
      this.props.change_rent_line_template_detail(this.props.rent_line_template_detail.concat(detail))
      this.props.insert_rent_line_template_detail(detail)
      

      this.setState({ text: "" });
    }

  }

  onStockAdd = () => {

      if(this.props.stock_rooms.length > 10){
        alert("一度に送信できる物件数は10件までです。\n10件を超えて送信する場合は、ストックリストを分けて挿入してください。")
        return
      }

      let text = ""
      for(let i=0; i<this.props.stock_rooms.length; i++){
        text = text + (text ? "," : "") + this.props.stock_rooms[i].tatemono_id + "_" + 
        this.props.stock_rooms[i].room_id + "_" + 
        this.props.stock_rooms[i].tatemono_name + "_" + 
        this.props.stock_rooms[i].room_name + "_" + 
        this.props.stock_rooms[i].chinryou + "_" + 
        this.props.stock_rooms[i].choiki + "_" + 
        this.props.stock_rooms[i].madori_name
      }

      let rent_line_template_detail = this.props.rent_line_template_detail
      let maxNo = rent_line_template_detail.length > 0 ? Math.max.apply(null,rent_line_template_detail.map(function(o:any){return o.no;})) : -1
      // alert(maxNo)
      let detail = {
        no:maxNo+1,
        message_type:"stocklist",
        mime_type:null,
        mime:null,
        file_id:null,
        message_text:text,
      }
      
      this.props.change_rent_line_template_detail(this.props.rent_line_template_detail.concat(detail))
      this.props.insert_rent_line_template_detail(detail)
      

      this.setState({ text: "" });


  }

  onChange = (e:any) => {

    this.setState({ text: e.target.value });

  }


  refresh() {
    this.setState({
      refresh: !this.state.refresh
    });
  }
  handleChange = (event: any) => {

    let rent_line_template = this.props.rent_line_template

    rent_line_template[event.target.name] = event.target.value

    this.props.change_rent_line_template(rent_line_template)

    this.refresh()
  }


  handleOpen(id:any) {

    this.props.get_rent_line_template_detail(id)

    let rent_line_template = this.props.rent_line_templates_list.filter((a:any) => a.id == id)[0]
    this.props.change_rent_line_template(rent_line_template);

    this.refresh()

    // this.props.change_rent_m_corporates_top_open(true)
}

handleUpdate(id:any, delflg:any){

  let rent_line_template =  this.props.rent_line_template
  rent_line_template["is_deleted"] = delflg
  this.props.change_rent_line_template(rent_line_template)

  if(id){
      this.props.update_rent_line_template()
      if(delflg == 1){
        this.props.change_rent_line_template({id: '', title:'', section_id: this.state.select_section_id > 0 ? this.state.select_section_id : this.props.user_details.amSectionId, is_deleted: 0 })
      }
      
  }else{
      this.props.insert_rent_line_template()

  }

  this.refresh()
  
}

handleNew(){
    this.props.change_rent_line_template_detail([])
    this.props.change_rent_line_template({id: '', title:'', section_id: this.state.select_section_id > 0 ? this.state.select_section_id : this.props.user_details.amSectionId, is_deleted: 0 })

    this.refresh()
}

  _onColumnResizeEndCallback(newColumnWidth:any, columnKey:any) {
    this.setState(({ columnWidths }) => ({
      columnWidths: {
        ...columnWidths,
        [columnKey]: newColumnWidth,
      },
    }));
}

_rowClassNameGetter(index:any){
  if(this.props.rent_line_templates_list && this.props.rent_line_templates_list[index]) {
    return parseInt(this.props.rent_line_templates_list[index].id) == parseInt(this.props.rent_line_template.id) ? "line_template_selected" : ""
  }else{
    // alert(2)
    return ""
  }
}

  handleRoomListSend(){

      let rent_line_template_detail = this.props.rent_line_template_detail
      let maxNo = rent_line_template_detail.length > 0 ? Math.max.apply(null,rent_line_template_detail.map(function(o:any){return o.no;})) : -1
      // alert(maxNo)
      let detail = {no:maxNo+1,
        message_type:"5",
        mime_type:null,
        mime:null,
        file_id:null,
        message_text:"",}
      
      this.props.change_rent_line_template_detail(this.props.rent_line_template_detail.concat(detail))
      this.props.insert_rent_line_template_detail(detail)
      

      this.setState({ text: "" });

  }
  handleChangeSection(e:any){

    this.props.get_rent_line_templates_list(e.target.value)
    this.props.change_rent_line_template({id: '', title:'', section_id: e.target.value, is_deleted: 0 })

    this.setState({ select_section_id: e.target.value });

  }

  render() {

    return (
      <div style={{marginLeft:10,marginRight:10,width:1000,height:700}}>

        {/* <TextField
          id="outlined-basic"
          //   className={classes.textField}
          label="お客様名"
          margin="normal"

          onChange={this.handleChange}
          name="name"
          value={this.state.formData.name}
          style={{ float: "left", width: "150px", marginTop: 0, paddingRight: "5px" }}

        /> */}

        <div style={{float:"left"}}>
        



        {/* <TextField
        id="datetime-local"
        label="送信予約日時"
        type="datetime-local"
        defaultValue="2017-05-24T10:30"
        style={{ width: 200, marginBottom:5 }}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
 

        {/* <Table rowHeight={45} rowsCount={this.props.response_search_list.filter((a:any)=>a.line_send == true).length} headerHeight={40}
                    width={250}
                    height={600}

                    // onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                >

          <Column isResizable={true} columnKey="column4" header={<Cell>送信先</Cell>} cell={({ rowIndex, ...props }) => (<Cell>
            {this.props.response_search_list.filter((a:any)=>a.line_send == true)[rowIndex].line_img_url ? <img className="faceicon" src={this.props.response_search_list.filter((a:any)=>a.line_send == true)[rowIndex].line_img_url} alt="lineimg" /> : ""}
            {this.props.response_search_list.filter((a:any)=>a.line_send == true)[rowIndex].name1}</Cell>)} width={200} />
        </Table> */}

        <FormControl variant="outlined" fullWidth>
            <InputLabel id="sectionId-label">店舗</InputLabel>
            <MaterialUiSelect
                labelId="sectionId-label"
                id="section_id"
                name="section_id"
                value={this.state.select_section_id}
                onChange={this.handleChangeSection}
                // disabled={this.props.formData.id}
                label="section_id"
                margin='dense'
            >
                <MenuItem key={-1} value={0}>全店</MenuItem>
                {this.props.section_select.filter((a:any) => a.value > 0 && a.value <=6 || a.value == 10 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47 || a.value == 55).map(function (item:any, i:any) {
                return (
                    <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                );

                })}

            </MaterialUiSelect>
        </FormControl>

        <Table rowHeight={45} rowsCount={this.props.rent_line_templates_list.length} headerHeight={40}
                    width={350}
                    height={600}

                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    className="gridLineTemplateList"
                    rowClassNameGetter={this._rowClassNameGetter}
                >

          <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{ marginTop: -5 }} aria-label="edit" size="small" onClick={() => { this.handleOpen(this.props.rent_line_templates_list[rowIndex].id) }}><EditIcon /></IconButton></Cell>)} width={40} />
          <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell>
            {(() => {
                    if (this.props.rent_line_templates_list[rowIndex].section_id === 1) {
                        return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-success">番</span></span></div >
                    } else if (this.props.rent_line_templates_list[rowIndex].section_id === 2) {
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-warning">レ</span></span></div >
                    } else if (this.props.rent_line_templates_list[rowIndex].section_id === 3) {
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-danger">駅</span></span></div >
                    } else if (this.props.rent_line_templates_list[rowIndex].section_id === 4) {
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-dark">東</span></span></div >
                    } else if (this.props.rent_line_templates_list[rowIndex].section_id === 6) {
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-light">公</span></span></div >
                    } else if (this.props.rent_line_templates_list[rowIndex].section_id === 17) {
                        return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-info">サ</span></span></div >
                    } else if (this.props.rent_line_templates_list[rowIndex].section_id === 47) {
                        return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-secondary">通</span></span></div >
                    }else{
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-primary">
                        {this.props.rent_line_templates_list[rowIndex].section_id > 0 && this.props.shops.length > 0 ? this.props.shops.filter((a:any) => a.tenpoNo == this.props.rent_line_templates_list[rowIndex].section_id)[0].tenpoName.charAt(0) : ""}
                      </span></span></div >
                    }
          })()}
            
            </Cell>)} width={40} />
          <Column isResizable={true} columnKey="title" header={<Cell>タイトル</Cell>} cell={({ rowIndex, ...props }) => (<Cell>{this.props.rent_line_templates_list[rowIndex].title}</Cell>)} width={this.state.columnWidths.title} />
        </Table>
        <Button style={{marginTop:10}} onClick={()=>this.handleNew()} variant="contained" size="small" color="primary">新規作成</Button>
        


        </div>

        <div style={{float:"left", marginLeft:20}}>
          {/* {this.props.rent_line_template.id} */}
          <TextField
            id="title"
            name = "title"
            label="タイトル"
            type="text"
            value = {this.props.rent_line_template.title}
            onChange={this.handleChange}
            style={{ width: 300, marginBottom:5 }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            margin="dense"
          />
          <Button style={{ marginLeft:10, marginTop:10}} onClick={()=>this.handleUpdate(this.props.rent_line_template.id, 0)} variant="contained" size="small" color="primary">タイトル保存</Button>
          
          {this.props.rent_line_template.id ? <Button style={{ marginLeft:5, marginTop:10 }} size="small" variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>テンプレート削除</Button>:""}
                
          <Modal isOpen={this.state.modal_del} fade={false} className="modal-dialog-centered">
            <ModalBody>
              <div>本当に削除してよろしいですか？</div>
            </ModalBody>
            <ModalFooter>
              <Button variant="contained" color="secondary" onClick={()=>{this.handleUpdate(this.props.rent_line_template.id, 1); this.setState({ "modal_del": false })}}>削除</Button>
              <Button
                variant="contained"
                style={{marginLeft:3}}
                onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button>
             </ModalFooter>              
          </Modal>
          {this.props.rent_line_template.id ? <span>
          <div style={{width:500, height:500, border:"1px solid gray"}} className="item-line" id="item-line">
            <ResponseLineDispComponent rent_line_template_detail={this.props.rent_line_template_detail} />
          </div>
          <div style={{marginTop:20, float:"left"}}>
            <TextField
              id="outlined-multiline-static"
              name="text"
              label="内容"
              multiline
              rows={4}
              defaultValue=""
              variant="outlined"
              style={{width:300}}
              value = {this.state.text}
              onChange={this.onChange}
            />
          </div>
          <div style={{marginTop:20, float:"left"}}>
            <div>
              <Tooltip title="リンク">
                <Fab onClick={() => {this.setState({ "type": 0 }); this.props.change_file_update_disp(true)}} size="small" color="secondary" aria-label="add" style={{marginLeft:20, backgroundColor:"dodgerblue"}}>
                  <WebIcon />
                </Fab>
              </Tooltip>
              
              {/* <Fab color="primary" aria-label="add">
                <AddIcon />
              </Fab> */}

              <Tooltip title="ストック">
                    <Fab color="primary" size="small" onClick={() => this.setState({stock_modal: true})} style={{marginLeft:15, backgroundColor:"#f50057"}} >
                        <ShoppingCartIcon />
                    </Fab>
              </Tooltip>

              <Tooltip title="おすすめ物件">
                <Fab onClick={() => this.handleRoomListSend()} size="small" color="secondary" aria-label="add" style={{marginLeft:15, backgroundColor:"orange"}}>
                  <HomeWorkIcon />
                </Fab>
              </Tooltip>

              <Tooltip title="画像">
                <Fab onClick={() => {this.setState({ "type": 1 }); this.props.change_file_update_disp(true)}} size="small" color="secondary" aria-label="add" style={{marginLeft:15, backgroundColor:"limegreen"}}>
                  <InsertPhotoIcon />
                </Fab>
              </Tooltip>
            </div>

            {this.state.stock_modal ? <Paper style={{ height: "530px", width: "500px", position: "absolute", bottom: "5px", right: "20px", padding: 10, zIndex: 1000 }}>

            <div style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "400px", height: "450px", padding: "20px", background: "#FFF" }}>
                LINE物件情報送信
                <Grid />

                <Button variant="contained" color="primary" style={{ margin: "20px", marginTop: "50px" }} onClick={this.onStockAdd} >送信する</Button>
                <Button variant="contained" color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={() => this.setState({stock_modal: false})}>キャンセル</Button>

            </div>

            </Paper> : ""
            }


            {this.props.file_update_disp ? <Paper id="responsefileupload" style={{ height: "330px", width: "400px", position: "absolute", bottom: "5px", right: "20px", padding: 10, zIndex: 1000 }}>

              <BottomNavigationAction label="Nearby" onClick={() => this.props.change_file_update_disp(false)} style={{ float: "right", paddingTop: 0, paddingBottom: 0, paddingRight: 0 }} icon={<CloseIcon />} />

              {/* <hr /> */}
              <ResponseLineSendFileUpload fileid={this.state.fileid} name={this.state.name} type={this.state.type} />
            </Paper>:""}

            <div>
            <Fab size="small" onClick={this.onAdd} variant="extended" color="primary" aria-label="add" style={{marginLeft:20, marginTop:30}}>
              <SendIcon />
              送信
            </Fab>
            </div>
          </div>
          </span>:<div style={{fontSize:11}}>※新規テンプレートを作成する場合は、まずタイトルを登録してください。</div>}
        </div>




      </div>
    );
  }


}



const mapStateToProps = (state: any) => {
  return {
    response_search_list: state.responselist.response_search_list,

    line_schedule_detail: state.responselist.line_schedule_detail,

    rent_line_templates_list: state.responselist.rent_line_templates_list,
    rent_line_template: state.responselist.rent_line_template,
    rent_line_template_detail: state.responselist.rent_line_template_detail,

    file_update_disp: state.responselist.file_update_disp,
    section_select: state.masterReducer.section_select,
    user_details: state.masterReducer.user_details,
    shops: state.masterReducer.shops,
    
    stock_rooms: state.StockRooms.stock_rooms,
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    change_line_schedule_detail(state: any) {
      dispatch(Responses.change_line_schedule_detail(state))
    },

    get_rent_line_templates_list(state: any) {
      dispatch(Responses.get_rent_line_templates_list(state))
    },
    get_rent_line_template_detail(state: any) {
      dispatch(Responses.get_rent_line_template_detail(state))
    },
    change_rent_line_template_detail(state: any) {
      dispatch(Responses.change_rent_line_template_detail(state))
    },
    insert_rent_line_template_detail(state: any) {
      dispatch(Responses.insert_rent_line_template_detail(state))
    },
    change_rent_line_template(state: any) {
      dispatch(Responses.change_rent_line_template(state))
    },
    insert_rent_line_template(state: any) {
      dispatch(Responses.insert_rent_line_template(state))
    },
    update_rent_line_template(state: any) {
      dispatch(Responses.update_rent_line_template(state))
    },

    change_file_update_disp(state: any) {
      dispatch(Responses.change_file_update_disp(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseLineSendComponent);