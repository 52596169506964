import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Document, Page, pdfjs } from "react-pdf";

import * as Contracts from './Saga/Contracts';
import "react-input-range/lib/css/index.css";
import './Css/Contracts.css';

var moment = require('moment-timezone');
class ContractCheck extends Component {
  constructor(props) {
    super(props);
    this.state = { //page: 1,
    // numPages: 1,
    base64:null,
    // rotate: 0,
    // scale: 10,
    confirm: false}
    console.log(this.props.check_contract_sheet_file_data)
    if(!this.props.base64 && this.props.check_contract_sheet_file_data){
        if(this.props.check_contract_sheet_file_data.file){
            const reader = new FileReader()
            reader.readAsDataURL(this.props.check_contract_sheet_file_data.file)
            reader.onload = () => {
                this.setState({
                    base64: reader.result,
                    // name: file.name,
                })
            }
        }
    }
    this.handleResend = this.handleResend.bind(this);
    this.handleDocumentLoad = this.handleDocumentLoad.bind(this);
  }
  

  handleButtonClick(page) {
    this.setState({ page })
  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }
  handleResend(payment){
    this.props.contracts_resend_parking({responses_id:this.props.rent_contract_details.rent_response_id,payment:payment})
  }
  handleDocumentLoad({ numPages }) {
    // this.setState({ numPages })

    this.props.numPages(numPages);
  }

  render() {
    let ver = []
    return (
        <div className="component" style={{width:'100%'}}>
        {this.props.check_contract_sheet_file_data && (this.state.base64 || this.props.base64)  ? <Document
          // file={this.props.contracts_rent_application_doc_uri}
          file={this.props.check_contract_sheet_file_data.file ? this.props.check_contract_sheet_file_data.file : this.state.base64 ? this.state.base64 : ''}
          style={{ border: 'dotted 1px #aaa' }}
          onLoadSuccess={this.handleDocumentLoad.bind(this)}
          error={this.props.sheet == 2 ? <div><p>ファイルの読み込みに失敗しました。</p><p><a href={process.env.REACT_APP_HOMESTATION4_URL+"/manage/building/detail/index?t_id="+this.props.rent_contract_details.tatemono_id}>建物詳細</a>の添付ファイルでハザードマップがない、もしくはファイルに不具合がある可能性があります。ご確認ください。</p></div> : "ファイルの読み込みに失敗しました。"}
        >
          <Page
            // rotate={90}
            scale={this.props.scale / 100}
            rotate={this.props.rotate}
            pageNumber={this.props.page}
            style={{ border: 'solid 2px #000', height: 300,textAlign:'center',margin:'0 auto' }}
          />
        </Document> : this.props.check_contract_sheet_file_data ? <img src={this.props.base64 ? this.props.base64 : this.state.base64} alt="pdfimg" height={500} width={500} /> : ""}
      </div >
        

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    
    rent_contract_details: state.contracts.rent_contract_details,
    check_contract_sheet_file:state.contracts.check_contract_sheet_file,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    contracts_resend_parking(state){
        dispatch(Contracts.contracts_resend_parking(state))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractCheck);
