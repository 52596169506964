
const authentication = require('../../../react-azure-adb2c2').default;




export const list = (values: any) => {


    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContactsAutoSends/` + values;



    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const insert = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/customer/contacts/autosends/`;
    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/customer/contacts/autosends/`;
    // alert(url)
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};