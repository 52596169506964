import React, { Component } from 'react';

import { connect, Field, reduxForm } from 'react-redux';
import { Modal,Row, Col,ModalHeader,ModalFooter,ModalBody} from 'reactstrap';
import Grid from '@mui/material/Grid';
import * as MapMypageTemplate from './Saga/MapMypageTemplate';
//import { actionCreators } from '../../Store/MailData';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CommentIcon from '@mui/icons-material/Comment';
import TextureIcon from '@mui/icons-material/Texture';
import CircleIcon from '@mui/icons-material/PanoramaFishEye';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';
import TrainIcon from '@mui/icons-material/Train';
import StoreIcon from '@mui/icons-material/Store';
import IconButton from '@mui/material/IconButton';
import { Scrollbars } from 'react-custom-scrollbars';
import '../../Css/Table.css';
//import './Css/Search.css';
// import * as AutoKana from 'vanilla-autokana';
let autokana1;
let Bosyuflg;






// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',

//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: '60.33%',
//     flexShrink: 0,
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary,
//   },
// }));
// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

const R = Math.PI / 180;

function distance(lat1, lng1, lat2, lng2) {
  lat1 *= R;
  lng1 *= R;
  lat2 *= R;
  lng2 *= R;
  // alert(6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2)))
  return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}

function line_distance(list) {
  let dis = 0
  // alert(list.length)
  for(let i = 1; i<list.length; i++){
    let dis2 = distance(list[i-1].lat, list[i-1].lng, list[i].lat, list[i].lng)
    // alert(dis2)
    dis = dis + dis2
  }
  return dis

}

function App(props) {
  // const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  const [savealert, setSavealert] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
    props.change_map_disp(!props.map_disp)
    setSavealert(false);
    props.change_rent_introduction_point_template_headers(props.rent_introduction_point_templates.rent_introduction_point_template_headers.filter(a => a.id === id)[0])
    if (isExpanded === false) {
      props.change_rent_introduction_point_template_headers([])
    }
  };

  const clear_rent_introduction_point_template_details = () => {
    let rent_introduction_point_template_headers = props.rent_introduction_point_template_headers
    rent_introduction_point_template_headers.rent_introduction_point_template_details = []
    props.change_rent_introduction_point_template_headers(rent_introduction_point_template_headers)
    props.change_map_disp(!props.map_disp)
  };

  const onChangeName = (e) => {
    // alert(e.target.value)
    let rent_introduction_point_templates = props.rent_introduction_point_templates
    rent_introduction_point_templates.name = e.target.value
    props.change_rent_introduction_point_templates(rent_introduction_point_templates)
    setRefresh(!refresh)
  };

  const onChange = (event) => {
    let rent_introduction_point_template_headers = props.rent_introduction_point_template_headers
    if(event.target.name == "point_color"){
      rent_introduction_point_template_headers.rent_introduction_point_template_details[0][event.target.name]=event.target.value
    }else{
      // rent_introduction_point_headers[event.target.name]=event.target.value
    }

    props.change_rent_introduction_point_template_headers(rent_introduction_point_template_headers)
    console.log(event)

    setRefresh(!refresh);
    props.change_map_disp(!props.map_disp)

    // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}
//  alert(event.target.value)
  }

  const onChangePoint = (event) => {
    let rent_introduction_point_template_headers=props.rent_introduction_point_template_headers

    if(event.target.name == "link_url" || event.target.name == "point_color"){
      rent_introduction_point_template_headers.rent_introduction_point_template_details[0][event.target.name]=event.target.value
    }else if(event.target.name == "distance_is_no_disp"){
      rent_introduction_point_template_headers.rent_introduction_point_template_details[0][event.target.name]=event.target.checked ? 1 : 0
    }else{
      rent_introduction_point_template_headers[event.target.name]=event.target.value
    }

    props.change_rent_introduction_point_template_headers(rent_introduction_point_template_headers)
    console.log(event)
    setRefresh(!refresh);
    props.change_map_disp(!props.map_disp)
  }

  const onChangeIcon = (icon) => {
    let rent_introduction_point_template_headers=props.rent_introduction_point_template_headers

    rent_introduction_point_template_headers.rent_introduction_point_template_details[0]["point_icon"] = icon

    props.change_rent_introduction_point_template_headers(rent_introduction_point_template_headers)

    setRefresh(!refresh);
    props.change_map_disp(!props.map_disp)
  }


  return (
    <div style={{ backgroundColor: "White", height: "100%", width: 250, textAlign: "left", paddingLeft: 8 }}>

        <TextField
          id="outlined-multiline-flexible"
                        label="テンプレート名"
                        name="name"
                        onChange={onChangeName}
                        value={props.rent_introduction_point_templates.name}
                        // style={{ width: "300px", marginTop: "10px" }}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}

                  />
      {props.rent_introduction_point_templates.id && props.rent_introduction_point_templates.section_id == props.user_details.amSectionId ? <BottomNavigation
        //  value={props.map_settings_flg}
        onChange={(event, newValue) => {
          props.change_map_settings_flg(newValue);
        }}
        showLabels
      //   className={classes.root}
      >
        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_template_headers(1)} value={1} label="ポイント" icon={<LocationOnIcon />} />
        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_template_headers(3)} value={3} label="コメント" icon={<CommentIcon />} />




      </BottomNavigation> : ""}
      {props.rent_introduction_point_templates.id && props.rent_introduction_point_templates.section_id == props.user_details.amSectionId ? <BottomNavigation
        value={props.map_settings_flg}
        onChange={(event, newValue) => {
          props.change_map_settings_flg(newValue);
        }}
        showLabels
      //   className={classes.root}
      >


        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_template_headers(2)} value={2} label="範囲" icon={<TextureIcon />} />
        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_template_headers(4)} value={4} label="線" icon={<ShowChartIcon />} />
        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_template_headers(5)} value={5} label="円" icon={<CircleIcon />} />


      </BottomNavigation> : ""}

      <div 
      // className={classes.root}
      ><Scrollbars style={{height:window.innerHeight-370}}>

        {props.rent_introduction_point_templates.section_id == props.user_details.amSectionId && props.rent_introduction_point_templates.rent_introduction_point_template_headers ? props.rent_introduction_point_templates.rent_introduction_point_template_headers.map((value, index) => {

          return (
            <Accordion expanded={props.rent_introduction_point_template_headers.id === value.id} onChange={handleChange(value.id)}>

              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >

                <Typography 
                  // className={classes.heading} 
                  style={{display: 'inline-block'}}>
                  {value.type === 1 ? <LocationOnIcon /> : value.type === 2 ? <TextureIcon /> : value.type === 3 ? <CommentIcon /> : value.type === 4 ? <ShowChartIcon /> : <CircleIcon />} {value.contents}</Typography>
                  <Typography 
                  // className={classes.heading} 
                  style={{display: 'inline-block'}}>
                  {value.type == 5 && value.rent_introduction_point_template_details.length == 2 ? " 半径:" + (Math.round(distance(parseFloat(value.rent_introduction_point_template_details[0].lat), parseFloat(value.rent_introduction_point_template_details[0].lng),parseFloat(value.rent_introduction_point_template_details[1].lat),parseFloat(value.rent_introduction_point_template_details[1].lng))*10)/10) +"㎞" : ""}
                  {value.type == 4 && value.rent_introduction_point_template_details.length >= 2 ? " 距離" + (Math.round(line_distance(value.rent_introduction_point_template_details)*10)/10) +"㎞" : ""}

                </Typography>





              </AccordionSummary>


              {
                //1ポイント 3コメント
                value.type === 1 || value.type === 3 ?
                <div style={{background:"#FFF",padding:"5px", textAlign:"left"}}>
                  
                  {value.type===1 ? <div>
                    <RadioGroup style={{paddingLeft:10}} row aria-label="position" name="point_color" onChange={onChangePoint} value={value.rent_introduction_point_template_details[0].point_color == null ? "red" : value.rent_introduction_point_template_details[0].point_color} >
                      <FormControlLabel control={<Radio value={"red"} style={{color:"red"}} />} label="" />
                      <FormControlLabel control={<Radio value={"blue"} style={{color:"blue"}} />} label="" />
                      <FormControlLabel control={<Radio value={"darkorange"} style={{color:"darkorange"}} />} label="" />
                    </RadioGroup>
                  
                  <div>
                    <IconButton onClick={()=>onChangeIcon(0)} style={{padding:2}} size="large"><LocationOnIcon style={{color:value.rent_introduction_point_template_details[0].point_icon == 0 ? value.rent_introduction_point_template_details[0].point_color == null ? "red" : value.rent_introduction_point_template_details[0].point_color : ""}} fontSize='large' /></IconButton>
                    <IconButton onClick={()=>onChangeIcon(1)} style={{padding:2}} size="large"><ApartmentIcon style={{color:value.rent_introduction_point_template_details[0].point_icon == 1 ? value.rent_introduction_point_template_details[0].point_color == null ? "red" : value.rent_introduction_point_template_details[0].point_color : ""}} fontSize='large' /></IconButton>
                    <IconButton onClick={()=>onChangeIcon(2)} style={{padding:2}} size="large"><HomeIcon style={{color:value.rent_introduction_point_template_details[0].point_icon == 2 ? value.rent_introduction_point_template_details[0].point_color == null ? "red" : value.rent_introduction_point_template_details[0].point_color : ""}} fontSize='large' /></IconButton>
                    <IconButton onClick={()=>onChangeIcon(3)} style={{padding:2}} size="large"><TrainIcon style={{color:value.rent_introduction_point_template_details[0].point_icon == 3 ? value.rent_introduction_point_template_details[0].point_color == null ? "red" : value.rent_introduction_point_template_details[0].point_color : ""}} fontSize='large' /></IconButton>
                    <IconButton onClick={()=>onChangeIcon(4)} style={{padding:2}} size="large"><StoreIcon style={{color:value.rent_introduction_point_template_details[0].point_icon == 4 ? value.rent_introduction_point_template_details[0].point_color == null ? "red" : value.rent_introduction_point_template_details[0].point_color : ""}} fontSize='large' /></IconButton>
                  </div>
                  </div> : ""}
                  {value.type===1 ? 
                    <div>
                      <FormControlLabel control={<Checkbox style={{marginLeft:10}} checked={value.rent_introduction_point_template_details[0].distance_is_no_disp == 1} value={1} onChange={onChangePoint} name="distance_is_no_disp" />} label="距離表示しない" />
                      <TextField name="contents" onChange={onChangePoint} fullWidth value={value.contents} label={"ポイント名"} variant="outlined" size='small'/>
                    </div>:
                  
                    <TextField name="contents" onChange={onChangePoint} value={value.contents} label={"コメント"} variant="outlined" fullWidth multiline maxRows={3} minRows={3} />}
                  
                  <TextField id="outlined-basic" size="small" fullWidth name="link_url" onChange={onChangePoint} value={value.rent_introduction_point_template_details[0].link_url} label={"リンク先"} variant="outlined" style={{marginTop:10}}/>

            
              </div>:""

              }


              {
                // props.rent_introduction_headers.rent_introduction_point_headers.filter(a => a.id === id)[0]
                (value.type === 4 || value.type === 2 || value.type === 5) && props.rent_introduction_point_template_headers && props.rent_introduction_point_template_headers.rent_introduction_point_template_details ? props.rent_introduction_point_template_headers.rent_introduction_point_template_details.length > 0 ? 
                <RadioGroup style={{marginLeft:20}} row aria-label="position" name="point_color" onChange={onChange} value={props.rent_introduction_point_template_headers.rent_introduction_point_template_details[0].point_color == null ? "red" : props.rent_introduction_point_template_headers.rent_introduction_point_template_details[0].point_color} >
                  <FormControlLabel control={<Radio value={"red"} style={{color:"red"}} />} label="" />
                  <FormControlLabel control={<Radio value={"blue"} style={{color:"blue"}} />} label="" />
                  <FormControlLabel control={<Radio value={"darkorange"} style={{color:"darkorange"}} />} label="" />
                  <FormControlLabel control={<Radio value={"green"} style={{color:"green"}} />} label="" />
                  <FormControlLabel control={<Radio value={"hotpink"} style={{color:"hotpink"}} />} label="" />
                </RadioGroup>
                :"":""
              }

              {/* </AccordionDetails> */}
              <Button size="small"
                color="secondary"
                variant="contained"
                type="button"
                style={{ margin: "5px" }}
                onClick={() => props.delete_rent_introduction_point_template_headers(props.rent_introduction_point_template_headers.id)}
              >
                削除
              </Button>


              {(props.rent_introduction_point_template_headers.type === 2 || props.rent_introduction_point_template_headers.type === 4) ? <Button size="small"
                //  color="secondary"
                variant="contained"
                type="button"
                style={{ margin: "5px" }}
                onClick={clear_rent_introduction_point_template_details}
              >
                クリア
              </Button> : ""}
              {/* {savealert?<Alert color="danger">
      登録できませんでした。<br/>
        重複している番号があります！
        </Alert>:""} */}
              {/* <Button size="small" 
         color="primary"
         variant="contained"
         type="button"
         style={{float:"right",margin:"5px"}}
         onClick={()=>props.update_rent_introduction_point_headers()}
         >
            保存
          </Button> */}
            </Accordion>
          );

        }) : ""}

      </Scrollbars>
      </div>
      

    </div>
  );
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
  return {

    map_settings_flg: state.mapmypagetemplate.map_settings_flg,
    map_settings_edit: state.mapmypagetemplate.map_settings_edit,
    
    map_disp: state.mapmypagetemplate.map_disp,

    user_details: state.masterReducer.user_details,



    rent_introduction_point_templates: state.mapmypagetemplate.rent_introduction_point_templates,
    rent_introduction_point_template_disp: state.mapmypagetemplate.rent_introduction_point_template_disp,
    rent_introduction_point_template_headers: state.mapmypagetemplate.rent_introduction_point_template_headers,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    //firebasedata: state.firebaseReducer.firebasedata,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_map_settings_flg(state) {
      dispatch(MapMypageTemplate.change_map_settings_flg(state))
    },
    change_map_settings_edit(state) {
      dispatch(MapMypageTemplate.change_map_settings_edit(state))
    },
    change_rent_introduction_point_template_headers(state) {
      dispatch(MapMypageTemplate.change_rent_introduction_point_template_headers(state))
    },
    change_map_disp(state) {
      dispatch(MapMypageTemplate.change_map_disp(state))
    },
    delete_rent_introduction_point_template_headers(state) {
      dispatch(MapMypageTemplate.delete_rent_introduction_point_template_headers(state))
    },



    insert_rent_introduction_point_template_headers(state) {
      dispatch(MapMypageTemplate.insert_rent_introduction_point_template_headers(state))
    },
    change_rent_introduction_point_templates(state) {
      dispatch(MapMypageTemplate.change_rent_introduction_point_templates(state))
    },

    //loadTodos(state) {
    //    dispatch(loadTodos(state))
    //}
  };
}

// App = reduxForm({
//     form: 'mapsearchform', // a unique identifier for this form
//     validate,//バリデーション

//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(App)
// App = withStyles(useStyles)(App);  
export default connect(mapStateToProps, mapDispatchToProps)(App);
