
const authentication = require('../../../react-azure-adb2c2').default;

export const list = (values: any) => {

    // const url = `http://localhost:5000/api/v1/familyconvert/list/` + values;
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/familyconvert/list/` + values;
  
    const token = authentication.getAccessToken();
    // console.log(JSON.stringify(values))
    // alert(JSON.stringify(values))
    return fetch(url, {
      method: 'get',
      // body: JSON.stringify(values),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(res => res.json())
      .then(payload => ({ payload }))
      .catch(error => ({ error }));
  };

export const update = (value: any) => {
    // alert( JSON.stringify(value))

    // const url = `http://localhost:5000/api/v1/familyconvert/update/` + value.rent_contract_id;
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/familyconvert/update/` + value.rent_contract_id;
  
    const token = authentication.getAccessToken();
  
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value.family),
  
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
  
  };
  

