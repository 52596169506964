import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as RoomList from './Saga/RoomList';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import '../../Css/Table.css';

let Bosyuflg;


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSetRoomName: false,

            // shubetu_1: true,
            // shubetu_2: true,
            // shubetu_7: true,
            // shubetu_21: true,
            // BoshuOnly: true,

            // setKubun1:true,

        };

        Bosyuflg = 1;

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onChange = this.onChange.bind(this);
        this.hanei = this.hanei.bind(this);
        this.syokika = this.syokika.bind(this);
        this.kensaku = this.kensaku.bind(this);

    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChangedate1 = (date) => {
        let room_list_search_form = this.props.room_list_search_form
        room_list_search_form.nyuukyo_kanou = date
        this.props.change_room_list_search_form(room_list_search_form)
        this.setState({ name1: date });
    };

    onChange(e) {
        // alert(e.target.name)
        let room_list_search_form = this.props.room_list_search_form
        room_list_search_form[e.target.name] = !room_list_search_form[e.target.name]
        this.props.change_room_list_search_form(room_list_search_form)

        if (e.target.name === "boshu_only") {
            // this.props.change_room_search_list_loading(true);
            this.props.get_search_rooms(this.props.rent_response_id);
        } else {
            if (room_list_search_form.boshu_only) {
                this.props.search()
            }
            // if (room_list_search_form.boshu_only) {
            //     this.props.search()
            // } else {
            //     this.props.get_search_rooms();
            // }


        }




        this.setState({ name1: e.target.value });
    }

    onChangeText(e) {
        // alert(e.target.name)
        let room_list_search_form = this.props.room_list_search_form
        room_list_search_form[e.target.name] = e.target.value

        // if (e.target.name === "tatemono_name") {
        //     clearTimeout(timer);


        //     timer = setTimeout(function () {

        //         this.props.change_room_list_search_form(room_list_search_form)
        //         // this.props.search()
        //         if (room_list_search_form.boshu_only) {
        //             this.props.search()

        //         } else {
        //             this.props.search()
        //             // this.props.get_search_rooms();
        //         }

        //     }.bind(this), 800);
        //     this.setState({ name1: e.target.value });


        // } else {
        // this.props.change_room_list_search_form(room_list_search_form)

        // this.props.search()

        // this.setState({ name1: e.target.value });

        if (e.target.name === "boshu_only") {
            this.props.change_room_search_list_loading(true);
            this.props.get_search_rooms(this.props.rent_response_id);
        } else {
            if (room_list_search_form.boshu_only) {
                this.props.search()
            }
            // if (room_list_search_form.boshu_only) {
            //     this.props.search()
            // } else {
            //     this.props.get_search_rooms();
            // }


        }

        this.setState({ name1: e.target.value });
        // }
    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }

    syokika() {

        let room_list_search_form = this.props.room_list_search_form
        let responsedetail = this.props.responsedetail

        room_list_search_form.kubun_1 = true
        room_list_search_form.kubun_2 = false
        room_list_search_form.kubun_3 = false
        room_list_search_form.kubun_4 = true

        room_list_search_form.yachin_etc_0 = false
        room_list_search_form.yachin_etc_1 = false
        room_list_search_form.chinryou_from = 0
        room_list_search_form.chinryou_to = 0
        room_list_search_form.yachin_etc_2 = false
        room_list_search_form.yachin_etc_3 = false

        room_list_search_form.shubetsu_1 = true
        room_list_search_form.shubetsu_2 = true
        room_list_search_form.shubetsu_7 = true
        room_list_search_form.shubetsu_21 = true

        room_list_search_form.madori_1 = true
        room_list_search_form.madori_2 = true
        room_list_search_form.madori_3 = true
        room_list_search_form.madori_5 = true
        room_list_search_form.madori_8 = true
        room_list_search_form.madori_9 = true
        room_list_search_form.madori_11 = true
        room_list_search_form.madori_14 = true
        room_list_search_form.madori_15 = true
        room_list_search_form.madori_17 = true
        room_list_search_form.madori_20 = true
        room_list_search_form.tikunen = ""
        room_list_search_form.tikunen1 = ""
        room_list_search_form.tikunen2 = ""
        room_list_search_form.tikunen1_month = ""
        room_list_search_form.tikunen2_month = ""
        room_list_search_form.senyuu_u = 0
        room_list_search_form.senyuu_t = 0
        room_list_search_form.toho = 0
        room_list_search_form.setsubi_20 = false
        room_list_search_form.setsubi_16 = false
        room_list_search_form.setsubi_45 = false
        room_list_search_form.setsubi_21 = false
        room_list_search_form.setsubi_91 = false
        room_list_search_form.setsubi_118 = false
        room_list_search_form.setsubi_3 = false
        room_list_search_form.setsubi_4 = false
        room_list_search_form.setsubi_1 = false
        room_list_search_form.setsubi_5 = false
        room_list_search_form.setsubi_127 = false
        room_list_search_form.setsubi_100 = false
        room_list_search_form.setsubi_99 = false
        room_list_search_form.setsubi_102 = false
        room_list_search_form.setsubi_103 = false
        room_list_search_form.setsubi_143 = false
        room_list_search_form.setsubi_12 = false
        room_list_search_form.setsubi_110 = false
        room_list_search_form.setsubi_14 = false
        room_list_search_form.setsubi_9 = false
        room_list_search_form.setsubi_37 = false
        room_list_search_form.setsubi_41 = false
        room_list_search_form.setsubi_40 = false
        room_list_search_form.setsubi_87 = false
        room_list_search_form.setsubi_153 = false
        room_list_search_form.setsubi_154 = false
        room_list_search_form.setsubi_109 = false
        room_list_search_form.setsubi_54 = false
        room_list_search_form.setsubi_51 = false
        room_list_search_form.setsubi_69 = false
        room_list_search_form.setsubi_35 = false
        room_list_search_form.setsubi_36 = false
        room_list_search_form.setsubi_108 = false
        room_list_search_form.setsubi_105 = false
        room_list_search_form.setsubi_104 = false
        room_list_search_form.setsubi_43 = false
        room_list_search_form.setsubi_47 = false
        room_list_search_form.setsubi_86 = false
        room_list_search_form.setsubi_rs = false
        room_list_search_form.setsubi_76 = false
        room_list_search_form.setsubi_140 = false
        room_list_search_form.setsubi_39 = false
        room_list_search_form.setsubi_142 = false
        room_list_search_form.setsubi_129 = false
        room_list_search_form.setsubi_38 = false
        room_list_search_form.setsubi_77 = false
        room_list_search_form.setsubi_2k = false
        room_list_search_form.setsubi_gk = false
        room_list_search_form.setsubi_gkt = false
        room_list_search_form.setsubi_gkt_su = false
        room_list_search_form.setsubi_ac = false
        room_list_search_form.setsubi_ac_su = false
        room_list_search_form.setsubi_ih = false
        room_list_search_form.setsubi_ih_su = false
        room_list_search_form.setsubi_ev = false
        room_list_search_form.setsubi_minami = false
        room_list_search_form.is_gakusei_yoyaku = 0
        room_list_search_form.is_foreigner = 0
        room_list_search_form.is_gakusei_long_move_3 = 0
        room_list_search_form.is_gakusei_long_move_4 = 0
        room_list_search_form.setsubi_toshigas = 0

        room_list_search_form.setsubi_toshigas = false
        room_list_search_form.setsubi_196 = false
        room_list_search_form.setsubi_17 = false
        room_list_search_form.setsubi_147 = false
        room_list_search_form.setsubi_kai = false

        room_list_search_form.kouzou_4 = false
        room_list_search_form.kouzou_3 = false
        room_list_search_form.kouzou_1 = false
        room_list_search_form.kouzou_2 = false
        room_list_search_form.kouzou_14 = false
        room_list_search_form.kouzou_9 = false

        room_list_search_form.nyuukyo_kanou_date = ""


        this.props.change_room_list_search_form(room_list_search_form)

        this.setState({ change: !this.state.change });

    }

    hanei() {

        let room_list_search_form = this.props.room_list_search_form
        let responsedetail = this.props.responsedetail

        room_list_search_form.shubetsu_1 = responsedetail.request_tatemono_types && !responsedetail.request_tatemono_types.split(',').includes("1") ? false : true
        room_list_search_form.shubetsu_2 = responsedetail.request_tatemono_types && !responsedetail.request_tatemono_types.split(',').includes("2") ? false : true
        room_list_search_form.shubetsu_7 = responsedetail.request_tatemono_types && !responsedetail.request_tatemono_types.split(',').includes("7") ? false : true
        room_list_search_form.shubetsu_21 = responsedetail.request_tatemono_types && !responsedetail.request_tatemono_types.split(',').includes("21") ? false : true

        room_list_search_form.chinryou_from = responsedetail.request_from_cost
        room_list_search_form.chinryou_to = responsedetail.request_to_cost

        let madori = responsedetail.request_from_plan > 0 || responsedetail.request_to_plan > 0 ? true : false

        room_list_search_form.madori_1 = madori && (responsedetail.request_from_plan > 1 || (1 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_2 = madori && (responsedetail.request_from_plan > 2 || (2 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_3 = madori && (responsedetail.request_from_plan > 3 || (3 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_5 = madori && (responsedetail.request_from_plan > 5 || (5 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_8 = madori && (responsedetail.request_from_plan > 8 || (8 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_9 = madori && (responsedetail.request_from_plan > 9 || (9 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_11 = madori && (responsedetail.request_from_plan > 11 || (11 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_14 = madori && (responsedetail.request_from_plan > 14 || (14 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_15 = madori && (responsedetail.request_from_plan > 15 || (15 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_17 = madori && (responsedetail.request_from_plan > 17 || (17 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true
        room_list_search_form.madori_20 = madori && (responsedetail.request_from_plan > 20 || (20 > responsedetail.request_to_plan && responsedetail.request_to_plan != 0)) ? false : true

        room_list_search_form.tikunen = responsedetail.request_year > 0 ? responsedetail.request_year : ""

        room_list_search_form.setsubi_20 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("20") ? true : false
        room_list_search_form.setsubi_16 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("16") ? true : false
        room_list_search_form.setsubi_45 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("45") ? true : false
        room_list_search_form.setsubi_21 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("21") ? true : false
        room_list_search_form.setsubi_91 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("91") ? true : false
        room_list_search_form.setsubi_118 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("118") ? true : false
        room_list_search_form.setsubi_3 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("3") ? true : false
        room_list_search_form.setsubi_4 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("4") ? true : false
        room_list_search_form.setsubi_1 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("1") ? true : false
        room_list_search_form.setsubi_5 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("5") ? true : false
        room_list_search_form.setsubi_127 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("127") ? true : false
        room_list_search_form.setsubi_100 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("100") ? true : false
        room_list_search_form.setsubi_99 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("99") ? true : false
        room_list_search_form.setsubi_102 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("102") ? true : false
        room_list_search_form.setsubi_103 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("103") ? true : false
        room_list_search_form.setsubi_143 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("143") ? true : false
        room_list_search_form.setsubi_12 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("12") ? true : false
        room_list_search_form.setsubi_110 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("110") ? true : false
        room_list_search_form.setsubi_14 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("14") ? true : false
        room_list_search_form.setsubi_9 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("19") ? true : false
        room_list_search_form.setsubi_37 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("37") ? true : false
        room_list_search_form.setsubi_41 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("41") ? true : false
        room_list_search_form.setsubi_40 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("40") ? true : false
        room_list_search_form.setsubi_87 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("87") ? true : false
        room_list_search_form.setsubi_153 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("153") ? true : false
        room_list_search_form.setsubi_154 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("154") ? true : false
        room_list_search_form.setsubi_109 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("109") ? true : false
        room_list_search_form.setsubi_54 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("54") ? true : false
        room_list_search_form.setsubi_51 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("51") ? true : false
        room_list_search_form.setsubi_69 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("69") ? true : false
        room_list_search_form.setsubi_35 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("35") ? true : false
        room_list_search_form.setsubi_36 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("36") ? true : false
        room_list_search_form.setsubi_108 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("108") ? true : false
        room_list_search_form.setsubi_105 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("105") ? true : false
        room_list_search_form.setsubi_104 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("104") ? true : false
        room_list_search_form.setsubi_43 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("43") ? true : false
        room_list_search_form.setsubi_47 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("47") ? true : false
        room_list_search_form.setsubi_86 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("86") ? true : false
        room_list_search_form.setsubi_76 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("76") ? true : false
        room_list_search_form.setsubi_140 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("140") ? true : false
        room_list_search_form.setsubi_39 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("39") ? true : false
        room_list_search_form.setsubi_142 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("448") ? true : false
        room_list_search_form.setsubi_129 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("448") ? true : false
        room_list_search_form.setsubi_38 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("38") ? true : false
        room_list_search_form.setsubi_77 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("77") ? true : false
        room_list_search_form.setsubi_2k = responsedetail.response_requests && (responsedetail.response_requests.split(',').includes("22") || responsedetail.response_requests.split(',').includes("23") || responsedetail.response_requests.split(',').includes("28") || responsedetail.response_requests.split(',').includes("30") || responsedetail.response_requests.split(',').includes("59")) ? true : false
        room_list_search_form.setsubi_gkt = responsedetail.response_requests && (responsedetail.response_requests.split(',').includes("22") || responsedetail.response_requests.split(',').includes("23") || responsedetail.response_requests.split(',').includes("24") || responsedetail.response_requests.split(',').includes("95")) ? true : false
        // room_list_search_form.setsubi_gkt_su = responsedetail.response_requests.split(',').includes("22") ? 22 : responsedetail.response_requests.split(',').includes("23") ? 23 : responsedetail.response_requests.split(',').includes("24") ? 24 : responsedetail.response_requests.split(',').includes("95") ? 95 : ""
        room_list_search_form.setsubi_gkt_su = responsedetail.response_requests ? (responsedetail.response_requests.split(',').includes("22") ? 22 : responsedetail.response_requests.split(',').includes("23") ? 23 : responsedetail.response_requests.split(',').includes("24") ? 24 : responsedetail.response_requests.split(',').includes("95") ? 95 : "") : ""
        room_list_search_form.setsubi_ac = responsedetail.response_requests && (responsedetail.response_requests.split(',').includes("7") || responsedetail.response_requests.split(',').includes("8") || responsedetail.response_requests.split(',').includes("58") || responsedetail.response_requests.split(',').includes("115") || responsedetail.response_requests.split(',').includes("119") || responsedetail.response_requests.split(',').includes("121") || responsedetail.response_requests.split(',').includes("151")) ? true : false
        room_list_search_form.setsubi_ac_su = responsedetail.response_requests ? (responsedetail.response_requests.split(',').includes("7") ? 7 : responsedetail.response_requests.split(',').includes("8") ? 8 : responsedetail.response_requests.split(',').includes("58") ? 58 : responsedetail.response_requests.split(',').includes("115") ? 115 : responsedetail.response_requests.split(',').includes("119") ? 119 : responsedetail.response_requests.split(',').includes("121") ? 121 : responsedetail.response_requests.split(',').includes("151") ? 151 : "") : ""
        room_list_search_form.setsubi_ih = responsedetail.response_requests && (responsedetail.response_requests.split(',').includes("29") || responsedetail.response_requests.split(',').includes("30") || responsedetail.response_requests.split(',').includes("59") || responsedetail.response_requests.split(',').includes("92")) ? true : false
        room_list_search_form.setsubi_ih_su = responsedetail.response_requests ? (responsedetail.response_requests.split(',').includes("29") ? 29 : responsedetail.response_requests.split(',').includes("30") ? 30 : responsedetail.response_requests.split(',').includes("59") ? 59 : responsedetail.response_requests.split(',').includes("92") ? 92 : "") : ""

        room_list_search_form.setsubi_ev = false//
        room_list_search_form.setsubi_minami = false//
        room_list_search_form.is_gakusei_yoyaku = 0//
        room_list_search_form.is_foreigner = 0//
        room_list_search_form.is_gakusei_long_move_3 = 0//
        room_list_search_form.is_gakusei_long_move_4 = 0//
        room_list_search_form.setsubi_toshigas = false
        room_list_search_form.setsubi_196 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("196") ? true : false
        room_list_search_form.setsubi_17 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("17") ? true : false
        room_list_search_form.setsubi_147 = responsedetail.response_requests && responsedetail.response_requests.split(',').includes("147") ? true : false

        room_list_search_form.nyuukyo_kanou_date = ""

        this.props.change_room_list_search_form(room_list_search_form)

        this.setState({ change: !this.state.change });
    }

    kensaku() {

        let room_list_search_form = this.props.room_list_search_form
        if (room_list_search_form.boshu_only) {
            this.props.search()
        } else {
            this.props.change_room_search_list_loading(true);
            this.props.get_search_rooms(this.props.rent_response_id);
        }


        this.setState({ change: !this.state.change });
    }


    render() {

        const { handleSubmit, handleChange } = this.props;
        //moment.locale('ja')
        return (
            <div className='loomlist' style={{ backgroundColor: "White", width: 250, textAlign: "left", paddingLeft: 8, paddingBottom: "60px", boxSizing: "border-box" }}>
                <Chip label={this.props.search_rooms_count + "件"} style={{ fontSize: 14 }} variant="outlined"/>
                <Button
                    variant={"contained"}
                    size="small"
                    style={{ float: "right", marginLeft: 5 }}
                    onClick={() => { this.syokika(); this.kensaku() }}>クリア</Button>
                <Button variant={"contained"} color="primary" size="small" style={{ float: "right", background: "#28a745", color: "white" }} onClick={() => { this.syokika(); this.hanei(); this.kensaku() }}>条件反映</Button>

                {/* <form onSubmit={handleSubmit} onChange={handleChange} > */}
                <dl id="check_title" style={{ marginTop: 10 }}>
                    <dt className="side_menu_title">物件名</dt>
                    <div className="form-inline">
                        <TextField
                            fullWidth
                            size="small"
                            name="tatemono_name"
                            id="tatemono_name"
                            type="text"
                            placeholder=""
                            value={this.props.room_list_search_form.tatemono_name}
                            onChange={this.onChangeText}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    // エンターキー押下時の処理
                                    this.props.get_search_rooms(this.props.rent_response_id);
                                }
                            }}
                        />
                    </div>
                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">物件種別</dt>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.shubetsu_1}
                                onChange={this.onChange}
                                color="primary"
                                name="shubetsu_1"

                            />
                        }
                        label="アパート"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.shubetsu_2}
                                onChange={this.onChange}
                                color="primary"
                                name="shubetsu_2"

                            />
                        }
                        label="マンション"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.shubetsu_7}
                                onChange={this.onChange}
                                color="primary"
                                name="shubetsu_7"

                            />
                        }
                        label="一戸建て"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.shubetsu_21}
                                onChange={this.onChange}
                                color="primary"
                                name="shubetsu_21"

                            />
                        }
                        label="分譲"
                    />
                </dl>
                <dl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.boshu_only}
                                onChange={this.onChange}
                                color="primary"
                                name="boshu_only"
                                style={{paddingBottom:5}}
                            />
                        }
                        label="募集中のみ"
                        style={{marginBottom:-3}}
                    />
                    {this.props.room_list_search_form.boshu_only ? "" : <div style={{fontSize:11, color:"red"}}>募集中以外も検索する場合は、条件を入力して検索ボタンを押してください。</div>}

                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">管理区分</dt>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kubun_1}
                                onChange={this.onChange}
                                color="primary"
                                name="kubun_1"

                            />
                        }
                        label="管理"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kubun_2}
                                onChange={this.onChange}
                                color="primary"
                                name="kubun_2"

                            />
                        }
                        label="一般"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kubun_3}
                                onChange={this.onChange}
                                color="primary"
                                name="kubun_3"

                            />
                        }
                        label="業物"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kubun_4}
                                onChange={this.onChange}
                                color="primary"
                                name="kubun_4"

                            />
                        }
                        label="レオパレス除く"
                    />
                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">家賃</dt>
                    <dd className="select">
                        <div className="form-inline">
                            <FormControl variant="outlined" size="small">
                                <Select
                                    name="chinryou_from"
                                    id="chinryou_from"
                                    value={this.props.room_list_search_form.chinryou_from}
                                    size="small"
                                    onChange={this.onChangeText}
                                    style={{ fontSize: 13, width: 95 }}
                                >
                                    <MenuItem value="">下限なし</MenuItem>
                                    <MenuItem value="20000">2万円</MenuItem>
                                    <MenuItem value="25000">2.5万円</MenuItem>
                                    <MenuItem value="30000">3万円</MenuItem>
                                    <MenuItem value="35000">3.5万円</MenuItem>
                                    <MenuItem value="40000">4万円</MenuItem>
                                    <MenuItem value="45000">4.5万円</MenuItem>
                                    <MenuItem value="50000">5万円</MenuItem>
                                    <MenuItem value="55000">5.5万円</MenuItem>
                                    <MenuItem value="60000">6万円</MenuItem>
                                    <MenuItem value="65000">6.5万円</MenuItem>
                                    <MenuItem value="70000">7万円</MenuItem>
                                    <MenuItem value="75000">7.5万円</MenuItem>
                                    <MenuItem value="80000">8万円</MenuItem>
                                    <MenuItem value="85000">8.5万円</MenuItem>
                                    <MenuItem value="90000">9万円</MenuItem>
                                    <MenuItem value="95000">9.5万円</MenuItem>
                                    <MenuItem value="100000">10万円</MenuItem>
                                    <MenuItem value="110000">11万円</MenuItem>
                                    <MenuItem value="120000">12万円</MenuItem>
                                    <MenuItem value="130000">13万円</MenuItem>
                                    <MenuItem value="140000">14万円</MenuItem>
                                    <MenuItem value="150000">15万円</MenuItem>
                                    <MenuItem value="200000">20万円</MenuItem>
                                    <MenuItem value="300000">30万円</MenuItem>
                                    <MenuItem value="500000">50万円</MenuItem>
                                    <MenuItem value="1000000">100万円</MenuItem>
                                </Select>
                            </FormControl>
                            <span className="en">～</span>
                            <FormControl variant="outlined" size="small">
                                <Select
                                    name="chinryou_to"
                                    id="chinryou_to"
                                    value={this.props.room_list_search_form.chinryou_to}
                                    size="small"
                                    onChange={this.onChangeText}
                                    style={{ fontSize: 13, width: 95 }}
                                >
                                    <MenuItem value="">上限なし</MenuItem>
                                    <MenuItem value="20000">2万円</MenuItem>
                                    <MenuItem value="25000">2.5万円</MenuItem>
                                    <MenuItem value="30000">3万円</MenuItem>
                                    <MenuItem value="35000">3.5万円</MenuItem>
                                    <MenuItem value="40000">4万円</MenuItem>
                                    <MenuItem value="45000">4.5万円</MenuItem>
                                    <MenuItem value="50000">5万円</MenuItem>
                                    <MenuItem value="55000">5.5万円</MenuItem>
                                    <MenuItem value="60000">6万円</MenuItem>
                                    <MenuItem value="65000">6.5万円</MenuItem>
                                    <MenuItem value="70000">7万円</MenuItem>
                                    <MenuItem value="75000">7.5万円</MenuItem>
                                    <MenuItem value="80000">8万円</MenuItem>
                                    <MenuItem value="85000">8.5万円</MenuItem>
                                    <MenuItem value="90000">9万円</MenuItem>
                                    <MenuItem value="95000">9.5万円</MenuItem>
                                    <MenuItem value="100000">10万円</MenuItem>
                                    <MenuItem value="110000">11万円</MenuItem>
                                    <MenuItem value="120000">12万円</MenuItem>
                                    <MenuItem value="130000">13万円</MenuItem>
                                    <MenuItem value="140000">14万円</MenuItem>
                                    <MenuItem value="150000">15万円</MenuItem>
                                    <MenuItem value="200000">20万円</MenuItem>
                                    <MenuItem value="300000">30万円</MenuItem>
                                    <MenuItem value="500000">50万円</MenuItem>
                                    <MenuItem value="1000000">100万円</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </dd>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.yachin_etc_0}
                                onChange={this.onChange}
                                color="primary"
                                name="yachin_etc_0"
                            />
                        }
                        label="共益費込み"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.yachin_etc_1}
                                onChange={this.onChange}
                                color="primary"
                                name="yachin_etc_1"
                            />
                        }
                        label="駐車場料金込み"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.yachin_etc_2}
                                onChange={this.onChange}
                                color="primary"
                                name="yachin_etc_2"
                            />
                        }
                        label="礼金なし"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.yachin_etc_3}
                                onChange={this.onChange}
                                color="primary"
                                name="yachin_etc_3"
                            />
                        }
                        label="敷金なし"
                    />
                    {/* <Field name="yachinEtc_0" component={renderCheckbox} label="共益費込み" />
                        <Field name="yachinEtc_1" component={renderCheckbox} label="駐車場料金込み" />
                        <Field name="yachinEtc_2" component={renderCheckbox} label="礼金なし" />
                        <Field name="yachinEtc_3" component={renderCheckbox} label="敷金なし" /> */}
                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">間取り</dt>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_1}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_1"
                            />
                        }
                        label="1R"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_2}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_2"
                            />
                        }
                        label="1K"
                        style={{ marginLeft: 8 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_3}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_3"
                            />
                        }
                        label="1DK"
                        style={{ marginLeft: 8 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_5}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_5"
                            />
                        }
                        label="1LDK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_8}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_8"
                            />
                        }
                        label="2K"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_9}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_9"
                            />
                        }
                        label="2DK"
                        style={{ marginLeft: 8 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_11}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_11"
                            />
                        }
                        label="2LDK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_14}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_14"
                            />
                        }
                        label="3K"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_15}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_15"
                            />
                        }
                        label="3DK"
                        style={{ marginLeft: 8 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_17}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_17"
                            />
                        }
                        label="3LDK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.madori_20}
                                onChange={this.onChange}
                                color="primary"
                                name="madori_20"
                            />
                        }
                        label="4K～"
                    />
                    {/* <Field name="madori_1" component={renderCheckbox} label="1R" />
                        <Field name="madori_2" component={renderCheckbox} label="1K" style={{ marginLeft: 16 }} />
                        <Field name="madori_3" component={renderCheckbox} label="1DK" style={{ marginLeft: 16 }} />
                        <Field name="madori_4" component={renderCheckbox} label="1LDK" />
                        <Field name="madori_8" component={renderCheckbox} label="2K" />
                        <Field name="madori_9" component={renderCheckbox} label="2DK" style={{ marginLeft: 16 }} />
                        <Field name="madori_11" component={renderCheckbox} label="2LDK" />
                        <Field name="madori_14" component={renderCheckbox} label="3K" />
                        <Field name="madori_15" component={renderCheckbox} label="3DK" style={{ marginLeft: 16 }} />
                        <Field name="madori_17" component={renderCheckbox} label="3LDK" />
                        <Field name="madori_20" component={renderCheckbox} label="4K～" /> */}
                </dl>
                <dl id="check_title">

                    <dt className="side_menu_title">入居可能日</dt>
                        <div className="form-inline">
                            <TextField
                                name="nyuukyo_kanou_date"
                                id="nyuukyo_kanou_date"
                                variant="outlined"
                                size="small"
                                onChange={this.onChangeText}
                                style={{ marginTop: 0 }}
                                type="date"
                                value={this.props.room_list_search_form.nyuukyo_kanou_date}
                            />
                        </div>
                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">築年数</dt>
                    <div className="form-inline">
                        <TextField
                            name="tikunen"
                            id="tikunen"
                            variant="outlined"
                            size="small"
                            onChange={this.onChangeText}
                            style={{ marginTop: 0, width: 70 }}
                            type="number"
                            value={this.props.room_list_search_form.tikunen}
                        />年以内
                        {/* <Field
                                name="tikunen"
                                id="tikunen"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                autocomplete="off"
                                style={{ width: 70 }}
                            />年以内 */}
                    </div>
                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">築年期間指定</dt>
                    <div className="form-inline">
                        西暦
                        <TextField
                            name="tikunen1"
                            id="tikunen1"
                            variant="outlined"
                            size="small"
                            onChange={this.onChangeText}
                            type="number"
                            style={{ marginTop: 0, width: 85 }}
                            value={this.props.room_list_search_form.tikunen1}
                        />年
                        <TextField
                            name="tikunen1_month"
                            id="tikunen1_month"
                            variant="outlined"
                            size="small"
                            onChange={this.onChangeText}
                            type="number"
                            style={{ marginTop: 0, width: 70 }}
                            value={this.props.room_list_search_form.tikunen1_month}
                        />月～

                    </div>
                    <div className="form-inline">
                        <TextField
                            name="tikunen2"
                            id="tikunen2"
                            variant="outlined"
                            size="small"
                            onChange={this.onChangeText}
                            style={{ marginTop: 0, width: 85, marginLeft: 28 }}
                            value={this.props.room_list_search_form.tikunen2}
                        />年
                        <TextField
                            name="tikunen2_month"
                            id="tikunen2_month"
                            variant="outlined"
                            size="small"
                            onChange={this.onChangeText}
                            type="number"
                            style={{ marginTop: 0, width: 70 }}
                            value={this.props.room_list_search_form.tikunen2_month}
                        />月

                    </div>
                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">専有面積</dt>
                    <div className="form-inline">
                        <TextField
                            name="senyuu_u"
                            id="senyuuU"
                            variant="outlined"
                            size="small"
                            onChange={this.onChangeText}
                            value={this.props.room_list_search_form.senyuu_u}
                            style={{ marginTop: 0, width: 55 }}
                        />m²以上～
                        <TextField
                            name="senyuu_t"
                            id="senyuuT"
                            variant="outlined"
                            size="small"
                            onChange={this.onChangeText}
                            value={this.props.room_list_search_form.senyuu_t}
                            style={{ marginTop: 0, width: 55 }}
                        />m²以下
                        {/* <Field
                                name="senyuuU"
                                id="senyuuU"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                autocomplete="off"
                                style={{ width: 60 }}
                            />m²以上～
                            <Field
                                name="senyuuT"
                                id="senyuuT"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                autocomplete="off"
                                style={{ width: 60 }}
                            />m²以下 */}
                    </div>
                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">駅からの徒歩</dt>
                    <FormControl variant="outlined">
                        <Select
                            name="toho"
                            id="toho"
                            value={this.props.room_list_search_form.toho}
                            size="small"
                            onChange={this.onChangeText}
                            style={{ fontSize: 13, width: 150 }}
                        >
                            <MenuItem value="">指定なし</MenuItem>
                            <MenuItem value="3">3分</MenuItem>
                            <MenuItem value="5">5分</MenuItem>
                            <MenuItem value="10">10分</MenuItem>
                            <MenuItem value="15">15分</MenuItem>
                            <MenuItem value="30">30分</MenuItem>
                            <MenuItem value="60">60分</MenuItem>
                        </Select>
                    </FormControl>

                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">構造</dt>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kouzou_4}
                                onChange={this.onChange}
                                color="primary"
                                name="kouzou_4"

                            />
                        }
                        label="鉄骨鉄筋コンクリート"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kouzou_3}
                                onChange={this.onChange}
                                color="primary"
                                name="kouzou_3"

                            />
                        }
                        label="鉄筋コンクリート"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kouzou_1}
                                onChange={this.onChange}
                                color="primary"
                                name="kouzou_1"

                            />
                        }
                        label="木造"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kouzou_2}
                                onChange={this.onChange}
                                color="primary"
                                name="kouzou_2"

                            />
                        }
                        label="軽量鉄骨"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kouzou_14}
                                onChange={this.onChange}
                                color="primary"
                                name="kouzou_14"

                            />
                        }
                        label="重量鉄骨"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.room_list_search_form.kouzou_9}
                                onChange={this.onChange}
                                color="primary"
                                name="kouzou_9"

                            />
                        }
                        label="鉄骨造"
                    />
                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">人気のこだわり条件</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_20}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_20"
                                />
                            }
                            label="バス・トイレ別"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_20" component={renderCheckbox} label="バス・トイレ別" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_16}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_16"
                                />
                            }
                            label="インターネット使い放題"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_16" component={renderCheckbox} label="インターネット使い放題" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_45}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_45"
                                />
                            }
                            label="ペット可"
                        />
                        {/* <Field name="setsubi_45" component={renderCheckbox} label="ペット可" style={{ marginBottom: -5 }} /> */}
                    </dd>
                </dl>
                <dl id="check_title">
                    <dt className="side_menu_title">すべてのこだわり条件</dt>
                    <dt className="all_sub side_menu_title">キッチン</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_21}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_21"
                                />
                            }
                            label="システムキッチン"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_21" component={renderCheckbox} label="システムキッチン" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_91}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_91"
                                />
                            }
                            label="カウンターキッチン"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_91" component={renderCheckbox} label="カウンターキッチン" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_ih}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_ih"
                                />
                            }
                            label="IHクッキングヒーター"
                            style={{ marginBottom: -5 }}
                        />
                        {this.props.room_list_search_form.setsubi_ih ? <Select
                            name="setsubi_ih_su"
                            onChange={this.onChangeText}
                            value={this.props.room_list_search_form.setsubi_ih_su}
                        >
                            <MenuItem value={""}>-</MenuItem>
                            <MenuItem value={29}>1口付</MenuItem>
                            <MenuItem value={30}>2口付</MenuItem>
                            <MenuItem value={59}>3口付</MenuItem>
                            <MenuItem value={92}>口数不明</MenuItem>

                        </Select> : ""}
                        {/* <Field name="setsubi_IH" component={renderCheckbox} label="IHクッキングヒーター" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_2k}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_2k"
                                />
                            }
                            label="2ロ以上コンロ"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_2K" component={renderCheckbox} label="2ロ以上コンロ" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_118}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_118"
                                />
                            }
                            label="食器（洗浄）乾燥機"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_118" component={renderCheckbox} label="食器（洗浄）乾燥機" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_gkt}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_gkt"
                                />
                            }
                            label="ガスコンロ付き"
                            style={{ marginBottom: -5 }}
                        />
                        {this.props.room_list_search_form.setsubi_gkt ? <Select
                            name="setsubi_gkt_su"
                            onChange={this.onChangeText}
                            value={this.props.room_list_search_form.setsubi_gkt_su}
                        >
                            <MenuItem value={""}>-</MenuItem>
                            <MenuItem value={24}>1口付</MenuItem>
                            <MenuItem value={23}>2口付</MenuItem>
                            <MenuItem value={22}>3口付</MenuItem>
                            <MenuItem value={95}>口数不明</MenuItem>

                        </Select> : ""}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_gk}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_gk"
                                />
                            }
                            label="ガスコンロ設置可"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_GK" component={renderCheckbox} label="ガスコンロ設置可" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dt className="all_sub side_menu_title">バス・トイレ</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_3}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_3"
                                />
                            }
                            label="追焚き機能"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_3" component={renderCheckbox} label="追焚き機能" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_77}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_77"
                                />
                            }
                            label="独立洗面台"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_77" component={renderCheckbox} label="独立洗面台" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_4}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_4"
                                />
                            }
                            label="洗髪洗面化粧台"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_4" component={renderCheckbox} label="洗髪洗面化粧台" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_1}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_1"
                                />
                            }
                            label="温水洗浄便座"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_1" component={renderCheckbox} label="温水洗浄便座" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_5}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_5"
                                />
                            }
                            label="浴室乾燥機"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_5" component={renderCheckbox} label="浴室乾燥機" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_196}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_196"
                                />
                            }
                            label="脱衣所"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_5" component={renderCheckbox} label="浴室乾燥機" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dt className="all_sub side_menu_title">冷暖房</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_ac}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_ac"
                                />
                            }
                            label="エアコン"
                            style={{ marginBottom: -5 }}
                        />
                        {this.props.room_list_search_form.setsubi_ac ? <Select
                            name="setsubi_ac_su"
                            onChange={this.onChangeText}
                            value={this.props.room_list_search_form.setsubi_ac_su}
                        >
                            <MenuItem value={""}>-</MenuItem>
                            <MenuItem value={58}>1台付</MenuItem>
                            <MenuItem value={7}>2台付</MenuItem>
                            <MenuItem value={8}>3台付</MenuItem>
                            <MenuItem value={119}>4台付</MenuItem>
                            <MenuItem value={151}>5台付</MenuItem>
                            <MenuItem value={121}>6台付</MenuItem>
                            <MenuItem value={115}>台数不明</MenuItem>

                        </Select> : ""}
                        {/* <Field name="setsubi_AC" component={renderCheckbox} label="エアコン" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_127}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_127"
                                />
                            }
                            label="床暖房"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_127" component={renderCheckbox} label="床暖房" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dt className="all_sub side_menu_title">収納</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_100}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_100"
                                />
                            }
                            label="クローゼット"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_100" component={renderCheckbox} label="クローゼット" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_99}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_99"
                                />
                            }
                            label="ウォークインクローゼット"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_99" component={renderCheckbox} label="ウォークインクローゼット" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_102}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_102"
                                />
                            }
                            label="床下収納"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_102" component={renderCheckbox} label="床下収納" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_103}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_103"
                                />
                            }
                            label="トランクルーム"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_103" component={renderCheckbox} label="トランクルーム" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_143}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_143"
                                />
                            }
                            label="シューズボックス"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_143" component={renderCheckbox} label="シューズボックス" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dt className="all_sub side_menu_title">テレビ･通信</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_12}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_12"
                                />
                            }
                            label="BS端子"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_12" component={renderCheckbox} label="BS端子" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_110}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_110"
                                />
                            }
                            label="CS"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_110" component={renderCheckbox} label="CS" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_14}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_14"
                                />
                            }
                            label="光ファイバー"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_14" component={renderCheckbox} label="光ファイバー" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_9}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_9"
                                />
                            }
                            label="CATV"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_9" component={renderCheckbox} label="CATV" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dt className="all_sub side_menu_title">セキュリティ</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_37}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_37"
                                />
                            }
                            label="オートロック"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_37" component={renderCheckbox} label="オートロック" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_41}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_41"
                                />
                            }
                            label="TVモニタ付インターフォン"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_41" component={renderCheckbox} label="TVモニタ付インターフォン" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_40}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_40"
                                />
                            }
                            label="24時間セキュリティ"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_40" component={renderCheckbox} label="24時間セキュリティ" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_87}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_87"
                                />
                            }
                            label="防犯カメラ"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_87" component={renderCheckbox} label="防犯カメラ" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dt className="all_sub side_menu_title">位置</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_153}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_153"
                                />
                            }
                            label="2階以上"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_153" component={renderCheckbox} label="2階以上" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_kai}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_kai"
                                />
                            }
                            label="1階"
                            style={{ marginBottom: -5 }}
                        />
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_154}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_154"
                                />
                            }
                            label="最上階"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_154" component={renderCheckbox} label="最上階" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_109}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_109"
                                />
                            }
                            label="角部屋"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_109" component={renderCheckbox} label="角部屋" style={{ marginBottom: -5 }} /> */}
                    </dd>

                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_minami}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_minami"
                                />
                            }
                            label="南向き"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_109" component={renderCheckbox} label="角部屋" style={{ marginBottom: -5 }} /> */}
                    </dd>


                    <dt className="all_sub side_menu_title">条件</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_54}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_54"
                                />
                            }
                            label="社会人専用"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_54" component={renderCheckbox} label="社会人専用" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_51}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_51"
                                />
                            }
                            label="楽器相談"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_51" component={renderCheckbox} label="楽器相談" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.is_gakusei_yoyaku}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="is_gakusei_yoyaku"
                                />
                            }
                            label="学生予約可"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_51" component={renderCheckbox} label="楽器相談" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.is_gakusei_long_move_3}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="is_gakusei_long_move_3"
                                />
                            }
                            label="3月入居可"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_51" component={renderCheckbox} label="楽器相談" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.is_gakusei_long_move_4}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="is_gakusei_long_move_4"
                                />
                            }
                            label="4月入居可"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_51" component={renderCheckbox} label="楽器相談" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.is_foreigner}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="is_foreigner"
                                />
                            }
                            label="外国人入居可"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_51" component={renderCheckbox} label="楽器相談" style={{ marginBottom: -5 }} /> */}
                    </dd>

                    <dt className="all_sub side_menu_title">室内設備</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_69}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_69"
                                />
                            }
                            label="室内洗濯機置き場"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_69" component={renderCheckbox} label="室内洗濯機置き場" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_35}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_35"
                                />
                            }
                            label="フローリング"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_35" component={renderCheckbox} label="フローリング" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_36}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_36"
                                />
                            }
                            label="ロフト"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_36" component={renderCheckbox} label="ロフト" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_108}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_108"
                                />
                            }
                            label="メゾネット"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_108" component={renderCheckbox} label="メゾネット" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dt className="all_sub side_menu_title">建造設備</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_ev}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_ev"
                                />
                            }
                            label="エレベーター"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_EV" component={renderCheckbox} label="エレベーター" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_105}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_105"
                                />
                            }
                            label="駐輪場"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_105" component={renderCheckbox} label="駐輪場" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_104}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_104"
                                />
                            }
                            label="バイク置き場"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_104" component={renderCheckbox} label="バイク置き場" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dt className="all_sub side_menu_title">特徴</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_43}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_43"
                                />
                            }
                            label="オール電化"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_43" component={renderCheckbox} label="オール電化" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_47}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_47"
                                />
                            }
                            label="分譲タイプ"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_47" component={renderCheckbox} label="分譲タイプ" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_86}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_86"
                                />
                            }
                            label="デザイナーズ"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_86" component={renderCheckbox} label="デザイナーズ" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dt className="all_sub side_menu_title">その他</dt>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_76}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_76"
                                />
                            }
                            label="バルコニー"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_76" component={renderCheckbox} label="バルコニー" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_140}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_140"
                                />
                            }
                            label="バルコニー2面以上"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_140" component={renderCheckbox} label="バルコニー2面以上" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_39}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_39"
                                />
                            }
                            label="庭（専有庭）"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_39" component={renderCheckbox} label="庭（専有庭）" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_142}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_142"
                                />
                            }
                            label="家具付き"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_142" component={renderCheckbox} label="家具付き" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_129}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_129"
                                />
                            }
                            label="家電付き"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_129" component={renderCheckbox} label="家電付き" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_38}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_38"
                                />
                            }
                            label="宅配ボックス"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_38" component={renderCheckbox} label="宅配ボックス" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_toshigas}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_toshigas"
                                />
                            }
                            label="都市ガス"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_Toshigas" component={renderCheckbox} label="都市ガス" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_17}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_17"
                                />
                            }
                            label="P2台可"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_Toshigas" component={renderCheckbox} label="都市ガス" style={{ marginBottom: -5 }} /> */}
                    </dd>
                    <dd>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.room_list_search_form.setsubi_147}
                                    onChange={this.onChange}
                                    color="primary"
                                    name="setsubi_147"
                                />
                            }
                            label="猫相談可"
                            style={{ marginBottom: -5 }}
                        />
                        {/* <Field name="setsubi_Toshigas" component={renderCheckbox} label="都市ガス" style={{ marginBottom: -5 }} /> */}
                    </dd>
                </dl>
                {/* </form> */}

                {this.props.room_list_search_form.boshu_only ? "" : <div style={{ position: "fixed", bottom: 50, height: "50px", width: "250px", background: "white", textAlign: "center", padding: 10 }}>
                    <Button variant={"contained"} color="primary" style={{ background: "#28a745", color: "white" }} onClick={() => this.props.get_search_rooms(this.props.rent_response_id)} >検索</Button>
                </div>}

            </div >
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        search_rooms_list: state.roomlist.search_rooms_list,
        search_rooms: state.roomlist.search_rooms,
        room_list_search_form: state.roomlist.room_list_search_form,
        search_rooms_count: state.roomlist.search_rooms_count,
        responsedetail: state.responsesReducer.responsedetail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_room_list_search_form(state) {
            dispatch(RoomList.change_room_list_search_form(state))
        },
        search(state) {
            dispatch(RoomList.search(state))
        },
        get_search_rooms(state) {
            dispatch(RoomList.get_search_rooms(state))
        },
        change_room_search_list_loading(state) {
            dispatch(RoomList.change_room_search_list_loading(state))
        },
    };
}
// let timeout = null
// App = reduxForm({
//     form: 'roomlistsearchform', // a unique identifier for this form
//     validate,//バリデーション

//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(App)

export default connect(mapStateToProps, mapDispatchToProps)(App);