import React, { Component } from 'react';
import { connect } from 'react-redux';

import "react-datepicker/dist/react-datepicker.css";
import * as MailTemplate from './Saga/MailTemplate';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Autocomplete from '@mui/material/Autocomplete';
import { Row, Col } from 'reactstrap';
interface Props {

  handleSubmit: any,
  onSubmit: any,
  get_rent_contract_accounting: any,
  send_mail_details: any,
  change_send_mail_cc_disp: any,
  change_send_mail_bcc_disp: any,
  send_mail_cc_disp: number,
  send_mail_bcc_disp: number,
  send_edit_contents: number
  handleChange: any,
  change_send_edit_contents: any,
  mail_templates: any,
  mailtemplate: any
  mail_template_details: any,
  section_select: any,
  users_select: any,
  mail_template_categories: any,
  change_mail_template_details: any,
  mail_template_search: any,
}

interface Forms {
  message: string;

}

interface State {
  refresh: boolean
}


// class MailEditHeaderComponent extends Component<InjectedFormProps<Props, {}, string>>  {
class mailtemplateForm extends Component<Props, State>{
  constructor(props: any) {
    super(props);
    this.state = {
      refresh: false
    }
    // console.log(this.props.mailtemplate)
    // this.props.initialize(this.props.mailtemplate);//フォームに初期値を反映させる

    this.handleChange = this.handleChange.bind(this);
    this.handle_section_id_change = this.handle_section_id_change.bind(this);
    this.handle_user_id_change = this.handle_user_id_change.bind(this);
    this.handle_category_change = this.handle_category_change.bind(this);
  }
  handleChange(e: any) {
    let mail_template_details = this.props.mail_template_details
    mail_template_details.template_name = e.target.value
    this.props.change_mail_template_details(mail_template_details)
    this.setState({ refresh: !this.state.refresh })
  }
  handle_section_id_change(e: any, newvalue: any) {
    let mail_template_details = this.props.mail_template_details
    mail_template_details.section_id = newvalue ? newvalue.value : 0
    this.props.change_mail_template_details(mail_template_details)
    this.setState({ refresh: !this.state.refresh })
  }

  handle_user_id_change(e: any, newvalue: any) {

    let mail_template_details = this.props.mail_template_details
    mail_template_details.user_id = newvalue ? newvalue.value : 0
    this.props.change_mail_template_details(mail_template_details)
    this.setState({ refresh: !this.state.refresh })
  }
  handle_category_change(e: any, newvalue: any) {
    console.log(e.value)
    let mail_template_details = this.props.mail_template_details
    mail_template_details.mail_template_category_id = newvalue ? newvalue.value : 0
    this.props.change_mail_template_details(mail_template_details)
    this.setState({ refresh: !this.state.refresh })
  }

  render() {

    // const { handleChange } = this.props;
    // this.props.initialize(this.props.mailtemplate);//フォームに初期値を反映させる
    return (
      <div >
        {/* <form onChange={handleChange}  > */}
        <FormControl >
          <div className="searchform">
            <TextField
              name="title"
              // component="input"
              type="text"
              label="テンプレート名"
              // className="form-control"
              value={this.props.mail_template_details.template_name}
              style={{ border: "none", margin: "5px", width: "400px", marginRight: "25px", }}
              onChange={this.handleChange}
            />
          </div>

          {/* <FormControl >
          <InputLabel id="demo-simple-select-helper-label">大カテゴリ</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={this.props.mail_template_details.category_id}
            style={{ width: 150, marginRight: 5 }}
            // onChange={this.handleChange}
            name="category"
          >

            <MenuItem value={0}>
              <em>-</em>
            </MenuItem>



            {
              this.props.mail_templates.map(function (value: any) {
                return <MenuItem value={value.id}>{value.categoryName}</MenuItem>
              })}


          </Select>
        </FormControl > */}

          <Row style={{ width: 700 }}>
            <Col lg="4">
              <div className="searchform" style={{ width: 200 }} >
                {/* <Select
                  name="mail_template_category_id"
                  options={this.props.mail_template_categories}

                  value={this.props.mail_template_details.mail_template_category_id}
                  onChange={this.handle_category_change}

                  placeholder="カテゴリを選択"
                /> */}

                {this.props.mail_template_categories[0] ? <Autocomplete
                  id="combo-box-demo"
                  options={this.props.mail_template_categories}
                  getOptionLabel={(option) => option.label}
                  value={this.props.mail_template_categories.filter((a: any) => a.value === this.props.mail_template_details.mail_template_category_id)[0]}
                  style={{ width: 150 }}

                  onChange={this.handle_category_change}
                  renderInput={(params) => <TextField  {...params} label="カテゴリ" />}
                /> : ""}
              </div>
            </Col>

            <Col lg="4">
              <div className="searchform" style={{ width: 200 }}>
                {/* <Select
                  name="section_id"
                  options={this.props.section_select}
                  value={this.props.mail_template_details.section_id}
                  // value={this.props.mail_template_details.section_id}
                  onChange={this.handle_section_id_change}
                  // component={RenderSelectInput}
                  //  onChange={(a)=>this.setState({formData[sectionId]:a)}
                  //  onBlur={(a) => input.onBlur(a?a.value:0)}

                  placeholder="店舗を選択"
                /> */}
                {this.props.section_select[0] ? <Autocomplete
                  id="combo-box-demo"
                  options={this.props.section_select}
                  getOptionLabel={(option) => option.label}
                  style={{ width: 150 }}
                  value={this.props.section_select.filter((a: any) => a.value === this.props.mail_template_details.section_id)[0]}
                  onChange={this.handle_section_id_change}

                  renderInput={(params) => <TextField  {...params} label="店舗選択" />}
                /> : ""}
              </div>
            </Col>


            <Col lg="4">

              <div className="searchform" style={{ width: 200 }} >
                {/* <Select
                  name="user_id"
                  options={this.props.users_select}
                  value={this.props.mail_template_details.user_id}
                  // value={this.props.responselist.formData.primaryUserId}
                  onChange={this.handle_user_id_change}

                  placeholder="担当を選択"
                /> */}
                <Autocomplete
                  id="combo-box-demo"
                  options={this.props.users_select}
                  getOptionLabel={(option: any) => option.label}
                  style={{ width: 150 }}
                  value={this.props.users_select.filter((a: any) => a.value === this.props.mail_template_details.user_id)[0]}
                  onChange={this.handle_user_id_change}
                  renderInput={(params) => <TextField  {...params} label="担当選択" />}
                />

              </div>
            </Col>
          </Row>
        </FormControl >

      </div >
    );
  }
}



// export default  reduxForm<Props>({
//     form: 'mailheader',

// })(MailEditHeaderComponent);



const mapStateToProps = (state: any) => {
  return {
    mail_template_details: state.mailtemplate.mail_template_details,
    users_select: state.masterReducer.users_select,
    section_select: state.masterReducer.section_select,
    mail_template_categories: state.mailtemplate.mail_template_categories,
    mail_template_search: state.mailtemplate.mail_template_search,
  }
}
function mapDispatchToProps(dispatch: any) {
  return {

    change_mail_template_details(state: any) {
      dispatch(MailTemplate.change_mail_template_details(state))
    },


  };
}

export default connect(mapStateToProps, mapDispatchToProps)(mailtemplateForm);
