
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, take, select } from 'redux-saga/effects';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';

import * as YoyakuParkingApi from '../Api/YoyakuParkingApi';
import * as tm_parkingarea_api from '../Api/tm_parkingarea_api';

import * as Responses from '../../ResponseDetail/Saga/Responses';
import * as MypageLog from '../../MypageLog/Saga/MypageLog';
import * as CustomerContacts from '../../CustomerContacts/Saga/CustomerContacts';

import * as Files from '../../Files/Saga/Files';
import * as Mail from '../../Mail/Saga/Mail';
import moment from 'moment';
import { any, string } from 'prop-types';
import { idText } from 'typescript';

export interface yoyakuparkingState {
    // rent_contracts_list: any;
    // rent_sales_list: any;
    yoyaku_parking_rent_responses_id: any,
    yoyaku_parking_list: any;
    yoyaku_parking_list_search: any;
    tm_yoyakuparkingarea_list: any;
    yoyaku_parking: any;
    yoyaku_parking_top_open: boolean;
    new_create_top_open: boolean;
    new_parking_top_open:boolean;
    yoyaku_parking_detail_open: boolean;
    tm_yoyakuparkingarea_new_create_list:any;

    search_data: any;
    renraku_check:boolean;
    search_data_contacts: any;
    search_data_replace: any;
    yoyakuparking_edit:any;
    yoyaku_parking_new_create:any;
    // rent_responses_response_list: any;
    // rent_responses_coming_list: any;
    // rent_responses_contract_list: any;
    // rent_corporate_contract_by_year: any;
    // rent_corporate_contract_by_month: any;
    // rent_responses_contract_count: any;

    // rent_corporate_contacts_list: any;
    // rent_corporate_contacts: any;

    // rent_corporate_contacts_all_list: any;
    // rent_corporate_contacts_all_list_search: any;
    // rent_corporate_contacts_all: any;

    // rent_corporate_docs_list: any;
    // rent_corporate_docs: any;

    // rent_corporate_staffs_list: any;
    // rent_corporate_staffs: any;

    // rent_corporate_offices_list: any;
    // rent_corporate_offices: any;

    yoyaku_parking_list_replace: any;
    yoyaku_parking_list_replace_search: any;

    rent_m_agents_list: any;


}

const initialState: yoyakuparkingState = {

    // rent_contracts_list: [],
    // rent_sales_list: [],
    yoyaku_parking_rent_responses_id:0,
    yoyaku_parking_list: [],
    yoyaku_parking_list_search: [],
    yoyaku_parking: {},
    tm_yoyakuparkingarea_list:[],
    yoyaku_parking_top_open: false,
    new_create_top_open:false,
    new_parking_top_open:false,
    yoyaku_parking_detail_open: false,
    search_data: { name: "", },
    search_data_contacts: { name: "", user_id: "", start_date: moment().startOf('months').format("YYYY-MM-DD"), end_date: moment().endOf('months').format("YYYY-MM-DD") },
    search_data_replace: { m_corporate_name: "", select_m_corporate_id:"", customer_name: "", },
    tm_yoyakuparkingarea_new_create_list:[],
    yoyakuparking_edit:{},
    // rent_responses_response_list: [],
    // rent_responses_coming_list: [],
    // rent_responses_contract_list: [],
    // rent_corporate_contract_by_year: [],
    // rent_corporate_contract_by_month: [],
    // rent_responses_contract_count: [],

    // rent_corporate_contacts_list: [],
    // rent_corporate_contacts: [],

    // rent_corporate_contacts_all_list: [],
    // rent_corporate_contacts_all_list_search: [],
    // rent_corporate_contacts_all: [],

    // rent_corporate_docs_list: [],
    // rent_corporate_docs: [],

    // rent_corporate_staffs_list: [],
    // rent_corporate_staffs: [],

    // rent_corporate_offices_list: [],
    // rent_corporate_offices: [],
    yoyaku_parking_new_create:false,
    renraku_check:false,
    yoyaku_parking_list_replace: [],
    yoyaku_parking_list_replace_search: [],

    rent_m_agents_list: [],


    // m_corporations: any;,
    // users: [],
    // rentAgents: [],
    // rentMReasons: [],



}



///action 
const actionCreator = actionCreatorFactory();

export const change_search_data = actionCreator<any>('CHANGE_SEARCH_DATA');
export const change_renraku_check = actionCreator<any>('CHANGE_RENRAKU_CHECK');
// export const change_search_data_contacts = actionCreator<any>('CHANGE_SEARCH_DATA_CONTACTS');
export const get_yoyaku_parking_list = actionCreator('GET_YOYAKU_PARKING_LIST');
export const get_yoyakuparking_reload_list = actionCreator('GET_YOYAKU_PARKING_RELOAD_LIST');
export const change_yoyaku_parking_list = actionCreator('CHANGE_YOYAKU_PARKING_LIST');
export const get_yoyaku_parking_list_search = actionCreator('GET_YOYAKU_PARKING_LIST_SEARCH');
export const change_yoyaku_parking_list_shikichi = actionCreator('CHANGE_YOYAKU_PARKING_LIST_SHIKICHI');
export const get_yoyaku_parking_name_list_search = actionCreator('GET_YOYAKU_PARKING_NAME_LIST_SEARCH');
export const get_yoyaku_parking_tel_list_search = actionCreator('GET_YOYAKU_PARKING_TEL_LIST_SEARCH');
export const get_yoyaku_parking_renraku_list_search = actionCreator('GET_YOYAKU_PARKING_RENRAKU_LIST_SEARCH');
export const change_yoyaku_parking_list_search = actionCreator('CHANGE_YOYAKU_PARKING_LIST_SEARCH');
export const get_tm_yoyakuparkingarea_list = actionCreator('GET_TM_YOYAKUPARKINGAREA_LIST');
export const change_tm_yoyakuparkingarea_list = actionCreator('CHANGE_TM_YOYAKUPARKINGAREA_LIST');
export const change_tm_yoyakuparkingarea_new_create_list = actionCreator('CHANGE_TM_YOYAKUPARKINGAREA_NEW_CREATE_LIST');
export const get_tm_yoyakuparkingarea_detail_list = actionCreator<any>('GET_TM_YOYAKUPARKINGAREA_DETAIL_LIST');
export const get_tm_yoyakuparkingarea_new_create = actionCreator<any>('GET_TM_YOYAKUPARKINGAREA_NEW_CREATE');

export const send_tm_yoyakuparkingarea_select_list = actionCreator<any>('SEND_TM_YOYAKUPARKINGAREA_SELECT_LIST');
export const change_yoyaku_parking_top_open = actionCreator<boolean>('CHANGE_YOYAKU_PARKING_TOP_OPEN');
export const change_new_create_top_open = actionCreator<boolean>('CHANGE_NEW_CREATE_TOP_OPEN');
export const change_new_parking_top_open = actionCreator<boolean>('CHANGE_NEW_PARKING_TOP_OPEN');
export const change_yoyaku_parking_detail_open = actionCreator<boolean>('CHANGE_YOYAKU_PARKING_DETAIL_OPEN');
export const create_yoyaku_parking = actionCreator<boolean>('CREATE_YOYAKU_PARKING');
export const create_yoyaku_response = actionCreator<boolean>('CREATE_YOYAKU_RESPONSE');

// export const change_search_data_replace = actionCreator<any>('CHANGE_SEARCH_DATA_REPLACE');
export const get_change_yoyaku_parking = actionCreator('GET_YOYAKU_PARKING'); 
export const change_yoyaku_parking = actionCreator<any>('CHANGE_YOYAKUPARKING');
export const change_yoyakuparking_edit = actionCreator<any>('CHANGE_YOYAKUPARKING_EDIT')
export const update_yoyakuparking = actionCreator<any>('UPDATE_YOYAKUPARKING')
export const change_yoyaku_parking_new_create = actionCreator<any>('CHANGE_YOYAKU_PARKING_NEW_CREATE')


export const yoyakuparkingReducer = reducerWithInitialState(initialState)
    .case(change_search_data, (state, payload) => ({ ...state, search_data: payload }))
    .case(change_renraku_check, (state, payload) => ({ ...state, renraku_check: payload }))
    // .case(change_search_data_contacts, (state, payload) => ({ ...state, search_data_contacts: payload }))
    // .case(change_search_data_replace, (state, payload) => ({ ...state, search_data_replace: payload }))
    .case(change_yoyaku_parking_list, (state, payload) => ({ ...state, yoyaku_parking_list: payload }))
    .case(change_yoyaku_parking_list_search, (state, payload) => ({ ...state, yoyaku_parking_list_search: payload }))
    .case(change_tm_yoyakuparkingarea_list, (state, payload) => ({ ...state, tm_yoyakuparkingarea_list: payload }))
    .case(change_tm_yoyakuparkingarea_new_create_list, (state, payload) => ({ ...state, tm_yoyakuparkingarea_new_create_list: payload }))
    .case(change_yoyaku_parking, (state, payload) => ({ ...state, yoyaku_parking: payload }))
    .case(change_yoyaku_parking_top_open, (state, payload) => ({ ...state, yoyaku_parking_top_open: payload }))
    .case(change_new_create_top_open, (state, payload) => ({ ...state, new_create_top_open: payload }))
    .case(change_new_parking_top_open, (state, payload) => ({ ...state, new_parking_top_open: payload }))
    .case(change_yoyaku_parking_detail_open, (state, payload) => ({ ...state, yoyaku_parking_detail_open: payload }))
    .case(change_yoyaku_parking_detail_open, (state, payload) => ({ ...state, yoyaku_parking_detail_open: payload }))
    .case(change_yoyakuparking_edit, (state, payload) => ({ ...state, yoyakuparking_edit: payload }))
    .case(change_yoyaku_parking_new_create, (state, payload) => ({ ...state, yoyaku_parking_new_create: payload }))
    

// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const yoyakuparkingSaga = [
    takeEvery('GET_YOYAKU_PARKING_LIST', handle_get_yoyakuparking_list),
    takeEvery('GET_YOYAKU_PARKING_RELOAD_LIST', handle_get_yoyakuparking_reload_list),
    takeEvery('GET_YOYAKU_PARKING_LIST_SEARCH', handle_get_yoyakuparking_list_search),
    takeEvery('CHANGE_YOYAKU_PARKING_LIST_SHIKICHI', handle_change_yoyaku_parking_list_shikichi),
    takeEvery('GET_YOYAKU_PARKING_NAME_LIST_SEARCH', handle_get_yoyakuparking_name_list_search),
    takeEvery('GET_YOYAKU_PARKING_TEL_LIST_SEARCH', handle_get_yoyakuparking_tel_list_search),
    takeEvery('GET_YOYAKU_PARKING_RENRAKU_LIST_SEARCH', handle_get_yoyakuparking_renraku_list_search),
    takeEvery('GET_TM_YOYAKUPARKINGAREA_DETAIL_LIST',handle_get_tm_yoyakuparkingarea_detail_list),
    takeEvery('GET_TM_YOYAKUPARKINGAREA_NEW_CREATE',handle_get_tm_yoyakuparkingarea_new_create),
    takeEvery('SEND_TM_YOYAKUPARKINGAREA_SELECT_LIST',handle_send_tm_yoyakuparkingarea_select_list),
    takeEvery('GET_YOYAKU_PARKING',handle_get_yoyakuparking_list),
    takeEvery('UPDATE_YOYAKUPARKING',handle_update_yoyakuparking),
    takeEvery('CREATE_YOYAKU_PARKING',handle_create_yoyakuparking),
    takeEvery('CREATE_YOYAKU_RESPONSE',handle_create_yoyaku_response),
];
// export default masterSaga;


// 空き待ち予約リスト
function* handle_get_yoyakuparking_list() {
    var { payload, error } = yield call(YoyakuParkingApi.list);
    // const { payload, error } = yield call(RentMCorporationsApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log("aaaaaaaaaaaaa",payload)
        yield put(change_yoyaku_parking_list(payload))
        yield put(change_yoyaku_parking_list_search(payload))
    } else {

    }
}
// 空き待ち予約リスト
function* handle_get_yoyakuparking_reload_list(action: any) {
    var { payload, error } = yield call(YoyakuParkingApi.reloadlist, action.payload);
    // const { payload, error } = yield call(RentMCorporationsApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log("aaaaaaaaaaaaa",payload)
        yield put(change_yoyaku_parking_list(payload))
        yield put(change_yoyaku_parking_list_search(payload))
    } else {

    }
}
// 検索
function* handle_get_yoyakuparking_list_search(action: any): any {

    const state = yield select();
    
    // 文字列が一致するか
    // let rent_m_corporates_list_base=  state.corporation.rent_m_corporates_list.filter((a:any)=>{
    //     if(a.name.indexOf(action.payload) != -1) return true
    // })
    let rent_m_corporates_list_base = state.yoyakuparking.yoyaku_parking_list
    let search_data = state.yoyakuparking.search_data
    if (search_data.P_Name) {
        // rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => a.name.indexOf(search_data.name))//名前
        rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => (a.P_Name && a.P_Name.indexOf(search_data.P_Name) !== -1))//
    }

    yield put(change_yoyaku_parking_list_search(rent_m_corporates_list_base))

}
function* handle_get_yoyakuparking_name_list_search(action: any): any {

    const state = yield select();

    let rent_m_corporates_list_base = state.yoyakuparking.yoyaku_parking_list
    let search_data = state.yoyakuparking.search_data
    if (search_data.name) {
        // rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => a.name.indexOf(search_data.name))//名前
        rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => (a.Kokyaku_Name && a.Kokyaku_Name.indexOf(search_data.name) !== -1))//
    }

    yield put(change_yoyaku_parking_list_search(rent_m_corporates_list_base))

}
function* handle_get_yoyakuparking_tel_list_search(action: any): any {
    const state = yield select();
    // 文字列が一致するか
    // let rent_m_corporates_list_base=  state.corporation.rent_m_corporates_list.filter((a:any)=>{
    //     if(a.name.indexOf(action.payload) != -1) return true
    // })
    let rent_m_corporates_list_base = state.yoyakuparking.yoyaku_parking_list
    let search_data = state.yoyakuparking.search_data
    if (search_data.tel) {
        // rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => a.name.indexOf(search_data.name))//名前
        rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => (a.Tel && a.Tel.indexOf(search_data.tel) !== -1))//
    }
    yield put(change_yoyaku_parking_list_search(rent_m_corporates_list_base))

}
function* handle_update_yoyakuparking(action: any):any{
    const state = yield select();
    console.log(action.payload)
    var { payload, error } = yield call(YoyakuParkingApi.changedata,action.payload)
    if(payload && !error){
        yield put(change_yoyaku_parking(payload));
        yield put(change_yoyakuparking_edit(JSON.parse(JSON.stringify(payload))));
        handle_get_yoyakuparking_list();
    }else{
    }
}
function* handle_get_tm_yoyakuparkingarea_new_create(action: any):any {
    const state = yield select();
    var a = {searchwd:''}
    a.searchwd = action.payload
    var { payload, error } = yield call(YoyakuParkingApi.parking_info, a);
    console.log(payload)
    yield put(change_tm_yoyakuparkingarea_new_create_list(payload));
}
function* handle_get_yoyakuparking_renraku_list_search(action: any): any {
    const state = yield select();
    let rent_m_corporates_list_base = state.yoyakuparking.yoyaku_parking_list
    // let search_data = state.yoyakuparking.search_data
    if (!state.yoyakuparking.renraku_check) {
        // rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => a.name.indexOf(search_data.name))//名前
        rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => (a.Is_Renraku_Zumi == 0))//
    }
    yield put(change_yoyaku_parking_list_search(rent_m_corporates_list_base))
}

function* handle_send_tm_yoyakuparkingarea_select_list(action: any): any {
    const state = yield select();　　// store.getState()と同じ
    var P_Id = action.payload[0].p_id
    var P_Name = action.payload[0].p_name
    var PA_Id = action.payload.map((x:any) => x.pa_id).join(',')
    var PA_Name = action.payload.map((x:any) => x.pa_name).join(',')
    var contractor_kubun = action.payload[0].contractor_kubun
    var contractor_name = action.payload[0].contractor_name
    var contractor_mail = action.payload[0].contractor_mail
    var contract_date = action.payload[0].contract_date
    var selected = {P_Id:P_Id,P_Name:P_Name,PA_Id:PA_Id,PA_Name:PA_Name,contractor_kubun:contractor_kubun,contract_date:contract_date,contractor_name:contractor_name,contractor_mail:contractor_mail}
    var senddata = {selectdata:selected,client:state.yoyakuparking.yoyaku_parking}
    if(PA_Id && PA_Name){
        var { payload, error } = yield call(YoyakuParkingApi.addresponse,senddata );
        if (payload && !error) {
            yield put(Responses.change_responseid(payload.responses_id))
            yield put(Responses.change_modalResponseDetail(true))
            yield put(Responses.change_response_tabs(1))
            yield put(CustomerContacts.get_rent_customer_contacts(payload.responses_id))
            yield put(MypageLog.get_rent_mypage_logs(payload.responses_id))
            yield put(Mail.change_mail_new_template({rent_response_id:payload.responses_id,mail_template_id:326}))
        } else {
        }
    }else{
        alert('区画が選択できていません。')
    }
}
function* handle_create_yoyaku_response(action: any): any{
    const state = yield select();
    console.log(state)
    var P_Id = action.payload.P_Id
      var P_Name = action.payload.P_Name
      var PA_Id = ''
      var PA_Name = ''
      var contractor_kubun = ''
      var contract_date = ''
      var selected = {P_Id:P_Id,P_Name:P_Name,PA_Id:PA_Id,PA_Name:PA_Name,contractor_kubun:contractor_kubun,contract_date:contract_date}
      var senddata = {selectdata:selected,client:state.yoyakuparking.yoyaku_parking}
　    const { payload ,error } = yield call(YoyakuParkingApi.addresponse,senddata)
if (payload && !error) {
    yield put(Responses.change_responseid(payload.responses_id))
    yield put(Responses.change_modalResponseDetail(true))
    yield put(Responses.change_response_tabs(1))
    yield put(CustomerContacts.get_rent_customer_contacts(payload.responses_id))
    yield put(MypageLog.get_rent_mypage_logs(payload.responses_id))
    
    // let maildetail = {toMail:state.customerdetail.mail1,fromMail:"cd@global-center.co.jp",mailAccountId:11,isSended:1};
    // yield put(Mail.change_mail_new(maildetail))
    // let send_mail_details = state.send_mail_details;
    // // send_mail_details.htmlUtf8=data
    // //ここに「メール作成」を押したときの動作を書く。
    // yield put(Mail.change_send_mail_details(send_mail_details))
    
    
  } else {
  }
    // console.log(payload)
}
function* handle_create_yoyakuparking(action: any): any{
    const state = yield select();
    const { payload ,error } = yield call(YoyakuParkingApi.addyoyaku,action.payload)
    if(payload && !error){
        yield put(change_yoyaku_parking_new_create(false))
        console.log(payload)
        yield put(change_yoyaku_parking(payload))
        yield put(change_yoyakuparking_edit(JSON.parse(JSON.stringify(payload))))
    }else{

    }

}
function* handle_get_tm_yoyakuparkingarea_detail_list(action: any): any {   
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し
    // /contract/rest / parking.json'
    // let rent_contract_editor_forms = state.contracts.rent_contract_editor_forms
    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  
    // alert(rent_contract_details.tatemono_id)
    // if (rent_contract_editor_forms.counting === 1) {
  
    // } else {/
      // alert(rent_contract_editor_forms.tatemono_id)
    console.log(state.yoyakuparking)
    const { payload, error } = yield call(tm_parkingarea_api.list, state.yoyakuparking.yoyaku_parking.P_Data.P_Id);
    if (payload && !error) {
    // console.log(payload)

    let list = payload
    yield put(change_tm_yoyakuparkingarea_list(list));
    yield put(change_yoyaku_parking(state.yoyakuparking.yoyaku_parking.P_Data));
    yield put(change_yoyakuparking_edit(JSON.parse(JSON.stringify(state.yoyakuparking.yoyaku_parking.P_Data))))
    } else {
    }
}

function* handle_change_yoyaku_parking_list_shikichi(action: any): any {
    const state = yield select();

    console.log(action.payload)
    let rent_m_corporates_list_base = state.yoyakuparking.yoyaku_parking_list
    if(!action.payload){
        rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => (a.Is_Shikichi === false))
    }
    yield put(change_yoyaku_parking_list_search(rent_m_corporates_list_base))
}