import React, { Component } from 'react';

import { connect } from 'react-redux';

import './Css/TimeStateComponent.css';

let hiddenFlg;

const StateChars = [ "〇", "△", "TEL", "×" ,"定休日"];
const StateClasses = ["open","crowded","tel","closed","holiday"];

class TimeStateComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // gasu: this.props.responsedetail.gasu,
            // denki: this.props.responsedetail.denki,
            // ih: this.props.responsedetail.ih,
            // gasuka: this.props.responsedetail.gasuka,
            // denkika: this.props.responsedetail.denkika,
            // ihka: this.props.responsedetail.ihka,
            // eakon: this.props.responsedetail.eakon,
        };
    }

    render() {
 
      return (
        <div>            
            <div className={'stateCell' + ' ' + StateClasses[this.props.state]} style={{width:this.props.width,height:this.props.height,fontSize:this.props.fontSize}} >
                <span>{StateChars[this.props.state]}</span>
            </div>
        </div>
    );
  }
}



//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        // setsubi_master: state.roomsReducer.setsubi_master,

        // chomei_list: state.search.chomei_list,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {


    };
}


export default connect(mapStateToProps, mapDispatchToProps)(TimeStateComponent);