const authentication = require('../../../react-azure-adb2c2').default;

export const list = (values: any) => {
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductions/` + values;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


export const insert = (values: any) => {
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductions/`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const update = (values: any) => {
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductions/` + values.id;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}