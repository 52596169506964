import React, { Component } from 'react';
// import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as Mail from './Saga/Mail';
// import MailEditorComponent from './MailEditAreaComponent';

// import * as store from '../../index'
import '../../Css/Grid.css';
import moment from 'moment'
// import LoadingComponent from '../Layout/LoadingComponent';
const ReactDataGrid = require('react-data-grid');

// タイマー用
// let timer:any;

interface Props {
  // get_mails: any,
  mails: any,
  shops: any,
  columns: any,
  add_mails: any,
  mail_loading: number,
  toggleselect: any,
  change_mail_loading: any
}



interface State {
  columns: any
  // state types
}


const DateFormatter = (payload: any) => {
  if (payload.value) {
    return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
  } else {
    return "";
  }
};









/**
 *会計連動グリッド
 *
 * @class AccountingGridComponent
 * @extends {Component<Props, State>}
 */
class MailDraftGridComponent extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.state = {
      columns: [
        { key: 'Id', name: 'ID', width: 60, resizable: true },
        { key: 'Subject', name: '件名', width: 300, resizable: true },
        { key: 'Date', name: '日時', width: 130, resizable: true, formatter: DateFormatter },
        { key: 'FromName', name: '店舗', width: 100, resizable: true },
        { key: 'name1', name: '請求先名', width: 150, resizable: true },
        { key: 'contractMoney', name: '契約金', width: 100, resizable: true },
        { key: 'contractDate', name: '契約日', width: 100, resizable: true, formatter: DateFormatter },
        { key: 'contractMoneyAllPayDate', name: '全額入金日', width: 100, resizable: true },

      ]
    };


  }






  componentWillMount() {

    // this.props.get_mails()


  }
  getSelectedCell(event: any) {
    alert()
  }

  onRowClick = (rowIdx: any, row: any) => {

    this.props.toggleselect(row.Id)


  }


  onRowsSelected = (rows: any) => {
    console.log(rows)
    rows.forEach(function (r: any) {
      r.row.selected = true;
    });

    this.forceUpdate();

  };

  onRowsDeselected = (rows: any) => {

    rows.forEach(function (r: any) {
      r.row.selected = false;
    });
    this.forceUpdate();
  };
  onScroll(state: any) {

    if (state.rowVisibleEndIdx >= this.props.mails.length && this.props.mail_loading === 0) {

      this.props.add_mails()
    }

  }


  render() {

    return (
      <div>

        <ReactDataGrid
          // ref={node => this.grid = node}
          enableCellSelect={false}
          columns={this.state.columns}
          rowGetter={(i: number) => this.props.mails[i]}

          rowsCount={this.props.mails.length}
          headerRenderer={true}
          dragable={true}
          onScroll={this.onScroll}
          // onColumnResize={(idx, width) =>
          //     console.log(`Column ${idx} has been resized to ${width}`)
          // }
          // emptyRowsView={this.EmptyRowsView}
          onRowClick={this.onRowClick}
          rowSelection={{
            showCheckbox: true,
            enableShiftSelect: true,
            onRowsSelected: this.onRowsSelected,
            onRowsDeselected: this.onRowsDeselected,
            selectBy: {
              isSelectedKey: 'selected'
              // indexes: this.state.selectedIndexes
            }
          }}

          minHeight={300} />

      </div>
    );
  }


}



const mapStateToProps = (state: any) => {
  return {

    mails: state.mailReducer.mails,
    mail_loading: state.mailReducer.mail_loading,
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    // get_mails(state: any) {
    //   dispatch(Mail.get_mails(state))
    // },
    add_mails(state: any) {
      dispatch(Mail.add_mails(state))
    },
    change_mail_loading(state: number) {
      dispatch(Mail.change_mail_loading(state))
    },


  };
}



export default connect(mapStateToProps, mapDispatchToProps)(MailDraftGridComponent);