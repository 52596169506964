import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import { Field, reduxForm } from 'redux-form';
import '../../Css/Table.css';





class RoomDispComponent extends Component {
   
    constructor(props) {
        super(props);
      
        this.state = {
            customerinfo:this.props.forecasts,
            startDate: new Date()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        //this.onChange = this.onChange.bind(this);
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    handleTatemonoOpen(id) {
        window.open(process.env.REACT_APP_HOMESTATION4_URL+`/manage/building/detail/index?t_id=` + id, '_blank', 'noopener')
    }

    handleRoomOpen(tid,rid) {
        window.open(process.env.REACT_APP_HOMESTATION4_URL+`/manage/room/detail/index?t_id=`+tid+`&r_id=`+rid, '_blank', 'noopener')
    }



    render() {
        const today  = new Date()
        let taikyo = '';
               
               if (this.props.roomdetail.taikyo_yotei_date != null /* && val.Kagiban_Setti_Date == '0000年00月00日'*/){
                   if (new Date(this.props.roomdetail.taikyo_yotei_date) < today){
                       taikyo = this.props.roomdetail.taikyo_yotei_date
                   } else{
                       taikyo = this.props.roomdetail.taikyo_yotei_date
                   }
               } else if (this.props.roomdetail.kaiyaku_date  != null && this.props.roomdetail.kagiban_setti_date == null){
                   if (new Date(this.props.roomdetail.kaiyaku_date) < today){
                       taikyo = this.props.roomdetail.taikyo_yotei_date
                   } else {
                       taikyo =this.props.roomdetail.kaiyaku_date
                   }
               }
               
               let kagi="";
//                      
                   if (this.props.roomdetail.kagi_uketori_date != null && this.props.roomdetail.kagiban_setti_date < new Date(this.props.roomdetail.kagi_uketori_date)) {
                      kagi=kagi==""?'':'</br>';
                       kagi+='鍵受取日:'+(new Date(this.props.roomdetail.kagi_uketori_date).getFullYear() +'年'+(new Date(this.props.roomdetail.kagi_uketori_date).getMonth()+1)+'月'+new Date(this.props.roomdetail.kagi_uketori_date).getDate()+'日')
                   }
                   let kagikaigyou=kagi==""?'':'</br>';
                   kagi+=this.props.roomdetail.kagi_shozai_name!=""?kagikaigyou+this.props.roomdetail.kagi_shozai_name:"";
              
                   if (this.props.roomdetail.kagiban_setti_date != null && this.props.roomdetail.io_keiyaku_date != null && new Date(this.props.roomdetail.kagiban_setti_date) > new Date(this.props.roomdetail.io_keiyaku_date)) {
                       kagikaigyou=kagi==""?'':'</br>';
                       kagi+=this.props.roomdetail.kagiban_setti_date != null ? kagikaigyou+"鍵番設置:"+(new Date(this.props.roomdetail.kagiban_setti_date).getFullYear() +'年'+(new Date(this.props.roomdetail.kagiban_setti_date).getMonth()+1)+'月'+new Date(this.props.roomdetail.kagiban_setti_date).getDate()+'日'):""
                   }
                   if (this.props.roomdetail.kagi_ban != "") {
                        kagikaigyou=kagi==""?'':'</br>';
                       kagi+=kagikaigyou+"鍵番種類:"+this.props.roomdetail.kagi_ban
                   }
                   if (this.props.roomdetail.kagiban_tekkyo_date == null) {
                       kagikaigyou=kagi==""?'':'</br>';
                       kagi+=(this.props.roomdetail.kagiban_tekkyo_date != null && new Date(this.props.roomdetail.kagiban_tekkyo_date) > new Date(this.props.roomdetail.kaiyakuDate)) ? kagikaigyou+"鍵番撤去日"+(new Date(this.props.roomdetail.kagiban_tekkyo_date).getFullYear() +'年'+(new Date(this.props.roomdetail.kagiban_tekkyo_date).getMonth()+1)+'月'+new Date(this.props.roomdetail.kagiban_tekkyo_date).getDate()+'日'):""
                   }
                   kagikaigyou=kagi==""?'':'</br>';
                 kagi+=(this.props.roomdetail.kagibikou != '' ? kagikaigyou+'鍵備考:' : '')+this.props.roomdetail.kagibikou;





                let nyuukyo_kanoubi = '-';
               let nk_day = this.props.roomdetail.nyuukyo_kanou_day;
               if ( nk_day.indexOf('上旬') != -1) {
                nk_day = 8
               }else if ( nk_day.indexOf('中旬') != -1) {
                nk_day = 18
              }else if ( nk_day.indexOf('下旬') != -1) {
                nk_day = 28
              }
                
              //  payload.row.nyuukyoKanouYear +'/'+(payload.row.nyuukyoKanouMonth)+'/'+nk_day
              let nyuukyo_date = this.props.roomdetail.nyuukyo_kanou_year+'/'+this.props.roomdetail.nyuukyo_kanou_month+'/'+nk_day
               if (this.props.roomdetail.kuushitsu_joukyou_no == 1 || this.props.roomdetail.kuushitsu_joukyou_no == 2 || this.props.roomdetail.kuushitsu_joukyou_no== 3){
                   if (this.props.roomdetail.kaiyaku_date != null){
                    let day = new Date();
                    let nkd= day.setDate(new Date(nyuukyo_date).getDate() + 3 );
                    let bd= day.setDate(new Date(this.props.roomdetail.kaiyaku_date).getDate() + 30 );
                // let bd= date('Y/m/d', strtotime('+30 day',  strtotime($value["Kaiyaku_Date"])));
               
                           if (new Date(nyuukyo_date) > new Date()){
                               nyuukyo_kanoubi = this.props.roomdetail.nyuukyo_kanou_year > 0 ? this.props.roomdetail.nyuukyo_kanou_year+'/'+this.props.roomdetail.nyuukyo_kanou_month+'/'+this.props.roomdetail.nyuukyo_kanou_day: '即';
                           } else{
                           
                               nyuukyo_kanoubi = '即';
                           
                           }

                   } else{
                       if (new Date(nyuukyo_date) > new Date()){
                           nyuukyo_kanoubi = this.props.roomdetail.nyuukyo_kanou_year > 0 ? this.props.roomdetail.nyuukyo_kanou_year +'/'+this.props.roomdetail.nyuukyo_kanou_month+'/'+this.props.roomdetail.nyuukyo_kanou_day : '即';
                       } else{
                           nyuukyo_kanoubi = '即';
                       }
                   }
               
                       if(this.props.roomdetail.hoshuu_date != null){
                           if(new Date()< new Date(this.props.roomdetail.hoshuu_date)){
                               nyuukyo_kanoubi = '募集中';//'募集中　完工予定:' + val.checkout.Hoshuu_Date;
                           }
                       }
                       if(this.props.roomdetail.completion_at != null && this.props.roomdetail.completion_at != ''){
                           if(new Date() < new Date(this.props.roomdetail.completion_at)){
                               nyuukyo_kanoubi = '募集中';//'募集中　完工:' + val.checkout.completion_at;
                           }else{
                               nyuukyo_kanoubi = '即';
                           }
                       }
               
               }
               
               
               
               
               
               
               
               
               
               
               const time = new Date()
               const ndate = this.props.roomdetail.nyuukyoritsu_date

















        let boshu_joukyou = ((this.props.roomdetail.completion_at !== null && this.props.roomdetail.completion_at !== '')?'即':'募集中');
        if(this.props.roomdetail.kuushitsu_joukyou_no===2){
          boshu_joukyou='押さえ'
        }else if(this.props.roomdetail.kuushitsu_joukyou_no===3){
          boshu_joukyou='申込み'
        }else if(this.props.roomdetail.kuushitsu_joukyou_no===4){
          boshu_joukyou='入居中'
        }else if(this.props.roomdetail.kuushitsu_joukyou_no===5){
          boshu_joukyou='解約予定'
        }else if(this.props.roomdetail.kuushitsu_joukyou_no===6){
          boshu_joukyou='募集停止'
        }
        
        let kankou = (this.props.roomdetail.completion_at !== null && this.props.roomdetail.completion_at !== '')?(new Date(this.props.roomdetail.completion_at).getFullYear() +'/'+(new Date(this.props.roomdetail.completion_at).getMonth()+1)+'/'+new Date(this.props.roomdetail.completion_at).getDate()):(this.props.roomdetail.hoshuu_date != null ? <div>補完予定 :{(new Date(this.props.roomdetail.hoshuu_date).getFullYear() +'/'+(new Date(this.props.roomdetail.hoshuu_date).getMonth()+1)+'/'+new Date(this.props.roomdetail.hoshuu_date).getDate())}</div>:'');
                  
        if(kankou == '' && this.props.roomdetail.kaiyaku_date == null){
            if (new Date(nyuukyo_date) > new Date()){

                kankou = <div>補完予定 :{this.props.roomdetail.nyuukyo_kanou_year > 0 ? this.props.roomdetail.nyuukyo_kanou_year+'/'+this.props.roomdetail.nyuukyo_kanou_month+'/'+this.props.roomdetail.nyuukyo_kanou_day : "即"}</div>
            } else{
                kankou = this.props.roomdetail.nyuukyo_kanou_year +'/'+this.props.roomdetail.nyuukyo_kanou_month+'/'+this.props.roomdetail.nyuukyo_kanou_day
                boshu_joukyou = '即';
            }
        }

        let boshu_set =(this.props.roomdetail.kanri_kubun_no === 1 || this.props.roomdetail.kanri_kubun_no === 4) ?<div>
        {/* <div>募集状況:{boshu_joukyou} </div> */}
        <div>入居可能:{nyuukyo_kanoubi}</div>
        <div>契 約 日:{(this.props.roomdetail.io_keiyaku_date != null ?  (new Date(this.props.roomdetail.io_keiyaku_date).getFullYear() +'/'+(new Date(this.props.roomdetail.io_keiyaku_date).getMonth()+1)+'/'+new Date(this.props.roomdetail.io_keiyaku_date).getDate()): '')}</div>
        <div>解 約 日:{((this.props.roomdetail.kaiyaku_date != null) ? (new Date(this.props.roomdetail.kaiyaku_date).getFullYear() +'/'+(new Date(this.props.roomdetail.kaiyaku_date).getMonth()+1)+'/'+new Date(this.props.roomdetail.kaiyaku_date).getDate() ): '')}</div>
        <div>退去予定:{((taikyo !== "" && taikyo !== null) ?  new Date(taikyo).getFullYear() +'/'+(new Date(taikyo).getMonth()+1)+'/'+new Date(taikyo).getDate() : '')}</div>
        <div>工事完了:{kankou}</div>
        {(time < ndate ? <div>管理開始{new Date(this.props.roomdetail.nyuukyoritsu_date).getFullYear() +'/'+(new Date(this.props.roomdetail.nyuukyoritsu_date).getMonth()+1)+'/'+new Date(this.props.roomdetail.nyuukyoritsu_date).getDate()} </div>: '')}
        </div>:
        // <div><div>募集状況:{boshu_joukyou} </div>
        <div><div>入居可能:{nyuukyo_kanoubi}</div></div>
                                          
     
      return (
          <div>
                  {/* <button className="btn btn-sm btn-success float-right" style={{ display: (this.props.notedit === 1) ? 'none' : '' }} onClick={this.props.roomeditchange}>編集</button> */}
                  <table className="disp_table">
                <colgroup width='20%' />
                <colgroup width='30%' />
                <colgroup width='20%' />
                <colgroup width='30%' />
                <tbody>

                    <tr >
                        <th>物件名</th>
                        <td colSpan="3">
                        <div style={{ fontSize: 9}}>{this.props.roomdetail.tatemono_kana} 
                        </div>
                            <b style={{fontSize:"18px"}}>
                                {this.props.roomdetail.tatemono_name}
                                <IconButton size="small" aria-label="delete" onClick={() => this.handleTatemonoOpen(this.props.roomdetail.tatemono_id)}>
                                    <OpenInNewIcon color="primary" />
                                </IconButton> 
                                {this.props.roomdetail.room_name}
                                <IconButton size="small" aria-label="delete" onClick={() => this.handleRoomOpen(this.props.roomdetail.tatemono_id, this.props.roomdetail.room_id)}>
                                    <OpenInNewIcon color="primary" />
                                </IconButton> 
                            </b>
                        </td>
                    </tr>
                    <tr >
                        <th>管理区分</th>
                        <td>
                            {this.props.roomdetail.kanri_kubun_name}
                        </td>
                        <th>取引形態</th>
                        <td>
                            {/* {alert(this.props.roomdetail.shop)} */}
                            {(() => {
                                if (this.props.roomdetail.torihiki_keitai_no === 1) {
                                    return "仲介";
                                } else if (this.props.roomdetail.shop === 2) {/////////////////////////////////////////////////
                                    return "貸主";
                                }
                            })()}
                        </td>
                    </tr>
                    <tr >
                        <th>間取</th>
                        <td>
                            <b style={{fontSize:"20px"}}>{this.props.roomdetail.madori_name}</b>
                        </td>
                        <th>向き</th>
                        <td>
                            {this.props.roomdetail.room_muki_name}
                        </td>
                    </tr>
                    <tr >
                        <th>間取詳細</th>
                        <td colSpan={3}>
                            {this.props.roomdetail.madori_shousai_name}
                        </td>
                        
                    </tr>

                    


                    <tr >
                        <th>面積</th>
                        <td>
                            <div className="form-inline">
                                {this.props.roomdetail.senyuu_menseki}㎡
                            </div>
                        </td>
                        <th>用途</th>
                        <td>
                            {(() => {
                                if (this.props.roomdetail.youto_no === 1) {
                                    return "住宅";
                                } else if (this.props.roomdetail.youto_no === 2) {
                                    return "駐車場";
                                } else if (this.props.roomdetail.youto_no === 3) {
                                    return "テナント";
                                } else if (this.props.roomdetail.youto_no === 4) {
                                    return "倉庫";
                                } else if (this.props.roomdetail.youto_no === 5) {
                                    return "事業用土地";
                                } else if (this.props.roomdetail.youto_no === 6) {
                                    return "不明";
                                }
                            })()}
                        </td>
                    </tr>
                    <tr >
                        <th>募集状況</th>
                        <td>
                    {/* {boshu_joukyou} */}
                            {(() => {
                                if (this.props.roomdetail.kuushitsu_joukyou_no === 1) {
                                    return <b style={{fontSize:"16px",color:"red"}}>募集中</b>;
                                } else if (this.props.roomdetail.kuushitsu_joukyou_no === 2) {
                                    return <b style={{fontSize:"16px"}}>押さえ</b>;
                                } else if (this.props.roomdetail.kuushitsu_joukyou_no === 3) {
                                    return <b style={{fontSize:"16px"}}>申込み</b>;
                                } else if (this.props.roomdetail.kuushitsu_joukyou_no === 4) {
                                    return <b style={{fontSize:"16px"}}>入居中</b>;
                                } else if (this.props.roomdetail.kuushitsu_joukyou_no === 6) {
                                    return <b style={{fontSize:"16px"}}>募集停止</b>;
                                } else if (this.props.roomdetail.kuushitsu_joukyou_no === 100) {
                                    return <b style={{fontSize:"16px"}}>家主使用</b>;
                                } else if (this.props.roomdetail.kuushitsu_joukyou_no === 101) {
                                    return <b style={{fontSize:"16px"}}>未補修</b>;
                                }
                            })()}
                        </td>
                        <th>WEB掲載</th>
                        <td>
                            {(() => {
                                if (this.props.roomdetail.web_open === 0) {
                                    return "WEB掲載";
                                }
                            })()}
                        </td>
                    </tr>
                    <tr >
                        <th>入居可能日</th>
                        <td colSpan="3">
                            <div className="form-inline">
                            {boshu_set}
                            {/* {(() => {
                                if (this.props.roomdetail.nyuukyoKanouYear !== "") {
                                    return this.props.roomdetail.nyuukyoKanouYear + "年" + this.props.roomdetail.nyuukyoKanouMonth + "月" + (this.props.roomdetail.nyuukyoKanouDay === "上旬" || this.props.roomdetail.nyuukyoKanouDay === "中旬" || this.props.roomdetail.nyuukyoKanouDay === "下旬" ? this.props.roomdetail.nyuukyoKanouDay : this.props.roomdetail.nyuukyoKanouDay + "日");
                                }
                            })()} */}
                            </div>
                        </td>
                    </tr>
                </tbody>
              </table>
              </div>
    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomDispComponent);