import React, { Component } from 'react';
import { connect } from 'react-redux';

// import ComposedChart from '@bit/recharts.recharts.composed-chart';
import {
    Legend,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,

  } from 'recharts';

import withStyles from '@mui/styles/withStyles';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';





const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))

const dataGraph = [
  {month: '2011', '法人': 2, '個人': 2},
  {month: '2012', '法人': 1, '個人': 3},
  {month: '2013', '法人': 2, '個人': 4},
  {month: '2014', '法人': 3, '個人': 5},
  {month: '2015', '法人': 4, '個人': 6},

];


class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <div>
                <div>年計推移</div>
    <LineChart
    width={(window.innerWidth / 2) - 30} height={300}
      data={this.props.rent_corporate_contract_by_year} // 表示するデータ  
      margin={{ top: 20, right: 60, bottom: 0, left: -30 }}>
    <Legend />  // 凡例を表示(図の【売上】【総売上】)
    <XAxis // X軸
      dataKey="year" // X軸の基準となるデータ項目名
      // tickFormatter={(props) => moment(props).format('YYYY/MM/DD')} // X軸を YYYY/MM/DD 形式で表示します
    />
    <YAxis // Y軸
    type="number"
    //  unit="件"
      // domain={['dataMin', 'dataMax']}
      // ticks={[-10,-5,0,5,10,15,20,25,30]} // Y軸に表示する温度
      // unit="℃" // Y軸の単位
    />
    <CartesianGrid // ガイド線の表示
      stroke="#f5f5f5"
      // strokeDasharray="3 3"
    />
    <Tooltip // ツールチップの表示
      // labelFormatter={(props) => moment(props).format('YYYY/MM/DD(ddd)')} // ラベルの表示フォーマット（日付）
    />
    <Line // 最高気温のデータを表示
      name="法人"
      dataKey="houjin" // this.props.data のキー
      stroke="salmon" // 線の色
      unit="件" //単位
    />
    <Line // 最低気温のデータを表示
      name="個人"
      dataKey="kojin" // this.props.data のキー
      stroke="skyblue" // 線の色
      unit="件" //単位
    />
  </LineChart>

{/* 
  <LineChart width={500} height={300} data={data}>
    <XAxis dataKey="name"/>
    <YAxis/>
    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
    <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
  </LineChart> */}
            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        m_corporations_list: state.corporation.m_corporations_list,
        rent_corporate_contract_by_year: state.corporation.rent_corporate_contract_by_year,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




