import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as MailTemplate from './Saga/MailTemplate';
// import moment from 'moment';

import Button from '@mui/material/Button';

import './Css/Grid.css';
const ReactDataGrid = require('react-data-grid');



interface Props {
    mail_template_parameters: any,
    get_mail_template_parameters: any,
    paramset: any,
    display: any,
    is_text: any,
}


interface State {
    columns: any,

}

const RowRenderer = ({ renderBaseRow, ...props }: any) => {
    //console.log(props)
    //const color = props.idx % 2 ? "green" : "blue";


    let backgroundColor = props.row.kuushitsuJoukyouNo === 4 ? " row-highlight-gray" : "";
    backgroundColor = props.row.status === 4 ? " row-highlight-gray" : backgroundColor;
    //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
    return <span className={backgroundColor}>{renderBaseRow(props)}</span>;



};



class ParamGridComponent extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);


        this.getCellActions = this.getCellActions.bind(this);

        this.toggledelete = this.toggledelete.bind(this);

        this.props.get_mail_template_parameters({display:this.props.display, is_text: this.props.is_text})
        this.state = {
            // modaldelete: false,
            // rent_introduction_id: 0,

            columns: [

                { key: 'edit', name: '追加', width: 70, resizable: true },
                { key: 'name', name: '項目名', width: 100, resizable: true },
                { key: 'remarks', name: '内容', width: 400, resizable: true },

                // { key: 'updated_at', name: '更新日', width: 200, resizable: true, formatter: DateFormatter },
                // { key: 'id', name: 'id', width: 60, resizable: true },
                // { key: 'statusset', name: 'ステータス', width: 90, resizable: true, formatter: this.StatusFormatter },
                // { key: 'tatemonoName', name: '物件名', width: 180, resizable: true, formatter: this.NameFormatter },
                // { key: 'roomName', name: '部屋名', width: 70, resizable: true, formatter: this.RoomFormatter },
                // { key: 'chinRyou', name: '賃料', width: 120, resizable: true, formatter: ChinRyouFormatter },
                // { key: 'chinRyouK', name: '契約時', width: 100, resizable: true, formatter: KeiyakuFormatter },
                // { key: 'delete', name: '削除', width: 60, resizable: true },



                // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
            ]
        }



    }


    componentWillMount() {

        // this.props.get_rooms()
        // this.props.customerList()

    }
    getSelectedCell(event: any) {
        alert()
    }
    edit(event: any) {
        alert(event)
    }


    toggledelete = () => {

        // this.setState(prevState => ({
        //     modaldelete: !prevState.modaldelete,

        // }));
    }


    getCellActions(column: any, row: any) {

        if (column.key === "edit") {
            return [{
                icon: <Button variant="contained" color="primary" size="small">追加</Button>,
                callback: () => {
                    this.props.paramset(row.contents)

                }
            }]


        }
    }

    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div className="filesgrid">

                <ReactDataGrid
                    // ref={node => this.grid = node}
                    enableCellSelect={false}
                    columns={this.state.columns}
                    rowGetter={(i: number) => this.props.mail_template_parameters[i]}

                    rowsCount={this.props.mail_template_parameters.length}
                    getCellActions={this.getCellActions}
                    // headerRenderer={true}
                    dragable={true}
                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    // emptyRowsView={this.EmptyRowsView}
                    rowRenderer={RowRenderer}
                    minHeight={400}
                // onRowClick={this.props.selectsheet}
                //onCellSelected={this.getSelectedCell}
                />






                {/* <Modal isOpen={this.state.modaldelete} toggle={this.toggledelete} size="lg" fade={false}>

                    <ModalHeader toggle={this.toggledelete}>リストから削除</ModalHeader>
                    <ModalBody>
                        対象物件を紹介リストから削除します。

                        自動送信・自動送信除外を削除した場合は再度自動送信リストに加わります。
               </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.delete_introductions}>削除する</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggledelete}>キャンセル</button>
                    </ModalFooter>
                </Modal> */}


            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        // rooms: state.roomlist.rooms,
        // response_files: state.responsefiles.response_files,
        // response_file_update_disp: state.responsefiles.response_file_update_disp,
        mail_template_parameters: state.mailtemplate.mail_template_parameters,
        // responseid: state.customersReducer.responseid,
        // responsedetail: state.customersReducer.responsedetail,
        response_files_loading: state.responsefiles.response_files_loading
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_mail_template_parameters(state: any) {
            dispatch(MailTemplate.get_mail_template_parameters(state))
        },
        // get_mail_template_details(state: any) {
        //     dispatch(MailTemplate.get_mail_template_details(state))
        // },
        // delete_response_file(state: any) {
        //     dispatch(ResponseFiles.delete_response_file(state))
        // },
        // change_response_file_update_disp(state: any) {
        //     dispatch(ResponseFiles.change_response_file_update_disp(state))
        // },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParamGridComponent);