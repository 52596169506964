import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';


import { call, put, take, join, takeEvery, select } from 'redux-saga/effects';
import * as EstimatesApi from '../Api/EstimatesApi';
import * as EstimateDownloadApi from '../Api/EstimateDownloadApi';
import * as TmTatemonoListApi from '../Api/TmTatemonoListApi';
import * as SearchRoomsListApi from '../Api/SearchRoomsListApi';
import * as RentEstimateDetailApi from '../Api/RentEstimateDetailApi';

import * as BoshuChinryousApi from '../Api/BoshuChinryousApi';
import * as RentContractChecksApi from '../Api/RentContractChecksApi';
import * as SearchRoomsApi from '../Api/SearchRoomsApi';

import * as rent_contract_monies_api from '../Api/rent_contract_monies_api';


import * as BoshuSet from '../Api/BoshuSet';
import * as DefaultSet from '../Api/DefaultSet';
import * as HiwariSet from '../Api/HiwariSet';

export interface EstimatesState {
    setsubis: any;
    chinryou_koumokus: any;
    estimateData: any;
    estimate_loading: boolean;
    tatemonoes: any;
    estimate_rooms: any;
    tatemono_select: any;
    estimateMonies1: any;
    estimateMonies2: any;
    estimateMonies3: any;
    estimateMonies4: any;
    estimateMonies5: any;
    payTypes: any;
    suidouTypes: any;
    kyouekiTypes: any;
    parkingTypes: any;
    hokenTypes: any;
    payUpdateTypes: any;
    payTaikyoTypes: any;
    monthTypes: any;
    yearTypes: any;
    etcTypes: any;
    estimate_month_flg: boolean;
    estimate_onetime_flg: boolean;
    estimate_after_flg: boolean;
    estimate_sum_flg: boolean;
    onetimeKoumoku: any;
    monthKoumoku: any;
    boshu: any;
    rent_contract_checks: any;
    contract_check_message: string;
    estimate_update_flg: boolean;

    contract_detail_edit_disp: boolean;
    estimate_edit_disp: boolean;
}




const initialState: EstimatesState = {

    setsubis: [],
    chinryou_koumokus: [],
    estimateData: {},
    estimate_loading: true,
    tatemonoes: [],
    tatemono_select: [],
    estimate_rooms: [],
    estimateMonies1: [],
    estimateMonies2: [],
    estimateMonies3: [],
    estimateMonies4: [],
    estimateMonies5: [],

    monthKoumoku: [
        { id: 5, value: "駐車料（2台目）" },
        { id: 6, value: "駐車料（3台目）" },
        { id: 8, value: "自治会費" },
        { id: 7, value: "浄水器リース料" },
        { id: 46, value: "浄水シャワーリース料" },
        { id: 20, value: "インターネット料" },
        { id: 24, value: "家電" },
        { id: 26, value: "ガス料" },
        { id: 30, value: "バイク駐輪代" },
        { id: 32, value: "水道代･ガス代" },
        { id: 70, value: "駐輪場使用料" },
        { id: 56, value: "月額保証料" },
        { id: 37, value: "その他（月額費用）" },
        { id: 71, value: "その他2（月額費用）" },
        { id: 65, value: "大学負担分賃料" },
    ],
    onetimeKoumoku: [
        { id: 34, value: "駐車場敷金" },
        { id: 35, value: "駐車場礼金" },
        { id: 39, value: "車庫証明発行手数料" },
        { id: 42, value: "自治会費" },
        { id: 33, value: "保証会社保証料" },
        { id: 23, value: "楽賃初回保証料" },
        { id: 25, value: "室内消毒料" },
        { id: 22, value: "安心入居サポート" },
        { id: 15, value: "室内抗菌料" },
        { id: 13, value: "事務手数料" },
        { id: 15, value: "室内抗菌料" },
        { id: 58, value: "駐車場仲介手数料" },
        { id: 47, value: "定額クリーニング代" },
        { id: 38, value: "その他（初期費用）" },
        { id: 64, value: "フリーレント" },
        { id: 68, value: "その他2(初期費用)" },
        { id: 63, value: "初回ネット手数料" },
    ],


    payTypes: [
        { id: -1, value: "" },
        { id: 0, value: "" },
        { id: 1, value: "実費" },
        { id: 2, value: "込み" },
        { id: 3, value: "無し" },
        { id: 4, value: "WEB" }
    ],
    payUpdateTypes: [
        { id: -1, value: "" },
        { id: 3, value: "無し" },
    ],
    payTaikyoTypes: [
        { id: 1, value: "実費" },
        { id: 2, value: "込み" },
    ],
    suidouTypes: [
        { id: -1, value: "定額" },
        { id: 1, value: "実費" },
        { id: 2, value: "込み" }
    ],
    etcTypes: [
        { id: -1, value: "" },
        { id: 1, value: "実費" },
        { id: 2, value: "込み" },
        { id: 3, value: "無し" },

    ],
    kyouekiTypes: [
        { id: -1, value: "" },
        { id: 2, value: "込み" }
    ],
    parkingTypes: [
        { id: -1, value: "" },
        { id: 2, value: "込み" },
        { id: 3, value: "無し" },
    ],
    hokenTypes: [
        { id: -1, value: "" },
        { id: 2, value: "込み" },
        { id: 3, value: "無し" },
        { id: 4, value: "WEB" },
    ],
    monthTypes: [
        { id: 0, value: "" }
        , { id: 1, value: "1ヵ月" }
        , { id: 2, value: "2ヵ月" }
        , { id: 3, value: "3ヵ月" }
        , { id: 4, value: "4ヵ月" }
        , { id: 5, value: "5ヵ月" }
        , { id: 6, value: "6ヵ月" }
        , { id: 7, value: "7ヵ月" }
        , { id: 8, value: "8ヵ月" }
        , { id: 9, value: "9ヵ月" }
        , { id: 10, value: "10ヵ月" }
        , { id: 11, value: "11ヵ月" }
        , { id: 12, value: "12ヵ月" }
    ],
    yearTypes: [
        { id: 0, value: "" }
        , { id: 1, value: "1年間" }
        , { id: 2, value: "2年間" }

    ],
    boshu: [],
    rent_contract_checks: [],
    estimate_month_flg: false,
    estimate_onetime_flg: false,
    estimate_after_flg: false,
    estimate_sum_flg: false,
    contract_check_message: "",
    estimate_update_flg: false,


    contract_detail_edit_disp: false,
    estimate_edit_disp: false,
}



///action 
const actionCreator = actionCreatorFactory();

export const get_chinryou_koumokus = actionCreator<any>('GET_CHINRYOU_KOUMOKUS');
export const get_chinryou_koumokus_success = actionCreator("GET_CHINRYOU_KOUMOKUS_SUCCESS");
export const get_chinryou_koumokus_failure = actionCreator("GET_CHINRYOU_KOUMOKUS_FAILURE");


export const get_estimates = actionCreator<any>('GET_ESTIMATES');
export const change_estimateData = actionCreator<any>("CHANGE_ESTIMATEDATA");

export const change_estimate_loading = actionCreator<any>("CHANGE_ESTIMATE_LOADING");

export const get_estimate_doc = actionCreator<any>('GET_ESTIMATE_DOC');

export const get_tatemonoes = actionCreator<any>('GET_TATEMONOES');
export const get_tatemonoes_success = actionCreator("GET_TATEMONOES_SUCCESS");
export const get_tatemono_select_success = actionCreator<any>("GET_TATEMONO_SELECT_SUCCESS");



export const get_estimate_rooms = actionCreator<any>('GET_ESTIMATE_ROOMS');
export const get_estimate_rooms_success = actionCreator<any>("GET_ROOMS_ESTIMATE_SUCCESS");

export const change_estimateMonies1 = actionCreator<any>("CHANGE_ESTIMATEMONIES1");
export const change_estimateMonies2 = actionCreator<any>("CHANGE_ESTIMATEMONIES2");
export const change_estimateMonies3 = actionCreator<any>("CHANGE_ESTIMATEMONIES3");
export const change_estimateMonies4 = actionCreator<any>("CHANGE_ESTIMATEMONIES4");
export const change_estimateMonies5 = actionCreator<any>("CHANGE_ESTIMATEMONIES5");

export const change_estimate_month_flg = actionCreator<any>("CHANGE_ESTIMATE_MONTH_FLG");
export const change_estimate_onetime_flg = actionCreator<any>("CHANGE_ESTIMATE_ONETIME_FLG");
export const change_estimate_after_flg = actionCreator<any>("CHANGE_ESTIMATE_AFTER_FLG");
export const change_estimate_sum_flg = actionCreator<any>("CHANGE_ESTIMATE_SUM_FLG");

export const add_estimateMonies1 = actionCreator("ADD_ESTIMATEMONIES1");
export const add_estimateMonies2 = actionCreator("ADD_ESTIMATEMONIES2");
export const add_estimateMonies3 = actionCreator("ADD_ESTIMATEMONIES3");
export const add_estimateMonies4 = actionCreator("ADD_ESTIMATEMONIES4");
export const add_estimateMonies5 = actionCreator("ADD_ESTIMATEMONIES5");


export const update_estimateMonies = actionCreator("UPDATE_ESTIMATEMONIES");


export const check_estimates = actionCreator("CHECK_ESTIMATES");

export const get_boshu = actionCreator<any>("GET_BOSHU");
export const get_boshu_success = actionCreator("GET_BOSHU_SUCCESS");

export const get_rent_contract_checks = actionCreator<any>("GET_RENT_CONTRACT_CHECKS");
export const get_rent_contract_checks_success = actionCreator("GET_RENT_CONTRACT_CHECKS_SUCCESS");


export const auto_boshu_setting = actionCreator("AUTO_BOSHU_SETTING");
export const hiwariset = actionCreator("HIWARISET");

export const change_contract_check_message = actionCreator<string>("CHANGE_CONTRACT_CHECK_MESSAGE");


export const set_contract_checks = actionCreator("SET_CONTRACT_CHECKS");


export const change_estimate_update_flg = actionCreator<any>("CHANGE_ESTIMATE_FLG");


export const change_contract_detail_edit_disp = actionCreator<any>("CHANGE_CONTRACT_DETAIL_EDIT_DISP")
export const change_estimate_edit_disp = actionCreator<any>("CHANGE_ESTIMATE_EDIT_DISP")

// export const get_rent_sales_list = actionCreator<any>('GET_RENT_SALES_LIST');
// export const get_rent_sales_list_success = actionCreator("GET_RENT_SALES_LIST_SUCCESS");
// export const get_rent_sales_list_failure = actionCreator("GET_RENT_SALES_LIST_FAILURE");


// export const get_rent_m_sale_accounts = actionCreator<any>('GET_RENT_M_SALE_ACCOUNTS');
// export const get_rent_m_sale_accounts_success = actionCreator("GET_RENT_M_SALE_ACCOUNTS_SUCCESS");



// export const getUsers = actionCreator('USERS_REQUEST');
// export const getUsersSuccess = actionCreator("USERS_SUCCESS");
// export const getUsersFailure = actionCreator("USERS_FAILURE");

// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const estimatesReducer = reducerWithInitialState(initialState)
    .case(get_chinryou_koumokus_success, (state, payload) => ({ ...state, chinryou_koumokus: payload }))

    .case(change_estimateData, (state, payload) => ({ ...state, estimateData: payload }))
    .case(change_estimate_loading, (state, payload) => ({ ...state, estimate_loading: payload }))


    .case(get_tatemonoes_success, (state, payload) => ({ ...state, tatemonoes: payload }))
    .case(get_tatemono_select_success, (state, payload) => ({ ...state, tatemono_select: payload }))

    .case(get_estimate_rooms_success, (state, payload) => ({ ...state, estimate_rooms: payload }))

    .case(change_estimateMonies1, (state, payload) => ({ ...state, estimateMonies1: payload }))
    .case(change_estimateMonies2, (state, payload) => ({ ...state, estimateMonies2: payload }))
    .case(change_estimateMonies3, (state, payload) => ({ ...state, estimateMonies3: payload }))
    .case(change_estimateMonies4, (state, payload) => ({ ...state, estimateMonies4: payload }))
    .case(change_estimateMonies5, (state, payload) => ({ ...state, estimateMonies5: payload }))

    .case(change_estimate_month_flg, (state, payload) => ({ ...state, estimate_month_flg: payload }))
    .case(change_estimate_onetime_flg, (state, payload) => ({ ...state, estimate_onetime_flg: payload }))
    .case(change_estimate_after_flg, (state, payload) => ({ ...state, estimate_after_flg: payload }))

    .case(change_estimate_sum_flg, (state, payload) => ({ ...state, estimate_sum_flg: payload }))




    .case(get_boshu_success, (state, payload) => ({ ...state, boshu: payload }))
    .case(get_rent_contract_checks_success, (state, payload) => ({ ...state, rent_contract_checks: payload }))

    .case(change_contract_check_message, (state, payload) => ({ ...state, contract_check_message: payload }))

    .case(change_estimate_update_flg, (state, payload) => ({ ...state, estimate_update_flg: payload }))


    .case(change_contract_detail_edit_disp, (state, payload) => ({ ...state, contract_detail_edit_disp: payload }))
    .case(change_estimate_edit_disp, (state, payload) => ({ ...state, estimate_edit_disp: payload }))


// .case(get_rent_sales_list_success, (state, payload) => ({ ...state, rent_sales_list: payload }))
// .case(get_rent_m_sale_accounts_success, (state, payload) => ({ ...state, rent_m_sale_accounts: payload }))


//.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
// .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const estimatesSaga = [takeEvery('GET_ESTIMATES', handle_get_estimates),
takeEvery('GET_ESTIMATE_DOC', handle_get_estimate_doc),

takeEvery('GET_TATEMONOES', handle_get_tatemonoes),

takeEvery('GET_ESTIMATE_ROOMS', handle_get_estimate_rooms),

takeEvery('ADD_ESTIMATEMONIES1', handle_add_estimatemonies1),
takeEvery('ADD_ESTIMATEMONIES2', handle_add_estimatemonies2),
takeEvery('ADD_ESTIMATEMONIES3', handle_add_estimatemonies3),
takeEvery('ADD_ESTIMATEMONIES4', handle_add_estimatemonies4),
takeEvery('ADD_ESTIMATEMONIES5', handle_add_estimatemonies5),


takeEvery('UPDATE_ESTIMATEMONIES', handle_update_estimatemonies),


takeEvery('CHECK_ESTIMATES', handle_check_estimates),

takeEvery('GET_BOSHU', handle_get_boshu),

takeEvery('GET_RENT_CONTRACT_CHECKS', handle_get_rent_contract_checks),


takeEvery('AUTO_BOSHU_SETTING', handle_auto_boshu_setting),

takeEvery('HIWARISET', handle_hiwariset),


takeEvery('SET_CONTRACT_CHECKS', handle_set_contract_checks),




    // takeEvery('GET_RENT_M_SALE_ACCOUNTS', handle_get_rent_m_sale_accounts),

    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_estimates(action: any): any {

    const state = yield select();


    yield put(change_estimate_loading(true));
    const { payload, error } = yield call(RentEstimateDetailApi.detail, action.payload);
    //  const { payload, error }= yield call(EstimatesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        let estimateData = payload
        // alert(payload.id)
        if (payload.id == 0) {



            // estimateData=state.estimates.estimateData
            // estimateData.applicationDate=new Date()
            // estimateData.contractDate=new Date()
            estimateData.userId = state.masterReducer.user_details.id
            estimateData.sectionId = state.responsesReducer.responsedetail.sectionId
            estimateData.operationUserId = state.masterReducer.user_details.id
            estimateData.operationSectionId = state.masterReducer.user_details.amSectionId

            if (estimateData.managementCompany === 5) {//大東
                estimateData.rentMContractTypeId = 42
            } else if (estimateData.managementCompany === 7) {//積和
                estimateData.rentMContractTypeId = 43
            } else if (estimateData.managementCompany === 14) {//東建
                estimateData.rentMContractTypeId = 44
            } else {
                estimateData.rentMContractTypeId = (estimateData.division === 1 || estimateData.division === 2) ? 2 : 1
            }


            estimateData.type = 0
            estimateData.trader = "2"
            estimateData.billingClosingDate = null
            estimateData.contractPenalty = 4
            estimateData.penaltyRemarks = 4
            estimateData.contractRemarks = null
            estimateData.pets = 0
            estimateData.baseTax = 10
            estimateData.rentResponseId = state.responsesReducer.responsedetail.id



            interface tatemono {
                value: string,
                label: string,
            }
            let tatemonolist: { value: string; label: string }[] = [{
                value: payload.tatemonoId,
                label: payload.buildingName
            }];

            yield put(get_tatemonoes(payload.buildingName));
            yield put(get_tatemono_select_success(tatemonolist));
            yield put(get_estimate_rooms_success(payload.roomsViewModel));





            let estimateMonies = yield call(DefaultSet.set, state);


            yield put(change_estimateMonies1(estimateMonies.estimateMonies1))
            yield put(change_estimateMonies2(estimateMonies.estimateMonies2))
            yield put(change_estimateMonies3(estimateMonies.estimateMonies3))
            yield put(change_estimateMonies4(estimateMonies.estimateMonies4))
            yield put(change_estimateMonies5(estimateMonies.estimateMonies1))
            yield put(change_estimateData(estimateData));

            if (payload.tatemonoId > 0) {



                //   const { payloads, errors  }=  yield call(BoshuChinryousApi.list, {tatemonoId:payload.tatemonoId,roomId:payload.roomId});
                const { payloads, errors } = yield call(SearchRoomsApi.detail, { tatemonoId: estimateData.tatemonoId, roomId: estimateData.roomId });
                console.log(payloads)
                if (payloads && !errors) {
                    yield put(get_boshu_success(payloads));
                    yield put(auto_boshu_setting());
                    //   yield put(check_estimates())
                } else {
                    //    // 失敗時アクション呼び出し
                    //    yield put(loginFailure(payload));
                }
            }

        } else {
            // const options = payload.map((item:any, i:number) => ({
            //     value: item.tatemonoId,
            //     label: item.tatemonoName
            //   }))
            interface tatemono {
                value: string,
                label: string,
            }
            let tatemonolist: { value: string; label: string }[] = [{
                value: payload.tatemonoId,
                label: payload.buildingName
            }];

            //    yield put(get_tatemonoes_success(payload));
            yield put(get_tatemono_select_success(tatemonolist));
            yield put(get_estimate_rooms_success(payload.roomsViewModel));

            // alert(payload.type)

            yield put(change_estimateData(payload));


            yield put(change_estimateMonies1(estimateData.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 1)))
            yield put(change_estimateMonies2(estimateData.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 2)))
            yield put(change_estimateMonies3(estimateData.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 3)))
            yield put(change_estimateMonies4(estimateData.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 4)))
            yield put(change_estimateMonies5(estimateData.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 5)))


        }


        const { payloads, errors } = yield call(SearchRoomsApi.detail, { tatemonoId: estimateData.tatemonoId, roomId: estimateData.roomId });

        if (payloads && !errors) {


            const { payloadchecks, errorchecks } = yield call(RentContractChecksApi.list, estimateData.rentMContractTypeId);
            if (payloadchecks && !errorchecks) {
                yield put(get_boshu_success(payloads));
                yield put(get_rent_contract_checks_success(payloadchecks));
                yield put(check_estimates())
                if (payload.id == 0) {
                    yield put(set_contract_checks());
                }
                yield put(change_estimate_loading(false));

            } else {
                alert(2)
            }

        } else {
            // const { payloadchecks, errorchecks }=  yield call(RentContractChecksApi.list, estimateData.rentMContractTypeId);
            // if (payloadchecks && !errorchecks) {
            // yield put(get_rent_contract_checks_success(payloadchecks));
            // yield put(check_estimates())
            // if(payload.id==0){
            //     yield put(set_contract_checks());
            // }
            yield put(change_estimate_loading(false));


        }


    } else {

        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_get_monies(action: any): any {

    const state = yield select();

    yield put(change_estimate_loading(true));
    const { payload, error } = yield call(rent_contract_monies_api.list, action.payload);
    //  const { payload, error }= yield call(EstimatesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        let estimate_data = payload
        // alert(payload.id)
        if (payload.id === 0) {



            // estimateData=state.estimates.estimateData
            // estimateData.applicationDate=new Date()
            // estimateData.contractDate=new Date()
            estimate_data.user_id = state.masterReducer.user_details.id
            estimate_data.section_id = state.responsesReducer.responsedetail.sectionId
            estimate_data.operation_user_id = state.masterReducer.user_details.id
            estimate_data.operation_section_id = state.masterReducer.user_details.amSectionId

            if (estimate_data.managementCompany === 5) {//大東
                estimate_data.rentMContractTypeId = 42
            } else if (estimate_data.managementCompany === 7) {//積和
                estimate_data.rentMContractTypeId = 43
            } else if (estimate_data.managementCompany === 14) {//東建
                estimate_data.rentMContractTypeId = 44
            } else {
                estimate_data.rentMContractTypeId = (estimate_data.division === 1 || estimate_data.division === 2) ? 2 : 1
            }


            estimate_data.type = 0
            estimate_data.trader = "2"
            estimate_data.billingClosingDate = null
            estimate_data.contractPenalty = 4
            estimate_data.penaltyRemarks = 4
            estimate_data.contractRemarks = null
            estimate_data.pets = 0
            estimate_data.baseTax = 10
            estimate_data.rentResponseId = state.responsesReducer.responsedetail.id



            interface tatemono {
                value: string,
                label: string,
            }
            let tatemonolist: { value: string; label: string }[] = [{
                value: payload.tatemonoId,
                label: payload.buildingName
            }];

            yield put(get_tatemonoes(payload.buildingName));
            yield put(get_tatemono_select_success(tatemonolist));
            yield put(get_estimate_rooms_success(payload.roomsViewModel));





            let estimate_monies = yield call(DefaultSet.set, state);


            yield put(change_estimateMonies1(estimate_monies.estimateMonies1))
            yield put(change_estimateMonies2(estimate_monies.estimateMonies2))
            yield put(change_estimateMonies3(estimate_monies.estimateMonies3))
            yield put(change_estimateMonies4(estimate_monies.estimateMonies4))
            yield put(change_estimateMonies5(estimate_monies.estimateMonies1))
            yield put(change_estimateData(estimate_data));

            if (payload.tatemono_id > 0) {



                //   const { payloads, errors  }=  yield call(BoshuChinryousApi.list, {tatemonoId:payload.tatemonoId,roomId:payload.roomId});
                const { payloads, errors } = yield call(SearchRoomsApi.detail, { tatemono_id: estimate_monies.tatemonoId, room_id: estimate_monies.roomId });
                console.log(payloads)
                if (payloads && !errors) {
                    yield put(get_boshu_success(payloads));
                    yield put(auto_boshu_setting());
                    //   yield put(check_estimates())
                } else {
                    //    // 失敗時アクション呼び出し
                    //    yield put(loginFailure(payload));
                }
            }

        } else {

            interface tatemono {
                value: string,
                label: string,
            }
            let tatemonolist: { value: string; label: string }[] = [{
                value: payload.tatemonoId,
                label: payload.buildingName
            }];

            //    yield put(get_tatemonoes_success(payload));
            yield put(get_tatemono_select_success(tatemonolist));
            yield put(get_estimate_rooms_success(payload.roomsViewModel));

            // alert(payload.type)

            yield put(change_estimateData(payload));


            yield put(change_estimateMonies1(estimate_data.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 1)))
            yield put(change_estimateMonies2(estimate_data.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 2)))
            yield put(change_estimateMonies3(estimate_data.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 3)))
            yield put(change_estimateMonies4(estimate_data.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 4)))
            yield put(change_estimateMonies5(estimate_data.estimateMoniesViewModel.filter((a: any) => a.accountGroup == 5)))


        }


        const { payloads, errors } = yield call(SearchRoomsApi.detail, { tatemonoId: estimate_data.tatemonoId, roomId: estimate_data.roomId });

        if (payloads && !errors) {


            const { payloadchecks, errorchecks } = yield call(RentContractChecksApi.list, estimate_data.rentMContractTypeId);
            if (payloadchecks && !errorchecks) {
                yield put(get_boshu_success(payloads));
                yield put(get_rent_contract_checks_success(payloadchecks));
                yield put(check_estimates())
                if (payload.id === 0) {
                    yield put(set_contract_checks());
                }
                yield put(change_estimate_loading(false));

            } else {
                alert(2)
            }

        } else {

            yield put(change_estimate_loading(false));


        }


    } else {

        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}




function* handle_update_estimatemonies(action: any): any {
    const state = yield select();

    yield put(change_estimate_update_flg(true));
    console.log(state.estimates)

    const estimateMonies1 = state.estimates.estimateMonies1.filter((a: any) => a.rentMAccountId > 0 && (a.rentMAccountId <= 4 || a.amount !== 0 || a.rentMEtcId > 0))
    const estimateMonies2 = state.estimates.estimateMonies2.filter((a: any) => a.rentMAccountId > 0 && (a.rentMAccountId === 9 || a.rentMAccountId === 10 || a.amount !== 0 || a.rentMEtcId > 0))
    const estimateMonies3 = state.estimates.estimateMonies3.filter((a: any) => a.rentMAccountId > 0 && (a.rentMAccountId == 16 || a.amount !== 0 || a.rentMEtcId > 0))
    const estimateMonies4 = state.estimates.estimateMonies4.filter((a: any) => a.rentMAccountId > 0 && (a.rentMAccountId == 17 || a.amount !== 0 || a.rentMEtcId > 0))
    const estimateMonies5 = state.estimates.estimateMonies5.filter((a: any) => a.rentMAccountId > 0 && (a.rentMAccountId <= 4 || a.amount !== 0 || a.rentMEtcId > 0))


    let estimateData = state.estimates.estimateData

    let estimateMoniesViewModel: any = [];
    estimateMonies1.forEach(function (value: any) {
        value.tax = value.tax === 1 || value.tax === true ? 1 : 0
        estimateMoniesViewModel.push(value);
    });

    estimateMonies2.forEach(function (value: any) {
        if ((value.rentMAccountId === 9 || value.rentMAccountId === 10) && value.amount === 0 && value.rentMEtcId <= 0) {

            value.rentMEtcId = 3
        }
        value.tax = value.tax === 1 || value.tax === true ? 1 : 0
        estimateMoniesViewModel.push(value);
    });

    estimateMonies3.forEach(function (value: any) {
        value.tax = value.tax === 1 || value.tax === true ? 1 : 0
        estimateMoniesViewModel.push(value);
    });
    estimateMonies4.forEach(function (value: any) {
        value.tax = value.tax === 1 || value.tax === true ? 1 : 0
        estimateMoniesViewModel.push(value);
    });

    //   estimateMonies5.forEach(function(value:any){
    //     value.tax= value.tax===1 || value.tax===true?1:0
    //     estimateMoniesViewModel.push(value);
    //   });
    if (new Date(estimateData.contractDate).getDate() != 1) {
        estimateMonies5.forEach(function (value: any) {
            value.tax = value.tax === 1 || value.tax === true ? 1 : 0
            estimateMoniesViewModel.push(value);
        });
    }
    estimateData.estimateMoniesViewModel = estimateMoniesViewModel


    // let { payload } = action;
    const { payload, error } = yield call(RentEstimateDetailApi.update, estimateData);


    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert("更新")
        //    alert(payload.id)
        // 成功時アクション呼び出し
        estimateData.id = payload.id
        estimateData.ver = payload.ver
        yield put(change_estimateData(estimateData));

        yield put(change_estimate_update_flg(false));
        // yield put(get_estimate_list_success(payload));
        // yield put(change_estimate_list_loading(false));
    } else {
        alert("失敗")
        yield put(change_estimate_update_flg(false));
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
    //} else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    //}
}
function* handle_get_estimate_doc(action: any): any {


    const state = yield select();
    yield put(change_estimate_update_flg(true));

    const estimateMonies1 = state.estimates.estimateMonies1.filter((a: any) => a.rentMAccountId > 0 && (a.amount !== 0 || a.rentMEtcId > 0))
    const estimateMonies2 = state.estimates.estimateMonies2.filter((a: any) => a.rentMAccountId > 0 && (a.rentMAccountId === 9 || a.rentMAccountId === 10 || a.amount !== 0 || a.rentMEtcId > 0))
    const estimateMonies3 = state.estimates.estimateMonies3.filter((a: any) => a.rentMAccountId > 0 && (a.amount !== 0 || a.rentMEtcId > 0))
    const estimateMonies4 = state.estimates.estimateMonies4.filter((a: any) => a.rentMAccountId > 0 && (a.amount !== 0 || a.rentMEtcId > 0))
    const estimateMonies5 = state.estimates.estimateMonies5.filter((a: any) => a.rentMAccountId > 0 && (a.amount !== 0 || a.rentMEtcId > 0))


    let estimateData = state.estimates.estimateData
    let estimateMoniesViewModel: any = [];
    estimateMonies1.forEach(function (value: any) {
        value.tax = value.tax === 1 || value.tax === true ? 1 : 0
        estimateMoniesViewModel.push(value);
    });

    estimateMonies2.forEach(function (value: any) {
        if ((value.rentMAccountId === 9 || value.rentMAccountId === 10) && value.amount === 0 && value.rentMEtcId <= 0) {
            value.rentMEtcId = 3
        }
        value.tax = value.tax === 1 || value.tax === true ? 1 : 0
        estimateMoniesViewModel.push(value);
    });

    estimateMonies3.forEach(function (value: any) {
        value.tax = value.tax === 1 || value.tax === true ? 1 : 0
        estimateMoniesViewModel.push(value);
    });
    estimateMonies4.forEach(function (value: any) {
        value.tax = value.tax === 1 || value.tax === true ? 1 : 0
        estimateMoniesViewModel.push(value);
    });
    estimateMonies5.forEach(function (value: any) {
        value.tax = value.tax === 1 || value.tax === true ? 1 : 0
        estimateMoniesViewModel.push(value);
    });

    estimateData.estimateMoniesViewModel = estimateMoniesViewModel

    // let { payload } = action;
    const { payload, error } = yield call(RentEstimateDetailApi.update, estimateData);


    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        estimateData.id = payload.id
        estimateData.ver = payload.ver
        yield put(change_estimateData(estimateData));


        const { payloaddoc, errordoc } = yield call(EstimateDownloadApi.download, payload.id);

        // サーバーからのレスポンスデータによる分岐処理
        if (payloaddoc && !errordoc) {
            // 成功時アクション呼び出し
            // console.log(payload)
            const a = document.createElement('a');
            // ダウンロードされるファイル名
            a.download = estimateData.buildingName + estimateData.roomName + "見積書.pdf";
            a.href = URL.createObjectURL(payloaddoc);
            // ダウンロード開始
            a.click();

            //    yield put(get_rent_contract_monies_success(payload));

        } else {
            //    // 失敗時アクション呼び出し
            //    yield put(loginFailure(payload));
        }
        yield put(change_estimate_update_flg(false));
    } else {
        alert("失敗")
        yield put(change_estimate_update_flg(false));
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }







}

function* handle_get_rent_contract_checks(action: any) {

    const { payloadchecks, errorchecks } = yield call(RentContractChecksApi.list, action.payload);

    if (payloadchecks && !errorchecks) {

        yield put(get_rent_contract_checks_success(payloadchecks));

        yield put(check_estimates())
        // yield put(change_estimate_loading(false));

    } else {
        alert(5)
    }

}


function* handle_get_tatemonoes(action: any) {

    const { payload, error } = yield call(TmTatemonoListApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)

        const options = payload.map((item: any, i: number) => ({
            value: item.tatemonoId,
            label: item.tatemonoName
        }))

        yield put(get_tatemonoes_success(payload));
        yield put(get_tatemono_select_success(options));

    } else {
        alert("dd")

        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_get_estimate_rooms(action: any) {

    const { payload, error } = yield call(SearchRoomsListApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        //   alert(action.payload)
        yield put(get_estimate_rooms_success(payload));

    } else {
        alert("dd")

        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}





function* handle_add_estimatemonies1(action: any): any {

    const state = yield select();

    let estimateMonies1 = state.estimates.estimateMonies1

    if (action.payload === 0) {

        if (estimateMonies1.filter((a: any) => a.rentMAccountId === 0).length === 0) {

            yield put(change_estimateMonies1(estimateMonies1.concat({
                rentContractId: 0,
                rentMAccountId: 0,
                name: "",
                displayName: 0,
                billing: 0,
                amount: 0,
                amountMonth: 0,
                eachMonth: 0,
                eachYear: 0,
                accountGroup: 1,
                tax: 0,
                taxAmount: 0,
                rentMEtcId: 0,
                isDailyRate: 0,
                isFreeRent: 0,
                works: 0,
                salesAmount: 0,
                remarks: null,
                createdUser: 0,
                createdAt: null,
                updatedAt: null,
                updatedUser: 0,
            })))
        }
    } else if (action.payload === 7) {//浄水器

        let result = estimateMonies1.filter((a: any) => a.rentMAccountId !== 7)

        yield put(change_estimateMonies1(result.concat({
            rentContractId: 0,
            rentMAccountId: 7,
            name: "浄水器リース料",
            displayName: 0,
            billing: 0,
            amount: 1400,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 1,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        })))

    } else if (action.payload === 46) {//浄水シャワー

        let result = estimateMonies1.filter((a: any) => a.rentMAccountId !== 46)

        yield put(change_estimateMonies1(result.concat({
            rentContractId: 0,
            rentMAccountId: 46,
            name: "浄水シャワーリース料",
            displayName: 0,
            billing: 0,
            amount: 1300,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 1,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        })))

    }
    yield put(hiwariset())
}
function* handle_add_estimatemonies2(action: any): any {

    const state = yield select();

    let estimateMonies2 = state.estimates.estimateMonies2
    let estimateMonies1 = state.estimates.estimateMonies1
    console.log(estimateMonies2)
    if (action.payload === 0) {

        if (estimateMonies2.filter((a: any) => a.rentMAccountId === 0).length === 0) {

            yield put(change_estimateMonies2(estimateMonies2.concat({
                rentContractId: 0,
                rentMAccountId: 0,
                name: "",
                displayName: 0,
                billing: 0,
                amount: 0,
                amountMonth: 0,
                eachMonth: 0,
                eachYear: 0,
                accountGroup: 2,
                tax: 0,
                taxAmount: 0,
                rentMEtcId: 0,
                isDailyRate: 0,
                isFreeRent: 0,
                works: 0,
                salesAmount: 0,
                remarks: null,
                createdUser: 0,
                createdAt: null,
                updatedAt: null,
                updatedUser: 0,
            })))
        }
    } else if (action.payload === 2) {//保険単身1年

        const result = estimateMonies2.map(function (value: any) {
            if (value.rentMAccountId === 11) {
                value.amount = 11000
                value.tax = 0
                value.taxAmount = 0
                value.amountMonth = 0
                value.rentMEtcId = -1
            }
            return value;
        });

        yield put(change_estimateMonies2(result))
    } else if (action.payload === 3) {//保険単身2年

        const result = estimateMonies2.map(function (value: any) {
            if (value.rentMAccountId === 11) {
                value.amount = 18000
                value.tax = 0
                value.taxAmount = 0
                value.amountMonth = 0
                value.rentMEtcId = 0
            }
            return value;
        });

        yield put(change_estimateMonies2(result))
    } else if (action.payload === 4) {//保険ファ１年

        const result = estimateMonies2.map(function (value: any) {
            if (value.rentMAccountId === 11) {
                value.amount = 13500
                value.tax = 0
                value.taxAmount = 0
                value.amountMonth = 0
                value.rentMEtcId = 0
            }
            return value;
        });

        yield put(change_estimateMonies2(result))
    } else if (action.payload === 5) {//保険ファ2年

        const result = estimateMonies2.map(function (value: any) {
            if (value.rentMAccountId === 11) {
                value.amount = 22000
                value.tax = 0
                value.taxAmount = 0
                value.amountMonth = 0
                value.rentMEtcId = 0
            }
            return value;
        });

        yield put(change_estimateMonies2(result))

    } else if (action.payload === 1) {//安心
        let result = estimateMonies2.filter((a: any) => a.rentMAccountId !== 22)

        yield put(change_estimateMonies2(result.concat({
            rentContractId: 0,
            rentMAccountId: 22,
            name: "安心入居サポート",
            displayName: 0,
            billing: 0,
            amount: 15000,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 2,
            tax: 1,
            taxAmount: 1500,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        })))
    } else if (action.payload === 6) {//抗菌
        let result = estimateMonies2.filter((a: any) => a.rentMAccountId !== 15)

        yield put(change_estimateMonies2(result.concat({
            rentContractId: 0,
            rentMAccountId: 15,
            name: "室内抗菌料",
            displayName: 0,
            billing: 0,
            amount: 12000,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 2,
            tax: 1,
            taxAmount: 1200,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        })))
    } else if (action.payload === 7) {//消毒
        let result = estimateMonies2.filter((a: any) => a.rentMAccountId !== 25)

        yield put(change_estimateMonies2(result.concat({
            rentContractId: 0,
            rentMAccountId: 25,
            name: "室内消毒料",
            displayName: 0,
            billing: 0,
            amount: 12000,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 2,
            tax: 1,
            taxAmount: 1200,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        })))
    } else if (action.payload === 14) {//鍵交換

        const result = estimateMonies2.map(function (value: any) {

            if (value.rentMAccountId === 14) {
                value.amount = 12000
                value.tax = 1
                value.taxAmount = 1200
                value.amountMonth = 0
                value.rentMEtcId = 0
            }
            return value;
        });

        yield put(change_estimateMonies2(result))
        yield put(check_estimates())

    } else if (action.payload === 12) {//仲介手数料
        const total = estimateMonies1.reduce((p: any, x: any) => p + ((x.rentMAccountId === 1 || x.rentMAccountId === 4 || x.rentMAccountId === 5 || x.rentMAccountId === 6) ? parseInt(x.amount) : 0), 0)

        const result = estimateMonies2.map(function (value: any) {
            if (value.rentMAccountId === 12) {
                value.amount = total
                value.tax = 1
                value.taxAmount = Math.round(total * 0.1)
                value.amountMonth = 0
                value.rentMEtcId = 0
            }
            return value;
        });

        yield put(change_estimateMonies2(result))
        yield put(check_estimates())
    }
}










function* handle_add_estimatemonies3(action: any): any {

    const state = yield select();

    let estimateMonies3 = state.estimates.estimateMonies3

    if (action.payload === 59) {
        let result = estimateMonies3.filter((a: any) => a.rentMAccountId !== 59)

        yield put(change_estimateMonies3(result.concat({
            rentContractId: 0,
            rentMAccountId: 59,
            name: "継続保証料",
            displayName: 0,
            billing: 0,
            amount: 10000,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 1,
            accountGroup: 3,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        })))
    } else if (action.payload === 16) {//更新料
        const result = estimateMonies3.map(function (value: any) {
            if (value.rentMAccountId === 16) {
                value.rentMEtcId = 0
                value.amount = 15000
                value.taxAmount = 0
                value.eachYear = 2
            }
            return value;
        });

        yield put(change_estimateMonies3(result))
    }

}



function* handle_add_estimatemonies4(action: any): any {

    const state = yield select();

    let estimateMonies4 = state.estimates.estimateMonies4

    if (action.payload === 19) {//退去時美装代
        let result = estimateMonies4.map(function (value: any) {
            if (value.rentMAccountId === 17) {
                value.rentMEtcId = 2

                value.amountMonth = 0

                value.eachMonth = 0

            }
            return value;
        });


        if (result.filter((a: any) => a.rentMAccountId === 27).length === 0) {
            result = result.concat({
                rentContractId: 0,
                rentMAccountId: 27,
                name: "退去時美装代",
                displayName: 0,
                billing: 0,
                amount: 0,
                amountMonth: 0,
                eachMonth: 0,
                eachYear: 0,
                accountGroup: 4,
                tax: 0,
                taxAmount: 0,
                rentMEtcId: 0,
                typeDisp: "",
                isDailyRate: 0,
                isFreeRent: 0,
                works: 0,
                salesAmount: 0,
                remarks: null,
                createdUser: 0,
                createdAt: null,
                updatedAt: null,
                updatedUser: 0,
            })
        }
        console.log(result)
        yield put(change_estimateMonies4(result))
    } else if (action.payload === 15) {//実費
        const result = estimateMonies4.map(function (value: any) {
            if (value.rentMAccountId === 17) {
                value.rentMEtcId = 1
                value.typeDisp = "実費"
                value.amountMonth = 0

                value.eachMonth = 0
            }
            return value;
        });

        yield put(change_estimateMonies4(result))
    } else if (action.payload === 16) {//込み
        const result = estimateMonies4.map(function (value: any) {
            if (value.rentMAccountId === 17) {
                value.rentMEtcId = 2
                value.amount = 0
                // value.typeDisp="込み"
                value.amountMonth = 0

                value.eachMonth = 0
            }
            return value;
        });
        console.log(result)

        yield put(change_estimateMonies4(result))
    } if (action.payload === 17) {//敷引１
        let result = estimateMonies4.map(function (value: any) {
            if (value.rentMAccountId === 17) {
                value.rentMEtcId = 2
                // value.typeDisp="込み"
                value.amountMonth = 0

                value.eachMonth = 0
            }
            return value;
        });

        result = result.filter((a: any) => a.rentMAccountId !== 21)

        result = result.concat({
            rentContractId: 0,
            rentMAccountId: 21,
            name: "敷引額",
            displayName: 0,
            billing: 0,
            amount: state.estimates.estimateMonies1.filter((a: any) => a.rentMAccountId === 1)[0].amount,
            amountMonth: 1,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 4,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            typeDisp: "",
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        })

        console.log(result)
        yield put(change_estimateMonies4(result))
    } if (action.payload === 18) {//敷引2
        let result = estimateMonies4.map(function (value: any) {
            if (value.rentMAccountId === 17) {
                value.rentMEtcId = 2
                value.typeDisp = "込み"
                value.amountMonth = 0

                value.eachMonth = 0
            }
            return value;
        });

        result = result.filter((a: any) => a.rentMAccountId !== 21)

        result = result.concat({
            rentContractId: 0,
            rentMAccountId: 21,
            name: "敷引額",
            displayName: 0,
            billing: 0,
            amount: state.estimates.estimateMonies1.filter((a: any) => a.rentMAccountId === 1)[0].amount * 2,
            amountMonth: 2,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 4,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            typeDisp: "",
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        })

        console.log(result)
        yield put(change_estimateMonies4(result))
    }

}

function* handle_add_estimatemonies5(action: any): any {

    const state = yield select();

    let estimateMonies5 = state.estimates.estimateMonies5

    if (action.payload === 0) {

        if (estimateMonies5.filter((a: any) => a.rentMAccountId === 0).length === 0) {

            yield put(change_estimateMonies5(estimateMonies5.concat({
                rentContractId: 0,
                rentMAccountId: 0,
                name: "",
                displayName: 0,
                billing: 0,
                amount: 0,
                amountMonth: 0,
                eachMonth: 0,
                eachYear: 0,
                accountGroup: 5,
                tax: 0,
                taxAmount: 0,
                rentMEtcId: 0,
                isDailyRate: 0,
                isFreeRent: 0,
                works: 0,
                salesAmount: 0,
                remarks: null,
                createdUser: 0,
                createdAt: 0,
                updatedAt: 0,
                updatedUser: 0,
            })))
        }
    }
}





function* handle_get_boshu(action: any) {


    const { payloads, errors } = yield call(SearchRoomsApi.detail, action.payload);
    // const { payloads, errors }=  yield call(SearchRoomsApi.detail, {tatemonoId:estimateData.tatemonoId,roomId:estimateData.roomId});

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    console.log(payloads)
    if (payloads && !errors) {
        yield put(get_boshu_success(payloads));

        yield put(check_estimates())
        return 1
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
        return 2
    }


}



function* handle_check_estimates(): any {

    const state = yield select();
    const estimateData = state.estimates.estimateData
    let estimateMonies1 = state.estimates.estimateMonies1
    let estimateMonies2 = state.estimates.estimateMonies2
    let estimateMonies3 = state.estimates.estimateMonies3
    let estimateMonies4 = state.estimates.estimateMonies4
    let estimateMonies5 = state.estimates.estimateMonies5
    let contract_check_message = ""

    const onemonth_amount = estimateMonies1.filter((a: any) => a.rentMAccountId === 1)[0].amount


    estimateMonies1.map(function (value: any) {
        value.taxAmount = (value.tax === 1 || value.tax === true) ? Math.round(value.amount * 0.1) : 0
        value.checkAmount = 0
        value.checkRemarks = ""
        value.billing = parseInt(estimateData.type) === 0 ? 0 : value.billing


        return value
    })

    estimateMonies2.map(function (value: any) {
        value.amount = value.amountMonth > 0 ? onemonth_amount * value.amountMonth : value.amount
        value.taxAmount = (value.tax === 1 || value.tax === true) ? Math.round(value.amount * 0.1) : 0
        value.checkAmount = 0
        value.checkRemarks = ""
        value.billing = parseInt(estimateData.type) === 0 ? 0 : value.billing
        return value
    })
    estimateMonies3.map(function (value: any) {
        value.checkAmount = 0
        value.checkRemarks = ""
        value.taxAmount = (value.tax === 1 || value.tax === true) ? Math.round(value.amount * 0.1) : 0
        value.billing = parseInt(estimateData.type) === 0 ? 0 : value.billing
        return value
    })
    estimateMonies4.map(function (value: any) {
        value.amount = value.amountMonth > 0 ? onemonth_amount * value.amountMonth : value.amount
        value.taxAmount = (value.tax === 1 || value.tax === true) ? Math.round(value.amount * 0.1) : 0
        value.checkAmount = 0
        value.checkRemarks = ""
        value.billing = parseInt(estimateData.type) === 0 ? 0 : value.billing
        return value
    })
    estimateMonies5.map(function (value: any) {
        value.amount = value.amountMonth > 0 ? onemonth_amount * value.amountMonth : value.amount
        value.taxAmount = (value.tax === 1 || value.tax === true) ? Math.round(value.amount * 0.1) : 0
        value.checkAmount = 0
        value.checkRemarks = ""
        value.billing = parseInt(estimateData.type) === 0 ? 0 : value.billing
        return value
    })

    estimateMonies1.map(function (value: any) {
        console.log(value)
        // console.log(state.estimates.boshu.moniesViewModel.filter((a:any)=>a.rentMAccountId==value.rentMAccountId)[0])

        if (state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0]) {
            if (value.amount != state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amount) {
                value.checkAmount = 2  //募集賃料と相違
                value.checkRemarks = "募集賃料" + state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amount + "円と相違しています"  //募集賃料と相違

                return value
            } else {
                value.checkRemarks = ""
                value.checkAmount = 0
                return value
            }
        } else {
            value.checkRemarks = ""
            value.checkAmount = 0
            return value
        }





    })

    estimateMonies1 = estimateMonies1.filter((a: any) => a.rentMAccountId !== -1)

    estimateMonies1 = estimateMonies1.concat({
        rentContractId: 0,
        rentMAccountId: -1,
        name: "小計",
        displayName: 0,
        billing: 0,
        amount: estimateMonies1.reduce((p: any, x: any) => p + (x.isFreeRent === 0 ? parseInt(x.amount) : 0), 0),
        amountMonth: 0,
        eachMonth: 0,
        eachYear: 0,
        accountGroup: 5,
        tax: 0,
        taxAmount: estimateMonies1.reduce((p: any, x: any) => p + (x.isFreeRent !== 1 ? parseInt(x.taxAmount) : 0), 0),
        rentMEtcId: 0,
        isDailyRate: 0,
        isFreeRent: 0,
        works: 0,
        salesAmount: 0,
        remarks: null,
        createdUser: 0,
        createdAt: 0,
        updatedAt: 0,
        updatedUser: 0,
    })

    yield put(change_estimateMonies1(estimateMonies1))



    estimateMonies2.map(function (value: any) {
        console.log(value)
        console.log(state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0])



        const tyukaitotal = estimateMonies1.reduce((p: any, x: any) => p + ((x.rentMAccountId === 1 || x.rentMAccountId === 4 || x.rentMAccountId === 5 || x.rentMAccountId === 6) ? parseInt(x.amount) : 0), 0)

        if (value.rentMAccountId === 12 && (value.amount) !== tyukaitotal) {
            value.checkAmount = 1  //仲介手数料と相違
            value.checkRemarks = "仲介手数料が" + tyukaitotal + "円と相違しています"  //仲介手数料と相違
            contract_check_message += "仲介手数料が" + tyukaitotal + "円と相違しています<br/>"

            return value
        } else if (state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0]) {
            if (value.amount != state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amount && value.rentMAccountId !== 11) {
                value.checkAmount = 2  //募集賃料と相違
                value.checkRemarks = "募集賃料" + state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amount + "円と相違しています<br/>"  //募集賃料と相違

                return value
            }
        } else {
            return value
        }
    })



    estimateMonies3.map(function (value: any) {

        if (state.estimates.boshu.moniesViewModel.filter((a: any) => a.tatemonoId == estimateData.tatemonoId && a.roomId == estimateData.roomId && a.rentMAccountId == value.rentMAccountId)[0]) {
            if (value.amount != state.estimates.boshu.moniesViewModel.filter((a: any) => a.chinryouKoumokuNo == value.rentMAccountId)[0].amount || value.rentMEtcId !== state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].rentMEtcId) {
                value.checkAmount = 2  //募集賃料と相違
                value.checkRemarks = "募集賃料" + state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amount + "円と相違しています"  //募集賃料と相違

                return value
            }
        } else {
            return value
        }
    })


    estimateMonies4.map(function (value: any) {

        if (state.estimates.boshu.moniesViewModel.filter((a: any) => a.tatemonoId == estimateData.tatemonoId && a.roomId == estimateData.roomId && a.rentMAccountId == value.rentMAccountId)[0]) {
            if (value.amount != state.estimates.boshu.moniesViewModel.filter((a: any) => a.chinryouKoumokuNo == value.rentMAccountId)[0].amount || value.rentMEtcId !== state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].rentMEtcId) {
                value.checkAmount = 2  //募集賃料と相違
                value.checkRemarks = "募集賃料" + state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amount + "円と相違しています"  //募集賃料と相違

                return value
            }
        } else {
            return value
        }
    })






    const rent_contract_checks = state.estimates.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id == state.estimates.estimateData.rentMContractTypeId)

    // console.log(state.estimates.rentMContractTypeId)
    //     alert()

    // alert(rent_contract_checks[0].rate)

    let contractPenalty = 4
    rent_contract_checks.map(function (value: any) {
        // if(value.category===1){
        //     if(estimateMonies1.filter((a:any)=>a.rentMAccountId===value.accountId)[0]){

        //         contract_check_message += state.masterReducer.tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.accountId)[0].chinryouKoumokuName
        //         alert(state.masterReducer.tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.accountId)[0].chinryouKoumokuName)


        //     }else{

        //     }
        //     }

        let estimateMonies = []
        if (value.category === 6) {//違約金


            contractPenalty = value.account_id

        } else if (value.category === 7) {//フリーレント
            if ((estimateMonies1.filter((a: any) => (a.rentMAccountId == 1 || a.rentMAccountId == 2 || a.rentMAccountId == 4 || a.rentMAccountId == 5 || a.rentMAccountId == 6) && a.amount !== 0 && a.isFreeRent == 0).length +
                estimateMonies5.filter((a: any) => (a.rentMAccountId == 1 || a.rentMAccountId == 2 || a.rentMAccountId == 4 || a.rentMAccountId == 5 || a.rentMAccountId == 6) && a.amount !== 0 && a.isFreeRent == 0).length)
                > 0) {
                contract_check_message += "初回賃料がフリーレントになっていません<br/>"

                // estimateMonies.filter((a:any)=>a.rentMAccountId===value.accountId)[0].checkAmount=1  
                // estimateMonies.filter((a:any)=>a.rentMAccountId===value.accountId)[0].checkRemarks="初回賃料がフリーレントになっていません。" 
            }



        } else {
            if (value.category === 1) {//月
                estimateMonies = estimateMonies1
            } else if (value.category === 2) {//一時金
                estimateMonies = estimateMonies2

            } else if (value.category === 3) {//一時金
                estimateMonies = estimateMonies3

            } else if (value.category === 4) {//一時金
                estimateMonies = estimateMonies4

            }


            let monthtotal = estimateMonies1.reduce((p: any, x: any) => p + (x.rentMAccountId != -1 ? parseInt(x.amount + x.taxAmount) : 0), 0)

            if (value.account_id === 56) {
                monthtotal = estimateMonies1.reduce((p: any, x: any) => p + (x.rentMAccountId != -1 && x.rentMAccountId !== 56 ? parseInt(x.amount + x.taxAmount) : 0), 0)
            }





            if (estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0]) {

                if (value.base === 2) {
                    contract_check_message += state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を削除してください<br/>"
                } else if (value.base === 5) {
                    if (estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].rentMEtcId !== 3) {
                        contract_check_message += state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を無しにしてください<br/>"
                        estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].checkAmount = 1
                        estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].checkRemarks = state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を無しにしてください"
                    }
                } else if (value.base === 4) {///込み

                    if (estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].rentMEtcId !== 2) {
                        contract_check_message += state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を込みにしてください<br/>"
                    }
                } else if (value.base === 1) {


                    let amount = estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount
                    if (value.rate > 0) {
                        if (value.rate_calc === 1) {

                            amount = Math.floor(monthtotal * value.rate / 100)

                        } else if (value.rate_calc === 2) {
                            amount = Math.ceil(monthtotal * value.rate / 100)
                        } else {
                            amount = Math.round(monthtotal * value.rate / 100)
                        }

                    }

                    if (value.max_amount > 0) {
                        amount = amount > value.max_amount ? value.max_amount : amount
                    }
                    if (value.min_amount > 0) {
                        amount = amount < value.min_amount ? value.min_amount : amount
                    }

                    if (value.fix_amount > 0) {
                        amount = value.fix_amount
                    }


                    if (amount !== estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount) {
                        contract_check_message += state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "が違います" + amount + "円<br/>"
                        estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].checkAmount = 1
                        estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].checkRemarks = state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "が違います" + amount + "円"
                    }


                }
            } else {

                if (value.base === 1) {




                    contract_check_message += state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を設定してください<br/>"
                }


            }
        }
    })


    estimateMonies2 = estimateMonies2.filter((a: any) => a.rentMAccountId !== -1)
    estimateMonies2 = estimateMonies2.concat({
        rentContractId: 0,
        rentMAccountId: -1,
        name: "小計",
        displayName: 0,
        billing: 0,
        amount: estimateMonies2.reduce((p: any, x: any) => p + (x.isFreeRent === 0 ? parseInt(x.amount) : 0), 0),
        amountMonth: 0,
        eachMonth: 0,
        eachYear: 0,
        accountGroup: 5,
        tax: 0,
        taxAmount: estimateMonies2.reduce((p: any, x: any) => p + (x.isFreeRent === 0 ? parseInt(x.taxAmount) : 0), 0),
        rentMEtcId: 0,
        isDailyRate: 0,
        isFreeRent: 0,
        works: 0,
        salesAmount: 0,
        remarks: null,
        createdUser: 0,
        createdAt: 0,
        updatedAt: 0,
        updatedUser: 0,
    })
    yield put(change_estimateMonies2(estimateMonies2))



    estimateMonies5 = estimateMonies5.filter((a: any) => a.rentMAccountId !== -1)
    estimateMonies5 = estimateMonies5.concat({
        rentContractId: 0,
        rentMAccountId: -1,
        name: "小計",
        displayName: 0,
        billing: 0,
        amount: estimateMonies5.reduce((p: any, x: any) => p + (x.isFreeRent === 0 ? parseInt(x.amount) : 0), 0),
        amountMonth: 0,
        eachMonth: 0,
        eachYear: 0,
        accountGroup: 5,
        tax: 0,
        taxAmount: estimateMonies5.reduce((p: any, x: any) => p + (x.isFreeRent === 0 ? parseInt(x.taxAmount) : 0), 0),
        rentMEtcId: 0,
        isDailyRate: 0,
        isFreeRent: 0,
        works: 0,
        salesAmount: 0,
        remarks: null,
        createdUser: 0,
        createdAt: 0,
        updatedAt: 0,
        updatedUser: 0,
    })
    yield put(change_estimateMonies5(estimateMonies5))



    if ((state.estimates.boshu.netGyosha == 1 || state.estimates.boshu.netGyosha == 2 || state.estimates.boshu.netGyosha == 368) && state.estimates.boshu.tatemonoId != 2114) {//初回ネット手数料

        if (state.estimates.estimateMonies2.filter((a: any) => a.rentMAccountId === 63)[0]) {
            if (state.estimates.estimateMonies2.filter((a: any) => a.rentMAccountId === 63)[0].amount + state.estimates.estimateMonies2.filter((a: any) => a.rentMAccountId === 63)[0].taxAmount !== 5500)
                contract_check_message += "初回ネット手数料の金額が違います<br/>"
        } else {
            contract_check_message += "初回ネット手数料を上げてください<br/>"
        }
    }
    if (state.estimates.boshu.setsubiViewModels.filter((a: any) => a.setsubiNo === 155)[0]) {
        if (state.estimates.estimateMonies1.filter((a: any) => a.rentMAccountId === 7)[0]) {
            contract_check_message += "浄水器設置不可の物件です<br/>"
        }
    }

    if (state.estimates.boshu.kaketsukeTou) {
        if (state.estimates.estimateMonies2.filter((a: any) => a.rentMAccountId === 22)[0]) {
            contract_check_message += "かけつけ加入の物件です<br/>"
        }
    }else{
     
            if (state.estimates.estimateMonies2.filter((a: any) => a.rentMAccountId === 22)[0]) {
                if (state.estimates.estimateMonies2.filter((a: any) => a.rentMAccountId === 22)[0].amount + state.estimates.estimateMonies2.filter((a: any) => a.rentMAccountId === 22)[0].taxAmount !== 16500)
                    contract_check_message += "安心入居サポート手数料の金額が違います<br/>"
            } else {
                if(estimateData.division === 1 || estimateData.division === 2){
                contract_check_message += "安心入居サポート手数料を上げてください<br/>"
            }
            }
   
    }
    // if(state.estimates.boshu.penalty){
    let room_penalty = 0
    let penalty = contractPenalty

    if (contractPenalty != 1) {//違約金で建物情報のあるものの違約金は募集条件を優先
        room_penalty = state.estimates.boshu.penalty == 0 ? 4 : state.estimates.boshu.penalty;


        penalty = state.masterReducer.m_penalties.filter((a: any) => a.id === room_penalty)[0].penaltyOrder > state.masterReducer.m_penalties.filter((a: any) => a.id === contractPenalty)[0].penaltyOrder ? room_penalty : contractPenalty; //一年未満違約金より厳しいもの優先
    }
    // alert(contractPenalty)
    // alert(penalty)
    if (estimateData.contractPenalty !== penalty) {
        contract_check_message += "違約金を" + state.masterReducer.m_penalties.filter((a: any) => a.id === penalty)[0].penaltyName + "に設定してください<br/>";
    }
    // }




    yield put(hiwariset())

    yield put(change_contract_check_message(contract_check_message))


    yield put(change_estimate_month_flg(true))

    yield put(change_estimate_onetime_flg(true))
    yield put(change_estimate_after_flg(true))

    yield put(change_estimate_sum_flg(true))

}







function* handle_auto_boshu_setting(): any {
    const state = yield select();
    let estimateData = state.estimates.estimateData
    let tm_chinryou_koumokus = state.masterReducer.tm_chinryou_koumokus
    let estimateMonies = yield call(DefaultSet.set, state);
    yield put(change_estimateData(estimateData));




    let estimateMonies1 = estimateMonies.estimateMonies1


    //    estimateMonies1 =  estimateMonies1.map(function( value:any ) {
    //     if(state.estimates.boshu.moniesViewModel.filter((a:any)=>a.accountGroup===1).filter((a:any)=>a.rentMAccountId==value.rentMAccountId)[0]){
    //     const boshu =  state.estimates.boshu.moniesViewModel.filter((a:any)=> a.rentMAccountId==value.rentMAccountId)[0]
    // //    console.log(boshu)

    //     value.amount =  boshu.amount?boshu.amount:0
    //     // value.tax =tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.rentMAccountId)[0].isKazei?1:0
    //     value.tax =boshu.tax
    //     value.taxAmount =value.tax===1?Math.round(value.amount*0.1):0
    //     value.billing =0
    //     value.amountMonth =boshu.amountMonth?boshu.amountMonth:0
    //     value.eachMonth =boshu.eachMonth?boshu.eachMonth:0
    //     value.accountGroup =1
    //     value.rentMEtcId=boshu.rentMEtcId?boshu.rentMEtcId:0
    //     value.isDailyRate=tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.rentMAccountId)[0].isHiwari!==null?1:0
    //     value.isFreeRent=0
    // }else{
    //     estimateMonies1.push({
    //         rentContractId:0,
    //         rentMAccountId:value.rentMAccountId,
    //         name:tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.rentMAccountId)[0].chinryouKoumokuName,
    //         displayName:tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.rentMAccountId)[0].chinryouKoumokuName,
    //         billing:0,
    //         amount:value.amount,
    //         amountMonth:value.amountMonth?value.amountMonth:0,
    //         eachMonth:value.eachMonth?value.eachMonth:0,
    //         eachYear:value.eachYear?value.eachYear:0,
    //         accountGroup:1,
    //         tax: tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.rentMAccountId)[0].isKazei?1:0,
    //         taxAmount: tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.rentMAccountId)[0].isKazei?Math.round(value.seikyuuGaku*0.1):0,
    //         rentMEtcId:value.rentMEtcId>0?value.rentMEtcId:0,
    //         isDailyRate:tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.rentMAccountId)[0].isHiwari?1:0,
    //         isFreeRent:0,
    //     　　works:0,
    //         salesAmount:0,
    //         remarks:null,
    //         createdUser:0,
    //         createdAt:null,
    //         updatedAt:null,
    //         updatedUser:0,
    //     })


    // }
    //         return value 

    // })


    estimateMonies1 = state.estimates.boshu.moniesViewModel.filter((a: any) => a.accountGroup === 1).map(function (value: any) {
        if (estimateMonies1.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0]) {
            const boshu = state.estimates.boshu.moniesViewModel.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0]
            //    console.log(boshu)

            value.amount = boshu.amount ? boshu.amount : 0
            // value.tax =tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.rentMAccountId)[0].isKazei?1:0
            value.tax = boshu.tax
            value.taxAmount = value.tax === 1 ? Math.round(value.amount * 0.1) : 0
            value.billing = 0
            value.amountMonth = boshu.amountMonth ? boshu.amountMonth : 0
            value.eachMonth = boshu.eachMonth ? boshu.eachMonth : 0
            value.accountGroup = 1
            value.rentMEtcId = boshu.rentMEtcId ? boshu.rentMEtcId : 0
            value.isDailyRate = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari !== null ? 1 : 0
            value.isFreeRent = 0
        } else {
            estimateMonies1.push({
                rentContractId: 0,
                rentMAccountId: value.rentMAccountId,
                name: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                displayName: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                billing: 0,
                amount: value.amount,
                amountMonth: value.amountMonth ? value.amountMonth : 0,
                eachMonth: value.eachMonth ? value.eachMonth : 0,
                eachYear: value.eachYear ? value.eachYear : 0,
                accountGroup: 1,
                tax: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? 1 : 0,
                taxAmount: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? Math.round(value.seikyuuGaku * 0.1) : 0,
                rentMEtcId: value.rentMEtcId > 0 ? value.rentMEtcId : 0,
                isDailyRate: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari ? 1 : 0,
                isFreeRent: 0,
                works: 0,
                salesAmount: 0,
                remarks: null,
                createdUser: 0,
                createdAt: null,
                updatedAt: null,
                updatedUser: 0,
            })


        }
        return value

    })


    yield put(change_estimateMonies1(estimateMonies1))



    console.log(estimateMonies1)
    console.log("dafewfafea2")

    let estimateMonies5 = estimateMonies.estimateMonies5
    estimateMonies1.map(function (value: any) {
        if (estimateMonies5.filter((a: any) => a.rentMAccountId === value.rentMAccountId)[0]) {

            let est5 = estimateMonies5.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0]

            est5.amount = value.amount !== 0 ? value.amount : 0
            est5.tax = value.tax
            est5.taxAmount = value.taxAmount
            est5.billing = 0
            est5.amountMonth = value.amountMonth
            est5.eachMonth = value.eachMonth
            est5.accountGroup = 5
            est5.rentMEtcId = value.rentMEtcId

            est5.isDailyRate = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari ? 1 : 0

        } else {
            if (value.rentMAccountId) {
                estimateMonies5.push({
                    rentContractId: 0,
                    rentMAccountId: value.rentMAccountId,
                    name: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    displayName: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    billing: value.billing,
                    amount: value.amount,
                    amountMonth: value.amountMonth,
                    eachMonth: value.eachMonth,
                    eachYear: value.eachYear,
                    accountGroup: 5,
                    tax: value.tax,
                    taxAmount: value.amount,
                    rentMEtcId: value.rentMEtcId,
                    isDailyRate: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari ? 1 : 0,
                    isFreeRent: 0,
                    works: 0,
                    salesAmount: 0,
                    remarks: null,
                    createdUser: 0,
                    createdAt: null,
                    updatedAt: null,
                    updatedUser: 0,
                })

            }
        }
    });
    console.log(estimateMonies1)
    console.log("dafewfafea")

    yield put(change_estimateMonies5(estimateMonies5))

    console.log(estimateMonies1)
    console.log("dafewfafea3")

    const total = estimateMonies1.reduce((p: any, x: any) => p + ((x.rentMAccountId === 1 || x.rentMAccountId === 4 || x.rentMAccountId === 5 || x.rentMAccountId === 6) ? parseInt(x.amount) : 0), 0)
    let estimateMonies2 = estimateMonies.estimateMonies2
    // let estimateMonies2 =  estimateData.estimateMoniesViewModel.filter((a:any)=>a.accountGroup==2)
    // estimateMonies2.map(function( value:any ) {
    //     if(state.estimates.boshu.moniesViewModel.filter((a:any)=> a.rentMAccountId==value.rentMAccountId)[0]){
    //         const boshu =  state.estimates.boshu.moniesViewModel.filter((a:any)=> a.rentMAccountId==value.rentMAccountId)[0]
    //    console.log(boshu)

    //         value.amount =  boshu.amount>0?boshu.amount:boshu.amountMonth>0?boshu.amountMonth*estimateMonies1.filter((a:any)=>a.rentMAccountId==1)[0].amount:0
    //         // value.tax =tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.rentMAccountId)[0].isKazei?1:0
    //         value.tax =boshu.tax

    //         value.taxAmount =value.tax===1?Math.round(value.amount*0.1):0
    //         value.billing =0
    //         value.amountMonth =boshu.amountMonth!==null?boshu.amountMonth:0
    //         value.eachMonth =boshu.amountMonth!==null?boshu.amountMonth:0
    //         value.accountGroup =2
    //         value.rentMEtcId=boshu.rentMEtcId>0?boshu.rentMEtcId:0
    //         // value.isDailyRate=1
    //         value.isFreeRent=0

    //      }

    //     if(value.rentMAccountId===12){
    //     value.amount =total
    //     value.taxAmount =Math.round(total*0.1)
    //     value.tax =1
    // }


    //         return value 

    // })

    state.estimates.boshu.moniesViewModel.filter((a: any) => a.accountGroup === 2).map(function (value: any) {

        if (!estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0]) {
            if (value.rentMAccountId) {
                estimateMonies2.push({
                    rentContractId: 0,
                    rentMAccountId: value.rentMAccountId,
                    name: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    displayName: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    billing: 0,
                    amount: value.amount,
                    amountMonth: value.amountMonth ? value.amountMonth : 0,
                    eachMonth: value.eachMonth ? value.eachMonth : 0,
                    eachYear: value.eachYear ? value.eachYear : 0,
                    accountGroup: 2,
                    tax: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? 1 : 0,
                    taxAmount: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? Math.round(value.seikyuuGaku * 0.1) : 0,
                    rentMEtcId: value.rentMEtcId > 0 ? value.rentMEtcId : 0,
                    isDailyRate: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari ? 1 : 0,
                    isFreeRent: 0,
                    works: 0,
                    salesAmount: 0,
                    remarks: null,
                    createdUser: 0,
                    createdAt: null,
                    updatedAt: null,
                    updatedUser: 0,
                })
            }
        } else {

            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].name = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].displayName = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].billing = 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amount = value.amount
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amountMonth = value.amountMonth ? value.amountMonth : 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].eachMonth = value.eachMonth ? value.eachMonth : 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].eachYear = value.eachYear ? value.eachYear : 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].tax = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? 1 : 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].taxAmount = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? Math.round(value.seikyuuGaku * 0.1) : 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].rentMEtcId = value.rentMEtcId > 0 ? value.rentMEtcId : 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].isDailyRate = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari ? 1 : 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].isFreeRent = 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].works = 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].salesAmount = 0
            estimateMonies2.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].remarks = null

        }
        return value

    })

    yield put(change_estimateMonies2(estimateMonies2))



    let estimateMonies3 = estimateMonies.estimateMonies3
    console.log(state.estimates.boshu.moniesViewModel)

    state.estimates.boshu.moniesViewModel.filter((a: any) => a.accountGroup === 3).map(function (value: any) {

        if (!estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0]) {
            if (value.rentMAccountId) {

                estimateMonies3.push({
                    rentContractId: 0,
                    rentMAccountId: value.rentMAccountId,
                    name: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    displayName: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    billing: 0,
                    amount: value.amount,
                    amountMonth: value.amountMonth ? value.amountMonth : 0,
                    eachMonth: value.eachMonth ? value.eachMonth : 0,
                    eachYear: value.eachYear ? value.eachYear : 0,
                    accountGroup: 2,
                    tax: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? 1 : 0,
                    taxAmount: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? Math.round(value.seikyuuGaku * 0.1) : 0,
                    rentMEtcId: value.rentMEtcId > 0 ? value.rentMEtcId : 0,
                    isDailyRate: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari ? 1 : 0,
                    isFreeRent: 0,
                    works: 0,
                    salesAmount: 0,
                    remarks: null,
                    createdUser: 0,
                    createdAt: null,
                    updatedAt: null,
                    updatedUser: 0,
                })
            }
        } else {

            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].name = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].displayName = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].billing = 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amount = value.amount
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amountMonth = value.amountMonth ? value.amountMonth : 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].eachMonth = value.eachMonth ? value.eachMonth : 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].eachYear = value.eachYear ? value.eachYear : 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].tax = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? 1 : 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].taxAmount = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? Math.round(value.seikyuuGaku * 0.1) : 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].rentMEtcId = value.rentMEtcId > 0 ? value.rentMEtcId : 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].isDailyRate = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari ? 1 : 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].isFreeRent = 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].works = 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].salesAmount = 0
            estimateMonies3.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].remarks = null

        }
        return value

    })

    yield put(change_estimateMonies3(estimateMonies3))

    let estimateMonies4 = estimateMonies.estimateMonies4
    state.estimates.boshu.moniesViewModel.filter((a: any) => a.accountGroup === 4).map(function (value: any) {

        if (!estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0]) {
            if (value.rentMAccountId) {
                estimateMonies4.push({
                    rentContractId: 0,
                    rentMAccountId: value.rentMAccountId,
                    name: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    displayName: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    billing: 0,
                    amount: value.amount,
                    amountMonth: value.amountMonth ? value.amountMonth : 0,
                    eachMonth: value.eachMonth ? value.eachMonth : 0,
                    eachYear: value.eachYear ? value.eachYear : 0,
                    accountGroup: 2,
                    tax: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? 1 : 0,
                    taxAmount: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? Math.round(value.seikyuuGaku * 0.1) : 0,
                    rentMEtcId: value.hokaSeikyuuNo > 0 ? value.hokaSeikyuuNo : 0,
                    isDailyRate: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari ? 1 : 0,
                    isFreeRent: 0,
                    works: 0,
                    salesAmount: 0,
                    remarks: null,
                    createdUser: 0,
                    createdAt: null,
                    updatedAt: null,
                    updatedUser: 0,
                })
            }
        } else {

            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].name = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].displayName = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].billing = 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amount = value.amount
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].amountMonth = value.amountMonth ? value.amountMonth : 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].eachMonth = value.eachMonth ? value.eachMonth : 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].eachYear = value.eachYear ? value.eachYear : 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].tax = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? 1 : 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].taxAmount = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isKazei ? Math.round(value.seikyuuGaku * 0.1) : 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].rentMEtcId = value.rentMEtcId > 0 ? value.rentMEtcId : 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].isDailyRate = tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].isHiwari ? 1 : 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].isFreeRent = 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].works = 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].salesAmount = 0
            estimateMonies4.filter((a: any) => a.rentMAccountId == value.rentMAccountId)[0].remarks = null

        }
        return value

    })

    yield put(change_estimateMonies4(estimateMonies4))


    yield put(check_estimates())
    yield put(set_contract_checks());

}


function* handle_hiwariset(): any {
    const state = yield select();
    let estimateData = state.estimates.estimateData
    let tm_chinryou_koumokus = state.masterReducer.tm_chinryou_koumokus


    const rent_contract_checks = state.estimates.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id == state.estimates.estimateData.rentMContractTypeId)


    let estimateMonies5: any = []
    if (new Date(estimateData.contractDate).getDate() != 1) {

        state.estimates.estimateMonies1.map(function (value: any) {

            let amount = value.amount
            let taxAmount = value.taxAmount
            let isDailyRate = 0
            let hiwari_calc = rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0] ? rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0].hiwari_calc : 0

            if (value.rentMAccountId !== 8 && value.rentMAccountId !== 61 && value.rentMAccountId > 0) {

                const dt = new Date(estimateData.contractDate)
                const monthlastday = new Date(dt.getFullYear(), dt.getMonth() + 1, 0).getDate()
                const thisday = dt.getDate()

                amount = Math.round(state.estimates.estimateMonies1.filter((a: any) => a.rentMAccountId === value.rentMAccountId)[0].amount * (monthlastday - thisday + 1) / monthlastday)
                if (hiwari_calc === 1) {
                    amount = Math.floor(state.estimates.estimateMonies1.filter((a: any) => a.rentMAccountId === value.rentMAccountId)[0].amount * (monthlastday - thisday + 1) / monthlastday)
                } else if (hiwari_calc === 2) {
                    amount = Math.ceil(state.estimates.estimateMonies1.filter((a: any) => a.rentMAccountId === value.rentMAccountId)[0].amount * (monthlastday - thisday + 1) / monthlastday)
                }
                taxAmount = (value.tax === 1 || value.tax === true) ? Math.round(amount * 0.1) : 0
                isDailyRate = 1
            }



            // if(value.rentMAccountId!==24 && value.rentMAccountId!==7 && value.rentMAccountId!==46){
            //     value.accountGroup=5
            //      return value
            // }

            if (value.rentMAccountId !== 24 && value.rentMAccountId !== 7 && value.rentMAccountId !== 46 && value.rentMAccountId > 0) {




                estimateMonies5.push({
                    rentContractId: 0,
                    rentMAccountId: value.rentMAccountId,
                    name: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    displayName: tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rentMAccountId)[0].chinryouKoumokuName,
                    billing: state.estimates.estimateMonies5.filter((a: any) => a.rentMAccountId === value.rentMAccountId)[0] ? state.estimates.estimateMonies5.filter((a: any) => a.rentMAccountId === value.rentMAccountId)[0].billing : value.billing,
                    amount: amount,
                    amountMonth: value.amountMonth,
                    eachMonth: value.eachMonth,
                    eachYear: value.eachYear,
                    accountGroup: 5,
                    tax: value.tax,
                    taxAmount: taxAmount,
                    rentMEtcId: value.rentMEtcId,
                    isDailyRate: isDailyRate,
                    isFreeRent: state.estimates.estimateMonies5.filter((a: any) => a.rentMAccountId === value.rentMAccountId)[0] ? state.estimates.estimateMonies5.filter((a: any) => a.rentMAccountId === value.rentMAccountId)[0].isFreeRent : value.isFreeRent,
                    works: 0,
                    salesAmount: 0,
                    remarks: null,
                    createdUser: 0,
                    createdAt: null,
                    updatedAt: null,
                    updatedUser: 0,
                })
            }
        })
        estimateMonies5 = estimateMonies5.filter((a: any) => a.rentMAccountId !== -1)
        estimateMonies5 = estimateMonies5.concat({
            rentContractId: 0,
            rentMAccountId: -1,
            name: "小計",
            displayName: 0,
            billing: 0,
            amount: estimateMonies5.reduce((p: any, x: any) => p + (x.isFreeRent !== 1 ? parseInt(x.amount) : 0), 0),
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 5,
            tax: 0,
            taxAmount: estimateMonies5.reduce((p: any, x: any) => p + (x.isFreeRent !== 1 ? parseInt(x.taxAmount) : 0), 0),
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: 0,
            updatedAt: 0,
            updatedUser: 0,
        })

    }
    yield put(change_estimateMonies5(estimateMonies5))
    // yield put(change_estimateMonies5(estimateMonies5))

    // yield put(check_estimates())
}


//契約形態の内容を反映
function* handle_set_contract_checks(): any {
    const state = yield select();

    const estimateData = state.estimates.estimateData
    let estimateMonies1 = state.estimates.estimateMonies1
    let estimateMonies2 = state.estimates.estimateMonies2
    let estimateMonies3 = state.estimates.estimateMonies3
    let estimateMonies4 = state.estimates.estimateMonies4
    let estimateMonies5 = state.estimates.estimateMonies5



    let contract_check_message = ""
    const rent_contract_checks = state.estimates.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id == state.estimates.estimateData.rentMContractTypeId)
    let contractPenalty = 4
    let isFreeRentset = false
    rent_contract_checks.map(function (value: any) {


        let estimateMonies = []
        if (value.category === 1) {//月
            estimateMonies = estimateMonies1
        } else if (value.category === 2) {//一時金
            estimateMonies = estimateMonies2

        } else if (value.category === 3) {//一時金
            estimateMonies = estimateMonies3

        } else if (value.category === 4) {//一時金
            estimateMonies = estimateMonies4

        }
        if (value.category === 6) {//違約金


            contractPenalty = value.account_id
        }

        if (value.category === 7) {//違約金


            isFreeRentset = true
        }

        let monthtotal = estimateMonies1.reduce((p: any, x: any) => p + (x.rentMAccountId != -1 ? parseInt(x.amount + x.taxAmount) : 0), 0)

        if (value.account_id === 56) {
            monthtotal = estimateMonies1.reduce((p: any, x: any) => p + (x.rentMAccountId != -1 && x.rentMAccountId !== 56 ? parseInt(x.amount + x.taxAmount) : 0), 0)
        }


        if (estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0]) {

            if (value.base === 2) {
                estimateMonies = estimateMonies.filter((a: any) => a.rentMAccountId !== value.accountId)

            } else if (value.base === 5) {//無し

                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].rentMEtcId = 3
                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount = 0
                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].taxAmount = 0
                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amountMonth = 0
                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].eachYear = 0
            } else if (value.base === 4) {//込み
                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].rentMEtcId = 2
                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount = 0
                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].taxAmount = 0
                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amountMonth = 0
                estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].eachYear = 0
            } else if (value.base === 1) {

                if (value.rate > 0) {
                    if (value.rate_calc === 1) {
                        estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount = Math.floor(monthtotal * value.rate / 100)
                    } else if (value.rate_calc === 2) {
                        estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount = Math.ceil(monthtotal * value.rate / 100)
                    } else {
                        estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount = Math.round(monthtotal * value.rate / 100)
                    }





                }
                if (value.max_amount > 0) {
                    estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount = estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount > value.max_amount ? value.max_amount : estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount
                }
                if (value.min_amount > 0) {
                    estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount = estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount < value.min_amount ? value.min_amount : estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount
                }

                if (value.fix_amount > 0) {

                    estimateMonies.filter((a: any) => a.rentMAccountId === value.account_id)[0].amount = value.fix_amount
                }



            }

        } else {
            if (value.base === 1) {


                let amount = 0
                if (value.rate > 0) {
                    if (value.rate_calc === 1) {
                        amount = Math.floor(monthtotal * value.rate / 100)
                    } else if (value.rate_calc === 2) {
                        amount = Math.ceil(monthtotal * value.rate / 100)
                    } else {
                        amount = Math.round(monthtotal * value.rate / 100)
                    }

                }

                if (value.max_amount > 0) {
                    amount = amount > value.max_amount ? value.max_amount : amount
                }
                if (value.min_amount > 0) {
                    amount = amount < value.min_amount ? value.min_amount : amount
                }

                if (value.fix_amount > 0) {

                    amount = value.fix_amount
                }
                estimateMonies.push({
                    rentContractId: 0,
                    rentMAccountId: value.account_id,
                    name: state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName,
                    displayName: state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName,
                    billing: 0,
                    amount: amount,
                    amountMonth: 0,
                    eachMonth: 0,
                    eachYear: value.each_year,
                    accountGroup: value.category,
                    tax: value.tax,
                    taxAmount: 0,
                    rentMEtcId: 0,
                    isDailyRate: state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].isHiwari ? 1 : 0,
                    isFreeRent: 0,
                    works: 0,
                    salesAmount: 0,
                    remarks: null,
                    createdUser: 0,
                    createdAt: null,
                    updatedAt: null,
                    updatedUser: 0,
                })


            } else {


            }
        }


        if (value.category === 1) {//月
            estimateMonies1 = estimateMonies
        } else if (value.category === 2) {//一時金
            estimateMonies2 = estimateMonies

        } else if (value.category === 3) {//一時金
            estimateMonies3 = estimateMonies

        } else if (value.category === 4) {//一時金
            estimateMonies4 = estimateMonies

        }


    })

    // yield put(change_estimateMonies1(estimateMonies1))
    // yield put(change_estimateMonies2(estimateMonies2))
    // yield put(change_estimateMonies3(estimateMonies3))
    // yield put(change_estimateMonies4(estimateMonies4))

    estimateMonies1 = estimateMonies1.filter(function (value: any) {
        if (rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0]) {
            if (rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0].base !== 2) {

                return value
            }
        } else {
            return value
        }

    })
    estimateMonies2 = estimateMonies2.filter(function (value: any) {
        if (rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0]) {
            if (rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0].base !== 2) {
                return value
            }
        } else {
            return value
        }


    })
    estimateMonies3 = estimateMonies3.filter(function (value: any) {
        if (rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0]) {
            if (rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0].base !== 2) {
                return value
            }
        } else {
            return value
        }

    })
    estimateMonies4 = estimateMonies4.filter(function (value: any) {
        if (rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0]) {
            if (rent_contract_checks.filter((a: any) => a.account_id === value.rentMAccountId)[0].base !== 2) {
                return value
            }
        } else {
            return value
        }

    })



    if(estimateData.division === 1 || estimateData.division === 2){
    if (state.estimates.boshu.kaketsukeTou) {//安心サポート

        estimateMonies2 = estimateMonies2.filter(function (value: any) {

            if (value.rentMAccountId !== 22) {
                return value
            }

        })

    }else{

        if (estimateMonies2.filter((a: any) => a.rentMAccountId === 22)[0]) {
            if (estimateMonies2.filter((a: any) => a.rentMAccountId ===22)[0].amount !== 15000) {
                estimateMonies2.filter((a: any) => a.rentMAccountId ===22)[0].amount = 15000
                estimateMonies2.filter((a: any) => a.rentMAccountId ===22)[0].taxAmount = 1500
            }
        } else {
            estimateMonies2.push({
                rentContractId: 0,
                rentMAccountId: 22,
                name: state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === 22)[0].chinryouKoumokuName,
                displayName: state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === 22)[0].chinryouKoumokuName,
                billing: 0,
                amount: 15000,
                amountMonth: 0,
                eachMonth: 0,
                eachYear: 0,
                accountGroup: 2,
                tax: 1,
                taxAmount: 1500,
                rentMEtcId: 0,
                isDailyRate: 0,
                isFreeRent: 0,
                works: 1,
                salesAmount: 0,
                remarks: null,
                createdUser: 0,
                createdAt: null,
                updatedAt: null,
                updatedUser: 0,
            })


        }
    }
}


    if ((state.estimates.boshu.netGyosha == 1 || state.estimates.boshu.netGyosha == 2 || state.estimates.boshu.netGyosha == 368) && state.estimates.boshu.tatemonoId != 2114) {//初回ネット手数料

        if (estimateMonies2.filter((a: any) => a.rentMAccountId === 63)[0]) {
            if (estimateMonies2.filter((a: any) => a.rentMAccountId === 63)[0].amount !== 5000) {
                estimateMonies2.filter((a: any) => a.rentMAccountId === 63)[0].amount = 5000
                estimateMonies2.filter((a: any) => a.rentMAccountId === 63)[0].taxAmount = 500
            }
        } else {
            estimateMonies2.push({
                rentContractId: 0,
                rentMAccountId: 63,
                name: state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === 63)[0].chinryouKoumokuName,
                displayName: state.masterReducer.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === 63)[0].chinryouKoumokuName,
                billing: 0,
                amount: 5000,
                amountMonth: 0,
                eachMonth: 0,
                eachYear: 0,
                accountGroup: 2,
                tax: 1,
                taxAmount: 500,
                rentMEtcId: 0,
                isDailyRate: 0,
                isFreeRent: 0,
                works: 0,
                salesAmount: 0,
                remarks: null,
                createdUser: 0,
                createdAt: null,
                updatedAt: null,
                updatedUser: 0,
            })


        }
    }

    if (state.estimates.boshu.setsubiViewModels.filter((a: any) => a.setsubiNo === 155)[0]) {
        estimateMonies1 = estimateMonies1.filter(function (value: any) {

            if (value.rentMAccountId !== 7) {
                return value
            }

        })
    }

 
    let penalty = contractPenalty
    if (state.estimates.boshu.penalty) {
        let room_penalty = 0


        if (contractPenalty != 1) {//違約金で建物情報のあるものの違約金は募集条件を優先
            room_penalty = state.estimates.boshu.penalty == 0 ? 4 : state.estimates.boshu.penalty;


            penalty = state.masterReducer.m_penalties.filter((a: any) => a.id === room_penalty)[0].penaltyOrder > state.masterReducer.m_penalties.filter((a: any) => a.id === contractPenalty)[0].penaltyOrder ? room_penalty : contractPenalty; //一年未満違約金より厳しいもの優先
        }
    }

    estimateData.contractPenalty = penalty

    if (isFreeRentset !== false) {//フリーレント
        estimateMonies1 = estimateMonies1.filter(function (value: any) {
            if (value.rentMAccountId == 1 || value.rentMAccountId == 2 || value.rentMAccountId == 4 || value.rentMAccountId == 5 || value.rentMAccountId == 6) {
                value.isFreeRent = 1
            }
            return value
        })


        estimateMonies5 = estimateMonies5.filter(function (value: any) {
            if (value.rentMAccountId == 1 || value.rentMAccountId == 2 || value.rentMAccountId == 4 || value.rentMAccountId == 5 || value.rentMAccountId == 6) {
                value.isFreeRent = 1
            }
            return value
        })

    }

    const tyukaitotal = estimateMonies1.reduce((p: any, x: any) => p + ((x.rentMAccountId === 1 || x.rentMAccountId === 4 || x.rentMAccountId === 5 || x.rentMAccountId === 6) ? parseInt(x.amount) : 0), 0)
    estimateMonies2 = estimateMonies2.filter(function (value: any) {

        if (value.rentMAccountId === 12) {
            value.amount = tyukaitotal
            value.tax = 1
            value.taxAmount = Math.round(tyukaitotal * 0.1)
        }
        return value


    })





    yield put(change_estimateMonies1(estimateMonies1))
    yield put(change_estimateMonies2(estimateMonies2))
    yield put(change_estimateMonies3(estimateMonies3))
    yield put(change_estimateMonies4(estimateMonies4))
    yield put(change_estimateMonies5(estimateMonies5))
    yield put(check_estimates())
}