
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';


import { call, put, takeEvery, select } from 'redux-saga/effects';
// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
// import * as SearchRoomsApi from '../Api/SearchRoomsApi';
// import * as RentIntroductionFilesApi from '../Api/RentIntroductionFilesApi';
// import * as RentIntroductionFileUploadApi from '../Api/RentIntroductionFileUploadApi';
// import * as RentIntroductionFilesDownloadApi from '../Api/RentIntroductionFilesDownloadApi';

import * as t_room_sheets_api from '../Api/t_room_sheets_api';
import * as RoomList from '../../RoomList/Saga/RoomList';
import * as IntroductionList from '../../IntroductionList/Saga/IntroductionList';

export interface State {
  // setsubi_master: any;
  stock_rooms: any;
  stock_rooms_loading: boolean;
  stock_rooms_open: boolean;
  // room_details: any;
  // response_rooms: any;
  // introductions: any;
  // introductions_loading: number;
  // introduction_file_id: number;
  // introduction_pdf: any;
  // introduction_pdf_img: any;
  // introduction_details: any;
  // introduction_detail_loading: boolean;

  //  rent_introductions: any;
}
const initialState: State = {

  // setsubi_master: [],
  stock_rooms: [],
  // room_details: {},
  // response_rooms: [],
  // introductions: [],
  // introductions_loading: 0,
  // introduction_file_id: 0,
  // introduction_pdf: null,
  // introduction_pdf_img: null,
  // introduction_details: {},
  stock_rooms_loading: false,
  stock_rooms_open: false,
  // rent_introductions: {}

}



///action 
const actionCreator = actionCreatorFactory();

// export const get_setsubi_master = actionCreator('GET_SETSUBI_MASTER');
// export const get_setsubi_master_success = actionCreator("GET_SETSUBI_MASTER_SUCCESS");
// export const get_setsubi_master_failure = actionCreator("GET_SETSUBI_MASTER_FAILURE");



export const get_stock_rooms = actionCreator('GET_STOCK_ROOMS');
export const change_stock_rooms = actionCreator<any>("CHANGE_STOCK_ROOMS");
// export const get_rooms_failure = actionCreator("GET_ROOMS_FAILURE");

export const change_stock_rooms_loading = actionCreator<boolean>("CHANGE_STOCK_ROOMS_LOADING");


export const change_stock_rooms_open = actionCreator<boolean>("CHANGE_STOCK_ROOMS_OPEN");


export const delete_stock_rooms = actionCreator<number>("DELETE_STOCK_ROOMS");


export const insert_stock_rooms = actionCreator<any>("INSERT_STOCK_ROOMS");

export const deleteall_stock_rooms = actionCreator("DELETEALL_STOCK_ROOMS");

// export const get_response_rooms = actionCreator<any>('GET_RESPONSE_ROOMS');
// export const get_response_rooms_success = actionCreator<any>("GET_RESPONSE_ROOMS_SUCCESS");


// // export const get_room_details = actionCreator<any>('GET_ROOM_DETAILS');
// // export const get_room_details_success = actionCreator("GET_ROOM_DETAILS_SUCCESS");
// // export const get_room_details_failure = actionCreator("GET_ROOM_DETAILS_FAILURE");

// export const get_introduction_details = actionCreator<any>('GET_INTRODUCTION_DETAILS');
// export const get_introduction_details_success = actionCreator("GET_INTRODUCTION_DETAILS_SUCCESS");
// export const change_introduction_detail_loading = actionCreator<any>('CHANGE_INTRODUCTION_DETAIL_LOADING');


// export const get_introductions = actionCreator<any>('GET_INTRODUCTIONS');
// export const get_introductions_success = actionCreator<any>('GET_INTRODUCTIONS_SUCCESS');

// export const insert_introductions = actionCreator<any>('INSERT_INTRODUCTIONS');
// export const insert_introductions_success = actionCreator<any>('INSERT_INTRODUCTIONS_SUCCESS');

// export const insert_introduction_files = actionCreator<any>('INSERT_INTRODUCTION_FILES');
// export const change_introduction_file_id = actionCreator<any>('CHANGE_INTRODUCTION_FILE_ID');

// export const change_introduction_pdf = actionCreator<any>('CHANGE_INTRODUCTION_PDF');
// export const change_introduction_pdf_img = actionCreator<any>('CHANGE_INTRODUCTION_PDF_IMG');

// export const update_open_introductions = actionCreator<any>('UPDATE_OPEN_INTRODUCTIONS');
// export const update_open_introductions_success = actionCreator<any>('UPDATE_OPEN_INTRODUCTIONS_SUCCESS');

// export const change_introductions_loading = actionCreator<number>('INTRODUCTIONS_LOADING');

// export const delete_introduction = actionCreator<number>('DELETE_INTRODUCTION');


// export const add_introduction_file = actionCreator<any>('ADD_INTRODUCTION_FILE');


// export const get_introduction_file = actionCreator<any>('GET_INTRODUCTION_FILE');



//  const getUsers = actionCreator('USERS_REQUEST');
// export const getUsersSuccess = actionCreator("USERS_SUCCESS");
// export const getUsersFailure = actionCreator("USERS_FAILURE");

// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const stockroomsReducer = reducerWithInitialState(initialState)
  // .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))
  // .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))
  .case(change_stock_rooms, (state, payload) => ({ ...state, stock_rooms: payload }))

  .case(change_stock_rooms_loading, (state, payload) => ({ ...state, stock_rooms_loading: payload }))
  .case(change_stock_rooms_open, (state, payload) => ({ ...state, stock_rooms_open: payload }))

// .case(get_response_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

// .case(get_introductions_success, (state, payload) => ({ ...state, introductions: payload }))

// .case(change_introductions_loading, (state, payload) => ({ ...state, introductions_loading: payload }))

// .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }))

// .case(change_introduction_file_id, (state, payload) => ({ ...state, introduction_file_id: payload }))


// .case(change_introduction_pdf, (state, payload) => ({ ...state, introduction_pdf: payload }))
// .case(change_introduction_pdf_img, (state, payload) => ({ ...state, introduction_pdf_img: payload }))
// .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const stockroomsSaga = [
  takeEvery('GET_STOCK_ROOMS', handle_get_stock_rooms),

  takeEvery('DELETE_STOCK_ROOMS', handle_delete_stock_rooms),

  takeEvery('INSERT_STOCK_ROOMS', handle_insert_stock_rooms),

  takeEvery('DELETEALL_STOCK_ROOMS', handle_deleteall_stock_rooms),

  // takeEvery('GET_ROOM_DETAILS', handleGetRoomDetailsRequest),
  // takeEvery('GET_RESPONSE_ROOMS', handle_get_response_rooms),
  // takeEvery('GET_INTRODUCTIONS', handle_get_introductions),
  // takeEvery('INSERT_INTRODUCTIONS', handle_insert_introductions),
  // takeEvery('UPDATE_OPEN_INTRODUCTIONS', handle_update_open_introductions),
  // takeEvery('DELETE_INTRODUCTION', handle_delete_introduction),
  // takeEvery('INSERT_INTRODUCTION_FILES', handle_insert_introduction_files),
  // takeEvery('ADD_INTRODUCTION_FILE', handle_add_introduction_file),
  // takeEvery('GET_INTRODUCTION_DETAILS', handle_get_introduction_details),

  // takeEvery('GET_INTRODUCTION_FILE', handle_get_introduction_file),

  // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
  // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;





function* handle_get_stock_rooms(action: any) {
  yield put(change_stock_rooms_loading(true));
  const { payload, error } = yield call(t_room_sheets_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_stock_rooms(payload));
    yield put(change_stock_rooms_loading(false));
  } else {

    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_insert_stock_rooms(action: any): any {
  // {tatemono_id,room_id}
  const state = yield select();

  yield put(change_stock_rooms_loading(true));
  const { payload, error } = yield call(t_room_sheets_api.insert, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_stock_rooms(payload));
    yield put(change_stock_rooms_loading(false));
    yield put(change_stock_rooms_open(true));
    yield put(RoomList.search());
    if(state.responsesReducer.responseid){
      yield put(IntroductionList.get_introductions({ rent_response_id: state.responsesReducer.responseid }));
    }
    

  } else {

    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}


function* handle_delete_stock_rooms(action: any):any {
  const state = yield select();
  yield put(change_stock_rooms_loading(true));
  const { payload, error } = yield call(t_room_sheets_api.stockdelete, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_stock_rooms(payload));
    yield put(change_stock_rooms_loading(false));
    yield put(RoomList.search());
    if(state.responsesReducer.responseid){
      yield put(IntroductionList.get_introductions({ rent_response_id: state.responsesReducer.responseid }));
    }


  } else {

    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}



function* handle_deleteall_stock_rooms(action: any):any {
  const state = yield select();
  yield put(change_stock_rooms_loading(true));
  const { payload, error } = yield call(t_room_sheets_api.stockdeleteall, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_stock_rooms(payload));
    yield put(change_stock_rooms_loading(false));
    yield put(RoomList.search());
    yield put(change_stock_rooms_open(false));
    if(state.responsesReducer.responseid){
      yield put(IntroductionList.get_introductions({ rent_response_id: state.responsesReducer.responseid }));
    }
  } else {

  }
}
