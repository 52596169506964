import React, { Component } from 'react';
import { connect } from 'react-redux';

// import CKEditor from '@ckeditor/ckeditor5-react';
// import CkEditor, { getEditorNamespace } from 'ckeditor4-react';
// import CKEDITOR from 'getEditorNamespace-react';
import * as MailTemplate from './Saga/MailTemplate';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ParamGrid from './ParamGrid';


// import Font from '@ckeditor/ckeditor5-font/src/font';
class CkEditorComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: "",
      modal: false
      // editorState: EditorState.createEmpty(),
      // editorState: editorState,
    };

    this.toggle = this.toggle.bind(this);
    this.paramset = this.paramset.bind(this);

  }
  componentDidMount() {

    // var editor = CKEDITOR.instances.editor1;
    // CKEDITOR.replace(this.elementName);
  }
  toggle() {
    this.setState({ modal: !this.state.modal })
  }
  onChange = (editorState) => {

  }


  paramset = (text) => {
    // let editor = window.CKEDITOR.instances.editor2;
    let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];

    editor.insertHtml(text);
    // editor.insertText(text);
    this.toggle()
  }
  html = (html) => {
    // let editor = window.CKEDITOR.instances.editor2
    let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];
    ;
    editor.insertHtml(html);
  }
  focus = () => {

    this.editor.focus();
    this.editor.insertText("eddd");
  };


  //   upload() {alert()
  //     // // Update the loader's progress.
  //     // server.onUploadProgress( data => {
  //     //     loader.uploadTotal = data.total;
  //     //     loader.uploaded = data.uploaded;
  //     // } );

  //     // // Return a promise that will be resolved when the file is uploaded.
  //     // return loader.file
  //     //     .then( file => server.upload( file ) );
  // }

  // Aborts the upload process.
  // abort() {
  //     // Reject the promise returned from the upload() method.
  // //     server.abortUpload();
  // }
  render() {


    return (
      <div>
        {/* <CkEditor

          // editor={ ClassicEditor }

          // type="classic"
          data={this.props.mail_template_details.body_all}
          // onInit={ editor => {
          //     // You can store the "editor" and use when it is needed.
          //     console.log( 'Editor is ready to use!', editor );

          // } }

          onChange={(event, editor) => {

            const data = event.editor.getData();
            let mail_template_details = this.props.mail_template_details
            mail_template_details.body_all = data
            this.props.change_mail_template_details(mail_template_details)
            // this.onChange()

            // this.props.body=event.editor.getData();
            this.setState({
              data,
            });


            console.log({ event, editor, data });
          }}
          onBlur={editor => {
            console.log('Blur.', editor);
          }}
          onFocus={editor => {
            console.log('Focus.', editor);

          }}
          onSelectionChange={editor => {
            console.log()

          }}


          //   FileRepository={ editor => {
          //     console.log( 'Focus.', editor );
          // } }
          config={{
            uploadUrl: 'localhost',
            height: this.props.height == null ? 500 : this.props.height,
            language: 'ja',
            extraPlugins: 'image2,uploadimage,colorbutton,colordialog',
            allowedContent: true,
            enterMode: 2,//エンターを押したときにpタグじゃなbrを挿入
            // Plugins:[FontColor],
            // extraPlugins: fontcolor,
            //                 colorButton_colors: 'CF5D4E,454545,FFF,CCC,DDD,CCEAEE,66AB16',
            // colorButton_enableAutomatic: false

            toolbar: [['Bold'], ['Italic'], ['image'], ['Link'], ['Format', 'Font', 'FontSize'], ['FontSize'], ['TextColor', 'BGColor'], ['Table'], ['Source'], ['Undo'], ['Redo']]

          }}
        /> */}

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ margin: '5px' }}
          onClick={this.toggle}>自動項目</button>

        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>

          <ModalHeader toggle={this.toggle}>項目選択</ModalHeader>
          <ModalBody>
            <ParamGrid paramset={a => this.paramset(a)} />
          </ModalBody>
          <ModalFooter>

            {/* <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggledelete}>キャンセル</button> */}
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}





const mapStateToProps = (state) => {
  return {
    mail_template_details: state.mailtemplate.mail_template_details,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_mail_template_details(state) {
      dispatch(MailTemplate.change_mail_template_details(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CkEditorComponent);