
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as SearchRoomsApi from '../Api/SearchRoomsApi';

import moment from 'moment';



export interface RoomsState {

  room_details: any;
  response_search_rooms: any;
  search_introductions: any;
  introductions_loading: number;
  room_search_list_loading: boolean;
  search_rooms: any;
  search_rooms_list: any;
  search_rooms_count: number;
  room_list_search_form: any;
  roomlist_update: boolean;
}
const initialState: RoomsState = {

  room_details: [],
  response_search_rooms: [],
  search_introductions: [],
  introductions_loading: 0,
  room_search_list_loading: false,
  search_rooms: [],
  search_rooms_count: 0,
  search_rooms_list: [],
  room_list_search_form: {
    tatemono_name: "",
    kubun_1: true,
    kubun_2: false,
    kubun_3: false,
    kubun_4: true,
    boshu_only: true,
    yachin_etc_0: false,
    yachin_etc_1: false,
    chinryou_from: 0,
    chinryou_to: 0,
    yachin_etc_2: false,
    yachin_etc_3: false,
    area_1: false,
    area_2: false,
    area_3: false,
    area_4: false,
    area_5: false,
    area_6: false,
    area_7: false,
    area_8: false,
    area_9: false,
    area_10: false,
    area_11: false,
    shubetsu_1: true,
    shubetsu_2: true,
    shubetsu_7: true,
    shubetsu_21: true,
  
    madori_1: true,
    madori_2: true,
    madori_3: true,
    madori_5: true,
    madori_8: true,
    madori_9: true,
    madori_11: true,
    madori_14: true,
    madori_15: true,
    madori_17: true,
    madori_20: true,
    tikunen: "",
    tikunen1: "",
    tikunen1_month: "",
    tikunen2: "",
    tikunen2_month: "",
    senyuu_u: 0,
    senyuu_t: 0,
    toho: 0,
    setsubi_20: false,
    setsubi_16: false,
    setsubi_45: false,
    setsubi_21: false,
    setsubi_91: false,
    setsubi_118: false,
    setsubi_3: false,
    setsubi_4: false,
    setsubi_1: false,
    setsubi_5: false,
    setsubi_127: false,
    setsubi_100: false,
    setsubi_99: false,
    setsubi_102: false,
    setsubi_103: false,
    setsubi_143: false,
    setsubi_12: false,
    setsubi_110: false,
    setsubi_14: false,
    setsubi_9: false,
    setsubi_37: false,
    setsubi_41: false,
    setsubi_40: false,
    setsubi_87: false,
    setsubi_153: false,
    setsubi_154: false,
    setsubi_109: false,
    setsubi_54: false,
    setsubi_51: false,
    setsubi_69: false,
    setsubi_35: false,
    setsubi_36: false,
    setsubi_108: false,
    setsubi_105: false,
    setsubi_104: false,
    setsubi_43: false,
    setsubi_47: false,
    setsubi_86: false,
    setsubi_rs: false,
    setsubi_76: false,
    setsubi_140: false,
    setsubi_39: false,
    setsubi_142: false,
    setsubi_129: false,
    setsubi_38: false,
    setsubi_77: false,
    setsubi_2k: false,
    setsubi_gk: false,
    setsubi_gkt: false,
    setsubi_gkt_su: false,
    setsubi_ac: false,
    setsubi_ac_su: false,
    setsubi_ih: false,
    setsubi_ih_su: false,
    setsubi_ev: false,
    setsubi_minami: false,
    is_gakusei_yoyaku: 0,
    is_foreigner: 0,
    is_gakusei_long_move_3: 0,
    is_gakusei_long_move_4: 0,

    setsubi_toshigas : false,
    setsubi_196 : false,
    setsubi_17 : false,
    setsubi_147 : false,
    setsubi_kai : false,

    kouzou_4: false,
    kouzou_3: false,
    kouzou_1: false,
    kouzou_2: false,
    kouzou_14: false,
    kouzou_9: false,

    max_lat:"",
    min_lat:"",
    max_lng:"",
    min_lng:"",

    nyuukyo_kanou_date:"",
  },
  roomlist_update: false,

}



///action 
const actionCreator = actionCreatorFactory();




export const get_response_search_rooms = actionCreator<any>('GET_RESPONSE_SEARCH_ROOMS');
export const get_response_search_rooms_success = actionCreator<any>("GET_RESPONSE_SEARCH_ROOMS_SUCCESS");




export const get_search_rooms = actionCreator<any>('GET_SEARCH_ROOMS');
export const change_search_rooms = actionCreator<any>("CHANGE_SEARCH_ROOMS");
export const change_search_rooms_list = actionCreator<any>("CHANGE_SEARCH_ROOMS_LIST");


export const search = actionCreator('SEARCH');

export const change_room_list_search_form = actionCreator<any>("CHANGE_ROOM_LIST_SEARCH_FORM");




export const update_introductions = actionCreator<any>('UPDATE_INTRODUCTIONS');
export const update_introductions_success = actionCreator<any>('UPDATE_INTRODUCTIONS_SUCCESS');

export const change_introductions_loading = actionCreator<number>('INTRODUCTIONS_LOADING');
export const insert_search_introductions = actionCreator<any>('INSERT_SEARCH_INTRODUCTIONS');

export const change_room_search_list_loading = actionCreator<any>('CHANGE_ROOM_SEARCH_LIST_LOADING');

export const change_search_rooms_count = actionCreator<any>('CHANGE_SEARCH_ROOMS_COUNT');

export const change_roomlist_update = actionCreator<any>('CHANGE_ROOMLIST_UPDATE');


export const roomlistReducer = reducerWithInitialState(initialState)

  .case(get_response_search_rooms_success, (state, payload) => ({ ...state, response_search_rooms: payload }))
  .case(change_room_search_list_loading, (state, payload) => ({ ...state, room_search_list_loading: payload }))
  .case(change_search_rooms, (state, payload) => ({ ...state, search_rooms: payload }))
  .case(change_search_rooms_list, (state, payload) => ({ ...state, search_rooms_list: payload }))
  .case(change_room_list_search_form, (state, payload) => ({ ...state, room_list_search_form: payload }))


  .case(change_search_rooms_count, (state, payload) => ({ ...state, search_rooms_count: payload }))


  .case(change_roomlist_update, (state, payload) => ({ ...state, roomlist_update: payload }))




// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const roomlistSaga = [
  takeEvery('GET_RESPONSE_SEARCH_ROOMS', handle_get_response_search_rooms),



  takeEvery('GET_SEARCH_ROOMS', handle_get_search_rooms),
  takeEvery('SEARCH', handle_search),
];

function* handle_get_response_search_rooms(action: any) {
alert()
  yield put(change_room_search_list_loading(true));
  const { payload, error } = yield call(SearchRoomsApi.response_list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)




    yield put(get_response_search_rooms_success(payload));




    yield put(change_room_search_list_loading(false));
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_get_search_rooms(action: any): any {
  const state = yield select();

  let room_list_search_form = state.roomlist.room_list_search_form
  // room_list_search_form.rent_response_id = state.responsesReducer.responsedetail.id
  room_list_search_form.rent_response_id = action.payload
  yield put(change_room_search_list_loading(true));
  yield put(change_search_rooms_list([]));

  let list = SearchRoomsApi.alllist
  if (room_list_search_form.boshu_only) {
    list = SearchRoomsApi.list
  }
  const { payload, error } = yield call(list, room_list_search_form);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    const state = yield select();

    let introductions = state.introductionlist.introductions
    let stock_rooms = state.StockRooms.stock_rooms

    let intro = payload.map(function (value: any) {
      if (introductions.filter((a: any) => a.tatemono_id === value.tatemono_id && a.room_id === value.room_id)[0]) {
        value.selected = true
      } else {
        value.selected = false

      }

      if (stock_rooms.filter((a: any) => a.tatemono_id === value.tatemono_id && a.room_id === value.room_id)[0]) {
        value.stocked = true
      } else {
        value.stocked = false

      }


      return value
    })
    console.log(intro)
    console.log("aaaaaaaaaaaaaaaaaa")

    yield put(change_search_rooms(intro));
    yield put(search());
    yield put(change_room_search_list_loading(false));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}



function* handle_search(action: any): any {

  yield put(change_roomlist_update(true));
  // yield put(change_room_search_list_loading(true));
  const state = yield select();


  let search_rooms = state.roomlist.search_rooms


  let room_list_search_form = state.roomlist.room_list_search_form



  if (room_list_search_form.boshu_only) {


  if (room_list_search_form.tatemono_name) {
    //strにhogeを含む場合の処理
    search_rooms = search_rooms.filter((a: any) => han2zen(kanaToHira(hankana2Zenkana(a.tatemono_name.toUpperCase()))).indexOf(han2zen(kanaToHira(hankana2Zenkana(room_list_search_form.tatemono_name.toUpperCase())))) !== -1 || han2zen(kanaToHira(hankana2Zenkana(a.tatemono_kana.toUpperCase()))).indexOf(han2zen(kanaToHira(hankana2Zenkana(room_list_search_form.tatemono_name.toUpperCase())))) !== -1)
  }
  //管理区分
  if (room_list_search_form.kubun_1 === false) {
    search_rooms = search_rooms.filter((a: any) => a.kanri_kubun_no !== 1 && a.kanri_kubun_no !== 4)
  }
  if (room_list_search_form.kubun_2 === false) {
    search_rooms = search_rooms.filter((a: any) => a.kanri_kubun_no !== 2 && a.kanri_kubun_no !== 3)
  }
  if (room_list_search_form.kubun_3 === false) {
    search_rooms = search_rooms.filter((a: any) => a.kanri_kubun_no !== 6)// 業物
  }
  if (room_list_search_form.kubun_4 === true) {
    search_rooms = search_rooms.filter((a: any) => a.kanri_corp_no !== 6)// レオパレス
  }

  //建物種別
  if (room_list_search_form.shubetsu_1 === false) {
    console.log(search_rooms)

    search_rooms = search_rooms.filter((a: any) => a.tatemono_shubetsu_no !== 1)
    console.log(search_rooms)

  }
  if (room_list_search_form.shubetsu_2 === false) {
    search_rooms = search_rooms.filter((a: any) => a.tatemono_shubetsu_no !== 2)
  }
  if (room_list_search_form.shubetsu_7 === false) {
    search_rooms = search_rooms.filter((a: any) => a.tatemono_shubetsu_no !== 7)
  }
  if (room_list_search_form.shubetsu_21 === false) {
    search_rooms = search_rooms.filter((a: any) => a.tatemono_shubetsu_no !== 21)
  }


  //間取り
  let madori_array: any = []
  if (room_list_search_form.madori_1 === true) {//1R
    madori_array.push(1)
    madori_array.push(54)
  }
  if (room_list_search_form.madori_2 === true) {//1K
    madori_array.push(2)
    madori_array.push(51)
    madori_array.push(57)

  }
  if (room_list_search_form.madori_3 === true) {//1DK
    madori_array.push(3)
    madori_array.push(4)

  }
  if (room_list_search_form.madori_5 === true) {//1LDK
    madori_array.push(5)
    madori_array.push(6)
    madori_array.push(60)
    madori_array.push(7)
  }
  if (room_list_search_form.madori_8 === true) {//2K
    madori_array.push(8)
    madori_array.push(59)

  }
  if (room_list_search_form.madori_9 === true) {//2DK
    madori_array.push(9)
    madori_array.push(10)
    madori_array.push(50)

  }

  if (room_list_search_form.madori_11 === true) {//2LDK
    madori_array.push(11)
    madori_array.push(12)
    madori_array.push(13)
  }
  if (room_list_search_form.madori_14 === true) {//3K
    madori_array.push(14)
  }

  if (room_list_search_form.madori_15 === true) {//3DK
    madori_array.push(15)
    madori_array.push(16)

  }

  if (room_list_search_form.madori_17 === true) {//3LDK
    madori_array.push(17)
    madori_array.push(18)
    madori_array.push(19)
    madori_array.push(49)
    madori_array.push(52)
    madori_array.push(63)
    madori_array.push(65)
  }
  if (room_list_search_form.madori_20 === true) {//4K～
    madori_array.push(20)
    madori_array.push(21)
    madori_array.push(22)
    madori_array.push(23)
    madori_array.push(24)
    madori_array.push(25)
    madori_array.push(26)
    madori_array.push(27)
    madori_array.push(28)
    madori_array.push(29)
    madori_array.push(30)
    madori_array.push(31)
    madori_array.push(32)
    madori_array.push(33)
    madori_array.push(34)
    madori_array.push(35)
    madori_array.push(36)
    madori_array.push(37)
    madori_array.push(38)
    madori_array.push(39)
    madori_array.push(40)
    madori_array.push(41)
    madori_array.push(42)
    madori_array.push(55)
    madori_array.push(56)
    madori_array.push(64)

  }






  // console.log(madori_array)

  search_rooms = search_rooms.map(function (value: any) {
    if (madori_array.indexOf(value.madori_no) !== -1) {
      return value

    }
  })

  search_rooms = search_rooms.filter(function (elem: any) {
    return elem;
  });
  // console.log(search_rooms)
  // alert()





  //賃料
  if (room_list_search_form.chinryou_from > 0) {
    if (room_list_search_form.yachin_etc_0 && room_list_search_form.yachin_etc_1) {
      search_rooms = search_rooms.filter((a: any) => (a.chinryou + a.p_ryou_int + a.kyouekihi) >= room_list_search_form.chinryou_from)
      search_rooms = search_rooms.filter((a: any) => a.p_ryou_int > 0 || a.p_ryou === "込み")
    } else if (room_list_search_form.yachin_etc_0) {
      search_rooms = search_rooms.filter((a: any) => (a.chinryou + a.kyouekihi) >= room_list_search_form.chinryou_from)
    } else if (room_list_search_form.yachin_etc_1) {
      search_rooms = search_rooms.filter((a: any) => (a.chinryou + a.p_ryou_int) >= room_list_search_form.chinryou_from)
      search_rooms = search_rooms.filter((a: any) => a.p_ryou_int > 0 || a.p_ryou === "込み")
    } else {
      search_rooms = search_rooms.filter((a: any) => a.chinryou >= room_list_search_form.chinryou_from)
    }
  }

  if (room_list_search_form.chinryou_to > 0) {
    if (room_list_search_form.yachin_etc_0 && room_list_search_form.yachin_etc_1) {
      search_rooms = search_rooms.filter((a: any) => (a.chinryou + a.p_ryou_int + a.kyouekihi) <= room_list_search_form.chinryou_to)
      search_rooms = search_rooms.filter((a: any) => a.p_ryou_int > 0 || a.p_ryou === "込み")
    } else if (room_list_search_form.yachin_etc_0) {
      search_rooms = search_rooms.filter((a: any) => (a.chinryou + a.kyouekihi) <= room_list_search_form.chinryou_to)
    } else if (room_list_search_form.yachin_etc_1) {
      search_rooms = search_rooms.filter((a: any) => (a.chinryou + a.p_ryou_int) <= room_list_search_form.chinryou_to)
      search_rooms = search_rooms.filter((a: any) => a.p_ryou_int > 0 || a.p_ryou === "込み")
    } else {
      search_rooms = search_rooms.filter((a: any) => a.chinryou <= room_list_search_form.chinryou_to)
    }
  }

  if (room_list_search_form.yachin_etc_2) {
    search_rooms = search_rooms.filter((a: any) => a.shikikin_mon === 0 && a.shikikin === 0)
  }
  if (room_list_search_form.yachin_etc_3) {
    search_rooms = search_rooms.filter((a: any) => a.reikin_mon === 0 && a.reikin === 0)
  }

  //占有面積
  if (room_list_search_form.senyuu_u > 0) {
    search_rooms = search_rooms.filter((a: any) => a.senyuu_menseki >= room_list_search_form.senyuu_u)
  }
  if (room_list_search_form.senyuu_t > 0) {
    search_rooms = search_rooms.filter((a: any) => a.senyuu_menseki <= room_list_search_form.senyuu_t)
  }

  if (room_list_search_form.nyuukyo_kanou_date) {
    search_rooms = search_rooms.filter((a: any) => 
    (a.nyuukyo_kanou_year != "" && 
    a.nyuukyo_kanou_month != "" && 
    a.nyuukyo_kanou_day != "" && 
    a.nyuukyo_kanou_year != "-" && 
    a.nyuukyo_kanou_month != "-" && 
    a.nyuukyo_kanou_day != "-" && 
    moment(a.nyuukyo_kanou_year + "/" + a.nyuukyo_kanou_month + "/" + a.nyuukyo_kanou_day.replace("上旬","08").replace("中旬","18").replace("下旬","28")).format("YYYYMMDD") <= moment(room_list_search_form.nyuukyo_kanou_date).format("YYYYMMDD")) || 
    (a.nyuukyo_kanou_year == "" || 
    a.nyuukyo_kanou_month == "" || 
    a.nyuukyo_kanou_day == "-" || 
    a.nyuukyo_kanou_year == "-" || 
    a.nyuukyo_kanou_month == "-" || 
    a.nyuukyo_kanou_day == "-"
    )
    )
  }


  //築年
  var now = new Date();
  var year = now.getFullYear(); //年
  var month = now.getMonth() + 1;    //月
  if (room_list_search_form.tikunen > 0) {


    search_rooms = search_rooms.filter((a: any) => (a.shunkou_year * 100 + a.shunkou_month) >= ((year - room_list_search_form.tikunen) * 100 + month))
  }

  if (room_list_search_form.tikunen1 > 1900) {

    search_rooms = search_rooms.filter((a: any) => (a.shunkou_year * 100 + a.shunkou_month) >= ((room_list_search_form.tikunen1) * 100 + (room_list_search_form.tikunen1_month ? parseInt(room_list_search_form.tikunen1_month) : 1)))
  }
  if (room_list_search_form.tikunen2 > 1900) {

    search_rooms = search_rooms.filter((a: any) => (a.shunkou_year * 100 + a.shunkou_month) <= ((room_list_search_form.tikunen2) * 100 + (room_list_search_form.tikunen2_month ? parseInt(room_list_search_form.tikunen2_month) : 12)))
  }

  //駅徒歩
  if (room_list_search_form.toho > 0) {
    search_rooms = search_rooms.filter((a: any) => a.koutsuu_1_min != null && a.koutsuu_1_min <= room_list_search_form.toho)
  }




  //設備
  if (room_list_search_form.setsubi_16) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',16,') !== -1)
  }
  if (room_list_search_form.setsubi_20) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',20,') !== -1)
  }
  if (room_list_search_form.setsubi_45) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',45,') !== -1)
  }
  if (room_list_search_form.setsubi_21) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',21,') !== -1)
  }

  if (room_list_search_form.setsubi_91) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',91,') !== -1)
  }

  if (room_list_search_form.setsubi_118) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',118,') !== -1)
  }
  if (room_list_search_form.setsubi_3) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',3,') !== -1)
  }
  if (room_list_search_form.setsubi_77) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',77,') !== -1)
  }
  if (room_list_search_form.setsubi_4) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',4,') !== -1)
  }
  if (room_list_search_form.setsubi_1) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',1,') !== -1)
  }
  if (room_list_search_form.setsubi_5) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',5,') !== -1)
  }
  if (room_list_search_form.setsubi_127) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',127,') !== -1)
  }
  if (room_list_search_form.setsubi_100) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',100,') !== -1)
  }
  if (room_list_search_form.setsubi_99) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',99,') !== -1)
  }
  if (room_list_search_form.setsubi_102) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',102,') !== -1)
  }
  if (room_list_search_form.setsubi_103) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',103,') !== -1)
  }
  if (room_list_search_form.setsubi_143) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',143,') !== -1)
  }
  if (room_list_search_form.setsubi_12) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',12,') !== -1)
  }
  if (room_list_search_form.setsubi_110) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',110,') !== -1)
  }
  if (room_list_search_form.setsubi_14) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',14,') !== -1)
  }
  if (room_list_search_form.setsubi_9) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',9,') !== -1)
  }
  if (room_list_search_form.setsubi_37) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',37,') !== -1)
  }
  if (room_list_search_form.setsubi_41) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',41,') !== -1)
  }
  if (room_list_search_form.setsubi_40) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',40,') !== -1)
  }
  if (room_list_search_form.setsubi_87) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',87,') !== -1)
  }
  if (room_list_search_form.setsubi_153) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',153,') !== -1)
  }
  if (room_list_search_form.setsubi_154) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',154,') !== -1)
  }
  if (room_list_search_form.setsubi_109) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',109,') !== -1)
  }
  if (room_list_search_form.setsubi_54) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',54,') !== -1)
  }
  if (room_list_search_form.setsubi_51) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',51,') !== -1)
  }
  if (room_list_search_form.setsubi_69) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',69,') !== -1)
  }
  if (room_list_search_form.setsubi_35) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',35,') !== -1)
  }
  if (room_list_search_form.setsubi_36) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',36,') !== -1)
  }
  if (room_list_search_form.setsubi_108) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',108,') !== -1)
  }
  if (room_list_search_form.setsubi_104) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',104,') !== -1)
  }
  if (room_list_search_form.setsubi_105) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',105,') !== -1)
  }
  if (room_list_search_form.setsubi_43) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',43,') !== -1)
  }
  if (room_list_search_form.setsubi_47) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',47,') !== -1)
  }
  if (room_list_search_form.setsubi_86) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',86,') !== -1)
  }
  if (room_list_search_form.setsubi_76) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',76,') !== -1)
  }
  if (room_list_search_form.setsubi_140) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',140,') !== -1)
  }
  if (room_list_search_form.setsubi_39) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',39,') !== -1)
  }
  if (room_list_search_form.setsubi_142) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',142,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',448,') !== -1)
  }
  if (room_list_search_form.setsubi_129) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',129,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',448,') !== -1)
  }
  if (room_list_search_form.setsubi_38) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',38,') !== -1)
  }
  if (room_list_search_form.setsubi_17) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',17,') !== -1)
  }
  if (room_list_search_form.setsubi_147) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',147,') !== -1)
  }
  if (room_list_search_form.setsubi_196) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',196,') !== -1)
  }



  if (room_list_search_form.setsubi_ih) {
    if(room_list_search_form.setsubi_ih_su == ""){// 口数未選択
      search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',29,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',30,') !== -1
        || (',' + a.room_setsubi + ',').indexOf(',59,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',92,') !== -1)
    }else{
      search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(','+room_list_search_form.setsubi_ih_su+',') !== -1)
    }
  }

  if (room_list_search_form.setsubi_2k) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',22,') !== -1
      || (',' + a.room_setsubi + ',').indexOf(',23,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',28,') !== -1
      || (',' + a.room_setsubi + ',').indexOf(',30,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',59,') !== -1)
  }
  if (room_list_search_form.setsubi_gk) {
    search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',25,') !== -1
      || (',' + a.room_setsubi + ',').indexOf(',26,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',94,') !== -1)
  }
  if (room_list_search_form.setsubi_gkt) {
    if(room_list_search_form.setsubi_gkt_su == ""){// 口数未選択
      search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',22,') !== -1
        || (',' + a.room_setsubi + ',').indexOf(',23,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',24,') !== -1
        || (',' + a.room_setsubi + ',').indexOf(',95,') !== -1)
    }else{
      search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(','+room_list_search_form.setsubi_gkt_su+',') !== -1)
    }
  }
  if (room_list_search_form.setsubi_ac) {
    if(room_list_search_form.setsubi_ac_su == ""){// 台数未選択
      search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(',7,') !== -1
        || (',' + a.room_setsubi + ',').indexOf(',8,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',58,') !== -1
        || (',' + a.room_setsubi + ',').indexOf(',115,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',119,') !== -1
        || (',' + a.room_setsubi + ',').indexOf(',121,') !== -1 || (',' + a.room_setsubi + ',').indexOf(',151,') !== -1
      )
    }else{
      search_rooms = search_rooms.filter((a: any) => (',' + a.room_setsubi + ',').indexOf(','+room_list_search_form.setsubi_ac_su+',') !== -1)
    }
  }
  if (room_list_search_form.setsubi_ev) {
    search_rooms = search_rooms.filter((a: any) => a.have_elevator === 1)
  }
  if (room_list_search_form.setsubi_minami) {
    search_rooms = search_rooms.filter((a: any) => a.room_muki_no === 4)
  }

  if (room_list_search_form.is_gakusei_yoyaku) {//学生予約可
    search_rooms = search_rooms.filter((a: any) => a.is_gakusei_yoyaku === 1)
  }
  if (room_list_search_form.is_foreigner) {//外国人可
    search_rooms = search_rooms.filter((a: any) => a.is_foreigner === 1)
  }
  if (room_list_search_form.is_gakusei_long_move_3) {//3月入居可
    search_rooms = search_rooms.filter((a: any) => a.is_gakusei_long_move === 3 || a.is_gakusei_long_move === 4)
  }
  if (room_list_search_form.is_gakusei_long_move_4) {//4月入居可
    search_rooms = search_rooms.filter((a: any) => a.is_gakusei_long_move === 4)
  }

  if (room_list_search_form.setsubi_toshigas) {
    search_rooms = search_rooms.filter((a: any) => a.gas_no === 1)
  }

  if (room_list_search_form.setsubi_kai) {
    search_rooms = search_rooms.filter((a: any) => a.kai_is_chijou === 1 && a.kai == 1)
  }

  let kouzou_array: any = []
  if (room_list_search_form.kouzou_4) {
    kouzou_array.push(4)
  }
  if (room_list_search_form.kouzou_3) {
    kouzou_array.push(3)
  }
  if (room_list_search_form.kouzou_1) {
    kouzou_array.push(1)
  }
  if (room_list_search_form.kouzou_2) {
    kouzou_array.push(2)
  }
  if (room_list_search_form.kouzou_14) {
    kouzou_array.push(14)
  }
  if (room_list_search_form.kouzou_9) {
    kouzou_array.push(9)
  }
  if (kouzou_array.length > 0) {
    search_rooms = search_rooms.filter((a: any) => kouzou_array.includes(a.kouzou_no))
  }



  }

  yield put(change_search_rooms_count(search_rooms.length));

  let stock_rooms = state.StockRooms.stock_rooms
  const search_rooms_list: any = []
  let tatemono_id = 0
  search_rooms.map(function (value: any) {
    if (value) {

      if (value.tatemono_id !== tatemono_id) {
        search_rooms_list.push({
          row_type: 1,
          tatemono_name: value.tatemono_name,
          tatemono_kana: value.tatemono_kana,
          tatemono_id: value.tatemono_id,
          room_id: "",
          room_name: "",
          kuushitsu_joukyou_name: "",
          madori_name: "",
          shikuchoson: value.shikuchoson,
          choiki: value.choiki,
          other_address: value.other_address,
          chinryou: "",
          kyouekihi: "",
          pryou: "",
          shikikin_mon: "",
          reikin_mon: "",
          shunkou_year: value.shunkou_year,
          shunkou_month: value.shunkou_month,
          kanri_corp_name: value.kanri_corp_name,
          selected: value.selected,
          stocked: stock_rooms.filter((a: any) => a.tatemono_id === value.tatemono_id && a.room_id === value.room_id)[0],
          nyuukyo_kanou_year: value.nyuukyo_kanou_year,
          nyuukyo_kanou_month: value.nyuukyo_kanou_month,
          nyuukyo_kanou_day: value.nyuukyo_kanou_day,
        })
      }
      search_rooms_list.push({
        row_type: 2,
        tatemono_name: value.tatemono_name,
        tatemono_kana: value.tatemono_kana,
        tatemono_id: value.tatemono_id,
        room_id: value.room_id,
        room_name: value.room_name,
        kuushitsu_joukyou_name: value.kuushitsu_joukyou_name,
        madori_name: value.madori_name,
        shikuchoson: "",
        choiki: "",
        other_address: "",
        chinryou: value.chinryou,
        kyouekihi: value.kyouekihi,
        pryou: value.pryou,
        shunkou_year: "",
        shunkou_month: "",
        kanri_corp_name: "",
        suidouryou: value.suidouryou,
        p_ryou: value.p_ryou,
        shikikin_mon: value.shikikin_mon,
        reikin_mon: value.reikin_mon,
        selected: value.selected,
        stocked: stock_rooms.filter((a: any) => a.tatemono_id === value.tatemono_id && a.room_id === value.room_id)[0],

        nyuukyo_kanou_year: value.nyuukyo_kanou_year,
        nyuukyo_kanou_month: value.nyuukyo_kanou_month,
        nyuukyo_kanou_day: value.nyuukyo_kanou_day,

        kanri_kubun_no: value.kanri_kubun_no,
        kanri_kubun_name: value.kanri_kubun_name,

      })

      tatemono_id = value.tatemono_id
    }
  })


  yield put(change_search_rooms_list(search_rooms_list));

  // yield put(change_room_search_list_loading(false));

}




function kanaToHira(str: string) {
  if (str) {
    return str.replace(/[\u30a1-\u30f6]/g, function (match) {
      var chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  } else {
    return ""
  }
}
function han2zen(str: string) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}

function hankana2Zenkana(str: string) {
  var kanaMap: any = {
    'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
    'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
    'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
    'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
    'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
    'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
    'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
    'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
    'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
    'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
    'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
    'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
    'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
    'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
    'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
    'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
    'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
    'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
    '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
  };

  var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
  if (str) {
    return str
      .replace(reg, function (match) {
        return kanaMap[match];
      })
      .replace(/ﾞ/g, '゛')
      .replace(/ﾟ/g, '゜');
  } else {
    return str
  }
};
