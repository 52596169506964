import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as MailTemplate from './Saga/MailTemplate';

import CkEditorComponent from './CkEditorComponent';
import MailTemplateListComponent from './MailTemplateListComponent';
import MailTemplateHeaderComponent from './MailTemplateHeaderComponent';
import DataGrid from './DataGrid';
import { Row, Col } from 'reactstrap';
import Loading from '../Layout/LoadingComponent';
import MailTemplateSearch from './MailTemplateSearch';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
class MailTemplateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: "",
            subject: "",
            from: "",
            contents: "",

        };



        this.settemplate = this.settemplate.bind(this);
        this.save = this.save.bind(this);
        this.change_body = this.change_body.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.delete = this.delete.bind(this);
        this.props.get_mail_templates()
        this.props.get_mail_template_categories()
    }

    componentWillMount() {
        console.log(this.props.mailid)


        // this.props.get_mail_details(this.props.mailid)



    }
    settemplate(cateid, templateid) {
        if (templateid === 0) {
            this.props.add_mail_templates({ id: 0, BodyAll: "", subject: "", MailTemplatecategoryId: cateid, templateName: "tes" })
        } else {
            // console.log(this.props.mail_templates)

            this.setState({ cateid: cateid, templateid: templateid });
            // console.log(this.props.mail_templates.filter(a => a.id=== cateid)[0].template.filter(b => b.id=== templateid)[0].bodyAll)
            this.setState({ mailtemplate: this.props.mail_templates.filter(a => a.id === cateid)[0].template.filter(b => b.id === templateid)[0] });
        }
        // this.props.mail_templates.

    }


    change_body(state) {
        let mailtemplate = this.state.mailtemplate
        mailtemplate.BodyAll = state;
        this.setState({ mailtemplate: mailtemplate });

    }

    handleChange(state) {
        console.log(state.target.name)
        let mailtemplate = this.state.mailtemplate
        mailtemplate[state.target.name] = state.target.value;

        // mailtemplate.categoryId=this.props.mailtemplateForm.values.categoryId;
        // mailtemplate.templateName=this.props.mailtemplateForm.values.templateName;
        this.setState({ mailtemplate: mailtemplate });
    }
    save() {

        this.props.update_mail_templates(this.state.mailtemplate)
    }

    delete() {
        // alert(this.props.mail_template_details.id)
        this.props.delete_mail_templates(this.props.mail_template_details.id)
        this.setState({ mailtemplate: {} });
    }
    render() {

        console.log(this.props.mail_template_details.bodyUtf8);

        return (
            <div style={{ paddingLeft: 10, width: `calc(100%-50px)` }}>
                {this.props.user_details.amSectionId ? <Row>


                    <Col lg="4" className="item-outer" style={{ paddingRight: 0 }}>
                        {/* <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }}> */}
                        {/* <div className="item"> */}
                        <Card >
                            <CardContent>


                                <MailTemplateSearch />
                                <DataGrid settemplate={this.settemplate} />
                            </CardContent>
                        </Card >

                    </Col>


                    <Col lg="8" className="item-outer">
                        <Card style={{ height: "100%" }}>
                            <CardContent>
                                {this.props.mail_template_details.id > 0 ? <div>

                                    <MailTemplateHeaderComponent />

                                    <CkEditorComponent />


                                    <button
                                        type="button"
                                        className="btn btn-success float-right"
                                        style={{ margin: '5px' }}
                                        onClick={this.save}>保存</button>

                                    <button
                                        type="button"
                                        className="btn btn-danger float-right"
                                        style={{ margin: '5px' }}
                                        onClick={this.delete}>削除</button>
                                </div> : ""}
                            </CardContent>
                        </Card >
                    </Col>
                </Row> : ""}


            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = state => {
    return {

        // mail_details: state.mailReducer.mail_details,
        mail_templates: state.mailtemplate.mail_templates,
        mail_template_details: state.mailtemplate.mail_template_details,
        // get_mail_details_loading:state.mailtemplate.get_mail_details_loading,
        mailtemplateForm: state.form.mailtemplateForm,
        user_details: state.masterReducer.user_details,
        mail_template_search: state.mailtemplate.mail_template_search,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_mail_details(state) {
        //    dispatch(MailTemplate.get_mail_details(state))
        // },
        get_mail_templates(state) {
            dispatch(MailTemplate.get_mail_templates(state))
        },
        get_mail_template_categories(state) {
            dispatch(MailTemplate.get_mail_template_categories(state))
        },
        update_mail_templates(state) {
            dispatch(MailTemplate.update_mail_templates(state))
        },
        add_mail_templates(state) {
            dispatch(MailTemplate.add_mail_templates(state))
        },
        delete_mail_templates(state) {
            dispatch(MailTemplate.delete_mail_templates(state))
        },
        change_mail_template_search(state) {
            dispatch(MailTemplate.change_mail_template_search(state))

        }

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(MailTemplateComponent);




