
const authentication = require('../../../react-azure-adb2c2').default;

export const list = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/pages/list/` + values
    // const url = `http://localhost:5000/v1/rent/limited/public/pages/list/` + values
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(url)

    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}




export const update = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/pages/update/` + values.id
    // const url = `http://localhost:5000/v1/rent/limited/public/pages/update/` + values.id

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


export const add = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/pages/add`
    // const url = `http://localhost:5000/v1/rent/limited/public/pages/add`
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(url)

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
