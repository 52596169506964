import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as IntroductionList from './Saga/IntroductionList';
import RoomDocComponent from '../Rooms/RoomDocComponent';
// import * as Responses from '../../Store/Responses';
import '../../Css/GridRooms.css';
import LoadingComponent from '../Layout/LoadingComponent';
import moment from 'moment';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import IntroductionsGridComponent from './IntroductionsGridComponent';
import EstimateComponent from '../Estimates/Index';
// import RoomListSearch from './RoomListSearch';

// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {

    rent_response_id: number,

    insert_introductions: any,
    get_introductions: any,

    delete_introduction: any,
    update_open_introductions: any,
    togglefileup: any,
    room_details: any,
    update_introduction_list_toiawase: any,
}


interface State {

    modalsheet: boolean,
    modalestimates: boolean,
    tatemono_id: number,
    room_id: number,
    // state types
}







class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {

            modalsheet: false,
            tatemono_id: 0,
            room_id: 0,
            modalestimates: false,


        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.selectTatemono = this.selectTatemono.bind(this);
        this.onMypage = this.onMypage.bind(this);

        this.toiawase = this.toiawase.bind(this);

        this.selectsheet = this.selectsheet.bind(this);

        this.togglesheet = this.togglesheet.bind(this);
        this.estimate = this.estimate.bind(this);
        this.props.get_introductions({ rent_response_id: props.rent_response_id });
    }


    //     submit(state:any){
    //         console.log(state)
    // this.props.get_response_rooms(state)
    //     }
    handleSubmit(state: any) {
        console.log(state)


        state.rentResponseId = this.props.rent_response_id;


        // this.props.actions.get_response_rooms(state);

    }

    selectTatemono(tatemonoId: string, roomId: string) {
        // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}

        let insertdata = {
            rentResponseId: this.props.rent_response_id,
            tatemono_id: tatemonoId,
            status: 2,

            room_id: roomId,

        };


        this.props.insert_introductions(insertdata)
    }

    selectsheet(row: any) {
        console.log(row)
        if (row)
            this.setState(prevState => ({
                modalsheet: !prevState.modalsheet,
                tatemono_id: row.tatemono_id,
                room_id: row.room_id
            }));
    }
    onMypage(value: any) {
        // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}

        let updatedata = {
            id: value.id,
            rent_response_id: this.props.rent_response_id,
            tatemono_id: value.tatemono_id,
            room_id: value.room_id,
            section_id: value.section_id,

            status: value.status,
            on_mypage: value.on_mypage === 1 ? 0 : 1,
            intro_flag: value.intro_flag,
            numbering: value.numbering,
            created_at: value.created_at,
            created_user: value.created_user,
            send_flag: value.send_flag,
            remarks: value.remarks,
        };



        this.props.update_open_introductions(updatedata)
    }


    toiawase(value: any) {
        // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}

        let updatedata = {
            id: value.id,
            rent_response_id: this.props.rent_response_id,
            tatemono_id: value.tatemono_id,
            room_id: value.room_id,
            section_id: value.section_id,

            status: value.status === 1 ? 0 : 1,
            on_mypage: value.on_mypage,
            intro_flag: value.intro_flag,
            numbering: value.numbering,
            created_at: value.created_at,
            created_user: value.created_user,
            send_flag: value.send_flag,
            remarks: value.remarks,
        };



        this.props.update_introduction_list_toiawase(updatedata)
    }

    togglesheet = () => {

        this.setState(prevState => ({
            modalsheet: !prevState.modalsheet,

        }));
    }


    estimate(tatemonoId: any, roomId: any) {
        this.setState({ tatemono_id: tatemonoId, room_id: roomId })


        this.toggleestimates()
    }


    toggleestimates = () => {

        this.setState(prevState => ({
            modalestimates: !prevState.modalestimates,

        }));

    }
    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (

            <div >


                <IntroductionsGridComponent
                    rent_response_id={this.props.rent_response_id}
                    onMypage={this.onMypage}
                    selectsheet={this.selectsheet}
                    delete_introduction={this.props.delete_introduction}
                    togglefileup={this.props.togglefileup}
                    estimate={this.estimate}
                    add_mail_room={null}
                    togglesearch={null}
                    toiawase={this.toiawase}
                />


                <Modal isOpen={this.state.modalsheet} toggle={this.togglesheet} size="xxl" fade={false}>
                    <ModalHeader toggle={this.togglesheet}><b style={{ fontSize: "20px" }}>{this.props.room_details.tatemono_name} {this.props.room_details.room_name}</b></ModalHeader>

                    < RoomDocComponent tatemonoId={this.state.tatemono_id} roomId={this.state.room_id} />

                </Modal>



                <Modal isOpen={this.state.modalestimates} zIndex={3000} size="xxl" fade={false}>

                    <ModalHeader toggle={this.toggleestimates}>見積作成</ModalHeader>
                    <ModalBody style={{ background: "#9fb6cc" }}>
                        <EstimateComponent rentEstimateId={0}
                            tatemonoId={this.state.tatemono_id.toString()}
                            roomId={this.state.room_id.toString()} />
                    </ModalBody>
                    <ModalFooter>
                        {/* <button className="btn btn btn-danger" style={{margin:"5px"}} onClick={this.delete_auto_send}>除外する</button> */}
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggleestimates}>閉じる</button>
                    </ModalFooter>
                </Modal>
            </div>


        );
    }


}



const mapStateToProps = (state: any) => {
    return {

        room_details: state.roomsReducer.room_details,
        // responseid: state.customersReducer.responseid,
        // response_rooms: state.roomsReducer.response_rooms,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_introductions(state: any) {
            dispatch(IntroductionList.get_introductions(state))
        },
        insert_introductions(state: any) {
            dispatch(IntroductionList.insert_introductions(state))
        },
        delete_introduction(state: any) {
            dispatch(IntroductionList.delete_introduction(state))
        },
        update_open_introductions(state: any) {
            dispatch(IntroductionList.update_open_introductions(state))
        },
        update_introduction_list_toiawase(state: any) {
            dispatch(IntroductionList.update_introduction_list_toiawase(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);