
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';


import { startSubmit, stopSubmit, reset, submit } from 'redux-form';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as TShutokuTaiyousApi from '../Api/TShutokuTaiyousApi';
import * as TShutokusApi from '../Api/TShutokusApi';
import * as TmTatemonoListApi from '../Api/TmTatemonoListApi';
import * as TmShutokuYouinsApi from '../Api/TmShutokuYouinsApi';
import * as TmRoomListApi from '../Api/TmRoomListApi';
import * as TShutokusByNoApi from '../Api/TShutokusByNoApi';
import * as EntrustedListApi from '../Api/EntrustedListApi';


import { connectAdvanced } from 'react-redux';
import { EXITED } from 'react-transition-group/Transition';


export interface EntrustedState {
    t_shutoku_taiyous: any;
    t_shutokus: any;
    contacts_loading: number;
    tatemono_list: any;
    t_shutoku_taiyou_details: any;
    tm_shutoku_youins: any;
    entrusted_rooms: any;
    entrusted_modal: boolean;
}

const initialState: EntrustedState = {

    t_shutoku_taiyous: [],
    contacts_loading: 0,
    t_shutokus: [],
    tatemono_list: [],
    t_shutoku_taiyou_details: [],
    tm_shutoku_youins: [],
    entrusted_rooms: [],
    entrusted_modal: false,


}



///action 
const actionCreator = actionCreatorFactory();

export const get_t_shutoku_taiyous = actionCreator<any>('GET_T_SHUTOKU_TAIYOUS');
export const get_t_shutoku_taiyous_success = actionCreator<any>("GET_T_SHUTOKU_TAIYOUS_SUCCESS");
export const get_t_shutoku_taiyous_failure = actionCreator("GET_T_SHUTOKU_TAIYOUS_FAILURE");

export const get_t_shutokus = actionCreator<any>('GET_T_SHUTOKUS');
export const get_t_shutokus_success = actionCreator<any>("GET_T_SHUTOKUS_SUCCESS");
export const get_t_shutokus_failure = actionCreator("GET_T_SHUTOKUS_FAILURE");


export const get_tatemono_list = actionCreator('GET_TATEMONO_LIST');
export const get_tatemono_list_success = actionCreator("GET_TATEMONO_LIST_SUCCESS");


export const get_t_shutoku_taiyou_details = actionCreator<any>('GET_T_SHUTOKU_TAIYOU_DETAILS');
export const get_t_shutoku_taiyou_details_success = actionCreator<any>("GET_T_SHUTOKU_TAIYOU_DETAILS_SUCCESS");


export const get_tm_shutoku_youins = actionCreator('GET_TM_SHUTOKU_YOUINS');
export const get_tm_shutoku_youins_success = actionCreator("GET_TM_SHUTOKU_YOUINS_SUCCESS");


export const get_entrusted_rooms = actionCreator('GET_ENTRUSTED_ROOMS');
export const get_entrusted_rooms_success = actionCreator("GET_ENTRUSTED_ROOMS_SUCCESS");

export const update_t_shutokus = actionCreator('UPDATE_T_SHUTOKUS');

export const delete_t_shutokus = actionCreator('DELETE_T_SHUTOKUS');
// export const update_t_shutokus_success = actionCreator("UPDATE_T_SHUTOKUS_SUCCESS");
export const change_entrusted_modal = actionCreator<any>('CHANGE_ENTRUSTED_MODAL');

export const get_excel = actionCreator<any>('GET_EXCEL');


export const entrustedReducer = reducerWithInitialState(initialState)


    .case(get_t_shutoku_taiyous_success, (state, payload) => ({ ...state, t_shutoku_taiyous: payload }))
    .case(get_t_shutokus_success, (state, payload) => ({ ...state, t_shutokus: payload }))
    .case(get_tatemono_list_success, (state, payload) => ({ ...state, tatemono_list: payload }))
    .case(get_tm_shutoku_youins_success, (state, payload) => ({ ...state, tm_shutoku_youins: payload }))
    .case(get_t_shutoku_taiyou_details_success, (state, payload) => ({ ...state, t_shutoku_taiyou_details: payload }))
    .case(get_entrusted_rooms_success, (state, payload) => ({ ...state, entrusted_rooms: payload }))
    .case(change_entrusted_modal, (state, payload) => ({ ...state, entrusted_modal: payload }))




//.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
// .case(add_rent_customer_contacts, (state, payload) => ({ ...state, users: payload }));
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const entrustedSaga = [
    takeEvery('GET_T_SHUTOKU_TAIYOUS', handle_get_t_shutoku_taiyous),
    takeEvery('GET_T_SHUTOKUS', handle_get_t_shutokus),
    takeEvery('GET_TATEMONO_LIST', handle_get_tatemono_list),
    takeEvery('GET_T_SHUTOKU_TAIYOU_DETAILS', handle_get_t_shutoku_taiyou_details),
    takeEvery('GET_TM_SHUTOKU_YOUINS', handle_get_tm_shutoku_youins),
    takeEvery('GET_ENTRUSTED_ROOMS', handle_get_entrusted_rooms),
    takeEvery('UPDATE_T_SHUTOKUS', handle_update_t_shutokus),

    takeEvery('DELETE_T_SHUTOKUS', handle_delete_t_shutokus),

    takeEvery('GET_EXCEL', handle_get_excel),


    // takeEvery('UPDATE_RENT_CUSTOMER_CONTACTS', handle_update_rent_customer_contacts_Request),
    // takeEvery('DELETE_RENT_CUSTOMER_CONTACTS', handle_delete_rent_customer_contacts_Request),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_t_shutoku_taiyous(action: any) {

    const { payload, error } = yield call(TShutokuTaiyousApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        yield put(get_t_shutoku_taiyous_success(payload));


        //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_t_shutokus(action: any) {

    const { payload, error } = yield call(TShutokusApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        yield put(get_t_shutokus_success(payload));


        //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_t_shutoku_taiyou_details(action: any) {

    const { payload, error } = yield call(TShutokuTaiyousApi.detail, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)

        yield put(get_t_shutoku_taiyou_details_success(payload));


        //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_get_tatemono_list(action: any) {

    const { payload, error } = yield call(TmTatemonoListApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        //    alert("dd")
        yield put(get_tatemono_list_success(payload));


        //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_get_tm_shutoku_youins(action: any) {

    const { payload, error } = yield call(TmShutokuYouinsApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        const shutoku_youins = payload.map((item: any, i: number) => ({
            value: item.shutokuYouinNo,
            label: item.shutokuYouinName
        }))
        yield put(get_tm_shutoku_youins_success(shutoku_youins));


        //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_get_entrusted_rooms(action: any) {
    console.log(action.payload)
    // alert()
    const { payload, error } = yield call(TmRoomListApi.list, action.payload);
    let rooms = payload;
    // サーバーからのレスポンスデータによる分岐処理
    if (rooms && !error) {

        // 成功時アクション呼び出し
        const { payload, error } = yield call(TShutokusByNoApi.list, action.payload);

        if (payload && !error) {
            console.log(payload)
            let roomlist = []
            if (payload.length === 0) {
                roomlist = rooms.map((item: any, i: number) => ({
                    shutokuNo: action.payload.shutokuNo,

                    selected: 0,
                    roomName: item.roomName,
                    isPrint: false,
                    id: i
                }))

            } else {

                roomlist = payload.map((item: any, i: number) => ({
                    shutokuNo: action.payload.shutokuNo,

                    selected: 1,
                    roomName: item.roomName,
                    isPrint: item.isPrint,
                    id: 0
                }))

                for (var l = 0; l < roomlist.length; l++) {
                    roomlist[l].id = l;
                }
                //         roomlist[l].id=l;
                //     for( var i = 0; i < payload.length; i++ ) {

                //         if(payload[i].roomName === roomlist[l].roomName){
                //             roomlist[l].selected=1;
                //             if(payload[i].isPrint === true){
                //                 roomlist[l].isPrint=true;
                //             }
                //         }
                //     }
                // }
            }

            let row = roomlist.length;
            //     for( var r = 0; r < payload.length; r++ ) {
            //     l=l+1
            // let exit=0;  
            //     for( var i = 0; i < roomlist.length; i++ ) {

            //         if(payload[r].roomName === roomlist[i].roomName){
            //             exit=1;
            //         }
            //     }
            // if(exit===0){

            //     roomlist.push({
            //         shutokuNo: action.payload.shutokuNo,

            //         selected: 1,
            //         roomName: payload[r].roomName,
            //         isPrint: payload[r].isPrint,
            //         id:l
            //       })
            // }

            // }

            for (var set = roomlist.length + 1; set < 100; set++) {
                roomlist.push({
                    shutokuNo: action.payload.shutokuNo,

                    selected: 0,
                    roomName: "",
                    isPrint: false,
                    id: set
                })
            }

            yield put(get_entrusted_rooms_success(roomlist));


        } else {
            alert("error")
            //    // 失敗時アクション呼び出し
            //    yield put(loginFailure(payload));
        }

        //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}




function* handle_update_t_shutokus(action: any) {

    yield put(get_t_shutokus_success([]));
    yield put(get_t_shutoku_taiyous_success([]));
    console.log(action.payload[0])

    const { payload, error } = yield call(TShutokuTaiyousApi.update, action.payload[0]);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)

        yield put(change_entrusted_modal(false));
        //    let t_shutokus: { tatemonoId: string; shutokuNo: number} = {
        //     tatemonoId: payload[0].tatemonoId,
        //     shutokuNo: payload[0].shutokuNo,

        //    };
        // alert(payload[0].tatemonoId)




        yield put(get_t_shutokus(payload[0]));
        yield put(get_t_shutoku_taiyous(payload[0]));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_delete_t_shutokus(action: any) {

    const { payload, error } = yield call(TShutokuTaiyousApi.deleteshutoku, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)

        //    yield put(get_t_shutoku_taiyou_details_success(payload));
        yield put(get_t_shutokus_success([]));
        yield put(get_t_shutoku_taiyous_success([]));
        yield put(change_entrusted_modal(false));
        yield put(get_t_shutokus(action.payload.tatemonoId));
        yield put(get_t_shutoku_taiyous(action.payload.tatemonoId));
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}





function* handle_get_excel(action: any) {


    // const { payload, error } = yield call(EntrustedListApi.excel, action.payload);

    // // サーバーからのレスポンスデータによる分岐処理
    // if (payload && !error) {

    //     // 成功時アクション呼び出し
    //     console.log(payload)
    //     const a = document.createElement('a');
    //     // ダウンロードされるファイル名
    //     a.download = "物件取得表.xlsx";
    //     a.href = URL.createObjectURL(payload);
    //     // ダウンロード開始
    //     a.click();
    //     //    yield put(get_rent_contract_monies_success(payload));

    // } else {
    //     //    // 失敗時アクション呼び出し
    //     //    yield put(loginFailure(payload));
    // }
}