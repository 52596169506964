import React, { Component } from 'react';

import { connect } from 'react-redux';

import ChinryoEdit from './ChinryoEditComponent';
import ChinryoDisp from './ChinryoEditComponent';






class ChinryoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            chinryoedit : 1,
            mode: '1',
            //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
        };
   

    }


    render() {
       
    return (
   
        <div>
           
            {this.state.chinryoedit === 0 ? <ChinryoDisp chinryoeditchange={this.props.chinryoeditchange} /> : <ChinryoEdit closed={1} chinryoeditchange={this.props.chinryoeditchange} /> }

          
      　</div>
 
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        //tatemonoedit: state.tatemonoReducer.tatemonoedit,


    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        tatemonochange() {
            //dispatch(Responses.responseeditchange())
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChinryoComponent);
