import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { actionCreators } from '../../Store/WeatherForecasts';

import { Field, reduxForm } from 'redux-form';
import '../../Css/Table.css';
import moment from 'moment'
moment.locale('ja')
// import * as AutoKana from 'vanilla-autokana';
let autokana1;


const RenderSelectInput = ({input, options, name, id}) => (
    <Select
         {...input}
         id={id}
         name={name}
         options={options}
         value={input.value}
         onChange={(a) => input.onChange(a?a.value:0)}
         onBlur={(a) => input.onBlur(a?a.value:0)}

         placeholder=""
    />
)

const renderTextArea = ({input, meta: { touched, error, warning }}) => (
    <div>
        {/* <label>Content</label> */}
        <div>
            <textarea {...input} placeholder="" rows="4" cols="50"/>
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
);
// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (!values.name1) {
        errors.name1 = "名前を入力してください。";
    }
    if (!values.kana1) {
        errors.kana1 = "カナを入力してください。";
    }

    if (values.mail1) {
        if (!values.mail1.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            errors.mail1 = "メールアドレスの形式が正しくありません。";
        }
    }
    if (values.mail2) {
        if (!values.mail2.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            errors.mail2 = "メールアドレスの形式が正しくありません。";
        }
    }

    if (values.tel1) {
        if (!values.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel1.match(/^\d{7,13}$/)) {
            errors.tel1 = "電話番号の形式が正しくありません。";
        }
    }

    if (values.tel2) {
        if (!values.tel2.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel2.match(/^\d{7,13}$/)) {
            errors.tel2 = "電話番号の形式が正しくありません。";
        }
    }

    if (values.fax) {
        if (!values.fax.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.fax.match(/^\d{7,13}$/)) {
            errors.fax = "FAXの形式が正しくありません。";
        }
    }

    if (values.post) {
        //if (!values.post.match(/^\d{3}-\d{4}$/)) {
        //    errors.post = "郵便番号の形式が正しくありません。";
        //}
    }
    return errors;
};

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, style, meta: { touched, error } }) => (
//     <div >
//         <input {...input} type={type} id={id} placeholder={placeholder} style={{ width: "80" }} className={touched && error ? 'alert-danger ' + className : className} />
//         {touched && error && <span className="text-danger">{error}</span>}
//     </div>
// );

export class RenderField extends React.Component {
    render() {
        const { className, type, input, disabled, meta: { touched, error } } = this.props;
        
        return (
            <div>
                {type === 'textarea' ?
                    <textarea className={className} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select className={className} {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input className={className} {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};

const ReduxFormDateRange = (props) => {
    

    console.log(props)
    return (

        <DatePicker
            
            selected={props.input.value!==""?new Date(props.input.value) :""}
            //selected={moment(props.input.value, "YYYY-MM-DD").format("YYYY-MM-DD") || null}
            onChange={props.input.onChange}
            //showTimeSelect
            dateFormat="yyyy/MM/dd"
            className="form-control"
        />
    )
}

class RoomEdit extends Component {
   
    constructor(props) {
        super(props);
      
        this.state = {
            customerinfo:this.props.forecasts,
            startDate: new Date()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }
    
  componentDidMount() {
    // This method is called when the component is first added to the document
      this.ensureDataFetched();

  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.ensureDataFetched();
  }

  ensureDataFetched() {
      //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
      //console.log(startDateIndex)

      //this.props.requestWeatherForecasts(0);

    }


    onChangeName(property, event) {

        if (property === "tatemonoKana") {
            this.props.change(property, autokana1.getFurigana())
        } else if (property === "kana2") {
            
        }


    }

    render() {

        const year = [""];
        const day = ["", "上旬", "中旬", "下旬"];

        var hiduke = new Date();
        for (var i = hiduke.getFullYear() -1; i <= hiduke.getFullYear() + 1; i++) {
            year.push(i);
        }
        for (var i2 = 1; i2 <= 31; i2++) {
            day.push(i2);
        }
        const { handleSubmit} = this.props;
        //moment.locale('ja')
      return (
        <form onSubmit={handleSubmit}  >
          <div className="row">
              <div className="item-outer col-lg-12">
                  <div className="">
       
            <table>
                <colgroup width='15%' />
                <colgroup width='35%' />
                <colgroup width='15%' />
                <colgroup width='35%' />
                <tbody>

                    <tr >
                        <th>契約日</th>
                        <td>
                     
                            <Field
                                name="keiyakuDate"
                                id="keiyakuDate"
                                component={ReduxFormDateRange}
                                onChange={this.handleDate}
                                type="text"
                                placeholder=""
                                className="form-control"
                            />
                        </td>
                        <th>戸数（区画数）</th>
                        <td>
                            <Field
                                name="koSuu"
                                id="koSuu"
                                type="text"
                                placeholder=""
                                className="form-control"
                                component="select">
                                <option value="1">+</option>
                                <option value="-1">-</option>
                            </Field>
                        </td>
                    </tr>
                    <tr >
                        <th>受託担当者</th>
                        <td>
                        <Field

name="jutakuTantouUserId"
options={this.props.users_select}
     component={RenderSelectInput} />
                            {/* <Field name="jutakuTantouUserId"
                                id="jutakuTantouUserId"
                                className="form-control"
                                type="select"
                                component={RenderField}>
                                <option value="0">-</option>
                                {
                                    this.props.users.map(function (item, i) {
                                        return (
                                            <option key={i} value={item.id}>{item.userNameAll}</option>
                                        );

                                    })
                                }
                            </Field> */}

                        </td>
                        <th>一部屋あたりの金額</th>
                        <td>
                        <div className="form-inline">
                            <Field
                                name="hitoHeyaKinS"
                                id="hitoHeyaKinS"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                style={{width:120}}
                            />円～<Field
                                name="hitoHeyaKinE"
                                id="hitoHeyaKinE"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                style={{width:120}}
                            />円
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <th>管理区分</th>
                            <td>

                                <Field
                                          name="kanriKubunNo"
                                          id="kanriKubunNo"
                                      type="text"
                                      placeholder=""
                                      className="form-control"
                                      component="select"
                                  >
                                      <option value="-1">-</option>
                                      <option value="1">管理</option>
                                      <option value="2">一般</option>
                                      <option value="3">専任</option>
                                      <option value="4">サブ</option>
                                      <option value="5">管理外</option>
                                      <option value="6">業物</option>
                                  </Field>
                        </td>
                        <th>管理部屋の合計金額</th>
                        <td>
                        <div className="form-inline">
                            <Field
                                name="kanriHeyaKin"
                                id="kanriHeyaKin"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                style={{width:120}}
                            />円
                            </div>
                        </td>
                    </tr>
                    
                    <tr >
                        <th>取得要因</th>
                        <td>
                        <Field

name="shutokuYouinNo"
options={this.props.tm_shutoku_youins}
     component={RenderSelectInput} />
                           
                        </td>
                        <th>満室時予想管理料</th>
                        <td>
                        <div className="form-inline">
                            <Field
                                name="manshitsuKin"
                                id="manshitsuKin"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                style={{width:120}}
                            />円
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <th>管理料率</th>
                            <td>
                                <div className="form-inline">
                                <Field
                                    name="kanriRitsu"
                                    id="kanriRitsu"
                                    type="number"
                                    placeholder=""
                                    className="form-control"
                                    component="input"
                                    style={{width:80}}
                                />％
                                </div>
                        </td>
                        <th>借上金額合計</th>
                        <td>
                        <div className="form-inline">
                            <Field
                                name="kariageKin"
                                id="kariageKin"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                style={{width:120}}
                            />円
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <th>管理契約開始日</th>
                        <td>
                            <Field
                                name="kanriKaishiDate"
                                id="kanriKaishiDate"
                                component={ReduxFormDateRange}
                                type="text"
                                placeholder=""
                                className="form-control"
                            />
                        </td>
                        <th>借上(サブ)部屋の合計金額</th>
                        <td>
                            <div className="form-inline">
                            <Field
                                name="subKin"
                                id="subKin"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                style={{width:120}}
                            />円
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <th>管理契約終了日</th>
                        <td>
                            <Field
                                name="kanriShuuryouDate"
                                id="kanriShuuryouDate"
                                component={ReduxFormDateRange}
                                type="text"
                                placeholder=""
                                className="form-control"
                            />
                        </td>
                        <th>差額金額合計</th>
                        <td>
                        <div className="form-inline">
                            <Field
                                name="sagakuKin"
                                id="sagakuKin"
                                type="number"
                                placeholder=""
                                className="form-control"
                                component="input"
                                style={{width:120}}
                            />円
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <th>サブリース契約金発生日</th>
                        <td>
                            <Field
                                name="subHasseiDate"
                                id="subHasseiDate"
                                component={ReduxFormDateRange}
                                type="text"
                                placeholder=""
                                className="form-control"
                            />
                        </td>
                        <th rowSpan={3}>備考</th>
                        <td rowSpan={3}>
                        <Field
                                name="bikou"
                                id="bikou"
                                component={renderTextArea}
                                type="text"
                                placeholder=""
                                className="form-control"
                            />
                        </td>
                    </tr>
                    <tr >
                        <th>担当店舗名</th>
                        <td>
                        <Field

name="tenpoNo"
options={this.props.section_select}
     component={RenderSelectInput} />
                           
                        </td>
                       
                    </tr>
                    <tr >
                        <th>オーナー名</th>
                        <td>
                        {/* <Field

name="ownerId"
options={this.props.tm_owners_select}
     component={RenderSelectInput} /> */}
                           
                        </td>
                       
                    </tr>
                </tbody>
              </table>

              </div>
          </div>
          </div>
          </form>
    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        users_select: state.masterReducer.users_select,
        initialValues:state.entrusted.t_shutoku_taiyou_details,
        tm_shutoku_youins: state.entrusted.tm_shutoku_youins,
        section_select: state.masterReducer.section_select,
        // tm_owners_select: state.masterReducer.tm_owners_select,
        
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

RoomEdit = reduxForm({
    form: 'estrustedform', // a unique identifier for this form
    validate,//バリデーション
    enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(RoomEdit)

export default connect(mapStateToProps, mapDispatchToProps)(RoomEdit);