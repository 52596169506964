import React, { Component } from 'react';

import { connect } from 'react-redux';
// import * as Estimates from './Saga/Estimates';
// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';


import * as Contracts from './Saga/Contracts';
import "react-input-range/lib/css/index.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const moment = require('moment-timezone');
class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.numPages = this.numPages.bind(this);
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }

  render() {








    let hiwari_delete = 0
    let hiwarimonth_delete = this.props.rent_contract_details_old.rent_contract_money_logs ? this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 5).map(function (value) {


      let amount = ""
      if (this.props.rent_contract_details_new.rent_contract_money_logs && this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0]) {

      } else {
        hiwari_delete = hiwari_delete + 1
        let old = this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0]
        let amount_old = old.rent_m_etc_id === 1 ? "実費" : old.rent_m_etc_id === 2 ? "込み" : old.rent_m_etc_id === 3 ? "無し" : old.rent_m_etc_id === 4 ? "WEB" : (old.amount + old.tax_amount) ? (old.amount + old.tax_amount).toLocaleString() : "-"
        let amount_old_tax = old.tax_amount ? "(" + old.tax_amount.toLocaleString() + ")" : "-"
        let freerent_old = old.is_free_rent ? "ﾌﾘｰﾚﾝﾄ " : ""


        return <tr style={{ background: "pink" }}>

          < td > {value.display_name ? value.display_name : value.name}【削除】</td>
          < td style={{ textAlign: "right" }}> {value.billing ? "" : amount_old}</td>
          < td >  {value.billing ? "" : amount_old_tax}</td>
          {this.props.rent_contract_details_new.contract_form ? <td style={{ textAlign: "right" }}>{value.billing ? amount : ""}</td> : ""}
          {this.props.rent_contract_details_new.contract_form ? <td > {value.billing ? amount_old_tax : ""}</td> : ""}

          < td > {freerent_old}{value.remarks}</td>
          < td style={{ textAlign: "right" }}> </td>

        </tr >


      }
    }, this) : ""






    let hiwari = 0
    let goukei_new = 0
    let goukei_tax_new = 0
    let goukei_n_new = 0
    let goukei_n_tax_new = 0

    let goukei_old = 0
    let goukei_tax_old = 0
    let goukei_n_old = 0
    let goukei_n_tax_old = 0
    let hiwarimonth = parseInt(moment(this.props.rent_contract_details_new.contract_date).format("D")) > 1 ? this.props.rent_contract_details_new.rent_contract_money_logs ? this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 5).map(function (value) {
      hiwari = hiwari + 1
      if(!value.is_free_rent){
        if (value.billing) {
          goukei_n_new = goukei_n_new + value.amount + value.tax_amount
          goukei_n_tax_new = goukei_n_tax_new + value.tax_amount
        } else {
          goukei_new = goukei_new + value.amount + value.tax_amount
          goukei_tax_new = goukei_tax_new + value.tax_amount
        }
      }

      let boshu = ""

      let amount = ""
      let amount_tax = ""
      let remarks = ""
      let color = ""
      if (this.props.rent_contract_details_old.rent_contract_money_logs && this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0]) {
        let old = this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0]
        if(!old.is_free_rent){
          if (old.billing) {
            goukei_n_old = goukei_n_old + old.amount + old.tax_amount
            goukei_n_tax_old = goukei_n_tax_old + old.tax_amount
          } else {
            goukei_old = goukei_old + old.amount + old.tax_amount
            goukei_tax_old = goukei_tax_old + old.tax_amount
          }
        }


        let amount_old = old.rent_m_etc_id === 1 ? "実費" : old.rent_m_etc_id === 2 ? "込み" : old.rent_m_etc_id === 3 ? "無し" : old.rent_m_etc_id === 4 ? "WEB" : (old.amount + old.tax_amount) ? (old.amount + old.tax_amount).toLocaleString() : "-"
        let amount_new = value.rent_m_etc_id === 1 ? "実費" : value.rent_m_etc_id === 2 ? "込み" : value.rent_m_etc_id === 3 ? "無し" : value.rent_m_etc_id === 4 ? "WEB" : (value.amount + value.tax_amount) ? (value.amount + value.tax_amount).toLocaleString() : "-"

        let amount_old_tax = old.tax_amount ? "(" + old.tax_amount.toLocaleString() + ")" : "-"
        let amount_new_tax = value.tax_amount ? "(" + value.tax_amount.toLocaleString() + ")" : "-"

        let remarks_old = old.is_free_rent ? "ﾌﾘｰﾚﾝﾄ " + old.remarks : old.remarks
        let remarks_new = value.is_free_rent ? "ﾌﾘｰﾚﾝﾄ " + value.remarks : value.remarks
        color = value.is_free_rent ? "#cccccc" : ""

        if (amount_old !== amount_new) {
          amount = <span><div style={{ color: "red", fontWeight: "bold" }}>{amount_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{amount_new}</div></span>
          amount_tax = <span><div style={{ color: "red", fontWeight: "bold" }}>{amount_old_tax}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{amount_new_tax}</div></span>

        } else {
          amount = amount_new
          amount_tax = amount_new_tax
        }

        if (remarks_old !== remarks_new) {
          remarks = <span><div style={{ color: "red", fontWeight: "bold" }}>{remarks_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{remarks_new}</div></span>
          
        } else {
          remarks = remarks_new
        }
      }


      return <tr>
        {hiwari === 1 ? <th rowSpan={this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 5).length + hiwari_delete}> {moment(this.props.rent_contract_details_new.contract_date).format("M月")}</th> : ""}
        < td style={{backgroundColor: color}}> {value.display_name ? value.display_name : value.name}</td>
        < td style={{ textAlign: "right", backgroundColor: color }}> {value.billing ? "" : amount}</td>
        < td style={{backgroundColor: color}}>  {value.billing ? "" : amount_tax}</td>
        {this.props.rent_contract_details_new.contract_form ? <td style={{ textAlign: "right", backgroundColor: color }}>{value.billing ? amount : ""}</td> : ""}
        {this.props.rent_contract_details_new.contract_form ? <td style={{backgroundColor: color}}> {value.billing ? amount_tax : ""}</td> : ""}

        < td style={{backgroundColor: color}}> {remarks}</td>
        < td style={{ textAlign: "right" }}> </td>
      </tr>

    }, this) : "" : ""








    let basemonth_delete_count = 0
    let basemonth_delete = this.props.rent_contract_details_old.rent_contract_money_logs ? this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 1).map(function (value) {


      let amount = ""
      if (this.props.rent_contract_details_new.rent_contract_money_logs && this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 1 && a.rent_m_account_id === value.rent_m_account_id)[0]) {

      } else {
        basemonth_delete_count = basemonth_delete_count + 1
        let old = this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 1 && a.rent_m_account_id === value.rent_m_account_id)[0]
        if(!old.is_free_rent){
          if (old.billing) {
            goukei_n_old = goukei_n_old + old.amount + old.tax_amount
            goukei_n_tax_old = goukei_n_tax_old + old.tax_amount
          } else {
            goukei_old = goukei_old + old.amount + old.tax_amount
            goukei_tax_old = goukei_tax_old + old.tax_amount
          }
        }

        let amount_old = old.rent_m_etc_id === 1 ? "実費" : old.rent_m_etc_id === 2 ? "込み" : old.rent_m_etc_id === 3 ? "無し" : old.rent_m_etc_id === 4 ? "WEB" : (old.amount + old.tax_amount) ? (old.amount + old.tax_amount).toLocaleString() : "-"
        let amount_old_tax = old.tax_amount ? "(" + old.tax_amount.toLocaleString() + ")" : "-"
        let freerent_old = old.is_free_rent ? "ﾌﾘｰﾚﾝﾄ" : "-"

        return <tr style={{ background: "pink" }}>

          < td > {value.display_name ? value.display_name : value.name}【削除】</td>
          < td style={{ textAlign: "right" }}> {value.billing ? "" : amount_old}</td>
          < td >  {value.billing ? "" : amount_old_tax}</td>
          {this.props.rent_contract_details_new.contract_form ? <td style={{ textAlign: "right" }}>{value.billing ? amount_old : ""}</td> : ""}
          {this.props.rent_contract_details_new.contract_form ? <td > {value.billing ? amount_old_tax : ""}</td> : ""}

          < td > {freerent_old}{value.remarks}</td>
          < td style={{ textAlign: "right" }}> </td>
        </tr >


      }
    }, this) : ""





    let base = 0
    let basemonth = this.props.rent_contract_details_new.rent_contract_money_logs ? this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 1).map(function (value) {
      base = base + 1
      if(!value.is_free_rent){
        if (value.billing) {
          goukei_n_new = goukei_n_new + value.amount + value.tax_amount
          goukei_n_tax_new = goukei_n_tax_new + value.tax_amount
        } else {
          goukei_new = goukei_new + value.amount + value.tax_amount
          goukei_tax_new = goukei_tax_new + value.tax_amount
        }
      }
      let boshu = ""
      if (this.props.rent_contract_boshu_chinryou.filter(a => a.chinryou_koumoku_no === value.rent_m_account_id)[0]) {
        boshu = this.props.rent_contract_boshu_chinryou.filter(a => a.chinryou_koumoku_no === value.rent_m_account_id)[0]
        let boshu_gaku = this.props.rent_contract_boshu_chinryou.filter(a => a.chinryou_koumoku_no === value.rent_m_account_id)[0].is_kazei ? Math.round(boshu.seikyuu_gaku * 1.1) : boshu.seikyuu_gaku
        boshu = boshu.hoka_seikyuu_no === 1 ? "実費" : boshu.hoka_seikyuu_no === 2 ? "込み" : boshu.hoka_seikyuu_no === 3 ? "無し" : boshu_gaku ? boshu_gaku.toLocaleString() : "-"

      }



      let amount = ""
      let amount_tax = ""
      let remarks = ""
      let color = ""
      if (this.props.rent_contract_details_old.rent_contract_money_logs && this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 1 && a.rent_m_account_id === value.rent_m_account_id)[0]) {
        let old = this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 1 && a.rent_m_account_id === value.rent_m_account_id)[0]
        if(!old.is_free_rent){
          if (old.billing) {
            goukei_n_old = goukei_n_old + old.amount + old.tax_amount
            goukei_n_tax_old = goukei_n_tax_old + old.tax_amount
          } else {
            goukei_old = goukei_old + old.amount + old.tax_amount
            goukei_tax_old = goukei_tax_old + old.tax_amount
          }
        }
        let amount_old = old.rent_m_etc_id === 1 ? "実費" : old.rent_m_etc_id === 2 ? "込み" : old.rent_m_etc_id === 3 ? "無し" : old.rent_m_etc_id === 4 ? "WEB" : (old.amount + old.tax_amount) ? (old.amount + old.tax_amount).toLocaleString() : "-"
        let amount_new = value.rent_m_etc_id === 1 ? "実費" : value.rent_m_etc_id === 2 ? "込み" : value.rent_m_etc_id === 3 ? "無し" : value.rent_m_etc_id === 4 ? "WEB" : (value.amount + value.tax_amount) ? (value.amount + value.tax_amount).toLocaleString() : "-"
        let amount_old_tax = old.tax_amount ? "(" + old.tax_amount.toLocaleString() + ")" : "-"
        let amount_new_tax = value.tax_amount ? "(" + value.tax_amount.toLocaleString() + ")" : "-"
        let remarks_old = old.is_free_rent ? "ﾌﾘｰﾚﾝﾄ " + (old.remarks ? old.remarks : "") : old.remarks
        let remarks_new = value.is_free_rent ? "ﾌﾘｰﾚﾝﾄ " + (value.remarks ? value.remarks : "") : value.remarks
        color = value.is_free_rent ? "#cccccc" : ""



        if (amount_old !== amount_new) {
          amount = <span><div style={{ color: "red", fontWeight: "bold" }}>{amount_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{amount_new}</div></span>
          amount_tax = <span><div style={{ color: "red", fontWeight: "bold" }}>{amount_old_tax}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{amount_new_tax}</div></span>

        } else {
          amount = amount_new
          amount_tax = amount_new_tax
        }

        if (remarks_old !== remarks_new) {
          remarks = <span><div style={{ color: "red", fontWeight: "bold" }}>{remarks_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{remarks_new}</div></span>
          
        } else {
          remarks = remarks_new
        }
      }


      return <tr>
        {base === 1 ? <th rowSpan={this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 1).length + basemonth_delete_count}> {parseInt(moment(this.props.rent_contract_details_new.contract_date).format("D")) > 1 ? moment(this.props.rent_contract_details_new.contract_date).startOf("month").add(1, 'months').format("M月") : moment(this.props.rent_contract_details_new.contract_date).format("M月")}</th> : ""}
        < td style={{backgroundColor: color}}> {value.display_name ? value.display_name : value.name}</td>
        < td style={{ textAlign: "right", backgroundColor: color }}> {value.billing ? "" : amount}</td>
        < td style={{backgroundColor: color}} >  {value.billing ? "" : amount_tax}</td>
        {this.props.rent_contract_details_new.contract_form ? <td style={{ textAlign: "right", backgroundColor: color }}>{value.billing ? amount : ""}</td> : ""}
        {this.props.rent_contract_details_new.contract_form ? <td style={{backgroundColor: color}}> {value.billing ? amount_tax : ""}</td> : ""}

        < td style={{backgroundColor: color}} > {remarks}</td>
        < td style={{ textAlign: "right", background: amount !== boshu ? "pink" : "#98fb98" }}> {boshu}</td>

      </tr>
    }, this) : ""




    let nextmonth = []
    for (var p = 0; p < this.props.rent_contract_details_new.pay_nextmonth; p++) {
      base = 0
      // eslint-disable-next-line no-loop-func
      nextmonth.push(this.props.rent_contract_details_new.rent_contract_money_logs ? this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 1).map(function (value) {
        base = base + 1
        if (value.billing) {
          goukei_n_new = goukei_n_new + value.amount + value.tax_amount
          goukei_n_tax_new = goukei_n_tax_new + value.tax_amount
        } else {
          goukei_new = goukei_new + value.amount + value.tax_amount
          goukei_tax_new = goukei_tax_new + value.tax_amount
        }

        let boshu = ""
        if (this.props.rent_contract_boshu_chinryou.filter(a => a.chinryou_koumoku_no === value.rent_m_account_id)[0]) {
          boshu = this.props.rent_contract_boshu_chinryou.filter(a => a.chinryou_koumoku_no === value.rent_m_account_id)[0]
          let boshu_gaku = this.props.rent_contract_boshu_chinryou.filter(a => a.chinryou_koumoku_no === value.rent_m_account_id)[0].is_kazei ? Math.round(boshu.seikyuu_gaku * 1.1) : boshu.seikyuu_gaku
          boshu = boshu.hoka_seikyuu_no === 1 ? "実費" : boshu.hoka_seikyuu_no === 2 ? "込み" : boshu.hoka_seikyuu_no === 3 ? "無し" : boshu_gaku ? boshu_gaku.toLocaleString() : "-"

        }



        let amount = ""
        let amount_tax = ""
        if (this.props.rent_contract_details_old.rent_contract_money_logs && this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 1 && a.rent_m_account_id === value.rent_m_account_id)[0]) {
          let old = this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 1 && a.rent_m_account_id === value.rent_m_account_id)[0]
          if (old.billing) {
            goukei_n_old = goukei_n_old + old.amount + old.tax_amount
            goukei_n_tax_old = goukei_n_tax_old + old.tax_amount
          } else {
            goukei_old = goukei_old + old.amount + old.tax_amount
            goukei_tax_old = goukei_tax_old + old.tax_amount
          }
          let amount_old = old.rent_m_etc_id === 1 ? "実費" : old.rent_m_etc_id === 2 ? "込み" : old.rent_m_etc_id === 3 ? "無し" : old.rent_m_etc_id === 4 ? "WEB" : (old.amount + old.tax_amount) ? (old.amount + old.tax_amount).toLocaleString() : "-"
          let amount_new = value.rent_m_etc_id === 1 ? "実費" : value.rent_m_etc_id === 2 ? "込み" : value.rent_m_etc_id === 3 ? "無し" : value.rent_m_etc_id === 4 ? "WEB" : (value.amount + value.tax_amount) ? (value.amount + value.tax_amount).toLocaleString() : "-"
          let amount_old_tax = old.tax_amount ? "(" + old.tax_amount.toLocaleString() + ")" : "-"
          let amount_new_tax = value.tax_amount ? "(" + value.tax_amount.toLocaleString() + ")" : "-"




          if (amount_old !== amount_new) {
            amount = <span><div style={{ color: "red", fontWeight: "bold" }}>{amount_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{amount_new}</div></span>
            amount_tax = <span><div style={{ color: "red", fontWeight: "bold" }}>{amount_old_tax}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{amount_new_tax}</div></span>

          } else {
            amount = amount_new
            amount_tax = amount_new_tax
          }
        }


        return <tr>
          {base === 1 ? <th rowSpan={this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 1).length + basemonth_delete_count}> {parseInt(moment(this.props.rent_contract_details_new.contract_date).format("D")) > 1 ? moment(this.props.rent_contract_details_new.contract_date).startOf("month").add(p + 2, 'months').format("M月") : moment(this.props.rent_contract_details_new.contract_date).add(p + 1, 'months').format("M月")}</th> : ""}
          < td > {value.display_name ? value.display_name : value.name}</td>
          < td style={{ textAlign: "right" }}> {value.billing ? "" : amount}</td>
          < td >  {value.billing ? "" : amount_tax}</td>
          {this.props.rent_contract_details_new.contract_form ? <td style={{ textAlign: "right" }}>{value.billing ? amount : ""}</td> : ""}
          {this.props.rent_contract_details_new.contract_form ? <td > {value.billing ? amount_tax : ""}</td> : ""}

          < td > {value.remarks}</td>
          < td style={{ textAlign: "right", background: amount !== boshu ? "pink" : "#98fb98" }}> {boshu}</td>

        </tr>
      }, this) : "")
    }





    let ontime = 0
    let ontimemonth = this.props.rent_contract_details_new.rent_contract_money_logs ? this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 2).map(function (value) {
      ontime = ontime + 1
      if(!value.is_free_rent){
        if (value.billing) {
          goukei_n_new = goukei_n_new + value.amount + value.tax_amount
          goukei_n_tax_new = goukei_n_tax_new + value.tax_amount
        } else {
          goukei_new = goukei_new + value.amount + value.tax_amount
          goukei_tax_new = goukei_tax_new + value.tax_amount
        }
      }
      let boshu = ""
      if (this.props.rent_contract_boshu_chinryou.filter(a => a.chinryou_koumoku_no === value.rent_m_account_id)[0]) {
        boshu = this.props.rent_contract_boshu_chinryou.filter(a => a.chinryou_koumoku_no === value.rent_m_account_id)[0]
        let boshu_gaku = this.props.rent_contract_boshu_chinryou.filter(a => a.chinryou_koumoku_no === value.rent_m_account_id)[0].is_kazei ? Math.round(boshu.seikyuu_gaku * 1.1) : boshu.seikyuu_gaku
        boshu = boshu.hoka_seikyuu_no === 1 ? "実費" : boshu.hoka_seikyuu_no === 2 ? "込み" : boshu.hoka_seikyuu_no === 3 ? "無し" : boshu_gaku ? boshu_gaku.toLocaleString() : "-"
      }


      let amount = ""
      let amount_tax = ""
      if (this.props.rent_contract_details_old.rent_contract_money_logs && this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 2 && a.rent_m_account_id === value.rent_m_account_id)[0]) {
        let old = this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 2 && a.rent_m_account_id === value.rent_m_account_id)[0]
        if(!old.is_free_rent){
          if (old.billing) {
            goukei_n_old = goukei_n_old + old.amount + old.tax_amount
            goukei_n_tax_old = goukei_n_tax_old + old.tax_amount
          } else {
            goukei_old = goukei_old + old.amount + old.tax_amount
            goukei_tax_old = goukei_tax_old + old.tax_amount
          }
        }

        let amount_old = old.rent_m_etc_id === 1 ? "実費" : old.rent_m_etc_id === 2 ? "込み" : old.rent_m_etc_id === 3 ? "無し" : old.rent_m_etc_id === 4 ? "WEB" : (old.amount + old.tax_amount) ? (old.amount + old.tax_amount).toLocaleString() : "-"
        let amount_new = value.rent_m_etc_id === 1 ? "実費" : value.rent_m_etc_id === 2 ? "込み" : value.rent_m_etc_id === 3 ? "無し" : value.rent_m_etc_id === 4 ? "WEB" : (value.amount + value.tax_amount) ? (value.amount + value.tax_amount).toLocaleString() : "-"
        let amount_old_tax = old.tax_amount ? "(" + old.tax_amount.toLocaleString() + ")" : "-"
        let amount_new_tax = value.tax_amount ? "(" + value.tax_amount.toLocaleString() + ")" : "-"


        if (amount_old !== amount_new) {
          amount = <span><div style={{ color: "red", fontWeight: "bold" }}>{amount_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{amount_new}</div></span>
          amount_tax = <span><div style={{ color: "red", fontWeight: "bold" }}>{amount_old_tax}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{amount_new_tax}</div></span>

        } else {
          amount = amount_new
          amount_tax = amount_new_tax
        }
      }
      return <tr>

        < td colSpan="2"> {value.display_name ? value.display_name : value.name}</td>
        < td style={{ textAlign: "right" }}> {value.billing ? "" : amount}</td>
        < td >  {value.billing ? "" : amount_tax}</td>
        {this.props.rent_contract_details_new.contract_form ? <td style={{ textAlign: "right" }}>{value.billing ? amount : ""}</td> : ""}
        {this.props.rent_contract_details_new.contract_form ? <td > {value.billing ? amount_tax : ""}</td> : ""}
        < td > {value.remarks}</td>
        < td style={{ textAlign: "right", background: amount !== boshu ? "pink" : "#98fb98" }}> {boshu}</td>
      </tr >
    }, this) : ""


    let ontime_delete = 0
    let ontimemonth_delete = this.props.rent_contract_details_old.rent_contract_money_logs ? this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 2).map(function (value) {
      ontime_delete = ontime_delete + 1

      let amount = ""
      if (this.props.rent_contract_details_new.rent_contract_money_logs && this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 2 && a.rent_m_account_id === value.rent_m_account_id)[0]) {

      } else {
        let old = this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 2 && a.rent_m_account_id === value.rent_m_account_id)[0]
        if(!old.is_free_rent){
          if (old.billing) {
            goukei_n_old = goukei_n_old + old.amount + old.tax_amount
            goukei_n_tax_old = goukei_n_tax_old + old.tax_amount
          } else {
            goukei_old = goukei_old + old.amount + old.tax_amount
            goukei_tax_old = goukei_tax_old + old.tax_amount
          }
        }

        let amount_old = old.rent_m_etc_id === 1 ? "実費" : old.rent_m_etc_id === 2 ? "込み" : old.rent_m_etc_id === 3 ? "無し" : old.rent_m_etc_id === 4 ? "WEB" : (old.amount + old.tax_amount) ? (old.amount + old.tax_amount).toLocaleString() : "-"
        let amount_old_tax = old.tax_amount ? "(" + old.tax_amount.toLocaleString() + ")" : "-"

        return <tr style={{ background: "pink" }}>

          < td colSpan="2"> {value.display_name ? value.display_name : value.name}【削除】</td>
          < td style={{ textAlign: "right" }}> {value.billing ? "" : amount_old}</td>
          < td >  {value.billing ? "" : amount_old_tax}</td>
          {this.props.rent_contract_details_new.contract_form ? <td >{value.billing ? amount_old : ""}</td> : ""}
          {this.props.rent_contract_details_new.contract_form ? <td >{value.billing ? amount_old_tax : ""}</td> : ""}
          < td > {value.remarks}</td>
          < td style={{ textAlign: "right" }}> </td>
        </tr >


      }
    }, this) : ""

    let goukei = ""
    let goukei_tax = ""
    let goukei_n = ""
    let goukei_n_tax = ""
    if (goukei_old !== goukei_new) {
      goukei = <span><div style={{ color: "red", fontWeight: "bold" }}>{goukei_old.toLocaleString()}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{goukei_new.toLocaleString()}</div></span>
      goukei_tax = <span><div style={{ color: "red", fontWeight: "bold" }}>{goukei_tax_old ? "(" + goukei_tax_old.toLocaleString() + ")" : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{goukei_tax_new ? "(" + goukei_tax_new.toLocaleString() + ")" : "-"}</div></span>

    } else {
      goukei = goukei_new.toLocaleString()
      goukei_tax = goukei_tax_new ? "(" + goukei_tax_new.toLocaleString() + ")" : "-"
    }

    if (goukei_n_old !== goukei_n_new) {
      goukei_n = <span><div style={{ color: "red", fontWeight: "bold" }}>{goukei_n_old.toLocaleString()}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{goukei_n_new.toLocaleString()}</div></span>
      goukei_n_tax = <span><div style={{ color: "red", fontWeight: "bold" }}>{goukei_n_tax_old ? "(" + goukei_n_tax_old.toLocaleString() + ")" : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{goukei_n_tax_new ? "(" + goukei_n_tax_new.toLocaleString() + ")" : "-"}</div></span>

    } else {
      goukei_n = goukei_n_new.toLocaleString()
      goukei_n_tax = goukei_n_tax_new ? "(" + goukei_n_tax_new.toLocaleString() + ")" : "-"
    }
    let goukeiall = < tr >

      < td colSpan="2"> 合計</td>
      < td style={{ textAlign: "right" }} > {goukei}</td>
      < td >{goukei_tax}</td>
      {this.props.rent_contract_details_new.contract_form ? <td style={{ textAlign: "right" }}>{goukei_n}</td> : ""}
      {this.props.rent_contract_details_new.contract_form ? <td>{goukei_n_tax}</td> : ""}
      < td > </td>
      < td > </td>

    </tr >

    return (

      <div>

        <table>
          <tr>

            <th colSpan="2">項目名</th>
            <th colSpan="2">契約者</th>
            {this.props.rent_contract_details_new.contract_form ? <th colSpan="2">入居者</th> : ""}
            < th > 備考</th>
            <th>募集


            </th>
          </tr>


          {hiwarimonth}
          {hiwarimonth_delete}

          {basemonth}
          {basemonth_delete}
          {nextmonth.map(function (value) {
            return value
          })}
          {ontimemonth}
          {ontimemonth_delete}
          {goukeiall}
        </table>
      </div >

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_boshu_chinryou: state.contracts.rent_contract_boshu_chinryou,
    contract_money_edit_disp: state.contracts.contract_money_edit_disp,
    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,

  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_rent_contract_editor_disp(state) {
      dispatch(Contracts.change_rent_contract_editor_disp(state))
    },
    // update_customerdetail(state) {
    //     dispatch(Customers.update_customerdetail(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
