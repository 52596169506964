import React, { Component } from 'react';
// import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as MailTemplate from './Saga/MailTemplate';
import * as Mail from '../Mail/Saga/Mail';
import * as Master from '../../Store/Master';
import MailCkEditorComponent from './MailCkEditorComponent';
import ParamGrid from './ParamGrid';
import AutoSendMailTemplateCategoryEdit from './AutoSendMailTemplateCategoryEdit';
// import * as store from '../../index'
import '../../Css/Grid.css';
import IconButton from '@mui/material/IconButton';
// import MuiButton from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { FormLabel, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import './Css/Grid.css'

// import LoadingComponent from '../Layout/LoadingComponent';
const ReactDataGrid = require('react-data-grid');




const timelist=[
    {value: 21, label: "10:00"},
    {value: 22, label: "10:30"},
    {value: 23, label: "11:00" },
    {value: 24, label: "11:30" },
    {value: 25, label: "12:00" },
    {value: 26, label: "12:30" },
    {value: 27, label: "13:00" },
    {value: 28, label: "13:30" },
    {value: 29, label: "14:00" },
    {value: 30, label: "14:30" },
    {value: 31, label: "15:00" },
    {value: 32, label: "15:30" },
    {value: 33, label: "16:00" },
    {value: 34, label: "16:30" },
    {value: 35, label: "17:00" },
    {value: 36, label: "17:30" },
    {value: 37, label: "18:00" },
    {value: 38, label: "18:30" },
    {value: 39, label: "19:00" },
    {value: 40, label: "19:30" },
    {value: 41, label: "20:00" },
    // {value: 42, label: "20:30" },
    // {value: 43, label: "21:00" },
    // {value: 44, label: "21:30" },
    // {value: 45, label: "22:00" },
    // {value: 46, label: "22:30" },
    // {value: 47, label: "23:00" },
    // {value: 48, label: "23:30" },




]




/**
 *会計連動グリッド
 *
 * @class AccountingGridComponent
 * @extends {Component<Props, State>}
 */
class MailGridComponent extends Component {
  constructor(props) {
    super(props);
    // this.onRowClick = this.onRowClick.bind(this);
    // this.onScroll = this.onScroll.bind(this);
    // this.togglesheet = this.togglesheet.bind(this);
    // this.getCellActions = this.getCellActions.bind(this);

    this.state = {
      modalsheet:false,
      mail_id:0,
      modal_new:false,
      modal_del:false,
      del_id:0,
      columnWidths:{
        id:100,
        template_name:200,
        category:90,
        section_id:90,
        user_id:80,
        auto_send_mail_template_category_id: 80,
        day_term: 80,
        time: 80,
      },
      setDragIndex:null,
      sort_mode:false,
      tab:0,
      modal_koumoku:false,
      auto_send_mail_template_category_disp:false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDetailChange = this.handleDetailChange.bind(this);
    this.handleNewChangeUser = this.handleNewChangeUser.bind(this);
    this.handleDetailChangeUser = this.handleDetailChangeUser.bind(this);
    this.RowClick = this.RowClick.bind(this);
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
    this.handleClickMailTemplateSelect = this.handleClickMailTemplateSelect.bind(this);
    this.handleClickMailTemplateSave = this.handleClickMailTemplateSave.bind(this)
    this.handleClickMailTemplateSaveChangeName = this.handleClickMailTemplateSaveChangeName.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.props.get_mail_template_list(this.props.auto_send);
    this.props.get_mail_template_categories();
    this.props.change_mail_template_details({id:0, template_name:"", subject:"", body_all_text:"", send_type:0, tab:0, is_text:0, mail_template_category_id:72, auto_send_mail_template_category_id:0, day_term:-1, time:0, section_id:this.props.user_details.amSectionId, user_id:0})
  }

  componentWillMount() {

  }

  handleChange = (event) => {
    // alert(event.target.name)
    console.log(event)
    // alert(event.target.name)
    // alert(event.target.value)
    let mail_template_form = {...this.props.mail_template_form}


    mail_template_form[event.target.name] = event.target.value

    if(event.target.name == "section_id"){
      mail_template_form.auto_send_mail_template_category_id = -1
    }

    this.props.change_mail_template_form(mail_template_form)
    this.props.get_mail_template_list_search()

    this.setState({ reflash: !this.state.reflash });
  }

  handleChangeUser = (event, value) => {

    let mail_template_form = this.props.mail_template_form
    mail_template_form.user_id = value ? value.value : 0;

    this.props.change_mail_template_form(mail_template_form);
    this.props.get_mail_template_list_search()

    this.setState({ reflash: !this.state.reflash });
  }

  handleDetailChange = (event) => {
    // alert(event.target.name)
    console.log(event)
    // alert(event.target.name)
    // alert(event.target.value)
    let mail_template_details = this.props.mail_template_details

    mail_template_details[event.target.name] = event.target.value

    if(event.target.name == "send_type"){
      mail_template_details.tab = event.target.value == 0 || event.target.value == 2 ? 0 :1
    }

    this.props.change_mail_template_details(mail_template_details)



    this.setState({ reflash: !this.state.reflash });
  }

  handleDetailChangeUser = (event, value) => {

    let mail_template_details = this.props.mail_template_details
    mail_template_details.user_id = value ? value.value : 0;

    this.props.change_mail_template_details(mail_template_details);
    this.props.get_mail_template_list_search()

    this.setState({ reflash: !this.state.reflash });
  }

  handleNewChangeUser = (event, value) => {

    this.setState({ user_id: value ? value.value : 0 });
    this.setState({ reflash: !this.state.reflash });
  }

  RowClick(event,index){
    // alert(index)

    this.props.get_mail_template_details_new(this.props.mail_template_list_search[index].id)

    this.setState({ refresh: !this.state.refresh })
  }

  _rowClassNameGetter(index){
        
    if(this.props.mail_template_list_search && this.props.mail_template_list_search[index]){

      if(this.props.mail_template_list_search[index].id == this.props.mail_template_details.id){
        return "select"
      }else{
        return "normal"
      }
    }else{
      return ""
    }
  }

  handleClickMailTemplateSelect(subject,body){


    let send_mail_details = this.props.send_mail_details;
    if(subject){
      send_mail_details.subject = subject;
    }
      
    // send_mail_details.htmlUtf8 = ""
    // send_mail_details.htmlUtf8 = this.props.mail_template_details.body_all;

    let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];
            // editor.insertHtml(payload.html_utf8);
            editor.insertHtml(body);


    this.props.change_send_mail_details(send_mail_details)

    this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)

    this.props.handleTemplateClose()
    this.setState({ refresh: !this.state.refresh })
  }

  dragStart(index){
    if(this.state.sort_mode){
    // alert(index)
    this.setState({ setDragIndex: index })
  }
  }


  dragEnter(index){
    // alert(index)
    // alert(this.state.setDragIndex)
    // alert(index)
    // console.log("dragEnter", this.state.setDragIndex, index)
    if(this.state.sort_mode){

      if (index === this.state.setDragIndex) return;


      let mail_template_list_search = JSON.parse(JSON.stringify(this.props.mail_template_list_search));
      const deleteElement = mail_template_list_search.splice(this.state.setDragIndex, 1)[0];
      mail_template_list_search.splice(index, 0, deleteElement);


      this.props.change_mail_template_list_search(mail_template_list_search)

      this.setState({ setDragIndex: index })
    }
  }

  dragEnd(){
    // alert("hozon")
    if(this.state.sort_mode){
      this.setState({ setDragIndex: null })
    }
  }

  handleClickMailTemplateSave(){

    if(parseInt(this.props.mail_template_details.mail_template_category_id) == 76){//自動追客
      if(this.props.mail_template_details.auto_send_mail_template_category_id <= 0){
        alert("追客対象を選択してください。")
        return
      }
      if(this.props.mail_template_details.day_term < 0){
        alert("反響登録時からの日数を選択してください。")
        return
      }
      if(this.props.mail_template_details.time == 0){
        alert("送信時間を選択してください。")
        return
      }
    }

    if(this.props.mail_template_details.id){
      // alert("update", this.props.mail_template_details.id)
      this.props.update_mail_template_details();
    }else{
      // alert("insert")
      this.props.insert_mail_template_details(this.props.mail_template_details)
    }
    this.setState({ "modalTemplateSave": true })
  }

  handleClickMailTemplateSaveChangeName(){

    if(parseInt(this.props.mail_template_details.mail_template_category_id) == 76){//自動追客
      if(this.props.mail_template_details.auto_send_mail_template_category_id <= 0){
        alert("追客対象を選択してください。")
        return
      }
      if(this.props.mail_template_details.day_term < 0){
        alert("反響登録時からの日数を選択してください。")
        return
      }
      if(this.props.mail_template_details.time == 0){
        alert("送信時間を選択してください。")
        return
      }
    }

    this.props.insert_mail_template_details(this.props.mail_template_details)

    this.setState({ "modalTemplateSave": true })

  }

  handleTabChange = (event, newValue) => {

    let mail_template_details = this.props.mail_template_details

    mail_template_details.tab = newValue

    this.props.change_mail_template_details(mail_template_details)

    this.setState({ refresh: this.state.refresh });
};

  paramset = (text) => {
    // let editor = window.CKEDITOR.instances.editor2;
    // let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[1]];

    // editor.insertHtml(text);
    // editor.insertText(text);
    // this.toggle()

    const mail_template_details = this.props.mail_template_details;

    const selectionStart = document.getElementById('body_all_text').selectionStart;

    // 現在のテキストを取得
    const currentText = mail_template_details.body_all_text;

    // カーソル位置に文字を挿入
    const newText = currentText.substring(0, selectionStart) + text + currentText.substring(selectionStart);

    // テキストフィールドを更新
    mail_template_details.body_all_text = newText;

    this.props.change_mail_template_details(mail_template_details)

    this.setState({ modal_koumoku: !this.state.modal_koumoku, reflash: !this.state.refrlash });

      // // カーソル位置を更新
      // const newCursorPosition = selectionStart + textToInsert.length;
      // textField.setSelectionRange(newCursorPosition, newCursorPosition);

  }


  render() {

    return (
      <div>
        <div style={{display: "flex"}}>
          <Card style={{padding:5, width:500}}>

          <Grid container>
          <Grid xs={6} style={{padding:5}}>
            <FormControl variant="outlined" fullWidth size="small" style={{marginTop:0, marginBottom:0}}>
              <InputLabel id="sectionId-label">カテゴリー</InputLabel>
              <Select
                  id="category"
                  name="category"
                  value={this.props.mail_template_form.category}
                  onChange={this.handleChange}
                  label="category"
                  disabled={this.props.auto_send}
              >
                  <MenuItem key={-1} value={0}></MenuItem>
                  {/* {this.props.section_select.filter(a => a.value > 0 && a.value <=6 || a.value == 10 || a.value == 16 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47).map(function (item, i) { */}
                  {this.props.mail_template_categories.filter(a=>a.display == 100).map(function (item, i) {
                  return (
                      <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                  );

                  })}

              </Select>
            </FormControl>
          </Grid>
          {this.props.mail_template_form.category == 76 ? <Grid xs={6} style={{padding:5}}>
            <FormControl variant="outlined" size="small" style={{marginTop:0, marginBottom:0,width:190}}>
              <InputLabel id="sectionId-label">追客区分</InputLabel>
              <Select
                  id="auto_send_mail_template_category_id"
                  name="auto_send_mail_template_category_id"
                  value={this.props.mail_template_form.auto_send_mail_template_category_id}
                  onChange={this.handleChange}
                  label="category"
              >
                  <MenuItem key={-1} value={0}></MenuItem>
                  {/* {this.props.section_select.filter(a => a.value > 0 && a.value <=6 || a.value == 10 || a.value == 16 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47).map(function (item, i) { */}
                  {this.props.mail_template_categories.filter(a=>a.display == 200).filter(a=>a.section_id == this.props.mail_template_form.section_id).map(function (item, i) {
                  return (
                      <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                  );

                  })}

              </Select>
            </FormControl>
            <IconButton
              onClick={(e)=>{this.setState({ "auto_send_mail_template_category_disp": true,  })}}
              style={{padding:2}}
              size="large"><EditIcon /></IconButton>
          </Grid>:""}

          <Grid xs={6} style={{padding:5}}>
            <FormControl variant="outlined" fullWidth size="small" style={{marginTop:0, marginBottom:0}}>
              <InputLabel id="sectionId-label">店舗</InputLabel>
              <Select
                  labelId="sectionId-label"
                  id="section_id"
                  name="section_id"
                  value={this.props.mail_template_form.section_id}
                  onChange={this.handleChange}
                  label="section_id"
              >
                  {/* <MenuItem key={-1} value={0}>全店</MenuItem> */}
                  {/* {this.props.section_select.filter(a => a.value > 0 && a.value <=6 || a.value == 10 || a.value == 16 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47).map(function (item, i) { */}
                  {/* {this.props.shops.sort((a, b) => {return a.dispOrder < b.dispOrder ? -1 : 1;}).filter(a => a.tenpoNo > 0 && a.tenpoNo <=6 || a.tenpoNo == 10 || a.tenpoNo == 16 || a.tenpoNo == 17 || a.tenpoNo == 26 || a.tenpoNo == 37 || a.tenpoNo == 47 || a.tenpoNo == 55).map(function (item, i) { */}
                  {this.props.shops.sort((a, b) => {return a.dispOrder < b.dispOrder ? -1 : 1;}).map(function (item, i) {
                  return (
                      <MenuItem key={i} value={item.tenpoNo}>{item.tenpoNo == -1 ? "共通" : item.tenpoName}</MenuItem>
                  );

                  })}

              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} style={{padding:5}}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={this.props.users_select.length === 0 && this.props.mail_template_form.user_id ? [this.props.users_select.filter(a => a.value === this.props.mail_template_form.user_id)[0]]
                  : this.props.users_select}
              getOptionLabel={(option) => option.label ? option.label : ''}

              style={{ width: "100%" }}
              name="user_id"
              loading={this.props.users_loading}

              value={this.props.mail_template_form.user_id ? this.props.users_select.filter(a => a.value === this.props.mail_template_form.user_id)[0] : {}}

              onChange={this.handleChangeUser}
              renderInput={(params) => <TextField
                  {...params}
                  variant="outlined"
                  name="user_id"

                  InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                          <React.Fragment>

                              {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                          </React.Fragment>
                      ),
                  }}
                  // onChange={this.change_response_user_id}
                  label="担当" />}

            />
          </Grid>
          <Grid xs={6} style={{padding:5}}>
            <MuiButton variant="contained" onClick={(e) => { this.setState({ "modal_new": true, template_name:"", mail_template_category_id:72, auto_send_mail_template_category_id: 0, day_term:-1, time:0, section_id:this.props.user_details.amSectionId, user_id:0 });}} style={{backgroundColor:"#dc3545", border:"#dc3545", color:"white", float:"left"}}>新規追加</MuiButton>
          </Grid>
          <Grid xs={12} style={{padding:5}}>
            <Table
              className="gridMailTemplateSearchList"
              rowHeight={36}
              rowsCount={this.props.mail_template_list_search.length} 
              width={440}
              // height={150}
              height={window.innerHeight - 390}
              headerHeight={34}
              // onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
              rowClassNameGetter={this._rowClassNameGetter}
              onRowClick={this.RowClick}
              
            >
              {/* <Column width={80} isResizable={false} header={<Cell>反映</Cell>} cell={({ rowIndex, ...props }) => (<Cell style={{padding:0}} {...props}>
                  <MuiButton size="small" variant="contained" onClick={()=>this.handleClickMailTemplateSelect(this.props.mail_template_list_search[rowIndex].subject, this.props.mail_template_list_search[rowIndex].body_all)} color="primary">反映</MuiButton>
              </Cell>)} /> */}
              {/* <Column 
              width={50} isResizable={true} columnKey="name" header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell 
              draggable={this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0} 
              onDragStart={() => this.dragStart(rowIndex)}
              onDragEnter={() => this.dragEnter(rowIndex)}
              onDragOver={(event) => event.preventDefault()}
              onDragEnd={() => this.dragEnd()}
              className={rowIndex === this.state.setDragIndex ? 'dragging' : ''}
              {...props}>
                  {<DragIndicatorIcon style={{color:this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0 ? "black" : "grey"}} />}
              </Cell>)} /> */}
              <Column width={this.state.columnWidths.template_name} isResizable={true} columnKey="name" header={<Cell>テンプレート名</Cell>} cell={({ rowIndex, ...props }) => (<Cell 
                          // draggable={this.state.sort_mode && this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0} 
                          draggable={true}
                          onDragStart={() => this.dragStart(rowIndex)}
                          onDragEnter={() => this.dragEnter(rowIndex)}
                          onDragOver={(event) => event.preventDefault()}
                          onDragEnd={() => this.dragEnd()}
                          className={rowIndex === this.state.setDragIndex ? 'dragging' : ''}
                {...props}>
                  {this.props.mail_template_list_search[rowIndex].template_name}
              </Cell>)} />
              {this.props.mail_template_form.category == 0 && <Column width={this.state.columnWidths.category} isResizable={true} columnKey="name" header={<Cell>カテゴリ</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}
                                      // draggable={this.state.sort_mode && this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0} 
                                      draggable={true}
                                      onDragStart={() => this.dragStart(rowIndex)}
                                      onDragEnter={() => this.dragEnter(rowIndex)}
                                      onDragOver={(event) => event.preventDefault()}
                                      onDragEnd={() => this.dragEnd()}
                                      className={rowIndex === this.state.setDragIndex ? 'dragging' : ''}
              >
                  {this.props.mail_template_list_search[rowIndex].mail_template_category_id && this.props.mail_template_categories.length > 0 && this.props.mail_template_categories.filter(a=>a.value == this.props.mail_template_list_search[rowIndex].mail_template_category_id).length > 0 ? this.props.mail_template_categories.filter(a=>a.value == this.props.mail_template_list_search[rowIndex].mail_template_category_id)[0].label : ""}
                {/* {this.props.mail_template_list_search[rowIndex].mail_template_category_id} */}
              </Cell>)} />}
              {this.props.mail_template_form.category == 76 && <Column width={this.state.columnWidths.category} isResizable={true} columnKey="name" header={<Cell>追客区分</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}
                                      // draggable={this.state.sort_mode && this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0} 
                                      draggable={true}
                                      onDragStart={() => this.dragStart(rowIndex)}
                                      onDragEnter={() => this.dragEnter(rowIndex)}
                                      onDragOver={(event) => event.preventDefault()}
                                      onDragEnd={() => this.dragEnd()}
                                      className={rowIndex === this.state.setDragIndex ? 'dragging' : ''}
              >
                  {this.props.mail_template_categories.length > 0 && this.props.mail_template_categories.filter(a=>a.value == this.props.mail_template_list_search[rowIndex].auto_send_mail_template_category_id).length > 0 ? this.props.mail_template_categories.filter(a=>a.value == this.props.mail_template_list_search[rowIndex].auto_send_mail_template_category_id)[0].label : ""}
                {/* {this.props.mail_template_list_search[rowIndex].mail_template_category_id} */}
              </Cell>)} />}
              {this.props.mail_template_form.category == 76 && <Column width={this.state.columnWidths.day_term} isResizable={true} columnKey="day_term" header={<Cell>日数</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}
                                      // draggable={this.state.sort_mode && this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0} 
                                      draggable={true}
                                      onDragStart={() => this.dragStart(rowIndex)}
                                      onDragEnter={() => this.dragEnter(rowIndex)}
                                      onDragOver={(event) => event.preventDefault()}
                                      onDragEnd={() => this.dragEnd()}
                                      className={rowIndex === this.state.setDragIndex ? 'dragging' : ''}
              >
                  {this.props.mail_template_list_search[rowIndex].day_term == 0 ? "当日" : this.props.mail_template_list_search[rowIndex].day_term+"日後"}
              </Cell>)} />}
              {this.props.mail_template_form.category == 76 && <Column width={this.state.columnWidths.time} isResizable={true} columnKey="time" header={<Cell>時間</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}
                                      // draggable={this.state.sort_mode && this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0} 
                                      draggable={true}
                                      onDragStart={() => this.dragStart(rowIndex)}
                                      onDragEnter={() => this.dragEnter(rowIndex)}
                                      onDragOver={(event) => event.preventDefault()}
                                      onDragEnd={() => this.dragEnd()}
                                      className={rowIndex === this.state.setDragIndex ? 'dragging' : ''}
              >
                  {timelist.filter(a=>a.value == this.props.mail_template_list_search[rowIndex].time).length > 0 ? timelist.filter(a=>a.value == this.props.mail_template_list_search[rowIndex].time)[0].label:""}
                {/* {this.props.mail_template_list_search[rowIndex].mail_template_category_id} */}
              </Cell>)} />}
              <Column width={this.state.columnWidths.section_id} isResizable={true} columnKey="name" header={<Cell>店舗</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}
                                      // draggable={this.state.sort_mode && this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0} 
                                      draggable={true}
                                      onDragStart={() => this.dragStart(rowIndex)}
                                      onDragEnter={() => this.dragEnter(rowIndex)}
                                      onDragOver={(event) => event.preventDefault()}
                                      onDragEnd={() => this.dragEnd()}
                                      className={rowIndex === this.state.setDragIndex ? 'dragging' : ''}
              >
                  {this.props.mail_template_list_search[rowIndex].section_id ? this.props.shops.filter(a=>a.tenpoNo == this.props.mail_template_list_search[rowIndex].section_id)[0].tenpoName : "共通"}
              </Cell>)} />
              <Column width={this.state.columnWidths.user_id} isResizable={true} columnKey="name" header={<Cell>担当</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}
                                      // draggable={this.state.sort_mode && this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0} 
                                      draggable={true}
                                      onDragStart={() => this.dragStart(rowIndex)}
                                      onDragEnter={() => this.dragEnter(rowIndex)}
                                      onDragOver={(event) => event.preventDefault()}
                                      onDragEnd={() => this.dragEnd()}
                                      className={rowIndex === this.state.setDragIndex ? 'dragging' : ''}
              >
                  {this.props.mail_template_list_search[rowIndex].user_id ? this.props.users_select.filter(a=>a.value == this.props.mail_template_list_search[rowIndex].user_id)[0].label : ""}
              </Cell>)} />
              <Column width={80} isResizable={false} header={<Cell>削除</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}
                                      // draggable={this.state.sort_mode && this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0} 
                                      draggable={true}
                                      onDragStart={() => this.dragStart(rowIndex)}
                                      onDragEnter={() => this.dragEnter(rowIndex)}
                                      onDragOver={(event) => event.preventDefault()}
                                      onDragEnd={() => this.dragEnd()}
                                      className={rowIndex === this.state.setDragIndex ? 'dragging' : ''}
              >
                  <IconButton
                    onClick={(e)=>{this.setState({ "modal_del": true, "del_id":this.props.mail_template_list_search[rowIndex].id })}}
                    style={{padding:2}}
                    size="large"><DeleteIcon /></IconButton>
              </Cell>)} />

            </Table>

            </Grid>
            <Grid xs={12} style={{padding:5}}>
              {this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0 && !this.state.sort_mode ? <MuiButton variant="contained" onClick={(e) => { this.setState({ "sort_mode": true });}} style={{backgroundColor:"#ffc400", border:"#dc3545", float:"right"}}>並び替え</MuiButton>:""}
              {this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0 && this.state.sort_mode ? <MuiButton variant="contained" onClick={(e) => { this.props.update_mail_template_list_sort();this.setState({"modalTemplateSave":true})}} style={{backgroundColor:"#ffc400", border:"#dc3545", float:"right"}}>並び替え保存</MuiButton>:""}
              {this.props.mail_template_form.category > 0 && this.props.mail_template_form.user_id == 0 && this.state.sort_mode ? <MuiButton variant="contained" onClick={(e) => { this.setState({ "sort_mode": false });this.props.get_mail_template_list_search()}} style={{backgroundColor:"#bdbdbd", border:"#dc3545", float:"right", marginRight:5}}>並び替え解除</MuiButton>:""}

          
            </Grid>
          </Grid>
          </Card>
          <Card style={{width:window.innerWidth-500,padding:5}}>

            <Grid container>
              <Grid xs={3} style={{padding:5}}>
                <TextField
                  fullWidth
                  onChange={this.handleDetailChange}
                  value={this.props.mail_template_details.template_name}
                  size="small"
                  label="テンプレート名"
                  name="template_name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid xs={3} style={{padding:5}}>
                {/* <span>{this.props.mail_template_details.mail_template_category_id}</span> */}
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="sectionId-label">カテゴリー</InputLabel>
                  <Select
                      id="mail_template_category_id"
                      name="mail_template_category_id"
                      value={parseInt(this.props.mail_template_details.mail_template_category_id)}
                      onChange={this.handleDetailChange}
                      label="mail_template_category_id"
                      
                      
                      InputLabelProps={{ shrink: true }}
                  >
                      {/* <MenuItem key={-1} value={0}></MenuItem> */}
                      {this.props.mail_template_categories.filter(a=>a.display == 100).map(function (item, i) {
                      return (
                          <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                      );

                      })}

                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={3} style={{padding:5}}>

                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="sectionId-label">店舗</InputLabel>
                  <Select
                      labelId="sectionId-label"
                      id="section_id"
                      name="section_id"
                      value={parseInt(this.props.mail_template_details.section_id)}
                      onChange={this.handleDetailChange}
                      label="section_id"
                      
                      
                      InputLabelProps={{ shrink: true }}
                  >
                      {this.props.shops.sort((a, b) => {return a.dispOrder < b.dispOrder ? -1 : 1;}).map(function (item, i) {
                      return (
                          <MenuItem key={i} value={item.tenpoNo}>{item.tenpoName}</MenuItem>
                      );

                      })}

                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={3} style={{padding:5}}>
                <Autocomplete
                  id="combo-box-demo"
                  size="small"
                  options={this.props.users_select.length === 0 && this.props.mail_template_details.user_id ? [this.props.users_select.filter(a => a.value === this.props.mail_template_details.user_id)[0]]
                      : this.props.users_select}
                  getOptionLabel={(option) => option.label ? option.label : ''}

                  fullWidth
                  name="user_id"
                  loading={this.props.users_loading}

                  value={this.props.mail_template_details.user_id ? this.props.users_select.filter(a => a.value === this.props.mail_template_details.user_id)[0] : {}}

                  onChange={this.handleDetailChangeUser}
                  renderInput={(params) => <TextField
                      {...params}
                      variant="outlined"
                      name="user_id"

                      InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                              <React.Fragment>

                                  {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                              </React.Fragment>
                          ),
                      }}
                      // onChange={this.change_response_user_id}
                      label="担当" />}

                />

              </Grid>

              
              {parseInt(this.props.mail_template_details.mail_template_category_id) == 76 ? <Grid xs={3} style={{padding:5}}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="sectionId-label">追客区分</InputLabel>
                  <Select
                      id="auto_send_mail_template_category_id"
                      name="auto_send_mail_template_category_id"
                      value={parseInt(this.props.mail_template_details.auto_send_mail_template_category_id)}
                      onChange={this.handleDetailChange}
                      label="追客区分"
                      
                      
                      InputLabelProps={{ shrink: true }}
                  >
                      {/* <MenuItem key={-1} value={0}></MenuItem> */}
                      {this.props.mail_template_categories.filter(a=>a.display == 200).filter(a=>a.section_id == this.props.mail_template_details.section_id).map(function (item, i) {
                      return (
                          <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                      );

                      })}

                  </Select>
                </FormControl>
              </Grid>:""}
              {parseInt(this.props.mail_template_details.mail_template_category_id) == 76 ? <Grid xs={9} style={{padding:5}}>
                <FormControl variant="outlined" size="small" style={{width:180}}>
                  <InputLabel id="sectionId-label">反響登録時からの日数</InputLabel>
                  <Select
                      id="day_term"
                      name="day_term"
                      value={parseInt(this.props.mail_template_details.day_term)}
                      onChange={this.handleDetailChange}
                      label="反響登録時からの日数"
                      
                      
                      InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem key={-1} value={-1}>-</MenuItem>
                    <MenuItem key={0} value={0}>当日</MenuItem>
                    <MenuItem key={1} value={1}>1日後</MenuItem>
                    <MenuItem key={2} value={2}>2日後</MenuItem>
                    <MenuItem key={3} value={3}>3日後</MenuItem>
                    <MenuItem key={4} value={4}>4日後</MenuItem>
                    <MenuItem key={5} value={5}>5日後</MenuItem>
                    <MenuItem key={6} value={6}>6日後</MenuItem>
                    <MenuItem key={7} value={7}>7日後</MenuItem>
                    <MenuItem key={8} value={8}>8日後</MenuItem>
                    <MenuItem key={9} value={9}>9日後</MenuItem>
                    <MenuItem key={10} value={10}>10日後</MenuItem>

                  </Select>
                </FormControl>
                {/* <MuiButton variant="contained" color="success" style={{marginRight:5}} onClick={()=>this.handleClickMailTemplateSave()} >保存</MuiButton>
                <MuiButton variant="outlined" color="success" onClick={()=>{this.props.insert_mail_template_details(this.props.mail_template_details);this.setState({ "modalTemplateSave": true })}} >別名で保存</MuiButton>
                 */}

                <FormControl variant="outlined" size="small" style={{width:180, marginLeft:10}}>
                  <InputLabel id="sectionId-label">送信時間</InputLabel>
                  <Select
                      id="time"
                      name="time"
                      value={parseInt(this.props.mail_template_details.time)}
                      onChange={this.handleDetailChange}
                      label="送信時間"
                      
                      
                      InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem key={0} value={0}>-</MenuItem>
                    {timelist.map(function (item, i) {
                      return (
                          <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                      );

                    })}
                    {/* <MenuItem key={23} value={23}>11:00</MenuItem>
                    <MenuItem key={37} value={37}>18:00</MenuItem>
                    <MenuItem key={38} value={38}>18:30</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>:""}
              <Grid xs={7} style={{padding:5}}>
                <TextField
                  onChange={this.handleDetailChange}
                  value={this.props.mail_template_details.subject}
                  margin="dense"
                  label="件名"
                  name="subject"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid xs={3} style={{padding:5}}>
                <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>送信タイプ</FormLabel>
                <RadioGroup size="small" row name="send_type" onChange={this.handleDetailChange} value={parseInt(this.props.mail_template_details.send_type)} >
                    <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={0} />} label="HTMLのみ" />
                    <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={1} />} label="テキストのみ" />
                    <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={2} />} label="両方" />
                </RadioGroup>
                
              </Grid>
              <Grid xs={2} style={{padding:5, textAlign:"right"}}>
                <MuiButton variant="contained" color="success" style={{marginRight:5}} onClick={()=>this.handleClickMailTemplateSave()} >保存</MuiButton>
                <MuiButton variant="outlined" color="success" onClick={this.handleClickMailTemplateSaveChangeName} >別名で保存</MuiButton>
                
              </Grid>
              
            </Grid>

            
            
            {/* {this.props.mail_template_details.subject}{this.props.mail_template_details.is_text} */}
            
            {/* {this.props.mail_template_details.is_text == 1 ? 
            <TextField multiline minRows={10} maxRows={10}></TextField>
            :<MailCkEditorComponent height={window.innerHeight - 650} />
            } */}

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={this.props.mail_template_details.tab} onChange={this.handleTabChange} aria-label="basic tabs example"

                variant="scrollable"
                scrollButtons="auto"
            >
                {parseInt(this.props.mail_template_details.send_type) == 0 || parseInt(this.props.mail_template_details.send_type) == 2 ? <Tab label="HTML形式" value={0} /> :null}
                {parseInt(this.props.mail_template_details.send_type) == 1 || parseInt(this.props.mail_template_details.send_type) == 2 ? <Tab label="テキスト形式" value={1} />:null}

            </Tabs>
            </Box>


  



            <div style={{marginTop:20}}>
            {this.props.mail_template_details.tab == 0 ? <MailCkEditorComponent height={this.props.auto_send ? window.innerHeight - 500 :window.innerHeight - 400} />:
              <>
              <TextField
                style={{marginTop:3}}
                fullWidth
                // onChange={this.handleDetailChange}
                // onChange={(e) => { this.setState({ "template_name": e.target.value }); }}
                onChange={this.handleDetailChange}
                value={this.props.mail_template_details.body_all_text ? this.props.mail_template_details.body_all_text : ""}
                margin="dense"
                label="内容"
                name="body_all_text"
                id="body_all_text"
                variant="outlined"
                multiline
                maxRows={this.props.auto_send ? 27 : 30}
                minRows={this.props.auto_send ? 27 : 30}
                InputLabelProps={{ shrink: true }}
              />

              <button
                type="button"
                className="btn btn-sm btn-primary float-left"
                style={{ margin: '5px' }}
                onClick={(e) => { this.setState({ "modal_koumoku": true });}}>自動項目</button>


              <Dialog open={this.state.modal_koumoku } style={{zIndex:1052}} maxWidth="md" fullWidth={true} onClose={(e) => { this.setState({ "modal_koumoku": false });}}>
                <DialogTitle>
                項目選択
                  <IconButton
                    aria-label="close"
                    onClick={(e) => { this.setState({ "modal_koumoku": false });}}
                    style={{float: 'right'}}
                    size="large">
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <div style={{padding:20}}>
                  {this.state.modal_koumoku ? <ParamGrid display={100} is_text={1} paramset={a => this.paramset(a)} />:""}
                </div>
              </Dialog>
              </>
            }
            </div>

          </Card>

        </div>






        <Grid container spacing={3}>
        <Grid xs={2} style={{ padding: "0px 5px" }}>
          
        </Grid>
        <Grid xs={2} style={{ padding: "0px 5px" }}>
          
        </Grid>
        <Grid xs={2} style={{ padding: "0px 5px" }}>
          
        </Grid>

        <Grid xs={6} style={{ padding: "0px 5px" }}>
        
          
        </Grid>
        <Grid xs={12} style={{ padding: "20px", display: "flex" }}>

          


        </Grid>
        <Grid xs={12} style={{ padding: "0px 5px" }}>

        
        </Grid>
        </Grid>


        <Dialog open={this.state.modal_new} style={{zIndex:1052}} maxWidth="sm" fullWidth={true} onClose={(e) => { this.setState({ "modal_new": false });}}>
          <DialogTitle>
            新しいテンプレートを追加する
            <IconButton
              aria-label="close"
              onClick={(e) => { this.setState({ "modal_new": false }); }}
              style={{float: 'right'}}
              size="large">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <div style={{padding:20}}>
          <Grid container>
            <Grid xs={6} style={{ padding: "10px" }}>
              <TextField
                fullWidth
                // style={{marginTop:3}}
                // onChange={this.handleDetailChange}
                onChange={(e) => { this.setState({ "template_name": e.target.value }); }}
                value={this.state.template_name}
                label="テンプレート名"
                name="template_name"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                size='small'
              />
            </Grid>
            <Grid xs={6} style={{ padding: "10px" }}>
              {/* <span>{this.props.mail_template_details.mail_template_category_id}</span> */}
              <FormControl  fullWidth>
                <InputLabel id="sectionId-label">カテゴリー</InputLabel>
                <Select
                    id="mail_template_category_id"
                    name="mail_template_category_id"
                    value={parseInt(this.state.mail_template_category_id)}
                    // onChange={this.handleDetailChange}
                    onChange={(e) => { this.setState({ "mail_template_category_id": e.target.value }); }}
                    label="mail_template_category_id"
                    size="small"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                >
                    {this.props.mail_template_categories.filter(a=>a.display == 100).map(function (item, i) {
                    return (
                        <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                    );

                    })}

                </Select>
              </FormControl>
            </Grid>

          {this.state.mail_template_category_id == 76 ? <Grid xs={6} style={{ padding: "10px" }}>
            <FormControl fullWidth >
              <InputLabel id="sectionId-label">追客区分</InputLabel>
              <Select
                  id="auto_send_mail_template_category_id"
                  name="auto_send_mail_template_category_id"
                  value={this.state.auto_send_mail_template_category_id}
                  onChange={(e) => { this.setState({ "auto_send_mail_template_category_id": e.target.value }); }}
                  label="auto_send_mail_template_category_id"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
              >
                  <MenuItem key={-1} value={0}></MenuItem>
                  {/* {this.props.section_select.filter(a => a.value > 0 && a.value <=6 || a.value == 10 || a.value == 16 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47).map(function (item, i) { */}
                  {this.props.mail_template_categories.filter(a=>a.display == 200).filter(a=>a.section_id == this.props.mail_template_form.section_id).map(function (item, i) {
                  return (
                      <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                  );

                  })}

              </Select>
            </FormControl>
          </Grid>:""}

          {this.state.mail_template_category_id == 76 ? <Grid xs={6} style={{ padding: "10px" }}>
            
          </Grid>:""}

            <Grid xs={6} style={{ padding: "10px" }}>
            {/* <span>{this.props.mail_template_details.section_id}</span> */}
              <FormControl fullWidth>
                <InputLabel id="sectionId-label">店舗</InputLabel>
                <Select
                    labelId="sectionId-label"
                    id="section_id"
                    name="section_id"
                    value={parseInt(this.state.section_id)}
                    // onChange={this.handleDetailChange}
                    onChange={(e) => { this.setState({ "section_id": e.target.value }); }}
                    label="section_id"
                    size="small"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                >
                    {this.props.shops.sort((a, b) => {return a.dispOrder < b.dispOrder ? -1 : 1;}).map(function (item, i) {
                    return (
                        <MenuItem key={i} value={item.tenpoNo}>{item.tenpoNo == -1 ? "共通" : item.tenpoName}</MenuItem>
                    );

                    })}

                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6} style={{ padding: "10px" }}>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                options={this.props.users_select.length === 0 && this.state.user_id ? [this.props.users_select.filter(a => a.value === this.state.user_id)[0]]
                    : this.props.users_select}
                getOptionLabel={(option) => option.label ? option.label : ''}

                style={{ width: "140px" }}
                name="user_id"
                loading={this.props.users_loading}

                value={this.state.user_id ? this.props.users_select.filter(a => a.value === this.state.user_id)[0] : {}}

                onChange={this.handleNewChangeUser}

                renderInput={(params) => <TextField
                    {...params}
                    variant="standard"
                    name="user_id"

                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>

                                {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    // onChange={this.change_response_user_id}
                    label="担当" />}

              />

            </Grid>
            
            
          </Grid>
          </div>

          <DialogActions>
            <MuiButton variant="contained" style={{backgroundColor:"#e0e0e0", color:"black"}} onClick={(e) => { this.setState({ "modal_new": false });}} >キャンセル</MuiButton>
            <MuiButton variant="contained" onClick={()=>{this.props.insert_mail_template_details({template_name:this.state.template_name, mail_template_category_id:this.state.mail_template_category_id, auto_send_mail_template_category_id:this.state.auto_send_mail_template_category_id, day_term:-1, time:0, section_id:this.state.section_id, user_id:this.state.user_id});this.setState({ "modal_new": false })}} style={{backgroundColor:"#5cb85c", color:"white"}} color="success">保存</MuiButton>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.modal_del} style={{zIndex:1052}} maxWidth="sm" fullWidth={true} onClose={(e) => { this.setState({ "modal_del": false, "del_id":0 });}}>
          <DialogTitle>
            削除してよろしいですか？
            <IconButton
              aria-label="close"
              onClick={(e) => { this.setState({ "modal_del": false, "del_id":0 }); }}
              style={{float: 'right'}}
              size="large">
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogActions>
            <MuiButton variant="contained" style={{backgroundColor:"#e0e0e0", color:"black"}} onClick={(e) => { this.setState({ "modal_del": false, "del_id":0 });}}>キャンセル</MuiButton>
            <MuiButton variant="contained" onClick={()=>{this.props.delete_mail_template_details(this.state.del_id);this.setState({ "modal_del": false, "del_id":0 })}} style={{backgroundColor:"#dc3545", border:"#dc3545", color:"white"}} color="success">削除</MuiButton>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.modalTemplateSave } style={{zIndex:1052}} onClose={(e) => { this.setState({ "modalTemplateSave": false });}}>
          <DialogTitle>
            保存しました。
          </DialogTitle>
          {/* <DialogActions>
            <ButtonMui variant="contained" color="inherit" onClick={()=>this.setState({ "modalTemplateSave": false })}>閉じる</ButtonMui>
          </DialogActions> */}
        </Dialog>

        <Dialog open={this.state.auto_send_mail_template_category_disp } style={{zIndex:1052}} onClose={(e) => { this.setState({ "auto_send_mail_template_category_disp": false });}}>
          <DialogTitle>
            自動送信対象区分
          </DialogTitle>
          <AutoSendMailTemplateCategoryEdit />


        </Dialog>

              

        <div>

        </div>
      </div>
    );
  }


}



const mapStateToProps = (state) => {
  return {

    mails: state.mailReducer.mails,
    mail_loading: state.mailReducer.mail_loading,
    shops: state.masterReducer.shops,
    users_select: state.masterReducer.users_select,
    user_details: state.masterReducer.user_details,
    mail_template_form: state.mailtemplate.mail_template_form,
    mail_template_categories: state.mailtemplate.mail_template_categories,
    mail_template_list_search: state.mailtemplate.mail_template_list_search,
    mail_template_details: state.mailtemplate.mail_template_details,

    // response_mail_loading: state.mailReducer.response_mail_loading,

    send_mail_details: state.mailReducer.send_mail_details,
    draggable: state.masterReducer.draggable,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    // get_mails(state: any) {
    //   dispatch(Mail.get_mails(state))
    // },
    // add_mails(state) {
    //   dispatch(Mail.add_mails(state))
    // },
    change_mail_template_form(state) {
      dispatch(MailTemplate.change_mail_template_form(state))
    },
    get_mail_template_list(state) {
      dispatch(MailTemplate.get_mail_template_list(state))
    },
    get_mail_template_categories(state) {
      dispatch(MailTemplate.get_mail_template_categories(state))
    },
    get_mail_template_list_search(state) {
      dispatch(MailTemplate.get_mail_template_list_search(state))
    },
    update_mail_template_list_sort(state) {
      dispatch(MailTemplate.update_mail_template_list_sort(state))
    },
    change_mail_template_details(state) {
      dispatch(MailTemplate.change_mail_template_details(state))
    },
    get_mail_template_details_new(state) {
      dispatch(MailTemplate.get_mail_template_details_new(state))
    },
    insert_mail_template_details(state) {
      dispatch(MailTemplate.insert_mail_template_details(state))
    },
    delete_mail_template_details(state) {
      dispatch(MailTemplate.delete_mail_template_details(state))
    },
    change_mail_template_list_search(state) {
      dispatch(MailTemplate.change_mail_template_list_search(state))
    },
    
    change_send_mail_details(state) {
      dispatch(Mail.change_send_mail_details(state))
    },
    change_draggable(state) {
      dispatch(Master.change_draggable(state))
    },
    update_mail_template_details(state) {
      dispatch(MailTemplate.update_mail_template_details(state))
    },
    // insert_mail_template_details(state) {
    //   dispatch(MailTemplate.insert_mail_template_details(state))
    // },
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(MailGridComponent);