// const authentication = require('react-azure-adb2c').default;
const authentication = require('../../../react-azure-adb2c2').default;



export const response_list = (value: any) => {

    // let url = `http://localhost:5000/api/v1/rent/responses/responselist/` + value;
    let url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/responselist/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const coming_list = (value: any) => {
    // let url = `http://localhost:5000/api/v1/rent/responses/cominglist/` + value;
    let url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/cominglist/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const contract_list = (value: any) => {
    // let url = `http://localhost:5000/api/v1/rent/responses/contractlist/` + value;
    let url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/contractlist/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const contract_count = (values: any) => {
    // console.log(values)
    // let value={date:Date()}

    // let url = `http://localhost:5000/api/v1/rent/responses/contractcount`;
    let url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/contractcount`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const customer_list = (value: any) => {
    // alert(value.customer_name)
    // let url = `http://localhost:5000/api/v1/rent/responses/customerlist`;
    let url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/customerlist`;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const response2_list = (value: any) => {
    // let url = `http://localhost:5000/api/v1/rent/responses/responselistall/` + value;
    let url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/responselistall/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update = (value: any) => {
    // alert( JSON.stringify(value))

    // let url = `http://localhost:5000/api/v1/rent/responses/updatecorporate`;
    let url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/updatecorporate`;//これに戻す


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};