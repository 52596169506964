const authentication = require('../../../react-azure-adb2c2').default;


export const list = (value: any) => {

    // let url = `http://localhost:5000/v1/users/`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/users`;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};