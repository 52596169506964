import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';


import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';

import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MaterialUiSelect from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MaskedInput from 'react-text-mask';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';

import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

import Grid from '@mui/material/Grid';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';
import GoogleMapReact, { MapOptions, Maps } from 'google-map-react'
import Geocode from 'react-geocode';
import styled from 'styled-components'





const phoneNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];


const mobileNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

const postNumberMask = [

  /[0-9]/,
  /\d/,
  /\d/,

  "-",

  /\d/,
  /\d/,
  /\d/,
  /\d/,

];


function TelMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
  
      <MaskedInput
        {...other}
        // ref={inputRef}
        mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" || !props.value ? mobileNumberMask : phoneNumberMask}
        placeholderChar={'\u2000'}
      // showMask
      />
    );
  }

function PostMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={postNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}

const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: true,
    fullscreenControl: false,
    // styles: [
    //   {
    //     featureType: 'water',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#e9e9e9',
    //       },
    //       {
    //         lightness: 17,
    //       },
    //     ],
    //   },

  }
}

const Pin = styled.div``
class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false,

            zoom: 14,
            draggable: true,
            lat: 34.36179852,
            lng: 134.048788977,
            geo_lat : "",
            geo_lng : "",
            center: null,

            columnWidths: {
                affiliate: 100,
                name: 90,
                add: 200,
                rent_m_agent_id: 120,
                contact1: 100,
                contact2: 100,
                contact3: 100,
                agent_remarks: 150,
                affiliate_remarks: 150,
            },
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        this.props.get_rent_m_agents_list()

        this.onChildMouseMove = this.onChildMouseMove.bind(this);
        this.onChildMouseUp = this.onChildMouseUp.bind(this);
        this.handleZoomChanged = this.handleZoomChanged.bind(this);
        this.getGeocode = this.getGeocode.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
        this.RowClick = this.RowClick.bind(this);
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
          columnWidths: {
            ...columnWidths,
            [columnKey]: newColumnWidth,
          },
        }));
    }


    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    onChange(state) {
        // console.log(this.props.claso_blog_places)
        let rent_corporate_offices = this.props.rent_corporate_offices

        if (state.target.name == "affiliate" || state.target.name == "request_tatemono_types" || state.target.name == "jyukenyotei") {

            if (rent_corporate_offices[state.target.name] && rent_corporate_offices[state.target.name].split(",").includes(state.target.value)) {

                //削除
                rent_corporate_offices[state.target.name] = rent_corporate_offices[state.target.name].split(",").filter(item => parseInt(item) != parseInt(state.target.value)).join(',')
            } else {

                let ary = rent_corporate_offices[state.target.name] ? rent_corporate_offices[state.target.name].split(",") : []

                ary.push(parseInt(state.target.value))

                ary = ary.filter(Boolean)

                rent_corporate_offices[state.target.name] = ary.join(',')
            }
        }else if (state.target.name === "is_main") {
            rent_corporate_offices[state.target.name] = state.target.checked ? 1 : 0
        }else{
            rent_corporate_offices[state.target.name] = state.target.value
        }

        this.props.change_rent_corporate_offices(rent_corporate_offices)
        this.setState({
            change: !this.state.change
        });

    }

    handleOpen(id) {
        if (id) {
            this.props.set_rent_corporate_offices(id)
        } else {
            // 新規
            let rent_corporate_offices = { id: '', rent_m_corporate_id: this.props.rent_m_corporates.id, rent_m_corporate_office_id: 0, post_card: 0, in_office: 0, remarks: '', name1:"", name2:'', department:'', position:'', tel:'', fax:'', mail:'', remarks:'', is_deleted: 0, created_at: '', created_user_id: '' }
            this.props.change_rent_corporate_offices(rent_corporate_offices)
        }

        this.setState({ "modal": true })
    }

    handleUpdate(id, delflg) {

        let rent_corporate_offices = this.props.rent_corporate_offices
        rent_corporate_offices["is_deleted"] = delflg
        this.props.change_rent_corporate_offices(rent_corporate_offices)

        if (id) {
            this.props.update_rent_corporate_offices()
        } else {
            // alert("insert")
            // console.log(this.props.rent_corporate_offices)
            this.props.insert_rent_corporate_offices()
        }

        this.setState({
            modal: false,
            modal_del: false,
            change: !this.state.change
        });

        this.props.handleClose()

    }

    _onBoundsChange = (center, zoom, bounds, marginBounds) => {
        this.setState({ zoom: zoom })
      }
    
      handleZoomChanged(){


    }
      onChildMouseUp() {
        this.setState({ draggable: true })
      }
      onChildMouseMove(childKey, childProps, mouse) {

          console.log(mouse)
        // function is just a stub to test callbacks
        this.setState({
          draggable: false,
          lat: mouse.lat,
          lng: mouse.lng,
    
        });

        // if(this.state.zoom < 14){
            this.setState({
                center: {lat:mouse.lat, lng:mouse.lng},
            });
        // }


        let rent_corporate_offices =  this.props.rent_corporate_offices

        rent_corporate_offices["lat"] = mouse.lat
        rent_corporate_offices["lng"] = mouse.lng

        this.props.change_rent_corporate_offices(rent_corporate_offices)
        this.setState({
            change: !this.state.change
        });


        // this.props.mapchange(mouse.lat, mouse.lng)
    
      }

    getGeocode() {
        // alert(this.props.tenant_details.address1 + this.props.tenant_details.address2 + this.props.tenant_details.address3 + this.props.tenant_details.address4 + this.props.tenant_details.address5)
        Geocode.setApiKey('AIzaSyDcEVxG_-xtQ1uCku--0iw84NPOffxow28');
        Geocode.setLanguage("ja");
        Geocode.fromAddress((this.props.rent_corporate_offices.add1 ? this.props.rent_corporate_offices.add1 : "") + (this.props.rent_corporate_offices.add2 ? this.props.rent_corporate_offices.add2 :""))
          .then((response) => {
    
            const { lat, lng } = response.results[0].geometry.location;
    
            let rent_corporate_offices =  this.props.rent_corporate_offices
            rent_corporate_offices.lat = lat
            rent_corporate_offices.lng = lng

            this.props.change_rent_corporate_offices(rent_corporate_offices)
            this.setState({
                center: {lat:lat, lng:lng},
                change: !this.state.change
            });


          })
          .catch((a) => {
            console.log(a)
            this.setState({
              lat: undefined,
              lng: undefined,
            });
          });
    
    }

    RowClick(event,index){

        this.props.set_rent_corporate_offices(this.props.rent_corporate_offices_list[index].id)

        this.setState({ "modal": true })

    }

    _rowClassNameGetter(index){
        
        if(this.props.rent_corporate_offices_list && this.props.rent_corporate_offices_list[index]) {
        //   return this.props.owner_eigyou[index].type == 1 ? "line" : 
        //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
        //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
            return "normal"
        }else{
          return ""
        }
    }

    render() {

        return (
            <div>
                    
                <ModalHeader toggle={(e) => { this.props.handleClose() }}>
                    <span>拠点情報</span>
                    {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
                </ModalHeader>
                

                <ModalBody>


                <Card>
                    <CardContent>

                <Grid container spacing={1}>
                    <Grid justifyContent="space-between" item xs={6}>


                <Grid container spacing={1}>
                    {/* <Grid justify="space-between" item xs={12}>
                        <FormControlLabel control={<Checkbox name="contract_form" onChange={this.handleChange} />} label="拠点" />
                    </Grid> */}
                    <Grid justifyContent="space-between" item xs={12}>
                        <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_offices.is_main == 1 ? true : false} onChange={this.onChange} name="is_main" />} label="メイン" />
                    </Grid>
                    <Grid justifyContent="space-between" item xs={12}>
                        <RadioGroup row aria-label="position" name="" onChange={this.handleChange} value={{}} >
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 9 }}>提携内容</FormLabel>
                            <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_offices.affiliate ? this.props.rent_corporate_offices.affiliate.split(',').includes("1") : false} value={1} onChange={this.onChange} name="affiliate" />} label="間接提携" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_offices.affiliate ? this.props.rent_corporate_offices.affiliate.split(',').includes("2") : false} value={2} onChange={this.onChange} name="affiliate" />} label="法人業務提携" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_offices.affiliate ? this.props.rent_corporate_offices.affiliate.split(',').includes("3") : false} value={3} onChange={this.onChange} name="affiliate" />} label="福利厚生提携" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_offices.affiliate ? this.props.rent_corporate_offices.affiliate.split(',').includes("4") : false} value={4} onChange={this.onChange} name="affiliate" />} label="他県案件送客" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_offices.affiliate ? this.props.rent_corporate_offices.affiliate.split(',').includes("5") : false} value={5} onChange={this.onChange} name="affiliate" />} label="専属提携" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_offices.affiliate ? this.props.rent_corporate_offices.affiliate.split(',').includes("6") : false} value={6} onChange={this.onChange} name="affiliate" />} label="専任提携" />
                        </RadioGroup>
                    </Grid>


                    <Grid justifyContent="space-between" item xs={12}>
                        <TextField name="name" label="拠点名" onChange={this.onChange} value={this.props.rent_corporate_offices.name} variant="outlined" fullWidth />
                    </Grid>
                    <Grid justifyContent="space-between" item xs={12}>
                        <TextField name="kana" label="カナ" onChange={this.onChange} value={this.props.rent_corporate_offices.kana} variant="outlined" fullWidth />
                    </Grid>
                    <Grid justifyContent="space-between" item xs={12}>
                        <TextField
                            variant="outlined"
                            name="post"
                            label="郵便番号"
                            type="tel"
                            value={this.props.rent_corporate_offices.post}
                            onChange={this.onChange}

                            InputProps={{
                                inputComponent: PostMaskCustom,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />   <Tooltip title="郵便番号から住所を検索" aria-label="add" onClick={this.props.get_rent_m_corporates_zip_address}>
                            <IconButton aria-label="delete" size="large">
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid justifyContent="space-between" item xs={6}>
                        <TextField name="add1" label="住所1" InputLabelProps={{shrink: true}} onChange={this.onChange} value={this.props.rent_corporate_offices.add1} variant="outlined" fullWidth />
                    </Grid>
                    <Grid justifyContent="space-between" item xs={6}>
                        <TextField name="add2" label="住所2(建物名等)" onChange={this.onChange} value={this.props.rent_corporate_offices.add2} variant="outlined" fullWidth />
                    </Grid>

    
                    <Grid justifyContent="space-between" item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>代行会社</InputLabel>
                            <MaterialUiSelect
                                value={this.props.rent_corporate_offices.rent_m_agent_id}
                                name="rent_m_agent_id"
                                onChange={this.onChange}
                            >
                                <MenuItem value={0}>-</MenuItem>
                                {this.props.rent_m_agents_list.map(function (value) {
                                    return <MenuItem value={value.id}>{value.name}</MenuItem>
                                })}

                            </MaterialUiSelect>
                        </FormControl>
                    </Grid>
                    <Grid justifyContent="space-between" item xs={6}></Grid>
                    <Grid justifyContent="space-between" item xs={4}>
                        <TextField name="contact1" label="依頼先1" onChange={this.onChange} value={this.props.rent_corporate_offices.contact1} variant="outlined" fullWidth />
                    </Grid>
                    <Grid justifyContent="space-between" item xs={4}>
                        <TextField name="contact2" label="依頼先2" onChange={this.onChange} value={this.props.rent_corporate_offices.contact2} variant="outlined" fullWidth />
                    </Grid>
                    <Grid justifyContent="space-between" item xs={4}>
                        <TextField name="contact3" label="依頼先3" onChange={this.onChange} value={this.props.rent_corporate_offices.contact3} variant="outlined" fullWidth />
                    </Grid>
                    
                    <Grid justifyContent="space-between" item xs={6}>

                        <TextField
                            fullWidth
                            name="agent_remarks"
                                label="社宅代行会社備考"
                                multiline
                                rows={4}
                                defaultValue=""
                                variant="outlined"
                                onChange={this.onChange}
                                value={this.props.rent_corporate_offices.agent_remarks}
                                />
                    </Grid>
                    <Grid justifyContent="space-between" item xs={6}>

                        <TextField
                            fullWidth
                            name="affiliate_remarks"
                                label="提携備考"
                                multiline
                                rows={4}
                                defaultValue=""
                                variant="outlined"
                                onChange={this.onChange}
                                value={this.props.rent_corporate_offices.affiliate_remarks}
                                />
                    </Grid>
                    

                </Grid>

                </Grid>
                <Grid justifyContent="space-between" item xs={6}>


<div style={{ height: 455, marginTop:3, width:530} }> 
    <GoogleMapReact 

    bootstrapURLKeys={{
        key: 'AIzaSyClDVE129NsjckVjDJpMUpqBUuqMTg5YUo'
    }}


    defaultCenter={{
        lat: this.props.rent_corporate_offices.lat ? parseFloat(this.props.rent_corporate_offices.lat) : 34.36179852,
        lng: this.props.rent_corporate_offices.lng ? parseFloat(this.props.rent_corporate_offices.lng) : 134.048788977
    }}

    center={this.state.center}

    defaultZoom={13}
    
    options={createMapOptions}
    // draggable={true}
    onBoundsChange={this._onBoundsChange}
    
    onChildMouseUp={this.onChildMouseUp}
    onChildMouseMove={this.onChildMouseMove}

    >
    {/* <Marker position={{lat: 34.36179852, lng: 134.048788977}} /> */}
    

    {
        


        parseFloat(this.props.rent_corporate_offices.lat) > 0 ? <Pin

        key={0}
        lat={this.props.rent_corporate_offices.lat ? parseFloat(this.props.rent_corporate_offices.lat) : 34.36179852}
        lng={this.props.rent_corporate_offices.lng ? parseFloat(this.props.rent_corporate_offices.lng) : 134.048788977}
        >
            <div style={{ borderRadius:15, backgroundColor:"blue",width:"20px", height:"20px", marginLeft:0, marginTop:0}}></div>
        </Pin>:""

    }


    </GoogleMapReact>
    <Button variant="contained" color="secondary" style={{marginTop:5,color:"white", backgroundColor:"#3f51b5"}} onClick={this.getGeocode} >住所から取得</Button>

</div>


                </Grid>
            </Grid>

                </CardContent>

                </Card>




                </ModalBody>
                

                <ModalFooter>

                <Button variant="contained" color="primary" onClick={() => this.handleUpdate(this.props.rent_corporate_offices.id, 0)} style={{ backgroundColor: "#5cb85c", marginRight: 5 }}>保存</Button>
                {this.props.rent_corporate_offices.id ? <Button variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button> : ""}

                </ModalFooter>

                <Modal isOpen={this.state.modal_del} fade={false} className="modal-dialog-centered">
                    <ModalBody>
                        <div>本当に削除してよろしいですか？</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="secondary" onClick={() => this.handleUpdate(this.props.rent_corporate_offices.id, 1)}>削除</Button>
                        <Button variant="contained" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_corporate_offices_list: state.corporation.rent_corporate_offices_list,
        rent_corporate_offices: state.corporation.rent_corporate_offices,
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_m_agents_list: state.corporation.rent_m_agents_list,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_corporate_offices(state) {
            dispatch(Corporation.change_rent_corporate_offices(state))
        },
        set_rent_corporate_offices(state) {
            dispatch(Corporation.set_rent_corporate_offices(state))
        },
        insert_rent_corporate_offices(state) {
            dispatch(Corporation.insert_rent_corporate_offices(state))
        },
        update_rent_corporate_offices(state) {
            dispatch(Corporation.update_rent_corporate_offices(state))
        },
        get_rent_m_agents_list(state) {
            dispatch(Corporation.get_rent_m_agents_list(state))
        },
        get_rent_m_corporates_zip_address(state) {
            dispatch(Corporation.get_rent_m_corporates_zip_address(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




