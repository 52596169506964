import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
// import * as firebase from "firebase";
// import '@firebase/firestore' // 👈 If you're using firestore
// import ReduxSagaFirebase from 'redux-saga-firebase'
import { eventChannel } from 'redux-saga'
import { call, put, takeEvery, take, select, delay, all } from 'redux-saga/effects';
import * as LinesApi from '../Api/LinesApi';
import * as TimeLineApi from '../Api/TimeLineApi';
import * as Responses from '../../ResponseDetail/Saga/Responses';

import * as LineSendsApi from '../Api/LineSendsApi';
import * as line_users_api from '../Api/line_users_api';
import * as rent_responses_api from '../Api/rent_responses_api';
import * as ContractFollow from '../../ContractFollow/Saga/ContractFollow';
import { reset } from 'redux-form';
import * as Firebase from '../../../Store/Firebase';
// import firebaseConfig from '../../../firebase-config';
// // 初期化
// const firebaseApp = firebase.initializeApp(firebaseConfig)

// const rsf = new ReduxSagaFirebase(firebaseApp)

// import console = require('console');
// import * as Common from './Common';

export interface LineState {
    lines: any;
    response_lines: any;
    line_user_details: any;
    line_loading: boolean;
    line_user_details_response: any;
    line_bottom: boolean;
    line_send: boolean;
    // get_line_open: any;
    line_open_flg: boolean;
    response_line_bottom: boolean;
    lienimage: any;
    line_images: any;
    line_file_data: any;
    line_send_modal: number;
    line_send_file_loading: boolean;
    line_send_list_loading: boolean;
    line_looked_modal: boolean;
    line_users: any;
    line_all: any;
}

const initialState: LineState = {

    // mails: [],
    lines: [],
    response_lines: [],
    line_user_details: [],
    line_loading: false,
    line_user_details_response: [],
    line_bottom: false,
    response_line_bottom: false,
    line_send: false,
    // get_line_open: [],
    line_open_flg: false,
    lienimage: [],
    line_images: {},
    line_file_data: null,
    line_send_modal: 0,
    line_send_file_loading: false,
    line_send_list_loading: false,
    line_looked_modal: false,
    line_users: [],
    line_all: [],
}



///action
const actionCreator = actionCreatorFactory();



export const get_lines = actionCreator<any>('GET_LINES');
// export const get_response_lines = actionCreator('GET_RESPONESE_LINES');
export const get_lines_success = actionCreator<any>("GET_LINES_SUCCESS");

export const get_response_lines_success = actionCreator<any>("GET_RESPONSE_LINES_SUCCESS");


export const send_message = actionCreator<any>('SEND_MESSAGE');
export const send_mypage = actionCreator<any>('SEND_MYPAGE');

// export const get_line_user_details = actionCreator<any>('GET_LINE_USER_DETAILS');
export const get_line_user_details_success = actionCreator<any>("GET_LINE_USER_DETAILS_SUCCESS");

// export const get_line_user_details_response = actionCreator<any>('GET_LINE_USER_DETAILS_RESPONSE');
// export const get_line_user_details_response_success = actionCreator<any>("GET_LINE_USER_DETAILS_RESPONSE_SUCCESS");
export const change_line_loading = actionCreator<boolean>('CHANGE_LINE_LOADING');



export const change_line_bottom = actionCreator<any>('CHANGE_LINE_BOTTOM');
export const change_response_line_bottom = actionCreator<any>('CHANGE_RESPONSE_LINE_BOTTOM');

export const change_line_send = actionCreator<any>('CHANGE_LINE_SEND');


export const get_line_open = actionCreator<any>('GET_LINE_OPEN');
export const get_line_close = actionCreator<any>('GET_LINE_CLOSE');


export const change_line_open_flg = actionCreator<any>('CHANGE_LINE_OPEN_FLG');


export const send_line_stock_rooms = actionCreator<any>('SEND_LINE_STOCK_ROOMS');
export const get_line_images = actionCreator<any>('GET_LINE_IMAGES');
export const change_line_images = actionCreator<any>('CHANGE_LINE_IMAGES');

export const change_line_file_data = actionCreator<any>('CHANGE_LINE_FILE_DATA');
// export const get_firestore_listen = actionCreator<any>('GET_FIRESTORE_LISTEN');

export const upload_line_file = actionCreator<any>('UPLOAD_LINE_FILE');

export const download_line_file = actionCreator<any>('DOWNLOAD_LINE_FILE');

export const change_line_send_modal = actionCreator<number>('CHANGE_LINE_SEND_MODAL');

export const change_line_send_file_loading = actionCreator<boolean>('CHANGE_LINE_SEND_FILE_LOADING');
export const change_line_send_list_loading = actionCreator<boolean>('CHANGE_LINE_SEND_LIST_LOADING');

export const get_line_user_details = actionCreator<boolean>('GET_LINE_USER_DETAILS');

export const delete_line_user = actionCreator<any>('DELETE_LINE_USER');

export const change_line_looked_modal = actionCreator<any>('CHANGE_LINE_LOOKED_MODAL');

export const update_line_looked = actionCreator<any>('UPDATE_LINE_LOOKED');
export const delete_line_link = actionCreator<any>('DELETE_LINE_LINK');

export const get_line_users = actionCreator<any>('GET_LINE_USERS');
export const change_line_users = actionCreator<any>('CHANGE_LINE_USERS');

export const download_line_receive_file = actionCreator<any>('DOWNLOAD_LINE_RECEIVE_FILE');

// export const get_line_all = actionCreator<any>('GET_LINE_ALL');
// export const change_line_all = actionCreator<any>('CHANGE_LINE_ALL');


export const lineReducer = reducerWithInitialState(initialState)


    .case(get_lines_success, (state, payload) => ({ ...state, lines: payload }))
    .case(get_response_lines_success, (state, payload) => ({ ...state, response_lines: payload }))

    .case(get_line_user_details_success, (state, payload) => ({ ...state, line_user_details: payload }))
    .case(change_line_bottom, (state, payload) => ({ ...state, line_bottom: payload }))
    .case(change_response_line_bottom, (state, payload) => ({ ...state, response_line_bottom: payload }))
    .case(change_line_send, (state, payload) => ({ ...state, line_send: payload }))
    .case(change_line_open_flg, (state, payload) => ({ ...state, line_open_flg: payload }))

    // .case(get_line_user_details_response_success, (state, payload) => ({ ...state, line_user_details_response: payload }))
    .case(change_line_images, (state, payload) => ({ ...state, line_images: payload }))

    .case(change_line_file_data, (state, payload) => ({ ...state, line_file_data: payload }))
    .case(change_line_send_modal, (state, payload) => ({ ...state, line_send_modal: payload }))
    .case(change_line_send_file_loading, (state, payload) => ({ ...state, line_send_file_loading: payload }))
    .case(change_line_send_list_loading, (state, payload) => ({ ...state, line_send_list_loading: payload }))
    .case(change_line_looked_modal, (state, payload) => ({ ...state, line_looked_modal: payload }))

    .case(change_line_loading, (state, payload) => ({ ...state, line_loading: payload }))

    .case(change_line_users, (state, payload) => ({ ...state, line_users: payload }))

// .case(change_line_all, (state, payload) => ({ ...state, line_all: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const lineSaga = [takeEvery('GET_LINES', handle_get_lines),
// takeEvery('GET_RESPONESE_LINES', handle_get_response_lines),
takeEvery('SEND_MESSAGE', handle_send_message),
takeEvery('SEND_MYPAGE', handle_send_mypage),
// takeEvery('GET_LINE_USER_DETAILS', handle_get_line_user_details),
takeEvery('GET_LINE_OPEN', handle_get_line_open),
takeEvery('GET_LINE_CLOSE', handle_get_line_close),
// takeEvery('GET_LINE_USER_DETAILS_RESPONSE', handle_get_line_user_details_response),
takeEvery('SEND_LINE_STOCK_ROOMS', handle_send_line_stock_rooms),
takeEvery('GET_LINE_IMAGES', handle_get_line_images),

takeEvery('UPLOAD_LINE_FILE', handle_upload_line_file),

takeEvery('DOWNLOAD_LINE_FILE', handle_download_line_file),

takeEvery('GET_LINE_USER_DETAILS', handle_get_line_user_details),

takeEvery('DELETE_LINE_USER', handle_delete_line_user),


takeEvery('UPDATE_LINE_LOOKED', handle_update_line_looked),
takeEvery('DELETE_LINE_LINK', handle_delete_line_link),

takeEvery('GET_LINE_USERS', handle_get_line_users),

takeEvery('DOWNLOAD_LINE_RECEIVE_FILE', handle_download_line_receive_file),
    // takeEvery('GET_LINE_ALL', handle_get_line_all),

    // takeEvery('GET_FIRESTORE_LISTEN', handle_get_firestore_listen),

    // takeEvery('GET_FIRESTORE_TIMELINE', handle_get_firestore_timeline),


];



function* handle_get_lines(action: any) {
    // console.log(action.payload)
    // alert("ddd")
    // alert("getline")
    if (action.payload.line_user_id) {
        const { payload, error } = yield call(TimeLineApi.list, action.payload);
        // alert(action.payload.line_user_id)
        // alert(payload.length)
        // サーバーからのレスポンスデータによる分岐処理
        if (payload && !error) {
            console.log(payload)
            // alert(payload.length)
            // 成功時アクション呼び出し
            yield put(get_lines_success([{}]))
            yield put(get_lines_success(payload))

            // yield put(change_line_bottom(true));
            yield put(change_line_open_flg(true))
            // yield put(change_line_bottom(true));
            // yield put(Firebase.get_fire_line_detail())


            // yield put(change_get_mail_details_loading(0));


        } else {


        }
    }
}



// function* handle_get_line_all(action: any): any {
//     // console.log(action.payload)
//     // alert("ddd")
//     // alert("getライン")
//     const state = yield select();

//     let line_all = state.line.line_all

//     // alert("getライン" + action.payload.line_user_id)
//     const { payload, error } = yield call(TimeLineApi.list_by_user, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         console.log(payload)
//         // if (lines_all.filter((a: any) => a.line_user_id == action.payload.line_user_id)[0]) {
//         // 成功時アクション呼び出し
//         // alert(payload.length)
//         line_all[action.payload.line_user_id] = payload
//         // alert(line_all[action.payload.line_user_id].length)
//         // alert(action.payload.line_user_id)
//         yield put(change_line_all(line_all))
//         // dispatch(Firebase.get_fire_line_detail_all(state))
//         yield put(Firebase.get_fire_line_detail_all(action.payload))

//         yield put(change_line_bottom(true));
//         // yield put(change_get_mail_details_loading(0));
//         // } else {

//         //     yield put(change_lines_all(lines_all.push(payload)))
//         // }

//     } else {


//     }

// }


function* handle_get_line_users(action: any) {
    // console.log(action.payload)
    // alert("ddd")

    // if (action.payload.line_user_id) {
    const { payload, error } = yield call(TimeLineApi.userlist, action.payload);



    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // 成功時アクション呼び出し
        yield put(change_line_users(payload))

        // yield put(Firebase.set_unsubscribeall())
        // yield put(change_get_mail_details_loading(0));


    } else {


    }
    // }
}




function* handle_get_line_open(action: any) {
    //    alert(action.payload)
    // yield put(get_line_user_details(action.payload))
    // alert("とどいたよ")

    // yield put(change_line_open_flg(true))

    const { payload, error } = yield call(TimeLineApi.list, action.payload);

    yield put(get_line_user_details_success(action.payload))
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        // alert()
        // 成功時アクション呼び出し

        // alert("open")
        // alert(payload.length)
        yield put(get_lines_success(payload))

        // yield put(change_line_bottom(true));
        yield put(change_line_open_flg(true))
        yield put(change_line_bottom(true));
        yield put(Firebase.get_fire_line_detail())
        // yield put(get_lines_success(payload))


    } else {


    }
}



function* handle_get_line_close(action: any) {
    //    alert(action.payload)
    // yield put(get_line_user_details_success({}))
    // yield put(get_lines_success({}))
    // yield put(change_get_mail_details_loading(0));
    yield put(change_line_open_flg(false))
}


function* handle_get_line_user_details(action: any) {

    const { payload, error } = yield call(line_users_api.get_detail_by_user_id, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        // alert(payload.id)
        // 成功時アクション呼び出し
        // yield put(get_line_user_details_response_success(payload))

        payload.line_user_id = payload.id
        yield put(get_line_user_details_success(payload))

        yield put(get_lines({ "line_user_id": payload.line_user_id, "line_account_section_id": payload.line_account_section_id, "take": 20 }))

        // alert(payload.line_account_section_id)


        yield put(change_line_bottom(true));
        yield put(change_line_open_flg(true))
        // yield put(Firebase.get_fire_line_detail())


        // yield put(change_get_mail_details_loading(0));


    } else {


    }
}



function* handle_delete_line_user(action: any) {

    const { payload, error } = yield call(line_users_api.del_user, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // 成功時アクション呼び出し
        // yield put(get_line_user_details_response_success(payload))
        // yield put(get_line_user_details_success(payload))

        // yield put(get_lines({ "line_user_id": payload.id, "line_account_section_id": payload.line_account_section_id, "take": 20 }))
        // yield put(change_get_mail_details_loading(0));


    } else {


    }
}


function* handle_send_message(action: any) {

    if (action.payload.message_text) {
        yield put(change_line_send(true));
    }
    const { payload, error } = yield call(LineSendsApi.send, action.payload);


    // yield put(change_line_bottom(true));
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


        // yield put(reset('linesendform'));
        yield put(change_line_send(false));
        //    const { payload2, error2 }= yield call(TimeLineApi.list, action.payload.responseId);


        // サーバーからのレスポンスデータによる分岐処理
        //    if (payload2 && !error2) {
        //     yield put(change_line_bottom(true));
        //    // 成功時アクション呼び出し
        //    yield put(get_lines_success(payload2))
        //        // yield put(change_get_mail_details_loading(0));



        //    } else {


        //    }

    } else {
        alert("送信できませんでした。")

    }
}

function* handle_send_mypage(action: any) {

    if (action.payload.message_text) {
        yield put(change_line_send(true));
    }
    const { payload, error } = yield call(LineSendsApi.send_mypage, action.payload);


    // yield put(change_line_bottom(true));
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // yield put(reset('linesendform'));
        yield put(change_line_send(false));
        //    const { payload2, error2 }= yield call(TimeLineApi.list, action.payload.responseId);

    } else {
        alert("送信できませんでした。")

    }
}

function* handle_send_line_stock_rooms(action: any): any {
    const state = yield select();

    let stock_rooms = state.StockRooms.stock_rooms

    if (stock_rooms.length > 0) {
        yield put(change_line_send(true));
        yield put(change_line_send_list_loading(true));


        const { payload, error } = yield call(LineSendsApi.send_line_stock_rooms, { line_user_id: state.line.line_user_details.line_user_id, line_account_section_id: state.line.line_user_details.line_account_section_id, stock_rooms: stock_rooms, rent_response_id: state.responsesReducer.responsedetail.id });


        // yield put(change_line_bottom(true));
        // サーバーからのレスポンスデータによる分岐処理
        if (payload && !error) {


            // yield put(reset('linesendform'));
            yield put(change_line_send(false));
            yield put(change_line_send_list_loading(false));
            yield put(change_line_send_modal(0));
            //    const { payload2, error2 }= yield call(TimeLineApi.list, action.payload.responseId);


            // サーバーからのレスポンスデータによる分岐処理
            //    if (payload2 && !error2) {
            //     yield put(change_line_bottom(true));
            //    // 成功時アクション呼び出し
            //    yield put(get_lines_success(payload2))
            //        // yield put(change_get_mail_details_loading(0));



            //    } else {


            //    }

        } else {
            alert("送信できませんでした。")

        }
    }
}



function* handle_get_line_images(action: any) {



    const { payload, error } = yield call(LinesApi.get_images, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        let bloburl = URL.createObjectURL(payload)
        // console.log(bloburl)
        // 成功時アクション呼び出し
        yield put(change_line_images({
            id: action.payload,
            bloburl: bloburl
        }))

        // yield put(change_get_mail_details_loading(0));


    } else {
        // const db_response = firebase.firestore();

        // // const query11 =db.collection("line").doc("7").collection(this.props.line_user_details.id).where("Timestamp",">",Math.floor( new Date().getTime() / 1000 ));
        // linelistening = db_response.collection("line").where("lineUserId", "==", this.props.line_user_details.id).where("timestamp", ">", Math.floor(new Date().getTime() / 1000)).orderBy("timestamp", "desc")
        //     .onSnapshot(snapshot => {
        //         snapshot.docChanges().forEach(function (change) {
        //             alert("listen")
        //             console.log(lines)
        //             this.props.get_lines_success(lines.concat(change.doc.data()))
        //             this.props.change_line_bottom(true);
        //             // this.setState({ reflesh: !this.state.reflesh })
        //         }, this)
        //     });

    }
}


function* handle_upload_line_file(action: any): any {
    // yield put(change_line_files_loading(true));
    const state = yield select();
    yield put(change_line_send_file_loading(true));
    const { payload, error } = yield call(LineSendsApi.upload, { user_id: state.line.line_user_details.line_user_id, line_account_section_id: state.line.line_user_details.line_account_section_id, name: state.line.line_file_data.name, file: state.line.line_file_data.file, mime_type: state.line.line_file_data.mime_type, rent_response_id: state.responsesReducer.responsedetail.id });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(change_line_file_data(null));
        yield put(change_line_send_file_loading(false));
        yield put(change_line_send_modal(0));
        // 成功時アクション呼び出し
        // console.log(payload)
        // yield put(change_response_file_update_disp(false));
        // yield put(change_response_files_loading(false));
        // const a = document.createElement('a');
        // ダウンロードされるファイル名
        // a.download = action.payload.name;
        // a.download = "10.pdf";
        // a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        // a.click();
        //    yield put(get_rent_contract_monies_success(payload));

    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_download_line_file(action: any): any {
    // yield put(change_line_files_loading(true));
    const state = yield select();

    const { payload, error } = yield call(LinesApi.download_file, action.payload.file_id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(change_line_file_data(null));
        // 成功時アクション呼び出し
        console.log(payload)
        // yield put(change_response_file_update_disp(false));
        // yield put(change_response_files_loading(false));
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.message_text;
        // a.download = "10.pdf";
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();
        //    yield put(get_rent_contract_monies_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_download_line_receive_file(action: any): any {
    // yield put(change_line_files_loading(true));
    const state = yield select();
// console.log(action.payload)
    const { payload, error } = yield call(LinesApi.download_receivefile, action.payload.file_id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(change_line_file_data(null));
        // 成功時アクション呼び出し
        console.log(payload)
        // yield put(change_response_file_update_disp(false));
        // yield put(change_response_files_loading(false));
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        // alert(action.payload.mime)
        a.download = action.payload.id + "." + action.payload.mime;
        // a.download = "10.pdf";
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();
        //    yield put(get_rent_contract_monies_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}




function* handle_update_line_looked(action: any): any {
    // yield put(change_line_files_loading(true));
    const state = yield select();

    // yield put(change_line_send_file_loading(true));

    const { payload, error } = yield call(rent_responses_api.change_status, state.responsesReducer.responsedetail.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let responsedetail = state.responsesReducer.responsedetail
        responsedetail.status = 6
        responsedetail.intendedRentMResponseStatusId = 6
        responsedetail.intended_rent_m_response_status_id = 6
        Responses.change_responsedetail(responsedetail)

        let contract_follow_list = state.contractfollow.contract_follow_list

        if(contract_follow_list.length > 0){//契約フォローのリストから開いている場合。
            if(contract_follow_list.filter((a: any) => a.rent_response_id === responsedetail.id).length > 0){
                contract_follow_list.filter((a: any) => a.rent_response_id === responsedetail.id)[0].status = 6
                contract_follow_list.filter((a: any) => a.rent_response_id === responsedetail.id)[0].intended_rent_m_response_status_id = 6
    
                ContractFollow.change_contract_follow_list(contract_follow_list)
                ContractFollow.search_contract_follow_list(contract_follow_list)
            }
        }

        yield put(change_line_looked_modal(false));


    } else {
        alert(error)

    }
}

function* handle_delete_line_link(action: any): any {
    // yield put(change_line_files_loading(true));
    const state = yield select();

    // yield put(change_line_send_file_loading(true));

    const { payload, error } = yield call(line_users_api.del_link, { rent_response_id: state.responsesReducer.responsedetail.id, line_user_id: action.payload.line_user_id, line_account_section_id: action.payload.line_account_section_id });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // line_user_details: state.line.line_user_details,
        yield put(get_line_user_details_success([]))
        
        yield put(get_line_users(state.responsesReducer.responsedetail.rent_customer_id))
    } else {
        alert(error)

    }
}

// function* handle_get_firestore_listen(action: any) {
//     alert()
//     const dblisten = firebase.firestore();

//     const state = yield select();

//     let lines = state.line.lines
//     let line_user_details = state.line.line_user_details
//     let users;


//     let a = eventChannel(emit => {
//         let linelistening = dblisten.collection("line").where("lineUserId", "==", line_user_details.id).where("timestamp", ">", Math.floor(new Date().getTime() / 1000)).orderBy("timestamp", "desc")
//         return linelistening.onSnapshot(snapshot => {
//             snapshot.docChanges().forEach(function (change) {
//                 alert("listen")
//                 console.log(lines)

//                 emit(lines.concat(change.doc.data()))

//                 // this.props.get_lines_success(lines.concat(change.doc.data()))
//                 // this.props.change_line_bottom(true);
//                 // this.setState({ reflesh: !this.state.reflesh })
//             });
//         })

//         // const unsubscribe = () => {
//         //     // linelistening()
//         // };

//         // return unsubscribe
//     })
//     // const query11 =db.collection("line").doc("7").collection(this.props.line_user_details.id).where("Timestamp",">",Math.floor( new Date().getTime() / 1000 ));

//     yield put(get_lines_success(a))
//     yield put(change_line_bottom(true))

//     // timelineというコレクションを更新日時によって並び変えている
//     // const colRef = dblisten.collection('timeline').orderBy('updatedAt')
//     // // そのtimelineコレクションの並びに変更があるか監視
//     // const channel = rsf.firestore.channel(colRef)

//     // while (true) {
//     //     // 更新を感知してhogehogeに格納
//     //     const hogehoge = yield take(channel)
//     //     // hogehogeをパラメータにactionを呼ぶ
//     //     yield put(action(hogehoge));
//     // }


//     // const state = yield select();

//     // let lines = state.line.lines
//     // const db = firebase.firestore();
//     // // const query11 =db.collection("line").doc("7").collection(this.props.line_user_details.id).where("Timestamp",">",Math.floor( new Date().getTime() / 1000 ));
//     // db.collection("line").where("lineUserId", "==", lines[0].lineUserId).where("timestamp", ">", Math.floor(new Date().getTime() / 1000)).orderBy("timestamp", "desc")
//     //     .onSnapshot((snapshot: any) => {
//     //         snapshot.docChanges().forEach(function (change: any) {
//     //             //    let aa =  change.doc.data()
//     //             //    aa.messageText= change.doc.data().messageText.replace(/\r?\n/g, '<br>')
//     //             //     alert(JSON.stringify(aa))
//     //             yield put(get_lines_success(lines.concat(change.doc.data())))
//     //             yield put(change_line_bottom(true))

//     //         }, yield)
//     //     }, yield);

// }


//firebase イベント受信
// function* messageChannel(messageId, firebase) {

//     const dblisten = firebase.firestore();

//     const state = yield select();

//     let lines = state.line.lines
//     let line_user_details = state.line.line_user_details

//     // const query11 =db.collection("line").doc("7").collection(this.props.line_user_details.id).where("Timestamp",">",Math.floor( new Date().getTime() / 1000 ));
//     return eventChannel(emit => {
//         const messageRef = dblisten.collection("line").where("lineUserId", "==", line_user_details.id).where("timestamp", ">", Math.floor(new Date().getTime() / 1000)).orderBy("timestamp", "desc");
//         messageRef.onSnapshot(snapshot => {
//             let val = snapshot.val();
//             val.key = snapshot.key;
//             emit(val);
//         });

//         const unsubscribe = () => {
//             messageRef.off()
//         };

//         return unsubscribe
//     })
// }