import React, { Component, useRef } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
// import RoomSearchListComponent from '../RoomList/Index';
import * as Files from './Saga/Files';
import Grid from '@mui/material/Grid';
import Loading from '../Layout/LoadingComponent';
import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import { Document, Page } from 'react-pdf';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Forms from './Css/FileUp.module.css'
import './Css/FileUp.css'
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
// import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PublicFileUpload from './PublicFileUpload';
import moment from "moment";
import * as Mail from '../Mail/Saga/Mail';
import EmailIcon from '@mui/icons-material/Email';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


moment.locale('ja')

// const styles = theme => ({
//   root: {
//     width: '200px',
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular,
//   },
// });

// タイマー用
let timer;

const files = ['pdf', 'png', 'jpg', 'jpeg', 'bmp', 'gif'];
class FileUpComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      deletealert: false,
      open: false,
      columnWidths: {
        name: 200,
        remarks: 300,
        disp: 110,
        created_at: 120,
      },
      tooltipOpen: 0,
      passTooltipOpen: 0,
      save: false
    };

    // this.props.change_contract_file_remarks(this.props.remarks)

    this.change_deletealert = this.change_deletealert.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.onChange = this.onChange.bind(this);
    this.change_contract_file_add = this.change_contract_file_add.bind(this);
    this.file_remarks_edit_open = this.file_remarks_edit_open.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.change_date = this.change_date.bind(this);
    this.handleSaveDialog = this.handleSaveDialog.bind(this);

    this.change_mail_new_file = this.change_mail_new_file.bind(this);
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);


    // this.props.get_rent_limited_public_pages_list(this.props.rent_contract_details.rent_response_id)
    // this.props.get_rent_limited_public_docs_list(this.props.rent_contract_details.rent_response_id)

  }

  change_deletealert(flg) {
    this.setState({ deletealert: flg })
  }

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {
    this.setState(({ columnWidths }) => ({
      columnWidths: {
        ...columnWidths,
        [columnKey]: newColumnWidth,
      },
    }));
  }

  // handleChange(id){
  //   let rent_limited_public_pages = []
  //   if(this.props.rent_limited_public_page_id !== id){
  //     rent_limited_public_pages = this.props.rent_limited_public_pages_list.filter((a) => a.id === id)[0]
  //   }
  //   this.props.change_rent_limited_public_pages(rent_limited_public_pages)
  //   this.props.change_rent_limited_public_page_id(this.props.rent_limited_public_page_id == id ? 0 : id)

  // }

  // onChange(e){
  //   let rent_limited_public_pages = this.props.rent_limited_public_pages_list.filter((a) => a.id === this.props.rent_limited_public_page_id)[0]
  //   rent_limited_public_pages[e.target.name] = e.target.value

  //   this.props.change_rent_limited_public_pages(rent_limited_public_pages)
  //   this.setState({ refresh: !this.state.refresh })

  // }

  change_contract_file_add(value) {

    this.setState({ fileid: 0, name: "", remarks: "" })
    this.props.change_rent_limited_public_docs_update_disp(true)
  }

  file_remarks_edit_open(value) {
    this.setState({ fileid: value.fileid, name: value.name, remarks: value.remarks })
    this.props.change_rent_limited_public_docs_update_disp(true)
  }

  handleUpdate(flg) {
    let rent_limited_public_pages = this.props.rent_limited_public_pages
    rent_limited_public_pages.is_deleted = flg
    this.props.change_rent_limited_public_pages(rent_limited_public_pages)

    this.props.update_rent_limited_public_pages()
    this.setState({ refresh: !this.state.refresh })
  }

  change_date(e) {

    let rent_limited_public_pages = this.props.rent_limited_public_pages

    rent_limited_public_pages.limited = moment(e).tz("Asia/Tokyo").format('YYYY/MM/DD')

    this.props.change_rent_limited_public_pages(rent_limited_public_pages)
    this.setState({ refresh: !this.state.refresh })
  }

  onChange(e) {

    let rent_limited_public_pages = this.props.rent_limited_public_pages

    rent_limited_public_pages[e.target.name] = e.target.value

    this.props.change_rent_limited_public_pages(rent_limited_public_pages)
    this.setState({ refresh: !this.state.refresh })
  }

  url = (e) => {
    console.log(e)
    e.target.select()
    navigator.clipboard.writeText(e.target.value);

    this.setState({ tooltipOpen: e.target.id })

    this.tooltipdelete()
  }

  pass = (e) => {
    console.log(e)
    e.target.select()
    navigator.clipboard.writeText(e.target.value);

    this.setState({ passTooltipOpen: e.target.id })

    this.tooltipdelete()
  }

  tooltipdelete = () => {

    setTimeout(() => {
      this.setState({ tooltipOpen: 0, passTooltipOpen: 0 })
    }, 1000)

  }

  handleSaveDialog() {
    this.setState({ save: !this.state.save })
  }

  change_mail_new_file() {

    this.props.change_mail_new_publicfiles({ rent_response_id: this.props.responsedetail.id, rent_limited_public_page_id: this.props.rent_limited_public_pages.id })


  }

  openWindow(id){
    window.open(`/FileDisp/` + '3/' + id, '_blank', 'noopener,width=500,toolbar=yes,menubar=yes,scrollbars=yes')
  }

  render() {

    return (

      <div>

        <Grid container>

          <Grid xs={4} style={{ padding: 5 }}>


            <Grid container spacing={3} style={{ padding: 10 }}>
              <Grid xs={9} style={{ padding: 5 }}>
                <TextField
                  id={this.props.rent_limited_public_pages.id}
                  label="URL"
                  type="text"
                  onClick={this.url}
                  value={"https://rooms.global-center.co.jp/publicdocument/" + this.props.rent_limited_public_pages.public_code + "?openExternalBrowser=1"}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
                <Tooltip title="コピーしました！" open={parseInt(this.state.tooltipOpen) === this.props.rent_limited_public_pages.id ? true : false}>
                  <div></div>
                </Tooltip>
              </Grid>
              <Grid xs={3} style={{ padding: 5 }}>
                <TextField
                  id={this.props.rent_limited_public_pages.id}
                  label="パス"
                  type="text"
                  onClick={this.pass}
                  value={this.props.rent_limited_public_pages.public_password}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
                <Tooltip title="コピーしました！" open={parseInt(this.state.passTooltipOpen) === this.props.rent_limited_public_pages.id ? true : false}>
                  <div></div>
                </Tooltip>
              </Grid>
              <Grid xs={12} style={{ padding: 5 }}>
                <TextField
                  label="ページ名"
                  // multiline
                  // rows={5}
                  name="name"
                  onChange={this.onChange}
                  value={this.props.rent_limited_public_pages.name}
                  // style={{ width: "300px", marginTop: "10px" }}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
              </Grid>
              <Grid xs={12} style={{ padding: 5 }}>
                <TextField
                  label="備考"
                  multiline
                  rows={3}
                  name="remarks"
                  onChange={this.onChange}
                  value={this.props.rent_limited_public_pages.remarks}
                  // style={{ width: "300px", marginTop: "10px" }}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={12} style={{ padding: 5 }}>

                <TextField
                  fullWidth
                  id="limited"
                  label="公開期限日"
                  type="date"
                  name="limited"
                  size="small"
                  // style={{marginLeft:20}}
                  value={moment(this.props.rent_limited_public_pages.limited).format("YYYY-MM-DD")}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  variant="standard"
                  onChange={this.onChange}
                />

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>

                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd(EE)"
                    // margin="normal"
                    id="date-picker-inline1"
                    label="公開期限日"
                    style={{ float: "left", marginTop: "0" }}
                    autoOk={true}
                    error={!this.props.rent_limited_public_pages.limited}
                    InputLabelProps={{ shrink: true }}
                    name="limited"
                    value={this.props.rent_limited_public_pages.limited}
                    onChange={this.change_date}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    invalidDateMessage={"日付の形式が違います"}
                    margin="dense"
                  />

                </MuiPickersUtilsProvider> */}
              </Grid>
              <Grid xs={12} style={{ padding: 5 }}>




                {/* <Button variant="contained" style={{ float: "right" }} onClick={() => this.handleUpdate(1)} color="secondary">
            削除
            </Button> */}
                <Button variant="contained" style={{ float: "right" }} onClick={() => { this.handleUpdate(0); this.handleSaveDialog() }} color="primary">
                  保存
                </Button>

                {/* <Button variant="contained" style={{ float: "right" }} onClick={() => this.change_mail_new_file()} color="primary">
                  メール送信
                </Button> */}
                <Fab color="primary" aria-label="add" onClick={() => this.change_mail_new_file()} style={{ height: "34px", width: "36px" }}>
                  <EmailIcon />
                </Fab>

              </Grid>
              <Grid xs={12} style={{ padding: 5, marginTop: 50 }}>
                {this.state.deletealert ? <Alert onClose={() => { this.change_deletealert(false) }} style={{ textAlign: "left" }} severity="error">本当に削除してもよろしいですか？ <Button variant="contained" style={{}} onClick={() => { this.handleUpdate(1); this.change_deletealert(false); this.props.change_contract_file_update_disp(false) }} color="secondary">
                  削除
                </Button></Alert> : <Button variant="contained" style={{ float: "left" }} onClick={() => this.change_deletealert(true)} color="secondary">
                  削除
                </Button>}
              </Grid>
            </Grid>

          </Grid>
          <Grid xs={8} style={{ padding: 5 }}>

            {this.props.rent_limited_public_pages.id > 0 && this.props.rent_limited_public_docs_list ? <Table
              rowHeight={38}
              rowsCount={this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id).length}
              height={700}
              headerHeight={30}
              width={740}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
            >
              <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{ marginTop: -5 }} onClick={(e) => { this.props.download_rent_limited_public_docs(this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex]) }} aria-label="edit" size="small"><GetAppIcon /></IconButton></Cell>)} width={40} />
              <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<Cell>ファイル名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].name}</Cell>)} />
              <Column width={this.state.columnWidths.remarks} isResizable={true} columnKey="remarks" header={<Cell>備考</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].remarks}</Cell>)} />
              <Column width={this.state.columnWidths.disp} isResizable={true} columnKey="disp" header={<Cell>表示</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>

                {files.includes(this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].ext.trim()) ? <span>
                <Button variant="contained" style={{}} size="small" onClick={() => this.props.download_disp_limited_public_docs(this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex])} color="primary">
                  表示
                </Button>
                <IconButton size="small" onClick={() => this.openWindow(this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].id)} color="primary">
                  <OpenInNewIcon />
                </IconButton>
                </span>:""}
              </Cell>)} />
              
              <Column width={this.state.columnWidths.created_at} isResizable={true} columnKey="created_at" header={<Cell>アップロード日</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].created_at).format('YYYY-MM-DD HH:mm:ss')}</Cell>)} />
              <Column header={<Cell>編集</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                <IconButton style={{ marginTop: -5 }} onClick={(e) => { this.file_remarks_edit_open({ fileid: this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].id, name: this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].name, remarks: this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].remarks ? this.props.rent_limited_public_docs_list.filter(a => a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].remarks : "" }) }} aria-label="edit" size="small">
                  <EditIcon />
                </IconButton></Cell>)} width={45} />

              {/* <Column header={<Cell></Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}><IconButton style={{marginTop:-5}} onClick={(e) => { this.handleDelOpen(this.props.rent_limited_public_docs_list.filter(a=>a.rent_limited_public_page_id == this.props.rent_limited_public_pages.id)[rowIndex].id); }} aria-label="edit" size="small"><DeleteForeverIcon /></IconButton></Cell>)} width={40}/> */}
            </Table> : ""}
          </Grid>
        </Grid>
        {this.props.rent_limited_public_pages.id > 0 ? <Fab color="primary" aria-label="add" onClick={this.change_contract_file_add} style={{ zIndex: 2000, height: "34px", width: "36px", position: "absolute", bottom: "10px", right: "10px" }}>
          <AddIcon style={{ fontSize: "20px" }} />
        </Fab> : ""}


        {this.props.rent_limited_public_docs_update_disp ? <Paper id="responsefileupload" style={{ height: "400px", width: "400px", position: "absolute", bottom: "20px", right: "100px", padding: 10, zIndex: 1000 }}>公開ファイル
          <BottomNavigationAction label="Nearby" onClick={(e) => { this.props.change_rent_limited_public_docs_update_disp(false) }} style={{ float: "right", paddingTop: 0, paddingBottom: 0, paddingRight: 0 }} icon={<CloseIcon />} />
          <hr />
          <PublicFileUpload fileid={this.state.fileid} name={this.state.name} remarks={this.state.remarks} />
        </Paper> : ""}

        <Dialog onClose={this.handleSaveDialog} open={this.state.save}>
          <DialogTitle>保存しました</DialogTitle>
        </Dialog>




      </div >

    );

  }
}




//コンテナ
const mapStateToProps = state => {
  return {

    response_file_remarks: state.responsefiles.response_file_remarks,
    file_tab: state.files.file_tab,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_limited_public_pages_list: state.files.rent_limited_public_pages_list,
    rent_limited_public_pages: state.files.rent_limited_public_pages,
    // rent_limited_public_page_id: state.files.rent_limited_public_page_id,
    rent_limited_public_docs_list: state.files.rent_limited_public_docs_list,
    rent_limited_public_docs_update_disp: state.files.rent_limited_public_docs_update_disp,

    mail_details: state.mailReducer.mail_details,
    mail_new: state.mailReducer.mail_new,
    customerdetail: state.customersReducer.customerdetail,
    responsedetail: state.responsesReducer.responsedetail,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    // change_contract_file_remarks(state) {
    //   dispatch(Files.change_contract_file_remarks(state))
    // },


    get_rent_limited_public_pages_list(state) {
      dispatch(Files.get_rent_limited_public_pages_list(state))
    },
    change_rent_limited_public_pages(state) {
      dispatch(Files.change_rent_limited_public_pages(state))
    },
    // change_rent_limited_public_page_id(state) {
    //   dispatch(Files.change_rent_limited_public_page_id(state))
    // },
    add_rent_limited_public_pages(state) {
      dispatch(Files.add_rent_limited_public_pages(state))
    },
    update_rent_limited_public_pages(state) {
      dispatch(Files.update_rent_limited_public_pages(state))
    },
    download_disp_limited_public_docs(state) {
      dispatch(Files.download_disp_limited_public_docs(state))
    },
    download_rent_limited_public_docs(state) {
      dispatch(Files.download_rent_limited_public_docs(state))
    },
    change_rent_limited_public_docs_update_disp(state) {
      dispatch(Files.change_rent_limited_public_docs_update_disp(state))
    },
    change_contract_file_update_disp(state) {
      dispatch(Files.change_contract_file_update_disp(state))
    },
    change_mail_new_publicfiles(state) {
      dispatch(Mail.change_mail_new_publicfiles(state))
    }

  };
}


export default connect(mapStateToProps, mapDispatchToProps)(FileUpComponent);
