import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from "./Grid";
import OtherSalesForm from "./OtherSalesForm";
import Search from "./Search";
import { Modal, ModalFooter } from 'reactstrap';
import withStyles from '@mui/styles/withStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
var moment = require('moment-timezone');

const styles = (theme: any) => ({
    margin: {
        margin: theme.spacing(1),
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 1,
        zIndex: 20000,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    style={{ position: 'absolute' }}
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}

        </MuiDialogTitle>
    );
});





interface Props {
    user_details: any,
}

interface State {
    form_toggle: boolean,
    search_date1: any,
    search_date2: any,
    search_section: any,
    search_user: any,
    search_uriage: any,
    search_creatdate1: any,
    search_creatdate2: any,
}

const date1 = new Date();
const year = date1.getFullYear();
const month = date1.getMonth() + 1;
var sdate = moment(year + "-" + month + "-01").format('YYYY-MM-DD');

class ResponseFollowSheet extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            form_toggle: false,
            search_date1: null,
            search_date2: null,
            search_section: null,
            search_user: null,
            search_uriage: null,
            search_creatdate1: null,
            search_creatdate2: null,
        }

        this.form_toggle = this.form_toggle.bind(this);
        this.search_change = this.search_change.bind(this);
        this.searchdate_change = this.searchdate_change.bind(this);

    }


    search_change(e: any) {
        this.setState({ ...this.state, [e.target.id]: e.target.value });
    }
    searchdate_change(e: any, id: any) {
        this.setState({ ...this.state, [id]: e });
    }

    form_toggle() {
        this.setState({ form_toggle: !this.state.form_toggle })
    }

    render() {

        return (
            <div className="item-outer col-lg-12">
                {/* 検索枠 */}
                {this.props.user_details.amSectionId ?
                    <div>
                        <Card >
                            <CardContent >
                                <Search
                                    section_id={this.props.user_details.amSectionId}
                                    search_state={this.state}
                                    searchdate_change={this.searchdate_change}
                                    search_change={this.search_change} />
                            </CardContent >
                        </Card >
                    </div> :
                    <Card ><CardContent ><div style={{ height: 76 }}></div></CardContent ></Card >}


                {/* リスト枠 */}
                <div style={{ marginTop: 10 }}>
                    <Card >
                        <CardContent >
                            <Grid form_toggle={this.form_toggle} />
                        </CardContent >
                    </Card >
                </div>





                {/* 登録・編集を行うフォーム */}
                < Modal isOpen={this.state.form_toggle} toggle={this.form_toggle} size="xl" fade={false}>
                    <DialogTitle id="customized-dialog-title" onClose={this.form_toggle} >その他売上</DialogTitle>
                    <OtherSalesForm />
                    <ModalFooter>
                    </ModalFooter>
                </Modal >

            </div >
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
        user_details: state.masterReducer.user_details,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResponseFollowSheet);




