import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Estimates from './Saga/Estimates';
import './Css/Grid.css';
import './Css/Form.css';
// import EstimateGridComponent from './EstimateGridComponent';
import EstimateMonthGridComponent from './EstimateMonthGridComponent';
import EstimateAfterGridComponent from './EstimateAfterGridComponent';
import EstimateOnetimeGridComponent from './EstimateOnetimeGridComponent';
import EstimatesInfoEditComponent from './EstimatesInfoEditComponent';
import EstimatesEtcEdit from './EstimatesEtcEdit';
import EstimatesSumEdit from './EstimatesSumEdit';
import { Row, Col } from 'reactstrap';
import Loading from '../Layout/LoadingComponent';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
let rowss = [
    { id: 0, name1: "name1", kana1: 20 },
    { id: 1, name1: "kana1", kana1: 40 },
    { id: 2, name1: "Task 3", kana1: 60 }
];



interface Props {
    // chinryou_koumokus: any
    // estimateData:any,
    // rentResponseId: number,
    get_estimate_doc: any,
    estimate_loading: boolean
    rentEstimateId: number,
    update_estimateMonies: any,
    tatemonoId: string
    roomId: string
    estimate_update_flg: boolean
}

interface State {
    refresh: boolean,
    submitted: boolean
}


class EstimateComponent extends Component<Props, State>  {
    constructor(props: any) {
        super(props);
        this.state = {

            refresh: true,
            submitted: false,
        }

        this.refresh = this.refresh.bind(this);
        if (this.props.rentEstimateId) {

            props.get_estimates({ rentEstimateId: this.props.rentEstimateId, tatemonoId: null, roomId: null });
        } else {


            props.get_estimates({ rentEstimateId: 0, tatemonoId: this.props.tatemonoId, roomId: this.props.roomId });
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        // props.get_chinryou_koumokus();
    }
    refresh() {

        this.setState({ refresh: !this.state.refresh })

    }


    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }
    render() {


        return (
            <Row>
                <Col lg="8" className="item-outer">
                    {/* <div className="item">
             
                </div> */}


                    <Col lg="12" className="item-outer">
                        <div className="item">
                            {this.props.estimate_loading === true ? <Loading /> : <EstimatesInfoEditComponent />}
                            {/* <EstimateGridComponent rowss={rowss} chinryou_koumokus={this.props.chinryou_koumokus}/> */}


                        </div>
                    </Col>
                    <Col lg="12" className="item-outer">

                        {this.props.estimate_loading === true ? <Loading /> : <EstimateMonthGridComponent />}
                        {/* <EstimateGridComponent /> */}
                    </Col>
                    <Col lg="12" className="item-outer">
                        {this.props.estimate_loading === true ? <Loading /> : <EstimateOnetimeGridComponent />}
                    </Col>



                    <Col lg="12" className="item-outer">
                        {this.props.estimate_loading === true ? <Loading /> : <EstimateAfterGridComponent refresh={this.refresh} />}
                    </Col>





                </Col>

                <Col lg="4" className="item-outer">

                    <Col lg="12" className="item-outer">
                        <div className="item">
                            {this.props.estimate_loading === true ? <Loading /> : <EstimatesEtcEdit />}
                        </div>
                    </Col>

                    <Col lg="12" className="item-outer">
                        <div className="item">

                            {this.props.estimate_loading === true ? <Loading /> : <EstimatesSumEdit />}
                        </div>
                    </Col>
                    <Col lg="12" className="item-outer">
                        <div className="item">



                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={this.state.submitted}
                                onClick={this.props.update_estimateMonies}
                            >
                                {
                                    (this.state.submitted && '更新中...')
                                    || (!this.state.submitted && '保存')
                                }
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                style={{ marginLeft: "10px" }}
                                onClick={this.props.get_estimate_doc}
                            >見積書
                </Button>
                        </div>
                    </Col>


                </Col>
                <Backdrop open={this.props.estimate_update_flg} style={{ zIndex: 7000 }} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Row>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        chinryou_koumokus: state.estimates.chinryou_koumokus,
        // estimates: state.estimates.estimates,

        estimate_loading: state.estimates.estimate_loading,
        estimate_update_flg: state.estimates.estimate_update_flg,
        // customerlist: state.customersReducer.customerlist
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        // get_chinryou_koumokus(state: any) {
        //     dispatch(Estimates.get_chinryou_koumokus(state))
        // },
        get_estimates(state: any) {
            dispatch(Estimates.get_estimates(state))
        },
        get_estimate_doc(state: any) {
            dispatch(Estimates.get_estimate_doc(state))
        },
        update_estimateMonies(state: any) {
            dispatch(Estimates.update_estimateMonies(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimateComponent);