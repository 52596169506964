
const authentication = require('react-azure-adb2c2').default;




export const get_images = (values) => {

    const url = process.env.REACT_APP_API_BASE_URL + `/api/lineimage/` + values

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const download_file = (values) => {

    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/getsendfile/` + values;
    // let url = `http://localhost:5000/v1/lines/getsendfile/` + values;

    // alert(url)

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const download_receivefile = (values) => {

    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/getfile/` + values;
    // let url = `http://localhost:5000/v1/lines/getsendfile/` + values;

    // alert(url)

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};