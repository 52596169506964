import React, { Component } from 'react';
import styled from 'styled-components'
import './Css/Pin.css'

import CommentIcon from '@mui/icons-material/Comment';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';
import TrainIcon from '@mui/icons-material/Train';
import StoreIcon from '@mui/icons-material/Store';
import AdjustIcon from '@mui/icons-material/Adjust';
import CircleIcon from '@mui/icons-material/Circle';


import GoogleMapReact from 'google-map-react'
import { connect } from 'react-redux';
import { submit } from 'redux-form'

import { change_map_polygon_settings, change_map_point_settings, change_map_settings_flg, change_rent_introduction_point_template_headers, change_rent_introduction_point_templates, change_map_disp, update_rent_introduction_point_detail_templates } from './Saga/MapMypageTemplate';
//   change_showRight,
//   change_showFlag,
//   change_draggable }from './Sagaes/MapSearch';

const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,


  }
}



const kaigyo = (str) => {
  return str.split("\n").map((m,i) => <span key={i}>{m}<br/></span>)
};

const R = Math.PI / 180;

function distance(lat1, lng1, lat2, lng2) {
  lat1 *= R;
  lng1 *= R;
  lat2 *= R;
  lng2 *= R;
  // alert(6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2)))
  return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}

// const App = (props) =>  (
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {

      draggable: true,

    }
    this.onChildClick = this.onChildClick.bind(this);
    this.onClick = this.onClick.bind(this);
    this.toggleestimates = this.toggleestimates.bind(this);
    this.onChildMouseUp = this.onChildMouseUp.bind(this);
    this.onChildMouseMove = this.onChildMouseMove.bind(this);
    this.setDraggable = this.setDraggable.bind(this);

  }
  setDraggable(e) {
    this.setState({ draggable: e })

  }
  toggleestimates() {
    this.setState({ a: false })
  }
  onChildClick = (key, childProps) => {
    if (this.props.mapmypagetemplate.map_settings_flg === 1) {
      console.log(childProps)
      // alert("編集")
      // alert(this.props.rent_response_id)
    } else if (this.props.mapmypagetemplate.map_settings_flg === 1) {

      // this.props.get_map_room_details({tatemono_id:key, rent_response_id:this.props.rent_response_id});

    }


    // this.props.onCenterChange([childProps.lat, childProps.lng]);
  }

  onClick = (event) => {
    if (this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 2 || this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 4) {
      console.log(event)
      // let map_polygon_settings=this.props.mapmypagetemplate.rent_introduction_point_headers.type==
      let rent_introduction_point_template_headers = this.props.mapmypagetemplate.rent_introduction_point_template_headers

      let max = Math.max.apply(null, rent_introduction_point_template_headers.rent_introduction_point_template_details.map(function (o) { return o.no; }))
      max = max > 0 ? max : 0
      let point = { lat: event.lat, lng: event.lng, name: (max + 1), no: (max + 1) }
      //  let point={lat:event.lat,lng:event.lng,name:event.lat}


      rent_introduction_point_template_headers.rent_introduction_point_template_details = rent_introduction_point_template_headers.rent_introduction_point_template_details.concat(point)

      this.props.change_rent_introduction_point_template_headers(rent_introduction_point_template_headers)

      this.props.update_rent_introduction_point_detail_templates()
      this.props.change_map_disp(!this.props.mapmypagetemplate.map_disp)

    }else if (this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 5) {
      console.log(event)
      if(this.props.mapmypagetemplate.rent_introduction_point_template_headers.rent_introduction_point_template_details.length == 0){
        let rent_introduction_point_template_headers = this.props.mapmypagetemplate.rent_introduction_point_template_headers

        let max = Math.max.apply(null, rent_introduction_point_template_headers.rent_introduction_point_template_details.map(function (o) { return o.no; }))
        max = max > 0 ? max : 0
        let point = { lat: event.lat, lng: event.lng, name: (max + 1), no: (max + 1) }
        //  let point={lat:event.lat,lng:event.lng,name:event.lat}

        rent_introduction_point_template_headers.rent_introduction_point_template_details = rent_introduction_point_template_headers.rent_introduction_point_template_details.concat(point)

        let point2 = { lat: event.lat, lng: event.lng + 0.011, name: (max + 2), no: (max + 2) }
        rent_introduction_point_template_headers.rent_introduction_point_template_details = rent_introduction_point_template_headers.rent_introduction_point_template_details.concat(point2)

        this.props.change_rent_introduction_point_template_headers(rent_introduction_point_template_headers)

        this.props.update_rent_introduction_point_detail_templates()
        this.props.change_map_disp(!this.props.mapmypagetemplate.map_disp)
      }
    }
  }

  onBoundsChange = (center, zoom, bounds, marginBounds, map) => {
    console.log(bounds)

    this.props.bounds(bounds);
    this.props.submit('mapsearchform')
    let rent_introduction_point_templates = this.props.mapmypagetemplate.rent_introduction_point_templates

    rent_introduction_point_templates.lat = center[0]
    rent_introduction_point_templates.lng = center[1]
    rent_introduction_point_templates.zoom = zoom
    this.props.change_rent_introduction_point_templates(rent_introduction_point_templates)
    // 

  }
  onChildMouseUp() {
    this.setState({ draggable: true })

    if (this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 2 || 
      this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 4 || 
      this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 5){
      this.props.update_rent_introduction_point_detail_templates()
    }
    this.props.change_map_disp(!this.props.mapmypagetemplate.map_disp)
  }
  onChildMouseMove(childKey, childProps, mouse) {
    this.setState({
      draggable: false,

    });
    let rent_introduction_point_template_headers = this.props.mapmypagetemplate.rent_introduction_point_template_headers
    // function is just a stub to test callbacks
    console.log(childProps)

    if (rent_introduction_point_template_headers.type === 2 || rent_introduction_point_template_headers.type === 4 || rent_introduction_point_template_headers.type === 5) {

      if (rent_introduction_point_template_headers.rent_introduction_point_template_details.filter(a => a.no === childProps.no)[0]) {
        rent_introduction_point_template_headers.rent_introduction_point_template_details.filter(a => a.no === childProps.no)[0].lat = mouse.lat
        rent_introduction_point_template_headers.rent_introduction_point_template_details.filter(a => a.no === childProps.no)[0].lng = mouse.lng


        console.log(rent_introduction_point_template_headers)
        this.props.change_rent_introduction_point_template_headers(rent_introduction_point_template_headers)
      }


    } else if (rent_introduction_point_template_headers.type > 0) {//移動

      // alert(childKey)

      if (rent_introduction_point_template_headers.rent_introduction_point_template_details.filter(a => a.id === childProps.id && childProps.flg === 2)[0]) {
        rent_introduction_point_template_headers.rent_introduction_point_template_details.filter(a => a.id === childProps.id && childProps.flg === 2)[0].lat = mouse.lat
        rent_introduction_point_template_headers.rent_introduction_point_template_details.filter(a => a.id === childProps.id && childProps.flg === 2)[0].lng = mouse.lng


        console.log(rent_introduction_point_template_headers)
        this.props.change_rent_introduction_point_template_headers(rent_introduction_point_template_headers)
      }
    }


  }

  open_window(url) {
    window.open(url, '_blank', 'noopener')
  }

  render() {
    const apiLoaded = (map, maps, rent_introduction_point_templates, type) => {

      if (rent_introduction_point_templates.rent_introduction_point_template_headers) {

        rent_introduction_point_templates.rent_introduction_point_template_headers.map(async function (pinsheader) {

          if (pinsheader.rent_introduction_point_template_details) {

            // pinsheader.rentIntroductionPointDetails.map((pins) => {
            if (pinsheader.type === 2) {

              const path = new maps.Polygon({
                path: pinsheader.rent_introduction_point_template_details.map((p) => ({
                  lat: parseFloat(p.lat),
                  lng: parseFloat(p.lng)
                })),
                geodesic: true,
                strokeColor: pinsheader.rent_introduction_point_template_details.length > 0 ? pinsheader.rent_introduction_point_template_details[0].point_color == null ? '#ff69b4' : pinsheader.rent_introduction_point_template_details[0].point_color :'#ff69b4',
                strokeOpacity: 1,
                strokeWeight: 2,
              })

              path.setMap(map)
            } else if (pinsheader.type === 4) {

              const path = new maps.Polyline({
                path: pinsheader.rent_introduction_point_template_details.map((p) => ({
                  lat: parseFloat(p.lat),
                  lng: parseFloat(p.lng)
                })),
                geodesic: true,
                strokeColor: pinsheader.rent_introduction_point_template_details.length > 0 ? pinsheader.rent_introduction_point_template_details[0].point_color == null ? '#ff4500' : pinsheader.rent_introduction_point_template_details[0].point_color :'#ff4500',
                strokeOpacity: 1,
                strokeWeight: 5,
              })
              path.setMap(map)
            } else if (pinsheader.type === 5) {

              const path = new maps.Circle({
                center: {
                  lat: pinsheader.rent_introduction_point_template_details.length == 2 ? parseFloat(pinsheader.rent_introduction_point_template_details[0].lat) :  0,
                  lng: pinsheader.rent_introduction_point_template_details.length == 2 ? parseFloat(pinsheader.rent_introduction_point_template_details[0].lng) : 0,
                },
                radius: pinsheader.rent_introduction_point_template_details.length == 2 ? distance(parseFloat(pinsheader.rent_introduction_point_template_details[0].lat), parseFloat(pinsheader.rent_introduction_point_template_details[0].lng),parseFloat(pinsheader.rent_introduction_point_template_details[1].lat),parseFloat(pinsheader.rent_introduction_point_template_details[1].lng)) * 1000 : 0,
                geodesic: true,
                strokeColor: pinsheader.rent_introduction_point_template_details.length > 0 ? pinsheader.rent_introduction_point_template_details[0].point_color == null ? '#ff4500' : pinsheader.rent_introduction_point_template_details[0].point_color :'#ff4500',
                strokeOpacity: 1,
                strokeWeight: 2,
              })

              path.setMap(map)
            }
            // })
          }
        })
      }
    }

    // var point = this.props.mapmypage.rent_introduction_point_templates.rent_introduction_details ? this.props.mapmypage.rent_introduction_point_templates.rent_introduction_details.filter(function (x, i, self) {
    //   return (self.findIndex(function (v2) {
    //     return (parseInt(x.tatemono_id) === parseInt(v2.tatemono_id))
    //   }) === i);
    // }) : null;
    // console.log(point)

    return (



      <GoogleMapReact
        ref={(map) => this._map = map}
        bootstrapURLKeys={{
          key: 'AIzaSyClDVE129NsjckVjDJpMUpqBUuqMTg5YUo'
        }}
        // defaultCenter={{
        //   lat: 34.341522,
        //   lng: 134.045426,
        // }}
        defaultCenter={{
          lat: this.props.mapmypagetemplate.rent_introduction_point_templates.lat ? parseFloat(this.props.mapmypagetemplate.rent_introduction_point_templates.lat) : 34.341522,
          lng: this.props.mapmypagetemplate.rent_introduction_point_templates.lng ? parseFloat(this.props.mapmypagetemplate.rent_introduction_point_templates.lng) : 134.045426,
        }}

        defaultZoom={this.props.mapmypagetemplate.rent_introduction_point_templates.zoom ? this.props.mapmypagetemplate.rent_introduction_point_templates.zoom : 12}
        getMapBounds={this.getMapBounds}
        options={createMapOptions}
        // draggable={this.props.mapsearch.draggable}
        draggable={this.state.draggable}
        onBoundsChange={this.onBoundsChange.bind(this)}
        onChildClick={this.onChildClick}

        onClick={this.onClick}
        yesIWantToUseGoogleMapApiInternals={true}
        // onGoogleApiLoaded={ this.handleApiLoaded.bind(this) }
        onGoogleApiLoaded={({ map, maps }) => apiLoaded(map, maps, this.props.mapmypagetemplate.rent_introduction_point_templates, this.props.mapmypagetemplate.rent_introduction_point_template_headers.type)}
        // onChildMouseDown={this.onChildMouseDown.bind(this)}
        // onChildMouseUp={this.onChildMouseUp.bind(this)}
        // onChildMouseMove={this.onCircleInteraction.bind(this)}
        onChildMouseUp={this.onChildMouseUp}
        onChildMouseMove={this.onChildMouseMove}
      >





        {
          this.props.mapmypagetemplate.rent_introduction_point_template_headers.rent_introduction_point_template_details ? this.props.mapmypagetemplate.rent_introduction_point_template_headers.rent_introduction_point_template_details.map((pin) => (




            this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 2 ? <Pin
              style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              no={pin.no}
              lat={pin.lat}
              lng={pin.lng}
              // key={"pin" + pin.id}
            >
              <div class="wrapper">

                <div class="pin">{pin.no}</div>

              </div>


            </Pin> : this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 4 ? <Pin
              style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              no={pin.no}
              lat={pin.lat}
              lng={pin.lng}
              // key={"pin" + pin.id}
            >
              <div class="wrapper">

                <div class="pinline">{pin.no}</div>

              </div>

            </Pin> : this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 5 ? <Pin
              style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              no={pin.no}
              lat={pin.lat}
              lng={pin.lng}
              // key={"pin" + pin.id}
            >
              <div class="wrapper">

                <div class="pincircle" >{pin.no == 1 ? <AdjustIcon />:<CircleIcon style={{color:"#fc4f83"}} />}</div>

              </div>


            </Pin> : this.props.mapmypagetemplate.rent_introduction_point_template_headers.type === 3 ? <Pin
              style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              id={pin.id}
              lat={pin.lat}
              lng={pin.lng}
              flg={2}
              // key={"pin" + pin.id}
            >
              <div class="wrapper">
                <div class="pinComment">
                  <CommentIcon />
                </div>


              </div>
              {/* <PointEdit id={pin.id} contents={pin.contents} setDraggable={this.setDraggable} /> */}
            </Pin> : <Pin
              style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              id={pin.id}
              lat={pin.lat}
              lng={pin.lng}
              key={"pin" + pin.id}
              flg={2}
            >
                    <div class="wrapper">
                      <div class="PinDetail">
                        {pin.point_icon == 1 ? <ApartmentIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : 
                        pin.point_icon == 2 ? <HomeIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : 
                        pin.point_icon == 3 ? <TrainIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : 
                        pin.point_icon == 4 ? <StoreIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : <LocationOnIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} />
                        }
                        
                      </div>
                    </div>

                    {/* <PointEdit id={pin.id} contents={pin.contents} setDraggable={this.setDraggable} /> */}
                    {/* :this.props.mapmypagetemplate.rent_introduction_point_template_headers.contents?<div style={{position: 'absolute',whiteSpace: "nowrap",opacity: "0.8",padding:1,paddingLeft:10,paddingRight:10,borderRadius: 10,backgroundColor:"#ff4500",color:"#FFF",fontWeight:"bold",fontSize:12}}>{this.props.mapmypage.rent_introduction_point_headers.contents}</div> */}

                  </Pin>

          )) : this.props.mapmypagetemplate.rent_introduction_point_templates.rent_introduction_point_template_headers ? this.props.mapmypagetemplate.rent_introduction_point_templates.rent_introduction_point_template_headers.map((pointheader, index) => {
            //全表示


            return pointheader.rent_introduction_point_template_details ? pointheader.rent_introduction_point_template_details.map((pin) => (

              pointheader.type === 3 ? <Pin
                style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
                id={pin.id}
                lat={pin.lat}
                lng={pin.lng}
                flg={2}
                key={"pin" + pin.id}
              >
                <div class="wrapper">
                  <div style={{ position: 'absolute', whiteSpace: "nowrap", opacity: "0.8", padding: 1, paddingLeft: 10, paddingRight: 10, padding: 10, borderRadius: 10, backgroundColor: "#32cd32", color: "#FFF", fontWeight: "bold", fontSize: 12,textAlign:"left" }}>
                    {pin.link_url ? <span className='link' onClick={() => this.open_window(pin.link_url)}>{kaigyo(pointheader.contents)}</span> : <span>{kaigyo(pointheader.contents)}</span>}
                  </div>
                </div>
                {/* <PointEdit id={pin.id} contents={pin.contents} setDraggable={this.setDraggable}/> */}
              </Pin> : pointheader.type === 1 ? <Pin
                style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
                id={pin.id}
                key={"pin" + pin.id}
                lat={pin.lat}
                lng={pin.lng}

                flg={2}
              >
                <div class="wrapper">
                  <div class="">
                    {/* <LocationOnIcon fontSize="large" /> */}
                    {pin.point_icon == 1 ? <ApartmentIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : 
                        pin.point_icon == 2 ? <HomeIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : 
                        pin.point_icon == 3 ? <TrainIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : 
                        pin.point_icon == 4 ? <StoreIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : <LocationOnIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} />
                        }
                  </div>
                </div>

                {/* <PointEdit id={pin.id} contents={pin.contents} setDraggable={this.setDraggable}/> */}
                <div style={{ position: 'absolute', whiteSpace: "nowrap", opacity: "0.8", padding: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: pin.point_color == null ? "red" : pin.point_color, color: "#FFF", fontWeight: "bold", fontSize: 12, textAlign:"left" }}>
                  {/* {pointheader.contents} */}
                  {pin.link_url ? <span className='link' onClick={() => this.open_window(pin.link_url)}>{kaigyo(pointheader.contents)}</span> : <span>{kaigyo(pointheader.contents)}</span>}
                </div>

              </Pin> : ""
            )) : ""
          }, this) : ""
        }




      </GoogleMapReact >
    )

  }
}



const Pin = styled.div``




export default connect(
  ({ mapmypagetemplate }) => ({ mapmypagetemplate }),
  {

    submit,
    change_map_polygon_settings,
    change_map_point_settings,
    change_map_settings_flg,
    change_rent_introduction_point_template_headers,
    change_rent_introduction_point_templates,
    change_map_disp,
    update_rent_introduction_point_detail_templates
    // MapSearch
  }
)(App)
