import React, { Component } from 'react';

import { connect } from 'react-redux';

import CustomerEdit from './CustomerEdit';
import CustomerDisp from './CustomerDisp';

import * as Customers from './Saga/Customers';





class CustomerInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
        };
        if (this.props.customeredit === 0 || this.props.rent_customer_editor_forms.rent_customer_id) {

            this.props.get_customer_details({ rent_contract_id: this.props.rent_contract_id, rent_response_id: this.props.rent_response_id })

        }
        if (this.props.rent_m_agents.length === 0) {
            this.props.get_rent_m_agents()
        }
    }

    render() {

        return (

            <div >


                {this.props.customeredit === 0 ? <CustomerDisp /> : <CustomerEdit />}


            </div>

        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {
        customeredit: state.customersReducer.customeredit,
        rent_customer_editor_forms: state.customersReducer.rent_customer_editor_forms,
        rent_m_agents: state.customersReducer.rent_m_agents,
        // customeredit: state.customersReducer.customeredit,
        //customerdetail1: state.customersReducer.customerdetail1,
        //firebasetoken: state.firebaseReducer.firebasetoken,

    }
}
function mapDispatchToProps(dispatch) {
    return {


        get_customer_details(state) {
            dispatch(Customers.get_customer_details(state))
        },
        change_customeredit(state) {
            dispatch(Customers.change_customeredit(state))
        },
        get_rent_m_agents(state) {
            dispatch(Customers.get_rent_m_agents(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfo);
