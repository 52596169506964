import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';

import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import { Row, Col } from 'reactstrap';

import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
const moment = require('moment-timezone');
class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.numPages = this.numPages.bind(this);
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }

  render() {
    let special_contract_building = ""
    if (this.props.rent_contract_details_old.special_contract_building !== this.props.rent_contract_details_new.special_contract_building) {
      special_contract_building = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.special_contract_building}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.special_contract_building}</div></span>

    } else {
      special_contract_building = this.props.rent_contract_details_new.special_contract_building

    }


    let special_contract_conditions = ""
    if (this.props.rent_contract_details_old.special_contract_conditions !== this.props.rent_contract_details_new.special_contract_conditions) {
      special_contract_conditions = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.special_contract_conditions}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.special_contract_conditions}</div></span>

    } else {
      special_contract_conditions = this.props.rent_contract_details_new.special_contract_conditions

    }

    let special_contract_etc = ""
    if (this.props.rent_contract_details_old.special_contract_etc !== this.props.rent_contract_details_new.special_contract_etc) {
      special_contract_etc = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.special_contract_etc}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.special_contract_etc}</div></span>

    } else {
      special_contract_etc = this.props.rent_contract_details_new.special_contract_etc

    }

    let special_contract_payoff = ""
    if (this.props.rent_contract_details_old.special_contract_payoff !== this.props.rent_contract_details_new.special_contract_payoff) {
      special_contract_payoff = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.special_contract_payoff}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.special_contract_payoff}</div></span>

    } else {
      special_contract_payoff = this.props.rent_contract_details_new.special_contract_payoff

    }

    let electronics = ""

    let electronics_new = this.props.rent_contract_details_new.electronics_special_flg ? this.props.rent_contract_details_new.electronics : "非表示"
    let electronics_old = this.props.rent_contract_details_old.electronics_special_flg ? this.props.rent_contract_details_old.electronics : "非表示"
    if (electronics_new !== electronics_old) {
      electronics = <span><div style={{ color: "red", fontWeight: "bold" }}>{electronics_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{electronics_new}</div></span>

    } else {
      electronics = electronics_new

    }







    let special_contract_decision = ""

    if (this.props.rent_contract_details_new.special_contract_decision !== this.props.rent_contract_details_old.special_contract_decision) {
      special_contract_decision = <span><div style={{ color: "red", fontWeight: "bold" }}>{electronics_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_old.special_contract_decision}</div></span>

    } else {
      special_contract_decision = this.props.rent_contract_details_new.special_contract_decision

    }








    let freerent_special_hiwari = "";
    this.props.rent_contract_details_old.rent_contract_money_logs ? this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 5).map(function (value) {
      if (value.is_free_rent === 1) {
        if (freerent_special_hiwari !== "") {
          freerent_special_hiwari = freerent_special_hiwari + ","
        }
        freerent_special_hiwari = freerent_special_hiwari + value.name === "賃料" ? "家賃" : value.name;
      }

    }) : freerent_special_hiwari = ""




    let freerent_special_basemonth = "";
    this.props.rent_contract_details_old.rent_contract_money_logs ? this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 1).map(function (value) {
      if (value.is_free_rent === 1) {
        if (freerent_special_basemonth !== "") {
          freerent_special_basemonth = freerent_special_basemonth + ","
        }
        freerent_special_basemonth = freerent_special_basemonth + value.name === "賃料" ? "家賃" : value.name;
      }

    }) : freerent_special_basemonth = ""


    let freerent_special_old = "";
    if (moment(this.props.rent_contract_details_old.contract_date).format("d") > 1) {//日割りあり


      if (freerent_special_hiwari !== "") {
        freerent_special_old = moment(this.props.rent_contract_details_old.contract_date).format("Y年M月分") + freerent_special_hiwari;
      }
      if (freerent_special_basemonth !== "") {
        if (freerent_special_hiwari !== "") {
          freerent_special_old = freerent_special_old + ",";
        }
        freerent_special_old = freerent_special_old + moment(this.props.rent_contract_details_old.contract_date).startOf('month').add('month', 1).format("Y年M月分") + freerent_special_basemonth;

      }

    } else {

      if (freerent_special_basemonth !== "") {
        //                    if ($contraction_old["pay_nextmonth"] == 1) {
        //                        $freerent_special_old = date('Y年n月分', strtotime($contraction_old["contraction_date"])) . $freerent_special_basemonth . "," . date('Y年n月分', strtotime($contraction_old["contraction_date"] . "+1 month")) . $freerent_special_basemonth;
        //                    } else {
        freerent_special_old = moment(this.props.rent_contract_details_old.contract_date).format("Y年M月分") + freerent_special_basemonth;

        //                    }
      }


    }



    let freerent_special_old_set = ""

    if (this.props.rent_contract_details_old.division !== 1 && this.props.rent_contract_details_old.division !== 2) {//管理じゃない
      freerent_special_old_set = freerent_special_old !== "" ? "・" + freerent_special_old + "フリーレントとする。" : ""
    } else {
      freerent_special_old_set = freerent_special_old !== "" ? "・" + freerent_special_old + "は無料とします。なお、乙が賃貸借契約期間の始期より1年未満に本解約した場合、及び乙の責めに帰すべき事由により本契約が解除された場合にはこの特約は失効するものとし、乙は免除されていた賃料等の全額を甲に支払うものとします。" : "";
    }





    freerent_special_hiwari = "";
    this.props.rent_contract_details_new.rent_contract_money_logs ? this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 5).map(function (value) {
      if (value.is_free_rent === 1) {
        if (freerent_special_hiwari !== "") {
          freerent_special_hiwari = freerent_special_hiwari + ","
        }
        freerent_special_hiwari = freerent_special_hiwari + value.name === "賃料" ? "家賃" : value.name;
      }

    }) : freerent_special_hiwari = ""




    freerent_special_basemonth = "";
    this.props.rent_contract_details_new.rent_contract_money_logs ? this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 1).map(function (value) {
      if (value.is_free_rent === 1) {
        if (freerent_special_basemonth !== "") {
          freerent_special_basemonth = freerent_special_basemonth + ","
        }
        freerent_special_basemonth = freerent_special_basemonth + value.name === "賃料" ? "家賃" : value.name;
      }

    }) : freerent_special_basemonth = ""


    let freerent_special_new = "";
    if (moment(this.props.rent_contract_details_new.contract_date).format("d") > 1) {//日割りあり


      if (freerent_special_hiwari != "") {
        freerent_special_new = moment(this.props.rent_contract_details_new.contract_date).format("Y年M月分") + freerent_special_hiwari;
      }
      if (freerent_special_basemonth !== "") {
        if (freerent_special_hiwari !== "") {
          freerent_special_new = freerent_special_new + ",";
        }
        freerent_special_new = freerent_special_new + moment(this.props.rent_contract_details_new.contract_date).startOf('month').add('month', 1).format("Y年M月分") + freerent_special_basemonth;

      }

    } else {

      if (freerent_special_basemonth !== "") {
        //                    if ($contraction_old["pay_nextmonth"] == 1) {
        //                        $freerent_special_old = date('Y年n月分', strtotime($contraction_old["contraction_date"])) . $freerent_special_basemonth . "," . date('Y年n月分', strtotime($contraction_old["contraction_date"] . "+1 month")) . $freerent_special_basemonth;
        //                    } else {
        freerent_special_new = moment(this.props.rent_contract_details_new.contract_date).format("Y年M月分") + freerent_special_basemonth;

        //                    }
      }


    }


    let freerent_special_new_set = ""

    if (this.props.rent_contract_details_new.division !== 1 && this.props.rent_contract_details_new.division !== 2) {//管理じゃない
      freerent_special_new_set = freerent_special_new !== "" ? "・" + freerent_special_new + "フリーレントとする。" : ""
    } else {
      freerent_special_new_set = freerent_special_new !== "" ? "・" + freerent_special_new + "は無料とします。なお、乙が賃貸借契約期間の始期より1年未満に本解約した場合、及び乙の責めに帰すべき事由により本契約が解除された場合にはこの特約は失効するものとし、乙は免除されていた賃料等の全額を甲に支払うものとします。" : "";
    }
    let freerent_special = ""
    if (freerent_special_new_set !== freerent_special_old_set) {
      freerent_special = <span><div style={{ color: "red", fontWeight: "bold" }}>{freerent_special_old_set}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{freerent_special_new_set}</div></span>

    } else {
      freerent_special = freerent_special_new_set

    }



    return (

      <div style={{ whiteSpace: 'pre-line' }}>

        <table className='basicList'>
          <tr>
            <th colSpan={2}>特約  <Button size="small" style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.props.check_rent_contract_editor_disp({ editor: "rent_contract_special_editor_disp", disp: true })}>
              編集
            </Button></th>
          </tr>
          <tr>
            <th width={100}>物件特約</th>
            <td>{special_contract_building}        </td>

          </tr>
          <tr>
            <th>契約特約</th>
            <td>{special_contract_conditions}</td>

          </tr>
          <tr>
            <th>その他特約</th>
            <td>{special_contract_etc}</td>

          </tr>
          <tr>
            <th>退去時条文</th>
            <td >{special_contract_payoff}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: "#ffd700" }}>レンティック家電</th>
            <td colSpan="3">{electronics}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: "#ffd700" }}>フリーレント特約</th>
            <td>{freerent_special}</td>

          </tr>
          <tr>
            <th style={{ backgroundColor: "red" }}>契約時判断必要特約</th>
            <td>{special_contract_decision}</td>

          </tr>
        </table>
      </div>

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_special_editor_disp: state.contracts.rent_contract_special_editor_disp,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
