import { PieChart, Pie, Cell,} from 'recharts';
import { ThemeContext } from './Index';

//グラフの各領域の色を定義
const COLORS = [
    '#ff0000',
    '#ff007f',
    '#7f00ff',
    '#007fff',
    '#00d0ff',
    '#00ff48',
    '#ffdd00',
    '#ff7f00',
  ];

  const label = ({ name, value, cx, x, y }) => {
    const textAnchor = x > cx ? "start" : "end";

    return (
      <>
    {/* 引数で付属情報を受け取れます */}
        <text x={x} y={y} textAnchor={textAnchor} fill="#808080">
          {name}
        </text>
        <text
          x={x}
          y={y}
          dominantBaseline="hanging"
          textAnchor={textAnchor}
          fill="#808080"
        >
          {value}人
        </text>
      </>
    )
  }
function App() {

    return (
        <ThemeContext.Consumer>
            {(value) => {
                const data = value.chart_pie;
                const data_column = value.chart_column;
                const data_unit = value.chart_unit;

                console.log(data)
                console.log("chart")
                return data.length ? <PieChart 
                    width={window.innerWidth / 3 - 80}
                    height={200} 
                    margin={{ top: 0, right: 60, bottom: 0, left: 10 }}>
                        <Pie 
                            data={data} 
                            nameKey="name" 
                            dataKey="count" 
                            cx="50%" 
                            cy="50%" 
                            outerRadius={70} 
                            startAngle={90} 
                            endAngle={-270} 
                            label={label} 
                            labelLine={true} 
                        >
                            {data.map((value, index) => (
                                <Cell key={value.name} fill={COLORS[index % COLORS.length]} />
                            ))}

                        </Pie>
            </PieChart> : ""

            }}
        </ThemeContext.Consumer>
    );

}

export default App;