import React, { Component } from 'react';

import { connect } from 'react-redux';


import "react-input-range/lib/css/index.css";


class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.numPages = this.numPages.bind(this);
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }

  render() {

    return (

      <div style={{ whiteSpace: 'pre-line' }}>

        <table className='basicList'>


          <tr>
            <th width={100}>募集キャンペーン</th>
            <td>{this.props.rent_contract_details_new.web_campaign_term}{this.props.rent_contract_details_new.web_campaign_contents}</td>

          </tr>
          <tr>
            <th>家賃減額幅</th>
            <td>{this.props.rent_contract_details_new.room_reduction_range_term}{this.props.rent_contract_details_new.room_reduction_range_contents}</td>

          </tr>
          <tr>
            <th>社内丸得情報</th>
            <td >{this.props.room_inhouse_info_term}{this.props.room_inhouse_info_contents}</td>
          </tr>
          <tr>
            <th>台帳用注意事項</th>
            <td colSpan="3">{this.props.rent_contract_details_new.pr_comment_daicho}</td>
          </tr>
          <tr>
            <th>物件注意事項</th>
            <td>{this.props.rent_contract_details_new.room_private_notice}</td>

          </tr>
          <tr>
            <th>緊急サポート</th>
            <td></td>

          </tr>
        </table>
      </div>

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    // change_contract_detail_edit_disp(state) {
    //   dispatch(Contracts.change_contract_detail_edit_disp(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
