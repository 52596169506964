import React, { Component } from 'react';

import { connect } from 'react-redux';

import Alert from '@mui/material/Alert';

import "react-input-range/lib/css/index.css";

class ContractCreate extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
   
  }



  render() {
    let ver = []
    return (
        
      <div style={{width:'100%',height:'90vh',backgroundColor:'#FFFFFF'}}>
        <div style={{width:'60%', margin:'10% auto'}}>
            <Alert severity="error" style={{padding:'15%',fontSize:'18px'}}>
                
                {/* <AlertTitle>ERROR</AlertTitle> */}
                <div style={{width:'auto'}}>契約金を登録するには来店を一つ以上登録する必要があります。</div>
            </Alert>
        </div>
      
        
      </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    // customerdetail: state.customersReducer.customerdetail,
    // responsedetail: state.responsesReducer.responsedetail,
    // rent_contract_details: state.contracts.rent_contract_details,
    // rent_contract_details_old: state.contracts.rent_contract_details_old,
    // rent_contract_details_new: state.contracts.rent_contract_details_new,
    // rent_contract_logs_new: state.contracts.rent_contract_logs_new,
    // rent_contract_logs_old: state.contracts.rent_contract_logs_old,
    // contracts_cancel_confirm: state.contracts.contracts_cancel_confirm,
    // check_contract_sheet: state.contracts.check_contract_sheet,
    // check_contract_button:state.contracts.check_contract_button,
    // check_contract_sheet_text:state.contracts.check_contract_sheet_text,
    // check_contract_sheet_file:state.contracts.check_contract_sheet_file
  }
}
function mapDispatchToProps(dispatch) {
  return {

    // change_rent_contract_logs_new(state) {
    //   dispatch(Contracts.change_rent_contract_logs_new(state))
    // },
    // change_rent_contract_logs_old(state) {
    //   dispatch(Contracts.change_rent_contract_logs_old(state))
    // },
    // change_rent_contract_logs_ver(state) {
    //   dispatch(Contracts.change_rent_contract_logs_ver(state))
    // },
    // contracts_send_parking(state) {
    //   dispatch(Contracts.contracts_send_parking(state))
    // },
    // download_docs(state) {
    //   dispatch(Contracts.download_docs(state))
    // },
    // download_bill(state) {
    //   dispatch(Contracts.download_bill(state))
    // },
    // download_contract(state) {
    //   dispatch(Contracts.download_contract(state))
    // },
    // change_contracts_cancel_confirm(state) {
    //   dispatch(Contracts.change_contracts_cancel_confirm(state))
    // },
    // contracts_cancel(state) {
    //   dispatch(Contracts.contracts_cancel(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractCreate);
