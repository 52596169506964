const authentication = require('react-azure-adb2c2').default;


//REACT_APP_API_CRM_URL→REACT_APP_API_CRM_CONTACS_URL

export const list = (value: any) => {

    // const url = `http://localhost:5000/api/v1/mail/templates/`

    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/api/v1/mail/templates/`
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const insert_base = (value: any) => {
    console.log(value)

    // const url = `http://localhost:5000/api/v1/mail/templates/insert2`
    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/api/v1/mail/templates/insert2`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update_base = (value: any) => {
    console.log(value)

    // const url = `http://localhost:5000/api/v1/mail/templates/update2`
    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/api/v1/mail/templates/update2`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update_sort = (value: any) => {
    console.log(value)

    // const url = `http://localhost:5000/api/v1/mail/templates/updatesort`
    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/api/v1/mail/templates/updatesort`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const set = (value: any) => {

    // const url = `http://localhost:5000/api/v1/mail/templates/set/`+value.response_id+"/"+value.template_id

    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/api/v1/mail/templates/set/`+value.response_id+"/"+value.template_id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update = (value: any) => {
    console.log(value)

    // const url = `http://localhost:5000/api/v1/mail/templates/update/`
    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/api/v1/mail/templates/update/`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const insert = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplates/`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const deletetemplate = (value: any) => {
    console.log(value)

    // let url = `http://localhost:5000/api/v1/mail/templates/delete/` + value
    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/api/v1/mail/templates/delete/` + value
    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const insertcategory = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/`
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
export const updatecategory = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const deletecategory = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};