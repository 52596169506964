import { PieChart, Pie, Cell,} from 'recharts';
import { ThemeContext } from './Index';

//グラフの各領域の色を定義
const COLORS = [
    '#ff0000',
    '#ff007f',
    '#7f00ff',
    '#007fff',
    '#00d0ff',
    '#00ff48',
    '#ffdd00',
    '#ff7f00',
  ];

  const label = ({ name, value, cx, x, y }) => {
    const textAnchor = x > cx ? "start" : "end";

    return (
      <>
    {/* 引数で付属情報を受け取れます */}
        <text x={x} y={y} textAnchor={textAnchor} fill="#808080">
          {name}
        </text>
        <text
          x={x}
          y={y}
          dominantBaseline="hanging"
          textAnchor={textAnchor}
          fill="#808080"
        >
          {value}
        </text>
      </>
    )
  }
function App() {

    return (
        <ThemeContext.Consumer>
            {(value) => {

                var data = Object.entries(value.chart_pie);
                const data_column = value.chart_column;
                const data_unit = value.chart_unit;
                const data_pie_title = value.chart_pie_title;

                data = data.map(([key, value]) => ({key, value}))

                // console.log(data_pie_title)
                // console.log("chartpie")
                // alert(Object.keys(data).length)
                return Object.keys(data).length ? <div>
                  
                  {data.map(function (value, key) {
                  return <div><div>{data_pie_title[key]}</div><PieChart 
                    width={window.innerWidth / 3 - 80}
                    height={200} 
                    margin={{ top: 0, right: 60, bottom: 0, left: 10 }}>
                        <Pie 
                            data={value.value} 
                            nameKey="name" 
                            dataKey="count" 
                            cx="50%" 
                            cy="50%" 
                            outerRadius={70} 
                            startAngle={90} 
                            endAngle={-270} 
                            label={label} 
                            labelLine={true} 
                        >
                            {value.value.map((value2, index) => (
                                <Cell key={value2.name} fill={COLORS[index % COLORS.length]} />
                            ))}

                        </Pie>
                  </PieChart></div>})
                    }
                  
                </div> : ""

            }}
        </ThemeContext.Consumer>
    );

}

export default App;