import React, { Component } from 'react';
import { connect } from 'react-redux';

import "react-datepicker/dist/react-datepicker.css";
import { Field, reduxForm, change } from 'redux-form';
// import { Field, reduxForm ,InjectedFormProps} from 'redux-form';
import '../../Css/Table.css';
import MailAddressComponent from './MailAddressComponent';
import MailAddressDispComponent from './MailAddressDispComponent';
import { FormLabel, Radio, RadioGroup, FormControlLabel } from '@mui/material';



// interface Props {

//   handleSubmit:any,
//     onSubmit:any,
//     get_rent_contract_accounting:any,
//     send_mail_details:any,
//     change_send_mail_cc_disp:any,
//     change_send_mail_bcc_disp:any,
//     send_mail_cc_disp:number,
//     send_mail_bcc_disp:number,
//     send_edit_contents:number
//     handleChange:any,
//     change_send_edit_contents:any,


// }

// interface Forms {
//   message: string;

// }

// interface State {

//   }


// class MailEditHeaderComponent extends Component<InjectedFormProps<Props, {}, string>>  {
class MailEditHeaderComponent extends Component {
  constructor(props) {
    super(props);
    // this.props.initialize(this.props.send_mail_details);//フォームに初期値を反映させる


  }

  //  componentDidUpdate(prevProps){
  //    if(this.props.send_mail_details.subject!==prevProps){
  //      this.props.change("subject", this.props.send_mail_details.subject)
  //    }
  //  }

  render() {

    const { handleChange, send_edit_contents, change } = this.props;
    // change("subject", this.props.send_mail_details.subject);

    return (
      <div>

        {/* {send_edit_contents===0? */}
        <div>

          <Field
            name="id"
            component="input"
            type="hidden"
          />
          <div className="float-left" style={{ display: "inline-block", width: 50, verticalAlign: "top", textAlign: "center" }}>From</div>
          <div className="float-left" style={{ display: "inline-block" }}>

            <Field name="fromMail"
              onChange={this.props.handleChange}
              className="form-control float-left"
              component="select">
              {["web@global-center.co.jp", "info@global-center.co.jp", "takamatsu@global-center.co.jp", "rainbow@global-center.co.jp", "east@global-center.co.jp", "park@global-center.co.jp", "sun@global-center.co.jp", "cd@global-center.co.jp", "ooshika@global-center.co.jp", "system@global-center.co.jp"].map((value) => <option key={value} value={value}>{value}</option>)}
            </Field>

          </div>

          {this.props.send_mail_details ? <div className="float-left" style={{marginLeft:30}}>
                {/* <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>送信タイプ</FormLabel> */}
                <RadioGroup size="small" row name="sendType" onChange={this.props.handleChange} value={parseInt(this.props.send_mail_details.sendType)} >
                    <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={0} />} label="HTMLのみ" />
                    <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={1} />} label="テキストのみ" />
                    <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={2} />} label="両方" />
                </RadioGroup>
          </div>:""}

          <div className="clearfix"></div>
          <div style={{ borderBottom: "solid #dcdcdc 1px", paddingRight: 5 }}>
            <div style={{ width: 470 }}>
              <div style={{ display: "inline-block", width: 40, verticalAlign: "top", textAlign: "center" }}>To</div>
              <div style={{ display: "inline-block", width: 380 }}><MailAddressComponent flg="to" handleChange={this.props.handleChange} send_mail_details={this.props.send_mail_details} mails={this.props.send_mail_details.toMailall} type={"to"} /></div>

              <span className="float-right" onClick={this.props.change_send_mail_bcc_disp}>Bcc</span>
              <span className="float-right" style={{ marginRight: 5 }} onClick={this.props.change_send_mail_cc_disp}>Cc</span>
            </div>
            {this.props.send_mail_details.cc ? <div> Cc<MailAddressComponent handleChange={this.props.handleChange} mails={this.props.send_mail_details.cc} type={"cc"} /></div> : this.props.send_mail_cc_disp === 1 ? <div> <div style={{ display: "inline-block", width: 40, verticalAlign: "top", textAlign: "center" }}>Cc</div><div style={{ display: "inline-block", width: 380 }}><MailAddressComponent mails={this.props.send_mail_details.cc} handleChange={this.props.handleChange} type={"cc"} /></div></div> : ""}
            {this.props.send_mail_details.bcc ? <div> Bcc<MailAddressComponent handleChange={this.props.handleChange} mails={this.props.send_mail_details.bcc} type={"bcc"} /></div> : this.props.send_mail_bcc_disp === 1 ? <div> <div style={{ display: "inline-block", width: 40, verticalAlign: "top", textAlign: "center" }}>Bcc</div><div style={{ display: "inline-block", width: 380 }}><MailAddressComponent mails={this.props.send_mail_details.bcc} handleChange={this.props.handleChange} type={"bcc"} /></div></div> : ""}

            {this.props.send_mail_address_disp === 1 ? <span className="float-right" ></span> : ""}
          </div>





        </div>

        {/* // :<MailAddressDispComponent   send_mail_details={this.props.send_mail_details}/>} */}
        <form onChange={handleChange}>
          <Field
            name="subject"
            component="input"

            type="text"
            placeholder="件名"
            className="form-control"
            style={{ border: "none", }}

          />



        </form>

      </div>
    );
  }
}



// export default  reduxForm<Props>({
//     form: 'mailheader',

// })(MailEditHeaderComponent);


// export default reduxForm({
//   form: 'sendmailForm',
// })(MailEditHeaderComponent);


const mapStateToProps = state => {
  return {
    // customerNewDispSet: state.customersReducer.customerNewDispSet,
    // customerlist: state.customersReducer.customerlist,
    formvalue: state.form,
    initialValues: state.mailReducer.send_mail_details // pull initial values from account reducer
  }
}


MailEditHeaderComponent = reduxForm({
  form: 'sendmailForm', // a unique identifier for this form


  // validate//バリデーション

  enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(MailEditHeaderComponent)


function mapDispatchToProps(dispatch) {
  return {

    // change(state) {
    //    dispatch(change(state))
    // },
    // update_customerdetail() {
    //     dispatch(Customers.update_customerdetail())
    // },
    // customerList(state) {
    //     dispatch(Customers.customerList(state))
    // },

  };
}
MailEditHeaderComponent = connect(

  mapStateToProps, mapDispatchToProps
)(MailEditHeaderComponent);



export default MailEditHeaderComponent

