
const authentication = require('../../../react-azure-adb2c2').default;
export const list = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/m/agents`;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(url)

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

