import React, { Component } from 'react';
import { connect } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import SortList from './SortList';
import * as MapMypage from './../Saga/MapMypage';
import { Alert } from 'reactstrap';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import ListAlt from '@mui/icons-material/ListAlt';
import '../Css/Form.css';

import Tooltip from '@mui/material/Tooltip';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',

//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: '70%',
//     flexShrink: 0,
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary,
//   },
//   fab: {
//     margin: theme.spacing(2),
//   },
//   absolute: {
//     backgroundColor: theme.palette.common.red,
//     // position: 'absolute',
//     bottom: theme.spacing(2),
//     right: theme.spacing(3),
//   },
// }));



// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));
function ControlledExpansionPanels(props) {
  // const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [savealert, setSavealert] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setSavealert(false);
    props.change_rent_introduction_headers(props.rent_introduction_headers_list.filter(a => a.id === panel)[0])
    if (isExpanded === false) {
      props.change_rent_introduction_point_headers([])
      props.change_rent_introduction_headers([])
    }
    props.change_map_disp(!props.map_disp)
  };



  const handleNumberSave = () => {


    var setnumber = props.rent_introduction_headers.rent_introduction_details.filter(function (x, i, self) {
      return (self.findIndex(function (v2) {
        return (parseInt(x.tatemono_id) === parseInt(v2.tatemono_id))
      }) === i);
    });

    var setnumber2 = setnumber.filter(function (x, i, self) {
      return (self.findIndex(function (v2) {
        return (parseInt(x.numbering) === parseInt(v2.numbering))
      }) === i);
    });

    if (setnumber.length !== setnumber2.length) {

      setSavealert(true);
      return
    }

    setSavealert(false);
    props.update_rent_introduction_headers(1)
  };



  const mypageOpen = (id) => {


    window.open("https://rooms.global-center.co.jp/rent/" + props.responsedetail.response_code + "/" + id + "/")
  }
  const url = (e) => {
    console.log(e)
    e.target.select()
    navigator.clipboard.writeText(e.target.value);

    setTooltipOpen(e.target.id);
    tooltipdelete()
  }

  const tooltipdelete = () => {

    setTimeout(() => {
      setTooltipOpen(0);
    }, 1000)

  }

  const link = (str) => {
    const regexp_url = /https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+/g;
    const regexp_makeLink = (url) => {
      return `<a href=${url} target="_blank" rel="noopener noreferrer">${url}</a>`;
    }
    const replacedString = str.replace(/\r?\n/g, '<br />').replace(regexp_url, regexp_makeLink);
  
  
  
    return (
      <p style={{margin:0}} dangerouslySetInnerHTML={{__html: replacedString}}></p>
    )
  };
  //   <Accordion expanded={expanded === 'panel1'} onChange={handleChange(0)}>
  //   <AccordionSummary
  //     expandIcon={<ExpandMoreIcon />}
  //     aria-controls="panel1bh-content"
  //     id="panel1bh-header"
  //   >
  //     <Typography className={classes.heading}>基本ページ</Typography>
  //     {/* <Typography className={classes.secondaryHeading}>I am an expansion panel</Typography> */}
  //   </AccordionSummary>
  //   <AccordionDetails>
  //     <Typography>
  //       <SortList/>

  //     </Typography>
  //   </AccordionDetails>
  // </Accordion>
  return (
    <div>

      {props.rent_introduction_headers_list.map((value, index) => {
        return (<Accordion key={index} expanded={props.rent_introduction_headers.id === value.id} onChange={handleChange(value.id)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography >{value.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Button size="small"
                color="primary"
                variant="contained"
                type="button"
                onClick={() => props.handleSelect(value.id)}
              >
                編集
              </Button>

              <Button size="small"
                color="primary"
                variant="outlined"
                type="button"
                style={{ float: "right", marginRight: "5px" }}
                onClick={() => mypageOpen(value.id)}
              >
                マイページ
              </Button>


              <TextField

                id={value.id}
                // label="番号"
                type="text"
                onClick={url}
                value={"https://rooms.global-center.co.jp/rent/" + props.responsedetail.response_code + "/" + value.id + "/"}
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
              <Tooltip title="コピーしました！" open={parseInt(tooltipOpen) === value.id ? true : false}>
                <div></div>
              </Tooltip>

              {props.rent_introduction_headers.comment ? <div className='mypage_comment' style={{marginTop:2, marginBottom:2, backgroundColor:"#fff4e5", fontSize:"12px", padding:3, overflowY:"auto", maxHeight:100}}>{link(props.rent_introduction_headers.comment)}</div>:""}

              <Chip
                size="small"
                icon={<ListAlt />}
                label="リスト表示"
                color={props.rent_introduction_headers.disp_list === 1 ? "" : "primary"}
                style={{ margin: "1px" }}
              />
              <Chip
                size="small"
                icon={<DomainDisabledIcon />}
                label="成約済グレー表示"
                color={props.rent_introduction_headers.disp_applied === 1 ? "" : "primary"}
                style={{ margin: "1px" }}
              />


              <SortList rent_introduction_headers_id={value.id} />


              <Button size="small"
                color="secondary"
                variant="contained"
                type="button"

                onClick={() => props.delete_rent_introduction_headers(value.id)}
              >
                削除
              </Button>

              {savealert ? <Alert color="danger">
                登録できませんでした。<br />
                重複している番号があります！
              </Alert> : ""}
              <Button size="small"
                color="primary"
                variant="contained"
                type="button"
                style={{ float: "right", marginRight: "5px" }}
                onClick={handleNumberSave}
              >
                保存
              </Button>


            </Typography>
          </AccordionDetails>
        </Accordion>
        )
      })}

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    rent_introduction_headers_list: state.mapmypage.rent_introduction_headers_list,
    rent_introduction_headers: state.mapmypage.rent_introduction_headers,
    responsedetail: state.responsesReducer.responsedetail,
    map_disp: state.mapmypage.map_disp,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_rent_introduction_headers(state) {
      dispatch(MapMypage.change_rent_introduction_headers(state))
    },
    update_rent_introduction_headers(state) {
      dispatch(MapMypage.update_rent_introduction_headers(state))
    },
    delete_rent_introduction_headers(state) {
      dispatch(MapMypage.delete_rent_introduction_headers(state))

    },
    change_rent_introduction_point_headers(state) {
      dispatch(MapMypage.change_rent_introduction_point_headers(state))

    },
    change_map_disp(state) {
      dispatch(MapMypage.change_map_disp(state))

    },
    // insert_introductions(state) {
    //     dispatch(IntroductionList.insert_introductions(state))
    // },
    // delete_introduction(state:any) {
    //     dispatch(IntroductionList.delete_introduction(state))
    // },
    // update_introductions(state:any) {
    //     dispatch(IntroductionList.update_introductions(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlledExpansionPanels);


