import React, { useState } from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { ThemeContext } from './Index';
const moment = require('moment')
// Table data as a list of array.
const rows = [
    "first row",
    "second row",
    "third row"
    // .... and more
];

// Custom cell implementation with special prop
const MyCustomCell = ({ mySpecialProp }) =>
    <Cell>
        {mySpecialProp === "column2" ? "I'm column 2" : "I'm not column 2"}
    </Cell>;


function App() {

    const [columnWidths, setColumnWidths] = useState({column1:70, column2:90, column3:90, column4:90, column5:90, column6:90});

    // const aaacolumnWidths = (state) => {
    //     setColumnWidths(state)
    // }


    return (
        <ThemeContext.Consumer>
            {(value) => {
                const data = value.chart_table;
                let data_column = value.chart_column;
                const data_unit = value.chart_unit;
                // console.log(value)
                // console.log("daaaaaaaata")

                let state = []

setColumnWidths(data_column)
// console.log(data_column)
// console.log("bbbbbbbbbbb")
                // for(let i = 1; i <= data_column.length; i++){
                //     state.push({
                //         ["column" + i]: data_column[i-1].size})
                // }
                // aaacolumnWidths(state)
                // setColumnWidths(state)

                // console.log(state)
                return data.length ? (<Table
                    rowHeight={34}
                    rowsCount={data.length}
                    width={window.innerWidth / 3 - 80}
                    height={50+data.length*34}
                    headerHeight={30}
                    // onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    onColumnResizeEndCallback={(newColumnWidth, columnKey) => {
                        let columnsset = columnWidths.map(function (value, key) {
                            if (key === columnKey) {
                              value.size = newColumnWidth
                            }
                            return value
                          })
                          setColumnWidths(columnsset)

                        // setColumnWidths({...columnWidths, [columnKey]: newColumnWidth} );


                    }}
                    isColumnResizing={false}
                >
                    {
                        data_column.map(function (value, key) {
                            return <Column width={data_column[key].size} isResizable={true} columnKey={key} header={<Cell>{data_column[key].name}</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{data[rowIndex]["column"+(key+1)]}</Cell>)} />
                            // return <Column width={columnWidths[Object.keys(columnWidths)[key]]} isResizable={true} columnKey={"column"+(key+1)} header={<Cell>{data_column[key].name}</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{data[rowIndex]["column"+(key+1)]}</Cell>)} />
                        })

                    }

                   
                </Table>) : ""

            }}
        </ThemeContext.Consumer>
    );

}

export default App;

// // Render your table
// ReactDOM.render(
//     <Table
//         rowHeight={50}
//         rowsCount={rows.length}
//         width={5000}
//         height={5000}
//         headerHeight={50}>
//         <Column
//             header={<Cell>Col 1</Cell>}
//             cell={<Cell>Column 1 static content</Cell>}
//             width={2000}
//         />
//         <Column
//             header={<Cell>Col 2</Cell>}
//             cell={<MyCustomCell mySpecialProp="column2" />}
//             width={1000}
//         />
//         <Column
//             header={<Cell>Col 3</Cell>}
//             cell={({ rowIndex, ...props }) => (
//                 <Cell {...props}>
//                     Data for column 3: {rows[rowIndex]}
//                 </Cell>
//             )}
//             width={2000}
//         />
//     </Table>,
//     document.getElementById('example')
// );