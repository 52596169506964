import React, { Component, ChangeEvent, useCallback, useState, useRef } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import * as Responses from './Saga/Responses';
import * as Customers from '../Customers/Saga/Customers';

import Alert from '@mui/material/Alert';

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

// import * as Master from '../../Store/Master';
import '../../Css/Table.css';
import moment from 'moment'
import { Grid } from '@mui/material';

moment.locale('ja')







class ResponseFreeEditComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

        this.handleChange = this.handleChange.bind(this);

        this.timer = null;
    }




    componentWillMount() {

    }

    componentDidMount() {
        // console.log(this.props)

        // console.log(this.props.responsedetail)

        // this.props.responsedetail.section_id = !this.props.responsedetail.section_id ? this.props.section_id : this.props.responsedetail.section_id;
        // this.props.responsedetail.user_id = !this.props.responsedetail.user_id ? this.props.user_id : this.props.responsedetail.user_id;
        // console.log(this.props.responsedetail.user_id)




        // this.props.initialize(this.props.responsedetail);//フォームに初期値を反映させる
        // console.log(this.props.responsedetail)


    }




    componentDidUpdate() {


    }



    handleChange = (event) => {

        let rent_responses_editor = this.props.rent_responses_editor

        rent_responses_editor[event.target.name] = event.target.value

        this.props.change_rent_responses_editor(rent_responses_editor);



        if (this.timer) {
            clearTimeout(this.timer);
          }
      
        this.timer = setTimeout(() => {
         this.saveDataToServer();
        }, 1000);



        this.setState({ refresh: !this.state.refresh, });
    
    }

    saveDataToServer = (data) => {
        console.log('データを保存します');
        this.props.change_rent_responses_free_update_alert_disp({type: 1, time: ""})//0:非表示 1:保存中 2:保存済み
        this.props.update_rent_responses_free()

        this.timer = ""
        // ここで実際のデータ保存処理を実装
    };

    componentWillUnmount() {
        if (this.timer) {
          clearTimeout(this.timer);
        }
    }

    render() {

        return (
            <div>
                    <Grid container>
                        <Grid xs={11} style={{padding: "5px"}}>
                        {this.props.rent_responses_free_update_alert_disp.type == 2 ?
                                <Alert severity="info" style={{ margin: 5 }}>保存しました。{this.props.rent_responses_free_update_alert_disp.time}</Alert> 
                            :
                            this.props.rent_responses_free_update_alert_disp.type == 1 ?<Alert severity="success" style={{ margin: 5, width:"100%" }}>保存中...</Alert>
                            : ""}
                        </Grid>
                        <Grid xs={1} style={{padding: "5px", textAlign:"right"}}>
                            <Tooltip title="閉じる">
                                <IconButton
                                    disabled={this.props.rent_responses_free_update_alert_disp.type == 1 || this.timer}
                                    aria-label="delete"
                                    onClick={() => this.props.change_rent_responses_free_edit_disp(false)}
                                    style={{ display: (this.props.notclose === 1) ? 'none' : '' }}
                                    size="large">
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid style={{padding: "0px 5px", marginTop:9}}>
                            <TextField fullWidth
                                // multiline
                                label="現住居"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_residence"
                                value={this.props.rent_responses_editor.free_residence}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="引越し理由"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_moving_reason"
                                value={this.props.rent_responses_editor.free_moving_reason}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="人物属性その他"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_person_attributes"
                                value={this.props.rent_responses_editor.free_person_attributes}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="通勤通学先"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_office_school"
                                value={this.props.rent_responses_editor.free_office_school}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="入居人数"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_number_of_people"
                                value={this.props.rent_responses_editor.free_number_of_people}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="賃料目安"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_rent"
                                value={this.props.rent_responses_editor.free_rent}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="賃料規定あれば"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_rent_remarks"
                                value={this.props.rent_responses_editor.free_rent_remarks}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="間取り目安"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_madori"
                                value={this.props.rent_responses_editor.free_madori}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="車種・台数"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_car"
                                value={this.props.rent_responses_editor.free_car}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="エリア目安"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_area"
                                value={this.props.rent_responses_editor.free_area}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="入居時期"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_move_in_period"
                                value={this.props.rent_responses_editor.free_move_in_period}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />

                            <TextField fullWidth
                                multiline
                                label="補足事項"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_remarks"
                                value={this.props.rent_responses_editor.free_remarks}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3} maxRows={100}
                            />
                        </Grid>
                    </Grid>
                    {/* <Button variant="contained" color="primary" onClick={this.props.update_rent_responses_free}>登録</Button> */}




            </div>
        );
    }
}








const mapStateToProps = state => {
    return {
        //responseedit: state.customersReducer.responseedit,
        rentMMedeas: state.masterReducer.rentMMedeas,
        users_select: state.masterReducer.users_select,
        shops: state.masterReducer.shops,
        // rentAgents: state.masterReducer.rentAgents,
        rent_m_agents: state.responsesReducer.rent_m_agents,
        rentMReasons: state.masterReducer.rentMReasons,
        newResponseCustomer: state.responsesReducer.newResponseCustomer,
        setsubi_master: state.masterReducer.setsubi_master,
        section_select: state.masterReducer.section_select,
        user_details: state.masterReducer.user_details,

        users: state.masterReducer.users,

        chomeis: state.masterReducer.chomeis,
        shougakkous: state.masterReducer.shougakkous,
        chuugakkous: state.masterReducer.chuugakkous,
        // responsedetail: state.responsesReducer.responsedetail,
        rent_responses_editor: state.responsesReducer.rent_responses_editor,

        rent_m_medea: state.responsesReducer.rent_m_medea,
        //rentMAgent:state.customersReducer.rentMAgent,
        customerNewDisp: state.customersReducer.customerNewDisp,
        response_detail_dialog_disp: state.responsesReducer.response_detail_dialog_disp,
        response_attention_disp: state.responsesReducer.response_attention_disp,

        rent_customer_editor_forms: state.customersReducer.rent_customer_editor_forms,
        rent_responses_free_update_alert_disp: state.responsesReducer.rent_responses_free_update_alert_disp,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_responsedetail(state) {
            dispatch(Responses.change_responsedetail(state))
        },
        change_rent_responses_editor(state) {
            dispatch(Responses.change_rent_responses_editor(state))
        },

        responseUpdate(state) {
            dispatch(Responses.responseUpdate(state))
        },
        change_response_attention_disp(state) {
            dispatch(Responses.change_response_attention_disp(state))
        },
        change_rent_customer_editor_forms(state) {
            dispatch(Customers.change_rent_customer_editor_forms(state))
        },
        change_rent_responses_free_edit_disp(state) {
            dispatch(Responses.change_rent_responses_free_edit_disp(state))
        },
        update_rent_responses_free(state) {
            dispatch(Responses.update_rent_responses_free(state))
        },
        change_rent_responses_free_update_alert_disp(state) {
            dispatch(Responses.change_rent_responses_free_update_alert_disp(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseFreeEditComponent);



