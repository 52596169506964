import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

var moment = require('moment-timezone');


const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
    margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const List = styled.ul`
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
        
    padding-left:0;
    margin-bottom:0px;
`;

const ListItem = styled.li`
        display: inline-block;
        width: 50%;
        height:300px;
        margin-left: 5px;
        white-space:normal;
        vertical-align: top;
        margin-bottom: 0px;
        
        background-color:white;
        border-radius: 12px;
        width:200px;
`;

const Title = styled.div`
        padding:10px;
    text-align:left;
`;

const Detail = styled.div`
        padding:10px;padding-top:0px;font-size:10px;height:55px;
    text-align:left;
`;

const Menu1 = styled.div`
        padding-top:5px;padding-bottom:5px;border-top:1px solid #dcdcdc;font-weight:bold;text-align:center;
`;

const Menu2 = styled.div`
        padding-top:5px;padding-bottom:5px;font-weight:bold;text-align:center;
`;


const Time = styled.div`
    width:100%;
    position: relative; 
    margin: 5 5 0 5px;
    bottom: 0;
    font-size:10px;
    color:#FFF;
    text-align:right;
`;



//export default class LineTextComponent extends React.Component {
class LineRightType5Component extends Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],

            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            lists: this.props.msg.message_text.split(','),
        };
    }

    render() {
        return (
            <Balloon className="col-lg-12">
                <Chatting>

                    <List>

                        {this.state.lists.map((data, index) => {
                            return (
                                <ListItem key={index}>
                                    <div><img className="l_img" border="0" src={process.env.REACT_APP_HOMESTATION4_URL+"/manage/common/img/building/" + data.split('_')[0] + "/1/1/1"} width="200" alt="" /></div>
                                    <Title>{data.split('_')[2]}-{data.split('_')[3]}</Title>
                                    <Detail>{data.split('_')[5]}/{data.split('_')[4]}円/{data.split('_')[6]}</Detail>
                                    <Menu1 className="col-xs-12">詳細ページ</Menu1>
                                    {/* <Menu2 className="col-xs-12">見学予約</Menu2> */}
                                </ListItem>
                            )
                        })}

                    </List>
                </Chatting>
                <Time>
                    <div>{this.props.users && this.props.msg.user_id > 0 ? this.props.users.filter(a=>a.id == parseInt(this.props.msg.user_id))[0].nickname : ""}</div>
                    <div>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</div>
                </Time>
            </Balloon>

        )
    }
}
//コンテナ
const mapStateToProps = state => {
    return {
        users: state.masterReducer.users,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        // get_firestore_listen(state) {
        //     dispatch(Line.get_firestore_listen(state))
        // },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(LineRightType5Component);