// const authentication = require('react-azure-adb2c').default;
const authentication = require('../../../react-azure-adb2c2').default;



export const list = (value: any) => {

    // let url = `http://localhost:5000/v1/rent/corporate/docs/list/` + value;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/docs/list/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update = (value: any) => {
    // alert( JSON.stringify(value))

    // let url = `http://localhost:5000/v1/rent/corporate/docs/update/`+ value.id;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/docs/update/`+ value.id;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const upload = (values: any) => {

// console.log(values)

    // let url = `http://localhost:5000/v1/rent/corporate/docs/uploadfile`;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/docs/uploadfile`;

    const token = authentication.getAccessToken();

    // alert(url)
    // alert(url)
    var formData = new FormData();
    // formData.append("files", values.files[0]);
    formData.append("files", values.file);
    formData.append("rent_m_corporate_id", values.rent_m_corporate_id);
    formData.append("remarks", values.remarks);
    console.log(formData)

    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const download = (values: any) => {
// console.log(values)

    // const url = `http://localhost:5000/v1/rent/corporate/docs/download/` + values
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/docs/download/` + values

    const token = authentication.getAccessToken();

    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(response => response.blob())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
