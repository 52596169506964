import React, { Component } from 'react';

import { connect } from 'react-redux';


import './Css/Images.css';
import Carousel, { Modal, ModalGateway } from 'react-images';


class ImagesComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerinfo: this.props.forecasts,
            startDate: new Date(),
            images: [],
            currentIndex: 0,
            modalIsOpen: false,

        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.imagebox = this.imagebox.bind(this);
        this.imageboxgaikan = this.imageboxgaikan.bind(this);

        this.toggleModal = this.toggleModal.bind(this);

        // let tenantMImageTypes ={}
        // for(let i=0;this.props.tenant_images.length>i;i++){
        //     tenantMImageTypes[["imageTypes"+this.props.tenant_images[i].imgNo]]=this.props.tenant_images[i].tenantMImageTypesId
        // }
        //  this.props.tenant_images.map((item, i) => ({
        //     tenantMImageTypes[["imageTypes"+item.imgNo]]: item.tenantMImageTypesId,
        //   }))

        //   console.log(tenantMImageTypes)

        // this.props.initialize(tenantMImageTypes);

    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }


    onDrop(value) {
        console.log(value)
    }
    imagebox(index) {

        let images = [];


        this.props.rooms_room_image_list.map(function (x) {

            images.push({ src: `https://img.global-center.co.jp/v2/rooms/` + x.tatemono_id + `/` + x.room_id + `/` + x.img_shubetsu_no + `/` + x.img_no + '/520/780/0' })

        }, this)


        this.setState({ "images": images })
        if (index >= 0) {
            this.setState({ currentIndex: index })
            this.setState({ modalIsOpen: true });
        }
    }


    imageboxgaikan(index) {

        let images = [];


        this.props.rooms_tatemono_image_list.filter(a => a.img_shubetsu_no !== 9 && a.img_shubetsu_no !== 10).map(function (x) {

            images.push({ src: `https://img.global-center.co.jp/v2/tatemono/` + x.tatemono_id + `/` + x.img_shubetsu_no + `/` + x.img_no + '/520/780/0' })

        }, this)


        this.setState({ "images": images })
        if (index >= 0) {
            this.setState({ currentIndex: index })
            this.setState({ modalIsOpen: true });
        }
    }

    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    };
    render() {
        let indexset = -1
        console.log(this.state.images)
        // let index = -1;
        //moment.locale('ja')
        const { handleSubmit } = this.props;
        return (
            <div >
                <div style={{ textAlign: "left" }}>
                    {
                        this.props.rooms_tatemono_image_list ? this.props.rooms_tatemono_image_list.filter(a => a.img_shubetsu_no !== 9 && a.img_shubetsu_no !== 10).map(function (x, index) {

                            return <img key={index} alt="" style={{ margin: "5px", maxWidth: 80, maxHeight: 80, }} src={`https://img.global-center.co.jp/v2/tatemono/` + x.tatemono_id + `/` + x.img_shubetsu_no + `/` + x.img_no + '/520/780/0'} onClick={() => this.imageboxgaikan(index)} />

                        }, this) : ""
                    }

                </div>
                <div style={{ textAlign: "left" }}>
                    {
                        this.props.rooms_room_image_list ? this.props.rooms_room_image_list.map(function (x, index) {
                            // indexset=indexset+1
                            return <img key={index} alt="" style={{ margin: "5px", maxWidth: 80, maxHeight: 80, }} src={`https://img.global-center.co.jp/v2/rooms/` + x.tatemono_id + `/` + x.room_id + `/` + x.img_shubetsu_no + `/` + x.img_no + '/520/780/0'} onClick={() => this.imagebox(index)} />

                        }, this) : ""
                    }
                </div>
                <ModalGateway >
                    {this.state.modalIsOpen ? (
                        <Modal onClose={this.toggleModal} >
                            <Carousel views={this.state.images}
                                currentIndex={this.state.currentIndex}

                            />
                        </Modal>
                    ) : null}
                </ModalGateway>

            </div>
        );
    }
}




// this.state.photoIndex>0?<Lightbox 
//             mainSrc={this.state.images[this.state.photoIndex]}
//             nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
//             prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
//             onCloseRequest={() => this.setState({ isOpen: false })}
//             onMovePrevRequest={() =>
//               this.setState({
//                 photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
//               })
//             }
//             onMoveNextRequest={() =>
//               this.setState({
//                 photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
//               })
//             }
//           />:""}
//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        room_details: state.roomsReducer.room_details,
        rooms_tatemono_image_list: state.roomsReducer.rooms_tatemono_image_list,
        rooms_room_image_list: state.roomsReducer.rooms_room_image_list,
        // tenant_images: state.tenantsReducer.tenant_images,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

// ImagesComponent = reduxForm({
//     form: 'roomform', // a unique identifier for this form
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ImagesComponent)
// ImagesComponent = reduxForm({
//     form: 'tenantImageForm', // a unique identifier for this form
//     // validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ImagesComponent)
export default connect(mapStateToProps, mapDispatchToProps)(ImagesComponent);