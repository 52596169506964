
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';



import { call, put, takeEvery } from 'redux-saga/effects';
import * as TenantMAddressesApi from '../Api/TenantMAddressesApi';



export interface SearchState {
    get_chomei:any;
    chomei_list:any;
 
}

const initialState: SearchState = {

    get_chomei: [],
    chomei_list: [],

    // rentAgents: [],
    // rentMReasons: [],
 


}



///action 
const actionCreator = actionCreatorFactory();

export const get_chomei = actionCreator('GET_CHOMEI');
export const get_chomei_success = actionCreator("GET_CHOMEI_SUCCESS");
export const get_chomei_failure = actionCreator("GET_CHOMEI_FAILURE");

// export const get_t_shutokus = actionCreator('GET_T_SHUTOKUS');
// export const get_t_shutokus_success = actionCreator("GET_T_SHUTOKUS_SUCCESS");
// export const get_t_shutokus_failure = actionCreator("GET_T_SHUTOKUS_FAILURE");

// export const delete_rent_customer_contacts = actionCreator<any>('DELETE_RENT_CUSTOMER_CONTACTS');

// export const update_rent_customer_contacts = actionCreator<any>('UPDATE_RENT_CUSTOMER_CONTACTS');
// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const searchReducer = reducerWithInitialState(initialState)


.case(get_chomei_success, (state, payload) => ({ ...state, chomei_list: payload }))
    // .case(get_t_shutokus_success, (state, payload) => ({ ...state, t_shutokus: payload }));
  
    //.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
    // .case(add_rent_customer_contacts, (state, payload) => ({ ...state, users: payload }));
    // .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
    // .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
    // .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
    // .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
    // .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const searchSaga = [
    takeEvery('GET_CHOMEI', handle_get_chomei),
    // takeEvery('GET_T_SHUTOKUS', handle_get_t_shutokus),
    // takeEvery('UPDATE_RENT_CUSTOMER_CONTACTS', handle_update_rent_customer_contacts_Request),
    // takeEvery('DELETE_RENT_CUSTOMER_CONTACTS', handle_delete_rent_customer_contacts_Request),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_chomei(action:any) {
  
 const { payload, error }= yield call(TenantMAddressesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
    console.log(payload)
    yield put(get_chomei_success(payload));

   
    //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    }
}
