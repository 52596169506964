
const authentication = require('../../../react-azure-adb2c2').default;
export const calendar = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/customer/contacts/calendar`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/customer/contacts/calendar`
    // alert(url)
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        // body: JSON.stringify({date:'2023/08/29', section_id:10, user_id: 1063}),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

