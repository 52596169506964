﻿import React from 'react'
// import { Document, Page } from 'react-pdf';
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from 'react-redux';

import * as Help from './Saga/Help';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import '@styles/react-pdf.styl'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { page: 1, base64: null, name: null }
    this.props.get_manuals(1)
    // this.handleDocumentLoad = this.handleDocumentLoad.bind(this);

  }

  componentDidUpdate() {
    if (this.props.manual_change_flg) {
      const file = this.props.manuals
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.setState({
          base64: reader.result,
          name: file.name,
        })
      }

      this.props.change_manual_change_flg(false)
    }
  }




  handleChange(e) {
    const file = this.props.manuals
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        base64: reader.result,
        name: file.name,
      })
    }
  }

  handleDocumentLoad({ numPages }) {
    this.setState({ numPages })

    // this.props.numPages(numPages);
  }



  render() {

    return <div className="component">
      {/* PDFを添付してください: */}
      {/* <input type="file" onChange={this.handleChange.bind(this)} /> */}
      <Document
        file={this.state.base64} style={{ border: 'dotted 1px #aaa' }}
        onLoadSuccess={this.handleDocumentLoad.bind(this)}
      >
        <Page
          // rotate={90}
          scale={10 / 10}
          rotate={0}
          pageNumber={1}
          style={{ border: 'solid 2px #000', height: 300 }}
        />
      </Document>
      {/* <div>{this.state.name}</div> */}


    </div>
  }
}


//コンテナ
const mapStateToProps = state => {
  return {
    manual_change_flg: state.help.manual_change_flg,
    // customerdetail: state.customersReducer.customerdetail,
    // //firebasetoken: state.firebaseReducer.firebasetoken,
    // contract_detail_edit_disp: state.estimates.contract_detail_edit_disp,
    manuals: state.help.manuals,
    // contracts_doc_extension: state.contracts.contracts_doc_extension,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_manuals(state) {
      dispatch(Help.get_manuals(state))
    },
    change_manual_change_flg(state) {
      dispatch(Help.change_manual_change_flg(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
