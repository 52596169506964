// const authentication = require('react-azure-adb2c').default;
const authentication = require('../../../react-azure-adb2c2').default;



export const list = (value: any) => {

    // let url = `http://localhost:5000/v1/rent/corporate/contacts/list/` + value;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/contacts/list/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const all_list = (value: any) => {

    // let url = `http://localhost:5000/v1/rent/corporate/contacts/`;
    let url =process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/contacts/`;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update = (value: any) => {
    // alert( JSON.stringify(value))

    // let url = `http://localhost:5000/v1/rent/corporate/contacts/update/`+ value.id;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/contacts/update/`+ value.id;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const insert = (value: any) => {

    console.log(value)
    // let url = `http://localhost:5000/v1/rent/corporate/contacts/insert`;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/contacts/insert`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const count_list = (value: any) => {

    // let url = `http://localhost:5000/api/v1/rent/responses/countlist`;
    let url =process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/countlist`;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};