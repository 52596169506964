import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';



import { call, put, takeEvery } from 'redux-saga/effects';
import * as RentSalesApi from '../Api/RentSalesApi';

import * as RentMSaleAccountsApi from '../Api/RentMSaleAccountsApi';



export interface RentContractsState {
    rent_contracts_list: any;
    rent_sales_list: any;
    rent_m_sale_accounts: any;
    // sales_loading:any;
}
const initialState: RentContractsState = {

    rent_contracts_list: [],
    rent_sales_list: [],
    rent_m_sale_accounts: [],
    // sales_loading:0
    // users: [],
    // rentAgents: [],
    // rentMReasons: [],



}



///action 
const actionCreator = actionCreatorFactory();


export const get_rent_sales_list = actionCreator<any>('GET_RENT_SALES_LIST');
export const get_rent_sales_list_success = actionCreator<any>("GET_RENT_SALES_LIST_SUCCESS");
export const get_rent_sales_list_failure = actionCreator("GET_RENT_SALES_LIST_FAILURE");

// export const change_sales_loading = actionCreator<any>("change_sales_loading");


export const update_rent_sales_list = actionCreator<any>('UPDATE_RENT_SALES_LIST');

export const get_rent_m_sale_accounts = actionCreator<any>('GET_RENT_M_SALE_ACCOUNTS');
export const get_rent_m_sale_accounts_success = actionCreator("GET_RENT_M_SALE_ACCOUNTS_SUCCESS");



// export const getUsers = actionCreator('USERS_REQUEST');
// export const getUsersSuccess = actionCreator("USERS_SUCCESS");
// export const getUsersFailure = actionCreator("USERS_FAILURE");

// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const salesReducer = reducerWithInitialState(initialState)
    .case(get_rent_sales_list_success, (state, payload) => ({ ...state, rent_sales_list: payload }))
    .case(get_rent_m_sale_accounts_success, (state, payload) => ({ ...state, rent_m_sale_accounts: payload }))
// .case(change_sales_loading, (state, payload) => ({ ...state, sales_loading: payload }))



//.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
// .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const salesSaga = [

takeEvery('GET_RENT_SALES_LIST', handle_get_rent_sales_list),

takeEvery('GET_RENT_M_SALE_ACCOUNTS', handle_get_rent_m_sale_accounts),
    // takeEvery('UPDATE_RENT_SALES_LIST', handle_update_rent_sales_list),



    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;






function* handle_get_rent_sales_list(action: any) {

    const { payload, error } = yield call(RentSalesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        yield put(get_rent_sales_list_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



//    function* handle_update_rent_sales_list(action:any) {
//     // yield put(get_rent_sales_list_success([]));
//     yield put(change_sales_loading(1));

//    console.log(action.payload)
//    const reset:any=[];
//    yield put(get_rent_sales_list_success(reset));
//     for (let i = action.payload.fromRow; i <= action.payload.toRow; i++) {


//     //  if(updated.rentMSaleAccountName){
//     //      // this.props.rent_sales_list[i] = { ...this.props.rent_sales_list[i], ...updated };
//     //           var rentmsales= this.props.rent_m_sale_accounts.filter((a:any)=>a.text===updated.rentMSaleAccountName)  

//     //           action.payload.rent_sales_list[i].rentMSaleAccountId = rentmsales[0].id;
//     //           action.payload.rent_sales_list[i].rentMSaleAccountName = rentmsales[0].value;
//     //          }else 
//              if(action.payload.updated.amount){
//                  // this.props.rent_sales_list[i] = { ...this.props.rent_sales_list[i], ...updated };
//                  action.payload.rent_sales_list[i].amount=action.payload.updated.amount;
//              //  this.props.rent_sales_list[i] = { ...this.props.rent_sales_list[i], ...updated };
//              } else if(action.payload.updated.remarks){
//                  // this.props.rent_sales_list[i] = { ...this.props.rent_sales_list[i], ...updated };
//                  action.payload.rent_sales_list[i].remarks=action.payload.updated.remarks;
//              //  this.props.rent_sales_list[i] = { ...this.props.rent_sales_list[i], ...updated };
//              } 
//              }
//         //   alert()
//             //    this.props.get_rent_sales_list_success(action.payload.rent_sales_list);
//             console.log(action.payload.rent_sales_list)
// //    alert()
//        yield put(get_rent_sales_list_success(action.payload.rent_sales_list));
//        yield put(change_sales_loading(0));
//    }



function* handle_get_rent_m_sale_accounts(action: any) {

    const { payload, error } = yield call(RentMSaleAccountsApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        const rent_m_sale_accounts = payload.map((item: any, i: number) => ({
            id: item.id,
            title: item.name,
            value: item.name,
            text: item.name,
        }))
        yield put(get_rent_m_sale_accounts_success(rent_m_sale_accounts));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}
// function* handleUsersRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(UsersApi.UsersLIst, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getUsersSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentAgentsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentAgentsApi.RentAgentsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentAgentsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentMReasonsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentMReasonsApi.RentMReasonsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentMReasonsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }