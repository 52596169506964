
const authentication = require('../../../react-azure-adb2c2').default;



export const upload = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/template/docs/uploadfile/`
    // const url = `http://localhost:5000/v1/rent/line/template/docs/uploadfile/`

    const token = authentication.getAccessToken();


    // alert(url)
    var formData = new FormData();
    formData.append("files", values.file[0]);
    console.log(formData)
    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


// export const download = (values: any) => {

//     const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/docs/download/` + values
//     // const url = `http://localhost:5000/v1/rent/limited/public/docs/download/` + values

//     const token = authentication.getAccessToken();

//     // alert(JSON.stringify(accountinglistset))
//     return fetch(url, {
//         method: 'get',
//         // body: JSON.stringify(accountinglistset),

//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//     }).then(response => response.blob())
//         .then(payload => ({ payload }))
//         // .then(res => res.json())
//         // .then(payload => ({ payload }))
//         .catch(error => ({ error }));
// };

