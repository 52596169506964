import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as Responses from './Saga/Responses';

import ResponseInfoEdit from './ResponseInfoEditComponent.js';
import ResponseInfoDisp from './ResponseInfoDispComponent';
// import ResponseInfoEditSubmitButton from './ResponseInfoEditSubmitButton';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import ResponseInfoEditComponent from './ResponseInfoEditComponent';
import { Card, CardContent } from '@mui/material';
import Alert from '@mui/material/Alert';

class ResponseInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {

            startDate: new Date()
        };

        this.responsedetailupdate = this.responsedetailupdate.bind(this);
        this.submit = this.submit.bind(this);

        if (this.props.rent_m_medea.length === 0) {
            this.props.get_rent_m_medea()
        }
        if (this.props.rent_m_response_end_reasons.length === 0) {
            this.props.get_rent_m_response_end_reasons();
        }
        if (this.props.rent_m_agents.length === 0) {
            this.props.get_rent_m_agents()
        }

    }
    // responsedetailupdate(values) {
    //     this.props.responseloding(0)
    //     this.props.responseUpdate(values)
    // }

    responsedetailupdate(value) {
        //    alert(JSON.stringify(value))
        this.props.responseloding(0)
        this.props.responseUpdate(value)

    }
    submit() {

        this.props.responseloding(0)
        console.log(this.props.responsedetail)

        this.props.responseUpdate(this.props.responsedetail)


    }
    render() {

        return (
            <div>
                {this.props.responseedit === 1 ? <div><ResponseInfoEdit responsedetail={this.props.responsedetail} responseeditchange={this.props.responseeditchange} setsubi_master={this.props.setsubi_master} onSubmit={this.responsedetailupdate} />
                    {/* <button
        type="button"
        className="btn btn-primary float-right" 
        onClick={this.submit}>登録</button> */}
                </div> : <ResponseInfoDisp responsedetail={this.props.responsedetail} responseeditchange={this.props.responseeditchange} />}

                <Modal isOpen={this.props.response_detail_dialog_disp} toggle={()=>this.props.change_response_detail_dialog_disp(false)} size="lg" fade={false}>
                    <ModalHeader toggle={this.togglesettings}>お客様情報</ModalHeader>
                    <Card>
                        <CardContent>
                        <Alert severity="error" style={{marginBottom:10}}>必須項目を入力してください。</Alert>
                        <ResponseInfoEditComponent responsedetail={this.props.responsedetail} responseeditchange={this.props.responseeditchange} setsubi_master={this.props.setsubi_master} onSubmit={this.responsedetailupdate} />
                        </CardContent>
                    </Card>
                    
                    {/* <MapMypage rent_response_id={this.props.responseid} responsedetail={this.props.responsedetail} selectsheet={this.selectsheet} /> */}

                </Modal>
            </div>
        );
    }
}



//コンテナ
const mapStateToProps = state => {
    return {
        responseedit: state.responsesReducer.responseedit,
        setsubi_master: state.masterReducer.setsubi_master,

        rent_m_medea: state.responsesReducer.rent_m_medea,
        rent_m_agents: state.responsesReducer.rent_m_agents,
        rent_m_response_end_reasons: state.responsesReducer.rent_m_response_end_reasons,
        response_detail_dialog_disp: state.responsesReducer.response_detail_dialog_disp,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        responseeditchange(state) {
            dispatch(Responses.responseeditchange(state))
        },
        responseUpdate(state) {
            dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            dispatch(Responses.responseloding(state))
        },
        
        get_rent_m_medea(state) {
            dispatch(Responses.get_rent_m_medea(state))
        },
        get_rent_m_response_end_reasons(state) {
            dispatch(Responses.get_rent_m_response_end_reasons(state))
        },
        get_rent_m_agents(state) {
            dispatch(Responses.get_rent_m_agents(state))
        },
        change_response_detail_dialog_disp(state) {
            dispatch(Responses.change_response_detail_dialog_disp(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseInfo);
