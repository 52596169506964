import React, { Component, useRef } from 'react';

import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
// import RoomSearchListComponent from '../RoomList/Index';
import * as Files from './Saga/Files';
import Loading from '../Layout/LoadingComponent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import './Css/FileUp.css'
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

// タイマー用
let timer;


class FileUpComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deletealert: false,
      contractdoc:this.props.contractdoc,
      // mode: '1',
      // messages: [],
      // scrollTop: 0,
      // old_id: 0,
      // back_date: "",
      // modalsearch: false,
      // modal: false,
      // modalselect: false,
      // files: [],
      // mailid: 0,
      // modalTemplateSelect: false,
      // textareaHeight: 0,
      // modalNoSubject: false,
      // tooltipOpen: false,
      // modalDelete: false,
      // modalmap: false,

      // base64: null,

      // introduction_details: this.props.introduction_details,

    };

    this.props.change_contract_file_remarks(this.props.remarks)
    this.props.change_contract_file_contractdoc(this.props.contractdoc)

    // alert(this.props.introduction_details)
    // this.props.insert_introduction_files({rentResponseId:this.props.rent_response_id})

    // this.toggletooltip = this.toggletooltip.bind(this);
    // this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);

    // this.save = this.save.bind(this);
    // props.get_mails()

    this.onDrop = this.onDrop.bind(this);

    this.change_deletealert = this.change_deletealert.bind(this);

    // this.handleChange = this.handleChange.bind(this);
    // this.handleEditorChange = this.handleEditorChange.bind(this);

    // this.downloadfile = this.downloadfile.bind(this);
    // this.togglesearch = this.togglesearch.bind(this);
    // this.togglemap = this.togglemap.bind(this);


    // this.toggleTemplateSelect = this.toggleTemplateSelect.bind(this);

    // this.selectTatemono = this.selectTatemono.bind(this);
    // this.deletefile = this.deletefile.bind(this);

    // this.delete_draft = this.delete_draft.bind(this);
    // this.toggleNoSubject = this.toggleNoSubject.bind(this);
    // this.toggleDelete = this.toggleDelete.bind(this);


  }


  onDrop = (files) => {
    console.log(files)
    const file = files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        base64: reader.result,
        name: file.name,
      })
    }
    // usePdf({
    //   file: files,
    //   // onDocumentComplete,
    //   // page,
    //   canvasEl:this.canvasEl
    // });
    let sendfile = [];
    sendfile.file = files;
    // sendfile.id = this.props.introduction_file_id;
    this.props.change_contract_file_data(files)


    // this.props.add_introduction_file(sendfile)
  };


  change_deletealert() {
    this.setState({ deletealert: true })
  }
  // toggle() {
  //   this.setState(prevState => ({
  //     modal: !prevState.modal
  //   }));
  // }

  // toggleselect = (id) => {

  //   this.setState(prevState => ({
  //     modalselect: !prevState.modalselect,
  //     mailid: id
  //   }));
  // }

  // togglesearch() {

  //   this.setState(prevState => ({
  //     modalsearch: !prevState.modalsearch
  //   }));
  //   this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)



  // }

  // togglemap() {

  //   this.setState(prevState => ({
  //     modalmap: !prevState.modalmap
  //   }));
  //   // this.props.draggable===1?this.props.change_draggable(2):this.props.change_draggable(1)
  // }


  // toggleTemplateSelect() {
  //   this.setState(prevState => ({
  //     modalTemplateSelect: !prevState.modalTemplateSelect
  //   }));

  // }
  // toggleNoSubject() {
  //   this.setState(prevState => ({
  //     modalNoSubject: !prevState.modalNoSubject
  //   }));

  // }
  // save = () => {
  //   let introduction_details = this.state.introduction_details
  //   introduction_details.rentResponseId = this.props.rent_response_id
  //   console.log(introduction_details)

  //   this.props.insert_introduction_files(introduction_details)
  //   this.props.togglefileupsave()

  // };


  // handlefrommail(state) {
  //   this.props.send_mail_details.fromMail = state.target.value;


  // }




  // handleChange(state) {
  //   // console.log(state)

  //   // console.log(state.target.name)
  //   //       console.log(state.target.value)
  //   // let send_mail_details = this.props.send_mail_details;
  //   // // alert(state.target.value)
  //   let introduction_details = this.state.introduction_details
  //   introduction_details[state.target.name] = state.target.value;
  //   this.setState({ introduction_details: introduction_details })
  //   //       clearTimeout(timer);


  //   //           timer = setTimeout(function () { 
  //   //             // console.log(send_mail_details)
  //   //           // alert(send_mail_details[state.target.name])
  //   //             this.props.update_send_mail_editor_state(send_mail_details)

  //   //           }.bind(this), 1500);
  //   //           // this.forceUpdate();
  // }

  // handleEditorChange(state) {
  //   // console.log(state.target.name)
  //   // console.log(state.target.value)
  //   this.props.send_mail_details.body = state;

  //   clearTimeout(timer);


  //   timer = setTimeout(function () {
  //     if (this.props.mail_new >= 1) {
  //       this.props.update_send_mail_editor_state(this.props.send_mail_details)
  //     }
  //   }.bind(this), 1500);

  // }

  // downloadfile(e, file) {
  //   e.stopPropagation();

  //   this.props.get_mail_file(file)
  //   // alert(state)
  // }
  // deletefile(e, file) {
  //   e.stopPropagation();
  //   this.props.delete_mail_file(file.id)
  //   // alert(state)
  // }

  // selectTatemono = (tatemonoId, roomId) => {
  //   // alert(this.props.responseid)
  //   this.props.add_mail_room({ window: window, id: 131, tatemonoId: tatemonoId, roomId: roomId, rentResponseId: this.props.responseid })
  //   // this.props.togglesearch();
  // }

  // delete_draft() {

  //   // alert(this.props.send_mail_details.id)
  //   let send_mail_details = this.props.send_mail_details;
  //   send_mail_details.isDeleted = true;
  //   console.log(send_mail_details)

  //   this.props.delete_draft(send_mail_details)
  //   this.setState(({ modalDelete: false }));
  //   // this.props.delete_draft(this.props.send_mail_details.id)
  // }
  // toggleDelete() {
  //   this.setState(prevState => ({
  //     modalDelete: !prevState.modalDelete
  //   }));

  // }

  // onDocumentLoadSuccess(e) {

  //   const importPDFCanvas = document.querySelector('.react-pdf__Page canvas');

  //   const pdfAsImageSrc = importPDFCanvas.toDataURL();


  //   var bin = atob(pdfAsImageSrc.replace(/^.*,/, ''));
  //   // バイナリデータ化
  //   var buffer = new Uint8Array(bin.length);
  //   for (var i = 0; i < bin.length; i++) {
  //     buffer[i] = bin.charCodeAt(i);
  //   }
  //   // ファイルオブジェクト生成(この例ではjpegファイル)
  //   const file = new File([buffer.buffer], this.props.introduction_file_id + ".jpg", { type: "image/jpeg" });
  //   let sendfile = [];
  //   let fileset = []
  //   fileset.push(file)
  //   sendfile.file = fileset;
  //   sendfile.id = this.props.introduction_file_id;

  //   this.props.change_introduction_pdf_img(sendfile)
  //   //  this.props.add_introduction_file(sendfile)
  //   console.log(file)
  // }

  // toggletooltip() {
  //   this.setState({
  //     tooltipOpen: !this.state.tooltipOpen
  //   });
  // }
  render() {

    // const files = this.props.send_files.map(file => (
    //     <li className="filename" key={file.name}>
    //       {file.name} <span className="fas fa-times float-right"/>

    //     </li>

    //   ));

    return (

      <div >



        {this.props.fileid > 0 ? <div style={{ textAlign: "center" }}>
          {this.props.name}
          {this.props.file_tab == "f5" ? <RadioGroup row aria-label="position" name="contractdoc" onChange={(a) => {this.props.change_contract_file_contractdoc(a.target.value);this.setState({ contractdoc: a.target.value })}} value={parseInt(this.state.contractdoc)} >
              <FormControlLabel control={<Radio value={0} />} label="契約書" />
              <FormControlLabel control={<Radio value={1} />} label="フォローシート" />
              <FormControlLabel control={<Radio value={2} />} label="適格請求書" />
              <FormControlLabel control={<Radio value={3} />} label="代行会社からの適格請求書" />
            </RadioGroup>:""}
          {this.props.file_tab !== "f4" ? 
          <TextField
            id="outlined-multiline-static"
            label="備考"
            defaultValue={this.props.remarks}
            multiline
            rows={4}
            onChange={(a) => this.props.change_contract_file_remarks(a.target.value)}
            style={{ width: "90%", margin: 10 }}
            // defaultValue="Default Value"
            variant="outlined"
          />:""}

          <Button variant="contained" style={{ float: "right" }} onClick={() => this.props.edit_contract_file_remarks(this.props.fileid)} color="primary">
            保存
      </Button>


          {this.state.deletealert ? <Alert style={{ textAlign: "left" }} severity="error">本当に削除してもよろしいですか？ <Button variant="contained" style={{ float: "left" }} onClick={() => this.props.delete_contract_file(this.props.fileid)} color="secondary">
            削除
      </Button></Alert> : <Button variant="contained" style={{ float: "left" }} onClick={this.change_deletealert} color="secondary">
              削除
      </Button>}
        </div > :
          <div style={{ textAlign: "center" }}>

            <div className='file' style={{ height: "100%", margin: 5 }}>
              {this.props.get_mail_detail_files_loading === 1 ? <Loading /> : <Dropzone

                onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (

                  <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5" }}>

                    <input {...getInputProps()} />

                    <div style={{ height: 100 }}>
                      <p>{this.state.name ? this.state.name : "ファイル添付　クリックまたはドラッグ&ドロップ"}</p>
                      <ul style={{ padding: 0, marginTop: 3 }}  >
                        {/* {this.props.mail_detail_files.map(file => (
            <li className="filename" key={file.id} >
              <span onClick={(e)=>this.downloadfile(e,file)}>{file.name}</span> <span onClick={(e)=>this.deletefile(e,file)} className="fas fa-times float-right" />
            
                </li>))} */}

                      </ul>

                    </div>

                  </div>

                )}
              </Dropzone>}

            </div>
            {this.props.file_tab == "f5" ? <RadioGroup row aria-label="position" name="contractdoc" onChange={(a) => {this.props.change_contract_file_contractdoc(a.target.value);this.setState({ contractdoc: a.target.value })}} value={parseInt(this.state.contractdoc)} >
              <FormControlLabel control={<Radio value={0} />} label="契約書" />
              <FormControlLabel control={<Radio value={1} />} label="フォローシート" />
              <FormControlLabel control={<Radio value={2} />} label="適格請求書" />
              <FormControlLabel control={<Radio value={3} />} label="代行会社からの適格請求書" />
            </RadioGroup>:""}
            {this.props.file_tab !== "f4" ? <TextField
              id="outlined-multiline-static"
              label="備考"
              multiline
              rows={4}
              onChange={(a) => this.props.change_contract_file_remarks(a.target.value)}
              style={{ width: "90%", margin: 10, marginTop:0 }}
              // defaultValue="Default Value"
              variant="outlined"
            />:""}
            <Button variant="contained" onClick={this.props.upload_contract_file} color="primary">
              保存
      </Button>
          </div >
        }
      </div >

    );

  }
}




//コンテナ
const mapStateToProps = state => {
  return {

    response_file_remarks: state.responsefiles.response_file_remarks,
    file_tab: state.files.file_tab
    // introduction_details: state.introductionlist.introduction_details,
    // introduction_pdf: state.introductionlist.introduction_pdf,
    // send_mail_cc_disp:state.mailReducer.send_mail_cc_disp,
    // send_mail_bcc_disp:state.mailReducer.send_mail_bcc_disp,
    // mail_detail_files:state.mailReducer.mail_detail_files,
    // send_edit_contents:state.mailReducer.send_edit_contents,
    // mail_new:state.mailReducer.mail_new,
    // mail_minimum:state.mailReducer.mail_minimum,
    // responseid: state.responsesReducer.responseid,
    // editor_height: state.mailReducer.editor_height,
    // editor_width: state.mailReducer.editor_width,
    // send_mail_loading:state.mailReducer.send_mail_loading,
    // send_mail_alert:state.mailReducer.send_mail_alert,
    // get_mail_detail_files_loading:state.mailReducer.get_mail_detail_files_loading,
    // get_mail_details_loading:state.mailReducer.get_mail_details_loading,
    // draggable:state.masterReducer.draggable,


  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_contract_file_data(state) {
      dispatch(Files.change_contract_file_data(state))
    },
    change_contract_file_remarks(state) {
      dispatch(Files.change_contract_file_remarks(state))
    },
    change_contract_file_contractdoc(state) {
      dispatch(Files.change_contract_file_contractdoc(state))
    },
    upload_contract_file(state) {
      dispatch(Files.upload_contract_file(state))
    },
    edit_contract_file_remarks(state) {
      dispatch(Files.edit_contract_file_remarks(state))
    },
    delete_contract_file(state) {
      dispatch(Files.delete_contract_file(state))
    },
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(FileUpComponent);
