import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Board from './Board';
import Sales from './Sales';
import Task from './Task';
import './Css/Form.css';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function BasicTabs(props) {
// export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="タスク" {...a11yProps(0)} />
                    <Tab label="反響分析" {...a11yProps(1)} />
                    <Tab label="売上分析" {...a11yProps(2)} />
                    <Tab label="市場分析" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} >
                {props.user_details.amSectionId > 0 ? <Task /> : ""}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Board />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Sales />
            </TabPanel>
            <TabPanel value={value} index={3}>
                Item Three
            </TabPanel>
            
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        user_details: state.masterReducer.user_details,
    }
  }

export default connect(mapStateToProps)(BasicTabs);
