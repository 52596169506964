import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Container, Draggable } from "react-smooth-dnd";

import { connect } from 'react-redux';
// import arrayMove from "array-move";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as ContractFollow from './Saga/ContractFollow';



import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent
  } from "@dnd-kit/core";
  import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
  } from "@dnd-kit/sortable";
  import SortableItem from "./SortableItem";
//   import { Item } from "./types";






import Divider from '@mui/material/Divider';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         margin: 'auto',
//     },
//     paper: {
//         width: 200,
//         height: 230,
//         overflow: 'auto',
//     },
//     button: {
//         margin: theme.spacing(0.5, 0),
//     },
// }));

function not(a: any, b: any) {
    return a.filter((value: any) => b.indexOf(value) === -1);
}

function intersection(a: any, b: any) {
    return a.filter((value: any) => b.indexOf(value) !== -1);
}


const SortableList = (props: any) => {

    const [items, setItems] = useState([
        { id: 1, name: "dnd-kit example 1" },
        { id: 2, name: "dnd-kit example 2" },
        { id: 3, name: "dnd-kit example 3" },
        { id: 4, name: "dnd-kit example 4" },
        { id: 5, name: "dnd-kit example 5" },
        { id: 6, name: "dnd-kit example 6" },
        { id: 7, name: "dnd-kit example 7" },
        { id: 8, name: "dnd-kit example 8" }
      ]);

      const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates
        })
      );

      const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
    
        if (!over) {
          return;
        }
    
        if (active.id !== over.id) {
        //   const oldIndex = items.findIndex((v) => v.id === active.id);
        //   const newIndex = items.findIndex((v) => v.id === over.id);
        //   setItems(arrayMove(items, oldIndex, newIndex));

          const oldIndex = props.rent_m_contract_follows_disp.findIndex((v:any) => v.id === active.id);
          const newIndex = props.rent_m_contract_follows_disp.findIndex((v:any) => v.id === over.id);
          const newArray = arrayMove(props.rent_m_contract_follows_disp, oldIndex, newIndex)
          props.change_rent_m_contract_follows_disp(newArray)
        }
      };


    // const onDrop = ({ removedIndex, addedIndex }: any) => {
    //     let rent_m_contract_follows_disp = props.rent_m_contract_follows_disp
    //     // setItems(kanri_m_follows => arrayMove(kanri_m_follows, removedIndex - 1, addedIndex - 1));
    //     rent_m_contract_follows_disp = arrayMove(rent_m_contract_follows_disp, removedIndex - 1, addedIndex - 1)

    //     let rent_m_contract_follow_settings = props.rent_m_contract_follow_settings
    //     let frozen = true
    //     let follows = rent_m_contract_follows_disp.map(function (value: any) {


    //         value.frozen = frozen
    //         if (rent_m_contract_follow_settings.frozen_key_name === value.key_name) {
    //             frozen = false
    //         }

    //         return value
    //     })
    //     props.change_rent_m_contract_follows_disp([])
    //     props.change_rent_m_contract_follows_disp(follows)
    // };

    // const displaynone = (id: any) => {
    //     let rent_m_contract_follows_disp = props.rent_m_contract_follows_disp
    //     let rent_m_contract_follows_none = props.rent_m_contract_follows_none
    //     rent_m_contract_follows_none.push(rent_m_contract_follows_disp.filter((a: any) => a.id === id)[0])
    //     rent_m_contract_follows_disp = rent_m_contract_follows_disp.filter((a: any) => a.id !== id)

    //     props.change_rent_m_contract_follows_disp(rent_m_contract_follows_disp)
    //     props.change_rent_m_contract_follows_none([])

    //     props.change_rent_m_contract_follows_none(rent_m_contract_follows_none)

    // };

    // const change_frozen = (key_name: string) => {

    //     let rent_m_contract_follow_settings = props.rent_m_contract_follow_settings

    //     let frozen = true

    //     let follows = props.rent_m_contract_follows_disp.map(function (value: any) {


    //         value.frozen = frozen
    //         if (key_name === value.key_name) {
    //             frozen = false
    //         }

    //         return value
    //     })

    //     props.change_rent_m_contract_follows_disp(follows)
    //     rent_m_contract_follow_settings.frozen_key_name = key_name
    //     props.change_rent_m_contract_follow_settings({})
    //     props.change_rent_m_contract_follow_settings(rent_m_contract_follow_settings)
    //     // props.change_kanri_m_follow_settings_pool()
    // };


    return (

    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={props.rent_m_contract_follows_disp} strategy={verticalListSortingStrategy}>
        <List>
        {/* <ul> */}
          {props.rent_m_contract_follows_disp.map((item:any) => (
            <SortableItem key={item.id} item={item} />
          ))}
        {/* </ul> */}
        </List>
      </SortableContext>
    </DndContext>
        // <List>
            
        //     <Container dragHandleSelector=".drag-handle" lockAxis="y" style={{ width: "400px" }} onDrop={onDrop}>

        //         <Divider />
        //         {props.rent_m_contract_follows_disp.map(({ id, name, key_name }: any) => (
        //             <Draggable key={id}>
        //                 <ListItem>
        //                     <FormControlLabel
        //                         value="top"
        //                         control={<Radio color="primary" />}
        //                         label="固定"
        //                         labelPlacement="top"
        //                         checked={props.rent_m_contract_follow_settings.frozen_key_name === key_name}
        //                         onChange={() => change_frozen(key_name)}
        //                     />



        //                     <ListItemText primary={name} />

        //                     <ListItemSecondaryAction style={{ marginRight: 30 }}>
        //                         <ListItemIcon className="drag-handle" >
        //                             <DragHandleIcon />
        //                         </ListItemIcon>

        //                     </ListItemSecondaryAction>
        //                     <IconButton aria-label="delete" onClick={() => displaynone(id)} size="large">
        //                         <DeleteIcon fontSize="small" color="secondary" />
        //                     </IconButton>
        //                 </ListItem>
        //                 <Divider />
        //             </Draggable>
        //         ))}

        //     </Container>
        // </List>
    );
};



const mapStateToProps = (state: any) => {
    return {
        users_select: state.masterReducer.users_select,
        // responseid: state.customersReducer.responseid,
        // responsedetail: state.customersReducer.responsedetail,
        user_details: state.masterReducer.user_details,
        // csv_loading: state.Adjustment.csv_loading,
        // search_list: state.Adjustment.search_list,
        // seisan_follow_list: state.Adjustment.seisan_follow_list,
        // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
        // seisan_follow_search: state.Adjustment.seisan_follow_search,
        // tantou: state.Adjustment.tantou,
        // rent_m_contract_follows: state.ContractFollow.rent_m_contract_follows,
        rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
        rent_m_contract_follows_none: state.contractfollow.rent_m_contract_follows_none,
        rent_m_contract_follow_settings: state.contractfollow.rent_m_contract_follow_settings,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {

        change_rent_m_contract_follows_disp(state: any) {
            dispatch(ContractFollow.change_rent_m_contract_follows_disp(state))
        },
        change_rent_m_contract_follows_none(state: any) {
            dispatch(ContractFollow.change_rent_m_contract_follows_none(state))
        },
        change_rent_m_contract_follow_settings(state: any) {
            dispatch(ContractFollow.change_rent_m_contract_follow_settings(state))
        },


        // set_seisan_follow_search_list(state: any) {
        //     dispatch(Adjustment.set_seisan_follow_search_list(state))
        // },

        // update_kyousai_comp(state: any) {
        //   dispatch(Adjustment.update_kyousai_comp(state))
        // },
        // change_search_list(state: any) {
        //   dispatch(Adjustment.change_search_list(state))
        // },
        // get_kyousais(state: any) {
        //   dispatch(Adjustment.get_kyousais(state))
        // },
        // matching(state: any) {
        //   dispatch(Adjustment.matching(state))
        // },
        // delete_kyousais(state: any) {
        //   dispatch(Adjustment.delete_kyousais(state))
        // },
        // all_select(state: any) {
        //   dispatch(Adjustment.all_select(state))
        // },
        // get_kyousais_csv(state: any) {
        //   dispatch(Adjustment.get_kyousais_csv(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SortableList);

