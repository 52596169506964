import React, { Component } from 'react';
import { connect } from 'react-redux';

import ContactEditComponent from './ContactEditComponent';
import ContactGridEditComponent from './ContactGridEditComponent';
import Reserve from '../Search/Index';
import ContactsButtons from './ContactsButtons';
import * as CustomerContacts from './Saga/CustomerContacts';
import * as Calendar from '../Calendar/Saga/Calendar';
import * as AutoSends from '../AutoSend/Saga/AutoSend';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { submit } from 'redux-form'
import Loading from '../Layout/LoadingComponent';
import ResponseEndComponent from './ResponseEndComponent';

import AutoSendComponent from './AutoSendComponent';
import TimeLine from './TimeLine';

import ContactsGridComponent from './ContactsGridComponent';
import { Modal, Row, Col, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import RentCalendarComponent from '../Calendar/RentCalendarComponent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import { AlarmTwoTone } from '@mui/icons-material';


var moment = require('moment-timezone');





var top = 0
var left = 0

var useStyles = makeStyles({
    avatar: {
        // backgroundColor: blue[100],
        // color: blue[600],
    },
    dialog: {
        position: 'absolute',
        left: left,
        top: top
    }
});


function TaskDialog(props) {



    useStyles = makeStyles({
        avatar: {
            // backgroundColor: blue[100],
            // color: blue[600],
        },
        dialog: {
            position: 'absolute',
            // left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
            // top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),

            width: 700,
            height: 750
        }
    });
    const classes = useStyles();
    const { onClose, selectedValue, open, event_type, change_customer_contacts_modal, customer_contacts_modal } = props;

    const handleClose = () => {
        change_customer_contacts_modal(false);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog
            maxWidth="xl"
            classes={{
                paper: classes.dialog
            }}
            onClose={handleClose} aria-labelledby="simple-dialog-title" open={customer_contacts_modal}>



            <ContactGridEditComponent onClose={change_customer_contacts_modal} />



            {/* {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : <button className="btn btn-primary float-right" type="button" onClick={this.props.add_rent_customer_contacts}>保存</button>}
      {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : (this.state.contactstate && this.state.contactstate.id ? <button className="btn btn-danger float-right" type="button" onClick={this.toggledelete}>削除</button> : "")}*/}
        </Dialog>
    );
}

class ContactsComponet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: '2',
            modalselect: false,
            modalscheduler: false,
            modalreserve: false,
            modalend: false,
            modalautosends: false,
            modalAutoSendSelect: false,
            comingdetail: {
                id: -1,
                user_id: props.user_details.id,
                date: new Date()
            },
            toggle_end_cancel_confirm: false,
            reflash: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.toggleselect = this.toggleselect.bind(this);
        this.toggleselect_calendar = this.toggleselect_calendar.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.toggledelete = this.toggledelete.bind(this);
        this.togglereserve = this.togglereserve.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleend = this.toggleend.bind(this);
        // this.handleEndFormSubmit = this.handleEndFormSubmit.bind(this);
        this.toggleendreset = this.toggleendreset.bind(this);
        this.toggleautosends = this.toggleautosends.bind(this);
        this.toggleAutoSendSelect = this.toggleAutoSendSelect.bind(this);
        this.toggle_end_cancel = this.toggle_end_cancel.bind(this);

        this.toggle_end_cancel_set = this.toggle_end_cancel_set.bind(this);
        this.end_form_change = this.end_form_change.bind(this);

        this.props.get_users();


    }
    componentWillMount() {

        this.setState({

            comingdetail: {
                id: -1,
                user_id: this.props.user_details.id,
                date: new Date()
            }
        });

    }
    handleClick(event) {
        if (this.state.mode === 1) {
            this.setState({

                mode: '2',
            });
        } else {
            this.setState({

                mode: '1',
            });
        }
    }
    handleFormSubmit(event) {

        // console.log(event)
        // alert()
        // event.userId= event.userId.value;

        if (event.id > 0) {
            if (event.rentMResponseStatusId === 0) {
                if (event.way === "3") {
                    event.rentMResponseStatusId = 8;
                } else if (event.way === "2") {
                    event.rentMResponseStatusId = 2;
                } else if (event.way === "1") {
                    event.rentMResponseStatusId = 9;
                }
            }
            console.log(event)

            this.props.update_rent_customer_contacts(event)

        } else {
            if (event.rentMResponseStatusId === 0) {
                if (event.way === "3") {
                    event.rentMResponseStatusId = 8;
                } else if (event.way === "2") {
                    event.rentMResponseStatusId = 2;
                } else if (event.way === "1") {
                    event.rentMResponseStatusId = 9;
                }
            }
            this.props.add_rent_customer_contacts(event)
        }
        this.toggleselect(event)

    }

    toggleendreset(event) {


        this.props.change_rent_responses_end(event);
        this.setState({ modalend: false });
    }

    toggleendresetend(event) {


        this.props.change_rent_responses_end(event);
        this.setState({ modalend: false });
    }
    // toggleendreset() {
    //     this.props.change_rent_responses_end({ rentResponseId: this.props.responsedetail.id, endResponseDate: null, endResponseReason: 0, endResponseRemarks: null, endResponseUserId: 0 });
    //     this.setState({ modalend: false });
    // }

    toggleselect(state) {
        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms


        rent_customer_contacts_forms.rent_response_id = state.rent_response_id ? this.props.responsedetail.id : state.rent_response_id;
        rent_customer_contacts_forms.section_id = state.section_id ? this.props.responsedetail.section_id : state.section_id;
        rent_customer_contacts_forms.user_id = state.user_id === 0 ? this.props.user_details.id : state.user_id;
        rent_customer_contacts_forms.date = state.way == 3 ? (state.date ? moment(state.date).utc().format("YYYY/MM/DD") : moment().format("YYYY/MM/DD")) : state.date ? moment(state.date).utc().format("YYYY/MM/DD HH:mm") : moment().format("YYYY/MM/DD HH:mm");
        rent_customer_contacts_forms.line_schedule_date = state.date ? moment(state.date).utc().format("YYYY/MM/DD HH:mm") : moment().format("YYYY/MM/DD HH:mm");
        rent_customer_contacts_forms.remarks = state.remarks ? state.remarks : '';
        rent_customer_contacts_forms.way = state.way ? state.way : '';
        rent_customer_contacts_forms.going = state.going ? state.going : state.going == 0 ? state.going : null;
        rent_customer_contacts_forms.going_time = state.going_time ? state.going_time : 0;
        rent_customer_contacts_forms.going_user_id = state.going_user_id ? state.going_user_id : 0;
        rent_customer_contacts_forms.is_application = state.is_application ? state.is_application : 0;
        rent_customer_contacts_forms.rent_m_going_reason_id = state.rent_m_going_reason_id ? state.rent_m_going_reason_id : 0;
        rent_customer_contacts_forms.going_section = state.going_section ? state.going_section : state.going_section == 0 ? state.going_section : -1 ;
        rent_customer_contacts_forms.online = state.online ? state.online : 0;
        rent_customer_contacts_forms.no_coming = state.no_coming ? state.no_coming : 0;
        rent_customer_contacts_forms.is_prepare = state.is_prepare ? state.is_prepare : 0;
        rent_customer_contacts_forms.rent_m_response_status_id = state.rent_m_response_status_id ? state.rent_m_response_status_id : 0;
        rent_customer_contacts_forms.intend = state.intend ? state.intend : 0;
        rent_customer_contacts_forms.agent_name = state.agent_name;
        rent_customer_contacts_forms.agent_etc = state.agent_etc;
        rent_customer_contacts_forms.intend_time = state.intend_time ? state.intend_time : 0;
        rent_customer_contacts_forms.intend_time2 = state.intend_time2 ? state.intend_time2 : 0;

        rent_customer_contacts_forms.start = state.start_at ? moment(state.start_at).utc().format("YYYY/MM/DD HH:mm") : null;
        rent_customer_contacts_forms.end = state.end_at ? moment(state.end_at).utc().format("YYYY/MM/DD HH:mm") : null;

        rent_customer_contacts_forms.id = state.id ? state.id : 0;
        rent_customer_contacts_forms.title = "";

        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
        this.props.change_rent_customer_contacts_forms_modal(!this.props.rent_customer_contacts_forms_modal)
        this.props.change_customer_contacts_modal(!this.props.customer_contacts_modal)

        this.props.change_customer_contacts_saving(false)
        this.props.change_customer_contacts_deleting(false)
        this.props.change_customer_contacts_visit_deleting(false)



        this.setState(prevState => ({
            modalselect: !prevState.modalselect,
            contactstate: state
        }));


        // {
        //     id: 0,
        //     userId: this.props.userId,
        //     rentResponseId: this.props.responsedetail.id,
        //     sectionId: this.props.responsedetail.sectionId,
        //     way:3,
        //     returning:0,
        //     going:0,
        //     remarks:"",
        //   date:new Date(),
        //   intend:0,
        //   intend_time:0,
        //   intend_time2:0
        // });/










    }

    toggleselect_calendar(state) {
        let rent_customer_contacts_calendar_forms = this.props.rent_customer_contacts_calendar_forms

        rent_customer_contacts_calendar_forms.rent_response_id = state.rent_response_id ? this.props.responsedetail.id : state.rent_response_id;
        rent_customer_contacts_calendar_forms.section_id = state.section_id ? this.props.responsedetail.section_id : state.section_id;
        rent_customer_contacts_calendar_forms.user_id = state.user_id === 0 ? this.props.user_details.id : state.user_id;
        rent_customer_contacts_calendar_forms.date = 
        state.way == 3 ? 
            (state.date ? 
                moment(state.date).utc().format("YYYY/MM/DD HH:mm") : 
                moment().format("YYYY/MM/DD 00:00")) : 
            state.date ? 
                moment(state.date).utc().format("YYYY/MM/DD HH:mm") : 
                moment().format("YYYY/MM/DD HH:mm");
        rent_customer_contacts_calendar_forms.start = state.start_at ? moment(state.start_at).utc().format("YYYY/MM/DD HH:mm") : null;
        rent_customer_contacts_calendar_forms.end = state.end_at ? moment(state.end_at).utc().format("YYYY/MM/DD HH:mm") : null;
        rent_customer_contacts_calendar_forms.line_schedule_date = state.date ? moment(state.date).utc().format("YYYY/MM/DD HH:mm") : moment().utc().format("YYYY/MM/DD HH:mm");
        rent_customer_contacts_calendar_forms.remarks = state.remarks ? state.remarks : '';
        rent_customer_contacts_calendar_forms.way = state.way ? state.way : '';
        rent_customer_contacts_calendar_forms.going = state.going ? state.going : state.going == 0 ? state.going : null;
        rent_customer_contacts_calendar_forms.going_time = state.going_time ? state.going_time : 0;
        rent_customer_contacts_calendar_forms.going_user_id = state.going_user_id ? state.going_user_id : 0;
        rent_customer_contacts_calendar_forms.is_application = state.is_application ? state.is_application : 0;
        rent_customer_contacts_calendar_forms.rent_m_going_reason_id = state.rent_m_going_reason_id ? state.rent_m_going_reason_id : 0;
        rent_customer_contacts_calendar_forms.going_section = state.going_section ? state.going_section : state.going_section == 0 ? state.going_section : -1 ;
        rent_customer_contacts_calendar_forms.online = state.online ? state.online : 0;
        rent_customer_contacts_calendar_forms.no_coming = state.no_coming ? state.no_coming : 0;
        rent_customer_contacts_calendar_forms.is_prepare = state.is_prepare ? state.is_prepare : 0;
        rent_customer_contacts_calendar_forms.rent_m_response_status_id = state.rent_m_response_status_id ? state.rent_m_response_status_id : 0;
        rent_customer_contacts_calendar_forms.intend = state.intend ? state.intend : 0;
        rent_customer_contacts_calendar_forms.agent_name = state.agent_name;
        rent_customer_contacts_calendar_forms.agent_etc = state.agent_etc;
        rent_customer_contacts_calendar_forms.intend_time = state.intend_time ? state.intend_time : 0;
        rent_customer_contacts_calendar_forms.intend_time2 = state.intend_time2 ? state.intend_time2 : 0;

        rent_customer_contacts_calendar_forms.id = state.id ? state.id : 0;
        rent_customer_contacts_calendar_forms.title = "";
        rent_customer_contacts_calendar_forms.mail_all_receive_id = state.mail_all_receive_id;
        rent_customer_contacts_calendar_forms.mail_template_id = state.mail_template_id;

        this.props.change_rent_customer_contacts_calendar_forms(rent_customer_contacts_calendar_forms)
        this.props.change_rent_customer_contacts_calendar_forms_modal(!this.props.rent_customer_contacts_calendar_forms_modal)
        this.props.change_customer_contacts_modal(!this.props.customer_contacts_modal)

        this.props.change_customer_contacts_saving(false)
        this.props.change_customer_contacts_deleting(false)
        this.props.change_customer_contacts_visit_deleting(false)



        this.setState(prevState => ({
            modalselect: !prevState.modalselect,
            contactstate: state
        }));

    }


    togglescheduler = () => {

        this.setState(prevState => ({
            modalscheduler: !prevState.modalscheduler,

        }));
    }

    togglereserve = () => {

        this.setState(prevState => ({
            modalreserve: !prevState.modalreserve,

        }));
    }

    end_form_change = () => {

        this.setState(prevState => ({
            reflash: !prevState.reflash,

        }));
    }


    toggledelete() {


        this.setState(prevState => ({
            modaldelete: !prevState.modaldelete,

        }));
    }

    toggleend() {

        this.setState(prevState => ({
            modalend: !prevState.modalend,

        }));

    }

    toggle_end_cancel() {

        this.setState(prevState => ({
            toggle_end_cancel_confirm: !this.state.toggle_end_cancel_confirm

        }));

    }

    toggle_end_cancel_set() {

        this.props.change_rent_responses_end_cancel()
        this.setState(prevState => ({
            toggle_end_cancel_confirm: !this.state.toggle_end_cancel_confirm

        }));

    }


    toggleautosends() {

        this.setState(prevState => ({
            modalautosends: !prevState.modalautosends,

        }));

    }
    toggleAutoSendSelect() {
        this.setState({ modalAutoSendSelect: true })
    }

    delete() {
        this.props.delete_rent_customer_contacts(this.state.contactstate.id)

        this.setState({
            modaldelete: false,
            modalselect: false,
        });
    }
    render() {
        console.log(this.props.customer_contacts)

        return (
            <div>
                {this.state.modalscheduler ? <div style={{ height: "500px", background: 'white', paddingBottom: '40px' }}><RentCalendarComponent togglescheduler={this.togglescheduler} customer_contacts_calendar={this.props.customer_contacts_calendar} toggle={this.toggleselect} /></div> :
                    <Row>
                        <Col lg="12">

                            {this.props.responsedetail ? <ContactsButtons toggle={this.toggleselect_calendar} togglescheduler={this.togglescheduler} togglereserve={this.togglereserve} toggleend={this.toggleend} toggleautosends={this.toggleautosends} toggle_end_cancel={this.toggle_end_cancel} responsedetail={this.props.responsedetail} toggle_end_cancel_confirm={this.state.toggle_end_cancel_confirm} toggle_end_cancel_set={this.toggle_end_cancel_set} /> : ""}
                        </Col>
                        <Col className="item-outer">
                            {this.props.customer_contacts_timeline ? <TimeLine /> : <ContactsGridComponent customer_contacts={this.props.customer_contacts} rent_response_id={this.props.responsedetail.id} toggle={this.toggleselect}  toggle_calendar={this.toggleselect_calendar} />}
                        </Col>
                    </Row>}


                <TaskDialog

                    change_customer_contacts_modal={this.props.change_customer_contacts_modal}
                    customer_contacts_modal={this.props.customer_contacts_modal} />

                {/* <Modal isOpen={this.props.rent_customer_contacts_forms_modal} toggle={this.toggleselect} size="lg" fade={false}>
                    <ModalHeader toggle={this.toggleselect}> {Number(this.props.rent_customer_contacts_forms.way) === 3 ? "来店登録" : "追客登録"}</ModalHeader>
                    <ContactEditComponent contactstate={this.state.contactstate} user_id={this.props.user_details.id} onSubmit={this.handleFormSubmit} users_select={this.props.users_select} responsedetail={this.props.responsedetail} />
                    {/* {this.state.contactstate===0?<ContactEditComponent contactstate={this.state.contactstate} userId={this.props.user_details.id}  onSubmit={this.handleFormSubmit} users_select={this.props.users_select} responsedetail={this.props.responsedetail}/>:<ComingEditComponent userId={this.props.user_details.id} onSubmit={this.handleFormSubmit}  comingdetail={this.state.comingdetail} users_select={this.props.users_select} responsedetail={this.props.responsedetail}/>} */}


                {/* <ModalFooter> */}

                {/* <button className="btn btn-primary float-right" type="button" onClick={() => this.props.submit('contactform')}>保存</button>*/}
                {/* {(Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : <button className="btn btn-primary float-right" type="button" onClick={this.props.add_rent_customer_contacts}>保存</button>} */}
                {/* {(Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : (this.state.contactstate && this.state.contactstate.id ? <button className="btn btn-danger float-right" type="button" onClick={this.toggledelete}>削除</button> : "")} */}
                {/* <Button variant="contained" style={{ margin: 10 }} color="secondary" onClick={this.toggleselect}>キャンセル</Button> */}
                {/* </ModalFooter> */}
                {/* </Modal>  */}

                <Modal isOpen={this.state.modaldelete} toggle={this.toggledelete} size="lg" fade={false}>

                    <ModalHeader toggle={this.toggledelete}>{this.props.responsedetail.rent_m_way_id === 5 && this.props.customer_contacts.filter(a => a.way === 3).length === 1 && Number(this.props.rent_customer_contacts_forms.way) === 3 ? "飛込では来店登録が1件必要なので削除できません。" : "本当に削除してもよろしいですか？"}</ModalHeader>
                    <ModalFooter>

                        {this.props.responsedetail.rent_m_way_id === 5 && this.props.customer_contacts.filter(a => a.way === 3).length === 1 && Number(this.props.rent_customer_contacts_forms.way) === 3 ? "" : <button className="btn btn-danger float-right" type="button" onClick={this.delete}>削除</button>}
                        <Button variant="contained" style={{ margin: 10 }} color="secondary" onClick={this.toggledelete}>キャンセル</Button>
                    </ModalFooter>
                </Modal>



                <Modal isOpen={this.state.modalreserve} toggle={this.togglereserve} size="lg" fade={false}>

                    <ModalHeader toggle={this.togglereserve}>来店予約</ModalHeader>
                    <ModalBody>
                        <Reserve />
                    </ModalBody>
                    <ModalFooter>

                        <Button variant="contained" style={{ margin: 10 }} color="secondary" onClick={this.togglereserve}>閉じる</Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={this.state.modalautosends} toggle={this.toggleautosends} size="lg" fade={false}>

                    <ModalHeader toggle={this.toggleautosends}>自動追客設定</ModalHeader>
                    <ModalBody>
                        <AutoSendComponent rentResponseId={this.props.responsedetail.id} modalAutoSendSelect={this.state.modalAutoSendSelect} toggle={this.toggleselect_calendar} />
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button variant="contained" style={{ margin: 10 }} color="primary" onClick={() => this.props.insert_auto_sends_templates_all()}>自動追客一括追加</Button> */}
                        <Button variant="contained" style={{ margin: 10 }} color="primary" onClick={() => this.props.add_auto_send_modal(true)}>自動追客追加</Button>
                        <Button variant="contained" style={{ margin: 10 }} color="secondary" onClick={this.toggleautosends}>閉じる</Button>
                    </ModalFooter>
                </Modal>








                <Modal isOpen={this.state.modalend} toggle={this.toggleend} size="lg" fade={false}>

                    <ModalHeader toggle={this.toggleend}>追客終了</ModalHeader>
                    <ModalBody>
                        <ResponseEndComponent end_form_change={this.end_form_change} user_id={this.props.user_details.id} users_select={this.props.users_select} responsedetail={this.props.responsedetail} />
                    </ModalBody>
                    <ModalFooter>

                        {this.props.rent_customer_contacts_end_responses_form.end_response_reason == 3 && !this.props.rent_customer_contacts_end_responses_form.end_response_remarks ? <Alert severity="error">備考に理由を入力してください。</Alert> :
                        this.props.rent_customer_contacts_end_responses_form.end_response_reason == 5 && !this.props.rent_customer_contacts_end_responses_form.end_response_remarks ? <Alert severity="error">備考に審査NG理由を入力してください。</Alert> :
                            <span>
                                <Button variant="contained" style={{ margin: 10 }} color="secondary" onClick={this.toggleendreset}>追客終了</Button>
                                {/* <button className="btn btn-danger float-right" type="button" onClick={this.toggleendresetend}>取り消し</button> */}
                                <Button variant="contained" style={{ margin: 10 }} onClick={this.toggleend}>閉じる</Button>
                            </span>}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

// function renderForecastsTable(handleClick, handleFormSubmit, state) {

//     if (state.mode===1) {
//         return (
//             <button className="btn btn-success float-right" onClick={handleClick}>編集</button>
//         );
//     } else {
//         //return (
//         //    <div>
//         //        <button className="btn btn-default float-right" onClick={handleClick}>閉じる</button>

//         //    </div>
//         //);
//     }
// }

// export default connect(
// )(ContactsComponet);

const mapStateToProps = state => {
    return {
        users: state.masterReducer.users,
        users_select: state.masterReducer.users_select,
        section_id: state.masterReducer.section_id,
        user_details: state.masterReducer.user_details,
        customer_contacts: state.customerContactsReducer.customer_contacts,
        customer_contacts_calendar: state.customerContactsReducer.customer_contacts_calendar,
        responsedetail: state.responsesReducer.responsedetail,
        rent_customer_contacts_forms: state.customerContactsReducer.rent_customer_contacts_forms,
        rent_customer_contacts_calendar_forms: state.customerContactsReducer.rent_customer_contacts_calendar_forms,
        rent_customer_contacts_forms_modal: state.customerContactsReducer.rent_customer_contacts_forms_modal,
        rent_customer_contacts_end_responses_form: state.customerContactsReducer.rent_customer_contacts_end_responses_form,
        customer_contacts_modal: state.customerContactsReducer.customer_contacts_modal,
        customer_contacts_timeline: state.customerContactsReducer.customer_contacts_timeline,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        add_rent_customer_contacts(state) {
            dispatch(CustomerContacts.add_rent_customer_contacts(state))
        },
        update_rent_customer_contacts(state) {
            dispatch(CustomerContacts.update_rent_customer_contacts(state))
        },
        delete_rent_customer_contacts(state) {
            dispatch(CustomerContacts.delete_rent_customer_contacts(state))
        },
        submit(state) {

            dispatch(submit(state))
        },
        change_rent_responses_end(state) {
            dispatch(CustomerContacts.change_rent_responses_end(state))
        },
        change_rent_responses_end_cancel(state) {
            dispatch(CustomerContacts.change_rent_responses_end_cancel(state))
        },
        add_auto_send_modal(state) {
            dispatch(CustomerContacts.add_auto_send_modal(state))
        },
        change_rent_customer_contacts_forms(state) {
            dispatch(CustomerContacts.change_rent_customer_contacts_forms(state))
        },
        change_rent_customer_contacts_forms_modal(state) {
            dispatch(CustomerContacts.change_rent_customer_contacts_forms_modal(state))
        },
        change_customer_contacts_modal(state) {
            dispatch(CustomerContacts.change_customer_contacts_modal(state))
        },
        change_customer_contacts_saving(state) {
            dispatch(CustomerContacts.change_customer_contacts_saving(state))
        },
        change_customer_contacts_deleting(state) {
            dispatch(CustomerContacts.change_customer_contacts_deleting(state))
        },
        change_customer_contacts_visit_deleting(state) {
            dispatch(CustomerContacts.change_customer_contacts_visit_deleting(state))
        },
        change_rent_customer_contacts_calendar_forms(state) {
            dispatch(CustomerContacts.change_rent_customer_contacts_calendar_forms(state))
        },
        change_rent_customer_contacts_calendar_forms_modal(state) {
            dispatch(CustomerContacts.change_rent_customer_contacts_calendar_forms_modal(state))
        },
        insert_auto_sends_templates_all(state) {
            dispatch(CustomerContacts.insert_auto_sends_templates_all(state))
        },
        get_users(state) {
            dispatch(Calendar.get_users(state))
        },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactsComponet);