import React, { Component } from 'react';

import { connect } from 'react-redux';

import Grid from "./ResponseListGridComponent";
import ResponseAddComponent from '../ResponseDetail/ResponseAddComponent';
import ResponseListSearch from "./ResponseListSearchComponent";
import ResponseSearch from "./ResponseSearchComponent";
import { ModalHeader, Button, ModalBody, ModalFooter, Modal } from 'reactstrap';

// import * as Master from '../../Store/Master';
// import * as Responses from './Saga/ResponseList';
import { getRentMReasons } from '../../Store/Master';
import { responseList } from './Saga/ResponseList';


import { change_response_add_disp, change_response_add_detail, change_customer_add_detail } from '../ResponseDetail/Saga/Responses';
import MailTemplateComponent from '../MailTemplate/Index';
import EventListener from 'react-event-listener';
import '../../Css/Form.css';

interface Props {
    responseList: any,
    dispatchRequest: any,
    masterReducer: any,
    responsesReducer: any,
    change_response_add_disp: any,
    change_response_add_detail: any,
    change_customer_add_detail: any,
    // get_rent_m_medea: any,

}

interface State {
    height: number,
    toggleaddresponse: boolean,
    togglemailtemplate: boolean,
    showRight: number,
    userDetail: any,
    condition: string
    // state types
}

class ResponseList extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            height: window.innerHeight,
            toggleaddresponse: false,
            togglemailtemplate: false,
            showRight: 0,
            userDetail: {},
            condition: "1"
        };
        this.handletoggleaddresponse = this.handletoggleaddresponse.bind(this);
        this.handletogglemailtemplate = this.handletogglemailtemplate.bind(this);
        this.responsedetailsearch = this.responsedetailsearch.bind(this);

    }
    componentWillMount() {

        //this.props.dispatchRequest()
    }

    handleResize = () => {
        console.info(
            `window height:width=${window.innerHeight}:${window.innerWidth}`,
        );
        this.setState({ height: window.innerHeight });
    };
    handletoggleaddresponse(state: any) {

        this.setState({ userDetail: state })
        this.setState(prevState => ({
            toggleaddresponse: !prevState.toggleaddresponse
        }));

    }

    handletogglemailtemplate() {
        this.setState(prevState => ({
            togglemailtemplate: !prevState.togglemailtemplate
        }));

    }
    //   toggleaddresponse(){
    //     this.setState(prevState => ({
    //         toggleaddresponse: !prevState.toggleaddresponse
    //     }));
    // }
    responsedetailsearch(value: any) {
        // alert(JSON.stringify(value))
        // alert(value.condition)
        this.setState({ condition: value.condition })

        this.props.responseList(value)

    }
    render() {

        return (
            <div>

                <EventListener target="window" onResize={this.handleResize} />
                {this.state.togglemailtemplate === true ? <MailTemplateComponent /> :
                    <div className="box responseMenu" style={{ position: "relative" }}>

                        <div style={{ position: "absolute", zIndex: 5, left: 0, right: 0 }}>
                            <div className="row" >
                                <div className="item-outer col-lg-12">
                                    <div style={{ backgroundColor: "#FFF" }}>
                                        {this.props.masterReducer.user_details.id > 0 ? <ResponseListSearch
                                            user_details={this.props.masterReducer.user_details}
                                            onSubmit={this.responsedetailsearch}
                                            handletoggleaddresponse={this.handletoggleaddresponse}
                                            handletogglemailtemplate={this.handletogglemailtemplate}
                                        // toggleaddresponse={this.toggleaddresponse}
                                        /> : ""}

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="item-outer col-lg-12">
                                    <div className="item">
                                        <Grid />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ position: "absolute", zIndex: 100, width: this.state.showRight + 40, right: 0 }}>
                            <div style={{ float: "left" }}>
                                <div style={{ backgroundColor: "#a9a9a9", width: 40, height: 40, textAlign: "left", paddingLeft: 8, paddingTop: 6 }}>
                                    <div className="fa fa-search" style={{ fontSize: 26, color: "White" }} onClick={() => this.setState({ "showRight": this.state.showRight === 0 ? 300 : 0 })}></div>
                                </div>
                            </div>
                            <div className="search_menu" style={{ float: "left", width: 300, border: "1px solid #cdcdcd", height: this.state.height - 40, overflowY: "scroll", display: this.state.showRight === 0 ? 'none' : '' }}>
                                <ResponseSearch />
                                <button className="btn btn-primary float-right" type="button" >検索</button>

                            </div>

                        </div>
                    </div>




                }


                <Modal isOpen={this.props.responsesReducer.response_add_disp} toggle={() => this.props.change_response_add_disp(0)}
                    size="xxl" fade={false}>


                    <ModalHeader toggle={() => this.props.change_response_add_disp(0)}>新規登録</ModalHeader>
                    <ModalBody style={{ background: "#9fb6cc" }}>
                        <ResponseAddComponent
                            responseedit={null}
                            responseeditchange={null}
                        />


                    </ModalBody>
                </Modal>


            </div>
        );
    }
};

export default connect(
    ({ masterReducer, responsesReducer }: any) => ({ masterReducer, responsesReducer }),
    // state => ({
    //   mapsearch: state.mapsearchReducer,

    // }),
    {
        responseList,
        getRentMReasons,
        change_response_add_disp, change_response_add_detail, change_customer_add_detail,

    }




)(ResponseList)
//export  default CustomerList;
// const mapStateToProps = (state:any) => {
//     return {

//     }
// }
// function mapDispatchToProps(dispatch: any) {
//     return {
//         responseList(search:string) {
//             dispatch(Responses.responseList(search))
//         },
//         dispatchRequest() { dispatch(Master.getRentMReasons()) },

//     };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ResponseList);




