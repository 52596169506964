import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';

import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import "react-input-range/lib/css/index.css";
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const moment = require('moment-timezone');

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      style={{ textAlign: 'right' }}

      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator

    />
  );
}


class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      reflash: false
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.change_date = this.change_date.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms[event.target.name] = event.target.value
    // if (event.target.name === "condition") {
    //   formData.contractionDateStart = new Date();
    //   formData.contractionDateEnd = null;
    // }
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    // if (event.target.name === "condition") {


    // }
    // this.setState({ reflash: !this.state.reflash });
  }


  change_date = (e) => {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    // rent_contract_editor_forms[name] = moment(event).format("YYYY/MM/DD");
    rent_contract_editor_forms[e.target.name] = e.target.value;


    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    // if (event.target.name === "condition") {


    // }
    this.setState({ reflash: !this.state.reflash });
  }
  render() {



    return (
      <div>

        <table>
          <tr>
            <th colSpan={4}>その他
          <IconButton
            aria-label="settings"
            style={{ float: "right" }}
            onClick={() => this.props.check_rent_contract_editor_disp({ editor: "rent_contract_ippan_editor_disp", disp: false })}
            size="large">
                <CancelIcon />
              </IconButton>
            </th>

          </tr>
          <tr>
            <th style={{ width: "20%" }}>補修完了日</th>
            <td style={{ width: "30%" }}>

              <TextField
                id="hoshuu_date"
                label="補修完了日"
                type="date"
                name="hoshuu_date"
                size="small"
                variant='outlined'
                value={this.props.rent_contract_editor_forms.hoshuu_date ? moment(this.props.rent_contract_editor_forms.hoshuu_date).format("YYYY-MM-DD"):null}
                onChange={this.change_date}

                InputLabelProps={{
                    shrink: true,
                }}
              />

              {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-inline1"
                  label="補修完了日"
                  autoOk={true}
                  InputLabelProps={{ shrink: !!this.props.rent_contract_editor_forms.hoshuu_date }}
                  name="hoshuu_date"
                  value={this.props.rent_contract_editor_forms.hoshuu_date ? this.props.rent_contract_editor_forms.hoshuu_date : null}
                  onChange={e => this.change_date(e, 'hoshuu_date')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  invalidDateMessage={"日付の形式が違います"}
                />
              </MuiPickersUtilsProvider> */}

            </td>
            <th style={{ width: "20%" }}>補修先</th>
            <td style={{ width: "30%" }}>
              <FormControl >
                <InputLabel id="demo-simple-select-outlined-label">補修先</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={{ width: 130, margin: 10 }}
                  name="kouji_gyousha"
                  value={this.props.rent_contract_editor_forms.kouji_gyousha}
                  onChange={this.handleChange}
                  label="補修先"
                >
                  <MenuItem value={0}>
                    -
                </MenuItem>
                  <MenuItem value={1}>オーナー</MenuItem>
                  <MenuItem value={2}>当社</MenuItem>

                </Select>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th style={{ width: "20%" }}>鍵交換</th>
            <td style={{ width: "30%" }}>
              <FormControl >
                <InputLabel id="demo-simple-select-outlined-label">鍵交換</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={{ width: 130, margin: 10 }}
                  name="kagi_gyousha"
                  value={this.props.rent_contract_editor_forms.kagi_gyousha}
                  onChange={this.handleChange}
                  label="鍵交換"
                >
                  <MenuItem value={0}>
                    -
                </MenuItem>
                  <MenuItem value={1}>オーナー</MenuItem>
                  <MenuItem value={2}>他社</MenuItem>
                  <MenuItem value={3}>当社</MenuItem>
                </Select>
              </FormControl>
            </td>
            <th >カギの所在</th>
            <td >

              <FormControl >
                <InputLabel id="demo-simple-select-outlined-label">カギの所在</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={{ width: 130, margin: 10 }}
                  name="kagi_shozai"
                  value={this.props.rent_contract_editor_forms.kagi_shozai}
                  onChange={this.handleChange}
                  label="カギの所在"
                >
                  <MenuItem value={0}>
                    -
                </MenuItem>
                  <MenuItem value={1}>オーナー</MenuItem>
                  <MenuItem value={2}>番町</MenuItem>
                  <MenuItem value={3}>レインボー</MenuItem>
                  <MenuItem value={4}>高松駅前</MenuItem>
                  <MenuItem value={5}>高松東</MenuItem>
                  <MenuItem value={6}>中央公園</MenuItem>
                  <MenuItem value={7}>サンフラワー</MenuItem>
                  <MenuItem value={47}>中央通り</MenuItem>
                  <MenuItem value={48}>現地</MenuItem>

                </Select>
              </FormControl>


            </td>
          </tr>
          <tr>
            <th>カギ交換日</th>
            <td colSpan={3}> 
            
              <TextField
                id="kagi_koukan_date"
                label="カギ交換日"
                type="date"
                name="kagi_koukan_date"
                size="small"
                variant='outlined'
                value={this.props.rent_contract_editor_forms.kagi_koukan_date ? moment(this.props.rent_contract_editor_forms.kagi_koukan_date).format("YYYY-MM-DD"):null}
                onChange={this.change_date}

                InputLabelProps={{
                    shrink: true,
                }}
              />
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


              <KeyboardDatePicker
                disableToolbar
                variant="inline"

                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-inline1"
                label="カギ交換日"
                autoOk={true}
                InputLabelProps={{ shrink: !!this.props.rent_contract_editor_forms.kagi_koukan_date }}
                name="kagi_koukan_date"
                value={this.props.rent_contract_editor_forms.kagi_koukan_date ? this.props.rent_contract_editor_forms.kagi_koukan_date : null}
                onChange={e => this.change_date(e, 'kagi_koukan_date')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                invalidDateMessage={"日付の形式が違います"}
              />
            </MuiPickersUtilsProvider> */}

            </td>
          </tr>

          <tr>
            <th>消毒日</th>
            <td>  
              
              <TextField
                id="shoudoku_date"
                label="消毒日"
                type="date"
                name="shoudoku_date"
                size="small"
                variant='outlined'
                value={this.props.rent_contract_editor_forms.shoudoku_date ? moment(this.props.rent_contract_editor_forms.shoudoku_date).format("YYYY-MM-DD"):null}
                onChange={this.change_date}

                InputLabelProps={{
                    shrink: true,
                }}
              />
              
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


              <KeyboardDatePicker
                disableToolbar
                variant="inline"

                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-inline1"
                label="消毒日"

                autoOk={true}
                InputLabelProps={{ shrink: !!this.props.rent_contract_editor_forms.shoudoku_date }}
                name="contract_date"
                value={this.props.rent_contract_editor_forms.shoudoku_date ? this.props.rent_contract_editor_forms.shoudoku_date : null}
                onChange={e => this.change_date(e, 'shoudoku_date')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                invalidDateMessage={"日付の形式が違います"}
              />
            </MuiPickersUtilsProvider> */}
            </td>
            <th>抗菌日</th>
            <td>

              <TextField
                id="koukin_date"
                label="抗菌日"
                type="date"
                name="koukin_date"
                size="small"
                variant='outlined'
                value={this.props.rent_contract_editor_forms.koukin_date ? moment(this.props.rent_contract_editor_forms.koukin_date).format("YYYY-MM-DD"):null}
                onChange={this.change_date}

                InputLabelProps={{
                    shrink: true,
                }}
              />
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"

                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-inline1"
                  label="抗菌日"

                  autoOk={true}
                  InputLabelProps={{ shrink: !!this.props.rent_contract_editor_forms.koukin_date }}
                  name="contract_date"
                  value={this.props.rent_contract_editor_forms.koukin_date ? this.props.rent_contract_editor_forms.koukin_date : null}
                  onChange={e => this.change_date(e, 'koukin_date')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  invalidDateMessage={"日付の形式が違います"}
                />
              </MuiPickersUtilsProvider> */}
            </td>
          </tr>




        </table>
        <Button style={{ float: "right", margin: 5 }} variant="contained" color="primary"
          onClick={this.props.update_rent_contracts}
        >
          保存
      </Button>
      </div >
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    change_rent_contract_editor_forms(state) {
      dispatch(Contracts.change_rent_contract_editor_forms(state))
    },
    update_rent_contracts(state) {
      dispatch(Contracts.update_rent_contracts(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
