import React, { Component } from 'react';

import { connect } from 'react-redux';
import FollowDispCalendar from '../Calendar/FollowDispCalendar';

import * as ContractFollow from './Saga/ContractFollow';

import ja from "date-fns/locale/ja";
import MaterialSelect from '@mui/material/Select';
import Select from 'react-select';
import Chip from '@mui/material/Chip';
import "react-datepicker/dist/react-datepicker.css";

import './Css/Search.css';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';

import Autocomplete from '@mui/material/Autocomplete';
import SelectCompo from "./Select";
import UnSelectCompo from "./UnSelect";
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import TextField from '@mui/material/TextField';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import EndGrid from "./EndGrid";
import { CSVLink } from "react-csv";


var moment = require('moment-timezone');
// var top = 0
// var left = 0

var useStyles = makeStyles({
  avatar: {
    // backgroundColor: blue[100],
    // color: blue[600],
  },
  dialog: {
    position: 'absolute',
    left: null,
    top: null,
    width: 800
  }
});







function SimpleDialog(props) {
  // useStyles = makeStyles({
  //   avatar: {
  //     // backgroundColor: blue[100],
  //     // color: blue[600],
  //   },
  //   dialog: {
  //     position: 'absolute',
  //     left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
  //     top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),

  //     width: 520,
  //     height: 580
  //   }
  // });

  const classes = useStyles();
  const { onClose, selectedValue, open, contract_follow_editer, change_state } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog
      }}
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {/* <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle> */}

      <EndGrid onClose={handleClose} />

    </Dialog>
  );
}




let timer

class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,
      reflash: false,
      ownshop_operation: true,
      ownshop_contract: true,
    };

    let formData = this.props.contract_follow_list_form
    formData.section_id = this.props.user_details.amSectionId > 0 ? this.props.user_details.amSectionId : 0
    formData.operation_user_id = this.props.user_details.id ? this.props.user_details.id : 0
    // formData = {
    //   email: '',
    //   password: '',
    //   tatemono_name: '',
    //   application_date: null,
    //   contraction_date_start: null,
    //   contraction_date_end: null,
    //   condition: "1",
    //   operation_user_id: this.props.user_details.id ? this.props.user_details.id : 0,
    //   contract_user_id: 0,
    //   name: null,
    //   section_id: this.props.user_details.amSectionId > 0 ? this.props.user_details.amSectionId : 0,
    //   modal_select_comp: false,
    //   anchorEl: null
    // }
    this.props.change_contract_follow_list_form(formData);
    this.toggle = this.toggle.bind(this);
    this.togglecalender = this.togglecalender.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    this.change_operation_user_id = this.change_operation_user_id.bind(this);
    this.change_agent_id = this.change_agent_id.bind(this);
    this.change_kanri_corp_id = this.change_kanri_corp_id.bind(this);
    this.change_contract_user_id = this.change_contract_user_id.bind(this);
    this.update = this.update.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.sort = this.sort.bind(this);
    this.toggle_select_comp = this.toggle_select_comp.bind(this);
    this.save = this.save.bind(this);
    this.saved = this.saved.bind(this);

    this.change_ownshop_operation = this.change_ownshop_operation.bind(this);
    this.change_ownshop_contract = this.change_ownshop_contract.bind(this);

    this.update_contract_follow_end_bulk = this.update_contract_follow_end_bulk.bind(this);

    this.change_search_room = this.change_search_room.bind(this);

    this.change_contract_follow_comp = this.change_contract_follow_comp.bind(this);


    this.change_contraction_date_start = this.change_contraction_date_start.bind(this);
    this.change_contraction_date_end = this.change_contraction_date_end.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.props.contract_follow_list_form)

  }


  change_search_room(e, check) {
    // console.log(e)
    // alert(e.target.name)
    let contract_follow_list_form = this.props.contract_follow_list_form
    contract_follow_list_form[e.target.name] = check
    this.props.change_contract_follow_list_form(contract_follow_list_form);
    this.props.search_contract_follow_list()
    this.setState({ reflesh: !this.props.reflesh })
  }



  change_contract_follow_comp() {
    // console.log(e)
    // alert(e.target.name)
    let contract_follow_list_form = this.props.contract_follow_list_form
    contract_follow_list_form.contract_follow_comp = !contract_follow_list_form.contract_follow_comp
    if (contract_follow_list_form.contract_follow_comp) {
      contract_follow_list_form.contraction_date_start = moment().add(-3, 'months').format("YYYY/MM/DD")
      contract_follow_list_form.contraction_date_end = null
    } else {
      contract_follow_list_form.contraction_date_start = null
      contract_follow_list_form.contraction_date_end = null
    }
    this.props.change_contract_follow_list_form(contract_follow_list_form);
    // this.props.search_contract_follow_list()
    this.props.get_contract_follow_list()
    this.setState({ reflesh: !this.props.reflesh })
  }


  change_ownshop_operation() {

    let contract_follow_list_form = this.props.contract_follow_list_form
    if (!this.state.ownshop_operation && this.props.users.filter(a => a.amSectionId === this.props.contract_follow_list_form.section_id && a.operation_user_id === contract_follow_list_form.operation_user_id)[0]) {

    } else if (!this.state.ownshop_operation) {

      contract_follow_list_form.operation_user_id = 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }


    this.setState({ ownshop_operation: !this.state.ownshop_operation })
  }
  change_ownshop_contract() {

    let contract_follow_list_form = this.props.contract_follow_list_form
    if (!this.state.ownshop_contract && this.props.users.filter(a => a.amSectionId === this.props.contract_follow_list_form.section_id && a.user_id === contract_follow_list_form.contract_user_id)[0]) {

    } else if (!this.state.ownshop_contract) {

      contract_follow_list_form.contract_user_id = 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }


    this.setState({ ownshop_contract: !this.state.ownshop_contract })
  }
  handleChangeSectionId(e, newValue) {


    let contract_follow_list_form = this.props.contract_follow_list_form
    contract_follow_list_form.section_id = newValue ? newValue.value : 0
    this.props.change_contract_follow_list_form(contract_follow_list_form);
    this.props.get_contract_follow_list()
  }

  update() {


    this.props.get_contract_follow_list()
  }


  handleChange = (event) => {
    console.log(event)

    let contract_follow_list_form = this.props.contract_follow_list_form
    contract_follow_list_form[event.target.name] = event.target.value;
    // if (event.target.name === "condition") {
    //   formData.contractionDateStart = new Date();
    //   formData.contractionDateEnd = null;
    // }
    this.props.change_contract_follow_list_form(contract_follow_list_form);
    // if (event.target.name === "condition") {
    clearTimeout(timer);
    timer = setTimeout(() => {

      this.props.search_contract_follow_list()
    }, 400);

    // }
    this.setState({ reflash: !this.state.reflash });
  }



  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")

    this.props.get_contract_follow_list({ section_id: this.props.user_details.amSectionId })
  }
  toggle() {
    //this.setState(prevState => ({
    //    modal: !prevState.modal
    //}));
    this.props.customerNewDispSet(0)
    // let matching = {}


    // this.props.change_matching(matching)
    this.props.history.push('/CustomerNew');

  }


  togglecalender() {
    this.setState(prevState => ({
      modalcalender: !prevState.modalcalender
    }));
  }
  saveSelectValue = (e, id) => {
    let data = {}
    data.id = id;
    data.value = e.target.value

    this.props.saveSelectValue(data)
  }

  // handleSectionIdChange(sectionId) {
  //   alert()
  //   let formData =   this.state.formData
  //   formData.sectionId=sectionId
  //     this.setState(formData);

  //   }
  change_operation_user_id(e, newValue) {


    if (newValue) {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.operation_user_id = newValue ? newValue.value : 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    } else {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.operation_user_id = 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }
    this.setState({ reflesh: !this.props.reflesh })


  };
  change_agent_id(e, newValue) {


    if (newValue) {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.rent_m_agent_id = newValue ? newValue.value : 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    } else {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.rent_m_agent_id = 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }
    this.setState({ reflesh: !this.props.reflesh })


  };
  change_kanri_corp_id(e, newValue) {

    if (newValue) {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.kanri_corp_id = newValue ? newValue.value : 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    } else {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.kanri_corp_id = 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }
    this.setState({ reflesh: !this.props.reflesh })

  };
  change_contract_user_id(e, newValue) {
    if (newValue) {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.contract_user_id = newValue ? newValue.value : 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    } else {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.contract_user_id = 0;
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }
  };
  change_contraction_date_start = date => {
    let contract_follow_list_form = this.props.contract_follow_list_form
    contract_follow_list_form.contraction_date_start = date
    this.props.change_contract_follow_list_form(contract_follow_list_form);
    this.props.search_contract_follow_list()

  };

  change_contraction_date_end = date => {

    let contract_follow_list_form = this.props.contract_follow_list_form
    contract_follow_list_form.contraction_date_end = date
    this.props.change_contract_follow_list_form(contract_follow_list_form);
    this.props.search_contract_follow_list()

  };

  sort(sort) {
    let formData = this.props.responselist.formData
    formData.sort = sort.target.value
    this.props.change_formData(formData);
    this.props.search_response_list()

  }

  toggle_select_comp() {
    this.setState({ modal_select_comp: !this.state.modal_select_comp })
  }


  save(e) {
    this.props.update_rent_m_contract_follow_settings()
    this.setState({ anchorEl: e.currentTarget });


  }
  saved(e) {

    this.setState({ anchorEl: null });
    this.props.change_rent_m_contract_follow_settings_save(false)
  }
  update_contract_follow_end_bulk(e) {

    this.props.change_contract_follow_end_bulk_open(true)
  }



  render() {

    const { handleSubmit } = this.props;

    let headers = [];
    //契約者
    {
      this.props.rent_m_contract_follows_disp.map(function (value, i) {
        if (value.key_name == 6) {
          headers.push({ label: value.name, key: "contract_money" },)
        } else if (value.key_name == "user_nickname") {
          headers.push({ label: value.name, key: value.key_name },)
          headers.push({ label: value.name, key: "operation_user_nickname" },)
          headers.push({ label: "入居者アプリ登録", key: "room_support_m_user_id" },)
        } else if (value.key_name == "75") {

          headers.push({ label: "浄水・シャワー", key: "water" },)
        } else {
          headers.push({ label: value.name, key: value.key_name },)
        }


        if (value.key_name != "tatemono_name" && value.key_name != "room_name" && value.key_name != "kanri_kubun" && value.key_name != "application_date" && value.key_name != "contract_date" && value.key_name != "name1"
          && value.key_name != "section_name" && value.key_name != "user_nickname" && value.key_name != "operation_user_nickname" && value.key_name != "status" && value.key_name != "end_check") {
          if (value.key_name == 6) {
            // headers.push({ label: value.name, key: "contract_money" },)
          } else {
            headers.push({ label: value.name + "備考", key: "remarks_" + value.key_name },)
          }
        }

      })
    }
    //入居者
    {
      this.props.rent_m_contract_follows_disp.map(function (value, i) {
        if (value.key_name != "tatemono_name" && value.key_name != "room_name" && value.key_name != "kanri_kubun" && value.key_name != "application_date" && value.key_name != "contract_date"
          && value.key_name != "section_name" && value.key_name != "user_nickname" && value.key_name != "operation_user_nickname" && value.key_name != "status" && value.key_name != "end_check") {
          if (value.key_name == "name1") {
            headers.push({ label: "法人 入居者", key: "name2" },)
          } else if (value.key_name == 6) {
            headers.push({ label: "法人 " + value.name, key: "contract_money_tenant" },)
          } else {
            headers.push({ label: "法人 " + value.name, key: "date_n_" + value.key_name },)
            headers.push({ label: "法人 " + value.name + "備考", key: "remarks_n_" + value.key_name },)
          }
        }
      })
    }

    // console.log(this.props.rent_m_contract_follows_disp)
    // console.log(headers)

    return (
      <div id="contractfollow">
        <form onSubmit={this.handleSubmit}  >
          <table>

            <tbody>

              <tr >

                <td colSpan="2" >
                  <div className="form-inline">
                    {/* <Field
                                  name="name"
                                  component="input"
                                  type="text"
                                  placeholder="お客様名"
                                          className="form-control"
                                          size="20"
                                  /> */}


                    <TextField
                      id="outlined-basic"
                      //   className={classes.textField}
                      label="お客様名"
                      size="small"
                      variant="standard"
                      onChange={this.handleChange}
                      name="name"
                      value={this.props.contract_follow_list_form.name}
                      style={{ width: "150px", marginRight: "5px" }}

                    />


                    <TextField
                      id="outlined-basic"
                      //   className={classes.textField}
                      label="建物名"
                      margin="normal"
                      name="tatemono_name"
                      onChange={this.handleChange}
                      size="small"
                      value={this.props.contract_follow_list_form.tatemono_name}
                      style={{ width: "150px", marginRight: "5px" }}
                      variant="standard"
                    />




                      {this.props.section_select.length > 0 && this.props.user_details ? <Autocomplete
                        id="combo-box-demo"
                        options={this.props.section_select.map(function (value) {
                          if (value.value == -1) {
                            value.label = "全店"
                          }
                          return value
                        }).filter(a => a.value <= 6 || a.value == 10 || a.value == 16 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47 || a.value == 55)}
                        getOptionLabel={(option) => option.label}
                        defaultValue={this.props.section_select.filter(a => a.value === this.props.user_details.amSectionId)[0]}
                        // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                        style={{ width: 200, margin:5 }}
                        disableClearable={true}
                        onChange={this.handleChangeSectionId}
                        size="small"
                        renderInput={(params) => <TextField variant="standard"
                          {...params} label="店舗を選択" />}

                      /> : ""}


                    <FormControl style={{ width: "160px" }}>
                      {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
                        id="combo-box-demo"
                        options={this.state.ownshop_operation ? this.props.users.filter(a => a.amSectionId === this.props.contract_follow_list_form.section_id).map((value) => ({
                          value: value.id,
                          label: value.userNameAll
                        })) : this.props.users_select}
                        getOptionLabel={(option) => option.label}
                        // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
                        // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                        style={{ width: 150 }}
                        name="operation_user_id"
                        value={this.props.contract_follow_list_form.operation_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.operation_user_id)[0] : null}
                        onChange={this.change_operation_user_id}
                        size="small"
                        renderInput={(params) => <TextField variant="standard"
                          {...params} label="手続担当を選択" />}

                      /> : ""}
                    </FormControl>

                    <FormControlLabel checked={this.state.ownshop_operation} onChange={this.change_ownshop_operation} control={<Checkbox name="checkedC" />} label="自店のみ" />

                    {this.props.rent_m_agents ? <FormControl style={{ marginLeft: "10px", marginRight: 15, width: "180px" }}><Autocomplete
                      id="combo-box-demo"
                      // options={this.props.users_select}
                      options={this.props.rent_m_agents.map((value) => ({
                        value: value.id,
                        label: value.name
                      }))}
                      getOptionLabel={(option) => option.label}
                      name="rent_m_agent_id"
                      value={this.props.contract_follow_list_form.rent_m_agent_id ? this.props.rent_m_agents.filter(a => a.id === this.props.contract_follow_list_form.rent_m_agent_id).map((value) => ({
                        value: value.id,
                        label: value.name
                      }))[0] : null}
                      onChange={this.change_agent_id}
                      size="small"
                      renderInput={(params) => <TextField variant="standard"
                        // variant="filled"
                        // error={!this.props.contract_follow_list_form.rent_m_agent_id}
                        {...params} label="代行会社" />}

                    /></FormControl> : ""}

                    {this.props.kanricorps ? <FormControl style={{ marginLeft: "10px", marginRight: 15, width: "180px" }}><Autocomplete
                      id="combo-box-demo"
                      // options={this.props.users_select}
                      options={this.props.kanricorps.map((value) => ({
                        value: value.value,
                        label: value.label
                      }))}
                      getOptionLabel={(option) => option.label}
                      name="kanri_corp_id"
                      value={this.props.contract_follow_list_form.kanri_corp_id ? this.props.kanricorps.filter(a => a.value === this.props.contract_follow_list_form.kanri_corp_id).map((value) => ({
                        value: value.value,
                        label: value.label
                      }))[0] : null}
                      onChange={this.change_kanri_corp_id}
                      size="small"
                      renderInput={(params) => <TextField variant="standard"
                        // variant="filled"
                        // error={!this.props.contract_follow_list_form.kanri_corp_id}
                        {...params} label="管理会社" />}

                    /></FormControl> : ""}


                    <FormControlLabel checked={this.props.contract_follow_list_form.contract_follow_comp} onChange={this.change_contract_follow_comp} control={<Checkbox name="contract_follow_comp" />} label="完了" />

                    <Button style={{ marginRight: 5, backgroundColor: "#5cb85c" }} size="small" variant="contained" color="primary">
                      <CSVLink style={{ textDecoration: "none", color: "white" }} headers={headers} filename={"契約フォローリスト.csv"} data={this.props.contract_follow_search_list.map(row => ({
                        ...row,
                        application_date: row.application_date ? moment(row.application_date).format("YYYY-MM-DD") : "",
                        contract_date: row.contract_date ? moment(row.contract_date).format("YYYY-MM-DD") : "",
                        last_response_date: row.last_response_date ? moment(row.last_response_date).format("YYYY-MM-DD") : "",
                        room_support_m_user_id: row.room_support_m_user_id && row.room_support_m_user_id == 1 ? "済" : "",
                        water: row.water ? moment(row.date_75).format("YYYY-MM-DD") : (row.get_shower_user_id ? "浄水器" : "") + (row.get_water_user_id ? "シャワー" : ""),



                        date_n_1: row.date_n_1 ? moment(row.date_n_1).format("YYYY-MM-DD") : "",
                        date_n_2: row.date_n_2 ? moment(row.date_n_2).format("YYYY-MM-DD") : "",
                        date_n_3: row.date_n_3 ? moment(row.date_n_3).format("YYYY-MM-DD") : "",
                        date_n_4: row.date_n_4 ? moment(row.date_n_4).format("YYYY-MM-DD") : "",
                        date_n_5: row.date_n_5 ? moment(row.date_n_5).format("YYYY-MM-DD") : "",
                        date_n_6: row.date_n_6 ? moment(row.date_n_6).format("YYYY-MM-DD") : "",
                        date_n_7: row.date_n_7 ? moment(row.date_n_7).format("YYYY-MM-DD") : "",
                        date_n_8: row.date_n_8 ? moment(row.date_n_8).format("YYYY-MM-DD") : "",
                        date_n_9: row.date_n_9 ? moment(row.date_n_9).format("YYYY-MM-DD") : "",

                        date_n_10: row.date_n_10 ? moment(row.date_n_10).format("YYYY-MM-DD") : "",
                        date_n_11: row.date_n_11 ? moment(row.date_n_11).format("YYYY-MM-DD") : "",
                        date_n_12: row.date_n_12 ? moment(row.date_n_12).format("YYYY-MM-DD") : "",
                        date_n_13: row.date_n_13 ? moment(row.date_n_13).format("YYYY-MM-DD") : "",
                        date_n_14: row.date_n_14 ? moment(row.date_n_14).format("YYYY-MM-DD") : "",
                        date_n_15: row.date_n_15 ? moment(row.date_n_15).format("YYYY-MM-DD") : "",
                        date_n_16: row.date_n_16 ? moment(row.date_n_16).format("YYYY-MM-DD") : "",
                        date_n_17: row.date_n_17 ? moment(row.date_n_17).format("YYYY-MM-DD") : "",
                        date_n_18: row.date_n_18 ? moment(row.date_n_18).format("YYYY-MM-DD") : "",
                        date_n_19: row.date_n_19 ? moment(row.date_n_19).format("YYYY-MM-DD") : "",
                        date_n_20: row.date_n_20 ? moment(row.date_n_20).format("YYYY-MM-DD") : "",
                        date_n_21: row.date_n_21 ? moment(row.date_n_21).format("YYYY-MM-DD") : "",
                        date_n_22: row.date_n_22 ? moment(row.date_n_22).format("YYYY-MM-DD") : "",
                        date_n_23: row.date_n_23 ? moment(row.date_n_23).format("YYYY-MM-DD") : "",
                        date_n_24: row.date_n_24 ? moment(row.date_n_24).format("YYYY-MM-DD") : "",
                        date_n_25: row.date_n_25 ? moment(row.date_n_25).format("YYYY-MM-DD") : "",
                        date_n_26: row.date_n_26 ? moment(row.date_n_26).format("YYYY-MM-DD") : "",
                        date_n_27: row.date_n_27 ? moment(row.date_n_27).format("YYYY-MM-DD") : "",
                        date_n_28: row.date_n_28 ? moment(row.date_n_28).format("YYYY-MM-DD") : "",
                        date_n_29: row.date_n_29 ? moment(row.date_n_29).format("YYYY-MM-DD") : "",
                        date_n_30: row.date_n_30 ? moment(row.date_n_30).format("YYYY-MM-DD") : "",
                        date_n_31: row.date_n_31 ? moment(row.date_n_31).format("YYYY-MM-DD") : "",
                        date_n_32: row.date_n_32 ? moment(row.date_n_32).format("YYYY-MM-DD") : "",
                        date_n_33: row.date_n_33 ? moment(row.date_n_33).format("YYYY-MM-DD") : "",
                        date_n_34: row.date_n_34 ? moment(row.date_n_34).format("YYYY-MM-DD") : "",
                        date_n_35: row.date_n_35 ? moment(row.date_n_35).format("YYYY-MM-DD") : "",
                        date_n_36: row.date_n_36 ? moment(row.date_n_36).format("YYYY-MM-DD") : "",
                        date_n_37: row.date_n_37 ? moment(row.date_n_37).format("YYYY-MM-DD") : "",
                        date_n_38: row.date_n_38 ? moment(row.date_n_38).format("YYYY-MM-DD") : "",
                        date_n_39: row.date_n_39 ? moment(row.date_n_39).format("YYYY-MM-DD") : "",
                        date_n_40: row.date_n_40 ? moment(row.date_n_40).format("YYYY-MM-DD") : "",
                        date_n_41: row.date_n_41 ? moment(row.date_n_41).format("YYYY-MM-DD") : "",
                        date_n_42: row.date_n_42 ? moment(row.date_n_42).format("YYYY-MM-DD") : "",
                        date_n_43: row.date_n_43 ? moment(row.date_n_43).format("YYYY-MM-DD") : "",
                        date_n_44: row.date_n_44 ? moment(row.date_n_44).format("YYYY-MM-DD") : "",
                        date_n_45: row.date_n_45 ? moment(row.date_n_45).format("YYYY-MM-DD") : "",
                        date_n_46: row.date_n_46 ? moment(row.date_n_46).format("YYYY-MM-DD") : "",
                        date_n_47: row.date_n_47 ? moment(row.date_n_47).format("YYYY-MM-DD") : "",
                        date_n_48: row.date_n_48 ? moment(row.date_n_48).format("YYYY-MM-DD") : "",
                        date_n_49: row.date_n_49 ? moment(row.date_n_49).format("YYYY-MM-DD") : "",
                        date_n_50: row.date_n_50 ? moment(row.date_n_50).format("YYYY-MM-DD") : "",
                        date_n_51: row.date_n_51 ? moment(row.date_n_51).format("YYYY-MM-DD") : "",
                        date_n_52: row.date_n_52 ? moment(row.date_n_52).format("YYYY-MM-DD") : "",
                        date_n_53: row.date_n_53 ? moment(row.date_n_53).format("YYYY-MM-DD") : "",
                        date_n_54: row.date_n_54 ? moment(row.date_n_54).format("YYYY-MM-DD") : "",
                        date_n_55: row.date_n_55 ? moment(row.date_n_55).format("YYYY-MM-DD") : "",
                        date_n_56: row.date_n_56 ? moment(row.date_n_56).format("YYYY-MM-DD") : "",
                        date_n_57: row.date_n_57 ? moment(row.date_n_57).format("YYYY-MM-DD") : "",
                        date_n_58: row.date_n_58 ? moment(row.date_n_58).format("YYYY-MM-DD") : "",
                        date_n_59: row.date_n_59 ? moment(row.date_n_59).format("YYYY-MM-DD") : "",
                        date_n_60: row.date_n_60 ? moment(row.date_n_60).format("YYYY-MM-DD") : "",
                        date_n_61: row.date_n_61 ? moment(row.date_n_61).format("YYYY-MM-DD") : "",
                        date_n_62: row.date_n_62 ? moment(row.date_n_62).format("YYYY-MM-DD") : "",
                        date_n_63: row.date_n_63 ? moment(row.date_n_63).format("YYYY-MM-DD") : "",
                        date_n_64: row.date_n_64 ? moment(row.date_n_64).format("YYYY-MM-DD") : "",
                        date_n_65: row.date_n_65 ? moment(row.date_n_65).format("YYYY-MM-DD") : "",
                        date_n_66: row.date_n_66 ? moment(row.date_n_66).format("YYYY-MM-DD") : "",
                        date_n_67: row.date_n_67 ? moment(row.date_n_67).format("YYYY-MM-DD") : "",
                        date_n_68: row.date_n_68 ? moment(row.date_n_68).format("YYYY-MM-DD") : "",
                        date_n_69: row.date_n_69 ? moment(row.date_n_69).format("YYYY-MM-DD") : "",
                        date_n_70: row.date_n_70 ? moment(row.date_n_70).format("YYYY-MM-DD") : "",
                        date_n_71: row.date_n_71 ? moment(row.date_n_71).format("YYYY-MM-DD") : "",
                        date_n_72: row.date_n_72 ? moment(row.date_n_72).format("YYYY-MM-DD") : "",
                        date_n_73: row.date_n_73 ? moment(row.date_n_73).format("YYYY-MM-DD") : "",
                        date_n_74: row.date_n_74 ? moment(row.date_n_74).format("YYYY-MM-DD") : "",
                        date_n_75: row.date_n_75 ? moment(row.date_n_75).format("YYYY-MM-DD") : "",
                        date_n_76: row.date_n_76 ? moment(row.date_n_76).format("YYYY-MM-DD") : "",
                        date_n_77: row.date_n_77 ? moment(row.date_n_77).format("YYYY-MM-DD") : "",
                        date_n_78: row.date_n_78 ? moment(row.date_n_78).format("YYYY-MM-DD") : "",
                        date_n_79: row.date_n_79 ? moment(row.date_n_79).format("YYYY-MM-DD") : "",
                        date_n_80: row.date_n_80 ? moment(row.date_n_80).format("YYYY-MM-DD") : "",
                        date_n_81: row.date_n_81 ? moment(row.date_n_81).format("YYYY-MM-DD") : "",
                        date_n_82: row.date_n_82 ? moment(row.date_n_82).format("YYYY-MM-DD") : "",
                        date_n_83: row.date_n_83 ? moment(row.date_n_83).format("YYYY-MM-DD") : "",
                        date_n_84: row.date_n_84 ? moment(row.date_n_84).format("YYYY-MM-DD") : "",
                        date_n_85: row.date_n_85 ? moment(row.date_n_85).format("YYYY-MM-DD") : "",
                        date_n_86: row.date_n_86 ? moment(row.date_n_86).format("YYYY-MM-DD") : "",
                        date_n_87: row.date_n_87 ? moment(row.date_n_87).format("YYYY-MM-DD") : "",
                        date_n_88: row.date_n_88 ? moment(row.date_n_88).format("YYYY-MM-DD") : "",
                        date_n_89: row.date_n_89 ? moment(row.date_n_89).format("YYYY-MM-DD") : "",
                        date_n_90: row.date_n_90 ? moment(row.date_n_90).format("YYYY-MM-DD") : "",
                        date_n_91: row.date_n_91 ? moment(row.date_n_91).format("YYYY-MM-DD") : "",
                        date_n_92: row.date_n_92 ? moment(row.date_n_92).format("YYYY-MM-DD") : "",
                        date_n_93: row.date_n_93 ? moment(row.date_n_93).format("YYYY-MM-DD") : "",
                        date_n_94: row.date_n_94 ? moment(row.date_n_94).format("YYYY-MM-DD") : "",
                        date_n_95: row.date_n_95 ? moment(row.date_n_95).format("YYYY-MM-DD") : "",
                        date_n_96: row.date_n_96 ? moment(row.date_n_96).format("YYYY-MM-DD") : "",
                        date_n_97: row.date_n_97 ? moment(row.date_n_97).format("YYYY-MM-DD") : "",
                        date_n_98: row.date_n_98 ? moment(row.date_n_98).format("YYYY-MM-DD") : "",
                        date_n_99: row.date_n_99 ? moment(row.date_n_99).format("YYYY-MM-DD") : "",

                      }))}>CSV</CSVLink>
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td >

                  {/* <Field 
              value={this.state.formData.primaryUserId}
                   name="primaryUserId"
                   onChange={this.handlePrimaryUserIdChange}
                   options={this.props.masterReducer.users_select}
                        component={RenderSelectUsers} /> */}

                  <div className="form-inline">


                    <FormControl style={{ width: "160px" }}>
                      {this.props.users_select && this.props.user_details ? <Autocomplete
                        id="combo-box-demo"
                        // options={this.props.users_select}
                        options={this.state.ownshop_contract ? this.props.users.filter(a => a.amSectionId === this.props.contract_follow_list_form.section_id).map((value) => ({
                          value: value.id,
                          label: value.userNameAll
                        })) : this.props.users_select}
                        getOptionLabel={(option) => option.label}
                        // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
                        // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                        style={{ width: 150 }}
                        name="contract_user_id"
                        value={this.props.contract_follow_list_form.contract_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.contract_user_id)[0] : null}
                        onChange={this.change_contract_user_id}
                        size="small"
                        renderInput={(params) => <TextField variant="standard"
                          {...params} label="契約担当を選択" />}

                      /> : ""}
                    </FormControl>
                    <FormControlLabel checked={this.state.ownshop_contract} onChange={this.change_ownshop_contract} control={<Checkbox name="checkedC" />} label="自店のみ" />











                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}
                      {/* <Grid
      justify="space-between" // Add it here :)
      item
      xs={6}
    > */}
                      {/* <div style={{ float: "left", width: "500px", }}> */}

                        <TextField
                            id="contraction_date_start"
                            label="契約日"
                            type="date"
                            name="contraction_date_start"
                            size="small"
                            onChange={this.handleChange}
                            value={moment(this.props.contract_follow_list_form.contraction_date_start).format("YYYY-MM-DD")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            style={{marginLeft:20}}
                        />



                        {/* <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="date-picker-inline1"
                          label="契約日"
                          //   showTodayButton={true}
                          style={{ float: "left", marginTop: "0" }}
                          autoOk={true}
                          InputLabelProps={{ shrink: !!this.props.contract_follow_list_form.contraction_date_start }}
                          // error={true}
                          // helperText="契約日を選択してください"
                          //   errorMessages={['契約日を選択してください']}
                          name="contraction_date_start"
                          value={this.props.contract_follow_list_form.contraction_date_start}
                          // value={new Date()}
                          // value={this.props.estimates.contractDate}
                          onChange={this.change_contraction_date_start}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          //   minDateMessage="契約日を選択してください"
                          // minDate={new Date("2000-01-01")}
                          invalidDateMessage={"日付の形式が違います"}
                        /> */}
                        {/* </Grid> */}
                        {/* <Grid
      justify="space-between" // Add it here :)
      item
      xs={6}
    > */}
                        <span style={{ margin: "15px" }}>～</span>
                          <TextField
                              id="contraction_date_end"
                              label="契約日"
                              type="date"
                              name="contraction_date_end"
                              size="small"
                              onChange={this.handleChange}
                              value={moment(this.props.contract_follow_list_form.contraction_date_end).format("YYYY-MM-DD")}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              variant="standard"
                              style={{marginRight:20}}
                          />
                        {/* <KeyboardDatePicker

                          disableToolbar
                          variant="inline"
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="date-picker-inline2"
                          label="契約日"
                          style={{ marginLeft: "20px", marginTop: "0" }}
                          autoOk={true}
                          InputLabelProps={{ shrink: !!this.props.contract_follow_list_form.contraction_date_end }}
                          // error={true}
                          // helperText="申込日を選択してください"
                          //   showTodayButton={true}
                          name="contraction_date_end"
                          // value={formData.applicationDate}
                          value={this.props.contract_follow_list_form.contraction_date_end}
                          onChange={this.change_contraction_date_end}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          invalidDateMessage={"日付の形式が違います"}
                        /> */}
                      {/* </div> */}
                      {/* </Grid> */}
                    {/* </MuiPickersUtilsProvider> */}

                    <FormControlLabel checked={this.props.contract_follow_list_form.search_room} onChange={this.change_search_room} control={<Checkbox name="search_room" />} label="部屋" />

                    <FormControlLabel checked={this.props.contract_follow_list_form.search_parking} onChange={this.change_search_room} control={<Checkbox name="search_parking" />} label="駐車場" />

                    <FormControlLabel checked={this.props.contract_follow_list_form.global_parking} onChange={this.change_search_room} control={<Checkbox name="global_parking" />} label="GP" />
                    
                    <FormControlLabel checked={this.props.contract_follow_list_form.cloudsign} onChange={this.change_search_room} control={<Checkbox name="cloudsign" />} label="電子契約" />
                    
                    <FormControlLabel checked={this.props.contract_follow_list_form.is_important_contract} onChange={this.change_search_room} control={<Checkbox name="is_important_contract" />} label="重要契約" />

                    <Button variant="contained" onClick={this.update} color="primary" style={{ margin: 5 }}>
                      更新
                    </Button>




                    {/* <FormControl style={{ marginLeft: "10px", width: "100px" }}>
                      <InputLabel id="demo-simple-select-label">並び順</InputLabel>
                      <MaterialSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={1}
                        // value={age}
                        onChange={this.sort}
                      >
                        <MenuItem value={1}>最新対応順</MenuItem>
                        <MenuItem value={2}>反響日順</MenuItem>
                        <MenuItem value={3}>ランク順</MenuItem>
                      </MaterialSelect>
                    </FormControl> */}

                    {/* <button
                      type="submit"
                      className="btn btn-primary ml-3 ml-auto"
                    >検索</button> */}


                    <Chip label={this.props.contract_follow_search_list.length + "件"} style={{ margin: 10, fontSize: 14 }} variant="outlined" />


                  </div>
                </td>
                <td style={{ 'paddingRight': 40 }}>
                  <FormControlLabel checked={this.props.contract_follow_easy_mode} onChange={() => this.props.change_contract_follow_easy_mode(!this.props.contract_follow_easy_mode)} control={<Checkbox name="contract_follow_easy_mode" />} label="かんたん登録" />
                  {this.props.contract_follow_easy_mode ?
                    <FormControl variant="outlined" size="small" style={{ marginRight: 5, width: 90 }}>
                      <InputLabel id="demo-simple-select-label">状況</InputLabel>
                      <MaterialSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="progress"
                        value={this.props.contract_follow_easy_mode_progress}
                        // onChange={this.handleOnChange}
                        onChange={(e) => this.props.change_contract_follow_easy_mode_progress(e.target.value)}
                      >
                        <MenuItem value={2}>完了</MenuItem>
                        <MenuItem value={4}>不使用</MenuItem>
                      </MaterialSelect>
                    </FormControl>
                    // <RadioGroup row aria-label="position" name="progress" onChange={() => this.props.change_contract_follow_easy_mode_progress(!this.props.contract_follow_easy_mode_progress)} value={this.props.contract_follow_easy_mode_progress} >
                    //   <FormControlLabel control={<Radio value={0} style={{}} />} label="完了" />
                    //   <FormControlLabel control={<Radio value={1} style={{}} />} label="不使用" />
                    // </RadioGroup>
                    : ""}
                  <FormControlLabel checked={this.props.contract_follow_end} onChange={this.props.set_end_check} control={<Checkbox name="search_parking" />} label="完了登録" />
                  {this.props.contract_follow_end ? <Button variant="contained" onClick={this.update_contract_follow_end_bulk} color="secondary" style={{ margin: 5 }}>
                    完了登録
                  </Button> : ""}


                  <IconButton
                    aria-label="InsertInvitationIcon"
                    onClick={this.togglecalender.bind(this)}
                    size="large">
                    <InsertInvitationIcon />
                  </IconButton>

                  <IconButton aria-label="SettingsIcon" onClick={this.toggle_select_comp} size="large">
                    <SettingsIcon />
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </table>


        </form >


        < Modal isOpen={this.state.modalcalender} toggle={this.togglecalender} size="xxl" fade={false} >

          <ModalHeader toggle={this.togglecalender}>予定</ModalHeader>
          <ModalBody>
            <div style={{ height: window.innerHeight - 200, background: 'white' }}>
              <FollowDispCalendar section_id={this.props.contract_follow_list_form.section_id} /></div>
          </ModalBody>
          <ModalFooter>
            <Button variant="contained" onClick={this.togglecalender} color="primary" style={{ float: "right", margin: 5 }}>
              閉じる
            </Button>

          </ModalFooter>
        </Modal >



        <Modal isOpen={this.state.modal_select_comp} toggle={this.toggle_select_comp} size="xl" fade={false}>
          <ModalHeader toggle={this.toggle_select_comp}><b style={{ fontSize: "20px" }}>選択</b></ModalHeader>
          <Grid container spacing={2} justifyContent="center" alignItems="flex-start" >
            <Grid item >

              <Card style={{ width: 400, margin: 10 }} >
                <CardHeader
                  title="表示"
                  action={
                    < Button aria-describedby="save" disabled={this.props.rent_m_contract_follow_settings_save} variant="contained" color="primary" onClick={this.save}>保存</Button>

                  }
                // subheader="September 14, 2016"
                />
                <Popover
                  id="save"
                  open={this.props.rent_m_contract_follow_settings_save_popover}
                  anchorEl={this.state.anchorEl}
                  onClose={this.saved}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div style={{ margin: 10 }}>保存されました</div>
                </Popover>
                <CardContent>
                  <SelectCompo />
                </CardContent>
              </Card >
            </Grid>
            <Grid item>
              <Card style={{ width: 400, margin: 10 }} >
                <CardHeader
                  title="非表示"
                // subheader="September 14, 2016"
                />
                <CardContent>
                  <UnSelectCompo />
                </CardContent>
              </Card >
            </Grid>
          </Grid>
        </Modal>

        <SimpleDialog open={this.props.contract_follow_end_bulk_open} onClose={this.props.change_contract_follow_end_bulk_open} />


      </div >
    );
  }
}




//<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>
//    <ResponseInsertComponent
//        toggle={this.toggle} onSubmit={this.props.customerUpdate}
//    />
//</Modal>


// ResponseListSearch = connect(
//   ({ masterReducer, contractfollow }) => ({ masterReducer, contractfollow }),
//   // state => ({
//   //   mapsearch: state.mapsearchReducer,

//   // }),
//   {
//     customerNewDispSet,
//     change_customeredit,
//     // change_matching,

//     get_contract_follow_list,
//     change_response_add_disp,
//     change_contract_follow_list_form,
//     search_contract_follow_list,
//     update_rent_m_contract_follow_settings,
//     change_rent_m_contract_follow_settings_save,

//   }

// )(ResponseListSearch)



// ResponseListSearch = reduxForm({
//   form: 'formset', // a unique identifier for this form
//   onSubmit: submit
//   //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ResponseListSearch)

// export default withRouter(ResponseListSearch)



const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    user_details: state.masterReducer.user_details,
    // csv_loading: state.Adjustment.csv_loading,
    // search_list: state.Adjustment.search_list,
    // seisan_follow_list: state.Adjustment.seisan_follow_list,
    // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
    // seisan_follow_search: state.Adjustment.seisan_follow_search,
    // tantou: state.Adjustment.tantou,
    // rent_m_contract_follows: state.ContractFollow.rent_m_contract_follows,
    rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    contract_follow_list_form: state.contractfollow.contract_follow_list_form,
    rent_m_contract_follow_settings_save_popover: state.contractfollow.rent_m_contract_follow_settings_save_popover,
    rent_m_contract_follow_settings_save: state.contractfollow.rent_m_contract_follow_settings_save,
    contract_follow_search_list: state.contractfollow.contract_follow_search_list,
    contract_follow_end: state.contractfollow.contract_follow_end,
    contract_follow_easy_mode: state.contractfollow.contract_follow_easy_mode,
    contract_follow_easy_mode_progress: state.contractfollow.contract_follow_easy_mode_progress,
    contract_follow_end_bulk_open: state.contractfollow.contract_follow_end_bulk_open,
    rent_m_agents: state.contractfollow.rent_m_agents,
    kanricorps: state.masterReducer.kanricorps,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_rent_m_contract_follows_disp(state) {
      dispatch(ContractFollow.change_rent_m_contract_follows_disp(state))
    },
    change_rent_m_contract_follows_none(state) {
      dispatch(ContractFollow.change_rent_m_contract_follows_none(state))
    },
    change_rent_m_contract_follow_settings(state) {
      dispatch(ContractFollow.change_rent_m_contract_follow_settings(state))
    },
    change_contract_follow_list_form(state) {
      dispatch(ContractFollow.change_contract_follow_list_form(state))
    },
    get_contract_follow_list(state) {
      dispatch(ContractFollow.get_contract_follow_list(state))
    },
    search_contract_follow_list(state) {
      dispatch(ContractFollow.search_contract_follow_list(state))
    },
    update_rent_m_contract_follow_settings(state) {
      dispatch(ContractFollow.update_rent_m_contract_follow_settings(state))
    },
    set_end_check(state) {
      dispatch(ContractFollow.set_end_check(state))
    },
    change_contract_follow_easy_mode(state) {
      dispatch(ContractFollow.change_contract_follow_easy_mode(state))
    },
    change_contract_follow_easy_mode_progress(state) {
      dispatch(ContractFollow.change_contract_follow_easy_mode_progress(state))
    },
    set_contract_follow_end_bulk(state) {
      dispatch(ContractFollow.set_contract_follow_end_bulk(state))
    },
    change_contract_follow_end_bulk_open(state) {
      dispatch(ContractFollow.change_contract_follow_end_bulk_open(state))
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);
