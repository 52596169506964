import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as RoomList from './Saga/RoomList';

import '../../Css/GridRooms.css';

import { Modal,  ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Grid from './Grid';
import RoomListSearch from './RoomListSearch';
import EstimateComponent from '../Estimates/Index';
import RoomDocComponent from '../Rooms/RoomDocComponent';
let timer: any;

interface Props {

    rent_response_id: any,
  
    get_search_rooms: any,
    search_rooms: any,
    mail: any,
    visit: any,
    response_search_rooms: any,
}


interface State {
    // columns:any,
    modalsheet: boolean,
    modalestimates: boolean,
    tatemonoId: any,
    roomId: any,
    tatemonoName: any,
    roomName: any,
    refresh: boolean
    // state types
}





class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {

            modalsheet: false,
            modalestimates: false,
            tatemonoId: null,
            roomId: null,
            tatemonoName: null,
            roomName: null,
            refresh: false
        }




        this.handleSubmit = this.handleSubmit.bind(this);


        this.togglesheet = this.togglesheet.bind(this);
        this.toggleestimates = this.toggleestimates.bind(this);
        this.estimate = this.estimate.bind(this);
        this.sheet = this.sheet.bind(this);


        this.props.get_search_rooms(this.props.rent_response_id);
    }



    sheet(tatemonoId: any, roomId: any, tatemonoName: any, roomName: any) {
        this.setState({ tatemonoId: tatemonoId, roomId: roomId, tatemonoName: tatemonoName, roomName: roomName })


        this.togglesheet()
    }
    togglesheet = () => {

        this.setState(prevState => ({
            modalsheet: !prevState.modalsheet,

        }));
    }

    estimate(tatemonoId: any, roomId: any) {
        this.setState({ tatemonoId: tatemonoId, roomId: roomId })


        this.toggleestimates()
    }


    toggleestimates = () => {

        this.setState(prevState => ({
            modalestimates: !prevState.modalestimates,

        }));

    }
    //     submit(state:any){
    //         console.log(state)
    // this.props.get_response_rooms(state)
    //     }
    handleSubmit(state: any) {
        console.log(state)

        clearTimeout(timer);


        timer = setTimeout(function (this: any) {

            state.rentResponseId = this.props.rent_response_id;
            this.props.get_search_rooms(state);

        }.bind(this), 400);




        // this.props.actions.get_response_rooms(state);

    }





    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (

            <div className="item-outer-outer" style={{ padding: "20px" }}>
                <div className="float-left" >



                    <div className="search_menu" style={{ position: "absolute", zIndex: 100, height: window.innerHeight - 140, overflowY: "scroll" }}>

                        <RoomListSearch rent_response_id={this.props.rent_response_id} onSubmit={this.handleSubmit} onChange={this.handleSubmit} />

                    </div>

                </div>
                <div style={{ marginLeft: "270px", height: window.innerHeight - 140 }}>
                    <Grid
                        togglesheet={this.togglesheet}
                        estimate={this.estimate}
                        sheet={this.sheet}
                        mail={this.props.mail}
                        visit={this.props.visit}
                        // delete_introduction={this.delete_introduction}
                        // selectTatemono={this.selectTatemono}
                        height={window.innerHeight - 140}
                        width={window.innerWidth - 420} />

                </div>


                <Modal isOpen={this.state.modalsheet} toggle={this.togglesheet} size="xxl" zIndex={3000} fade={false}>
                    <ModalHeader toggle={this.togglesheet}><b style={{ fontSize: "20px" }}>{this.state.tatemonoName} {this.state.roomName}</b>
                    </ModalHeader>

                    < RoomDocComponent tatemonoId={this.state.tatemonoId} roomId={this.state.roomId} />

                </Modal>


                <Modal isOpen={this.state.modalestimates} zIndex={3000} size="xxl" fade={false}>

                    <ModalHeader toggle={this.toggleestimates}>見積作成</ModalHeader>
                    <ModalBody style={{ background: "#9fb6cc" }}>
                        <EstimateComponent rentEstimateId={0} tatemonoId={this.state.tatemonoId} roomId={this.state.roomId} />
                    </ModalBody>
                    <ModalFooter>
                        {/* <button className="btn btn btn-danger" style={{margin:"5px"}} onClick={this.delete_auto_send}>除外する</button> */}
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggleestimates}>閉じる</button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        // rooms: state.roomsReducer.rooms,
        // introductions: state.responsesReducer.introductions,

        response_search_rooms: state.roomlist.response_search_rooms,
        search_rooms: state.roomlist.search_rooms,
        // rent_response_id: state.responsesReducer.responsedetail.id,
        // contacts_loading: state.customerContactsReducer.contacts_loading
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_search_rooms(state: any) {
            dispatch(RoomList.get_search_rooms(state))
        },





    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);