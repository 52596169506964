import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import './Css/Grid.css'

import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import { Table, Column, Cell } from 'fixed-data-table-2';
import CardContent from '@mui/material/CardContent';

import 'fixed-data-table-2/dist/fixed-data-table.css';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MaterialUiSelect from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MaskedInput from 'react-text-mask';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ControlPointIcon from '@mui/icons-material/ControlPoint';


// import CountComing from './Gadgets/CountComing';
// import ResponseCount from './Gadgets/ResponseCount/Index';



import Grid from '@mui/material/Grid';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';




const phoneNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  
  
  const mobileNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  
  const postNumberMask = [
  
    /[0-9]/,
    /\d/,
    /\d/,
  
    "-",
  
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  
  ];


  function TelMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
  
      <MaskedInput
        {...other}
        // ref={inputRef}
        mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" || !props.value ? mobileNumberMask : phoneNumberMask}
        placeholderChar={'\u2000'}
      // showMask
      />
    );
  }

class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false,
            columnWidths: {
                name: 100,
                department: 90,
                position: 90,
                tel: 120,
                fax: 120,
                mail: 120,
                rent_m_corporate_office_id: 90,
                remarks: 200,
            },
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
        this.RowClick = this.RowClick.bind(this);
        
    }


    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
          columnWidths: {
            ...columnWidths,
            [columnKey]: newColumnWidth,
          },
        }));
    }

    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }


    

    onChange(state) {
        // console.log(this.props.claso_blog_places)
        let rent_corporate_staffs = this.props.rent_corporate_staffs

        if (state.target.name === "post_card" || state.target.name === "in_office") {
            rent_corporate_staffs[state.target.name] = state.target.checked ? 1 : 0
        }else{
            rent_corporate_staffs[state.target.name] = state.target.value
        }

        this.props.change_rent_corporate_staffs(rent_corporate_staffs)
        this.setState({
            change: !this.state.change
        });

    }

    handleOpen(id) {
        if (id) {
            this.props.set_rent_corporate_staffs(id)
        } else {
            // 新規
            let rent_corporate_staffs = { id: '', rent_m_corporate_id: this.props.rent_m_corporates.id, rent_m_corporate_office_id: 0, post_card: 0, in_office: 0, remarks: '', name1:"", name2:'', department:'', position:'', tel:'', fax:'', mail:'', remarks:'', is_deleted: 0, created_at: '', created_user_id: '' }
            this.props.change_rent_corporate_staffs(rent_corporate_staffs)
        }

        this.setState({ "modal": true })
    }

    handleUpdate(id, delflg) {

        let rent_corporate_staffs = this.props.rent_corporate_staffs
        rent_corporate_staffs["is_deleted"] = delflg
        this.props.change_rent_corporate_staffs(rent_corporate_staffs)

        if (id) {
            this.props.update_rent_corporate_staffs()
        } else {
            this.props.insert_rent_corporate_staffs()
        }

        this.setState({
            modal: false,
            modal_del: false,
            change: !this.state.change
        });

    }

    RowClick(event,index){

        this.props.set_rent_corporate_staffs(this.props.rent_corporate_staffs_list[index].id)

        this.setState({ "modal": true })

    }

    _rowClassNameGetter(index){
        
        if(this.props.rent_corporate_staffs_list && this.props.rent_corporate_staffs_list[index]) {
        //   return this.props.owner_eigyou[index].type == 1 ? "line" : 
        //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
        //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
            return "normal"
        }else{
          return ""
        }
    }

    render() {

        return (
            <div>
                <div style={{ float: "left" }} ><PeopleAltIcon style={{ color: "#5cb85c" }}></PeopleAltIcon> 企業情報</div>
                <Button style={{ float: "right" }} size="small" variant="contained" color="primary" startIcon={<ControlPointIcon />} onClick={(e) => { this.handleOpen("") }}>新規</Button>
                <div style={{clear:"both"}}></div>
                <Table 
                    className="gridCorporateNormalList"
                    rowHeight={34} 
                    rowsCount={this.props.rent_corporate_staffs_list.length} 
                    height={200} 
                    headerHeight={30} 
                    width={(window.innerWidth / 2) - 75}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    onRowClick={this.RowClick}
                    rowClassNameGetter={this._rowClassNameGetter}
                >
                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} onClick={(e) => { this.handleOpen(this.props.rent_corporate_staffs_list[rowIndex].id); }} aria-label="edit" size="small"><EditIcon /></IconButton></Cell>)} width={40} /> */}
                    <Column width={45} isResizable={true} columnKey="post_card" header={<Cell>年賀状</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].post_card == 1 ? <CheckIcon />:""}</Cell>)}/>
                    <Column width={45} isResizable={true} columnKey="in_office" header={<Cell>在職</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].in_office == 1 ? <CheckIcon />:""}</Cell>)}/>
                    <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<Cell>氏名</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].name1} {this.props.rent_corporate_staffs_list[rowIndex].name2}</Cell>)}/>
                    <Column width={this.state.columnWidths.department} isResizable={true} columnKey="department" header={<Cell>部署</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].department}</Cell>)}/>
                    <Column width={this.state.columnWidths.position} isResizable={true} columnKey="position" header={<Cell>役職</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].position}</Cell>)}/>
                    <Column width={this.state.columnWidths.tel} isResizable={true} columnKey="tel" header={<Cell>TEL</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].tel}</Cell>)}/>
                    <Column width={this.state.columnWidths.fax} isResizable={true} columnKey="fax" header={<Cell>FAX</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].fax}</Cell>)}/>
                    <Column width={this.state.columnWidths.mail} isResizable={true} columnKey="mail" header={<Cell>メール</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].mail}</Cell>)}/>
                    <Column width={this.state.columnWidths.rent_m_corporate_office_id} isResizable={true} columnKey="rent_m_corporate_office_id" header={<Cell>拠点</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].rent_m_corporate_office_name}</Cell>)}/>
                    <Column width={this.state.columnWidths.remarks} isResizable={true} columnKey="remarks" header={<Cell>備考</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_staffs_list[rowIndex].remarks}</Cell>)}/>
                </Table>

                <Modal isOpen={this.state.modal} fade={false} >
                        <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
                            <span>企業情報</span>
                            {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
                        </ModalHeader>
                        

                        <ModalBody>


                        <Card>
                            <CardContent>
                        <Grid container spacing={1}>
                            <Grid justifyContent="space-between" item xs={12}>
                                <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_staffs.post_card == 1 ? true : false} onChange={this.onChange} name="post_card" />} label="年賀" />
                                <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_staffs.in_office == 1 ? true : false} onChange={this.onChange} name="in_office" />} label="在籍" />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField name="name1" label="姓" onChange={this.onChange} value={this.props.rent_corporate_staffs.name1} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField name="name2" label="名" onChange={this.onChange} value={this.props.rent_corporate_staffs.name2} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField name="department" label="部署" onChange={this.onChange} value={this.props.rent_corporate_staffs.department} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField name="position" label="役職" onChange={this.onChange} value={this.props.rent_corporate_staffs.position} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                            <TextField
                                variant="outlined"
                                name="tel"
                                label="TEL"
                                type="tel"
                                value={this.props.rent_corporate_staffs.tel}
                                onChange={this.onChange}
                                id="formatted-numberformat-input"
                                InputProps={{
                                    inputComponent: TelMaskCustom,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />


                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField
                                    variant="outlined"
                                    name="fax"
                                    label="FAX"
                                    type="fax"
                                    value={this.props.rent_corporate_staffs.fax}
                                    onChange={this.onChange}
                                    fullWidth

                                    InputProps={{
                                        inputComponent: TelMaskCustom,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
       
                                />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={12}>
                                <TextField name="mail" label="メール" onChange={this.onChange} value={this.props.rent_corporate_staffs.mail} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={12}>
                                <FormControl variant="outlined" className={{}}>
                                    <InputLabel id="rank-outlined-label">拠点</InputLabel>
                                    <MaterialUiSelect
                                        id="rent_m_corporate_office_id" style={{ width: 150 }}
                                        value={this.props.rent_corporate_staffs.rent_m_corporate_office_id}
                                        name="rent_m_corporate_office_id"
                                        onChange={this.onChange}
                                        label="拠点"
                                    >
                                        <MenuItem value={0}>-</MenuItem>
                                        {this.props.rent_corporate_offices_list.map(function (value) {
                                            return <MenuItem value={value.id}>{value.name}</MenuItem>
                                        })}

                                    </MaterialUiSelect>
                                </FormControl>
                            </Grid>
                            <Grid justifyContent="space-between" item xs={12}>

                                <TextField
                                    fullWidth
                                    name="remarks"
                                    label="備考"
                                    multiline
                                    rows={4}
                                    defaultValue=""
                                    variant="outlined"
                                    value={this.props.rent_corporate_staffs.remarks}
                                    onChange={this.onChange}
                                        />
                            </Grid>
                            

                        </Grid>



                        </CardContent>

                        </Card>




                        </ModalBody>
                        

                        <ModalFooter>

                        <Button variant="contained" color="primary" onClick={() => this.handleUpdate(this.props.rent_corporate_staffs.id, 0)} style={{ backgroundColor: "#5cb85c", marginRight: 5 }}>保存</Button>
                        {this.props.rent_corporate_staffs.id ? <Button variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button> : ""}

                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.modal_del} fade={false} className="modal-dialog-centered">
                    <ModalBody>
                        <div>本当に削除してよろしいですか？</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="secondary" onClick={() => this.handleUpdate(this.props.rent_corporate_staffs.id, 1)}>削除</Button>
                        <Button variant="contained" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button>
                    </ModalFooter>

                </Modal>

            </div>
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_corporate_staffs_list: state.corporation.rent_corporate_staffs_list,
        rent_corporate_staffs: state.corporation.rent_corporate_staffs,
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_corporate_offices_list: state.corporation.rent_corporate_offices_list,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_corporate_staffs(state) {
            dispatch(Corporation.change_rent_corporate_staffs(state))
        },
        set_rent_corporate_staffs(state) {
            dispatch(Corporation.set_rent_corporate_staffs(state))
        },
        insert_rent_corporate_staffs(state) {
            dispatch(Corporation.insert_rent_corporate_staffs(state))
        },
        update_rent_corporate_staffs(state) {
            dispatch(Corporation.update_rent_corporate_staffs(state))
        },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);





