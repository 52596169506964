
const authentication = require('../../../react-azure-adb2c2').default;


export const detail = (value: any) => {

    // let url = `http://localhost:5000/v1/rent/line/template/details/detail/` + value;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/template/details/detail/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const insert = (values: any) => {
    // alert(JSON.stringify(values))
// console.log(values)
    // const url = `http://localhost:5000/v1/rent/line/template/details/insert/`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/template/details/insert/`;


    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export function detail_delete(id: number, no: number) {

    const token = authentication.getAccessToken();

    // const url = `http://localhost:5000/v1/rent/line/template/details/delete/` + id + `/` + no;
//   alert(url)
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/template/details/delete/` + id + `/` + no;
  
    // alert(url)
    return fetch(url, {
      method: 'get',
  
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
      .then(res => res.json())
      .then(payload => ({ payload }))
      .catch(error => ({ error }));
  
  }

