import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as ResponseList from './Saga/ResponseList';
import * as Responses from '../ResponseDetail/Saga/Responses';
import * as Customers from './../Customers/Saga/Customers';
import moment from 'moment';
import '../../Css/Grid.css';
import EventListener from 'react-event-listener';
import LoadingComponent from '../Layout/LoadingComponent';
import { ModalHeader, Button, ModalBody, ModalFooter, Modal } from 'reactstrap';
// import { ModalHeader,Modal,ModalFooter,Button,ModalBody} from 'reactstrap';
import MailDetail from "../Mail/MailDetailComponent";

import ResponseMatchingGridComponent from "./ResponseMatchingGridComponent";
import ResponseMatchingSelectGridComponent from "./ResponseMatchingSelectGridComponent";

import ResponseLineMatchingGridComponent from "./ResponseLineMatchingGridComponent";

import ResponseComponent from "../ResponseDetail/ResponseComponent";
import AutoSend from "../AutoSend/Index";
// import Modal from "react-modal";
import * as Line from '../Line/Saga/Line';





// const appElement = document.getElementById('app');


// Modal.setAppElement("#app");

// const customStyles = {
//     overlay: {
//       zIndex: "1000",
//       backgroundColor: "rgba(0, 0, 0, 0.5)"
//     },
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       width:900,
//     padding:0,
//       marginRight: "-50%",

//       transform: "translate(-50%, -50%)"
//     }
//   };

// const style = {
//     border: "solid #000",
//     height:"200"
// }
//<span type="button" className="btn btn-primary" target="_blank" href={`/tradedetails/` + props.row.id}>詳細</span>
const DetailLinkFormatter = ({ value, ...props }) => {

    if (props.row.flg === 3) {
        // if(props.row.request===1){
        return "";
        //     }else{

        // }
        // return "";
    } else {
        return value;
    }
    //return <ProgressBar now={value} label={`${value}%`} />;
};
// const ColorFormatter = ({ value }) => {
//     return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px" ,fontWeight: "bold" }}>担当者</span> {value}</div >;

// };

const Col1Formatter = ({ value, ...props }) => {
    // console.log(props.row)
    if (props.row.flg === 1) {
        if (props.row.request === 1) {
            // if(value==1){
            //     return <div style={{ textAlign: "left" }}><h5><span class="badge badge-primary">アパマン</span></h5> </div>
            // }else if(value==14){
            // return <div style={{ textAlign: "left" }}><h5><span class="badge badge-success">スーモ</span></h5> </div>co
            const mediaArray = props.row.media.split(",");
            return <span>

                {mediaArray.filter((v) => v === "1").length > 0 ? <span className="badge badge-primary">アパマン</span> : ""}
                {mediaArray.filter((v) => v === "14").length > 0 ? <span className="badge badge-success">スーモ</span> : ""}
                {mediaArray.filter((v) => v === "16").length > 0 ? <span className="badge badge-warning">ホームズ</span> : ""}
                {mediaArray.filter((v) => v === "21").length > 0 ? <span className="badge badge-danger">アットホーム</span> : ""}
                {mediaArray.filter((v) => v === "29").length > 0 ? <span className="badge badge-light">すまいズ</span> : ""}
                {mediaArray.filter((v) => v === "2").length > 0 ? <span className="badge badge-info">CLASO.</span> : ""}
                {mediaArray.filter((v) => v === "30").length > 0 ? <span className="badge badge-info">高松賃貸.net</span> : ""}
                {mediaArray.filter((v) => v === "36").length > 0 ? <span className="badge badge-info">スモッカ</span> : ""}
            </span>;
        } else if (props.row.request === 2) {
            return <span style={{ color: 'green', fontSize: 20, padding: "1px" }} className="fab fa-line " />
        } else {
            let rank = "";
            if (props.row.rank === 1) {
                rank = <span className="badge badge-danger">S</span>
            } else if (props.row.rank === 2) {
                rank = <span className="badge badge-warning">A</span>
            } else if (props.row.rank === 3) {
                rank = <span className="badge badge-primary">B</span>
            } else if (props.row.rank === 4) {
                rank = <span className="badge badge-light">C</span>
            }


            let shop = "";
            if (props.row.section_id === 1) {
                shop = <span className="badge badge-success">番</span>
            } else if (props.row.section_id === 2) {
                shop = <span className="badge badge-warning">レ</span>
            } else if (props.row.section_id === 3) {
                shop = <span className="badge badge-danger">駅</span>
            } else if (props.row.section_id === 4) {
                shop = <span className="badge badge-dark">東</span>
            } else if (props.row.section_id === 6) {
                shop = <span className="badge badge-light">公</span>
            } else if (props.row.section_id === 17) {
                shop = <span className="badge badge-info">サ</span>
            } else if (props.row.section_id === 47) {
                shop = <span className="badge badge-secondary">通</span>
            }


            // return <div style={{ textAlign: "left" }}><span style={{ fontSize: "16px" }}>{shop} {rank} {value > 0 ? <span class="badge badge-success"> <span class="fas fa-shoe-prints"> {value}</span></span> : <span class="badge badge-info"> <span class="fas fa-envelope">{props.row.col8}</span></span>}</span><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>反響日</span>{props.row.col9 ? props.row.col9 : ""}</div >;

            return <div style={{ textAlign: "left" }}><span style={{ fontSize: "16px" }}>{shop} {rank} {value > 0 ? <span className="badge badge-success"> <span className="fas fa-shoe-prints"> {value}</span></span> : <span className="badge badge-info"> <span className="fas fa-envelope">{props.row.col8}</span></span>}</span><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>反響日</span>{props.row.date ? moment(new Date(props.row.date)).format("YYYY年MM月DD日") : ""}</div >;
        }
    } else if (props.row.flg === 2) {
        if (props.row.request === 1) {
            const time = props.row.date ? moment(new Date(props.row.date).setTime(new Date(props.row.date).getTime() - 1000 * 60 * 60 * 9)) : ""
            // return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>反響日</span>{value ? value : ""}</div >;
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>反響日</span>{time ? time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm") : ""}</div >;
        } else if (props.row.request === 2) {
            // return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>受信日時</span>{value ? value : ""}</div >;
            const time = props.row.date ? moment(new Date(props.row.date).setTime(new Date(props.row.date).getTime() - 1000 * 60 * 60 * 9)) : ""
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>受信日時</span>{time ? time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm") : ""}</div >;
        } else {
            let status = "";
            if (props.row.status === 1) {
                status = <span className="badge badge-danger">メール受信</span>
            } else if (props.row.status === 2) {
                status = <span className="badge badge-success">メール送信</span>
            } else if (props.row.status === 3) {
                status = <span className="badge badge-info">自動送信</span>
            } else if (props.row.status === 4) {
                status = <span className="badge badge-secondary">既読</span>
            } else if (props.row.status === 5) {
                status = <span className="badge badge-secondary">反響受信</span>
            } else if (props.row.status === 6) {
                status = <span className="badge badge-secondary">対応</span>
            } else if (props.row.status === 7) {
                status = <span className="badge badge-danger">マッチング物件なし</span>
            } else if (props.row.status === 12) {
                status = <span className="badge badge-danger">LINE受信</span>
            } else if (props.row.status === 13) {
                status = <span className="badge badge-success">LINE送信</span>
            } else {
                status = <span className="badge badge-secondary">最終対応</span>
            }


            // return <div style={{ textAlign: "left" }}><span style={{ fontSize: "16px" }}>{status} </span><span >{value ? value : ""}</span></div >
            const time = props.row.last_response_date ? moment(new Date(props.row.last_response_date).setTime(new Date(props.row.last_response_date).getTime() - 1000 * 60 * 60 * 9)) : ""
            return <div style={{ textAlign: "left" }}><span style={{ fontSize: "16px" }}>{status} </span><span >{time ? time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm") : ""}</span></div >
        }

    } else if (props.row.flg === 3) {
        if (props.row.request === 1) {
            if (props.row.shops !== null) {
                const shopArray = props.row.shops.split(",");
                return <span>

                    {shopArray.filter((v) => v === "1").length > 0 ? <span className="badge badge-success">番</span> : ""}
                    {shopArray.filter((v) => v === "2").length > 0 ? <span className="badge badge-warning">レ</span> : ""}
                    {shopArray.filter((v) => v === "3").length > 0 ? <span className="badge badge-danger">駅</span> : ""}
                    {shopArray.filter((v) => v === "4").length > 0 ? <span className="badge badge-dark">東</span> : ""}
                    {shopArray.filter((v) => v === "6").length > 0 ? <span className="badge badge-light">公</span> : ""}
                    {shopArray.filter((v) => v === "17").length > 0 ? <span className="badge badge-info">サ</span> : ""}
                    {shopArray.filter((v) => v === "47").length > 0 ? <span className="badge badge-secondary">通</span> : ""}
                </span>;
            } else {




                return props.row.col6;
            }

        } else if (props.row.request === 2) {
            return "";
        } else {

            let status = "";

            // if(props.row.status===1){
            //     status=<span class="badge badge-danger">メール受信予定</span>
            // }else 
            if (props.row.col5 === "2") {
                status = <span className="badge badge-success">メール予定</span>
            } else if (props.row.col5 === "3") {
                status = <span className="badge badge-info">自動送信予定</span>
                // }else if(props.row.status===4){
                // status=<span class="badge badge-secondary">既読予定</span>
                // }else if(props.row.status===5){
                // status=<span class="badge badge-secondary">反響受信予定</span>
            } else if (props.row.col5 === "6") {
                status = <span className="badge badge-secondary">対応予定</span>
                // }else if(props.row.status===7){
                //     // status=<span class="badge badge-danger">マッチング物件なし</span>
            } else if (props.row.col5 === "8") {
                status = <span className="badge badge-success">来店予定</span>
            } else if (props.row.col5 === "9") {
                status = <span className="badge badge-warning">TEL予定</span>
            } else {

                status = <span className="badge badge-success">予定</span>
            }


            // return <div style={{ textAlign: "left" }}><span style={{ fontSize: "16px" }}>{status} </span><span >{props.row.col1 ? props.row.col1 : ""}</span></div >
            let time = props.row.intended_at ? moment(new Date(props.row.intended_at).setTime(new Date(props.row.intended_at).getTime() - 1000 * 60 * 60 * 9)) : ""
            return <div style={{ textAlign: "left" }}><span style={{ fontSize: "16px" }}>{status} </span><span >{time === "" ? "" : time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm")}</span></div >


            // return <div style={{ textAlign: "left" }}> {value}</div >;
        }
    }

};
const Col2Formatter = ({ value, ...props }) => {

    if (props.row.flg === 1) {
        if (props.row.request === 2) {
            return <div style={{ textAlign: "left" }}>{props.row.col10 ? <img className="faceicon" src={props.row.col10} alt="lineimg" /> : ""}<b>{value}</b></div >;

        } else if (props.row.request === 1) {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>顧客名</span>{props.row.col10 ? <img className="faceicon" src={props.row.col10} alt="lineimg" /> : ""}<b>{value}</b>{props.row.col4 > 0 ? <span class="badge badge-danger p-2">信用情報マッチングあり！</span> : ""}</div >;

        } else {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>顧客名</span>{props.row.col10 ? <img className="faceicon" src={props.row.col10} alt="lineimg" /> : ""}<b>{value}</b></div >;
        }
    } else if (props.row.flg === 2) {
        if (props.row.request === 2) {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>メッセージ</span> {value}</div >;
        } else {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>問合物件</span> {value}</div >;
        }
    } else if (props.row.flg === 3) {
        if (props.row.request === 2) {
            return "";
        } else {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>メール</span> {value}<span className="badge badge-info float-right"> <span className="fas fa-envelope"></span></span></div>;
        }
    }

};
const Col3Formatter = ({ value, ...props }) => {

    if (props.row.flg === 1) {
        if (props.row.request === 2) {
            return "";
        } else {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>ＴＥＬ</span>{value}</div >;
        }
    } else if (props.row.flg === 2) {
        if (props.row.request === 1) {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>連絡方法</span> {value}</div >;

        } else if (props.row.request === 2) {
            return "";
        } else {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>媒　体</span> {value}</div >;
        }
    } else if (props.row.flg === 3) {
        if (props.row.request === 2) {
            return value;
        } else {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>引越予定</span> {value}</div >;
        }
    }
}
const Col4Formatter = ({ value, ...props }) => {

    if (props.row.flg === 1) {
        if (props.row.request === 1) {
            return ""
        } else if (props.row.request === 2) {
            return value ? value : "";
        } else {

            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>担当者</span>{props.row.staff}</div >;
        }
    } else if (props.row.flg === 2) {
        if (props.row.request === 1) {
            return value ? value : "";
        } else if (props.row.request === 2) {
            return value ? value : "";
        } else {
            return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>経　路</span> {value}</div >;
        }
    } else if (props.row.flg === 3) {
        if (props.row.request === 1) {
            return value ? value : "";
        } else if (props.row.request === 2) {
            return value ? value : "";
        } else {
            return <div style={{ textAlign: "left" }}> {value}</div >;
        }
    }

};




// export class CustomHeader extends React.Component {
//     render() {
//         return (
//             <div >
//                 <div className="border-bottoms">お客様名</div>
//                 <div className="border-bottoms">I m custome Header</div>
//                 <div className="border-bottoms">I m custome Header</div>
//             </div>
//                 )
//     }
// };

const RowRenderer = ({ renderBaseRow, ...props }) => {
    //console.log(props)
    //const color = props.idx % 2 ? "green" : "blue";
    const border = props.idx % 3 === 2 ? "border-bottoms" : "";
    let backgroundColor = border + "";

    if (props.row.request === 1) {
        backgroundColor = border + " row-highlight-pink";
    } else if (props.row.request === 2) {
        backgroundColor = border + " row-highlight-green";
    } else if (props.row.status === 1) {
        backgroundColor = border + " row-highlight-yellow";
    } else if (props.row.status === 12) {
        backgroundColor = border + " row-highlight-yellowgreen";
    }
    //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
    return <span className={backgroundColor}>{renderBaseRow(props)}</span>;



};


// class checkBoxFormatter extends React.Component {
//     constructor(props) {
//         super(props);

//       }
//     render() {
//         console.log(this.props.row.id)
//       return (this.props.row.flg === 2 ?
//         <div>
//            <input type="checkbox" id={"scales"+this.props.row.id} className="react-grid-checkbox" name={"scales"+this.props.row.id} />
//             <label for={"scales"+this.props.row.id} class="react-grid-checkbox-label">
//             </label>
//             </div>:""
//       )
//     }
//   }

const columns = [
    // { key: 'check', name: '', width: 40, resizable: true, formatter: checkBoxFormatter },
    { key: 'col1', name: '反響日', width: 220, resizable: true, formatter: Col1Formatter },
    //{ key: 'col2', name: 'タイトル', width: 300, resizable: true, headerRenderer: <CustomHeader /> },
    { key: 'col2', name: 'お客様名', width: 300, resizable: true, formatter: Col2Formatter },
    { key: 'col3', name: '連絡先', width: 170, resizable: true, formatter: Col3Formatter },
    { key: 'col4', name: '反響内容', width: 100, resizable: true, formatter: Col4Formatter },
    { key: 'col5', name: '備考', resizable: true, formatter: DetailLinkFormatter },
];

// const rows = [{ id: 0, title: 'row1', count: 20 }, { id: 1, title: 'row1aaaaaaaaaaasssssssssssssssssssssssaaaaaaaaaa', count: 40 }, { id: 2, title: 'row1', count: 60 }];


class ResponseListGridComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight,
            modalselect: false,
            mailid: 0,
            modalMatching: false,
            mailMatchingId: 0,
            responseId: 0,
            mailAllReceiveId: 0,
            modalResponseDetail: false,
            rentResponseId: 0,

            modalAutoSend: false,
            modalmailtaiou: false,
            take: 10,
            line_open: false,
            line_delete: false,
            line_del: {}
        };
        this.onScroll = this.onScroll.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        this.selectedrow = this.selectedrow.bind(this);
        this.addResponse = this.addResponse.bind(this);
        this.togglemailtaiou = this.togglemailtaiou.bind(this);
        this.mailtaioudelete = this.mailtaioudelete.bind(this);
        this.closeResponseDetail = this.closeResponseDetail.bind(this);

        this.toggle_line_open = this.toggle_line_open.bind(this);
        this.toggle_line_delete = this.toggle_line_delete.bind(this);


    }


    selectedrow(responseId, mailAllReceiveId) {


        this.setState({
            responseId: responseId,
            mailAllReceiveId: mailAllReceiveId
        })
    }
    closeResponseDetail = () => {

        this.setState(prevState => ({
            modalResponseDetail: false,
        }));
    }

    addResponse() {
        let matching = {};
        matching.response_matching_detail = this.props.response_matching_list.filter(a => a.mail_all_receive_id === this.state.mailAllReceiveId)[0]

        matching.responseId = this.state.responseId

        if (this.state.responseId > 0) {
            // alert(this.state.responseId)
            console.log(this.props.response_matching_select_list)
            // matching.response_matching_select_list = this.props.response_matching_select_list.filter(a => a.selected === true)
            console.log(matching)

            // alert()
            matching.response_matching_select_list = this.props.response_matching_select_list.filter(a => a.selected === true).map(function (value) {

                return value.id;

            });

            console.log(this.props.history)



            this.props.customerNewDispSet(0)
            matching.history = this.props.history
            this.props.merge_response_mails(matching)
        } else {

            matching.response_matching_select_list = this.props.response_matching_select_list.filter(a => a.selected === true).map(function (value) {

                return value.id;

            });

            console.log(matching)

            this.props.change_line_user_id({ line_user_id: null, line_account_section_id: 0 })

            var fromrequestRoomMadoriNo = null;
            var torequestRoomMadoriNo = null;
            var madoriNo = matching.response_matching_detail.request_room_madori_no;



            if (madoriNo === 1
                || madoriNo === 2
                || madoriNo === 3
                || madoriNo === 4
                || madoriNo === 57
                || madoriNo === 54
                || madoriNo === 51
            ) {
                fromrequestRoomMadoriNo = 2;
                torequestRoomMadoriNo = 3;
            } else if (madoriNo === 5
                || madoriNo === 6
                || madoriNo === 7
                || madoriNo === 8
                || madoriNo === 9
                || madoriNo === 10
                || madoriNo === 50
                || madoriNo === 59
                || madoriNo === 60
            ) {
                fromrequestRoomMadoriNo = 5;
                torequestRoomMadoriNo = 9;
            } else if (madoriNo === 11
                || madoriNo === 12
            ) {
                fromrequestRoomMadoriNo = 11;
                torequestRoomMadoriNo = 11;
            } else if (madoriNo === 13
                || madoriNo === 14
                || madoriNo === 15
                || madoriNo === 16
            ) {
                fromrequestRoomMadoriNo = 15;
                torequestRoomMadoriNo = 15;
            } else if (madoriNo > 0) {
                fromrequestRoomMadoriNo = 17;
                torequestRoomMadoriNo = null;
            }

            var area = matching.response_matching_detail.request_room_area;
            var areas = "";
            if (area === "緑") {
                areas = [1];
            } else if (area === "赤") {
                areas = [1];
            } else if (area === "黄") {
                areas = [5];
            } else if (area === "黒") {
                areas = [4];
            } else if (area === "オレンジ") {
                areas = [2, 3];
            } else if (area === "紫") {
                areas = [1];
            } else if (area === "ピンク") {
                areas = [3];
            } else if (area === "青") {
                areas = [2, 3];
            } else if (area === "白") {


                // areas =[1];
            }
            let shop = [];

            shop = this.props.shops.filter(function (shop) {
                return shop.tenpoNo === matching.response_matching_detail.section_id;
            });

            let user = [];
            const shopps = shop[0].tenpoNo;
            user = this.props.users.filter(function (user) {
                return user.mPositionId === 4 && user.amSectionId === shopps;
            });


            let rank = 4
            if (matching.response_matching_detail.tel1 && this.props.response_matching_select_list.length === 1) {
                rank = 1
            } else if (matching.response_matching_detail.tel1) {
                rank = 2
            } else if (this.props.response_matching_select_list.length === 1) {
                rank = 3
            }

            this.props.open_response_add(
                {
                    contract_form: 0,
                    name1: matching.response_matching_detail.name,
                    kana1: matching.response_matching_detail.kana1,
                    tel1: matching.response_matching_detail.tel1,
                    // section_id: matching.response_matching_detail.section_id,//2023/1/9　小松さんより全店でとりあうため反響の店舗デフォルトをはずす
                    section_id: 0,
                    mail1: matching.response_matching_detail.email1,
                    rent_m_medium_id: matching.response_matching_detail.rent_m_medium_id,
                    building_name: matching.response_matching_detail.building_name,
                    request_from_plan: fromrequestRoomMadoriNo,
                    request_to_plan: torequestRoomMadoriNo,
                    request_from_cost: Number(matching.response_matching_detail.request_room_chinryou) > 0 ? Math.round((Number(matching.response_matching_detail.request_room_chinryou)) / 10000) * 10000 - 5000 : null,
                    request_to_cost: Number(matching.response_matching_detail.request_room_chinryou) > 0 ? Math.round((Number(matching.response_matching_detail.request_room_chinryou)) / 10000) * 10000 + 5000 : null,
                    areas: areas ? areas.join(',') : "",
                    rent_m_way_id: 2,
                    line_user_id: 0,
                    line_account_section_id: 0,
                })
            // this.props.change_rent_customer_editor_forms(
            //     {
            //         contract_form: 0,
            //         name1: matching.response_matching_detail.name,
            //         kana1: matching.response_matching_detail.kana1,
            //         tel1: matching.response_matching_detail.tel1,
            //         mail1: matching.response_matching_detail.email1,

            //     })

            // this.props.change_rent_responses_editor({
            // rent_m_medium_id: matching.response_matching_detail.rent_m_medium_id,
            //     building_name: matching.response_matching_detail.building_name,
            //         request_from_plan: fromrequestRoomMadoriNo,
            //             request_to_plan: torequestRoomMadoriNo,
            //                 request_from_cost: Number(matching.response_matching_detail.request_room_chinryou) > 0 ? Math.round((Number(matching.response_matching_detail.request_room_chinryou)) / 10000) * 10000 - 5000 : null,
            //                     request_to_cost: Number(matching.response_matching_detail.request_room_chinryou) > 0 ? Math.round((Number(matching.response_matching_detail.request_room_chinryou)) / 10000) * 10000 + 5000 : null,
            //                         areas: areas,
            //                             user_id: user,
            //                                 section_id: shop,
            //                                     rank: rank
            //     })



            var response_add_select_mail_id_list = this.props.response_matching_select_list.filter(a => a.selected === true).map(function (value) {
                return value.id;

            });
            if (this.state.mailAllReceiveId > 0) {
                response_add_select_mail_id_list.push(this.state.mailAllReceiveId)
            }


            this.props.change_response_add_select_mail_id_list(response_add_select_mail_id_list)
            this.props.change_response_add_disp(1)
        }


    }






    togglemail = (id) => {

        this.setState(prevState => ({
            modalmail: !prevState.modalmail,
            mailid: id
        }));
    }
    toggleMatching = (id) => {

        this.setState(prevState => ({
            modalMatching: !prevState.modalMatching,
            mailMatchingId: id
        }));
    }
    toggleResponseDetail = (id) => {

        this.setState(prevState => ({
            modalResponseDetail: !prevState.modalResponseDetail,
            rentResponseId: id
        }));
    }
    toggleAutoSend = (id) => {

        this.setState(prevState => ({
            modalAutoSend: !prevState.modalAutoSend,
            rentResponseId: id
        }));
    }

    togglemailtaiou = () => {

        this.setState(prevState => ({
            modalmailtaiou: !prevState.modalmailtaiou,
        }));
    }
    mailtaioudelete() {
        this.props.change_status({ id: this.state.rentResponseId })
        this.togglemailtaiou()
    }



    handleResize = () => {
        console.info(
            `window height:width=${window.innerHeight}:${window.innerWidth}`,
        );

        this.setState({ height: window.innerHeight });

    };
    onScroll(state) {

        console.log(state.rowVisibleEndIdx)
        window.scrollTo(0, 0)
    }




    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.responselist_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", height: 250, padding: "100px" }} > <h3>{message}</h3></div>

        );
    };

    toggle_line_open() {
        this.props.get_line_open({ line_user_id: this.props.line_user_details.id, line_account_section_id: this.props.line_user_details.line_account_section_id, take: 20 })
        // this.setState({ line_open: !this.state.line_open })
    };


    toggle_line_delete(value) {
        this.setState({ line_delete: !this.state.line_delete })
        if (!this.state.line_delete) {
            // alert(value.line_user_id)
            this.setState({ line_del: value })
        } else {
            this.setState({ line_del: {} })
        }

    };


    line_delete(value) {


        this.props.delete_line_user(this.state.line_del)
        this.setState({ line_del: {} })
        this.setState({ line_delete: false })

    };

    getCellActions(column, row) {
        // console.log(row.way)

        // const cellActions = {

        //   coming: comingActions
        // };
        if (row.flg === 3 && column.key === "col5" && row.request === 1) {
            return [
                {
                    icon: <button className="btn btn-sm btn-success " style={{ width: "100px", margin: "5px" }} >マッチング</button>,

                    callback: () => {


                        this.selectedrow(0, 0)
                        this.props.change_response_matching_list_loading(1)
                        this.props.change_response_matching_select_list_loading(1)
                        this.props.get_response_matching_list(row.id)
                        this.props.get_response_matching_select_list(row.id)
                        // this.togglemail(row.id)
                        // this.toggleMatching(row.id)
                        this.props.change_modalMatching(true)
                    }
                },

            ]



        } else if (row.flg === 3 && column.key === "col5" && row.request === 2) {
            return [{
                icon: <button className="btn btn-danger btn-sm" style={{ width: "80px", margin: "5px" }} >削除</button>,

                callback: () => {
                    this.toggle_line_delete({ line_user_id: row.id, line_account_section_id: row.line_account_section_id })

                }
            }, {
                icon: <button className="btn btn-primary btn-sm" style={{ width: "80px", margin: "5px" }} >反響結付け</button>,

                callback: () => {


                    // this.props.change_response_matching_select_list_loading(1)


                    var data = new Date()
                    data.setMonth(data.getMonth() - 1)
                    this.props.change_line_user_id({ line_user_id: row.id, line_account_section_id: row.line_account_section_id });
                    this.props.get_response_line_matching_list({
                        name: '',
                        section_id: this.props.user_details.amSectionId,

                        user_id: this.props.user_details.id,
                        response_date_start: data.getFullYear() + '/' + (data.getMonth() + 1) + '/' + data.getDate()
                    })
                    // this.props.get_response_matching_select_list(row.id)
                    // this.togglemail(row.id)
                    // this.toggleMatching(row.id)
                    this.props.change_modalLineMatching(true)

                }
            }, {
                icon: <button className="btn btn-warning btn-sm" style={{ width: "80px", margin: "5px" }} >新規登録</button>,

                callback: () => {



                    this.props.change_line_user_id({ line_user_id: row.id, line_account_section_id: row.line_account_section_id })
                    this.props.open_response_add(
                        {
                            contract_form: 0,
                            name1: row.name,
                            section_id: 0,
                            rent_m_medium_id: row.rent_m_medium_id,
                            medium_remarks: row.medium_remarks,
                            mypage_request_number_of_people: row.mypage_request_number_of_people,
                            building_name: "",
                            request_to_cost: row.request_to_cost,
                            request_cost_remarks: row.request_cost_remarks,
                            request_from_plan: row.request_from_plan,
                            request_to_plan: row.request_to_plan,
                            request_moving_time: row.request_moving_time,

                            areas: row.rent_m_area_id,
                            rent_m_way_id: 6,
                        })




                }
            },
            {
                icon: <button className="btn btn-success btn-sm" style={{ width: "80px", margin: "5px" }} >LINE表示</button>,

                callback: () => {
                    this.props.get_line_user_details({ line_user_id: row.id, line_account_section_id: row.line_account_section_id })

                    this.props.get_line_opesn({ line_user_id: row.id, line_account_section_id: row.line_account_section_id, take: 20 })

                    // this.props.change_response_add_disp(1)

                    // this.props.get_lines({ line_user_id: row.id, line_account_section_id: row.line_account_section_id, take: this.state.take + 5 })
                    // // this.setState({ take: this.state.take + 5 })
                    // // this.node.scrollTop = 50;
                    // this.setState({ line_open: true })

                    // this.props.change_line_user_id({ line_user_id: row.id, line_account_section_id: row.line_account_section_id })
                    // this.props.change_customer_add_detail({ name1: row.col2, lineUserId: row.id })
                    // this.props.change_response_add_detail({ rentMMediumId: 2, buildingName: "" })

                }
            }
            ]




        } else if (row.flg === 3 && column.key === "col5" && row.request !== 1) {

            let button = [];
            button = button.concat({
                icon: <button className="btn btn-sm btn-primary " style={{ margin: "5px" }} ><i className="fas fa-external-link-alt"></i></button>,

                callback: () => {
                    window.open(`/customer/` + row.id, '_blank', 'noopener')

                }
            })
            button = button.concat({
                icon: <button className="btn btn-sm btn-success" style={{ width: "80px", margin: "5px" }} >詳細</button>,

                callback: () => {

                    // this.toggleResponseDetail(row.id)

                    this.props.change_modalResponseDetail(true)
                    this.props.change_responseid(row.id)
                }
            })
            if (row.intended_rent_m_response_status_id === 3) {
                button = button.concat({
                    icon: <button className="btn btn-sm btn-info" style={{ width: "130px", margin: "5px" }} >次回自動送信予定</button>,

                    callback: () => {
                        this.toggleAutoSend(row.id)


                    }
                })
            }
            if (row.status === 1 || row.status === 12) {
                button = button.concat(
                    {
                        icon: <button className="btn btn-sm btn-primary" style={{ width: "120px", margin: "5px" }} >確認済みにする</button>,

                        callback: () => {

                            this.togglemailtaiou()
                            this.setState({ rentResponseId: row.id })

                            // this.props.change_response_matching_list_loading(1)
                            // this.props.change_response_matching_select_list_loading(1)
                            // this.props.get_response_matching_list(row.id)
                            // this.props.get_response_matching_select_list(row.id)
                            // // this.togglemail(row.id)
                            // // this.toggleMatching(row.id)
                            // this.props.change_modalMatching(true)
                        }
                    })
            }



            return button;

            // return [{
            //     icon: <button class="btn btn-sm btn-primary " style={{margin:"5px"}} ><i class="fas fa-external-link-alt"></i></button>,

            //     callback: () => {
            //         window.open(`/customer/` + row.id,'_blank','noopener')

            //     }
            //   },{
            //     icon: <button class="btn btn-sm btn-success" style={{width:"80px",margin:"5px"}} >詳細</button>,

            //     callback: () => {
            //         this.toggleResponseDetail(row.id)


            //     }
            //   },{
            //     icon: <button class="btn btn-sm btn-info" style={{width:"130px",margin:"5px"}} >次回自動送信予定</button>,

            //     callback: () => {
            //         this.toggleAutoSend(row.id)


            //     }
            //   },

            // ]


        } else {
            return "";
        }

    }








    render() {


        return (
            <div>



                <EventListener target="window" onResize={this.handleResize} />
                <ReactDataGrid
                    scrollToRowIndex={20}
                    enableCellSelect={true}
                    columns={columns}
                    rowGetter={i => this.props.responselist[i]}
                    rowsCount={this.props.responselist.length}
                    headerRenderer={true}
                    getCellActions={this.getCellActions}
                    //headerRowHeight={64}
                    dragable={true}
                    onColumnResize={(idx, width) =>
                        console.log(`Column ${idx} has been resized to ${width}`)
                    }
                    togglemail={this.togglemail}
                    // scrollToRowIndex={100}
                    emptyRowsView={this.EmptyRowsView}
                    rowRenderer={RowRenderer}
                    onScroll={this.onScroll}
                    minHeight={this.state.height - 170}
                />



                {/* <Modal isOpen={this.state.modalmail} toggle={this.toggleMatching}  style={customStyles}> */}
                <Modal isOpen={this.state.modalmail} toggle={this.toggleMatching} size="lg" fade={false}>

                    <ModalHeader toggle={this.togglemail}>メール詳細</ModalHeader>
                    <ModalBody>
                        <MailDetail toggle={this.toggleselect} mailid={this.state.mailid} />
                    </ModalBody>
                </Modal>


                <Modal isOpen={this.props.modalMatching} toggle={() => this.props.change_modalMatching(false)}
                    size="xxl" fade={false}

                >


                    <ModalHeader toggle={() => this.props.change_modalMatching(false)}>メールマッチング</ModalHeader>
                    <ModalBody>
                        メイン
                        <ResponseMatchingGridComponent selectedrow={this.selectedrow} />
                        <br />
                        取り込み反響選択
                        <ResponseMatchingSelectGridComponent setrow={this.state.mailAllReceiveId} />

                    </ModalBody>

                    <ModalFooter>

                        <button className="btn btn-primary float-right" type="button" onClick={this.addResponse}>登録</button>

                        <Button color="secondary" onClick={() => this.props.change_modalMatching(false)}>キャンセル</Button>
                    </ModalFooter>
                </Modal>







                <Modal isOpen={this.props.modalLineMatching} toggle={() => this.props.change_modalLineMatching(false)}
                    size="xxl" fade={false}

                >


                    <ModalHeader toggle={() => this.props.change_modalLineMatching(false)}>LINEマッチング</ModalHeader>
                    <ModalBody>

                        <ResponseLineMatchingGridComponent selectedrow={this.selectedrow} />


                    </ModalBody>

                    {/* <ModalFooter> 

        <button className="btn btn-primary float-right" type="button" onClick={this.addResponse}>登録</button>

        <Button color="secondary" onClick={()=>this.props.change_modalLineMatching(false)}>キャンセル</Button>
     </ModalFooter> */}
                </Modal>












                <Modal isOpen={this.props.modalResponseDetail} toggle={() => this.props.change_modalResponseDetail(false)}
                    size="xxl" fade={false}

                >


                    {/* <ModalHeader toggle={() => this.props.change_modalResponseDetail(false)}>反響詳細</ModalHeader> */}


                    <ResponseComponent change_modalResponseDetail={(a) => this.props.change_modalResponseDetail(a)} rentResponseId={this.props.responseid} response_modal={true} />

                </Modal>

                <Modal isOpen={this.state.modalAutoSend} toggle={this.toggleAutoSend}
                    size="xl" fade={false}>


                    <ModalHeader toggle={this.toggleAutoSend}>自動送信予定</ModalHeader>
                    <ModalBody style={{ height: "550px" }}>

                        <AutoSend rentResponseId={this.state.rentResponseId} />

                    </ModalBody>



                    {/* <ModalFooter> 

         <button className="btn btn-primary float-right" type="button" onClick={this.addResponse}>登録</button>

         <Button color="secondary" onClick={this.toggleMatching}>キャンセル</Button>
      </ModalFooter> */}
                </Modal>

                <Modal isOpen={this.state.modalmailtaiou} toggle={this.togglemailtaiou} size="sm" fade={false}>

                    <ModalHeader toggle={this.togglemailtaiou}>確認済みにする</ModalHeader>
                    <ModalBody>
                        確認済みにするとメール・LINE受信のアラートが削除されます。
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.mailtaioudelete}>確認済みにする</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.togglemailtaiou}>キャンセル</button>
                    </ModalFooter>
                </Modal>



                {/* <Modal isOpen={this.state.line_open} toggle={this.toggle_line_open} fade={false}>

                    <ModalHeader toggle={this.toggle_line_open}>LINE</ModalHeader>
                    <ModalBody>
                        {this.props.line_user_details.id ? <LineComponent /> : ""}
                    </ModalBody>
                    {/* <ModalFooter>
            <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.line_bind}>結びつける</button>
            <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.togglemodalLineSelect}>キャンセル</button>
          </ModalFooter> */}
                {/* </Modal> * /} */}



                < Modal isOpen={this.state.line_delete} toggle={this.toggle_line_delete} fade={false} >

                    <ModalHeader toggle={this.toggle_line_delete}>LINE削除</ModalHeader>
                    <ModalBody>
                        本当にLINEアカウントを削除してもよろしいですか？
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={() => this.line_delete()}>削除</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggle_line_delete}>キャンセル</button>
                    </ModalFooter>
                </Modal >

            </div >
        );
    }


}



const mapStateToProps = state => {
    return {
        responselist: state.responselist.responselist,
        responselist_loading: state.responselist.responselist_loading,
        window_height: state.masterReducer.window_height,
        response_matching_list: state.responsesReducer.response_matching_list,
        response_matching_select_list: state.responsesReducer.response_matching_select_list,
        response_matching_list_loading: state.responsesReducer.response_matching_list_loading,
        modalResponseDetail: state.responsesReducer.modalResponseDetail,
        responseid: state.responsesReducer.responseid,
        modalMatching: state.responsesReducer.modalMatching,
        modalLineMatching: state.responselist.modalLineMatching,
        user_details: state.masterReducer.user_details,
        users: state.masterReducer.users,
        line_user_details: state.line.line_user_details,
        shops: state.masterReducer.shops,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_responses_editor() {
            dispatch(Responses.change_rent_responses_editor())
        },
        change_rent_customer_editor_forms() {
            dispatch(Customers.change_rent_customer_editor_forms())
        },


        responseList() {
            dispatch(ResponseList.responseList())
        },
        get_response_matching_list(state) {
            dispatch(ResponseList.get_response_matching_list(state))
        },
        get_response_matching_select_list(state) {
            dispatch(ResponseList.get_response_matching_select_list(state))
        },
        change_response_matching_list_loading(state) {
            dispatch(ResponseList.change_response_matching_list_loading(state))
        },
        change_response_matching_select_list_loading(state) {
            dispatch(ResponseList.change_response_matching_select_list_loading(state))
        },
        merge_response_mails(state) {
            dispatch(Responses.merge_response_mails(state))
        },
        // change_matching(state) {
        //     dispatch(ResponseList.change_matching(state))
        // },

        customerNewDispSet(state) {
            dispatch(Customers.customerNewDispSet(state))
        },
        change_response_add_disp(state) {
            dispatch(Responses.change_response_add_disp(state))
        },
        change_response_add_detail(state) {
            dispatch(Responses.change_response_add_detail(state))
        },
        change_customer_add_detail(state) {
            dispatch(Responses.change_customer_add_detail(state))
        },
        change_line_user_id(state) {
            dispatch(Responses.change_line_user_id(state))
        },
        change_response_add_select_mail_id_list(state) {
            dispatch(Responses.change_response_add_select_mail_id_list(state))
        },
        change_responseid(state) {
            dispatch(Responses.change_responseid(state))
        },
        change_modalResponseDetail(state) {
            dispatch(Responses.change_modalResponseDetail(state))
        },
        change_modalMatching(state) {
            dispatch(Responses.change_modalMatching(state))
        },
        change_modalLineMatching(state) {
            dispatch(ResponseList.change_modalLineMatching(state))
        },
        get_response_line_matching_list(state) {
            dispatch(ResponseList.get_response_line_matching_list(state))
        },
        open_response_add(state) {
            dispatch(Responses.open_response_add(state))
        },


        change_status(state) {
            dispatch(ResponseList.change_status(state))
        },

        get_lines(state) {
            dispatch(Line.get_lines(state))
        },
        get_line_open(state) {
            dispatch(Line.get_line_open(state))
        },
        get_line_user_details(state) {
            dispatch(Line.get_line_user_details(state))
        },
        delete_line_user(state) {
            dispatch(Line.delete_line_user(state))
        },
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponseListGridComponent));
// export default withRouter(connect(mapStateToProps, mapDispatchToProps,appElement)(ResponseListGridComponent));
