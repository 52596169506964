import React, { Component } from 'react';

import {ComposedChart,XAxis,YAxis,Tooltip,CartesianGrid,Bar} from 'recharts';

 
const data = [
  { name: '白井', "返信率": 10 },
  { name: '釜野', "返信率": 18 },
  { name: '小松', "返信率": 17 },
  { name: '田中', "返信率": 25 },
  { name: '中西', "返信率": 35 },
  { name: '三野', "返信率": 30 },
  { name: '松本', "返信率": 11 },
  { name: '白石', "返信率": 30 },
  { name: '三宅', "返信率": 20 },
]

class SpeedAnalyticsComponent extends Component {
  render() {
    return (
      <div>
        返信速度(分)
     <ComposedChart　　//グラフ全体のサイズや位置、データを指定。場合によってmarginで上下左右の位置を指定する必要あり。
    width={500}  //グラフ全体の幅を指定
    height={280}  //グラフ全体の高さを指定
    // layout="vertical" //グラフのX軸とY軸を入れ替え
    data={data}   //Array型のデータを指定
    margin={{ top: 20, right: 60, bottom: 0, left: 50 }}  //marginを指定
  >
    <XAxis  //X軸に関する設定
       type="category" //データタイプをcategoryに変更
       dataKey="name"  //Array型のデータの、Y軸に表示したい値のキーを指定
    />
    <YAxis //Y軸に関する設定
    type="number" //データタイプをnumberに変更。デフォルトではcategoryになっている
    domain={[0, 'dataMax + 10']} //軸の表示領域を指定
     
    />
    <Tooltip /> ////hoverさせた時に具体的な値を表示させるように指定
    <CartesianGrid  //グラフのグリッドを指定
      stroke="#f5f5f5"  //グリッド線の色を指定
     /> 
    <Bar
      dataKey="返信率"
      barSize={20}
      stroke="rgba(34, 80, 162, 0.2)"
      fillOpacity={1}
      fill="#2250A2"
    />
  </ComposedChart>
 </div>
    );
  }
}

export default SpeedAnalyticsComponent;