
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as RentResponsesApi from '../Api/RentResponsesApi';
import * as RentResponsesChangeStatus from '../Api/RentResponsesChangeStatusApi';
import * as MailOutRespnoseApi from '../Api/MailOutResponseApi';
import * as rent_m_agents_api from '../Api/rent_m_agents_api';

import * as line_users_api from '../Api/line_users_api';
import * as rent_responses_api from '../Api/rent_responses_api';
import * as rent_hearing_sheets_api from '../Api/rent_hearing_sheets_api';
import * as rent_line_templates_api from '../Api/rent_line_templates_api';
import * as rent_line_template_details_api from '../Api/rent_line_template_details_api';
import * as rent_line_template_docs_api from '../Api/rent_line_template_docs_api';
import * as rent_customer_contacts_api from '../Api/rent_customer_contacts_api';
import * as rent_line_schedules_api from '../Api/rent_line_schedules_api';
import moment from 'moment'



export interface ResponsesListState {
  responselist: any;
  response_matching_list: any;
  responselist_loading: number;
  responseedit: number;
  responseloding: number;
  response_list: any;

  response_search_list: any;
  response_count: number;


  responsedetail: any;

  responseid: number;
  customer_logs: any;
  customer_logs_loading: number;
  response_matching_list_loading: number;

  response_matching_select_list: any;
  response_matching_select_list_loading: number;
  // matching: any;
  introductions: any;
  introductions_loading: number;
  intend_list: any;
  intend_list_calendar: any;
  response_line_matching_list: any;
  line_user_id: any;
  modalLineMatching: boolean;

  response_card_matching_list: any;
  modalCardMatching: boolean;

  formData: {
    sort: number,
    intend: number,
    // email: string,
    password: string,
    tatemonoName: string,
    applicationDate: string,
    contractionDateStart: string,
    contractionDateEnd: string,
    condition: any,
    condition_radio: number,
    response_user_id: number,
    primary_user_id: number,
    coming_user_id: number,
    going_user_id: number,
    web_user_id: number,
    name: string,
    section_id: number

    check_end_response:boolean,
    check_progress_response:boolean,
    check_application:boolean,

    building_name: string,
    parking_name: string,
    contract_user_id: number,
    response_date_start: string,
    response_date_end: string,
    response_condition: number,
    tel1: string,
    rank: any,
    moving_time: any,
    way: any,
    media: any,
    agent: any,
    reason: any,
    email: string,
    from_cost: number,
    to_cost: number,
    form_madori: number,
    to_madori: number,
    area: number,
    requests: number,
    contract_form: boolean,
    other_contract_form: boolean,
    student: boolean,
    other_student: boolean,
    end_response: number,
    gakusei_yoyaku: boolean,
    jyukenyotei: any,
    gouhi: number,
    rent_m_school_id: number,
    line_img_url: boolean,
    unfollowed: boolean,
    last_response_date_start: any,
    line_send: boolean,
  },
  respnose_list_backdrop: boolean;
  rent_m_agents: any,
  form_count: any;

  line_template_edit_open: boolean;
  line_schedule: any,
  line_schedule_users: any,
  line_schedule_detail: any,

  rent_line_templates_list: any;
  rent_line_template: any;
  rent_line_template_detail: any;
  file_update_disp: any;
  rent_line_template_url: any;

  rent_line_schedule: any;
  rent_line_schedule_template_detail: any;

  line_template_open: boolean;
  line_schedule_open: boolean;

  line_send_list_open: boolean;
  rent_line_schedule_list: any;
  rent_line_schedule_customer_contact_list: any;
  rent_line_schedule_customer_contact_list_search: any;
  rent_line_schedule_detail: any;
  line_send_search: any;
  
  


  // change_modalMatching:any
}

const initialState: ResponsesListState = {

  responselist: [],

  response_list: [],
  response_search_list: [],
  response_count: 0,
  response_matching_list: [],
  responselist_loading: 0,
  responseedit: 0,
  responseloding: 0,

  responsedetail: {},

  responseid: 0,
  customer_logs: [],
  customer_logs_loading: 0,
  response_matching_list_loading: 0,

  response_matching_select_list: [],
  response_matching_select_list_loading: 0,
  // matching: [],
  introductions: [],
  introductions_loading: 0,
  intend_list: [],
  intend_list_calendar: [],
  response_line_matching_list: [],
  line_user_id: { line_user_id: null, line_account_section_id: 0 },
  modalLineMatching: false,
  response_card_matching_list: [],
  modalCardMatching: false,
  formData: {
    // sort: 1,
    sort: 4,
    intend: 0,
    // email: '',
    password: '',
    tatemonoName: '',
    applicationDate: '',
    contractionDateStart: '',
    contractionDateEnd: '',
    condition: "",
    condition_radio:0,
    response_user_id: 0,
    primary_user_id: 0,
    coming_user_id: 0,
    going_user_id: 0,
    web_user_id: 0,
    name: '',

    check_end_response:false,
    check_progress_response:true,
    check_application:false,

    section_id: 0,
    building_name: '',
    parking_name: '',
    contract_user_id: 0,
    response_date_start: '',
    response_date_end: '',
    response_condition: 0,
    tel1: '',
    rank: [],
    moving_time: [],
    way: [],
    media: [],
    agent: [],
    reason: [],
    email: '',

    from_cost: 0,
    to_cost: 0,
    form_madori: 0,
    to_madori: 0,
    area: 0,
    requests: 0,
    contract_form: false,
    other_contract_form: false,
    student: false,
    other_student: false,
    end_response: 0,
    gakusei_yoyaku: false,
    jyukenyotei: "",
    gouhi: 0,
    rent_m_school_id: 0,
    line_img_url: false,
    unfollowed: false,
    last_response_date_start: moment().add(-1, "years").startOf("year").format("YYYY-MM-DD"),
    line_send:false,
  },
  respnose_list_backdrop: false,
  rent_m_agents: [],
  form_count: 0,

  line_template_edit_open:false,
  line_schedule: {id:"", rent_line_template_id:"", schedule_at:"", section_id:"", user_id:""},
  line_schedule_users: [],
  line_schedule_detail: [
    {
      no:1,
      message_type:"text",
      mime_type:null,
      mime:null,
      file_id:null,
      message_text:"aaaa",
    },
    {
      no:2,
      message_type:"text",
      mime_type:null,
      mime:null,
      file_id:null,
      message_text:"aaaa",
    },
  ],

  rent_line_templates_list: [],
  rent_line_template: [],
  rent_line_template_detail: [],
  file_update_disp: false,
  rent_line_template_url: "",

  rent_line_schedule : {schedule_at:"", intend_time:'', section_id: '', user_id: ''}, 
  rent_line_schedule_template_detail: [],

  line_template_open: false, 
  line_schedule_open: false, 
  
  line_send_list_open: false,
  rent_line_schedule_list: [],
  rent_line_schedule_customer_contact_list: [],
  rent_line_schedule_customer_contact_list_search: [],
  rent_line_schedule_detail : {id:"", rent_line_template_id:''}, 
  line_send_search: {condition:""}
}


///action 
const actionCreator = actionCreatorFactory();

// export const responseeditchange = actionCreator< number >('RESPONSE_EDIT');
export const responseid = actionCreator<number>('RESPONSE_ID');
// export const responseloding = actionCreator<number>('RESPONSE_LODING');




// export const responseUpdate = actionCreator<any>('RESPONSE_UPDATE');
// export const responseUpdateSuccess = actionCreator("RESPONSE_UPDATE_SUCCESS");
// export const responseUpdateFailure = actionCreator("RESPONSE_UPDATE_FAILURE");








export const responseList = actionCreator<any>('RESPONSE_LIST');
export const responseListSuccess = actionCreator("RESPONSE_LIST_SUCCESS");
export const responseListFailure = actionCreator("RESPONSE_LIST_FAILURE");
export const change_responselist_loading = actionCreator<number>('CHANGE_RESPONSE_LIST_LOADING');



export const search_response_list = actionCreator<any>('SEARCH_RESPONSE_LIST');

export const change_response_list = actionCreator<any>('CHANGE_RESPONSE_LIST');
export const search_response_list2 = actionCreator<any>('SEARCH_RESPONSE_LIST2');
export const change_response_search_list = actionCreator<any>('CHANGE_RESPONSE_SEARCH_LIST');


export const change_response_count = actionCreator<any>('CHANGE_RESPONSE_COUNT');


export const get_response_matching_list = actionCreator<any>('GET_RESPONSE_MATCHING_LIST');
export const get_response_matching_list_success = actionCreator<any>("GET_RESPONSE_MATCHING_LIST_SUCCESS");
export const change_response_matching_list_loading = actionCreator<number>('CHANGE_RESPONSE_MATCHING_LIST_LOADING');

export const get_response_line_matching_list = actionCreator<any>('GET_RESPONSE_LINE_MATCHING_LIST');
export const get_response_line_matching_list_success = actionCreator<any>("GET_RESPONSE_LINE_MATCHING_LIST_SUCCESS");
export const change_response_line_matching_list_loading = actionCreator<number>('CHANGE_RESPONSE_LINE_MATCHING_LIST_LOADING');

export const change_line_user_id = actionCreator<any>('CHANGE_LINE_USER_ID');

export const get_response_card_matching_list = actionCreator<any>('GET_RESPONSE_CARD_MATCHING_LIST');
export const get_response_card_matching_list_success = actionCreator<any>("GET_RESPONSE_CARD_MATCHING_LIST_SUCCESS");
export const change_response_card_matching_list_loading = actionCreator<number>('CHANGE_RESPONSE_CARD_MATCHING_LIST_LOADING');

export const get_response_matching_select_list = actionCreator<any>('GET_RESPONSE_MATCHING_SELECT_LIST');
export const get_response_matching_select_list_success = actionCreator<any>("GET_RESPONSE_MATCHING_SELECT_LIST_SUCCESS");
export const change_response_matching_select_list_loading = actionCreator<number>('CHANGE_RESPONSE_MATCHING_SELECT_LIST_LOADING');



export const get_intend_list = actionCreator<any>('GET_INTEND_LIST');
export const get_intend_list_success = actionCreator("GET_INTEND_LIST_SUCCESS");
export const get_intend_list__calendar_success = actionCreator("GET_INTEND_LIST_CALENDER_SUCCESS");

export const change_status = actionCreator<any>('CHANGE_STATUS');


export const delete_mail = actionCreator<any>('DELETE_MAIL');

export const line_bind = actionCreator<any>('LINE_BIND');
export const card_bind = actionCreator<any>('CARD_BIND');

export const change_modalLineMatching = actionCreator<any>('CHANGE_MODALLINEMATCHING');
export const change_modalCardMatching = actionCreator<any>('CHANGE_MODALCARDMATCHING');

export const change_formData = actionCreator<any>('CHANGE_FORMDATA');


export const delete_line_user = actionCreator<any>('DELETE_LINE_USER');


export const change_respnose_list_backdrop = actionCreator<any>('CHANGE_RESPONSE_LIST_BACKDROP');

export const get_rent_m_agents = actionCreator<any>('GET_RENT_M_AGENTS');
export const change_rent_m_agents = actionCreator<any>('CHANGE_RENT_M_AGENTS');

export const change_form_count = actionCreator<any>('CHANGE_FORM_COUNT');

export const delete_card = actionCreator<any>('DELETE_CARD');

export const get_line_schedule = actionCreator<any>('GET_LINE_SCHEDULE');
export const change_line_schedule = actionCreator<any>('CHANGE_LINE_SCHEDULE');
export const get_line_schedule_users = actionCreator<any>('GET_LINE_SCHEDULE_USERS');
export const change_line_schedule_users = actionCreator<any>('CHANGE_LINE_SCHEDULE_USERS');
export const get_line_schedule_detail = actionCreator<any>('GET_LINE_SCHEDULE_DETAIL');
export const change_line_schedule_detail = actionCreator<any>('CHANGE_LINE_SCHEDULE_DETAIL');

export const get_rent_line_templates_list = actionCreator<any>('GET_RENT_LINE_TEMPLATES_LIST');
export const change_rent_line_templates_list = actionCreator<any>('CHANGE_RENT_LINE_TEMPLATES_LIST');

export const change_rent_line_template = actionCreator<any>('CHANGE_RENT_LINE_TEMPLATE');
export const insert_rent_line_template = actionCreator<any>('INSERT_RENT_LINE_TEMPLATE');
export const update_rent_line_template = actionCreator<any>('UPDATE_RENT_LINE_TEMPLATE');


export const get_rent_line_template_detail = actionCreator<any>('GET_RENT_LINE_TEMPLATE_DETAIL');
export const change_rent_line_template_detail = actionCreator<any>('CHANGE_RENT_LINE_TEMPLATE_DETAIL');
export const insert_rent_line_template_detail = actionCreator<any>('INSERT_RENT_LINE_TEMPLATE_DETAIL');
export const delete_rent_line_template_detail = actionCreator<any>('DELETE_RENT_LINE_TEMPLATE_DETAIL');

export const change_file_update_disp = actionCreator<any>('CHANGE_FILE_UPDATE_OPEN');
export const change_line_file_data = actionCreator<any>('CHANGE_LINE_FILE_DATA');
export const upload_line_template_file = actionCreator('UPLOAD_LINE_TEMPLATE_FILE');
export const change_rent_line_template_url = actionCreator<any>('CHANGE_RENT_LINE_TEMPLATE_URL');

export const change_rent_line_schedule = actionCreator<any>('CHANGE_RENT_LINE_SCHEDULE');

export const get_rent_line_schedule_template_detail = actionCreator<any>('GET_RENT_LINE_SCHEDULE_TEMPLATE_DETAIL');
export const change_rent_line_schedule_template_detail = actionCreator<any>('CHANGE_RENT_LINE_SCHEDULE_TEMPLATE_DETAIL');

export const change_line_template_open = actionCreator<any>('CHANGE_LINE_TEMPLATE_OPEN');
export const change_line_send_list_open = actionCreator<any>('CHANGE_LINE_SEND_LIST_OPEN');
export const change_line_schedule_open = actionCreator<any>('CHANGE_LINE_SCHEDULE_OPEN');

export const update_line_send = actionCreator<any>('UPDATE_LINE_SEND');

export const get_rent_line_schedule_list = actionCreator<any>('GET_RENT_LINE_SCHEDULE_LIST');
export const change_rent_line_schedule_list = actionCreator<any>('CHANGE_RENT_LINE_SCHEDULE_LIST');
export const change_rent_line_schedule_list_open = actionCreator<any>('CHANGE_RENT_LINE_SCHEDULE_LIST_OPEN');

export const change_line_template_edit_open = actionCreator<any>('CHANGE_LINE_TEMPLATE_EDIT_OPEN');

export const get_rent_line_schedule_customer_contact_list = actionCreator<any>('GET_RENT_LINE_SCHEDULE_CUSTOMER_CONTACT_LIST');
export const change_rent_line_schedule_customer_contact_list = actionCreator<any>('CHANGE_RENT_LINE_SCHEDULE_CUSTOMER_CONTACT_LIST');
export const get_rent_line_schedule_customer_contact_list_search = actionCreator<any>('GET_RENT_LINE_SCHEDULE_CUSTOMER_CONTACT_LIST_SEARCH');
export const change_rent_line_schedule_customer_contact_list_search = actionCreator<any>('CHANGE_RENT_LINE_SCHEDULE_CUSTOMER_CONTACT_LIST_SEARCH');
export const change_rent_line_schedule_detail = actionCreator<any>('CHANGE_RENT_LINE_SCHEDULE_DETAIL_LIST');
export const delete_rent_line_schedule = actionCreator<any>('DELETE_RENT_LINE_SCHEDULE');

export const delete_responselist_rent_customer_contacts = actionCreator<any>('DELETE_RENT_RESPONSE_CUSTOMER_CONTACTS');
export const change_line_send_search = actionCreator<any>('CHANGE_LINE_SEND_SEARCH');






// export const responsedetailData = actionCreator<{ responseId: number }>('RESPONSE_DETAIL');
// export const responsedetailSuccess = actionCreator("RESPONSE_DETAIL_SUCCESS");
// export const responsedetailFailure = actionCreator("RESPONSE_DETAIL_FAILURE");


// export const get_customer_logs = actionCreator<{ responseId: number }>('GET_CUSTOMER_LOGS');
// export const get_customer_logs_success = actionCreator("GET_CUSTOMER_LOGS_SUCCESS");
// export const get_customer_logs_failure = actionCreator("GET_CUSTOMER_LOGS_FAILURE");

// export const change_customer_logs_loading = actionCreator<number >('CHANGE_CUSTOMER_LOGS_LOADING');


// export const change_matching = actionCreator<any>('CHANGE_MATCHING');






// export const get_introductions = actionCreator<any>('GET_INTRODUCTIONS');
// export const get_introductions_success = actionCreator<any>('GET_INTRODUCTIONS_SUCCESS');

// export const insert_introductions = actionCreator<any>('INSERT_INTRODUCTIONS');
// export const insert_introductions_success = actionCreator<any>('INSERT_INTRODUCTIONS_SUCCESS');

// export const change_introductions_loading = actionCreator<number>('INTRODUCTIONS_LOADING');



export const responselistReducer = reducerWithInitialState(initialState)
  .case(responseid, (state, payload) => ({ ...state, responseid: payload }))

  .case(change_responselist_loading, (state, payload) => ({ ...state, responselist_loading: payload }))

  // .case(change_matching, (state, payload) => ({ ...state, matching: payload }))
  .case(get_intend_list_success, (state, payload) => ({ ...state, intend_list: payload }))


  .case(change_line_user_id, (state, payload) => ({ ...state, line_user_id: payload }))



  .case(get_response_matching_list_success, (state, payload) => ({ ...state, response_matching_list: payload }))

  .case(change_response_matching_list_loading, (state, payload) => ({ ...state, response_matching_list_loading: payload }))

  .case(get_response_matching_select_list_success, (state, payload) => ({ ...state, response_matching_select_list: payload }))

  .case(change_response_matching_select_list_loading, (state, payload) => ({ ...state, response_matching_select_list_loading: payload }))

  .case(get_intend_list__calendar_success, (state, payload) => ({ ...state, intend_list_calendar: payload }))

  .case(get_response_line_matching_list_success, (state, payload) => ({ ...state, response_line_matching_list: payload }))

  .case(change_modalLineMatching, (state, payload) => ({ ...state, modalLineMatching: payload }))
  .case(change_modalCardMatching, (state, payload) => ({ ...state, modalCardMatching: payload }))

  .case(get_response_card_matching_list_success, (state, payload) => ({ ...state, response_card_matching_list: payload }))

  .case(change_formData, (state, payload) => ({ ...state, formData: payload }))
  .case(change_form_count, (state, payload) => ({ ...state, form_count: payload }))

  .case(change_response_list, (state, payload) => ({ ...state, response_list: payload }))

  .case(change_response_search_list, (state, payload) => ({ ...state, response_search_list: payload }))

  .case(change_rent_m_agents, (state, payload) => ({ ...state, rent_m_agents: payload }))

  .case(change_response_count, (state, payload) => ({ ...state, response_count: payload }))
  // .case(responsedetailSuccess, (state, payload) => ({
  //     ...state,
  //     responsedetail: payload,
  //     responseloding: 1,
  //     responseedit: 0
  // }))
  .case(responseListSuccess, (state, payload) => ({ ...state, responselist: payload }))

  .case(change_line_template_edit_open, (state, payload) => ({ ...state, line_template_edit_open: payload }))
  .case(change_line_schedule, (state, payload) => ({ ...state, line_schedule: payload }))
  .case(change_line_schedule_users, (state, payload) => ({ ...state, line_schedule_users: payload }))
  .case(change_line_schedule_detail, (state, payload) => ({ ...state, line_schedule_detail: payload }))

  .case(change_rent_line_templates_list, (state, payload) => ({ ...state, rent_line_templates_list: payload }))
  .case(change_rent_line_template, (state, payload) => ({ ...state, rent_line_template: payload }))
  .case(change_rent_line_template_detail, (state, payload) => ({ ...state, rent_line_template_detail: payload }))
  .case(change_file_update_disp, (state, payload) => ({ ...state, file_update_disp: payload }))
  .case(change_line_file_data, (state, payload) => ({ ...state, line_file_data: payload }))
  .case(change_rent_line_template_url, (state, payload) => ({ ...state, rent_line_template_url: payload }))

  .case(change_rent_line_schedule, (state, payload) => ({ ...state, rent_line_schedule: payload }))
  .case(change_rent_line_schedule_template_detail, (state, payload) => ({ ...state, rent_line_schedule_template_detail: payload }))

  .case(change_line_template_open, (state, payload) => ({ ...state, line_template_open: payload }))
  .case(change_line_schedule_open, (state, payload) => ({ ...state, line_schedule_open: payload }))

  .case(change_line_send_list_open, (state, payload) => ({ ...state, line_send_list_open: payload }))
  .case(change_rent_line_schedule_list, (state, payload) => ({ ...state, rent_line_schedule_list: payload }))

  .case(change_rent_line_schedule_customer_contact_list, (state, payload) => ({ ...state, rent_line_schedule_customer_contact_list: payload }))
  .case(change_rent_line_schedule_customer_contact_list_search, (state, payload) => ({ ...state, rent_line_schedule_customer_contact_list_search: payload }))
  .case(change_rent_line_schedule_detail, (state, payload) => ({ ...state, rent_line_schedule_detail: payload }))
  .case(change_line_send_search, (state, payload) => ({ ...state, line_send_search: payload }))
  
  .case(change_respnose_list_backdrop, (state, payload) => ({ ...state, respnose_list_backdrop: payload }));

  




// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const responselistSaga = [

  takeEvery('RESPONSE_LIST', handleRentReasonsesListRequest),


  takeEvery('GET_RESPONSE_MATCHING_LIST', handle_get_response_matching_list),
  takeEvery('GET_RESPONSE_MATCHING_SELECT_LIST', handle_get_response_matching_select_list),


  takeEvery('GET_INTEND_LIST', handle_get_intend_list),
  takeEvery('CHANGE_STATUS', handle_change_status),
  takeEvery('DELETE_MAIL', handle_delete_mail),
  takeEvery('GET_RESPONSE_LINE_MATCHING_LIST', handle_get_response_line_matching_list),
  takeEvery('GET_RESPONSE_CARD_MATCHING_LIST', handle_get_response_card_matching_list),

  takeEvery('LINE_BIND', handle_line_bind),
  takeEvery('CARD_BIND', handle_card_bind),
  takeEvery('DELETE_LINE_USER', handle_delete_line_user),
  takeEvery('SEARCH_RESPONSE_LIST', handle_search_response_list),
  takeEvery('SEARCH_RESPONSE_LIST2', handle_search_response_list2),
  takeEvery('GET_RENT_M_AGENTS', handle_get_rent_m_agents),

  takeEvery('DELETE_CARD', handle_delete_card),

  // takeEvery('GET_LINE_SCHEDULE', handle_get_line_schedule),
  // takeEvery('GET_LINE_SCHEDULE_USERS', handle_get_line_schedule_users),
  // takeEvery('GET_LINE_SCHEDULE_DETAIL', handle_get_line_schedule_detail),

  takeEvery('GET_RENT_LINE_TEMPLATES_LIST', handle_get_rent_line_templates_list),
  takeEvery('INSERT_RENT_LINE_TEMPLATE', handle_insert_rent_line_template),
  takeEvery('UPDATE_RENT_LINE_TEMPLATE', handle_update_rent_line_template),
  takeEvery('GET_RENT_LINE_TEMPLATE_DETAIL', handle_get_rent_line_template_detail),
  takeEvery('INSERT_RENT_LINE_TEMPLATE_DETAIL', handle_insert_rent_line_template_detail),
  takeEvery('DELETE_RENT_LINE_TEMPLATE_DETAIL', handle_delete_rent_line_template_detail),
  takeEvery('UPLOAD_LINE_TEMPLATE_FILE', handle_upload_line_template_file),
  takeEvery('UPDATE_LINE_SEND', handle_update_line_send),
  takeEvery('GET_RENT_LINE_SCHEDULE_LIST', handle_get_rent_line_schedule_list),
  takeEvery('GET_RENT_LINE_SCHEDULE_CUSTOMER_CONTACT_LIST', handle_get_rent_line_schedule_customer_contact_list),
  takeEvery('GET_RENT_LINE_SCHEDULE_CUSTOMER_CONTACT_LIST_SEARCH', handle_get_rent_line_schedule_customer_contact_list_search),
  takeEvery('GET_RENT_LINE_SCHEDULE_TEMPLATE_DETAIL', handle_get_rent_line_schedule_template_detail),
  takeEvery('DELETE_RENT_LINE_SCHEDULE', handle_delete_rent_line_schedule),

  takeEvery('DELETE_RENT_RESPONSE_CUSTOMER_CONTACTS', handle_delete_responselist_rent_customer_contacts),

];
// export default responsesSaga;




function* handleRentReasonsesListRequest(action: any): any {

  yield put(change_respnose_list_backdrop(true));

  const reset: any = [];
  yield put(responseListSuccess(reset));
  const state = yield select();
  let formData = state.responselist.formData
  //  if(action.payload){
  //   // if (action.payload[0].sectionId) {
  //     action.payload.sectionId=action.payload.sectionId.value

  // alert("list")
  // }

  let api:any
  if(formData.condition_radio == 2){
    api = RentResponsesApi.getlist_syokai
  } else { 
    api = RentResponsesApi.getlist
  }
  if(formData.section_id == 50){
    formData.section_id = 10
  }

  const { payload, error } = yield call(api, formData);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    console.log(payload)
    yield put(change_response_list(payload));
    yield put(search_response_list(payload));//リストを切り替えたらsearch_response_list2のみにする

    yield put(search_response_list2(payload));


    yield put(change_respnose_list_backdrop(false));

    // alert("list")
    // let responselist: any = [];
    // payload.map(function (value: any) {




    //   let arry1 = {
    //     id: value.id,
    //     flg: 1,
    //     request: 0,
    //     status: value.status,
    //     col1: value.coming_response_count,
    //     col2: value.name1 + value.rentor_name,
    //     col3: value.tel1,
    //     col4: value.res_name_set,
    //     col5: value.contract_building_name,
    //     col6: value.section_id,
    //     col7: value.rank,
    //     col8: value.contact_response_count,
    //     col9: value.date,
    //     col10: value.line_img_url,

    //   }

    //   responselist.push(arry1)
    //   let arry2 = {
    //     id: value.id,
    //     flg: 2,
    //     request: 0,
    //     status: value.status,
    //     col1: value.last_response_date,
    //     col2: value.building_name,
    //     col3: value.main_media_name,
    //     col4: value.path_name,
    //     col5: value.remarks,

    //   }


    //   responselist.push(arry2)
    //   let arry3 = {
    //     id: value.id,
    //     flg: 3,
    //     request: 0,
    //     status: value.status,
    //     col1: value.intended_at,
    //     col2: value.email1,
    //     col3: value.preferred_date,
    //     col5: value.intended_rent_m_response_status_id,

    //   }



    //   responselist.push(arry3);

    // });

    // // responselist=payload
    // yield put(responseListSuccess(responselist));
    // // yield put(responseListSuccess(payload));
    // yield put(change_responselist_loading(0));
  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}




function* handle_search_response_list(action: any): any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し
  let response_list: any = [];
  let response_list_set = state.responselist.response_list
  let formData = state.responselist.formData

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し



  let response_grid_list: any = [];
  let name1 = ""
  let email1 = ""




  if (formData.sort === 4) {

      response_list_set.sort(function (a: any, b: any) {
        if (new Date(a.display_at) < new Date(b.display_at)) {
          return 1;
        } else {
          return -1;
        }
      })
    
  } else if (formData.sort === 1) {

    if(formData.condition_radio != 2){
      response_list_set.sort(function (a: any, b: any) {
        if (new Date(a.last_response_date) < new Date(b.last_response_date)) {
          return 1;
        } else {
          return -1;
        }
      })
    }
  } else if (formData.sort === 2) {

    response_list_set.sort(function (a: any, b: any) {
      if (new Date(a.date) < new Date(b.date)) {
        return 1;
      } else {
        return -1;
      }
    })
  } else if (formData.sort === 3) {

    response_list_set.sort(function (a: any, b: any) {

      let an = 0

      if (a.rank === 0) {
        an = 10
      } else {
        an = a.rank
      }

      let bn = 0

      if (b.rank === 0) {
        bn = 10
      } else {
        bn = b.rank
      }

      if (an > bn) {
        return 1;
      } else {
        return -1;
      }
    })
  }

  // alert("1 " + response_list_set.length)
  if (formData.condition_radio == 0) {
    // response_list_set = response_list_set.filter((a: any) => a.intended_rent_m_response_status_id !== 3)//自動追客
    // response_list_set = response_list_set.filter((a: any) => ((a.coming_response_count && a.coming_response_count === 0) || a.request == 1 || a.request == 2 || a.request == 3))//反響のみ
    //&& !(a.intended_rent_m_response_status_id === 3
    response_list_set = response_list_set

  // } else if (formData.condition_radio == 2 && formData.condition === "2") {
  //   response_list_set = response_list_set.filter((a: any) => a.returning == 0)//初回来店

  // } else if (formData.condition_radio == 2 && formData.condition === "3") {
  //   response_list_set = response_list_set.filter((a: any) => a.returning > 0)//再来

  } else if (formData.condition_radio == 2) {// 来店
    response_list_set = response_list_set

  } else if (formData.condition_radio == 1) {// 契約
    response_list_set = response_list_set
  
  // } else {
  //   //自動追客じゃない
  //   response_list_set = response_list_set.filter((a: any) => (!a.intended_rent_m_response_status_id || a.intended_rent_m_response_status_id !== 3) && (!a.end_response_date || a.end_response_date === null))
  }
// alert("2 " + response_list_set.length)

  if (formData.intend === 1) {
    response_list_set = response_list_set.filter((a: any) => a.intend_coming === 1)//来店予定
  }
  if (formData.intend == 2) {

    response_list_set = response_list_set.filter((a: any) => a.intend_contact === 1)//追客予定
  }
// alert("3 " + response_list_set.length)

if(parseInt(formData.condition_radio) != 2){
  let mailresponse = response_list_set.filter((a: any) => a.request === 1)//反響

  let line = response_list_set.filter((a: any) => a.request === 2)//line

  let hearing = response_list_set.filter((a: any) => a.request === 3)//来店カード

  // let res1 = response_list_set.filter((a: any) => a.request === 0 && a.status === 1)//メール受信
  // let res2 = response_list_set.filter((a: any) => a.request === 0 && a.status === 12)//ライン受信
  // let res4 = response_list_set.filter((a: any) => a.request === 0 && a.status === 16)//来店予約
  // let res5 = response_list_set.filter((a: any) => a.request === 0 && a.status === 17)//お気に入り登録
  // let res6 = response_list_set.filter((a: any) => a.request === 0 && a.status === 18)//条件設定
  // let res3 = response_list_set.filter((a: any) => a.request === 0 && a.status !== 1 && a.status !== 12 && a.status !== 16 && a.status !== 17 && a.status !== 18)

  let res1 = response_list_set.filter((a: any) => a.request === 0 && a.receive_mail_at)//メール受信
  let res2 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && a.receive_line_at)//ライン受信
  let res4 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && (a.coming_reservation_at || a.coming_reservation_cancel_at))//来店予約
  let res5 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && !(a.coming_reservation_at || a.coming_reservation_cancel_at) && a.favorite_set_at)//お気に入り登録
  let res6 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && !(a.coming_reservation_at || a.coming_reservation_cancel_at) && !a.favorite_set_at && a.condition_set_at)//条件設定
  let res7 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && !(a.coming_reservation_at || a.coming_reservation_cancel_at) && !a.favorite_set_at && !a.condition_set_at && a.mypage_inquiry_at)//マイページ問合せ
  

  let res3 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && !(a.coming_reservation_at || a.coming_reservation_cancel_at) && !a.favorite_set_at && !a.condition_set_at && !a.mypage_inquiry_at)


// alert("4 " + response_list_set.length)

  if (mailresponse[0]) {
    response_list = response_list.concat(mailresponse)
  }

  if (line[0]) {
    response_list = response_list.concat(line)
  }
  if (hearing[0]) {

    response_list = response_list.concat(hearing)
  }
  if (res1[0]) {
    response_list = response_list.concat(res1)
  }
  if (res2[0]) {
    response_list = response_list.concat(res2)
  }
  if (res4[0]) {
    response_list = response_list.concat(res4)
  }
  if (res5[0]) {
    response_list = response_list.concat(res5)
  }
  if (res6[0]) {
    response_list = response_list.concat(res6)
  }
  if (res7[0]) {
    response_list = response_list.concat(res7)
  }

  if (res3[0]) {
    response_list = response_list.concat(res3)
  }


}else{
  response_list = response_list_set
}
  // alert("5 " + response_list.length)


  yield put(change_response_count(response_list.length));





  response_list.map(function (value: any) {
    // console.log(value.name1)
    // console.log(name1)
    // console.log(value.request)


    if (value.request === 1 && (value.name1 === name1 || (value.email1 === email1 && value.email1 != null && value.email1 !== ""))) {
      // console.log(value.request)

    } else {


      let arry1 = {
        id: value.id,
        line_account_section_id: value.line_account_section_id ? value.line_account_section_id : 0,
        flg: 1,
        request: value.request,
        status: value.status,
        date: value.date,
        shops: value.shops,
        intended_rent_m_response_status_id: value.intended_rent_m_response_status_id,
        section_id: value.section_id,
        col1: value.coming_response_count,
        col2: value.name1,
        col3: value.tel1,
        col4: value.attentions,
        staff: value.primary_user_name,
        col5: value.contract_building_name,
        col6: value.section_id,
        rank: value.rank,
        col8: value.contact_response_count,
        col9: value.date,
        col10: value.line_img_url,
        media: value.media,

      }

      response_grid_list.push(arry1)
      let arry2 = {
        id: value.id,
        line_account_section_id: value.line_account_section_id ? value.line_account_section_id : 0,
        flg: 2,
        date: value.date,
        request: value.request,
        status: value.status,
        shops: value.shops,
        section_id: value.section_id,
        last_response_date: value.last_response_date,
        col2: value.building_name,
        col3: value.main_media_name,
        col4: value.path_name,
        col5: value.remarks,
        media: value.media,
        rank: value.rank,
        intended_rent_m_response_status_id: value.intended_rent_m_response_status_id,
      }





      response_grid_list.push(arry2)
      let arry3 = {
        id: value.id,
        line_account_section_id: value.line_account_section_id ? value.line_account_section_id : 0,
        flg: 3,
        request: value.request,
        status: value.status,
        date: value.date,
        shops: value.shops,
        section_id: value.section_id,
        intended_at: value.intended_at,
        col2: value.email1,
        col3: value.preferred_date,
        col5: value.intended_rent_m_response_status_id,
        media: value.media,
        rank: value.rank,
        intended_rent_m_response_status_id: value.intended_rent_m_response_status_id,
        name: value.name ? value.name : value.name1,
        rent_m_medium_id: value.rent_m_medium_id,
        medium_remarks: value.medium_remarks,
        mypage_request_number_of_people: value.mypage_request_number_of_people,
        rent_m_area_id: value.rent_m_area_id,
        request_from_cost: value.request_from_cost,
        request_to_cost: value.request_to_cost,
        request_cost_remarks: value.request_cost_remarks,
        request_from_plan: value.request_from_plan,
        request_to_plan: value.request_to_plan,
        request_moving_time: value.request_moving_time,
      }



      response_grid_list.push(arry3);
    }

    name1 = value.name1
    email1 = value.email1
  });



  // responselist=payload
  yield put(responseListSuccess(response_grid_list));
  // yield put(responseListSuccess(payload));
  yield put(change_responselist_loading(0));



}

function* handle_search_response_list2(action: any): any {
  yield put(change_response_search_list([]));
  yield put(change_response_count(0));
  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し
  let response_list: any = [];
  let response_list_set = state.responselist.response_list
  let formData = state.responselist.formData

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し



  let response_grid_list: any = [];
  let name1 = ""
  let email1 = ""



  
  if (formData.sort === 4) {

    response_list_set.sort(function (a: any, b: any) {
      if (new Date(a.display_at) < new Date(b.display_at)) {
        return 1;
      } else {
        return -1;
      }
    })
  } else if (formData.sort === 1) {
    if(formData.condition_radio != 2){
      response_list_set.sort(function (a: any, b: any) {
        if (new Date(a.last_response_date) < new Date(b.last_response_date)) {
          return 1;
        } else {
          return -1;
        }
      })
    }else{
      response_list_set.sort(function (a: any, b: any) {
        if (new Date(a.raiten_date) < new Date(b.raiten_date)) {
          return 1;
        } else {
          return -1;
        }
      })
    }
  } else if (formData.sort === 2) {

    response_list_set.sort(function (a: any, b: any) {
      if (new Date(a.date) < new Date(b.date)) {
        return 1;
      } else {
        return -1;
      }
    })
  } else if (formData.sort === 3) {

    response_list_set.sort(function (a: any, b: any) {

      let an = 0

      if (a.rank === 0) {
        an = 10
      } else {
        an = a.rank
      }

      let bn = 0

      if (b.rank === 0) {
        bn = 10
      } else {
        bn = b.rank
      }

      if (an > bn) {
        return 1;
      } else {
        return -1;
      }
    })
  }

  let count = 0;

  
  let response_list_set_tmp = new Array();
  if(formData.condition_radio == 0){
    if (formData.condition.split(',').includes("6") && formData.condition.split(',').includes("1")) {
      response_list_set_tmp = response_list_set_tmp.concat(response_list_set.filter((a: any) => a.intended_rent_m_response_status_id === 3 || a.coming_response_count === 0 || a.request == 1 || a.request == 2 || a.request == 3))/// 
      // response_list_set_tmp = response_list_set_tmp.concat(response_list_set.filter((a: any) => a.coming_response_count === 0 || a.request == 2 || a.request == 3))
      count++
      count++
    }else if (formData.condition.split(',').includes("6") && !formData.condition.split(',').includes("1")) {
      response_list_set_tmp = response_list_set_tmp.concat(response_list_set.filter((a: any) => a.intended_rent_m_response_status_id === 3))/// 
      count++
    }else if (!formData.condition.split(',').includes("6") && formData.condition.split(',').includes("1")) {
      response_list_set_tmp = response_list_set_tmp.concat(response_list_set.filter((a: any) => (a.coming_response_count === 0 || a.request == 1 || a.request == 2 || a.request == 3 ) && a.intended_rent_m_response_status_id !== 3))/// 
      count++
    }
    if (formData.condition == "") {
      response_list_set_tmp = response_list_set
    }
    response_list_set = response_list_set_tmp
  } else if(formData.condition_radio == 2){

    if (formData.condition.split(',').includes("2") && !formData.condition.split(',').includes("3")) {
      response_list_set_tmp = response_list_set_tmp.concat(response_list_set.filter((a: any) => a.returning == 0))//初回来店
      count++
    }else if (!formData.condition.split(',').includes("2") && formData.condition.split(',').includes("3")) {
      response_list_set_tmp = response_list_set_tmp.concat(response_list_set.filter((a: any) => a.returning > 0))//再来店
      count++
    }else{
      response_list_set_tmp = response_list_set
    }

    response_list_set = response_list_set_tmp

  }


  // if(formData.section_id == 26){//反響対策課なら来店なしのみ
  //   response_list_set = response_list_set.filter((a:any)=> !(a.request== 0 && a.coming_response_count > 0))
  // }

  // alert(JSON.stringify(response_list_set))



    // if (formData.condition.split(',').includes("1")) {// a.coming_response_count === 0 || a.request == 2 || a.request == 3
    //   if (formData.condition.split(',').includes("2")) {// a.coming_response_count === 1
    //     if (formData.condition.split(',').includes("3")) {// a.coming_response_count > 1
    //       if (formData.condition.split(',').includes("6")) {// a.intended_rent_m_response_status_id === 3
    //         response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 0 || a.request == 2 || a.request == 3 || a.coming_response_count === 1 || a.coming_response_count > 1 || a.intended_rent_m_response_status_id === 3)//自動追客
    //       }else{
    //         response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 0 || a.request == 2 || a.request == 3 || a.coming_response_count === 1 || a.coming_response_count > 1)//初回来店
    //       }
    //     }else{// !3
    //       if (formData.condition.split(',').includes("6")) {
    //         response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 0 || a.request == 2 || a.request == 3 || a.coming_response_count === 1 || a.coming_response_count > 1 || a.intended_rent_m_response_status_id === 3)//自動追客
    //       }else{
    //         response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 0 || a.request == 2 || a.request == 3 || a.coming_response_count === 1 || a.coming_response_count > 1)//初回来店
    //       }
    //     }
    //     response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 0 || a.request == 2 || a.request == 3 || a.coming_response_count === 1 || a.coming_response_count > 1)//初回来店
    //   }else{// !2
    //     response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 0 || a.request == 2 || a.request == 3)//反響のみ
    //   }
      
    // }else{// !1

    // }



    // if (formData.condition.split(',').includes("1")) {
    //   response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 0 || a.request == 2 || a.request == 3)//反響のみ
    // }
  
    // if (formData.condition.split(',').includes("2")) {
    //   response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 1)//初回来店
    // }
  
    // if (formData.condition.split(',').includes("3")) {
    //   response_list_set = response_list_set.filter((a: any) => a.coming_response_count > 1)//初回来店
    // }

    // if (formData.condition.split(',').includes("6")) {
    //   response_list_set = response_list_set.filter((a: any) => a.intended_rent_m_response_status_id === 3)//自動追客
    // }






    // if (formData.condition.split(',').includes("4")) {
    //   // response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 1)//契約xx
    // }



  // if (Number(formData.condition) === 6) {

  //   response_list_set = response_list_set.filter((a: any) => a.intended_rent_m_response_status_id === 3)//自動追客
  // } else if (Number(formData.condition) === 5) {
  //   //すべて
  // } else {//反響
  //   response_list_set = response_list_set.filter((a: any) => (!a.intended_rent_m_response_status_id || a.intended_rent_m_response_status_id !== 3))//自動追客じゃない
  //   // response_list_set = response_list_set.filter((a: any) => (!a.intended_rent_m_response_status_id || a.intended_rent_m_response_status_id !== 3) && (!a.end_response_date || a.end_response_date === null))//自動追客じゃない
  // }

  // if (Number(formData.condition) === 2) {
  //   response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 1)//初回来店
  // } else if (Number(formData.condition) === 3) {

  //   response_list_set = response_list_set.filter((a: any) => a.coming_response_count > 1)//初回来店
  //   // } else if (Number(formData.condition) === 1) {//反響のみ
  //   //   response_list_set = response_list_set.filter((a: any) => a.coming_response_count === 0)//反響のみ
  // }

  if (formData.intend === 1) {
    response_list_set = response_list_set.filter((a: any) => a.intend_coming === 1)//来店予定
  }
  if (formData.intend === 2) {

    response_list_set = response_list_set.filter((a: any) => a.intend_contact === 1)//追客予定
  }

// alert("6 " + response_list_set.length)
  if (formData.name) {
    // console.log(response_list_set)
    count++
    response_list_set = response_list_set.filter((a: any) => (a.name1 && han2zen(kanaToHira(hankana2Zenkana(a.name1))).indexOf(han2zen(kanaToHira(hankana2Zenkana(formData.name)))) !== -1) ||
      (a.kana1 && kanaToHira(hankana2Zenkana(a.kana1)).indexOf(kanaToHira(hankana2Zenkana(formData.name))) !== -1) ||
      (a.name2 && kanaToHira(hankana2Zenkana(a.name2)).indexOf(kanaToHira(hankana2Zenkana(formData.name))) !== -1) ||
      (a.kana2 && kanaToHira(hankana2Zenkana(a.kana2)).indexOf(kanaToHira(hankana2Zenkana(formData.name))) !== -1))//名前　〇
  }

  if (formData.building_name) {
    count++
    response_list_set = response_list_set.filter((a: any) => (a.building_name && han2zen(kanaToHira(hankana2Zenkana(a.building_name))).indexOf(han2zen(kanaToHira(hankana2Zenkana(formData.building_name)))) != -1) || (a.contract_building_name && han2zen(kanaToHira(hankana2Zenkana(a.contract_building_name))).indexOf(han2zen(kanaToHira(hankana2Zenkana(formData.building_name)))) != -1))//物件名〇
  }

  if (formData.response_user_id) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.user_id === formData.response_user_id)//反響担当
  }
  if (formData.primary_user_id) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.primary_user_id === formData.primary_user_id)//追客担当
  }
  if (formData.coming_user_id) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.coming_user_id === formData.coming_user_id)//来店担当
  }
  if (formData.going_user_id) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.going_user_id === formData.going_user_id)//案内担当
  }
  if (formData.web_user_id) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.web_user_id === formData.web_user_id)//追客担当
  }
  if (formData.contract_user_id) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.contract_user_id === formData.contract_user_id)//契約担当
  }
  if (formData.response_date_start) {
    // count++
      response_list_set = response_list_set.filter((a: any) => (a.date && moment(a.date).format("YYYYMMDD") >= moment(formData.response_date_start).format("YYYYMMDD")))//反響日開始〇
  }

  if (formData.response_date_end) {
    count++
    response_list_set = response_list_set.filter((a: any) => (a.date && moment(a.date).format("YYYYMMDD") <= moment(formData.response_date_end).format("YYYYMMDD")))//反響日終了〇
  }

  if(formData.condition_radio == 0){
    if (formData.check_application) {
      if (formData.application_date_start) {
        count++
        response_list_set = response_list_set.filter((a: any) => a.application_date && moment(a.application_date).format("YYYYMMDD") >= moment(formData.application_date_start).format("YYYYMMDD"))//申込日開始〇
      }
      if (formData.application_date_end) {
        count++
        response_list_set = response_list_set.filter((a: any) => a.application_date && moment(a.application_date).format("YYYYMMDD") <= moment(formData.application_date_end).format("YYYYMMDD"))//申込日終了〇
      }
    }
  }

  if(formData.condition_radio == 2){
    if (formData.coming_date_start) {
      // count++
      response_list_set = response_list_set.filter((a: any) => a.raiten_date && moment(a.raiten_date).utc().format("YYYYMMDD") >= moment(formData.coming_date_start).format("YYYYMMDD"))//来店日開始〇
    }
    if (formData.coming_date_end) {
      count++
      response_list_set = response_list_set.filter((a: any) => a.raiten_date && moment(a.raiten_date).utc().format("YYYYMMDD") <= moment(formData.coming_date_end).format("YYYYMMDD"))//来店日終了〇
    }
  }
  if(formData.condition_radio == 1){
    if (formData.contract_date_start) {
      // count++
      response_list_set = response_list_set.filter((a: any) => a.contract_date && moment(a.contract_date).format("YYYYMMDD") >= moment(formData.contract_date_start).format("YYYYMMDD"))//契約日開始〇
    }
    if (formData.contract_date_end) {
      count++
      response_list_set = response_list_set.filter((a: any) => a.contract_date && moment(a.contract_date).format("YYYYMMDD") <= moment(formData.contract_date_end).format("YYYYMMDD"))//契約日終了〇
    }
  }

  if (formData.tel1) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.tel1 && a.tel1.replace(/[^0-9]/g, '').indexOf(formData.tel1.replace(/[^0-9]/g, '')) != -1)//Tel〇
  }
  if (formData.rank.length > 0) {
    // alert(formData.rank)
    count++
    // response_list_set = response_list_set.filter((a: any) => a.rank && formData.rank.includes(a.rank))//ランク
    response_list_set = response_list_set.filter((a: any) => a.rank >= 0 && formData.rank.includes(a.rank))//ランク
  }
  if (formData.moving_time.length > 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.request_moving_time && formData.moving_time.includes(a.request_moving_time))//引越時期〇
  }
  if (formData.way.length > 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.rent_m_way_id && formData.way.includes(a.rent_m_way_id))//反響経路　〇
  }
  if (formData.media.length > 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.rent_m_medium_id && formData.media.includes(a.rent_m_medium_id))//反響媒体 〇
  }
  if (formData.agent.length > 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.rent_m_agent_id && formData.agent.includes(a.rent_m_agent_id))//代行会社〇
  }
  if (formData.email) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.email1 && a.email1.indexOf(formData.email) != -1)//メール〇
  }
  if (formData.reason.length > 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.rent_m_reason_id && formData.reason.includes(a.rent_m_reason_id))//引越理由
  }


  if (formData.from_cost > 0 && formData.to_cost === 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.request_from_cost >= formData.from_cost || a.request_to_cost >= formData.from_cost)
  } else if (formData.to_cost > 0 && formData.from_cost === 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => ((a.request_from_cost > 0 && a.request_to_cost === 0 && a.request_from_cost <= formData.to_cost) ||
      (a.request_from_cost === 0 && a.request_to_cost > 0 && a.request_to_cost >= formData.to_cost) ||
      (a.request_from_cost > 0 && a.request_to_cost > 0 && a.request_to_cost >= formData.to_cost)))
  } else if (formData.from_cost > 0 && formData.to_cost > 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => (a.request_from_cost <= formData.to_cost && formData.to_cost <= a.request_to_cost) ||
      (a.request_from_cost <= formData.from_cost && formData.from_cost <= a.request_to_cost) ||
      (formData.from_cost <= a.request_from_cost && a.request_to_cost <= formData.to_cost))

  }


  if (formData.from_madori > 0 && formData.to_madori === 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.request_from_plan >= formData.from_madori || a.request_to_plan >= formData.from_madori)
  } else if (formData.to_madori > 0 && formData.from_madori === 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => ((a.request_from_plan > 0 && a.request_to_plan === 0 && a.request_from_plan <= formData.to_madori) ||
      (a.request_from_plan === 0 && a.request_to_plan > 0 && a.request_to_plan >= formData.to_madori) ||
      (a.request_from_plan > 0 && a.request_to_plan > 0 && a.request_to_plan >= formData.to_madori)))
  } else if (formData.from_madori > 0 && formData.to_madori > 0) {
    count++
    response_list_set = response_list_set.filter((a: any) => (a.request_from_plan <= formData.to_madori && formData.to_madori <= a.request_to_plan) ||
      (a.request_from_plan <= formData.from_madori && formData.from_madori <= a.request_to_plan) ||
      (formData.from_madori <= a.request_from_plan && a.request_to_plan <= formData.to_madori))

  }

  if (formData.area) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.areas && a.areas.split(',').includes(String(formData.area)))//エリア
  }
  if (formData.requests) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.response_requests && a.response_requests.split(',').includes(String(formData.requests)))// こだわり設備〇
  }
  if (formData.contract_form && !formData.other_contract_form) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.contract_form === 1)// 法人〇
  }
  if (!formData.contract_form && formData.other_contract_form) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.contract_form !== 1)// 法人〇
  }
  if (formData.student && !formData.other_student) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.student_independent === 2)// 学生〇
  }else if (!formData.student && formData.other_student) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.student_independent !== 2)// 学生以外
  }
  if (formData.end_response === 1) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.end_response_date === null)// 追客状況 追客中〇
  }
  if (formData.end_response === 2) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.end_response_date !== null)// 追客状況 追客終了〇
  }
  if (formData.end_response === 3) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.coming_response_count > 0)// 追客状況 来店済み了〇
  }
  if (formData.gakusei_yoyaku) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.gakusei_yoyaku)// 学生予約〇
  }
  // alert(response_list_set.length)
  if(formData.condition_radio == 0 || formData.condition_radio == 2){

    if (formData.check_application) {//申込

      if (formData.check_end_response && !formData.check_progress_response) {//申込 追客修了
        // alert()
        response_list_set = response_list_set.filter((a: any) => a.end_response_date != null || (a.rent_contract_id > 0 && [0,1].includes(a.counting)))
        count++
      }else if (!formData.check_end_response && formData.check_progress_response) {//申込 追客中 ●
        // alert()
        response_list_set = response_list_set.filter((a: any) => (a.end_response_date == null && !(a.rent_contract_id > 0)) || (a.rent_contract_id > 0 && [0,1].includes(a.counting)))
        count++
      }else if (formData.check_end_response && formData.check_progress_response) {//申込 追客中 追客修了 ●
        response_list_set = response_list_set.filter((a: any) => !(a.rent_contract_id > 0) || (a.rent_contract_id > 0 && [0,1].includes(a.counting)))
        count++
      }else if (!formData.check_end_response && !formData.check_progress_response) {//申込　●
        // alert()
        response_list_set = response_list_set.filter((a: any) => (a.rent_contract_id > 0 && [0,1].includes(a.counting)))
        count++
      }

    }else{
      if (formData.check_end_response && !formData.check_progress_response) {//追客修了 ●
        // alert()
        response_list_set = response_list_set.filter((a: any) => a.end_response_date != null && !(a.rent_contract_id > 0))
        count++
      }else if (!formData.check_end_response && formData.check_progress_response) {//追客中 ●
        // alert()
        response_list_set = response_list_set.filter((a: any) => a.end_response_date == null && !(a.rent_contract_id > 0))
        count++
      }else if (formData.check_end_response && formData.check_progress_response) {//追客中　追客修了 ●
        // alert()
        response_list_set = response_list_set.filter((a: any) => !(a.rent_contract_id > 0))
        count++
      }else{//チェックなし
        // response_list_set = response_list_set.filter((a: any) => a.rent_contract_id == 0)
      }
    }
  }
  // alert(response_list_set.length)



  if (formData.jyukenyotei) {// 受験予定
    count++
    formData.jyukenyotei.split(",").forEach((e: any) => {
      response_list_set = response_list_set.filter((a: any) => a.jyukenyotei && a.jyukenyotei.split(",").indexOf(e) !== -1)
    });

  }
  if (formData.rent_m_school_id) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.rent_m_school_id && a.rent_m_school_id === formData.rent_m_school_id)// 学校
  }
  if (formData.line_img_url) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.line_img_url)// lineあり
  }
  if (formData.line_img_url && formData.unfollowed) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.unfollowed_at)// lineあり
  }
  if (formData.line_send) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.line_img_url)// lineあり
  }

  if (formData.gouhi) {
    count++
    response_list_set = response_list_set.filter((a: any) => a.gouhi && a.gouhi === formData.gouhi)// 合否
  }

  yield put(change_form_count(count));




if(parseInt(formData.condition_radio) == 2){
  response_list = response_list_set
}else{
  let mailresponse = response_list_set.filter((a: any) => a.request === 1)//反響

  let line = response_list_set.filter((a: any) => a.request === 2)//line
  let hearing = response_list_set.filter((a: any) => a.request === 3)//来店カード
  // let res1 = response_list_set.filter((a: any) => a.request === 0 && a.status === 1)//メール受信
  // let res2 = response_list_set.filter((a: any) => a.request === 0 && a.status === 12)//LINE受信
  // let res4 = response_list_set.filter((a: any) => a.request === 0 && a.status === 16)//来店予約
  // let res5 = response_list_set.filter((a: any) => a.request === 0 && a.status === 17)//お気に入り登録
  // let res6 = response_list_set.filter((a: any) => a.request === 0 && a.status === 18)//条件設定
  // let res3 = response_list_set.filter((a: any) => a.request === 0 && a.status !== 1 && a.status !== 12 && a.status !== 16 && a.status !== 17 && a.status !== 18)

  let res1 = response_list_set.filter((a: any) => a.request === 0 && a.receive_mail_at)//メール受信
  let res2 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && a.receive_line_at)//LINE受信
  let res4 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && (a.coming_reservation_at || a.coming_reservation_cancel_at))//来店予約
  let res5 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && !(a.coming_reservation_at || a.coming_reservation_cancel_at) && a.favorite_set_at)//お気に入り登録
  let res6 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && !(a.coming_reservation_at || a.coming_reservation_cancel_at) && !a.favorite_set_at && a.condition_set_at)//条件設定
  let res7 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && !(a.coming_reservation_at || a.coming_reservation_cancel_at) && !a.favorite_set_at && !a.condition_set_at && a.mypage_inquiry_at)//マイページ問合せ
  

  let res3 = response_list_set.filter((a: any) => a.request === 0 && !a.receive_mail_at && !a.receive_line_at && !(a.coming_reservation_at || a.coming_reservation_cancel_at) && !a.favorite_set_at && !a.condition_set_at && !a.mypage_inquiry_at)











  if (mailresponse[0]) {
    response_list = response_list.concat(mailresponse)
  }

  if (line[0]) {
    response_list = response_list.concat(line)
  }
  if (hearing[0]) {
    response_list = response_list.concat(hearing)
  }

  if (res1[0]) {
    response_list = response_list.concat(res1)
  }
  if (res2[0]) {
    response_list = response_list.concat(res2)
  }
  if (res4[0]) {
    response_list = response_list.concat(res4)
  }
  if (res5[0]) {
    response_list = response_list.concat(res5)
  }
  if (res6[0]) {
    response_list = response_list.concat(res6)
  }
  if (res7[0]) {
    response_list = response_list.concat(res7)
  }
  if (res3[0]) {
    response_list = response_list.concat(res3)
  }

}




  yield put(change_response_count(response_list.length));


  // alert(response_list.length)


  yield put(change_response_search_list(response_list));
  // responselist=payload
  // yield put(responseListSuccess(response_grid_list));
  // yield put(responseListSuccess(payload));
  // yield put(change_responselist_loading(0));

  function kanaToHira(str: string) {
    return String(str).replace(/[\u30a1-\u30f6]/g, function (match) {
      var chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  }


  function han2zen(str: string) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }
  function hankana2Zenkana(str: string) {
    var kanaMap: any = {
      'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
      'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
      'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
      'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
      'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
      'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
      'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
      'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
      'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
      'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
      'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
      'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
      'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
      'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
      'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
      'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
      'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
      'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
      '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
    };

    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    if (str) {
      return str
        .replace(reg, function (match) {
          return kanaMap[match];
        })
        .replace(/ﾞ/g, '゛')
        .replace(/ﾟ/g, '゜');
    } else {
      return str
    }
  };

}


function* handle_get_response_matching_list(action: any) {

  yield put(change_respnose_list_backdrop(true));

  yield put(change_response_matching_list_loading(1));
  const reset: any = [];
  yield put(get_response_matching_list_success(reset));

  const { payload, error } = yield call(RentResponsesApi.matcing_list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  console.log(payload)
  if (payload && !error) {
    yield put(get_response_matching_list_success([]));
    yield put(change_response_matching_list_loading(0));
    // yield put(change_responselist_loading(0));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
  yield put(change_respnose_list_backdrop(false));
}
function* handle_get_response_matching_select_list(action: any) {

  yield put(change_response_matching_select_list_loading(1));
  const reset: any = [];
  yield put(get_response_matching_select_list_success(reset));
  const { payload, error } = yield call(RentResponsesApi.matcing_select_list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  console.log(payload)
  if (payload && !error) {

    console.log(payload)
    //  alert("dd")
    const payloadset = payload.map(function (x: any) {
      x.selected = false;

      return x;
    })
    console.log(payloadset)
    // alert("ddd")
    yield put(get_response_matching_select_list_success(payloadset));
    yield put(change_response_matching_select_list_loading(0));
    // yield put(change_responselist_loading(0));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}










function* handle_get_intend_list(action: any) {
  // yield put(change_responselist_loading(1));
  // let { payload } = action;
  // const reset:any=[];
  // yield put(responseListSuccess(reset));


  const { payload, error } = yield call(RentResponsesApi.intend_list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  if (payload && !error) {
    // 成功時アクション呼び出し
    console.log(payload)
    yield put(get_intend_list_success(payload));


    const calendar = payload.map((item: any, i: number) => ({
      id: item.id,
      intend: item.intend,
      title: (item.way == 1 ? "電話" : item.way == 2 ? "メール" : "来店") + (item.intend === 1 ? "予定" : ""),
      allDay: true,
      start: item.date,
      end: item.date,
      contents: item
    }))
    yield put(get_intend_list__calendar_success(calendar));

    // yield put(change_customer_logs_loading(0));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}





function* handle_change_status(action: any): any {

  console.log(action.payload)
  // return

  const { payload, error } = yield call(RentResponsesChangeStatus.change, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  if (payload && !error) {
    // 成功時アクション呼び出し
    // yield put(submit('roomeditform'));
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let response_list = state.responselist.response_list
    let response_search_list = state.responselist.response_search_list

    let result = response_list.map(function (value: any) {
      if(value.id == action.payload.id){
        value.status = 6;

        value[action.payload.status] = null

      }
      return value;
    })

    yield put(change_response_list(result));

    result = response_search_list.map(function (value: any) {
      if(value.id == action.payload.id){
        value.status = 6;
      }
      return value;
    })

    yield put(change_response_search_list(result));


    // yield put(responseList(state.responselist.formData));
    // yield put(change_customer_logs_loading(0));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}

function* handle_line_bind(action: any): any {

  const { payload, error } = yield call(rent_responses_api.linebind, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  if (payload && !error) {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し
    yield put(responseList(state.responselist.formData));

    yield put(change_modalLineMatching(false));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}

function* handle_card_bind(action: any): any {

  const { payload, error } = yield call(rent_responses_api.cardbind, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  if (payload && !error) {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し
    yield put(responseList(state.responselist.formData));

    yield put(change_modalCardMatching(false));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}


function* handle_delete_mail(action: any) {
  yield put(change_response_matching_list_loading(1));
  yield put(get_response_matching_list_success([]));
  yield put(change_response_matching_select_list_loading(1));
  yield put(get_response_matching_select_list_success([]));

  const { payload, error } = yield call(MailOutRespnoseApi.outresponse, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  if (payload && !error) {
    // 成功時アクション呼び出し
    // yield put(submit('formset'));

    yield put(get_response_matching_list(action.payload));
    yield put(get_response_matching_select_list(action.payload));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}



function* handle_get_response_line_matching_list(action: any) {

  yield put(change_response_matching_list_loading(1));


  const { payload, error } = yield call(rent_responses_api.getsearchlist, action.payload);




  if (payload && !error) {
    // 成功時アクション呼び出し
    // yield put(submit('formset'));
    yield put(get_response_line_matching_list_success(payload));


    yield put(change_response_matching_list_loading(0));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}

function* handle_get_response_card_matching_list(action: any) {

  yield put(change_response_matching_list_loading(1));


  const { payload, error } = yield call(rent_responses_api.getsearchlist, action.payload);




  if (payload && !error) {
    // 成功時アクション呼び出し
    // yield put(submit('formset'));
    yield put(get_response_card_matching_list_success(payload));


    yield put(change_response_matching_list_loading(0));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}




function* handle_delete_line_user(action: any): any {

  const { payload, error } = yield call(line_users_api.del_user, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し
    yield put(responseList(state.responselist.formData));
    // 成功時アクション呼び出し
    // yield put(get_line_user_details_response_success(payload))
    // yield put(get_line_user_details_success(payload))

    // yield put(get_lines({ "line_user_id": payload.id, "line_account_section_id": payload.line_account_section_id, "take": 20 }))
    // yield put(change_get_mail_details_loading(0));


  } else {


  }
}

function* handle_get_rent_m_agents(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_agents_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_m_agents(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}

function* handle_delete_card(action: any):any {

  const state = yield select();

  const { payload, error } = yield call(rent_hearing_sheets_api.delete_card, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    let response_search_list = state.responselist.response_search_list.filter((a:any) => parseInt(a.rent_hearing_sheet_id) != parseInt(action.payload.rent_hearing_sheet_id))

    yield put(change_response_search_list(response_search_list));
    // 成功時アクション呼び出し
    // yield put(get_line_user_details_response_success(payload))
    // yield put(get_line_user_details_success(payload))

    // yield put(get_lines({ "line_user_id": payload.id, "line_account_section_id": payload.line_account_section_id, "take": 20 }))
    // yield put(change_get_mail_details_loading(0));


  } else {


  }
}

// LINEテンプレートリスト
function* handle_get_rent_line_templates_list(action: any):any {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms

  const state = yield select();
  let user_details = state.masterReducer.user_details

  const { payload, error } = yield call(rent_line_templates_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_line_templates_list(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }

}
// LINEテンプレート詳細　取得
function* handle_get_rent_line_template_detail(action: any):any {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms

  const state = yield select();

  const { payload, error } = yield call(rent_line_template_details_api.detail, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_line_template_detail(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }

}
// LINEテンプレートタイトル　新規
function* handle_insert_rent_line_template(action: any):any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let rent_line_templates_list = state.responselist.rent_line_templates_list
  let rent_line_template = state.responselist.rent_line_template
  let rent_line_template_detail = state.responselist.rent_line_template_detail
  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_line_templates_api.insert, {rent_line_template: rent_line_template, rent_line_template_detail: rent_line_template_detail});


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
      // console.log(payload)
      
      rent_line_template.id = payload // idを保存
      let list = rent_line_templates_list.concat(rent_line_template)

      // // 成功時アクション呼び出し
      yield put(change_rent_line_templates_list(list))
      // 情報更新
      yield put(change_rent_line_template(rent_line_template))

  } else {
      // 失敗時アクション呼び出し
      // yield put(loginFailure(payload));
  }


}
// LINEテンプレートタイトル　更新
function* handle_update_rent_line_template(action: any):any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let rent_line_templates_list = state.responselist.rent_line_templates_list
  let rent_line_template = state.responselist.rent_line_template
  let rent_line_template_detail = state.responselist.rent_line_template_detail
  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_line_templates_api.update, {rent_line_template: rent_line_template, rent_line_template_detail: rent_line_template_detail});


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
      // console.log(payload)
      
      // rent_line_template.id = payload // idを保存
      // let list = rent_line_templates_list.concat(rent_line_template)

      if(rent_line_template.is_deleted == 1){
        //一覧から削除
        let result = rent_line_templates_list.filter((value: any) => {
          return value.id != rent_line_template.id;
        });
        rent_line_templates_list = result

        // 成功時アクション呼び出し
        yield put(change_rent_line_templates_list(rent_line_templates_list))
      }

  } else {
      // 失敗時アクション呼び出し
      // yield put(loginFailure(payload));
  }


}
// LINEテンプレート詳細　追加
function* handle_insert_rent_line_template_detail(action: any):any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  // let rent_line_templates_list = state.responselist.rent_line_templates_list
  let rent_line_template = state.responselist.rent_line_template
  // let rent_line_template_detail = state.responselist.rent_line_template_detail
  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_line_template_details_api.insert, {rent_line_template_id: rent_line_template.id, rent_line_template_detail: action.payload});


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
      // console.log(payload)
      
      // rent_line_template.id = payload // idを保存
      // let list = rent_line_templates_list.concat(rent_line_template)

      // // // 成功時アクション呼び出し
      // yield put(change_rent_line_templates_list(list))
      // // 情報更新
      // yield put(change_rent_line_template(rent_line_template))

  } else {
      // 失敗時アクション呼び出し
      // yield put(loginFailure(payload));
  }


}
// LINEテンプレート詳細　削除
function* handle_delete_rent_line_template_detail(action: any):any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let rent_line_templates_list = state.responselist.rent_line_templates_list
  let rent_line_template = state.responselist.rent_line_template
  let rent_line_template_detail = state.responselist.rent_line_template_detail

  
  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_line_template_details_api.detail_delete, rent_line_template.id, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
      // console.log(payload)

      yield put(change_rent_line_template_detail([]))
    // alert(action.payload)
      rent_line_template_detail = rent_line_template_detail.filter((a:any)=>a.no != action.payload)
      yield put(change_rent_line_template_detail(rent_line_template_detail))
      
      // rent_line_template.id = payload // idを保存
      // let list = rent_line_templates_list.concat(rent_line_template)

      // // // 成功時アクション呼び出し
      // yield put(change_rent_line_templates_list(list))
      // // 情報更新
      // yield put(change_rent_line_template(rent_line_template))

  } else {
      // 失敗時アクション呼び出し
      // yield put(loginFailure(payload));
  }


}
// LINEテンプレート画像アップロード
function* handle_upload_line_template_file(action: any): any {

  // yield put(change_contract_files_loading(true));
  const state = yield select();
  console.log(state.responselist.line_file_data)


// alert(state.responsesReducer.responseid)
// alert(state.contracts.rent_contract_id)
  const { payload, error } = yield call(rent_line_template_docs_api.upload, {file: state.responselist.line_file_data});

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    let rent_line_template_detail = state.responselist.rent_line_template_detail
    let maxNo = rent_line_template_detail.length > 0 ? Math.max.apply(null,rent_line_template_detail.map(function(o:any){return o.no;})) : -1
      let detail = {no:maxNo+1,
        message_type:action.payload == 0 ? "url" : "image",
        mime_type: payload.mimetype,
        mime: payload.extension,
        file_id:payload.id,
        url:state.responselist.rent_line_template_url,
        message_text:'',}
      
      // 成功時アクション呼び出し
      yield put(change_rent_line_template_detail(rent_line_template_detail.concat(detail)))
      yield put(insert_rent_line_template_detail(detail))
      yield put(change_rent_line_template_url(""))
      

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}
// LINE一斉送信登録
function* handle_update_line_send(action: any):any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let rent_line_schedule = state.responselist.rent_line_schedule
  rent_line_schedule.section_id = state.masterReducer.user_details.amSectionId
  rent_line_schedule.user_id = state.masterReducer.user_details.id

  const { payload, error } = yield call(rent_line_schedules_api.insert, rent_line_schedule);

  if (payload && !error) {

    let response_search_list = state.responselist.response_search_list.filter((a:any)=>a.line_send == true)

    for(let i = 0; i < response_search_list.length; i++){

      let rent_customer_contacts = {
        rent_line_schedule_id: payload,
        date: rent_line_schedule.schedule_at,
        rent_response_id: response_search_list[i].id,
        section_id: state.masterReducer.user_details.amSectionId,
        user_id: state.masterReducer.user_details.id,
        remarks: 'LINE自動送信',
        intend_time: 0, 
        intend_time2: 0,
        going_time: 0,
        going_user_id: 0,
        is_application: 0,
        rent_m_going_reason_id: 0,
        going_section: -1,
        online: 0,
        no_coming: 0,
        is_prepare: 0,
        way: '4',
        agent_name: '',
        agent_etc: '',
        going: '0',
        rent_m_response_status_id: 15,
        intend: 1,
        id: 0,
        title: '',

      }

      // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
      console.log(rent_customer_contacts)
      const { payload2, error2 } = yield call(rent_customer_contacts_api.update, { rent_customer_contacts: rent_customer_contacts, rent_visits: {} });


      // サーバーからのレスポンスデータによる分岐処理
      //if (ログイン成功か失敗かの条件) {
      // 成功時アクション呼び出し

      if (payload2 && !error2) {
          // console.log(payload)

          // if(rent_line_template.is_deleted == 1){
          //   //一覧から削除
          //   let result = rent_line_templates_list.filter((value: any) => {
          //     return value.id != rent_line_template.id;
          //   });
          //   rent_line_templates_list = result

          //   // 成功時アクション呼び出し
          //   yield put(change_rent_line_templates_list(rent_line_templates_list))
          // }

      } else {
          // 失敗時アクション呼び出し
          // yield put(loginFailure(payload));
      }
    } 
  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }

}
// LINE送信履歴リスト
function* handle_get_rent_line_schedule_list(action: any):any {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms

  const state = yield select();
  let user_details = state.masterReducer.user_details

  const { payload, error } = yield call(rent_line_schedules_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_line_schedule_list(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }

}
// LINE送信履歴リスト　customer_contactリスト取得
function* handle_get_rent_line_schedule_customer_contact_list(action: any):any {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms

  const state = yield select();
  let user_details = state.masterReducer.user_details

  const { payload, error } = yield call(rent_customer_contacts_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_line_schedule_customer_contact_list(payload));
    yield put(change_rent_line_schedule_customer_contact_list_search(payload));
  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }

}
// LINE送信履歴リスト　customer_contactリスト取得 検索
function* handle_get_rent_line_schedule_customer_contact_list_search(action: any):any {

  const state = yield select();

  // let response_list: any = [];
  // let response_list_set = state.responselist.response_list

  let rent_line_schedule_customer_contact_list = state.responselist.rent_line_schedule_customer_contact_list
  let line_send_search = state.responselist.line_send_search
  let rent_line_schedule_customer_contact_list_tmp: any = [];

  if(line_send_search.condition != ""){
    if (line_send_search.condition.split(',').includes("1")) {// 来店済み

      if (line_send_search.condition.split(',').includes("2")) {// 追客終了

        if (line_send_search.condition.split(',').includes("3")) {// 成約済
          // ●来店済み、●追客終了、●成約済
          rent_line_schedule_customer_contact_list = rent_line_schedule_customer_contact_list.filter((a: any) => a.coming_response_count > 0 || a.end_response_date || a.rent_contract_id > 0)
        
        }else{
          // ●来店済み、●追客終了、成約済
          rent_line_schedule_customer_contact_list = rent_line_schedule_customer_contact_list.filter((a: any) => (a.coming_response_count > 0 || a.end_response_date) && a.rent_contract_id == 0)
        
        }

      }else{

        if (line_send_search.condition.split(',').includes("3")) {// 成約済
          // ●来店済み、追客終了、●成約済
          rent_line_schedule_customer_contact_list = rent_line_schedule_customer_contact_list.filter((a: any) => (a.coming_response_count > 0 && !a.end_response_date) || a.rent_contract_id > 0)
        
        }else{
          // ●来店済み、追客終了、成約済
          rent_line_schedule_customer_contact_list = rent_line_schedule_customer_contact_list.filter((a: any) => a.coming_response_count > 0 && !a.end_response_date && a.rent_contract_id == 0)
        
        }

      }
      
    }else{
      if (line_send_search.condition.split(',').includes("2")) {// 追客終了

        if (line_send_search.condition.split(',').includes("3")) {// 成約済
          // 来店済み、●追客終了、●成約済
          rent_line_schedule_customer_contact_list = rent_line_schedule_customer_contact_list.filter((a: any) => a.end_response_date || a.rent_contract_id > 0)
        
        }else{
          // 来店済み、●追客終了、成約済
          rent_line_schedule_customer_contact_list = rent_line_schedule_customer_contact_list.filter((a: any) => a.end_response_date)
        
        }

      }else{
        
        if (line_send_search.condition.split(',').includes("3")) {// 成約済
          // 来店済み、追客終了、●成約済
          rent_line_schedule_customer_contact_list = rent_line_schedule_customer_contact_list.filter((a: any) => a.rent_contract_id > 0)
        
        }else{
          // 来店済み、追客終了、成約済

        }

      }
    }
    
    
  }


  yield put(change_rent_line_schedule_customer_contact_list_search(rent_line_schedule_customer_contact_list));


}

// 個別削除
function* handle_delete_responselist_rent_customer_contacts(action: any): any {

  const state = yield select();
  let rent_line_schedule_customer_contact_list = state.responselist.rent_line_schedule_customer_contact_list

  // alert()
  const { payload, error } = yield call(rent_customer_contacts_api.contact_delete, action.payload);


  if (payload && !error) {
      console.log(payload)

      rent_line_schedule_customer_contact_list = rent_line_schedule_customer_contact_list.filter((a:any) => parseInt(a.id) != parseInt(action.payload))

      yield put(change_rent_line_schedule_customer_contact_list(rent_line_schedule_customer_contact_list));
      yield put(get_rent_line_schedule_customer_contact_list_search([]));
      

  } else {
      //    // 失敗時アクション呼び出し

  }
}
// LINEテンプレート詳細　取得 小窓用
function* handle_get_rent_line_schedule_template_detail(action: any):any {

  const state = yield select();

  const { payload, error } = yield call(rent_line_template_details_api.detail, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_line_schedule_template_detail(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }

}
// 予定削除
function* handle_delete_rent_line_schedule(action: any): any {

  const state = yield select();
  let rent_line_schedule_customer_contact_list = state.responselist.rent_line_schedule_customer_contact_list
  let rent_line_schedule_list = state.responselist.rent_line_schedule_list

  for(let i=0; i < rent_line_schedule_customer_contact_list.length; i++){

    const { payload, error } = yield call(rent_customer_contacts_api.contact_delete, rent_line_schedule_customer_contact_list[i].id);

    if (payload && !error) {
        console.log(payload)

    } else {
        //    // 失敗時アクション呼び出し

    }

  }

  const { payload, error } = yield call(rent_line_schedules_api.schedule_delete, action.payload);


    if (payload && !error) {
        console.log(payload)

        rent_line_schedule_list = rent_line_schedule_list.filter((a:any) => a.id != action.payload)

    } else {
        //    // 失敗時アクション呼び出し

    }

  yield put(change_rent_line_schedule_customer_contact_list([]));
  yield put(change_rent_line_schedule_list(rent_line_schedule_list));
  yield put(change_rent_line_template_detail([]));
  yield put(change_rent_line_schedule_detail({id:"", rent_line_template_id:''}));

}