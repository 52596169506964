import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';


import 'fixed-data-table-2/dist/fixed-data-table.css';

import './Css/Grid.css'
import withStyles from '@mui/styles/withStyles';

import IndexGrid from "./IndexGrid";
import IndexSearch from "./IndexSearch";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';




const styles = (theme) => ({

    backdrop: {
        // zIndex: theme.zIndex.drawer + 1,
        zIndex: 20000,
        color: '#fff',
    },

})


class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            change: false,
        };


        this.props.get_rent_m_corporates_list()
    }



    render() {

        return (
            <div className="item item-outer col-lg-12" style={{paddingLeft:3, paddingRight:3}}>

            <Card style={{ float: 'left', boxShadow:"none", marginLeft:5 }}>
                <IndexSearch />
            </Card>

                <Card style={{boxShadow:"none"}}>
                    <CardContent style={{marginLeft:5, padding:0}}>
                        <IndexGrid />
                    </CardContent>
                </Card>

            <Backdrop className={this.props.classes.backdrop} open={this.props.rent_m_corporates_list_backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates_list: state.corporation.rent_m_corporates_list,
        rent_m_corporates_list_search: state.corporation.rent_m_corporates_list_search,
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_m_corporates_top_open: state.corporation.rent_m_corporates_top_open,
        search_data: state.corporation.search_data,
        modalResponseDetail: state.responsesReducer.modalResponseDetail,
        responseid: state.responsesReducer.responseid,
        rent_m_corporates_detail_open: state.corporation.rent_m_corporates_detail_open,

        user_details: state.masterReducer.user_details,
        users: state.masterReducer.users,
        rent_m_corporates_list_backdrop: state.corporation.rent_m_corporates_list_backdrop,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        get_rent_m_corporates_list(state) {
            dispatch(Corporation.get_rent_m_corporates_list(state))
        },
        

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




