import React, { Component } from 'react';

import { connect } from 'react-redux';
// import Dropzone from 'react-dropzone';
//import { Link } from 'react-router-dom';
//import CustomerInfoEdit from './CustomerInfoEditComponent';
//import CustomerInfoDisp from './CustomerInfoDispComponent';

import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import MailDetail from "./MailDetailComponent";
import MailGridComponent from "./MailGridComponent";
import * as Mail from './Saga/Mail';
import MailDraftGridComponent from './MailDraftGridComponent';



// import throttle from 'lodash.throttle';


// function separate(num) {
//     // 文字列にする
//     num = String(num);

//     var len = num.length;

//     // 再帰的に呼び出すよ
//     if (len > 3) {
//         // 前半を引数に再帰呼び出し + 後半3桁
//         return separate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
//     } else {
//         return num;
//     }
// }






class MailComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            scrollTop: 0,
            old_id: 0,
            back_date: "",

            modal: false,
            modalselect: false,
            modaldraft: false,
            files: [],
            mailid: 0
        };



        this.change_mail_new = this.change_mail_new.bind(this);
        // this.toggleselect = toggleselect.bind(this);


        props.get_mails()


    }






    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleselect = (id) => {

        this.setState(prevState => ({
            modalselect: !prevState.modalselect,
            mailid: id
        }));
    }
    toggledraft = (id) => {

        this.setState(prevState => ({
            modaldraft: !prevState.modaldraft,

        }));
    }

    change_mail_new() {
        console.log("aaaaa")
        let maildetail = {};
        console.log(this.props.customerdetail)
        alert(this.props.customerdetail.mail1)
        maildetail.toMail = this.props.customerdetail.mail1;
        maildetail.fromMail = "cd@global-center.co.jp";
        maildetail.mailAccountId = 11;
        maildetail.isSended = 1;
        this.props.change_mail_new(maildetail)
    }




    render() {
        console.log(this.state.files)
        // const files = this.state.files.map(file => (
        //     <li key={file.name}>
        //       {file.name} - {file.size} bytes
        //     </li>
        //   ));
        console.log(this.props.getmails)
        return (

            <div className="item-mail" id="item-mail" ref={(node) => this.node = node}>

                <MailGridComponent toggleselect={this.toggleselect} />


                <table className="table-hover">
                    <tbody>

                        {this.state.messages}
                    </tbody>
                </table>
                <Modal isOpen={this.state.modalselect} toggle={this.toggleselect} size="lg" fade={false}>
                    <ModalHeader toggle={this.toggleselect}>メール詳細</ModalHeader>


                    <MailDetail toggle={this.toggleselect} mailid={this.state.mailid} />

                </Modal>
                <div style={{ padding: "5px" }}>
                    <button type="button" className="btn btn-sm btn-success float-left"
                        onClick={this.toggledraft}>下書き</button>

                    <Modal isOpen={this.state.modaldraft} toggle={this.toggledraft} fade={false}>
                        <ModalHeader toggle={this.toggledraft}>Modal title</ModalHeader>
                        <ModalBody>
                            <MailDraftGridComponent />
                        </ModalBody>
                        {/* <MailDetail toggle={this.toggleselect} mailid={this.state.mailid} /> */}
                        {/* <MailDetailFilesComponent mailid={this.state.mailid} /> */}
                        <ModalFooter>
                            <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
                            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    <button type="button" className="btn btn-sm btn-primary float-right"
                        onClick={this.change_mail_new}>メール作成</button>
                </div>
            </div>

        );

    }
}




//コンテナ
const mapStateToProps = state => {
    return {

        mail_details: state.mailReducer.mail_details,
        mail_new: state.mailReducer.mail_new,
        customerdetail: state.customersReducer.customerdetail,

        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_mails(state) {
        //     dispatch(Mail.get_mails(state))
        // },
        add_mail_file(state) {
            dispatch(Mail.add_mail_file(state))
        },
        change_mail_new(state) {
            dispatch(Mail.change_mail_new(state))
        }

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(MailComponent);
