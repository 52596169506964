import React, { Component } from 'react';
// import { Alert } from 'reactstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { connect } from 'react-redux';
// import { makeStyles } from '@mui/material/styles';
// import { reduxForm } from 'redux-form';
// import '../../Css/Table.css';
// var moment = require("moment");
import moment from 'moment';

class SetsubiEditComponent extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     customerinfo:this.props.forecasts,
        //     startDate: new Date()
        // };
        // this.handleFormSubmit = this.handleFormSubmit.bind(this);
        // this.onChange = this.onChange.bind(this);

    }
    //     handleFormSubmit(event) {
    //         event.preventDefault();
    //         console.log(event)
    //         // form値取得
    //         console.log(this.props.forecasts);
    //         // form値取得
    //         //const params = {
    //         //    name1: event.target.name1.value,
    //         //    kana1: event.target.kana1.value,
    //         //};

    //         //alert(JSON.stringify(params, null, '  '));
    //     }

    //     onChange(e) {
    //         console.log(e.target.value);
    //         this.setState({ name1: e.target.value });
    //     }

    //   componentDidMount() {
    //     // This method is called when the component is first added to the document
    //       this.ensureDataFetched();
    //       //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    //   }

    //   componentDidUpdate() {
    //     // This method is called when the route parameters change
    //     this.ensureDataFetched();
    //   }

    //   ensureDataFetched() {
    //       //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
    //       //console.log(startDateIndex)

    //       //this.props.requestWeatherForecasts(0);

    //     }




    render() {

        // const classes = useStyles();
        return (
            <div style={{ padding: "5px" }}>

                {this.props.room_campaign.end_at ?
                    <Alert icon={false} severity="info" style={{ textAlign: "left", marginBottom:5 }}>
                        <AlertTitle>キャンペーン情報</AlertTitle>

                        {this.props.room_campaign.is_public_web == 0 ? <strong style={{fontWeight:"bold", color:"red", background:"yellow"}}>【WEB非公開】</strong>:""}
                        {this.props.room_campaign.is_free_chukai == 1 ? <strong style={{fontWeight:"bold", color:"red",background:"yellow"}}>【仲介手数料無料】</strong>:""}

                        {this.props.room_campaign.end_at < moment().format('YYYY/MM/DD') ? <><span style={{fontWeight:"bold",color:"red",background:"yellow"}}>【※掲載期限切れ】{this.props.room_campaign.start_at} ～ {this.props.room_campaign.end_at}</span><br/></> : <><span>【掲載期限】{this.props.room_campaign.start_at} ～ {this.props.room_campaign.end_at}</span><br/></>}
                        {this.props.room_campaign.content ? <span>{this.props.room_campaign.content}</span> : ""}
                        {this.props.room_campaign.remarks ? <>
                            <br/><br/><strong>備考</strong><br/>
                            <div dangerouslySetInnerHTML={{ __html: this.props.room_campaign.remarks.replace(/\r?\n/g, '<br>') }}></div>
                        </> : ""}

                    </Alert> : ""}

                {(this.props.room_details.lower_yachin > 0 || this.props.room_details.lower_parking > 0) ?
                    <Alert icon={false} severity="error" style={{ textAlign: "left", marginBottom:5 }}>
                        <AlertTitle>減額交渉可能幅(賃料交渉下記まで可能)</AlertTitle>
                        家賃/共益費 {this.props.room_details.lower_yachin > 0 ? this.props.room_details.lower_yachin.toLocaleString() + "円" : "-"}/{this.props.room_details.kyouekihi ? this.props.room_details.kyouekihi.toLocaleString() + "円" : ""}<br />
                        駐車料  {this.props.room_details.lower_parking > 0 ? this.props.room_details.is_tax_parking ? Math.round(this.props.room_details.lower_parking * 1.1).toLocaleString() + "円" : this.props.room_details.lower_parking.toLocaleString() + "円" : "-"}<br />
                        {this.props.room_details.content}<br />
                        【期限 :{this.props.room_details.start_at ? moment(new Date(this.props.room_details.start_at)).format("YYYY/MM/DD") : ""}～ {this.props.room_details.end_at ? moment(new Date(this.props.room_details.end_at)).format("YYYY/MM/DD") : ""}】
                    </Alert> : ""}

                {this.props.room_details.pr_comment_daicho ?
                    <Alert icon={false} severity="warning" style={{ textAlign: "left", marginBottom:5 }}>
                        <AlertTitle>台帳用注意事項</AlertTitle>
                        <div dangerouslySetInnerHTML={{ __html: this.props.room_details.pr_comment_daicho.replace(/\r?\n/g, '<br>') }}></div>
                    </Alert> : ""}
                {this.props.room_details.room_private_notice ?
                    <Alert icon={false} severity="warning" style={{ textAlign: "left", marginBottom:5 }}>
                        <AlertTitle>社内用注意事項</AlertTitle>
                        <div dangerouslySetInnerHTML={{ __html: this.props.room_details.room_private_notice.replace(/\r?\n/g, '<br>') }}></div>
                    </Alert> : ""}

                {this.props.room_details.kagiban_setti_date || this.props.room_details.kagi_ban || this.props.room_details.kagibikou ?
                    <Alert icon={false} severity="success" style={{ textAlign: "left", marginBottom:5 }} >
                        <AlertTitle>鍵情報</AlertTitle>
                        <div>鍵番設置：{this.props.room_details.kagiban_setti_date ? moment(this.props.room_details.kagiban_setti_date).format("YYYY/MM/DD") : ""}</div>
                        <div>鍵番種類：{this.props.room_details.kagi_ban}</div>
                        <div dangerouslySetInnerHTML={{ __html: "鍵備考：" + this.props.room_details.kagibikou.replace(/\r?\n/g, '<br>') }}></div>
                    </Alert> : ""}

                {(this.props.room_details.room_inhouse_info_content) ?
                    <Alert icon={false} severity="info" style={{ textAlign: "left", marginBottom:5 }}>
                        <AlertTitle>マル得</AlertTitle>
                        <div dangerouslySetInnerHTML={{ __html: this.props.room_details.room_inhouse_info_content.replace(/\r?\n/g, '<br>') }}></div>
                        【期限 :{moment(this.props.room_details.room_inhouse_info_start_at).format("YYYY/MM/DD")}～ {moment(this.props.room_details.room_inhouse_info_end_at).format("YYYY/MM/DD")}】

                    </Alert> : ""}

                {this.props.room_details.seisan_bikou ?
                    <Alert icon={false} severity="error" style={{ textAlign: "left", marginBottom:5 }}>
                        <AlertTitle>工事備考</AlertTitle>
                        <div dangerouslySetInnerHTML={{ __html: this.props.room_details.seisan_bikou.replace(/\r?\n/g, '<br>') }}></div>
                    </Alert> : ""}


                {(this.props.room_details.reformed_at || this.props.room_details.reformed_memo) ?
                    <Alert icon={false} severity="success" style={{ textAlign: "left", marginBottom:5 }}>
                        <AlertTitle>リフォーム物件</AlertTitle>

                        {this.props.room_details.reformed_at ? moment(this.props.room_details.reformed_at).format("YYYY/MM/DD") + ' リフォーム完了' : ""}
                        {this.props.room_details.reformed_at ? <br /> : ""}
                        {this.props.room_details.reformed_memo ? this.props.room_details.reformed_memo : ""}

                    </Alert> : ""}
                

                


            </div>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        // room_details: state.roomsReducer.room_details,
        // room_campaign: state.roomsReducer.room_campaign,

        // setsubi_master: state.roomsReducer.setsubi_master,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {


        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

// SetsubiEditComponent = reduxForm({
//     form: 'roomform', // a unique identifier for this form
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(SetsubiEditComponent)

export default connect(mapStateToProps, mapDispatchToProps)(SetsubiEditComponent);