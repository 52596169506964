import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Disp from "./IndexDisp";
// import Edit from "./IndexEdit";
import {Tab, Tabs, Box } from '@mui/material';

import OwnerSearchOwnerNameComponent from "./OwnerSearchOwnerNameComponent";
import OwnerSearchBuildingComponent from "./OwnerSearchBuildingComponent";
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';
// import { compose } from 'redux'





class OwnerInfo extends Component{


    constructor(props) {
        super(props);

        this.state = {
            refresh: false,

            selectTabs : 0,

        }

        // this.handleClose = this.handleClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);

    }

    handleTabChange = (event, newValue) => {

        if(newValue == 1){

        }else if(newValue == 2){
            // this.props.get_t_outai_list(this.props.owner_detail.owner_id)
        }

        this.setState({ selectTabs: newValue });

    };


    // handleClose(){
    //     this.props.clear_owner_info();
    //     this.props.change_tm_owner_top_open(false);
    //     this.props.change_owner_info_edit(false);

    //     this.setState({
    //         refresh: !this.state.refresh    //再描画
    //     });
    // }

    render() {

        return (
            <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', position:"relative" }}>
                <Tabs 
                    value={this.state.selectTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="オーナー名から検索" value={0} />
                    <Tab label="建物から検索" value={1} />
        
                </Tabs>
                
                <div style={{position:'absolute', right:1, top:1, fontSize:17}}>
                    <div>
                        <IconButton
                            aria-label="close"
                            style={{}}
                            onClick={this.props.handleOwnerSearchClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                
            </Box>

            <div style={{ padding: 10, background: "#b0c4de" }} >

                {this.state.selectTabs == 0 ? <OwnerSearchOwnerNameComponent handleOwnerSearchClose={this.props.handleOwnerSearchClose} /> : 
                this.state.selectTabs == 1 ? <OwnerSearchBuildingComponent handleOwnerSearchClose={this.props.handleOwnerSearchClose} /> :""}
                
                

            </div>

            </div>
        );
    }
};


const mapStateToProps = (state) => {
    return {
        // owner_info_edit: state.owner.owner_info_edit,
        // tm_owner: state.ownerlist.tm_owner,
        // owner_detail: state.owner.owner_detail,
        // owner_detail_backdrop: state.owner.owner_detail_backdrop,

        // line_open_flg: state.line.line_open_flg,
        // line_users: state.line.line_users,



    }
}
function mapDispatchToProps(dispatch) {
    return {

        // get_owner_detail(state) {
        //     dispatch(Calendar.get_owner_detail(state))
        // },
        // change_owner_info_edit(state) {
        //     dispatch(Calendar.change_owner_info_edit(state))
        // },


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerInfo);
