import React, { Component } from 'react';

import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';

//import { actionCreators } from '../../Store/WeatherForecasts';

import { Field, reduxForm } from 'redux-form';
import '../../Css/Table.css';
// import * as AutoKana from 'vanilla-autokana';
let autokana1;
let Bosyuflg;


// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (!values.roomName) {
        errors.roomName = "部屋名を入力してください。";
    }
    if (!values.kana1) {
        errors.kana1 = "カナを入力してください。";
    }

    if(Bosyuflg === 1){//募集
        if (values.kanriKubunNo <= 0) {
            errors.kanriKubunNo = "管理区分を入力してください。";
        }
        if (values.torihikiKeitaiNo <= 0) {
            errors.torihikiKeitaiNo = "取引形態を入力してください。";
        }
        if (values.madoriNo <= 0) {
            errors.madoriNo = "間取を入力してください。";
        }
        if (values.roomMukiNo <= 0) {
            errors.roomMukiNo = "向きを入力してください。";
        }
        if (!values.senyuuMenseki) {
            errors.senyuuMenseki = "面積を入力してください。";
        }
        if (values.youtoNo <= 0) {
            errors.youtoNo = "用途を入力してください。";
        }
    }

    return errors;
};

export class RenderField extends React.Component {

    render() {
        const { className, id, name, type, style, input, disabled, onBlur, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};

class RoomEditComponent extends Component {
   
    constructor(props) {
        super(props);
      
        this.state = {
            isSetRoomName: this.props.roomdetail.roomName > 0 ? false : true,
            isSetTorihikiKeitaiNo: this.props.roomdetail.torihikiKeitaiNo > 0 ? false : true,
            isSetMadoriNo: this.props.roomdetail.madoriNo > 0 ? false : true,
            isSetRoomMukiNo: this.props.roomdetail.roomMukiNo > 0 ? false : true,
            isSetSenyuuMenseki: this.props.roomdetail.senyuuMenseki > 0 ? false : true,
            isSetYoutoNo: this.props.roomdetail.youtoNo > 0 ? false : true,
            isSetKanriKubunNo: this.props.roomdetail.kanriKubunNo > 0 ? false : true,

        };

        Bosyuflg = 1;

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        //this.handleClick = this.handleClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.props.initialize(this.props.roomdetail);//フォームに初期値を反映させる
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }
    
  componentDidMount() {
    // This method is called when the component is first added to the document
      this.ensureDataFetched();
      //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.ensureDataFetched();
  }

  ensureDataFetched() {
      //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
      //console.log(startDateIndex)

      //this.props.requestWeatherForecasts(0);

    }


    onChangeName(property, event) {

        if (property === "tatemonoKana") {
            this.props.change(property, autokana1.getFurigana())
        } else if (property === "kana2") {
            
        }


    }

    render() {

        const year = [""];
        const day = ["", "上旬", "中旬", "下旬"];

        var hiduke = new Date();
        for (var i = hiduke.getFullYear() -1; i <= hiduke.getFullYear() + 1; i++) {
            year.push(i);
        }
        for (var i2 = 1; i2 <= 31; i2++) {
            day.push(i2);
        }
       
        //moment.locale('ja')
      return (
          <div>
                  <button className="btn btn-sm btn-light float-right" style={{ display: (this.props.notclose === 1) ? 'none' : '' }} onClick={this.props.roomeditchange}>閉じる</button>
            <table>
                <colgroup width='15%' />
                <colgroup width='35%' />
                <colgroup width='15%' />
                <colgroup width='35%' />
                <tbody>

                    <tr >
                        <th>部屋名</th>
                        <td colSpan="3">
                            <Field
                                name="roomName"
                                id="roomName"
                                type="text"
                                placeholder=""
                                className={this.state.isSetRoomName ? "alert-danger form-control" : "form-control"}
                                onBlur={e => this.setState({"isSetRoomName":!e.target.value})}
                                component={RenderField}
                            />
                        </td>
                    </tr>
                    <tr >
                        <th>管理区分</th>
                        <td>
                            <Field
                                name="kanriKubunNo"
                                id="kanriKubunNo"
                                placeholder=""
                                className={this.state.isSetKanriKubunNo ? "alert-danger form-control" : "form-control"}
                                onBlur={e => this.setState({"isSetKanriKubunNo": e.target.value > 0 ? false : true })}
                                component={RenderField}
                                type="select">
                                <option value="-1">-</option>
                                <option value="1">管理</option>
                                <option value="2">一般</option>
                                <option value="3">専任</option>
                                <option value="4">サブ</option>
                                <option value="5">管理外</option>
                                <option value="6">業物</option>
                            </Field>
                        </td>
                        <th>取引形態</th>
                        <td>
                            <Field
                                name="torihikiKeitaiNo"
                                id="torihikiKeitaiNo"
                                placeholder=""
                                component={RenderField}
                                className={this.state.isSetTorihikiKeitaiNo ? "alert-danger form-control" : "form-control"}
                                onBlur={e => this.setState({"isSetTorihikiKeitaiNo": e.target.value > 0 ? false : true })}
                                type="select">
                                <option value="-1">-</option>
                                <option value="1">仲介</option>
                                <option value="2">貸主</option>
                            </Field>
                        </td>
                    </tr>
                    <tr >
                        <th>間取</th>
                        <td>
                            <Field
                                name="madoriNo"
                                id="madoriNo"
                                placeholder=""
                                component={RenderField}
                                className={this.state.isSetMadoriNo ? "alert-danger form-control" : "form-control"}
                                onBlur={e => this.setState({"isSetMadoriNo": e.target.value > 0 ? false : true })}
                                type="select">
                                    <option value="-1">-</option>
                            </Field>
                        </td>
                        <th>向き</th>
                        <td>
                            <Field
                                name="roomMukiNo"
                                id="roomMukiNo"
                                placeholder=""
                                component={RenderField}
                                className={this.state.isSetRoomMukiNo ? "alert-danger form-control" : "form-control"}
                                onBlur={e => this.setState({"isSetRoomMukiNo": e.target.value > 0 ? false : true })}
                                type="select">
                                    <option value="-1">-</option>
                            </Field>
                        </td>
                    </tr>
                    <tr >
                        <th>面積</th>
                        <td>
                            <div className="form-inline">
                            <Field
                                name="senyuuMenseki"
                                id="senyuuMenseki"
                                type="number"
                                placeholder=""
                                component={RenderField}
                                className={this.state.isSetSenyuuMenseki ? "alert-danger form-control" : "form-control"}
                                onBlur={e => this.setState({"isSetSenyuuMenseki":!e.target.value})}
                                style={{width:80}}
                            />㎡
                            </div>
                        </td>
                        <th>用途</th>
                        <td>
                            <Field
                                name="youtoNo"
                                id="youtoNo"
                                placeholder=""
                                component={RenderField}
                                className={this.state.isSetYoutoNo ? "alert-danger form-control" : "form-control"}
                                onBlur={e => this.setState({"isSetYoutoNo": e.target.value > 0 ? false : true })}
                                type="select">
                                <option value="-1">-</option>
                                <option value="1">住宅</option>
                                <option value="2">駐車場</option>
                                <option value="3">テナント</option>
                                <option value="4">倉庫</option>
                                <option value="5">事業用土地</option>
                                <option value="6">不明</option>
                            </Field>
                        </td>
                    </tr>
                    <tr >
                        <th>募集状況</th>
                        <td>
                            <Field
                                name="kuushitsuJoukyouNo"
                                id="kuushitsuJoukyouNo"
                                placeholder=""
                                className="form-control"
                                component="select">
                                
                                <option value="1">募集中</option>
                                <option value="2">押さえ</option>
                                <option value="3">申込み</option>
                                <option value="4">入居中</option>
                                <option value="6">募集停止</option>
                                <option value="100">家主使用</option>
                                <option value="101">未補修</option>
                            </Field>
                        </td>
                        <th>WEB掲載</th>
                        <td>
                            <label><Field name="webOpen" id="webOpen" component="input" type="checkbox" value={0} />WEB掲載</label>
                        </td>
                    </tr>
                    <tr >
                        <th>入居可能日</th>
                        <td colSpan="3">
                            <div className="form-inline">
                            <Field
                                name="nyuukyoKanouYear"
                                id="nyuukyoKanouYear"
                                type="text"
                                placeholder=""
                                className="form-control"
                                component="select">
                                    {year.map((value) => <option value={value}>{value}</option>)}
                            </Field>年
                            <Field
                                name="nyuukyoKanouMonth"
                                id="nyuukyoKanouMonth"
                                type="text"
                                placeholder=""
                                className="form-control"
                                component="select">
                                {["", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value) => <option value={value}>{value}</option>)}
                            </Field>月
                            <Field
                                name="nyuukyoKanouDay"
                                id="nyuukyoKanouDay"
                                type="text"
                                placeholder=""
                                className="form-control"
                                component="select">
                                {day.map((value) => <option value={value}>{value}</option>)}
                            </Field>日
                            </div>
                        </td>
                    </tr>
                </tbody>
              </table>

              <button className="btn btn-primary float-right" onClick={this.handleFormSubmit}>保存</button>
              </div>
    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

RoomEditComponent = reduxForm({
    form: 'roomeditform', // a unique identifier for this form
    validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(RoomEditComponent)

export default connect(mapStateToProps, mapDispatchToProps)(RoomEditComponent);