import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import MailDetail from "./MailDetailComponent";
import MailGridComponent from "./MailGridComponent";
import * as Mail from './Saga/Mail';
import MailDraftGridComponent from './MailDraftGridComponent';
import ButtonMui from '@mui/material/Button';







class MailResponseComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            scrollTop: 0,
            old_id: 0,
            back_date: "",

            modal: false,
            modalselect: false,
            modaldraft: false,
            files: [],
            mailid: 0,
            mail_looked_modal:false,
        };


        this.toggleselect = this.toggleselect.bind(this);
        this.change_mail_new = this.change_mail_new.bind(this);
        this.draft_mail_edit = this.draft_mail_edit.bind(this);
        // props.get_mails()

        // props.get_response_mails(this.props.responsedetail.id)

    }






    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleselect = (id) => {

        this.setState(prevState => ({
            modalselect: !prevState.modalselect,
            mailid: id
        }));
    }
    toggledraft = (id) => {

        this.setState(prevState => ({
            modaldraft: !prevState.modaldraft,

        }));
    }

    change_mail_new() {
        let maildetail = {};
        // console.log(this.props.customerdetail)
        // alert(this.props.responsedetail.mailAccountId)

        this.props.change_editor_height(window.innerHeight)
        this.props.change_editor_width(1000)

        maildetail.toMail = this.props.customerdetail.mail1 == null ? "" : this.props.customerdetail.mail1;
        maildetail.toMailall = this.props.customerdetail.mail1;
        if (this.props.responsedetail.section_id === 17) {
            maildetail.fromMail = "sun@global-center.co.jp";
            maildetail.fromName = "グローバルセンターサンフラワー店";
            maildetail.mailAccountId = 6;
        } else if (this.props.responsedetail.section_id === 47) {
            maildetail.fromMail = "cd@global-center.co.jp";
            maildetail.fromName = "グローバルセンター中央通り店";
            maildetail.mailAccountId = 11;
        } else if (this.props.responsedetail.section_id === 1) {
            maildetail.fromMail = "info@global-center.co.jp";
            maildetail.fromName = "アパマンショップ高松番町店";
            maildetail.mailAccountId = 1;
        } else if (this.props.responsedetail.section_id === 2) {
            maildetail.fromMail = "rainbow@global-center.co.jp";
            maildetail.fromName = "アパマンショップレインボーロード店";
            maildetail.mailAccountId = 2;
        } else if (this.props.responsedetail.section_id === 3) {
            maildetail.fromMail = "takamatsu@global-center.co.jp";
            maildetail.fromName = "アパマンショップ高松駅前店";
            maildetail.mailAccountId = 3;
        } else if (this.props.responsedetail.section_id === 4) {
            maildetail.fromMail = "east@global-center.co.jp";
            maildetail.fromName = "アパマンショップ高松東店";
            maildetail.mailAccountId = 4;
        } else if (this.props.responsedetail.section_id === 6) {
            maildetail.fromMail = "park@global-center.co.jp";
            maildetail.fromName = "アパマンショップ中央公園店";
            maildetail.mailAccountId = 5;
        } else if (this.props.responsedetail.section_id === 10) {
            maildetail.fromMail = "ooshika@global-center.co.jp";
            maildetail.fromName = "グローバルセンター";
            maildetail.mailAccountId = 10;
        } else {
            maildetail.fromMail = "web@global-center.co.jp";
            maildetail.fromName = "グローバルセンター";
            maildetail.mailAccountId = 12;
        }


        maildetail.isSended = 1;//下書き
        maildetail.rentResponseId = this.props.responsedetail.id;


        this.props.change_mail_new(maildetail)


    }


    draft_mail_edit(state) {
        // alert("response")
        // let maildetail = {};
        this.props.change_mail_draft_edit(state)
        // console.log(this.props.customerdetail)

        // maildetail.toMail = this.props.customerdetail.mail1;
        // maildetail.fromMail = "ooshika@global-center.co.jp";
        // maildetail.fromName = "ooshika@global-center.co.jp";
        // maildetail.mailAccountId = 10;
        // maildetail.isSended = 1;//下書き
        // maildetail.rentResponseId= this.props.rent_response_id;

        // this.props.get_response_mails(this.props.rent_response_id)

    }

    render() {
        // console.log(this.state.files)
        // const files = this.state.files.map(file => (
        //     <li key={file.name}>
        //       {file.name} - {file.size} bytes
        //     </li>
        //   ));
        // console.log(this.props.getmails)
        return (

            <div className="item-mail" id="item-mail" ref={(node) => this.node = node}>

                <MailGridComponent rent_response_id={this.props.responsedetail.id}
                    customerdetail={this.props.customerdetail}
                    toggleselect={this.toggleselect} draft_mail_edit={this.draft_mail_edit}
                    response_mail_loading={this.props.response_mail_loading}
                    response_mails={this.props.response_mails}
                />


                <table className="table-hover">
                    <tbody>

                        {this.state.messages}
                    </tbody>
                </table>
                <Modal isOpen={this.state.modalselect} toggle={this.toggleselect} size="lg" fade={false}>
                    <ModalHeader toggle={this.toggleselect}>メール詳細</ModalHeader>


                    <MailDetail toggle={this.toggleselect} mailid={this.state.mailid} rent_response_id={this.props.responsedetail.id} />

                </Modal>
                <div style={{ padding: "5px" }}>
                    {/* <button type="button" className="btn btn-sm btn-success float-left" 
        onClick={this.toggledraft}>下書き</button> */}

                    <Modal isOpen={this.state.modaldraft} toggle={this.toggledraft} fade={false}>
                        <ModalHeader toggle={this.toggledraft}>Modal title</ModalHeader>
                        <ModalBody>
                            <MailDraftGridComponent />
                        </ModalBody>
                        {/* <MailDetail toggle={this.toggleselect} mailid={this.state.mailid} /> */}
                        {/* <MailDetailFilesComponent mailid={this.state.mailid} /> */}
                        <ModalFooter>
                            <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
                            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    <button type="button" className="btn btn-sm btn-primary float-right"
                        onClick={this.change_mail_new}>メール作成</button>

                    {this.props.responsedetail.receive_mail_at ? 
                        <ButtonMui size="small" color="secondary" variant="contained" onClick={()=>this.setState({ mail_looked_modal: true })}>既読</ButtonMui>:""}



<Modal isOpen={this.state.mail_looked_modal} toggle={()=>this.setState({ mail_looked_modal: false })} fade={false}>

<ModalBody>
    <div>メールを既読にしますか？</div>

        <Button color="danger" style={{ margin: "20px", marginLeft:50, marginTop: "50px" }} onClick={()=>{this.props.update_mail_looked({ id: this.props.responsedetail.id, status: "receive_mail_at", status_id: 1}); this.setState({ mail_looked_modal: false })}} >既読にする</Button>
        <Button color="secondary" style={{ margin: "20px", marginLeft:50, marginTop: "50px" }} onClick={()=>this.setState({ mail_looked_modal: false })}>キャンセル</Button>
</ModalBody>

</Modal>



                </div>
            </div>

        );

    }
}




//コンテナ
const mapStateToProps = state => {
    return {

        mail_details: state.mailReducer.mail_details,
        mail_new: state.mailReducer.mail_new,
        customerdetail: state.customersReducer.customerdetail,
        responsedetail: state.responsesReducer.responsedetail,
        response_mails: state.mailReducer.response_mails,
        response_mail_loading: state.mailReducer.response_mail_loading,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_mails(state) {
        //     dispatch(Mail.get_mails(state))
        // },

        add_mail_file(state) {
            dispatch(Mail.add_mail_file(state))
        },
        change_mail_new(state) {
            dispatch(Mail.change_mail_new(state))
        },
        change_mail_draft_edit(state) {
            dispatch(Mail.change_mail_draft_edit(state))
        },
        change_editor_height(state) {
            dispatch(Mail.change_editor_height(state))
        },
        change_editor_width(state) {
            dispatch(Mail.change_editor_width(state))
        },
        update_mail_looked(state) {
            dispatch(Mail.update_mail_looked(state))
        },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(MailResponseComponent);
