import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as Responses from './Saga/Responses';

import '../../Css/Table.css';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';

class ResponseInfoDisp extends Component {

    constructor(props) {
        super(props);

        this.state = {

            startDate: new Date(),

        };
    }

    componentDidMount() {



    }



    render() {
        const userid = this.props.responsedetail.user_id;
        const user = this.props.users.filter(function (user) {
            return user.id === userid;
        });
        const primaryUser = this.props.users.filter(function (user) {
            return user.id === this.props.responsedetail.primary_user_id
        }, this);
        const comingUser = this.props.users.filter(function (user) {
            return user.id === this.props.responsedetail.coming_user_id
        }, this);
        const goingUser = this.props.users.filter(function (user) {
            return user.id === this.props.responsedetail.going_user_id
        }, this);
        const webUser = this.props.users.filter(function (user) {
            return user.id === this.props.responsedetail.web_user_id
        }, this);

        const firstContactSection = this.props.shops.filter(function (shop) {
            return shop.tenpoNo === this.props.responsedetail.first_contact_section_id
        }, this);
        const sectionId = this.props.responsedetail.section_id;
        const shop = this.props.shops.filter(function (shops) {
            return shops.tenpoNo === sectionId;
        });
        const rentmmediumid = this.props.responsedetail.rent_m_medium_id;

        const rentmmedium = this.props.rentMMedeas.filter(function (rentmmedium) {
            return rentmmedium.id === rentmmediumid;
        });

        const rentmagentid = this.props.responsedetail.rent_m_agent_id;
        const rentmagent = this.props.rent_m_agents.length > 0 && rentmagentid > 0 ? this.props.rent_m_agents.filter(a=>a.id == rentmagentid) : []
        // let rentmagent = []
        // if(this.props.rent_m_agents.length > 0){
        //     rentmagent = this.props.rent_m_agents.filter(function (rentmagent) {
        //         return rentmagent.id === rentmagentid;
        //     });
        // }
        // const rentmagent = this.props.rentAgents.filter(function (rentmagent) {
        //     return rentmagent.rentMAgentId === rentmagentid;
        // });

        const rentmreasonid = this.props.responsedetail.rent_m_reason_id;
        const rentmreason = this.props.rentMReasons.filter(function (rentmreason) {
            return rentmreason.id === rentmreasonid;
        });


        console.log(this.props.responsedetail)

        return (
            <div>

                <div style={{textAlign:"right"}}>
                    <LoadingButton
                        size="small"
                        color="secondary"
                        style={{ color: "white", backgroundColor: "#28a745", marginRight:3 }}
                        onClick={() => this.props.download_response_preparation_sheet()}
                        loading={this.props.response_preparation_sheet_loading}
                        loadingPosition="start"
                        startIcon={<DownloadIcon />}
                        variant="contained"
                    >
                        <span>準備シート</span>
                    </LoadingButton>
                    <Button size="small" style={{ display: (this.props.notedit === 1) ? 'none' : '' }} variant="contained" color="primary" onClick={() => {this.props.change_rent_responses_free_edit_disp(true);this.props.change_rent_responses_editor(this.props.responsedetail);this.props.change_rent_responses_free_update_alert_disp({type: 0, time:""})}}>
                        編集
                    </Button>
                </div>



                <table className='basicList'>
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <tbody>


       



                        <tr>
                            <th>現住居</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_residence}
                            </td>
                        </tr>
                        <tr>
                            <th>引越し理由</th>
                            <td colSpan={3}>
                                {/* {this.props.responsedetail.free_moving_reason} */}
                                {this.props.responsedetail.free_moving_reason ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_moving_reason.length ? this.props.responsedetail.free_moving_reason.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>人物属性その他</th>
                            <td colSpan={3}>
                                {/* {this.props.responsedetail.free_person_attributes} */}
                                {this.props.responsedetail.free_person_attributes ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_person_attributes.length ? this.props.responsedetail.free_person_attributes.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>通勤通学先</th>
                            <td colSpan={3}>
                                {/* {this.props.responsedetail.free_office_school} */}
                                {this.props.responsedetail.free_office_school ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_office_school.length ? this.props.responsedetail.free_office_school.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>入居人数</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_number_of_people ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_number_of_people.length ? this.props.responsedetail.free_number_of_people.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                                {/* {this.props.responsedetail.free_number_of_people} */}
                            </td>
                        </tr>
                        <tr>
                            <th>間取り目安</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_madori ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_madori.length ? this.props.responsedetail.free_madori.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                                {/* {this.props.responsedetail.free_madori} */}
                            </td>
                            
                        </tr>
                        <tr>
                            <th>賃料目安</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_rent ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_rent.length ? this.props.responsedetail.free_rent.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                                {/* {this.props.responsedetail.free_rent} */}
                            </td>
                        </tr>
                        <tr>
                            <th>賃料規定あれば</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_rent_remarks ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_rent_remarks.length ? this.props.responsedetail.free_rent_remarks.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                                {/* {this.props.responsedetail.free_rent_remarks} */}
                            </td>
                        </tr>
                        <tr>
                            <th>車種・台数</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_car ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_car.length ? this.props.responsedetail.free_car.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                                {/* {this.props.responsedetail.free_car} */}
                            </td>
                        </tr>
                        <tr>
                            <th>エリア目安</th>
                            <td colSpan={3}>
                                {/* {this.props.responsedetail.free_area} */}
                                {this.props.responsedetail.free_area ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_area.length ? this.props.responsedetail.free_area.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>入居時期</th>
                            <td colSpan={3}>
                                {/* {this.props.responsedetail.free_move_in_period} */}
                                {this.props.responsedetail.free_move_in_period ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_move_in_period.length ? this.props.responsedetail.free_move_in_period.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>補足事項</th>
                            <td colSpan={3}>
                                {/* {this.props.responsedetail.free_remarks} */}
                                {this.props.responsedetail.free_remarks ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_remarks.length ? this.props.responsedetail.free_remarks.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>

                    </tbody>
                </table>


            </div>
        );
    }
}




//containers
const mapStateToProps = state => {
    return {
        // responseedit: state.responsesReducer.responseedit,
        //customerdetail: state.customersReducer.customerdetail,
        // responsedetail: state.responsesReducer.responsedetail,
        rentMMedeas: state.masterReducer.rentMMedeas,
        users: state.masterReducer.users,
        rentAgents: state.masterReducer.rentAgents,
        rentMReasons: state.masterReducer.rentMReasons,
        shops: state.masterReducer.shops,
        setsubi_master: state.masterReducer.setsubi_master,
        chomeis: state.masterReducer.chomeis,
        shougakkous: state.masterReducer.shougakkous,
        chuugakkous: state.masterReducer.chuugakkous,

        user_details: state.masterReducer.user_details,
        rent_m_agents: state.responsesReducer.rent_m_agents,
        response_preparation_sheet_loading: state.responsesReducer.response_preparation_sheet_loading,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        responseeditchange(state) {
            dispatch(Responses.responseeditchange(state))
        },
        open_response_edit(state) {
            dispatch(Responses.open_response_edit(state))
        },
        download_response_preparation_sheet(state) {
            dispatch(Responses.download_response_preparation_sheet(state))
        },
        change_rent_responses_editor(state) {
            dispatch(Responses.change_rent_responses_editor(state))
        },
        change_rent_responses_free_edit_disp(state) {
            dispatch(Responses.change_rent_responses_free_edit_disp(state))
        },
        change_rent_responses_free_update_alert_disp(state) {
            dispatch(Responses.change_rent_responses_free_update_alert_disp(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseInfoDisp);




