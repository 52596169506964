import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ContractSheetCheck from './ContractSheetCheck';
import ContractSheetDataCheck from './ContractSheetDataCheck';

import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';
import InputRange from 'react-input-range';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import Button from '@mui/material/Button';
import "react-input-range/lib/css/index.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
var moment = require('moment-timezone');
class ContractsSendEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      scale: 10,
      confirm: false,
      sheet:0,

      base64:null,
      typeset:null
    };
    this.change_new = this.change_new.bind(this);
    this.change_old = this.change_old.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.numPages = this.numPages.bind(this);
    this.ChangeButtonClick = this.ChangeButtonClick.bind(this);
    this.SendButtonClick = this.SendButtonClick.bind(this);
    this.CloseButtonClick = this.CloseButtonClick.bind(this);
    this.filesGet = this.filesGet.bind(this);
  }
  change_new(e) {
    this.props.change_rent_contract_logs_new(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  change_old(e) {
    this.props.change_rent_contract_logs_old(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  SendButtonClick(){
    this.props.resend_contract_sheet();
  }
  CloseButtonClick(){
    this.props.change_check_contract_sheet(false)
  }
  handleButtonClick(page) {
    this.setState({ page })
  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }
  handleSend(payment){
       var check_contract_sheet_text = this.props.check_contract_sheet_text
       check_contract_sheet_text.name = this.props.rent_contract_details.name1
       check_contract_sheet_text.title = '駐車場賃貸借契約書'
       check_contract_sheet_text.Mail = this.props.rent_contract_details.mail1
       check_contract_sheet_text.company = ''
       check_contract_sheet_text.message = 'メール受信後２日以内に契約書に入力してください。'
    this.props.contracts_resend_parking({responses_id:this.props.rent_contract_details.rent_response_id,payment:payment})
  }
  numPages(state) {
    this.setState({ numPages:state })
  }
  ChangeButtonClick(value){
    this.setState({ sheet: value })
    this.setState({ scale: 10 })
    this.setState({ rotate: 0 })
    this.setState({ page: 1 })
    this.filesGet(value)
  }
  filesGet(state){
    const reader = new FileReader()
    reader.readAsDataURL(this.props.check_contract_sheet_file[state].file)
    reader.onload = () => {
        this.setState({
        base64: reader.result,
        })
    }
  }



  render() {
    let ver = []
    console.log(this.state)
    return (
      <div>
       <Modal fade={false} isOpen={this.props.check_contract_sheet} toggle={() => { this.props.change_check_contract_sheet(false);}} size="xxl" style={{minHeight:'90%'}} >
       <ModalHeader toggle={() => { this.props.change_check_contract_sheet(false);}}>契約書確認
        
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={2}>
          <Grid xs={7} md={7} item className="item-outer-outer" style={{ paddingRight: 0 }}>
            <Card>
                <CardContent>
                {(this.props.check_contract_sheet_file && !this.props.rent_contract_details.number_of_cars) ?
                <ContractSheetCheck numPages={this.numPages} scale={this.state.scale} page={this.state.page} rotate={this.state.rotate} base64={this.state.base64} check_contract_sheet_file_data={this.props.check_contract_sheet_file}></ContractSheetCheck> :''}
                <span><InputRange
                  maxValue={20}
                  minValue={0}
                  draggableTrack={false}
                  value={this.state.scale}
                  allowSameValues={false}
                  onChange={value => this.setState({ scale:value })}
                />
                  <IconButton
                    aria-label="left"
                    disabled={this.state.page <= 1}
                    onClick={() => this.handleButtonClick(this.state.page - 1)}
                    size="large">
                    <ChevronLeftIcon />
                  </IconButton>
                  {this.state.page || 1} / {this.state.numPages || '-'}
                  <IconButton
                    aria-label="left"
                    disabled={this.state.page >= this.state.numPages || !this.state.numPages}
                    onClick={() => this.handleButtonClick(this.state.page + 1)}
                    size="large">
                    <ChevronRightIcon />
                  </IconButton>

                  <IconButton
                    aria-label="left"
                    onClick={() => this.handleRotateClick(this.state.rotate - 90)}
                    size="large">
                    <RotateLeftIcon />
                  </IconButton>
                  <IconButton
                    aria-label="left"
                    onClick={() => this.handleRotateClick(this.state.rotate + 90)}
                    size="large">
                    <RotateRightIcon />
                  </IconButton>
                </span>
                </CardContent>
            </Card>
          </Grid>
          <Grid xs={5} md={5} item className="item-outer-outer" >
            <Card>    
                <CardContent>
                    <ContractSheetDataCheck/>
                </CardContent>
            </Card>
          </Grid>
        </Grid>
        </ModalBody>
        {
        (this.state.sheet + 1) == this.props.rent_contract_details.number_of_cars ?
        <ModalFooter>
            <div style={{width:'100%'}}>
              {this.state.sheet > 0 && (this.state.sheet+1) == this.props.rent_contract_details.number_of_cars ? 
                <Button variant="contained" onClick={()=>this.ChangeButtonClick(this.state.sheet-1)} color="secondary" style={{ float: "left", margin: 5 }}>
                    前へ
                </Button>
                :<Button variant="contained" onClick={()=>this.CloseButtonClick()} color="#FFFFFF" style={{ float: "left", margin: 5 }}>
                閉じる
                </Button>
                }
              <Button variant="contained" onClick={()=>this.SendButtonClick()} color="primary" style={{ float: "right", margin: 5 }}>
                送信
              </Button>
            </div>
        </ModalFooter>
        :
        <ModalFooter>
            <div style={{width:'100%'}}>
                {this.state.sheet > 0 && (this.state.sheet+1) == this.props.rent_contract_details.number_of_cars ? 
                <Button variant="contained" onClick={()=>this.ChangeButtonClick(this.state.sheet-1)} color="secondary" style={{ float: "left", margin: 5 }}>
                    前へ
                </Button>
                :<Button variant="contained" onClick={()=>this.CloseButtonClick()} color="#FFFFFF" style={{ float: "left", margin: 5 }}>
                閉じる
                </Button>}
                <Button variant="contained" onClick={()=>this.ChangeButtonClick(this.state.sheet+1)} color="secondary" style={{ float: "right", margin: 5 }}>
                    次へ
                </Button>
            </div>
        </ModalFooter>
        }
        </Modal>
      </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    customerdetail: state.customersReducer.customerdetail,
    responsedetail: state.responsesReducer.responsedetail,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_logs_new: state.contracts.rent_contract_logs_new,
    rent_contract_logs_old: state.contracts.rent_contract_logs_old,
    contracts_cancel_confirm: state.contracts.contracts_cancel_confirm,
    check_contract_sheet: state.contracts.check_contract_sheet,
    check_contract_button:state.contracts.check_contract_button,
    check_contract_sheet_text:state.contracts.check_contract_sheet_text,
    check_contract_sheet_file:state.contracts.check_contract_sheet_file
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_check_contract_sheet(state) {
        dispatch(Contracts.change_check_contract_sheet(state))
      },
    contracts_resend_parking(state){
        dispatch(Contracts.contracts_resend_parking(state))
    },
    resend_contract_sheet(state){
        dispatch(Contracts.resend_contract_sheet(state))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractsSendEdit);
