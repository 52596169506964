import React, { Component } from 'react';
// import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as Mail from './Saga/Mail';
// import MailEditorComponent from './MailEditAreaComponent';
import LoadingComponent from '../Layout/LoadingComponent';
// import * as store from '../../index'
import '../../Css/Grid.css';
import moment from 'moment'
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import MuiButton from '@mui/material/Button';

// import LoadingComponent from '../Layout/LoadingComponent';
const ReactDataGrid = require('react-data-grid');


interface Props {
  get_mails: any,
  mails: any,
  shops: any,
  columns: any,
  add_mails: any,
  mail_loading: number,
  toggleselect: any,
  change_mail_loading: any,
  response_mails: any,
  draft_mail_edit: any,
  response_mail_loading: number,
  customerdetail: any,
  rent_response_id: number,
  get_response_mails: any,
  change_mail_new: any,
  change_mail_minimum: any,
  delete_media_mail: any,
  change_editor_height: any,
  change_editor_width: any,
}



interface State {
  columns: any,
  modalsheet:boolean,
  mail_id:number,
  // state types
}

const SendTypeFormatter = (payload: any) => {
    return <div style={{textAlign: "left"}} >
      {payload.value == 0 ? <span className="badge badge-dark">HTML</span> : payload.value == 1 ? <span className="badge badge-dark">TEXT</span> : <span className="badge badge-dark">両方</span>}
    </div>
};
const FromFormatter = (payload: any) => {
    return <div style={{textAlign: "left"}} >{payload.value}</div>
};

const DateFormatter = (payload: any) => {
  if (payload.value) {
    return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
  } else {
    return "";
  }
};

const draftFormatter = (payload: any) => {
  console.log(payload)

  if (payload.row.requestRentMMediaId > 0) {
    let portal;
    portal = payload.row.requestRentMMediaId === 1 ? <span className="badge badge-primary">アパマン</span>
      : payload.row.requestRentMMediaId === 14 ? <span className="badge badge-success">スーモ</span>
        : "";

    return <div style={{textAlign: "left"}}><span>
      {payload.row.toMail === "info@global-center.co.jp" ? <span>{portal} <span className="badge badge-success">番</span> {payload.value}</span> : ""}
      {payload.row.toMail === "rainbow@global-center.co.jp" ? <span>{portal} <span className="badge badge-warning">レ</span> {payload.value}</span> : ""}
      {payload.row.toMail === "takamatsu@global-center.co.jp" ? <span>{portal} <span className="badge badge-danger">駅</span> {payload.value}</span> : ""}
      {payload.row.toMail === "east@global-center.co.jp" ? <span>{portal} <span className="badge badge-dark">東</span> {payload.value}</span> : ""}
      {payload.row.toMail === "park@global-center.co.jp" ? <span>{portal} <span className="badge badge-light">中</span> {payload.value}</span> : ""}
      {payload.row.toMail === "sun@global-center.co.jp" ? <span>{portal} <span className="badge badge-info">サ</span> {payload.value}</span> : ""}
      {payload.row.toMail === "cd@global-center.co.jp" ? <span>{portal} <span className="badge badge-secondary">通</span> {payload.value}</span> : ""}


    </span></div>;
  } else if (payload.row.openedAt !== null) {
    return <div style={{textAlign: "left"}}><span><span className="badge badge-secondary">既　読</span> {payload.value}</span></div>
  } else if (payload.row.isSended === 1) {
    return <div style={{textAlign: "left"}}><span><span className="badge badge-success">下書き</span> {payload.value}</span></div>
  } else if (payload.row.isSended === 2) {
    return <div style={{textAlign: "left"}}><span><span className="badge badge-primary">送信済</span> {payload.value}</span></div>
  } else {

    if (payload.row.toMail === "info@global-center.co.jp") {
      return <div style={{textAlign: "left"}}><span> <span className="badge badge-success">番</span> {payload.value}</span></div>
    } else if (payload.row.toMail === "rainbow@global-center.co.jp") {
      return <div style={{textAlign: "left"}}><span><span className="badge badge-warning">レ</span> {payload.value}</span></div>
    } else if (payload.row.toMail === "takamatsu@global-center.co.jp") {
      return <div style={{textAlign: "left"}}><span><span className="badge badge-danger">駅</span> {payload.value}</span></div>
    } else if (payload.row.toMail === "east@global-center.co.jp") {
      return <div style={{textAlign: "left"}}><span> <span className="badge badge-dark">屋</span> {payload.value}</span></div>
    } else if (payload.row.toMail === "park@global-center.co.jp") {
      return <div style={{textAlign: "left"}}><span> <span className="badge badge-light">中</span> {payload.value}</span></div>
    } else if (payload.row.toMail === "sun@global-center.co.jp") {
      return <div style={{textAlign: "left"}}><span><span className="badge badge-info">サ</span> {payload.value}</span></div>
    } else if (payload.row.toMail === "cd@global-center.co.jp") {
      return <div style={{textAlign: "left"}}><span> <span className="badge badge-secondary">通</span> {payload.value}</span></div>
    } else {
      return <div style={{textAlign: "left"}}>{payload.value}</div>
    }


  }
};




const RowRenderer = ({ renderBaseRow, ...props }: any) => {
  //console.log(props)
  //const color = props.idx % 2 ? "green" : "blue";


  const backgroundColor = props.row.isSended === 1 ? " row-highlight-green" : props.row.isSended === 2 ? " row-highlight-gray" : "";

  //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
  return <span className={backgroundColor}>{renderBaseRow(props)}</span>;



};





/**
 *会計連動グリッド
 *
 * @class AccountingGridComponent
 * @extends {Component<Props, State>}
 */
class MailGridComponent extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.togglesheet = this.togglesheet.bind(this);
    this.getCellActions = this.getCellActions.bind(this);

    this.state = {
      columns: [

        { key: 'sendType', name: '形式', width: 50, resizable: true,  formatter: SendTypeFormatter },
        { key: 'subject', name: '件名', width: 300, resizable: true,  formatter: draftFormatter },

        { key: 'fromName', name: 'From', width: 150, resizable: true, formatter: FromFormatter },
        { key: 'date', name: '日時', width: 150, resizable: true, formatter: DateFormatter },
        { key: 'id', name: 'ID', width: 60, resizable: true },

        { key: 'del', name: '紐付け解除', width: 90, resizable: false },

      ],
      modalsheet:false,
      mail_id:0,
    };


  }






  componentWillMount() {

    // this.props.get_mails()


  }
  getSelectedCell(event: any) {
    alert()
  }

  onRowClick = (rowIdx: any, row: any, column: any,) => {

    if(column && column.key !== "del"){


    if (row) {
      if (row.isSended === 1) {

        this.props.change_editor_height(window.innerHeight)
        this.props.change_editor_width(1000)

        this.props.change_mail_minimum(0)
        this.props.draft_mail_edit(row)
      } else {

        this.props.toggleselect(row.id)
      }

    }

    }
  }


  onRowsSelected = (rows: any) => {
    console.log(rows)
    rows.forEach(function (r: any) {
      r.row.selected = true;
    });

    this.forceUpdate();

  };

  onRowsDeselected = (rows: any) => {

    rows.forEach(function (r: any) {
      r.row.selected = false;
    });
    this.forceUpdate();
  };
  onScroll(state: any) {

    if (state.rowVisibleEndIdx >= this.props.mails.length && this.props.mail_loading === 0) {

      this.props.add_mails()
    }

  }

  EmptyRowsView = () => {
    const message = "検索結果がありません。";

    return (
      this.props.response_mail_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", height: 250, padding: "100px" }} > <h3>{message}</h3></div>

    );
  };

  togglesheet = (mail_id:any) => {
// alert(mail_id)
    this.setState(prevState => ({
        modalsheet: !prevState.modalsheet,
        mail_id:!prevState.modalsheet ? mail_id:0,
      }));
  }

  getCellActions(column: any, row: any) {
    // console.log(row.way)

    // const cellActions = {

    //   coming: comingActions
    // };

    if (column.key === "del" && row.requestRentMMediaId > 0) {

        return [
            {
                icon: <i className="fas fa-times" style={{marginLeft:-5, marginTop:5}}></i>,
                callback: () => {
                    // alert(row.id)
                    this.togglesheet(row.id)
                    // this.setState({ rent_introduction_id: row.id })

                }
            }

        ]
    }
}


  render() {

    return (
      <div>

        <ReactDataGrid
          // ref={node => this.grid = node}
          enableCellSelect={false}
          columns={this.state.columns}
          rowGetter={(i: number) => this.props.response_mails[i]}

          rowsCount={this.props.response_mails.length}
          headerRenderer={true}
          dragable={true}
          onScroll={this.onScroll}
          // onColumnResize={(idx, width) =>
          //     console.log(`Column ${idx} has been resized to ${width}`)
          // }
          emptyRowsView={this.EmptyRowsView}
          onRowClick={this.onRowClick}
          getCellActions={this.getCellActions}
          // getCellActions={this.getCellActions}
          // rowSelection={{
          //     showCheckbox: true,
          //     enableShiftSelect: true,
          //      onRowsSelected: this.onRowsSelected,
          //     onRowsDeselected: this.onRowsDeselected,
          //     selectBy: {
          //       isSelectedKey: 'selected'
          //       // indexes: this.state.selectedIndexes
          //     }
          //   }}
          rowRenderer={RowRenderer}
          minHeight={300} />

          <Modal isOpen={this.state.modalsheet} toggle={this.togglesheet} fade={false}>

          <ModalBody>
              <div style={{fontWeight:"bold"}}>反響メールの結び付けを解除しますが、よろしいですか？</div>
              <div style={{marginTop:10}}>※解除すると、反響登録されていない情報として追客リストに表示されます。</div>
          </ModalBody>
          <ModalFooter>
              <MuiButton variant="contained" color="secondary" onClick={() => {this.props.delete_media_mail(this.state.mail_id);this.setState(() => ({modalsheet:false,mail_id:0}))}}>はい</MuiButton>
              <MuiButton variant="contained" style={{ marginLeft: 3 }} onClick={this.togglesheet}>いいえ</MuiButton>
          </ModalFooter>

          </Modal>

      </div>
    );
  }


}



const mapStateToProps = (state: any) => {
  return {

    mails: state.mailReducer.mails,
    mail_loading: state.mailReducer.mail_loading,

    // response_mail_loading: state.mailReducer.response_mail_loading,


  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    // get_mails(state: any) {
    //   dispatch(Mail.get_mails(state))
    // },
    add_mails(state: any) {
      dispatch(Mail.add_mails(state))
    },
    change_mail_loading(state: number) {
      dispatch(Mail.change_mail_loading(state))
    },
    change_mail_new(state: number) {
      dispatch(Mail.change_mail_new(state))
    },
    get_response_mails(state: number) {
      dispatch(Mail.get_response_mails(state))
    },
    change_mail_minimum(state: number) {
      dispatch(Mail.change_mail_minimum(state))
    },
    delete_media_mail(state: number) {
      dispatch(Mail.delete_media_mail(state))
    },

    change_editor_height(state: number) {
      dispatch(Mail.change_editor_height(state))
    },
    change_editor_width(state: number) {
      dispatch(Mail.change_editor_width(state))
    },
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(MailGridComponent);