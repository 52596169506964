const authentication = require('../../../react-azure-adb2c2').default;

export const update = (value: any) => {
    // alert( JSON.stringify(value))
    // const url = `http://localhost:5000/v1/rent/corporate/contacts/updatecalendar/` + value.before_owner_id + "/" + value.eigyou_no;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/contacts/updatecalendar/` + value.before_owner_id + "/" + value.eigyou_no;
  
    const token = authentication.getAccessToken();
  
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),
  
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
  
  };

export const insert = (value: any) => {

    console.log(value)
    // const url = `http://localhost:5000/v1/rent/corporate/contacts/insertcalendar`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/contacts/insertcalendar`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};