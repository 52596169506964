const authentication = require('../../../react-azure-adb2c2').default;




export const responsedetail = (value: any) => {

    let url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateResponse/` + value.id + '?rentResponseId=' + value.rentResponseId;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const detail = (value: any) => {

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateResponse/` + value + '?rentResponseId=89389&tatemonoId=10&roomId=1';
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const rooms = (value: any) => {

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateRooms/` + value.id + '?rentResponseId=' + value.rentResponseId + '&tatemonoId=' + value.tatemonoId + '&roomId=' + value.roomId + '';
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const list = (value: any) => {

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/`
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplates/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const insert = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplates/`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const deletetemplate = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const insertcategory = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/`
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
export const updatecategory = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const deletecategory = (value: any) => {
    console.log(value)

    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};