import React, { Component } from 'react';
import TestScheduler from './TestScheduler';
import { connect } from 'react-redux';
import '../../Css/Scheduler.css';

class SchedulerComponent extends Component {
    constructor(props) {
        super(props);
     
        this.onView = this.onView.bind(this);
    }
    onView(state){
        if(state==="agenda"){
            this.props.togglescheduler();
        }
    }
    render() {
        return (
            <div className="container-fluid" >
                
                <div className="row">
                 
                </div>
                <div className="row">
                <div className="item-outer col-sm-12">

                </div>
                    <div className="item-outer col-sm-12">
                    
                        <div className="item">
                      
                    <TestScheduler onView={this.onView}
                    />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect()(SchedulerComponent);