import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseEditComponents from './BaseEditComponents';
import RoomEditComponent from './RoomEditComponent';
import {  Row, Col } from 'reactstrap';

class TatemonoInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: '2',
            
        };
        
        this.handleClick = this.handleClick.bind(this);
     

    }
    handleClick(event) {
        if (this.state.mode === 1) {
            this.setState({
                
                mode: '2',
            });
        } else {
            this.setState({
                
                mode: '1',
            });
        }
    }
 


    render() {
      
    return (
     
        <Row>
            <Col lg="12" className="item-outer">
                <form>
                {<BaseEditComponents onSubmit={this.props.onSubmit}/>}
                </form>
            </Col>
            <Col lg="6" className="item-outer">
                {<RoomEditComponent />}
            </Col>
           
        </Row>

    );
  }
}

// function renderForecastsTable(handleClick, handleFormSubmit, state) {
  
//     if (state.mode===1) {
//         return (
//             <button className="btn btn-success float-right" onClick={handleClick}>編集</button>
//         );
//     } else {
//         //return (
//         //    <div>
//         //        <button className="btn btn-default float-right" onClick={handleClick}>閉じる</button>

//         //    </div>
//         //);
//     }
// }

export default connect(
)(TatemonoInfo);
