import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import '../../Css/Grid.css';
import Loading from '../Layout/LoadingComponent';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import './Css/Grid.css'
import ErrorIcon from '@mui/icons-material/Error';



interface Props {
    attentions_matching_list: any
    attentions_matching_list_loading: any
    grid_width: any
    
}

interface State {
    modalmail: boolean,
    modalselect: boolean,
    modalMatching: boolean,
    mailMatchingId: number,
    refresh: boolean,
    modalMailDelete: boolean,
    columns: any,
    colSortDirs: any,


}



const columns = [
    { key: 'detail', name: '詳細', width: 50 },
    { key: 'date', name: '対応日', width: 90 },
    { key: 'name', name: '名前', width: 100},
    { key: 'kana', name: 'カナ', width: 100},
    { key: 'tel1', name: 'TEL', width: 100 },
    { key: 'mail', name: 'メール', width: 120 },
    { key: 'birthday', name: '生年月日', width: 90 },
    { key: 'remarks', name: '内容', width: 300 },
    { key: 'nikkei_telecom', name: '日経', width: 50 },
    { key: 'tenpo_name', name: '部署', width: 100 },
    { key: 'nickname', name: '担当', width: 80 },
];



var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
  };
  


  
const Base = ({ column, props, rowIndex }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.attentions_matching_list[rowIndex][column.key] ? props.attentions_matching_list[rowIndex][column.key] : ""}
</Cell>;

const Birthday = ({ column, props, rowIndex }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.attentions_matching_list[rowIndex][column.key] ? moment(props.attentions_matching_list[rowIndex][column.key]).utc().format("YYYY/MM/DD") : ""}
</Cell>;

const Nikkei = ({ column, props, rowIndex }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.attentions_matching_list[rowIndex][column.key] ? <ErrorIcon /> : ""}
</Cell>;

const Link = ({ column, props, rowIndex, openShinyou }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
    {
        (() => {
          
          return (
            <IconButton
              style={{padding:2}}
              onClick={(e)=>openShinyou(props.attentions_matching_list[rowIndex].id)}
              size="large"><OpenInNewIcon /></IconButton>
          );
            // return <Button variant="contained" color='primary' size="small" onClick={(e)=>openShinyou(props.attentions_matching_list[rowIndex].id)} >反響詳細</Button>
        })()
    }
</Cell>;


class CustomerDuplicateList extends Component<Props, State>{
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            // height: window.innerHeight,
            modalselect: false,
            modalMatching: false,
            mailMatchingId: 0,
            modalmail: false,
            refresh: false,
            modalMailDelete: false,
            columns: columns,
            colSortDirs: {},
        };

        this.refresh = this.refresh.bind(this);


        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this._onSortChange = this._onSortChange.bind(this);

        // this.RowClick = this.RowClick.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);


        // alert(this.props.mail_id)
    }


 


    refresh() {
        this.setState({
            refresh: true
        });
    }

    _onColumnResizeEndCallback(newColumnWidth:any, columnKey:any) {

        let columnsset = this.state.columns.map(function (value:any) {
          if (value.key === columnKey) {
            value.width = newColumnWidth
          }
          return value
        })
    
        this.setState({ columns: columnsset })
    
      }

    _onSortChange(columnKey: any, sortDir: any) {

        // alert(columnKey)
        // alert(_defaultSortIndexes.indexOf(columnKey))
        let attentions_matching_list = this.props.attentions_matching_list;
        attentions_matching_list.sort((indexA:any, indexB:any) => {

          var valueA = indexA[columnKey];
          var valueB = indexB[columnKey];
          var sortVal = 0;
          if (valueA > valueB) {
            sortVal = 1;
          }
          if (valueA < valueB) {
            sortVal = -1;
          }
    
          if (!valueA && !valueB) {
            sortVal = 0
          }
          if (!valueA) {
            sortVal = -1
          }
          if (!valueB) {
            sortVal = 1
          }
    
    
          if (sortVal !== 0 && sortDir === SortTypes.DESC) {
            sortVal = sortVal * -1;
          }
    
          return sortVal;
        });
        // this.props.get_attentions_matching_list_success(attentions_matching_list)
        this.setState({
          // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
          colSortDirs: {
            [columnKey]: sortDir,
          },
        });
    }
    


    openShinyou(id:any){

    }

    _rowClassNameGetter(index:any){//#ffb8b8
        
      if(this.props.attentions_matching_list && this.props.attentions_matching_list[index]) {
        if(this.props.attentions_matching_list[index].nikkei_telecom){
          return "nikkei"
        }else{
          return "normal"
        }
          
      }else{
        return "normal"
      }
    }


    render() {

        return (
            <div>
            {this.props.attentions_matching_list_loading ? <div style={{height:300}}><Loading /></div> :
            <Table
              rowHeight={40}
              rowsCount={this.props.attentions_matching_list.length}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
              touchScrollEnabled={true}
              width={this.props.grid_width}
              height={300}
              headerHeight={40}
              className='gridAttentionNormalList'
            //   rowClassNameGetter={"aaaaa"}
            //   onRowClick={this.RowClick}
              rowClassNameGetter={this._rowClassNameGetter}
                // rowClassNameGetter = {'normal'}
              >

              {this.state.columns.map((value:any, i:any) => {
                return <Column
                  columnKey={value.key}
                  header={<Cell 
                    // onSortChange={this._onSortChange}
                    // sortDir={this.state.colSortDirs[value.key_name]}
                    // style={{ whiteSpace: "nowrap" }}
                    >{value.name}</Cell>}

                  // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                  cell={({ rowIndex, ...props }) => (


                    value.key === "date" || value.key === "birthday" ? <Birthday column={value} props={this.props} rowIndex={rowIndex}></Birthday>
                    : value.key === "nikkei_telecom" ? <Nikkei column={value} props={this.props} rowIndex={rowIndex}></Nikkei>
                    : value.key === "detail" ? <Link column={value} props={this.props} rowIndex={rowIndex} openShinyou={this.openShinyou}></Link>
                      : <Base column={value} props={this.props} rowIndex={rowIndex}></Base>

                  )}

                  width={value.width}
                  isResizable={true}
                //   fixed={value.frozen}

                />
              }, this.props)}




{/* { key: 'detail', name: '詳細', width: 50 },link
    { key: 'date', name: '対応日', width: 80 },date
    { key: 'name', name: '名前', width: 100},
    { key: 'kana', name: 'カナ', width: 100},
    { key: 'tel1', name: 'TEL', width: 100 },
    { key: 'mail', name: 'メール', width: 120 },
    { key: 'birthday', name: '誕生日', width: 80 },date
    { key: 'remarks', name: '内容', width: 200 },
    { key: 'nikkei_telecom', name: '日経', width: 50 },check
    { key: 'tenpo_name', name: '部署', width: 100 },
    { key: 'nickname', name: '担当', width: 80 }, */}






            </Table >
            }







            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
  return {


    attentions_matching_list: state.customersReducer.attentions_matching_list,
    attentions_matching_list_loading: state.customersReducer.attentions_matching_list_loading
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    // get_response_matching_select_list_success(state: any) {
    //   dispatch(Responses.get_response_matching_select_list_success(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDuplicateList);