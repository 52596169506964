
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';


import { call, put, takeEvery, select } from 'redux-saga/effects';
// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import * as m_manuals_api from '../Api/m_manuals_api';
// import * as rent_application_docs_api from '../Api/rent_application_docs_api';


// import * as RentIntroductionFilesApi from '../Api/RentIntroductionFilesApi';
// import * as rent_files_api from '../Api/rent_files_api';

// import * as Contracts from '../../Contracts/Saga/Contracts';

export interface RoomsState {
  manuals: any;
  manual_disp: boolean;
  manual_change_flg: boolean;
}
const initialState: RoomsState = {

  manuals: null,
  manual_disp: false,
  manual_change_flg: false,
}



///action 
const actionCreator = actionCreatorFactory();


// export const get_rent_response_docs = actionCreator('GET_RENT_RESPONSE_FILES');
// export const change_rent_response_docs = actionCreator<any>("CHANGE_RENT_RESPONSE_DOCS");
// export const change_rent_response_files_loading = actionCreator<boolean>('CHANGE_RENT_RESPONSE_FILES_LOADING');
// export const download_rent_response_file = actionCreator('DOWNLOAD_RENT_RESPONSE_FILE');

export const get_manuals = actionCreator<number>('GET_MANUALS');

export const change_manuals = actionCreator<any>('CHANGE_MANUALS');

export const change_manual_disp = actionCreator<any>('CHANGE_MANUAL_DISP');

export const change_manual_change_flg = actionCreator<any>('CHANGE_MANUAL_CHANGE_FLG');


// export const change_response_file_data = actionCreator<any>('CHANGE_RESPONSE_FILE_DATA');
// export const change_response_file_remarks = actionCreator<any>('CHANGE_RESPONSE_FILE_REMARKS');

// export const upload_response_file = actionCreator('UPLOAD_RESPONSE_FILE');
// export const edit_response_file_remarks = actionCreator('EDIT_RESPONSE_FILE_REMARKS');

// export const delete_response_file = actionCreator('DELETE_RESPONSE_FILE');

// export const change_response_file_update_disp = actionCreator<boolean>('CHANGE_RESPONSE_FILE_UPDATE_DISP');



export const helpReducer = reducerWithInitialState(initialState)
  // .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))
  // .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))
  .case(change_manuals, (state, payload) => ({ ...state, manuals: payload }))

  .case(change_manual_disp, (state, payload) => ({ ...state, manual_disp: payload }))

  .case(change_manual_change_flg, (state, payload) => ({ ...state, manual_change_flg: payload }))



// .case(change_rent_response_files_loading, (state, payload) => ({ ...state, rent_response_files_loading: payload }))

// .case(change_response_file_data, (state, payload) => ({ ...state, response_file_data: payload }))
// .case(change_response_file_remarks, (state, payload) => ({ ...state, response_file_remarks: payload }))

// .case(change_response_file_update_disp, (state, payload) => ({ ...state, response_file_update_disp: payload }))

// .case(change_public_files, (state, payload) => ({ ...state, public_files: payload }))

// .case(change_application_files, (state, payload) => ({ ...state, application_files: payload }))

// .case(change_flie_loading, (state, payload) => ({ ...state, flie_loading: payload }))

// .case(change_contract_files, (state, payload) => ({ ...state, contract_files: payload }))




// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const helpSaga = [
  takeEvery('GET_MANUALS', handle_get_manuals),
  // takeEvery('DOWNLOAD_RENT_RESPONSE_FILE', handle_download_rent_response_file),
  // takeEvery('UPLOAD_RENT_RESPONSE_FILE', handle_upload_rent_response_file),

  // takeEvery('DOWNLOAD_CONTRACT_FILES', handle_download_contract_files),


  // takeEvery('EDIT_RESPONSE_FILE_REMARKS', handle_edit_response_file_remarks),


  // takeEvery('DELETE_RESPONSE_FILE', handle_delete_response_file),


  // takeEvery('GET_FILES', handle_get_files),

];
// export default masterSaga;





function* handle_get_manuals(action: any) {
  // yield put(change_rent_response_files_loading(true));
  yield put(change_manuals(null));
  const { payload, error } = yield call(m_manuals_api.download, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    // alert(payload)

    yield put(change_manuals(payload));
    yield put(change_manual_change_flg(true));


    // yield put(change_rent_response_files_loading(false));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}



// function* handle_download_rent_response_file(action: any) {

//   const { payload, error } = yield call(rent_response_docs_api.download, action.payload.id);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     // 成功時アクション呼び出し
//     console.log(payload)
//     const a = document.createElement('a');
//     // ダウンロードされるファイル名
//     a.download = action.payload.name;
//     // a.download = "10.pdf";
//     a.href = URL.createObjectURL(payload);
//     // ダウンロード開始
//     a.click();
//     //    yield put(get_rent_contract_monies_success(payload));

//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }

// function* handle_upload_rent_response_file(action: any): any {
//   yield put(change_rent_response_files_loading(true));
//   const state = yield select();
//   console.log(state.responsefiles.response_file_data)
//   const { payload, error } = yield call(rent_response_docs_api.upload, { response_id: state.responsesReducer.responseid, file: state.responsefiles.response_file_data, remarks: state.responsefiles.response_file_remarks });

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     yield put(change_rent_response_docs(payload));
//     // 成功時アクション呼び出し
//     // console.log(payload)
//     // yield put(change_rent_response_file_update_disp(false));
//     yield put(change_rent_response_files_loading(false));
//     // const a = document.createElement('a');
//     // ダウンロードされるファイル名
//     // a.download = action.payload.name;
//     // a.download = "10.pdf";
//     // a.href = URL.createObjectURL(payload);
//     // ダウンロード開始
//     // a.click();
//     //    yield put(get_rent_contract_monies_success(payload));

//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }



// function* handle_get_files(action: any) {

//   // yield put(change_get_mail_detail_files_loading(1))
//   // console.log( action.payload)
//   // yield put(get_mail_detail_files_success([]))
//   yield put(change_flie_loading(true))

//   const { payload, error } = yield call(rent_files_api.list, action.payload);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     console.log(payload)
//     console.log(payload.rent_application_docs)

//     yield put(change_rent_response_docs(payload.rent_response_docs))
//     yield put(change_public_files(payload.rent_public_docs))
//     yield put(change_application_files(payload.rent_application_docs))
//     yield put(change_contract_files(payload.rent_contract_docs))
//     yield put(change_flie_loading(false))
//     //  yield put(change_get_mail_detail_files_loading(0))




//   } else {

//   }
// }



// function* handle_download_contract_files(action: any): any {
//   // yield put(change_line_files_loading(true));
//   yield put(Contracts.change_contracts_rent_application_doc_uri(""));
//   const state = yield select();


//   alert(action.payload.extension)
//   const { payload, error } = yield call(rent_files_api.download_contracts, action.payload.id);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {

//     yield put(Contracts.change_contracts_rent_application_doc_uri(payload));
//     yield put(Contracts.change_contracts_doc_extension(action.payload.extension.trim()));
//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }


// function* handle_download_application_files(action: any): any {
//   // yield put(change_line_files_loading(true));
//   yield put(Contracts.change_contracts_rent_application_doc_uri(""));
//   const state = yield select();


//   alert(action.payload.extension)
//   const { payload, error } = yield call(rent_application_docs_api.download, action.payload.id);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {

//     yield put(Contracts.change_contracts_rent_application_doc_uri(payload));
//     yield put(Contracts.change_contracts_doc_extension(action.payload.extension.trim()));
//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }