import { connect } from 'react-redux';
import Table from './ApprovalTable';
import { Radio, RadioGroup } from '@mui/material';
import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import { CSVLink, CSVDownload } from "react-csv";
import Button from '@mui/material/Button';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Row, Col } from 'reactstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const authentication = require('react-azure-adb2c2').default;

export const ThemeContext = createContext();

// const token = authentication.getAccessToken();

// csvカラム★
const headers = [
    { label: "申請No", key: "application_id" },
    { label: "申請日", key: "created_at" },
    { label: "申請者", key: "user_name" },
    { label: "申請フォーム", key: "form_name" },
    { label: "承認状況", key: "approval_name" },
    { label: "タイトル", key: "title" },
  ];


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function App(props) {

    const [chart_name, setChartName] = useState("案内数"); //タイトル★

    const [tenpolist, setTenpoList] = useState([]);


    /////////////////////////////////////
    const [approval_list, setApprovalList] = useState([]);
    const [approval_list_search, setApprovalListSearch] = useState([]);
    const [table_column, setTableColumn] = useState([]); //カラム名

    const [formlist, setFormList] = useState([]);//申請書の種類
    const [userlist, setUserList] = useState([]);//ユーザーリスト

    const [value, setValue] = useState(0);//タブ

    const [form, setForm] = useState([]);
    const [user, setUser] = useState([]);
    const [title, setTitle] = useState([]);
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [approval_start_date, setApprovalStartDate] = useState("");
    const [approval_end_date, setApprovalEndDate] = useState("");

    const token = authentication.getAccessToken();

    const getSearchListData = async () => {
        // alert("ws")
        const f = await fetch(`http://localhost:5000/v1/flw/forms`, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })

        const d = await f.json()

        setFormList(d)

    }

    const getApprovalData = async (user_id, section_id) => {
        // alert()

        let search = {user_id: user_id, section_id: section_id}

        const f = await fetch(`http://localhost:5000/v1/flw/applications`, { method: 'post', body: JSON.stringify(search), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })

        const d = await f.json()

        setApprovalList(d)
// alert("a")
        // カラム★
        let column = [
            {name:"申請No", size:100, column:"application_id"},
            {name:"申請日", size:100, column:"created_at"}, 
            {name:"申請者", size:120, column:"user_name"}, 
            {name:"申請フォーム", size:250, column:"form_name"}, 
            {name:"承認状況", size:100, column:"approval_name"}, 
            {name:"タイトル", size:380, column:"title"},
            // {name:"タイトル", size:380, column:"status_user_id"},
            
        ]

        setTableColumn(column)
    }

    useEffect(() => {
        
        getSearchListData()

        setUserList(props.users_select)

        if(props.user_details.amSectionId){
            // alert(props.user_details.id)
            // alert(props.user_details.amSectionId)
            getApprovalData(props.user_details.id, props.user_details.amSectionId)
        }
        
    }, [props])

 
    useEffect(() => {
        // alert("b")
        // alert(value)
        let list = []
        if(approval_list.length > 0){
            if(value == 0){//承認待ち
                list = approval_list.filter(a=> a.status_user_id == props.user_details.id)
            }else if(value == 1){//進行中
                list = approval_list.filter(a=> a.approval_flg == 0)
            }else if(value == 2){//すべて
                list = approval_list
            }else if(value == 3){//完了
                list = approval_list.filter(a=> a.approval_flg == 1)
            }else if(value == 4){//差し戻し
                list = approval_list.filter(a=> a.approval_flg == 2)
            }else if(value == 5){//却下
                list = approval_list.filter(a=> a.approval_flg == 3)
            }


            if(form != "" && form != -1){
                list = list.filter(a=> a.form_id == form)
            }
            if(user != "" && user != -1){
                list = list.filter(a=> a.user_id == user)
            }
            if(start_date != ""){
                list = list.filter(a=> moment(a.created_at).format("YYYY-MM-DD") >= moment(start_date).format("YYYY-MM-DD"))
            }
            if(end_date != ""){
                list = list.filter(a=> moment(a.created_at).format("YYYY-MM-DD") <= moment(end_date).format("YYYY-MM-DD"))
            }
            if(start_date != ""){
                list = list.filter(a=> moment(a.created_at).format("YYYY-MM-DD") >= moment(start_date).format("YYYY-MM-DD"))
            }
            if(end_date != ""){
                list = list.filter(a=> moment(a.created_at).format("YYYY-MM-DD") <= moment(end_date).format("YYYY-MM-DD"))
            }
            if(approval_start_date != ""){
                list = list.filter(a=> moment(a.updated_at).format("YYYY-MM-DD") >= moment(approval_start_date).format("YYYY-MM-DD"))
            }
            if(approval_end_date != ""){
                list = list.filter(a=> moment(a.updated_at).format("YYYY-MM-DD") <= moment(approval_end_date).format("YYYY-MM-DD"))
            }
            if(title != ""){
                list = list.filter(a=> a.title && a.title.indexOf(title) !== -1)
            }

            // if(user){
            //     alert(user)
            //     list = list.filter(a=> a.user_id == user)
            // }
            // if(start_date){
            //     alert(start_date)
            //     list = list.filter(a=> moment(a.created_at).format("YYYY-MM-DD") >= moment(start_date).format("YYYY-MM-DD"))
            // }

            
            // alert("list3"+list.length)
            setApprovalListSearch(list)
        }
        
    }, [value, props, approval_list, start_date, end_date, form, user, approval_start_date, approval_end_date,title])

    // const handleChange = (event, newValue) => {
    //     alert()
    //     setValue(newValue);

    // };

    return (
        <ThemeContext.Provider value={{
            table: approval_list_search, column: table_column}}>
            <div>


{/* 
                {chart_disp.table == 1 ? chart_type != 0 ? <Button style={{ float: "right", marginRight:5, top:-20}} color="secondary" size="small" variant="contained" onClick={() => setChartType(0)}><ListIcon  style={{fontSize:15}} />　リスト</Button>: <Button style={{ float: "right", marginRight:5, top:-20}} color="secondary" size="small" variant="contained" onClick={() => setChartType(0)} disabled><ListIcon  style={{fontSize:15}} />　リスト</Button>:""}
                {chart_disp.composed == 1 ? chart_type != 1 ? <Button style={{ float: "right", marginRight:5, top:-20}} color="secondary" size="small" variant="contained" onClick={() => setChartType(1)}><BarChartIcon  style={{fontSize:15}} />　棒</Button>: <Button style={{ float: "right", marginRight:5, top:-20}} color="secondary" size="small" variant="contained" onClick={() => setChartType(1)} disabled><BarChartIcon  style={{fontSize:15}} />　棒</Button>:""}
                {chart_disp.pie == 1 ? chart_type != 2 ? <Button style={{ float: "right", marginRight:5, top:-20}} color="secondary" size="small" variant="contained" onClick={() => setChartType(2)}><PieChartIcon  style={{fontSize:15}} />　円</Button>: <Button style={{ float: "right", marginRight:5, top:-20}} color="secondary" size="small" variant="contained" onClick={() => setChartType(2)} disabled><PieChartIcon  style={{fontSize:15}} />　円</Button>:""} */}


        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}

        <Card>
            <CardContent style={{paddingTop:0, paddingBottom:0}}>

        <Row style={{margin:0}}>
            <Col lg="2" style={{ padding: 3 }}>
                {formlist.length ? <Autocomplete
                    fullWidth
                    // options={tenpolist.filter(a=> a.tenpo_no != -1)}
                    options={formlist}
                    getOptionLabel={(option) => option.form_name}
                    // defaultValue={tenpolist.filter(a => a.tenpo_no === 1)[0]}
                    value={tenpolist.filter(a => a.form_id === form)[0]}
                    // style={{ width: 200 }}
                    onChange={(e, newValue) => {
                        setForm(newValue ? newValue.form_id : -1);
                      }}
                    renderInput={(params) => <TextField
                        {...params} label="申請書を選択" />}

                /> : ""}
            </Col>
            <Col lg="2" style={{ padding: 3 }}>
                {userlist.length ? <Autocomplete
                    fullWidth
                    // options={tenpolist.filter(a=> a.tenpo_no != -1)}
                    options={userlist}
                    getOptionLabel={(option) => option.label}
                    // defaultValue={tenpolist.filter(a => a.tenpo_no === 1)[0]}
                    value={userlist.filter(a => a.value === user)[0]}
                    // style={{ width: 200 }}
                    onChange={(e, newValue) => {
                        setUser(newValue ? newValue.value : -1);
                      }}
                    renderInput={(params) => <TextField
                        {...params} label="申請者を選択" />}

                /> : ""}
            </Col>
            <Col lg="2" style={{ padding: 3 }}>

                <TextField
                    id="start_date"
                    label="申請日　開始"
                    type="date"
                    name="start_date"
                    size="small"
                    variant='outlined'
                    onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    value={moment(start_date).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {/* <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    label="申請日　開始"
                    style={{marginTop:0, width:"100%" }}
                    autoOk={true}   
                    InputLabelProps={{ shrink: true }}
                    name="start_date"
                    value={start_date == "" ? null : start_date}
                    onChange={(e) => {
                        setStartDate(e);
                      }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    invalidDateMessage={"日付の形式が違います"}
                /> */}
            </Col>
            <Col lg="2" style={{ padding: 3 }}>
                <TextField
                    id="end_date"
                    label="申請日　終了"
                    type="date"
                    name="end_date"
                    size="small"
                    variant='outlined'
                    onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    value={end_date == "" ? null : moment(end_date).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {/* <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    label="申請日　終了"
                    style={{marginTop:0, width:"100%" }}
                    autoOk={true}
                    InputLabelProps={{ shrink: true }}
                    name="end_date"
                    value={end_date == "" ? null : end_date}
                    onChange={(e) => {
                        setEndDate(e);
                      }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    // invalidDateMessage={"日付の形式が違います"}
                /> */}

                
            </Col>
            <Col lg="4" style={{ padding: 3 }}>
                {approval_list.length ? 
                <Button style={{ backgroundColor: "#5cb85c", float: "right", marginRight:5 }} size="small" variant="contained" color="primary">
                    <CSVLink style={{textDecoration:"none", color:"white" }} headers={headers} filename={"承認リスト.csv"} data={approval_list_search}>CSV</CSVLink>
                </Button>:""}
            </Col>
            <Col lg="4" style={{ padding: 3 }}>
                <TextField
                    id="outlined-multiline-static"
                    label="タイトル"
                    value={title}
                    name="title"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    rows={4}
                    fullWidth
                    variant="outlined"
                    size="small"
                />
            </Col>
            <Col lg="2" style={{ padding: 3 }}>
                <TextField
                    id="approval_start_date"
                    label="最終承認日　開始"
                    type="date"
                    name="approval_start_date"
                    size="small"
                    variant='outlined'
                    onChange={(e) => {
                        setApprovalStartDate(e.target.value);
                      }}
                    value={approval_start_date == "" ? null : moment(approval_start_date).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {/* <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    label="最終承認日　開始"
                    style={{marginTop:0, width:"100%" }}
                    autoOk={true}   
                    InputLabelProps={{ shrink: true }}
                    name="approval_start_date"
                    value={approval_start_date == "" ? null : approval_start_date}
                    onChange={(e) => {
                        setApprovalStartDate(e);
                      }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    invalidDateMessage={"日付の形式が違います"}
                /> */}
            </Col>
            <Col lg="2" style={{ padding: 3 }}>
                <TextField
                    id="approval_end_date"
                    label="最終承認日　終了"
                    type="date"
                    name="approval_end_date"
                    size="small"
                    variant='outlined'
                    onChange={(e) => {
                        setApprovalEndDate(e.target.value);
                      }}
                    value={approval_end_date == "" ? null : moment(approval_end_date).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {/* <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    label="最終承認日　終了"
                    autoOk={true}
                    style={{marginTop:0, width:"100%" }}
                    InputLabelProps={{ shrink: true }}
                    name="approval_end_date"
                    value={approval_end_date == "" ? null : approval_end_date}
                    onChange={(e) => {
                        setApprovalEndDate(e);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    // inputVariant="outlined"
                /> */}
                            

            </Col>
        </Row>
            </CardContent>
        </Card>
        <Card style={{marginTop:5}}>
            <CardContent style={{paddingTop:0, paddingBottom:0}}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={(event, newValue) => setValue(newValue)} aria-label="basic tabs example">
                        <Tab label="承認待ち" {...a11yProps(0)} />
                        <Tab label="進行中" {...a11yProps(1)} />
                        <Tab label="すべて" {...a11yProps(2)} />
                        <Tab label="完了" {...a11yProps(3)} />
                        <Tab label="差し戻し" {...a11yProps(4)} />
                        <Tab label="却下" {...a11yProps(5)} />
                    </Tabs>
                </Box>
                {/* <TabPanel value={value} index={0}>
                Item One
                </TabPanel>
                <TabPanel value={value} index={1}>
                Item Two
                </TabPanel>
                <TabPanel value={value} index={2}>
                Item Three
                </TabPanel> */}
                {/* {props.user_details.id}
                {props.user_details.amSectionId} */}
                {approval_list_search.length > 0 ? <Table />:""}
                {/* {chart_type == 0 && chart_table.length ? <Table /> : chart_type == 1 && chart_composed.length ? <Composed /> : chart_type == 2 && Object.keys(chart_pie).length ? <Pie /> : ""} */}

            </CardContent>
        </Card>

        {/* </MuiPickersUtilsProvider> */}

              

            </div>
        </ThemeContext.Provider>
    );
}

//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
      user_details: state.masterReducer.user_details,
      users_select: state.masterReducer.users_select,
    }
  }

export default connect(mapStateToProps)(App);