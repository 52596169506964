import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import ReactDataGrid from 'react-data-grid';
import { Editors } from "react-data-grid-addons";
import Button from '@mui/material/Button';
// import * as Tenants from './Saga/Tenants';
import * as Estimates from './Saga/Estimates';
// import '../../Css/Table.css';
// import '../../Css/Grid.css';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Forms from './Css/Form.module.css'

import Select from '@mui/material/Select';
// import Grid from './Css/Grid.module.css'
import {ModalBody,ModalFooter,Modal,Tooltip} from 'reactstrap';

// import './Css/Tenant.css';
// import { EditorBase } from 'common/editors';
const { DropDownEditor} = Editors;
// const { DropDownFormatter } = Formatters;
// const month1Types = [
//     { id: 0 ,value: "" },
//     { id: 2,value:"込み"},
    
// ];
// const month2Types = [
//     { id: 0 ,value: "定額" },
//     { id: 1 ,value: "実費" },
//     { id: 2,value:"込み"},
    
// ];
const payTypes = [
    { id: 0 ,value: "" },
    { id: 2,value:"込み"},
    { id: 3,value: "無し"}
];
const payUpdateTypes = [
    { id: 0 ,value: "" },
    { id: 3,value:"無し"},
];
const payTaikyoTypes = [
    { id: 1 ,value: "実費" },
    { id: 2,value:"込み"},
];

const monthTypes = [
    { id: 0 , value: ""  }
    ,{ id: 1, value: "1ヵ月"  }
    ,{ id: 2,value: "2ヵ月" }
    ,{ id: 3,value: "3ヵ月" }
    ,{ id: 4,value: "4ヵ月" }
    ,{ id: 5,value: "5ヵ月" }
    ,{ id: 6,value: "6ヵ月" }
    ,{ id: 7,value: "7ヵ月" }
    ,{ id: 8,value: "8ヵ月" }
    ,{ id: 9,value: "9ヵ月" }
    ,{ id: 10,value: "10ヵ月" }
    ,{ id: 11,value: "11ヵ月" }
    ,{ id: 12,value: "12ヵ月" }
];
const yearTypes = [
    { id: 0 , value: ""  }
    ,{ id: 1, value: "1年間"  }
    ,{ id: 2,value: "2年間" }

];
const year=[];
year[""]=0;
year["1年間"]=1;
year["2年間"]=2;


const month=[];
month[""]=0;
month["1ヵ月"]=1;
month["2ヵ月"]=2;
month["3ヵ月"]=3;
month["4ヵ月"]=4;
month["5ヵ月"]=5;
month["6ヵ月"]=6;
month["7ヵ月"]=7;
month["8ヵ月"]=8;
month["9ヵ月"]=9;
month["10ヵ月"]=10;
month["11ヵ月"]=11;
month["12ヵ月"]=12;


const monthno=[];
monthno[0]="";
monthno[1]="1ヵ月";
monthno[2]="2ヵ月";
monthno[3]="3ヵ月";
monthno[4]="4ヵ月";
monthno[5]="5ヵ月";
monthno[6]="6ヵ月";
monthno[7]="7ヵ月";
monthno[8]="8ヵ月";
monthno[9]="9ヵ月";
monthno[10]="10ヵ月";
monthno[11]="11ヵ月";
monthno[12]="12ヵ月";


class TooltipItem extends Component {
  // const TooltipItem = props => {
      constructor(props) {
          super(props);
      // const { item, id } = props;
      this.state = {
          tooltipOpen:false
      }
      this.toggle = this.toggle.bind(this);
      // [tooltipOpen, setTooltipOpen] = useState(false);
      }
      // const [tooltipOpen, setTooltipOpen] = useState(false);
  //  toggle = () => this.setTooltipOpen(!this.tooltipOpen);
   toggle() {
      this.setState({tooltipOpen:!this.state.tooltipOpen})
   }
      componentDidMount() {
        // this.setState({tooltipOpen:true})
          // this.setState({tooltipOpen:this.props.item.open===true?true:false})
      }
      render() {
      return (
        <span>
      <span   id={"Tooltip-" + this.props.id} >{this.props.value.amount===0?"　":this.props.value.amount.toLocaleString()}</span>
      {this.props.value.checkAmount>0?<Tooltip
          data-placement="right"
            placement={this.props.item.placement}
            isOpen={this.state.tooltipOpen}
            target={"Tooltip-" + this.props.id}
            toggle={this.toggle}
            className="red-tooltip"
          >{this.props.value.checkRemarks}</Tooltip>:""}
        </span>
      );
      }
    };






class DropDownEditor2 extends  React.Component  {
  constructor(props) {

        super(props);
        this.state = { rentMEtcId: props.value };
      
      }
    getInputNode() {
        
      return ReactDOM.findDOMNode(this);
      
    }
    getValue() {
      // alert(this.state.rentMEtcId)
        return { rentMEtcId:this.state.rentMEtcId};
      }
    onClick() {
      this.getInputNode().focus();
    }
  
    onDoubleClick() {
      this.getInputNode().focus();
    }
  onChange(value){

    this.setState({ rentMEtcId: value.currentTarget.value });

  }
    render() {
      return (
        <select  defaultValue={this.props.value} onBlur={this.props.onBlur} onChange={this.onChange.bind(this)} >
          {this.renderOptions()}
        </select>);
    }
  
    renderOptions() {
      let options = [];
      console.log(this.props)
    
      let types = this.props.options.etcTypes
       if(this.props.rowData.rentMAccountId===2){
        types =this.props.options.kyouekiTypes
      }else if(this.props.rowData.rentMAccountId===3){
        types =this.props.options.suidouTypes
      }else if(this.props.rowData.rentMAccountId===4){
        types =this.props.options.parkingTypes
      }
      types.forEach(function(payload) {
        // if (typeof(name) === 'string') {
          options.push(<option key={payload.id} value={payload.id}>{payload.value}</option>);
        // } else {
        //   options.push(<option key={name.id} value={name.value} title={name.title}  >{name.text || name.value}</option>);
        // }
      }, this);

      return options;
    }
  }
class MoneyEditComponent extends Component {
    
    constructor(props) {
        super(props);
        let columnsTuki1=[]
        let columnsTuki5=[]
        let columnsTukiCorp1=[]
        let columnsTukiCorp5=[]

     



      const cloumnkoumoku =  { key: 'name', name: '項目名', width: 100,  
      editor: <DropDownEditor options={this.props.monthKoumoku} /> ,
      editable:  function (rowData) {
       return (rowData.rentMAccountId===1 || rowData.rentMAccountId===2 || rowData.rentMAccountId===3 || rowData.rentMAccountId===4  || rowData.rentMAccountId===-1) ? false : true; 
      }
        ,formatter:function (props) {
     
        return  (props.row.rentMAccountId===1 || props.row.rentMAccountId===2 || props.row.rentMAccountId===3 || props.row.rentMAccountId===4 || props.row.rentMAccountId===-1)?<div style={{background: '#CCC',textAlign:'Left',paddingLeft:"5px"}}>{props.value}</div>:<div style={{textAlign:'Left',paddingLeft:"5px"}}>{props.value}　</div>
      }}

      
      columnsTuki1.push(cloumnkoumoku)
      columnsTuki5.push(cloumnkoumoku)
      columnsTukiCorp1.push(cloumnkoumoku)
      columnsTukiCorp5.push(cloumnkoumoku)
      const columntypeDisp= { key: 'rentMEtcId', name: '請求', width: 80 ,
     
        editable: function (rowData) {
        
          return rowData.rentMAccountId === 1 || rowData.rentMAccountId === -1 ? false : true;
          // return (rowData.id===0) ? false : (rowData.rentMAccountId===1 || rowData.rentMAccountId===-1?false:true);
      },
    
      formatter:function (props) {

//  console.log(props)
      return  ((props.row.rentMAccountId===-1 || props.row.rentMAccountId===1)?<div style={{background: '#CCC'}}>　</div>:<div style={{background: 'pink'}}>　{(props.row.rentMEtcId===0 || props.row.rentMEtcId===-1) && props.row.rentMAccountId===3?"定額":this.props.payTypes.filter(a=>a.id===props.row.rentMEtcId)[0].value}</div>);
}.bind(this),
      editor:<DropDownEditor2 options={this.props}/>,
      //   // let Types = this.props.etcTypes
      //   // if(rowData.rentMAccountId===2){
      //   //   Types = this.props.kyouekiTypes
      //   // }else if(rowData.rentMAccountId===4){
      //   //   Types = this.props.parkingTypes
      //   // }else if(rowData.rentMAccountId===3){
      //   //   Types = this.props.suidouTypes
      //   // }
      //   return  
     
    }
        //  const columntypeDisp= { key: 'rentMEtcId', name: '請求', width: 80 ,
   
  //   editable:  function (rowData) {
  //       return (rowData.id===0) ? false : (rowData.rentMAccountId===1 ?false:true);
  //   },

  //   formatter:function (props) {
  //       return  props.row.id===0?<div style={{background: '#CCC'}}>　</div>:(props.row.rentMAccountId===1 ?<div style={{background: '#CCC'}}>　</div>:<div style={{background: 'pink'}}>{props.row.type===2?"込み":(props.row.type===3?"無し":"")}　</div>);
  //   },
  //   editor: <DropDownEditor options={payTypes}/>}
    columnsTuki1.push(columntypeDisp)
    columnsTuki5.push(columntypeDisp)
    columnsTukiCorp1.push(columntypeDisp)
    columnsTukiCorp5.push(columntypeDisp)

    const columnamount = { key: 'amount', name: '金額', width: 100 ,
formatter: function (props) {
// console.log(props.row)
return <div style={{background: (props.row.rentMAccountId===-1?'#CCC':props.row.checkAmount===2?'#00ff7f':props.row.checkAmount===1?'#ff69b4':((props.row.rentMEtcId===0 || props.row.rentMEtcId===-1) ?'':"#CCC")),textAlign: 'right',paddingRight:'2px'}} ><TooltipItem key={props.row.id+"-4"} item={{placement:"right",text:props.row.name,toggle:true,open:true}} id={props.row.id+"-4"}　value={props.row}/></div>
// return (!props.row.rentMEtcId>0) ? (props.value===0?"":
// <div style={{background:props.row.checkAmount?'#ffc0cb':'#CCC',textAlign: 'right',padding:'2px'}} >{props.value.toLocaleString()}<TooltipItem key={props.row.id+"-4"} item={{placement:"right",text:props.row.name,toggle:true,open:true}} id={props.row.id+"-4"} /></div>) :
// <div style={{background: props.row.checkAmount?'#ffc0cb':'#CCC'}} ><TooltipItem key={props.row.id+"-4"} item={{placement:"right",text:props.row.name,toggle:true,open:true}} id={props.row.id+"-4"}　value={props.row}/></div>

// return (!props.row.rentMEtcId>0) ? (props.value===0?"":<div style={{textAlign: 'right',padding:'2px'}}>{props.value.toLocaleString()}</div>) :<div style={{background: '#CCC'}}>{props.value===0?"":props.value}　</div>;
},

editable:  function (rowData) {
return  (rowData.rentMEtcId>0 || rowData.rentMAccountId===-1) ?  false: true;

}

}
columnsTuki1.push(columnamount)
    columnsTuki5.push(columnamount)
    columnsTukiCorp1.push(columnamount)
    columnsTukiCorp5.push(columnamount)

    const columntaxAmount = { key: 'taxAmount', name: '税', width: 50 , 
formatter: function (props) {
// console.log(props.row)
return (props.row.rentMEtcId>0 || props.row.rentMAccountId===-1) ?<div style={{background: '#CCC'}}>{props.row.taxAmount>0?props.row.taxAmount.toLocaleString():"　"}</div>:""
},
// editable:  function (rowData) {
//   return  (rowData.rentMAccountId===-1) ?  false: true;
  
//   }

}
columnsTuki1.push(columntaxAmount)
    columnsTuki5.push(columntaxAmount)
    columnsTukiCorp1.push(columntaxAmount)
    columnsTukiCorp5.push(columntaxAmount)

 const columnisDailyRate= { key: 'isDailyRate', name: '日割', width: 60 , 
  formatter: function (props) {
  // console.log(props.row)
 return (props.row.rentMEtcId>0 || props.row.rentMAccountId===-1) ?<div style={{background: '#CCC'}}>　</div>:"" 
},
editable:  function (rowData) {
  return  (rowData.rentMAccountId===-1) ?  false: true;
  
  }
  }

  columnsTuki5.push(columnisDailyRate)
  columnsTukiCorp5.push(columnisDailyRate)

    const columntype= { key: 'billing', name: '入居者', width: 60 , 
    formatter: function (props) {
    // console.log(props.row)
    return (props.row.rentMEtcId>0 || props.row.rentMAccountId===-1) ?<div style={{background: '#CCC'}}>　</div>:"" 
    },editable:  function (rowData) {
      return  (rowData.rentMAccountId===-1) ?  false: true;
      
      }
    }


    columnsTukiCorp1.push(columntype)
    columnsTukiCorp5.push(columntype)


      const columnisFreeRent= { key: 'isFreeRent', name: 'フリーレント', width: 70 , 
formatter: function (props) {
// console.log(props.row)
return (props.row.rentMEtcId>0 || props.row.rentMAccountId===-1) ?<div style={{background: '#CCC'}}>　</div>:"" 
},editable:  function (rowData) {
  return  (rowData.rentMAccountId===-1) ?  false: true;
  
  }
}

columnsTuki1.push(columnisFreeRent)
columnsTuki5.push(columnisFreeRent)
columnsTukiCorp1.push(columnisFreeRent)
columnsTukiCorp5.push(columnisFreeRent)

const columnremarks= { key: 'remarks', name: '備考', width: 100 ,
editable:  function (rowData) {
  return  (rowData.rentMAccountId===-1) ?  false: true;
  
  },formatter: function (props) {
  return (props.row.rentMAccountId === -1) ? <div style={{ background: '#CCC' }}>　</div> : props.row.remarks  
    }

}

columnsTuki1.push(columnremarks)
columnsTuki5.push(columnremarks)
columnsTukiCorp1.push(columnremarks)
columnsTukiCorp5.push(columnremarks)

const columndelete= { key: 'delete', name: '削除', width: 40,
formatter: function (props) {
  // console.log(props.row)
  return (props.row.rentMAccountId<=4 && props.row.rentMAccountId>=1) || props.row.rentMAccountId===-1 ?<div style={{background: '#CCC'}} >　</div>:"";
  } ,
  editable:  function (rowData) {
    return  (rowData.rentMAccountId===-1) ?  false: true;
    
    }}
columnsTuki1.push(columndelete)
columnsTuki5.push(columndelete)
columnsTukiCorp1.push(columndelete)
columnsTukiCorp5.push(columndelete)
// const columncheck= { key: 'check', name: 'チェック', width: 40,
// formatter: function (props) {
//   console.log(props.row)
//   return <div style={{background: props.row.check?'#ff0000':'#CCC'}} >　{props.row.check?<TooltipItem key={props.row.id+"-4"} item={{placement:"right",text:props.row.name,toggle:true,open:true}} id={props.row.id+"-4"} />:""}</div>;
//   } }

//   columnsTuki1.push(columncheck)
//   columnsTuki5.push(columncheck)

        this.state = {
            customerinfo: this.props.forecasts,
            startDate: new Date(),
            refresh: true,
       
            columnsTuki1 : columnsTuki1,
            columnsTuki5 : columnsTuki5,
 
            columnsTukiCorp1 : columnsTukiCorp1,
            columnsTukiCorp5 : columnsTukiCorp5,
            
            // columnsKeiyaku : columnsTuki1
            // amount:this.props.estimateMonies1[0].amount
            alertopen: false,
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        // this.onRowsTukiUpdated = this.onRowsTukiUpdated.bind(this);
     
        // this.getLeaveRowCount5 = this.getLeaveRowCount5.bind(this);
        // this.getLeaveRowCount1 = this.getLeaveRowCount1.bind(this);
        this.add1 = this.add1.bind(this);
        this.add5 = this.add5.bind(this);
       this.refresh = this.refresh.bind(this); 
        // this.onCellSelected1 = this.onCellSelected1.bind(this);
        // this.onCellSelected5 = this.onCellSelected5.bind(this);
        this.getCellActions1 = this.getCellActions1.bind(this);
        this.getCellActions5 = this.getCellActions5.bind(this);

        this.onRowsUpdated1 = this.onRowsUpdated1.bind(this);
        this.onRowsUpdated5 = this.onRowsUpdated5.bind(this);

        this.getRowCount1 = this.getRowCount1.bind(this);
        this.getRowCount5 = this.getRowCount5.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

  
    handleChange = (event) => {
      // alert(event.target.name)
        // console.log(event)

        let estimateData= this.props.estimateData
 
          estimateData[event.target.name] = event.target.value
      // }
        this.props.change_estimateData(estimateData)
        this.setState({change:true})
        this.props.check_estimates()
    }


    add1 = (value) => {
      
        this.props.add_estimateMonies1(value)
        this.refresh()
        this.props.check_estimates()
      }
      add5 = (value) => {
        
        this.props.add_estimateMonies5(value)
        this.refresh()
        this.props.check_estimates()
      }
    handleFormSubmit(event) {
        event.preventDefault();
        // console.log(event)
        console.log(this.props.forecasts);
    }

    onChange(e) {
        // console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }
//     shouldComponentUpdate(nextProps, nextState) {
// // alert(nextProps.estimateMonies1[0].amount)
// // alert(this.props.estimateMonies1[0].amount)
// // alert(this.state.estimateMonies1[0].amount)
// // alert(nextState.estimateMonies1[0].amount)

// //  if (this.props.estimateMonies1[0].amount !== nextProps.estimateMonies1[0].amount) {
// //         this.refresh() 
// //       }
//     }
    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
// if(this.state.selected){
        window.addEventListener('scroll', event => this.watchCurrentPosition(), true)
    // }
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    }
    watchCurrentPosition(){
        // console.log(document.activeElement.className)

        if(document.activeElement.className!=="" && document.activeElement.className.indexOf("editor-main")){
        // document.activeElement.blur()
    }
    }

    componentDidUpdate(prevProps) {
      // alert(prevProps.estimateMonies1[0].amount)
      // alert(this.state.amount )



      // console.log(prevProps)
      // alert(prevProps.estimateMonies1[0].amount)
      // alert(this.props.estimateMonies1[0].amount )
      if (this.props.estimate_month_flg===true) {
   
        this.props.change_estimate_month_flg(false)

     this.refresh() 
      }
     
      //   // This method is called when the route parameters change
      //   this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }


    onRowsUpdated1 = ({fromRow, toRow, updated }) => {
        // console.log(updated)
        let estimateMonies1 = this.props.estimateMonies1;
        // console.log(updated.amount)
    
        for (let i = fromRow; i <= toRow; i++) {
        


          if ('name' in updated) {
          
            const chinryouKoumokuNo =   this.props.tm_chinryou_koumokus.filter(a=>a.chinryouKoumokuName===updated.name)[0].chinryouKoumokuNo
    
            if(estimateMonies1.filter(a=>a.rentMAccountId===chinryouKoumokuNo).length>0){
    
    this.setState({alertopen:true})
              }else{
                estimateMonies1[i].rentMAccountId= chinryouKoumokuNo
                estimateMonies1[i].name= updated.name
              }
           
          }
    
    
    
          if ('amount' in updated) {
            estimateMonies1[i].amount=parseInt(updated.amount.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
              return String.fromCharCode(s.charCodeAt(0) - 65248);
          }))
          
     
          if((estimateMonies1[i].rentMAccountId===9 || estimateMonies1[i].rentMAccountId===10) && estimateMonies1[i].amount%this.props.estimateMonies1.filter(a=>a.rentMAccountId===1)[0].amount=== 0){
            estimateMonies1[i].amountMonth= estimateMonies1[i].amount/this.props.estimateMonies1.filter(a=>a.rentMAccountId===1)[0].amount
          }else{
            estimateMonies1[i].amountMonth= 0
          } 
         
    
        }
        // alert(estimateMonies1[i].amount)
    
            if(updated.amountMonth && isNaN(updated.amountMonth)===true){
       
              estimateMonies1[i].amountMonth= this.props.monthTypes.filter(a=>a.value===updated.amountMonth)[0].id
              estimateMonies1[i].amount= this.props.estimateMonies1.filter(a=>a.rentMAccountId===1)[0].amount*estimateMonies1[i].amountMonth
              estimateMonies1[i].taxAmount= estimateMonies1[i].tax===1?Math.round(estimateMonies1[i].amount*0.1):0
            }else if(isNaN(updated.amountMonth)===false){
              estimateMonies1[i].amountMonth= 0
              estimateMonies1[i].amount= 0
              estimateMonies1[i].taxAmount= 0
            }
    
    
    
    
            
        if ('remarks' in updated) {
          estimateMonies1[i].remarks=updated.remarks
      }








            // console.log(updated)
            if(parseInt(updated.rentMEtcId)===2){
              estimateMonies1[i].rentMEtcId=2
              estimateMonies1[i].amount=0
              estimateMonies1[i].amountMonth=0
              estimateMonies1[i].taxAmount=0
              
              estimateMonies1[i].tax=0
   
            }else if(parseInt(updated.rentMEtcId)===3){
              estimateMonies1[i].rentMEtcId=3
              estimateMonies1[i].amount=0
              estimateMonies1[i].amountMonth=0
              estimateMonies1[i].taxAmount=0
              estimateMonies1[i].tax=0
            
            }else if(parseInt(updated.rentMEtcId)===1){
              estimateMonies1[i].rentMEtcId=1
              estimateMonies1[i].amount=0
              estimateMonies1[i].amountMonth=0
              estimateMonies1[i].taxAmount=0
              estimateMonies1[i].tax=0
        
            }else if(parseInt(updated.rentMEtcId)===0 || parseInt(updated.rentMEtcId)===-1){
              
              estimateMonies1[i].rentMEtcId=-1
              estimateMonies1[i].amount=0
              estimateMonies1[i].amountMonth=0
              estimateMonies1[i].taxAmount=0
              estimateMonies1[i].tax=0
              
            }else{
              // estimateMonies1[i].amount=updated.amount
          }

            // rows[i] = { ...this.props.estimateData.estimateMoniesViewModel.filter(a=>a.accountGroup===1)[i], ...updated };
        }
        // let rowput = estimateMonies1.filter(a=>a.accountGroup!==1)
      //  console.log(estimateMonies1)
      //  alert()
        this.props.change_estimateMonies1(estimateMonies1)
        this.props.check_estimates()
        this.refresh()





          // rowput = rowput.concat(rows)
          // let estimatedata = this.props.estimateData.estimateMoniesViewModel
          // estimatedata.estimateMoniesViewModel=rowput

          //   this.props.change_estimateData(estimatedata)
            // this.refresh()
        }


        onRowsUpdated5 = ({fromRow, toRow, updated }) => {
        
          // console.log(updated)
          let estimateMonies5 = this.props.estimateMonies5;

          for (let i = fromRow; i <= toRow; i++) {
             




            if ('name' in updated) {
          
              const chinryouKoumokuNo =   this.props.tm_chinryou_koumokus.filter(a=>a.chinryouKoumokuName===updated.name)[0].chinryouKoumokuNo
      
              if(estimateMonies5.filter(a=>a.rentMAccountId===chinryouKoumokuNo).length>0){
      
      this.setState({alertopen:true})
                }else{
                  estimateMonies5[i].rentMAccountId= chinryouKoumokuNo
                  estimateMonies5[i].name= updated.name
                }
             
            }
      
      
      


       


            if ('amount' in updated) {
              estimateMonies5[i].amount=parseInt(updated.amount.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
                return String.fromCharCode(s.charCodeAt(0) - 65248);
            }))
      
            if((estimateMonies5[i].rentMAccountId===9 || estimateMonies5[i].rentMAccountId===10) && estimateMonies5[i].amount%this.props.estimateMonies1.filter(a=>a.rentMAccountId===1)[0].amount=== 0){
              estimateMonies5[i].amountMonth= estimateMonies5[i].amount/this.props.estimateMonies1.filter(a=>a.rentMAccountId===1)[0].amount
            }else{
              estimateMonies5[i].amountMonth= 0
            } 
           
      
          }
                
      
              if(updated.amountMonth && isNaN(updated.amountMonth)===true){
         
                estimateMonies5[i].amountMonth= this.props.monthTypes.filter(a=>a.value===updated.amountMonth)[0].id
                estimateMonies5[i].amount= this.props.estimateMonies1.filter(a=>a.rentMAccountId===1)[0].amount*estimateMonies5[i].amountMonth
                estimateMonies5[i].taxAmount= estimateMonies5.tax===1?Math.round(estimateMonies5[i].amount*0.1):0
              }else if(isNaN(updated.amountMonth)===false){
                estimateMonies5[i].amountMonth= 0
                estimateMonies5[i].amount= 0
                estimateMonies5[i].taxAmount= 0
              }
      
      
      
      
              
          if ('remarks' in updated) {
            estimateMonies5[i].remarks=updated.remarks
        }
  













              // console.log(updated)
              if(parseInt(updated.rentMEtcId)===2){
                estimateMonies5[i].rentMEtcId=2
                estimateMonies5[i].amount=0
                estimateMonies5[i].isDailyRate=0
              estimateMonies5[i].taxAmount=0
              estimateMonies5[i].tax=0
              
              }else if(parseInt(updated.rentMEtcId)===3){
                estimateMonies5[i].rentMEtcId=3
                estimateMonies5[i].amount=0
                estimateMonies5[i].isDailyRate=0
              estimateMonies5[i].taxAmount=0
              estimateMonies5[i].tax=0

            }else if(parseInt(updated.rentMEtcId)===1){
              estimateMonies5[i].rentMEtcId=1
              estimateMonies5[i].amount=0
              estimateMonies5[i].isDailyRate=0
              estimateMonies5[i].taxAmount=0
              estimateMonies5[i].tax=0


              }else if(parseInt(updated.rentMEtcId)===0 || parseInt(updated.rentMEtcId)===-1){
                estimateMonies5[i].rentMEtcId=-1
                estimateMonies5[i].amount=0
                estimateMonies5[i].isDailyRate=0
              estimateMonies5[i].taxAmount=0
              estimateMonies5[i].tax=0
              }
         
            
           }
      
           this.props.change_estimateMonies5(estimateMonies5)
     this.refresh()
              
          }
        

   getCellActions1(column, row) {
    
    if(column.key==="taxAmount" && (row.rentMEtcId===0 || row.rentMEtcId===-1) && row.rentMAccountId!==-1){
        
       
        return [{
            icon: <div className="float-right" style={{textAlign:"right",paddingRight:"2px",width:"50px",color:"#000000"}}>{this.props.estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount===0?"":this.props.estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount.toLocaleString()}</div>,
            callback: () => {
             
              let estimateMonies1 = this.props.estimateMonies1
           
              if(estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount>0){
              estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount= 0
              estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].tax= false
            }else{
              estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount=  Math.round(row.amount*0.1)
              estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].tax= true
            }

              this.props.change_estimateMonies1(estimateMonies1)
              this.props.check_estimates()
              this.setState({refresh:!this.state.refresh})
            }
        }]
      }else if(column.key==="isFreeRent" &&  (row.rentMEtcId===0 || row.rentMEtcId===-1) && row.rentMAccountId!==-1){
      

        return [{
       icon: <div style={{width:"70px"}}>{row.isFreeRent===1?<i class="fas fa-check"></i>:""}</div>,
            
            callback: () => {
              let estimateMonies1 = this.props.estimateMonies1
  
              estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].isFreeRent= estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].isFreeRent=== 0?1:0
              this.props.change_estimateMonies1(estimateMonies1)
              this.props.check_estimates()
              this.setState({refresh:!this.state.refresh})
            }
          }
        ]
      }else if(column.key==="billing" &&  (row.rentMEtcId===0 || row.rentMEtcId===-1) && row.rentMAccountId!==-1){
      

        return [{
       icon: <div style={{width:"60px"}}>{row.billing===1?<i class="fas fa-check"></i>:""}</div>,
            
            callback: () => {
              let estimateMonies1 = this.props.estimateMonies1
  
              estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].billing= estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].billing=== 0?1:0
              this.props.change_estimateMonies1(estimateMonies1)
              this.props.check_estimates()
              this.setState({refresh:!this.state.refresh})
            }
          }
        ]
 
      }else if(column.key==="delete" && (row.rentMAccountId>4 || row.rentMAccountId===0) && row.rentMAccountId!==-1){
        return [{
            icon:<i class="fas fa-trash-alt" style={{marginRight:"15px"}}></i>,
            
            callback: () => {
              if(row.rentMAccountId===0 || row.rentMAccountId>4){
              let estimateMonies1 = this.props.estimateMonies1

              estimateMonies1=  estimateMonies1.filter(a=>a.rentMAccountId!==row.rentMAccountId)
              this.props.change_estimateMonies1(estimateMonies1)
              this.props.check_estimates()
              this.setState({refresh:!this.state.refresh})
            }
            }
          }
        ]
    }



    }


    getCellActions5(column, row) {
    
      if(column.key==="taxAmount" &&  (row.rentMEtcId===0 || row.rentMEtcId===-1) && row.rentMAccountId!==-1){
        return [{
        icon: <div className="float-right" style={{textAlign:"right",paddingRight:"2px",width:"50px",color:"#000000"}}>{this.props.estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount===0?"":this.props.estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount.toLocaleString()}</div>,
        callback: () => {
         
          let estimateMonies5 = this.props.estimateMonies5
       
          if(estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount>0){
          estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount= 0
          estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].tax= false
        }else{
          estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount=  Math.round(row.amount*0.1)
          estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].tax= true
        }

          this.props.change_estimateMonies5(estimateMonies5)
          this.props.check_estimates()
          this.setState({refresh:!this.state.refresh})
        }
      }]
  
    }else if(column.key==="isDailyRate" &&  (row.rentMEtcId===0 || row.rentMEtcId===-1) && row.rentMAccountId!==-1){
      
      const dt = new Date(this.props.estimateData.contractDate)
     const monthlastday= new Date(dt.getFullYear(), dt.getMonth() + 1, 0).getDate()
const thisday = dt.getDate()
      return [{
      icon: <div style={{width:"50px",color:"#000000"}}>{row.isDailyRate===1?((monthlastday-thisday+1)+"/"+monthlastday):""}　</div>,
          
          callback: () => {
            let estimateMonies5 = this.props.estimateMonies5

            estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].isDailyRate= estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].isDailyRate=== 0?1:0
            estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].amount= estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].isDailyRate=== 1?Math.round(this.props.estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].amount/monthlastday*(monthlastday-thisday+1)):this.props.estimateMonies1.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].amount
            this.props.change_estimateMonies5(estimateMonies5)
            this.props.check_estimates()
            this.setState({refresh:!this.state.refresh})
          }
        }
      ]

     }else if(column.key==="isFreeRent" &&  (row.rentMEtcId===0 || row.rentMEtcId===-1) && row.rentMAccountId!==-1){
      

      return [{
     icon: <div style={{width:"70px"}}>{row.isFreeRent===1?<i class="fas fa-check"></i>:""}</div>,
          
          callback: () => {
            let estimateMonies5 = this.props.estimateMonies5

            estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].isFreeRent= estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].isFreeRent=== 0?1:0
            this.props.change_estimateMonies5(estimateMonies5)
            this.props.check_estimates()
            this.setState({refresh:!this.state.refresh})
          }
        }
      ]

    }else if(column.key==="billing" &&  (row.rentMEtcId===0 || row.rentMEtcId===-1) && row.rentMAccountId!==-1){
      

      return [{
     icon: <div style={{width:"60px"}}>{row.billing===1?<i class="fas fa-check"></i>:""}</div>,
          
          callback: () => {
            let estimateMonies5 = this.props.estimateMonies5

            estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].billing= estimateMonies5.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].billing=== 0?1:0
            this.props.change_estimateMonies5(estimateMonies5)
            this.props.check_estimates()
            this.setState({refresh:!this.state.refresh})
          }
        }
      ]

        }else if(column.key==="delete" && (row.rentMAccountId>4 || row.rentMAccountId===0) && row.rentMAccountId!==-1){
          return [{
              icon:<i class="fas fa-trash-alt" style={{marginRight:"15px"}}></i>,
              
              callback: () => {
                if(row.rentMAccountId===0 || row.rentMAccountId>4){
                let estimateMonies5 = this.props.estimateMonies5
  
                estimateMonies5=  estimateMonies5.filter(a=>a.rentMAccountId!==row.rentMAccountId)
                this.props.change_estimateMonies5(estimateMonies5)
                this.props.check_estimates()
                this.setState({refresh:!this.state.refresh})
              }
              }
            }
          ]
      }
  
  
  
      }

      getMonthRowCount() {
        let count = this.props.money_onetime.length;
        if(this.state.refresh && count > 0) {
          count--; // hack for update data-grid
          this.setState({
            refresh: false
          });
        }
    
        return count;
      }

      getRowCount1() {
//         alert("1")
        let count = this.props.estimateMonies1.length;
// alert(this.state.estimateMonies1[0].amount)
// alert(this.props.estimateMonies1[0].amount)
// if(this.state.estimateMonies1[0].amount!=this.props.estimateMonies1[0].amount){
//         this.setState({
//           estimateMonies1: this.props.estimateMonies1.map()
//         });
//       }
        if(this.state.refresh && count >= 0) {
      
          count--; // hack for update data-grid
          this.setState({
            refresh: false
          });
        }
    
        return count;
      }
      getRowCount5() {
        let count = this.props.estimateMonies5.length;
        if(this.state.refresh && count > 0) {
          count--; // hack for update data-grid
          this.setState({
            refresh: false
          });
        }
    
        return count;
      }
    
    refresh() {
        this.setState({
          refresh: true
        });
      }



      handleClose = () => {
        this.setState({alertopen:false});
      };



selected(){
    alert()
}
    
    render() {
       
        // const { handleSubmit } = this.props;
        // console.log(this.props.estimateData.estimateMoniesViewModel)
        // alert(this.props.estimateData.estimateMoniesViewModel.length)
      
        return (
          <div className="item" id="parentDivOfGrid">
            <Button size="small" variant="outlined" style={{ margin: 5, float: "right", display: (this.props.notedit === 1) ? 'none' : '' }} onClick={() => this.props.change_estimate_edit_disp(false)}>閉じる</Button>
          {/* {this.props.estimateMonies1[0].amount} */}
     
                    {/* <button className="btn btn-sm btn-light float-right" style={{ display: (this.props.notclose === 1) ? 'none' : '' }} onClick={this.props.moneyeditchange}>閉じる</button> */}
                   {new Date(this.props.estimateData.contractDate).getDate()!==1? <div style={{ marginBottom: 5 }}>
                        <span className="float-left">初月賃料</span>
                        <Button
                    color="primary"
                    variant="contained"
                    size="small" 
    
                    type="button"
                    style={{margin:2,float:"right"}}
                    onClick={()=>this.add5(0)}
                >追加
                </Button>
               
                <ReactDataGrid
                       
                            columns={ String(this.props.estimateData.type)==="0"?this.state.columnsTuki5:this.state.columnsTukiCorp5}
                            rowGetter={i => this.props.estimateMonies5[i]}
                            rowsCount={ this.getRowCount5()}
                            minHeight={this.props.estimateMonies5.length*36+36}
                            style={{ width: 200 }}
                            onGridRowsUpdated={this.onRowsUpdated5}
                            enableCellSelect={true}
                            getCellActions={this.getCellActions5}
                            // onCellSelected={this.onCellSelected5}
                        />
                    </div>:""}

                    <div style={{ marginBottom: 10 }}>
                        <span className="float-left">{new Date(this.props.estimateData.contractDate).getDate()!==1? "次月賃料":"初月賃料"}</span>

                        <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="submit"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add1(7)}
                >浄水器
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="button"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add1(46)}
                >浄水シャワ－
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    size="small" 
    
                    type="button"
                    style={{margin:2,float:"right"}}
                    onClick={()=>this.add1(0)}
                >追加
                </Button>


                        <ReactDataGrid
                       
                            columns={String(this.props.estimateData.type)==="1"?this.state.columnsTukiCorp1:this.state.columnsTuki1}
                            rowGetter={i => this.props.estimateMonies1[i]}
                            rowsCount={this.getRowCount1()}
                            minHeight={this.props.estimateMonies1.length*36+36}
                            style={{ width: 200 }}
                            onGridRowsUpdated={this.onRowsUpdated1}
                            enableCellSelect={true}
                            getCellActions={this.getCellActions1}
                            // onCellSelected={this.onCellSelected1}
                        />
                        ※この金額が毎月賃料となります。フリーレントの場合も全額を打ってチェックしてください。
                    </div>

                    <Grid item xs={12}>
<FormControl className={Forms.formControl}   style={{float:"left"}}>
        <InputLabel id="demo-simple-select-helper-label">プラス請求</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={this.props.estimateData.payNextmonth}
     
          onChange={this.handleChange}
          name="payNextmonth"
        >
          <MenuItem value={0}>
            <em>-</em>
          </MenuItem>
          <MenuItem value={1}>1ヵ月</MenuItem>
          <MenuItem value={2}>2ヵ月</MenuItem>
          <MenuItem value={3}>3ヵ月</MenuItem>
          <MenuItem value={4}>4ヵ月</MenuItem>
          <MenuItem value={5}>5ヵ月</MenuItem>
          <MenuItem value={6}>6ヵ月</MenuItem>
        </Select>
        

        

      </FormControl>
</Grid>


<Modal isOpen={this.state.alertopen} toggle={this.handleClose}  zIndex={3000} size="sm"  fade={false}>
                   
{/*              
                <ModalHeader toggle={()=>this.props.change_response_add_disp(0)}>新規登録</ModalHeader> */}
                <ModalBody  >
      項目が重複しています
            
             
 </ModalBody>
 <ModalFooter>
               {/* <button className="btn btn btn-danger" style={{margin:"5px"}} onClick={this.delete_auto_send}>除外する</button> */}
               <button className="btn btn btn-secondary" style={{margin:"5px"}} onClick={this.handleClose}>閉じる</button>
               </ModalFooter>
 </Modal>

            </div>

        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        estimateData: state.estimates.estimateData,
        estimateMonies1: state.estimates.estimateMonies1,
        estimateMonies5: state.estimates.estimateMonies5,
        estimate_month_flg: state.estimates.estimate_month_flg,
        payTypes: state.estimates.payTypes,
        monthKoumoku: state.estimates.monthKoumoku,
        tm_chinryou_koumokus: state.masterReducer.tm_chinryou_koumokus,
        etcTypes: state.estimates.etcTypes,
        kyouekiTypes: state.estimates.kyouekiTypes,
        suidouTypes: state.estimates.suidouTypes,
        parkingTypes: state.estimates.parkingTypes,
        hokenTypes: state.estimates.hokenTypes,
        // money_month: state.tenantsReducer.money_month,
        // money_onetime: state.tenantsReducer.money_onetime,
        // money_update: state.tenantsReducer.money_update,
        // money_leave: state.tenantsReducer.money_leave,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_estimateData(state) {
           dispatch(Estimates.change_estimateData(state))
        },
        add_estimateMonies1(state) {
            dispatch(Estimates.add_estimateMonies1(state))
         },
         add_estimateMonies5(state) {
            dispatch(Estimates.add_estimateMonies5(state))
         },
         change_estimateMonies1(state) {
          dispatch(Estimates.change_estimateMonies1(state))
       },
         change_estimateMonies5(state) {
          dispatch(Estimates.change_estimateMonies5(state))
       },
       change_estimate_month_flg(state) {
            dispatch(Estimates.change_estimate_month_flg(state))
         },
         check_estimates(state) {
          dispatch(Estimates.check_estimates(state))
      },
         
      change_estimate_edit_disp(state) {
        dispatch(Estimates.change_estimate_edit_disp(state))
      },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

// MoneyEditComponent = reduxForm({
//     form: 'roomform', // a unique identifier for this form
//     // validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(MoneyEditComponent)

export default connect(mapStateToProps, mapDispatchToProps)(MoneyEditComponent);