import React, { Component } from 'react';

import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/WeatherForecasts';


class RoomInfoDisp extends Component {
  componentDidMount() {
    // This method is called when the component is first added to the document
    this.ensureDataFetched();
  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.ensureDataFetched();
  }

  ensureDataFetched() {
      this.props.requestWeatherForecasts(0);
  }

    render() {
       
    return (
   
        <table className='table table-striped'>

            <tbody>

                <tr >
                    <th>お客様名</th>
                    <td colSpan="3">{this.props.forecasts.name1}{this.props.forecasts.kana1}</td>

                </tr>
                <tr >
                    <th>性別</th>
                    <td>{this.props.forecasts.sex}</td>
                    <th>生年月日</th>
                    <td>{this.props.forecasts.birthDate}</td>
                </tr>
                <tr >
                    <th>電話番号1</th>
                    <td>{this.props.forecasts.tel1}</td>
                    <th>電話番号2</th>
                    <td>{this.props.forecasts.tel2}</td>
                </tr>
                <tr >
                    <th>メール1</th>
                    <td colSpan="3">{this.props.forecasts.mail1}</td>

                </tr>
                <tr >
                    <th>メール2</th>
                    <td colSpan="3">{this.props.forecasts.mail2}</td>
                </tr>
                <tr >
                    <th>Fax</th>
                    <td colSpan="3">{this.props.forecasts.Fax}</td>
                </tr>
            </tbody>
        </table>
 
    );
  }
}



//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoDisp);


const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(RoomInfoDisp);