import React, { Component } from 'react';


import "react-datepicker/dist/react-datepicker.css";

import { Field, reduxForm ,InjectedFormProps} from 'redux-form';
import '../../Css/Table.css';
import { Button } from 'reactstrap';



interface Props {

  handleSubmit:any,
    onSubmit:any,
    get_rent_contract_accounting:any,
    send_mail_details:any,
    change_send_mail_cc_disp:any,
    change_send_mail_bcc_disp:any,
    send_mail_cc_disp:number,
    send_mail_bcc_disp:number,
    send_edit_contents:number
    handleChange:any,
    change_send_edit_contents:any,
 
  
}

interface Forms {
  message: string;

}

interface State {
 
  }


// class MailEditHeaderComponent extends Component<InjectedFormProps<Props, {}, string>>  {
  class MailTemplateAddCategryComponent extends Component<InjectedFormProps<Forms> & Props> {
  constructor(props: any) {
    super(props);
    // this.props.initialize(this.props.send_mail_details);//フォームに初期値を反映させる
  
  }
 
 

    render() {

        const {handleChange,handleSubmit} = this.props;
       
  
      return (
        <div>
          <form onSubmit={handleSubmit} > 
          <Field
                                      name="sectionId"
                                      component="input"
                                      type="hidden"
                                      value={26}
                                  />
          <div className="form-inline">
      <Field
                                  name="categoryName"
                                  component="input"
                                  type="text"
                                  placeholder="カテゴリー追加"
                                  className="form-control"
                                  style={{border:"none",width:"300px"}}
                              />
<Button  color="primary" size="sm" >追加</Button>
</div>
      </form>
      
      </div>
    );
  }
} 


export default reduxForm<Forms, Props>({
  form: 'addmailtemplatecategoryForm',
})(MailTemplateAddCategryComponent);