﻿import React from 'react'
// import { Document, Page } from 'react-pdf';
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from 'react-redux';
import './Css/Contracts.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import '@styles/react-pdf.styl'

class PdfDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.state = { page: 1, base64: null, name: null, typeset: "" }
    // this.handleDocumentLoad = this.handleDocumentLoad.bind(this);
    const reader = new FileReader()
    reader.readAsDataURL(this.props.contracts_rent_application_doc_uri)
    var signatures = {
      JVBERi0: "application/pdf",
      R0lGODdh: "image/gif",
      R0lGODlh: "image/gif",
      iVBORw0KGgo: "image/png",
      "/9j/": "image/jpg"
    };

    function detectMimeType(b64) {
      for (var s in signatures) {
        if (b64.indexOf(s) === 0) {
          return signatures[s];
        }
      }
    }
    let type = "jpg"
    if (reader.result) {
      // type = detectMimeType(reader.result)
      // alert(type)
      if (reader.result.match(/pdf/)) {
        type = "pdf"
      }
    }


    this.setState({
      typeset: type,
      // name: file.name,
    })
    reader.onload = () => {
      this.setState({
        base64: reader.result,
        // name: file.name,
      })
    }
  }

  handleChange(e) {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        base64: reader.result,
        name: file.name,
      })
    }
  }

  handleDocumentLoad({ numPages }) {
    this.setState({ numPages })

    this.props.numPages(numPages);
  }



  render() {

    return <div className="component">
      {/* PDFを添付してください:
      <input type="file" onChange={this.handleChange.bind(this)} /> */}
      {this.props.contracts_rent_application_doc_uri && this.state.base64 && this.props.contracts_doc_extension.indexOf("pdf") != -1 ? <Document
        // file={this.props.contracts_rent_application_doc_uri}
        file={this.state.base64}
        style={{ border: 'dotted 1px #aaa' }}
        onLoadSuccess={this.handleDocumentLoad.bind(this)}
      >
        <Page
          // rotate={90}
          scale={this.props.scale / 100}
          rotate={this.props.rotate}
          pageNumber={this.props.page}
          style={{ border: 'solid 2px #000', height: 300 }}
          
        />
      </Document> : this.props.contracts_rent_application_doc_uri ? <img src={this.state.base64} alt="pdfimg" height={500} width={500} /> : ""}
      {/* < div > {this.state.name}</div> */}



      {/* {this.state.typeset} */}
      { }
    </div >
  }
}



//コンテナ
const mapStateToProps = state => {
  return {
    // estimate_loading: state.estimates.estimate_loading,
    // customerdetail: state.customersReducer.customerdetail,
    // //firebasetoken: state.firebaseReducer.firebasetoken,
    // contract_detail_edit_disp: state.estimates.contract_detail_edit_disp,
    contracts_rent_application_doc_uri: state.contracts.contracts_rent_application_doc_uri,
    contracts_doc_extension: state.contracts.contracts_doc_extension,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    // get_estimates(state) {
    //   dispatch(Estimates.get_estimates(state))
    // },

    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfDisplay);
