// import console = require("console");

const authentication = require('../../../react-azure-adb2c2').default;






export const send = (payload: any) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/mail/mailsend/`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(payload),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const add_file = (payload: any) => {
    var formData = new FormData();
    formData.append(payload.id, payload.file[0]);


    // const urls = URL.createObjectURL(payload.file);
    // console.log(urls)
    //    formData.append(payload.id,new Blob([payload.file[0]], {type:payload.file[0].type}), payload.file[0].name);







    console.log(payload.file)
    console.log(formData)
    // alert(payload.id)
    let url = process.env.REACT_APP_API_BASE_URL + `/api/c/MailFileUpload/`;
    const token = authentication.getAccessToken();
    // console.log(JSON.stringify(payload))
    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const add_image = (payload: any) => {

    console.log(payload)

    var formData = new FormData();

    // const a = document.createElement('a');
    // ダウンロードされるファイル名
    // a.download = action.payload.name;
    const urls = URL.createObjectURL(payload.file);
    console.log(urls)
    formData.append(payload.id, new Blob([payload.file], { type: payload.file.type }), payload.file.name);

    let url = process.env.REACT_APP_API_BASE_URL + `/api/c/MailImageUploadApi`;
    const token = authentication.getAccessToken();
    // console.log(JSON.stringify(payload))
    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
