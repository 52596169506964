
const authentication = require('../../../react-azure-adb2c2').default;





export const addpdffile = (values: any) => {


    let url = process.env.REACT_APP_REPORT_API_URL + `/api/EstimateToMail/` + values.id + '/' + values.mailid;

    const token = authentication.getAccessToken();


    // alert(url)

    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));






};