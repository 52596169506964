import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';

import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import withStyles from '@mui/styles/withStyles';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';

import moment from 'moment';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))


class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            change: false,
            columnWidths: {
                date: 100,
                user_id: 90,
                section_id: 90,
                rent_m_corporate_way_id: 100,
                remarks: 500,
            },
            tab:0,
        };
        this.handleUserIdChangeSet = this.handleUserIdChangeSet.bind(this);
        this.handleSectionIdChangeSet = this.handleSectionIdChangeSet.bind(this);
        this.handleKanriCorpIdChangeSet = this.handleKanriCorpIdChangeSet.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);


    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            },
        }));
    }


    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }
    handleKanriCorpIdChangeSet(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.kanri_corp_no = value.value

        } else {
            rent_corporate_contacts.kanri_corp_no = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    handleUserIdChangeSet(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.user_id = value.value

        } else {
            rent_corporate_contacts.user_id = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    handleSectionIdChangeSet(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.section_id = value.value

        } else {
            rent_corporate_contacts.section_id = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    handleOpen(id) {
        if (id) {
            this.props.set_rent_corporate_contacts(id)
        } else {
            // 新規
            let rent_corporate_contacts = { id: '', rent_m_corporate_id: this.props.rent_m_corporates.id, user_id: this.props.user_details.id, section_id: this.props.user_details.amSectionId, date: moment(), rent_m_corporate_way_id: '', remarks: '', is_deleted: 0, created_at: '', created_user_id: '' }
            this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        }

        this.setState({ "modal": true })
    }
    onChangeDate(value) {

        let rent_corporate_contacts = this.props.rent_corporate_contacts
        rent_corporate_contacts.date = value
        this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        // this.props.change_responsedetail(responsedetail);
        this.setState({ reflash: !this.state.reflash });
    }
    onChange(state) {
        // console.log(this.props.claso_blog_places)
        let rent_corporate_contacts = this.props.rent_corporate_contacts

        rent_corporate_contacts[state.target.name] = state.target.value

        this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        this.setState({
            change: !this.state.change
        });

    }

    handleUpdate(id, delflg) {

        let rent_corporate_contacts = this.props.rent_corporate_contacts
        rent_corporate_contacts["is_deleted"] = delflg
        this.props.change_rent_corporate_contacts(rent_corporate_contacts)

        if (id) {
            this.props.update_rent_corporate_contacts()
        } else {
            this.props.insert_rent_corporate_contacts()
        }

        this.setState({
            modal: false,
            modal_del: false,
            change: !this.state.change
        });

    }

    handleTabChange = (event, newValue) => {

        let list
        if(newValue == 0){
            list = this.props.rent_corporate_contacts_list.filter(a=>a.rival != null && a.rival != "")
        }else if(newValue == 1){
            list = this.props.rent_corporate_contacts_list.filter(a=>a.need != null && a.need != "")
        }else if(newValue == 2){
            list = this.props.rent_corporate_contacts_list.filter(a=>a.trouble != null && a.trouble != "")
        }else if(newValue == 3){
            list = this.props.rent_corporate_contacts_list.filter(a=>a.request != null && a.request != "")
        }

        this.props.change_rent_corporate_contacts_tab_list(list)

        this.setState({ tab: newValue });
    };

    render() {

        return (
            <div>

                <Tabs value={this.state.tab} onChange={this.handleTabChange} aria-label="basic tabs example"

                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="ライバル情報" value={0} />
                    <Tab label="社宅ニーズ" value={1} />
                    <Tab label="お困りごと" value={2} />
                    <Tab label="弊社に望むこと" value={3} />
                </Tabs>


                    <Table
                        rowHeight={40}
                        rowsCount={this.props.rent_corporate_contacts_tab_list.length}
                        height={200}
                        headerHeight={30}
                        width={(window.innerWidth / 2) - 75}
                        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                        isColumnResizing={false}

                    >
                        <Column width={this.state.columnWidths.remarks} isResizable={true} columnKey="remarks" header={<Cell>内容</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.state.tab == 0 ? this.props.rent_corporate_contacts_tab_list[rowIndex].rival : this.state.tab == 1 ? this.props.rent_corporate_contacts_tab_list[rowIndex].need : this.state.tab == 2 ? this.props.rent_corporate_contacts_tab_list[rowIndex].trouble : this.state.tab == 3 ? this.props.rent_corporate_contacts_tab_list[rowIndex].request : ""}</Cell>)} />
                        <Column width={this.state.columnWidths.date} isResizable={true} columnKey="date" header={<Cell>日付</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.rent_corporate_contacts_tab_list[rowIndex].date).format("YYYY-MM-DD")}</Cell>)} />
                        <Column width={this.state.columnWidths.section_id} isResizable={true} columnKey="section_id" header={<Cell>部署</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.section_select && this.props.rent_corporate_contacts_tab_list[rowIndex].section_id ? this.props.section_select.filter((a) => a.value === this.props.rent_corporate_contacts_tab_list[rowIndex].section_id).length > 0 ? this.props.section_select.filter((a) => a.value === this.props.rent_corporate_contacts_tab_list[rowIndex].section_id)[0].label : "" : ""}</Cell>)} />
                        <Column width={this.state.columnWidths.user_id} isResizable={true} columnKey="user_id" header={<Cell>担当</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.users_select && this.props.rent_corporate_contacts_tab_list[rowIndex].user_id ? this.props.users_select.filter((a) => a.value === this.props.rent_corporate_contacts_tab_list[rowIndex].user_id).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_corporate_contacts_tab_list[rowIndex].user_id)[0].label : "" : ""}</Cell>)} />
                        
                    </Table>



            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates: state.corporation.rent_m_corporates,
        users_select: state.masterReducer.users_select,
        section_select: state.masterReducer.section_select,
        kanricorps: state.masterReducer.kanricorps,
        rent_corporate_contacts: state.corporation.rent_corporate_contacts,
        rent_corporate_contacts_list: state.corporation.rent_corporate_contacts_list,
        user_details: state.masterReducer.user_details,
        rent_corporate_contacts_tab_list: state.corporation.rent_corporate_contacts_tab_list,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_rent_corporate_contacts_list(state) {
            dispatch(Corporation.get_rent_corporate_contacts_list(state))
        },
        change_rent_corporate_contacts(state) {
            dispatch(Corporation.change_rent_corporate_contacts(state))
        },
        set_rent_corporate_contacts(state) {
            dispatch(Corporation.set_rent_corporate_contacts(state))
        },
        insert_rent_corporate_contacts(state) {
            dispatch(Corporation.insert_rent_corporate_contacts(state))
        },
        update_rent_corporate_contacts(state) {
            dispatch(Corporation.update_rent_corporate_contacts(state))
        },

        change_rent_corporate_contacts_tab_list(state) {
            dispatch(Corporation.change_rent_corporate_contacts_tab_list(state))
        },
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




