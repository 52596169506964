import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import CorporatesDetail from './CorporatesDetail';
import CorporatesContacts from './CorporatesContacts';
import CorporatesResponseList from './CorporatesResponseList';
import CorporatesCount from './CorporatesCount';
import CorporatesEnterprise from './CorporatesEnterprise';
import CorporatesPlace from './CorporatesPlace';
import CorporatesInformation from './CorporatesInformation';
import CorporatesRemark from './CorporatesRemark';
import CorporatesContactRemarks from './CorporatesContactRemarks';
import CorporatesFile from './CorporatesFile';
import CorporatesMap from './CorporatesMap';
import CorporatesMonthChart from './CorporatesMonthChart';
import CorporatesYearChart from './CorporatesYearChart';
import * as Responses from '../ResponseDetail/Saga/Responses';
import ResponseComponent from "../ResponseDetail/ResponseComponent";


import { Modal } from 'reactstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';



const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },

    backdrop: {
        // zIndex: theme.zIndex.drawer + 1,
        zIndex: 20000,
        color: '#fff',
    },


}))


class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false,
            type : this.props.rent_m_corporate_id ? 0 : 1
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()

        const id = this.props.rent_m_corporate_id ? this.props.rent_m_corporate_id : props.match.params.id

        this.props.set_rent_m_corporates(id)
        // this.props.get_owner_detail(id)
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <div>
            <Card style={{ backgroundColor: "#e9f2f8" }}>

                <CardContent>

                    <Grid container spacing={1} >
                        <Grid item xs={6}>
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesDetail></CorporatesDetail>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesContacts></CorporatesContacts>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    {this.props.rent_responses_response_list ? <CorporatesResponseList></CorporatesResponseList>:""}
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesCount></CorporatesCount>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesMonthChart></CorporatesMonthChart>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesYearChart></CorporatesYearChart>
                                </CardContent>
                            </Card >
                        </Grid>
                        <Grid item xs={6}>
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesEnterprise></CorporatesEnterprise>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesPlace></CorporatesPlace>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesInformation></CorporatesInformation>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesRemark></CorporatesRemark>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesContactRemarks></CorporatesContactRemarks>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesFile></CorporatesFile>
                                </CardContent>
                            </Card >
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <CorporatesMap></CorporatesMap>
                                </CardContent>
                            </Card >
                        </Grid>
                    </Grid>

                </CardContent>

            </Card>
            <Modal isOpen={this.props.modalResponseDetail} toggle={() => this.props.change_modalResponseDetail(false)}
                    size="xxl" fade={false} >
                    <ResponseComponent
                        // responseTabs="r4"
                        change_modalResponseDetail={(a) => this.props.change_modalResponseDetail(a)} rentResponseId={this.props.responseid} response_modal={true} />

                </Modal>
            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        m_corporations_list: state.corporation.m_corporations_list,
        rent_responses_response_list: state.corporation.rent_responses_response_list,
        corporate_loading: state.corporation.corporate_loading,

        modalResponseDetail: state.responsesReducer.modalResponseDetail,
        responseid: state.responsesReducer.responseid,
        rent_m_corporates_detail_open: state.corporation.rent_m_corporates_detail_open,

    }
}
function mapDispatchToProps(dispatch) {
    return {

        set_rent_m_corporates(state) {
            dispatch(Corporation.set_rent_m_corporates(state))
        },

        change_modalResponseDetail(state) {
            dispatch(Responses.change_modalResponseDetail(state))
        },
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




