
const authentication = require('../../../react-azure-adb2c2').default;
export const list = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;

    const url = process.env.REACT_APP_API_ROOMS_EX_URL + `/v1/tm/kanri/corp/`;
    // const url = `http://localhost:5000/v1/tm/tatemono/searchname/`;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

