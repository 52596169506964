import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import * as Task from './Saga/Task';
import * as Calendar from '../Calendar/Saga/Calendar';
import GridComponent from './GridComponent';
import { Row, Col } from 'reactstrap';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Today from '@mui/icons-material/Today';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import NavigateNext from '@mui/icons-material/NavigateNext';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
const styles = (theme) => ({

    backdrop: {
      // zIndex: theme.zIndex.drawer + 1,
      zIndex: 20000,
      color: '#fff',
    },
  
  })

class TaskComponent extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            refresh: false,
            task_tab: 0,
        }

        this.handle_change_user_id = this.handle_change_user_id.bind(this);
        this.handle_change_section_id = this.handle_change_section_id.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeDateButton = this.onChangeDateButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.props.get_users()
    }

    componentDidMount() {

            let rent_tasks_disp_form = this.props.rent_tasks_disp_form
            // alert(this.props.user_details.amSectionId)
            rent_tasks_disp_form.section_id = this.props.user_details.amSectionId
            // rent_tasks_disp_form.section_id = 2
            this.props.change_rent_tasks_disp_form(rent_tasks_disp_form);
            this.props.get_task_rent_responses_list()
            this.props.get_calendar_search_store_list()
 

      }

    handleTabChange = (event, newValue) => {
        // alert(newValue)
        // alert(newValue)k
        let rent_tasks_disp_form = this.props.rent_tasks_disp_form
        rent_tasks_disp_form.disp_tab = newValue
        this.props.change_rent_tasks_disp_form(rent_tasks_disp_form);

        this.props.search_task_rent_responses_list()
        this.setState({ reflesh: !this.props.refresh })
        // this.setState({ task_tab: newValue });
    };

    handle_change_section_id(e, newValue) {

        let rent_tasks_disp_form = this.props.rent_tasks_disp_form

        if (newValue) {
            rent_tasks_disp_form.section_id = newValue ? newValue.tenpo_no : 0
        }else{
            rent_tasks_disp_form.section_id = 0
        }
        this.props.change_rent_tasks_disp_form(rent_tasks_disp_form)

        // this.props.search_task_rent_responses_list()
        this.props.get_task_rent_responses_list()
        this.setState({ reflesh: !this.props.refresh })
    }

    handle_change_user_id(e, newValue) {

        let rent_tasks_disp_form = this.props.rent_tasks_disp_form

        if (newValue) {
            rent_tasks_disp_form.user_id = newValue ? newValue.id : 0
        }else{
            rent_tasks_disp_form.user_id = 0
        }
        this.props.change_rent_tasks_disp_form(rent_tasks_disp_form)

        this.props.search_task_rent_responses_list()
        this.setState({ reflesh: !this.props.refresh })
    }

    onChangeDate(value) {

        let rent_tasks_disp_form = this.props.rent_tasks_disp_form
        rent_tasks_disp_form.start = moment(value).startOf('day').format('YYYY/MM/DD HH:mm:ss')
        rent_tasks_disp_form.end = moment(value).endOf('day').format('YYYY/MM/DD HH:mm:ss')
        this.props.change_rent_tasks_disp_form(rent_tasks_disp_form);

        this.props.get_task_rent_responses_list()

        this.setState({
            refresh: !this.state.refresh    //再描画
        });
    }

    onChangeDateButton(value) {

        let rent_tasks_disp_form = this.props.rent_tasks_disp_form
    
        if (value == 0) {
            rent_tasks_disp_form.start = moment().startOf('day').format('YYYY/MM/DD HH:mm:ss')
            rent_tasks_disp_form.end = moment().endOf('day').format('YYYY/MM/DD HH:mm:ss')
        } else {
            rent_tasks_disp_form.start = moment(rent_tasks_disp_form.start).add(value, "days").startOf('day').format('YYYY/MM/DD HH:mm:ss')
          rent_tasks_disp_form.end = moment(rent_tasks_disp_form.end).add(value, "days").endOf('day').format('YYYY/MM/DD HH:mm:ss')
        }
    
    
        this.props.change_rent_tasks_disp_form(rent_tasks_disp_form);
    
        this.props.get_task_rent_responses_list()
    
        this.setState({
          refresh: !this.state.refresh    //再描画
        });
      }
    
    handleChange = (event) => {
        let rent_tasks_disp_form = this.props.rent_tasks_disp_form
    
        if(event.target.name == "is_completed" || event.target.name == "is_auto_mail"){
            rent_tasks_disp_form[event.target.name] = event.target.checked
            // rent_tasks_disp_form.start = moment().add(-6, 'days').startOf('day').format('YYYY/MM/DD HH:mm:ss')
        }else{
            rent_tasks_disp_form[event.target.name] = event.target.value
        }
    
        this.props.change_rent_tasks_disp_form(rent_tasks_disp_form);
    
        this.props.search_task_rent_responses_list()
    
        this.setState({
          refresh: !this.state.refresh    //再描画
        });
    
    
      }

    render() {


        return <>
            {/* <div style={{ padding: 0, background: "#b0c4de" }} > */}
            <div style={{ padding: 0,  }} >

            <Card style={{padding:"10px 5px", marginBottom:5}}>

                <TextField
                    id="date"
                    label="日付"
                    type="date"
                    name="date"
                    size="small"
                    onChange={this.handleChange}
                    defaultValue={moment(this.props.rent_tasks_disp_form.start).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                />
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>

                        <KeyboardDatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="yyyy/MM/dd(EE)"
                        margin="dense"
                        id="date-picker-inline1"
                        label="日付"
                        // showTodayButton={true}
                        style={{ float: "left", marginTop: "0", width:200 }}
                        autoOk={true}
                        InputLabelProps={{ shrink: true }}
                        name="date"
                        value={this.props.rent_tasks_disp_form.start}
                        onChange={this.onChangeDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        invalidDateMessage={"日付の形式が違います"}
                        />

                    </MuiPickersUtilsProvider> */}




                    <IconButton
                        style={{ padding: 5 }}
                        onClick={() => this.onChangeDateButton(-7)}
                        size="large"><DoubleArrow fontSize="small" style={{ transform: "rotate(180deg)" }} /></IconButton>

                    <IconButton
                        style={{ padding: 5 }}
                        onClick={() => this.onChangeDateButton(-1)}
                        size="large"><NavigateNext fontSize="medium" style={{ transform: "rotate(180deg)" }} /></IconButton>
                    <IconButton
                        style={{ padding: 5 }}
                        onClick={() => this.onChangeDateButton(0)}
                        size="large"><Today fontSize="small" /></IconButton>
                    <IconButton
                        style={{ padding: 5 }}
                        onClick={() => this.onChangeDateButton(1)}
                        size="large"><NavigateNext fontSize="medium" /></IconButton>

                    <IconButton
                        style={{ padding: 5 }}
                        onClick={() => this.onChangeDateButton(7)}
                        size="large"><DoubleArrow fontSize="small" /></IconButton>



                <FormControl>
                    {this.props.calendar_search_store_list.length > 0 ? <Autocomplete
                    id="combo-box-demo"

                    options={this.props.calendar_search_store_list.length === 0 && this.props.rent_tasks_disp_form.section_id ? [this.props.calendar_search_store_list.filter(a => String(a.tenpo_no) === String(this.props.rent_tasks_disp_form.section_id))[0]]
                        : this.props.calendar_search_store_list}
        
                    getOptionLabel={(option) => option.tenpo_name ? option.tenpo_name : ''}
                    value={this.props.rent_tasks_disp_form.section_id ? this.props.calendar_search_store_list.filter(a => String(a.tenpo_no) === String(this.props.rent_tasks_disp_form.section_id))[0] : {}}
                    style={{ width: 220 }}
                    onChange={this.handle_change_section_id}
                    size="small"
                    renderInput={(params) => <TextField variant="outlined"
                        {...params} label="部署を選択" />}

                    /> : ""}


                </FormControl>
                <FormControl>
                {this.props.users.length > 0 && this.props.user_details ? <Autocomplete
                    id="combo-box-demo"

                    options={this.props.users.length === 0 && this.props.rent_tasks_disp_form.user_id ? [this.props.users.filter(a => String(a.id) === String(this.props.rent_tasks_disp_form.user_id))[0]]
                    : 
                    this.props.rent_tasks_disp_form.section_id > 0 ? this.props.users.filter(a => this.props.rent_tasks_disp_form.section_id == 56 ? (a.id == 1392 || a.id == 1015) : (this.props.rent_tasks_disp_form.section_id == 501 || this.props.rent_tasks_disp_form.section_id == 502) ? a.amSectionId == 5 : a.amSectionId == this.props.rent_tasks_disp_form.section_id) : this.props.users}
    
                    getOptionLabel={(option) => option.userNameAll ? option.userNameAll : ''}
                    value={this.props.rent_tasks_disp_form.user_id ? this.props.users.filter(a => String(a.id) === String(this.props.rent_tasks_disp_form.user_id))[0] : {}}
                    size="small"
                    style={{ width: 150 }}
                    onChange={this.handle_change_user_id}

                    renderInput={(params) => <TextField variant="outlined" InputLabelProps={{ shrink: true }}
                    {...params} label="担当を選択" />}

                /> : ""}


                </FormControl>

                {this.props.calendar_search_store_list && this.props.calendar_search_store_list.length > 0 ? <FormControlLabel
                    control={
                    <Checkbox
                        name="is_completed"
                        checked={this.props.rent_tasks_disp_form.is_completed}
                        onChange={this.handleChange}
                        color="primary"
                        style={{marginLeft: 10}}
                    />
                    }
                    label="未完了のみ"
                />:""}

                {this.props.calendar_search_store_list && this.props.calendar_search_store_list.length > 0 ? <FormControlLabel
                    control={
                    <Checkbox
                        name="is_auto_mail"
                        checked={this.props.rent_tasks_disp_form.is_auto_mail}
                        onChange={this.handleChange}
                        color="primary"
                        style={{marginLeft: 10}}
                    />
                    }
                    label="メール自動送信以外"
                />:""}

            </Card>

            <Paper square >
                <Tabs
                    id="fileupload"
                    name="fileupload"
                    value={this.props.rent_tasks_disp_form.disp_tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {/* <Tab label={"すべて"} value={0} /> */}
                    <Tab label={<span>すべて<Chip label={this.props.rent_tasks_disp_form.count_type_0} style={{ margin: 5, fontSize: 14, }} /></span>} style={{width:200}} value={0} />
                    <Tab label={<span>来店<Chip label={this.props.rent_tasks_disp_form.count_type_1} style={{ margin: 5, fontSize: 14, }} /></span>} style={{width:200}} value={1} />
                    <Tab label={<span>契約フォロー<Chip label={this.props.rent_tasks_disp_form.count_type_2} style={{ margin: 5, fontSize: 14, }} /></span>} style={{width:200}} value={2} />
                    <Tab label={<span>その他<Chip label={this.props.rent_tasks_disp_form.count_type_3} style={{ margin: 5, fontSize: 14, }} /></span>} style={{width:200}} value={3} />
                    <Tab label={<span>追客<Chip label={this.props.rent_tasks_disp_form.count_type_4} style={{ margin: 5, fontSize: 14, }} /></span>} style={{width:200}} value={4} />
                    <Tab label={<span>初回対応<Chip label={this.props.rent_tasks_disp_form.count_type_5} style={{ margin: 5, fontSize: 14, }} /></span>} style={{width:200}} value={5} />
                    {/* <Tab label="Active" /> */}
                </Tabs>

            </Paper>

            <GridComponent />

            {/* {this.props.selectTabs == 0 ? <IndexOwnerDetail  /> : 
            this.state.selectTabs == 1 ? <SupportHistoryGridTab /> :
            this.state.selectTabs == 2 ? <RequestGridTab /> : 
            this.state.selectTabs == 3 ? <ConstructionGridTab /> :
            this.state.selectTabs == 4 ? <SeiyakuKaiyakuGridTab /> :""} */}



            <Backdrop className={this.props.classes.backdrop} open={this.props.task_backdrop}>
            <CircularProgress color="inherit" />
            </Backdrop>



            </div>
        </>;
    }


}



const mapStateToProps = (state) => {
    return {
        user_details: state.masterReducer.user_details,
        rent_tasks_disp_form: state.task.rent_tasks_disp_form,
        calendar_search_store_list: state.calendar.calendar_search_store_list,
        users: state.masterReducer.users,
        task_backdrop: state.task.task_backdrop
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_task_rent_responses_list(state) {
            dispatch(Task.get_task_rent_responses_list(state))
        },
        change_task_rent_responses_list(state) {
            dispatch(Task.change_task_rent_responses_list(state))
        },
        change_rent_tasks_disp_form(state) {
            dispatch(Task.change_rent_tasks_disp_form(state))
        },
        search_task_rent_responses_list(state) {
            dispatch(Task.search_task_rent_responses_list(state))
        },
        get_calendar_search_store_list(state) {
            dispatch(Calendar.get_calendar_search_store_list(state))
        },
        get_users(state) {
            dispatch(Calendar.get_users(state))
        },
      
    };
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(TaskComponent);