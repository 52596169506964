import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Sales from './Saga/Sales';
import '../../Css/Grid.css';
import SalesGridComponent from './SalesGridComponent';
import { Row, Col } from 'reactstrap';
// import Loading from '../Layout/LoadingComponent';






interface Props {
    get_rent_sales_list: any
    rent_sales_list: any,
    responseid: number,
    responsedetail: any,
    get_rent_m_sale_accounts: any,
    rent_m_sale_accounts: any,
    update_rent_sales_list: any,
    get_rent_sales_list_success: any,
    sales_loading: any,
}

interface State {
    refresh: any
}

class SalesComponent extends Component<Props, State>  {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            refresh: false
        }
        // alert(props.responsedetail.rentContractId)
        props.get_rent_sales_list(props.responsedetail.rentContractId)
        props.get_rent_m_sale_accounts(1);
        console.log(this.props.rent_sales_list)


    }
    onGridRowsUpdated = ({ fromRow, toRow, updated }: any) => {
        console.log(updated)


        // this.props.update_rent_sales_list({fromRow:fromRow, toRow:toRow, updated:updated,rent_sales_list:this.props.rent_sales_list})
        let rent_sales_list = this.props.rent_sales_list;
        this.props.get_rent_sales_list_success([]);
        for (let i = fromRow; i <= toRow; i++) {


            if (updated.rentMSaleAccountName) {
                var rentmsales = this.props.rent_m_sale_accounts.filter((a: any) => a.text === updated.rentMSaleAccountName)

                rent_sales_list[i].rentMSaleAccountId = rentmsales[0].id;
                rent_sales_list[i].rentMSaleAccountName = rentmsales[0].value;
            } else if (updated.amount) {

                rent_sales_list[i].amount = updated.amount;
                //  this.props.rent_sales_list[i] = { ...this.props.rent_sales_list[i], ...updated };
            } else if (updated.remarks) {

                rent_sales_list[i].remarks = updated.remarks;
                //  this.props.rent_sales_list[i] = { ...this.props.rent_sales_list[i], ...updated };
            }
            rent_sales_list[i] = { ...rent_sales_list[i], ...updated };
        }

        this.props.get_rent_sales_list_success(rent_sales_list);

        console.log(this.props.rent_sales_list)

        //   return this.props.rowss ;k

    };

    render() {


        return (
            <Row>
                <Col lg="8" className="item-outer">


                    <Col lg="12" className="item-outer">
                        <div className="item">
                            {this.props.rent_m_sale_accounts.length > 0 ? <SalesGridComponent
                                rent_sales_list={this.props.rent_sales_list}
                                rent_m_sale_accounts={this.props.rent_m_sale_accounts}
                                onGridRowsUpdated={this.onGridRowsUpdated.bind(this)} /> : ""}


                        </div>
                    </Col>

                </Col>

                <Col lg="4" className="item-outer">
                    <Col lg="12" className="item-outer">



                    </Col>


                    <Col lg="12" className="item-outer">
                        <div className="item">


                        </div>
                    </Col>


                </Col>
            </Row>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        responsedetail: state.responsesReducer.responsedetail,
        rent_sales_list: state.sales.rent_sales_list,
        rent_m_sale_accounts: state.sales.rent_m_sale_accounts,
        sales_loading: state.sales.sales_loading,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_rent_sales_list(state: any) {
            dispatch(Sales.get_rent_sales_list(state))
        },
        get_rent_sales_list_success(state: any) {
            dispatch(Sales.get_rent_sales_list_success(state))
        },
        get_rent_m_sale_accounts(state: any) {
            dispatch(Sales.get_rent_m_sale_accounts(state))
        },
        update_rent_sales_list(state: any) {
            dispatch(Sales.update_rent_sales_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesComponent);