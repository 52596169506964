import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as YoyakuParking from './Saga/YoyakuParking';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import { Table, Column, Cell } from 'fixed-data-table-2';

import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from '@mui/material';
import ParkingEdit from './ParkingEdit';
import NewCreate from './NewCreate';
import CloseIcon from '@mui/icons-material/Close';
// import { makeStyles } from '@mui/material/styles';
import ResponseComponent from "../ResponseDetail/ResponseComponent";
import * as Responses from '../ResponseDetail/Saga/Responses';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';
interface Props {
    classes: any,
    search_data: any,
    renraku_check:any,
    search_name_data: any,
    search_tel_data: any,
    change_search_data: any,
    change_reload_yoyaku_parking:any,
    change_renraku_check:any,
    yoyaku_parking_list: any,
    get_yoyaku_parking_list: any,
    yoyaku_parking_list_search: any,
    get_yoyaku_parking_list_search: any,
    change_yoyaku_parking_list_shikichi: any,
    get_yoyaku_parking_name_list_search: any,
    get_yoyaku_parking_tel_list_search: any,
    get_yoyaku_parking_renraku_list_search:any,
    get_tm_yoyakuparkingarea_detail_list:any,
    send_tm_yoyakuparkingarea_select_list:any,
    set_yoyaku_parking: any,
    change_yoyaku_parking: any,
    yoyaku_parking: any,
    change_yoyaku_parking_top_open: any,
    yoyaku_parking_top_open: boolean,
    change_new_create_top_open:any,
    change_yoyaku_parking_new_create:any,
    new_create_top_open:boolean,
    change_modalResponseDetail: any,
    modalResponseDetail: boolean,
    responseid: number,
    change_yoyaku_parking_detail_open: any,
    yoyaku_parking_detail_open: boolean,


    change_search_data_replace: any,
    change_yoyaku_parking_list_replace: any,
    change_yoyaku_parking_list_replace_search: any,
    change_rent_customer_list_replace: any,
    change_rent_customer_list_replace_search: any,

    user_details: any,
    // customerNewDispSet: any,
    // customerNewDisp:number,
    // customerno:number,
    // customerList:any,
    // toggle:any,
    // get_rent_contract_accounting: any,
    // banks: any,
    // get_banks: any
}



interface State {

    // state types
}
const useStyles = makeStyles(theme => ({
    dialogPaper: {

        height: '900px'
    },
}));

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))
interface State {
    modal: boolean;
    newmodal: boolean;
    modal_contacts: boolean;
    modal_replace: boolean;
    change: boolean;
    search: any;
    check: boolean;
    shikichi: boolean;
    columnWidths: any;
    startdate:any;
    enddate:any;
}

class ReserveParking extends Component<Props, State> {

    constructor(props: Readonly<Props>) {

        super(props);
        this.state = {
            modal: false,
            modal_contacts: false,
            modal_replace: false,
            newmodal:false,
            // modalselect: false,
            change: false,
            search: '',
            check: false,
            shikichi:true,
            startdate:new Date(new Date().setMonth(new Date().getMonth() - 1)),
            enddate:new Date(),

            columnWidths: {
                PId: 100,
                PName: 200,
                Name: 150,
                Akimachi: 80,
                Area:100,
                RNo:40,
                PBikou:200,
                Bikou:200,
                Tel:130,
                Mail:130,
                CDate:150
            },
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);

        this.handleOpen = this.handleOpen.bind(this);
        this.handleAnotherOpen = this.handleAnotherOpen.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleNameSearch = this.handleNameSearch.bind(this);
        this.handleTelSearch = this.handleTelSearch.bind(this);
        this.handleRenraku = this.handleRenraku.bind(this)
        this.handleContactsOpen = this.handleContactsOpen.bind(this);
        this.handleReplaceOpen = this.handleReplaceOpen.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
        this.handleReload = this.handleReload.bind(this);
        this.handleShikichi = this.handleShikichi.bind(this)

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        this.props.get_yoyaku_parking_list()
    }

    _onColumnResizeEndCallback(newColumnWidth: any, columnKey: any) {
        this.setState(({ columnWidths }) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            },
        }));
    }


    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state: any) {
        console.log(state)

        // this.props.get_banks(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }

    handleOpen(value:any) {
        // this.props.set_rent_m_corporates(id)
        let rent_m_corporates = this.props.yoyaku_parking
        rent_m_corporates.P_Data = value
        rent_m_corporates.P_Data.newCreate = false
        // rent_m_corporates = value
        // console.log(id,no)
        console.log(rent_m_corporates)
        // this.props.change_rent_m_corporates(rent_m_corporates);
        this.setState({
            change: !this.state.change
        });
        this.props.change_yoyaku_parking(rent_m_corporates)
        this.props.change_yoyaku_parking_top_open(true);
    }

    handleAnotherOpen(value:any) {
        // this.setState({
        //     newcreate: !this.state.newcreate
        // });
        console.log(value)
        this.setState({ "newmodal": true })
        console.log(this.state,"chcecked data")
        this.props.change_yoyaku_parking_new_create(true)
        this.props.change_new_create_top_open(true)
    }
    handleContactsOpen() {
        this.setState({ "modal_contacts": true })
    }

    handleReplaceOpen() {
        this.setState({ "modal_replace": true })
    }

    handleSearch(state: any) {

        let search_data = this.props.search_data
        search_data.P_Name = state.target.value
        this.props.change_search_data(search_data);

        this.props.get_yoyaku_parking_list_search()
        this.setState({
            search: state.target.value
        });
    }
    handleNameSearch(state: any) {

        let search_data = this.props.search_data
        search_data.name = state.target.value
        this.props.change_search_data(search_data);

        this.props.get_yoyaku_parking_name_list_search()
        this.setState({
            search: state.target.value
        });
    }
    handleTelSearch(state: any) {
        
        let search_data = this.props.search_data
        search_data.tel = state.target.value
        this.props.change_search_data(search_data);

        this.props.get_yoyaku_parking_tel_list_search()
        this.setState({
            search: state.target.value
        });
    }
    handleRenraku(state: any){
        this.setState({
            check: state.target.checked
        });
        this.props.change_renraku_check(state.target.checked)
        this.props.get_yoyaku_parking_renraku_list_search()
        console.log(this.state,"いあ")
        if(state.target.checked == false){
            this.props.change_reload_yoyaku_parking(this.state)
        }
    }
    handleShikichi(state: any){
        console.log(state.target.checked)
        this.setState({
            shikichi: state.target.checked
        });
        this.props.change_yoyaku_parking_list_shikichi(state.target.checked)
        // this.props.change_renraku_check(state.target.checked)
        // this.props.get_yoyaku_parking_renraku_list_search()
        
    }

    handleClear() {
        let search_data_replace = { m_corporate_name: "", select_m_corporate_id: "", customer_name: "", }
        this.props.change_search_data_replace(search_data_replace);
        this.props.change_yoyaku_parking_list_replace([])
        this.props.change_yoyaku_parking_list_replace_search([])
    }
    handleStartDate(state:any){
        this.setState({
            startdate: state.target.value
            // startdate: moment(state).format('YYYY/MM/DD')
        });
    }
    handleEndDate(state:any){
        this.setState({
            enddate: state.target.value
            // enddate: moment(state).format('YYYY/MM/DD')
        });
    }
    handleReload(state:any){
        this.props.change_reload_yoyaku_parking(this.state)
    }
    render() {
        return (
            // <div className={this.props.classes.grid} style={{ margin: 5 }}>
            //     <Grid container spacing={1}>
            //         <Grid item xs={6}>
            //             <Card className={this.props.classes.root}>
            //                 {/* <CountComing /> */}
            //             </Card>
            //         </Grid>
            //         <Grid item xs={6}>
            //             <Card className={this.props.classes.root}>
            //                 {/* <ResponseCount /> */}
            //             </Card>
            //         </Grid>
            //     </Grid>
            // </div >
            <Card style={{ padding: "10px" }}>
                <Card style={{ padding: 10, marginBottom: 10 }}>
                    <Grid container justifyContent="space-between" spacing={1}>
                        <Grid justifyContent="space-between" item xs={10}>
                            <TextField style={{ float: "left" ,width: "400" , margin: "0 10px" }} onChange={this.handleSearch} size="small" id="search standard-basic" label="駐車場名" name="search" variant="standard" InputLabelProps={{ shrink: true }} />
                            <TextField style={{ float: "left" ,width: "300" , margin: "0 10px" }} onChange={this.handleNameSearch} size="small" id="search standard-basic" label="名前" name="search" variant="standard" InputLabelProps={{ shrink: true }} />
                            <TextField style={{ float: "left" ,width: "300" , margin: "0 10px" }} onChange={this.handleTelSearch} size="small" id="search standard-basic" label="電話番号" name="search" variant="standard" InputLabelProps={{ shrink: true }} />
                        <FormControlLabel  style={{ float: "left" }}　control={<Checkbox onChange={this.handleRenraku}/>}　label="連絡済み" />
                        
                        <div>{this.state.check ? 
                        <>
                        <div style={{width:"750"}}>
                            <TextField
                                id="startdate"
                                label="表示期間"
                                type="date"
                                name="startdate"
                                size="small"
                                variant='outlined'
                                onChange={this.handleStartDate}
                                value={moment(this.state.startdate).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {/* <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd"
                                margin="normal"
                                id="date-picker-inline1"
                                label="表示期間"
                                //   showTodayButton={true}
                                style={{ float: "left", marginTop: "0" }}
                                autoOk={true}
                                InputLabelProps={{ shrink: true }}
                                name="contraction_date_start"
                                value={this.state.startdate}
                                onChange={this.handleStartDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                invalidDateMessage={"日付の形式が違います"}
                            /> */}
                    
                            <div style={{ float: "left", paddingTop: "15px" }}>～</div>

                                <TextField
                                    id="enddate"
                                    label="表示期間"
                                    type="date"
                                    name="enddate"
                                    size="small"
                                    variant='outlined'
                                    onChange={this.handleEndDate}
                                    value={moment(this.state.enddate).format("YYYY-MM-DD")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {/* <KeyboardDatePicker

                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="normal"
                                    id="date-picker-inline2"
                                    label="表示期間"
                                    style={{ marginLeft: "20px", marginTop: "0" }}
                                    autoOk={true}
                                    InputLabelProps={{ shrink: true }}
                                    name="contraction_date_end"
                                    value={this.state.enddate}
                                    onChange={this.handleEndDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={"日付の形式が違います"}
                                /> */}
                            
                            </div>
                            </> : ''}
                            </div>
                            <FormControlLabel  style={{ float: "left" }}　control={<Checkbox checked={this.state.shikichi} onClick={this.handleShikichi}/>}　label="敷地内含む" />
                            <Button style={{ margin: 5 }} size="small" variant="contained" color="primary" onClick={this.handleReload}>
                                            更新
                            </Button>
                      
                      
                            
                        </Grid>
                        <Grid style={{ padding: '4px'}}>
                            <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={this.handleAnotherOpen}>
                                新規作成
                            </Button>
                        </Grid>
                        {/* <Grid justify="space-between" item xs={2}>

                            <Modal isOpen={this.props.rent_m_corporates_detail_open} toggle={() => { this.props.change_rent_m_corporates_detail_open(false) }} fade={false}  >
                                <CorporatesDetailDialog parentComponent={0}></CorporatesDetailDialog>
                            </Modal>
                        </Grid>
                        <Grid justify="space-between" item xs={10}>
                        </Grid>
                        <Grid justify="space-between" item xs={2}>
                            <Button style={{ float: "right", backgroundColor: "#5cb85c" }} size="small" variant="contained" color="primary" onClick={this.handleContactsOpen}>営業対応履歴</Button>
                            {(this.props.user_details.amSectionId == 10 || this.props.user_details.id == 1015) ? <Button style={{ float: "right", backgroundColor: "#5cb85c", marginRight: 5 }} size="small" variant="contained" color="primary" onClick={this.handleReplaceOpen}>結びつけ</Button> : ""}
                        </Grid> */}
                    </Grid>
                </Card>

                {this.props.yoyaku_parking_list_search && this.props.yoyaku_parking_list_search.length > 0 ?<Table 
                    rowHeight={34}
                    rowsCount={this.props.yoyaku_parking_list_search.length}
                    width={window.innerWidth - 100}
                    height={window.innerHeight - 170}
                    headerHeight={40}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                >

                    <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{ marginTop: -5 }} aria-label="edit" size="small" onClick={() => { this.handleOpen(this.props.yoyaku_parking_list_search[rowIndex] ) }}><EditIcon /></IconButton>{this.props.yoyaku_parking_list_search[rowIndex].id}</Cell>)} width={40} />
                    {/* <Column header={<Cell>表示</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_m_corporates_list_search[rowIndex].is_display?"×":""}</Cell>)} width={50}/> */}
                    {/* <Column header={<Cell>id</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_m_corporates_list_search[rowIndex].id}</Cell>)} width={250}/> */}
                    <Column width={this.state.columnWidths.PId} isResizable={true} columnKey="PId" header={<Cell>駐車場ID</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{background:this.props.yoyaku_parking_list_search[rowIndex].Is_Renraku_Zumi ? "" : "9c9c9c"}} >{this.props.yoyaku_parking_list_search[rowIndex].P_Id}</Cell>)} />
                    <Column width={this.state.columnWidths.PName} isResizable={true} columnKey="PName" header={<Cell>駐車場名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.yoyaku_parking_list_search[rowIndex].P_Name}</Cell>)} />
                    <Column width={this.state.columnWidths.Name} isResizable={true} columnKey="Name" header={<Cell>お客様名</Cell>} cell={({ rowIndex, ...props }) => (<Cell style={{ color:"000000"}} {...props}>{this.props.yoyaku_parking_list_search[rowIndex].Kokyaku_Name}</Cell>)} />
                    <Column width={this.state.columnWidths.Akimachi} isResizable={true} columnKey="Akimachi" header={<Cell>空き有無</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.yoyaku_parking_list_search[rowIndex].akimachi?'空有':''}</Cell>)} />
                    <Column width={this.state.columnWidths.Area} isResizable={true} columnKey="Area" header={<Cell>空き区画</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.yoyaku_parking_list_search[rowIndex].Area}</Cell>)} />
                    <Column width={this.state.columnWidths.RNo} isResizable={true} columnKey="RNo" header={<Cell>No</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.yoyaku_parking_list_search[rowIndex].No}</Cell>)} />
                    <Column width={this.state.columnWidths.PBikou} isResizable={true} columnKey="PBikou" header={<Cell>駐車場備考</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.yoyaku_parking_list_search[rowIndex].Park_Bikou}</Cell>)} />
                    <Column width={this.state.columnWidths.Bikou} isResizable={true} columnKey="Bikou" header={<Cell>備考</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.yoyaku_parking_list_search[rowIndex].Bikou}</Cell>)} />
                    <Column width={this.state.columnWidths.Tel} isResizable={true} columnKey="Tel" header={<Cell>電話番号</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.yoyaku_parking_list_search[rowIndex].Tel}</Cell>)} />
                    <Column width={this.state.columnWidths.Mail} isResizable={true} columnKey="Mail" header={<Cell>メールアドレス</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.yoyaku_parking_list_search[rowIndex].Mail}</Cell>)} />
                    <Column width={this.state.columnWidths.CDate} isResizable={true} columnKey="CDate" header={<Cell>予約日</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.yoyaku_parking_list_search[rowIndex].created_at).format('YYYY年M月D日')}</Cell>)} />

                </Table> : ''}

                <Modal fade={false} isOpen={this.props.yoyaku_parking_top_open} toggle={() => { this.props.change_yoyaku_parking_top_open(false) }} size="xxl" >
                    

                    <div><IconButton
                        onClick={() => { this.props.change_yoyaku_parking_top_open(false) }}
                        style={{ float: "right", marginTop: 0, marginRight: 0 }}
                        size="large"><CloseIcon /></IconButton></div>
                    {/* <IconButton aria-label="edit" size="small" style={{width:40, float:"right", marginRight:0}} onClick={this.handleClose}><CloseIcon /></IconButton> */}
                    <ParkingEdit rent_m_corporate_id={this.props.yoyaku_parking.id}></ParkingEdit>



                    {/* <Button onClick={this.handleClose}>閉じる</Button> */}

                </Modal>
                <Modal fade={false} isOpen={this.props.new_create_top_open} toggle={() => { this.props.change_new_create_top_open(false);this.props.change_yoyaku_parking_new_create(false); }} size="xxl" >
                    

                    <div><IconButton
                        onClick={() => { this.props.change_new_create_top_open(false);this.props.change_yoyaku_parking_new_create(false); }}
                        style={{ float: "right", marginTop: 0, marginRight: 0 }}
                        size="large"><CloseIcon /></IconButton></div>
                    <NewCreate></NewCreate>


                </Modal>

                <Modal isOpen={this.props.modalResponseDetail} toggle={() => this.props.change_modalResponseDetail(false)}
                    size="xxl" fade={false}

                >


                    {/* <ModalHeader toggle={() => this.props.change_modalResponseDetail(false)}>反響詳細</ModalHeader> */}


                    <ResponseComponent
                        // responseTabs="r4"
                        change_modalResponseDetail={(a: any) => this.props.change_modalResponseDetail(a)} rentResponseId={this.props.responseid} response_modal={true} />

                </Modal>
                <Modal isOpen={this.props.modalResponseDetail} toggle={() => this.props.change_modalResponseDetail(false)}
                    size="xxl" fade={false}

                >


                    {/* <ModalHeader toggle={() => this.props.change_modalResponseDetail(false)}>反響詳細</ModalHeader> */}


                    <ResponseComponent
                        // responseTabs="r4"
                        change_modalResponseDetail={(a: any) => this.props.change_modalResponseDetail(a)} rentResponseId={this.props.responseid} response_modal={true} />

                </Modal>
            </Card>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        yoyaku_parking_list: state.yoyakuparking.yoyaku_parking_list,
        yoyaku_parking_list_search: state.yoyakuparking.yoyaku_parking_list_search,
        yoyaku_parking: state.yoyakuparking.yoyaku_parking,
        yoyaku_parking_top_open: state.yoyakuparking.yoyaku_parking_top_open,
        new_parking_top_open: state.yoyakuparking.new_parking_top_open,
        search_data: state.yoyakuparking.search_data,
        renraku_check:state.yoyakuparking.renraku_check,
        modalResponseDetail: state.responsesReducer.modalResponseDetail,
        responseid: state.responsesReducer.responseid,
        yoyaku_parking_detail_open: state.yoyakuparking.yoyaku_parking_detail_open,
        new_create_top_open:state.yoyakuparking.new_create_top_open,
        user_details: state.masterReducer.user_details,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        change_search_data(state: any) {
            dispatch(YoyakuParking.change_search_data(state))
        },
        change_renraku_check(state: any) {
            dispatch(YoyakuParking.change_renraku_check(state))
        },
        get_yoyaku_parking_list(state: any) {
            dispatch(YoyakuParking.get_yoyaku_parking_list(state))
        },
        change_yoyaku_parking_top_open(state: any) {
            dispatch(YoyakuParking.change_yoyaku_parking_top_open(state))
        },
        change_new_create_top_open(state: any) {
            dispatch(YoyakuParking.change_new_create_top_open(state))
        },
        change_yoyaku_parking_new_create(state: any) {
            dispatch(YoyakuParking.change_yoyaku_parking_new_create(state))
        },
        change_reload_yoyaku_parking(state:any){
            dispatch(YoyakuParking.get_yoyakuparking_reload_list(state))
        },
        change_yoyaku_parking(state:any){
            dispatch(YoyakuParking.change_yoyaku_parking(state))
        },
        // insert_rent_m_corporates(state: any) {
        //     dispatch(Corporation.insert_rent_m_corporates(state))
        // },
        // update_rent_m_corporates(state: any) {
        //     dispatch(Corporation.update_rent_m_corporates(state))
        // },
        // change_rent_m_corporates(state: any) {
        //     dispatch(Corporation.change_rent_m_corporates(state))
        // },
        // set_rent_m_corporates(state: any) {
        //     dispatch(Corporation.set_rent_m_corporates(state))
        // },
        get_yoyaku_parking_list_search(state: any) {
            dispatch(YoyakuParking.get_yoyaku_parking_list_search(state))
        },
        change_yoyaku_parking_list_shikichi(state: any) {
            dispatch(YoyakuParking.change_yoyaku_parking_list_shikichi(state))
        },
        get_yoyaku_parking_name_list_search(state: any) {
            dispatch(YoyakuParking.get_yoyaku_parking_name_list_search(state))
        },
        get_yoyaku_parking_tel_list_search(state: any) {
            dispatch(YoyakuParking.get_yoyaku_parking_tel_list_search(state))
        },
        get_yoyaku_parking_renraku_list_search(state: any) {
            dispatch(YoyakuParking.get_yoyaku_parking_renraku_list_search(state))
        },

        change_responseid(state: any) {
            dispatch(Responses.change_responseid(state))
        },
        change_modalResponseDetail(state: any) {
            dispatch(Responses.change_modalResponseDetail(state))
        },
        get_tm_parkingarea_detail_list(state: any){
            dispatch(YoyakuParking.get_tm_yoyakuparkingarea_list(state))
        }


        // change_search_data_replace(state: any) {
        //     dispatch(Corporation.change_search_data_replace(state))
        // },
        // change_rent_m_corporates_list_replace(state: any) {
        //     dispatch(Corporation.change_rent_m_corporates_list_replace(state))
        // },
        // change_rent_m_corporates_list_replace_search(state: any) {
        //     dispatch(Corporation.change_rent_m_corporates_list_replace_search(state))
        // },
        // change_rent_customer_list_replace(state: any) {
        //     dispatch(Corporation.change_rent_customer_list_replace(state))
        // },
        // change_rent_customer_list_replace_search(state: any) {
        //     dispatch(Corporation.change_rent_customer_list_replace_search(state))
        // },

        // change_rent_m_corporates_detail_open(state: any) {
        //     dispatch(Corporation.change_rent_m_corporates_detail_open(state))
        // },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReserveParking));