import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as Files from './Saga/Files';

import './Css/FileUp.css';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import Applications from './Applications';
import ContractsDocs from './Contracts';
import ResponseContractDocs from './ResponseContractDocs';
import InDocs from './InDocs';

import LimitedPublicDocs from './LimitedPublicDocs';
import FileUpload from './FileUpload';
import PublicFileIndex from './PublicFileIndex';
import CloseIcon from '@mui/icons-material/Close';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import Paper from '@mui/material/Paper';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';

interface Props {

    responsedetail: any,
    get_files: any,
    contract_file_update_disp: boolean,
    change_contract_file_update_disp: any,
    change_file_tab: any,
    file_tab: any,
    add_rent_limited_public_pages: any,
    response_tabs: any,
    rent_contract_details: any,
    change_contract_files: any,
    contract_files_all: any,
    contract_files_all_disp: any,
    change_contract_files_all_disp: any,
    // insert_introductions: any,
    // get_introductions: any,

    // delete_introduction: any,
    // update_open_introductions: any,
    // togglefileup: any,
    // room_details: any,
}


interface State {
    fileid: number,
    name: string,
    remarks: string,
    contractdoc: number,
    // filetab: string
    responsefileuploadpop_disp: boolean,
    refresh:boolean
    // modalsheet: boolean,
    // modalestimates: boolean,
    // tatemonoId: number,
    // roomId: number,
    // state types
}







class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            fileid: 0,
            name: "",
            remarks: "",
            contractdoc: 0,
            responsefileuploadpop_disp: false,
            refresh:false,
            // modalsheet: false,
            // tatemonoId: 0,
            // roomId: 0,
            // modalestimates: false,
            // filetab: "f1"

        }
        // this.handleSubmit = this.handleSubmit.bind(this);
        // this.selectTatemono = this.selectTatemono.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.responsefileuploadpop = this.responsefileuploadpop.bind(this);
        this.file_remarks_edit_open = this.file_remarks_edit_open.bind(this);
        this.change_contract_file_add = this.change_contract_file_add.bind(this);
        this.onChangeDisp = this.onChangeDisp.bind(this);

        if(this.props.file_tab == "f1" && this.props.response_tabs == "r1"){
            // this.props.change_file_tab("f2")
        }
    }


    handleTabChange = (event: any, newValue: string) => {
        // alert(newValue)
        // alert(newValue)k
        // this.setState({ filetab: newValue });
        this.props.change_file_tab(newValue)
    };

    //     submit(state:any){
    //         console.log(state)
    // this.props.get_response_rooms(state)
    //     }
    handleSubmit(state: any) {
        console.log(state)


        state.rentResponseId = this.props.responsedetail.id;


        // this.props.actions.get_response_rooms(state);

    }

    responsefileuploadpop(state: boolean) {
        if (this.state.responsefileuploadpop_disp === true) {
            this.setState({ fileid: 0, name: "", remarks: "" })
            this.props.change_contract_file_update_disp(false)
        } else {
            this.setState({ fileid: 0, name: "", remarks: "" })
            this.props.change_contract_file_update_disp(true)
        }
    }

    file_remarks_edit_open(value: any) {

        if (this.props.contract_file_update_disp === true) {
            this.setState({ fileid: 0, name: "", remarks: "", contractdoc:0, })
            this.props.change_contract_file_update_disp(false)
        } else {
            this.setState({ fileid: value.fileid, name: value.name, remarks: value.remarks, contractdoc: value.contractdoc })
            this.props.change_contract_file_update_disp(true)
        }
    }

    change_contract_file_add(value: any) {

        this.setState({ fileid: 0, name: "", remarks: "" })
        this.props.change_contract_file_update_disp(!this.props.contract_file_update_disp)

        if(this.props.file_tab == "f3"){
            this.props.add_rent_limited_public_pages()
        }
    }

    onChangeDisp() {
// alert(this.state.all_disp)
        if(!this.props.contract_files_all_disp){
            // 全件
            // alert(this.props.contract_files_all.length)
            this.props.change_contract_files(this.props.contract_files_all)
        }else{
            // 絞って
            this.props.change_contract_files(this.props.contract_files_all.filter((a:any)=>a.disp == 1))
        }

        this.props.change_contract_files_all_disp(!this.props.contract_files_all_disp)
        this.setState({ refresh: !this.state.refresh})

    }



    // selectTatemono(tatemonoId: string, roomId: string) {
    //     // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}

    //     let insertdata = {
    //         rentResponseId: this.props.rent_response_id,
    //         tatemonoId: tatemonoId,
    //         status: 0,

    //         roomId: roomId,

    //     };


    //     // this.props.insert_introductions(insertdata)
    // }

    // selectsheet(row: any) {
    //     console.log(row)
    //     if (row)
    //         // this.setState(prevState => ({
    //         //     modalsheet: !prevState.modalsheet,
    //         //     tatemonoId: row.tatemonoId,
    //         //     roomId: row.roomId
    //         // }));
    // }
    // onMypage(value: any) {
    //     // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}

    //     let updatedata = {
    //         id: value.id,
    //         rentResponseId: this.props.rent_response_id,
    //         tatemonoId: value.tatemonoId,
    //         roomId: value.roomId,
    //         sectionId: value.sectionId,

    //         status: value.status,
    //         onMypage: value.onMypage === 1 ? 0 : 1,
    //         introFlag: value.introFlag,
    //         numbering: value.numbering,
    //         createdAt: value.createdAt,
    //         createdUser: value.createdUser,
    //         sendFlag: value.sendFlag,
    //         remarks: value.remarks,
    //     };



    //     // this.props.update_open_introductions(updatedata)
    // }

    // togglesheet = () => {

    //     this.setState(prevState => ({
    //         modalsheet: !prevState.modalsheet,

    //     }));
    // }





    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (

            <div style={{ height: 400, position: "relative"}}>
                <Paper square style={{width:(window.innerWidth / 2) - 75}}>
                    <Tabs
                        id="fileupload"
                
                        value={this.props.file_tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.props.response_tabs == "r1" ? "" : <Tab label="申込ファイル" value="f1" />}
                        {this.props.response_tabs == "r1" ? "" : (this.props.rent_contract_details.division == 1 || this.props.rent_contract_details.division == 2) ? <Tab label="契約書添付ファイル" value="f4" /> : ""}
                        <Tab label="社内ファイル" value="f2" />
                        <Tab label="公開ファイル" value="f3" />
                        {/* {this.props.response_tabs == "r1" ? "" : this.props.rent_contract_details.division != 1 && this.props.rent_contract_details.division != 2 ? <Tab label="契約書添付" value="f5" /> :""} */}
                        <Tab label="契約書添付" value="f5" />

                        {/* <Tab label="Active" /> */}
                    </Tabs>

                </Paper>
                {/* 
                <DataGrid
                    rent_response_id={this.props.responsedetail.id}
                    file_remarks_edit={this.file_remarks_edit_open}

                /> */}


                {this.props.file_tab === "f1" ? <Applications file_remarks_edit_open={this.file_remarks_edit_open} />
                    : this.props.file_tab === "f2" ? <InDocs file_remarks_edit_open={this.file_remarks_edit_open} />
                    : this.props.file_tab === "f3" ? <LimitedPublicDocs file_remarks_edit_open={this.file_remarks_edit_open} />
                    : this.props.file_tab === "f4" ? <ContractsDocs file_remarks_edit_open={this.file_remarks_edit_open} />
                    : this.props.file_tab === "f5" ? <ResponseContractDocs file_remarks_edit_open={this.file_remarks_edit_open} />
                    : ""}




                {this.props.contract_file_update_disp ? 
                this.props.file_tab === "f3" ? ""
                :
                <Paper id="responsefileupload" style={{ height: "420px", width: "400px", position: "absolute", top: "5px", left: "20px", padding: 10, zIndex: 1000 }}>
                {this.props.file_tab === "f1" ? "申込ファイル"
                    : this.props.file_tab === "f2" ? "社内ファイル"
                    : this.props.file_tab === "f3" ? "公開ファイル"
                    : this.props.file_tab === "f4" ? "契約書添付ファイル"
                    : this.props.file_tab === "f5" ? "契約書添付"
                    : ""}

                    <BottomNavigationAction label="Nearby" onClick={() => this.props.change_contract_file_update_disp(false)} style={{ float: "right", paddingTop: 0, paddingBottom: 0, paddingRight: 0 }} icon={<CloseIcon />} />

                    <hr />
                    <FileUpload fileid={this.state.fileid} name={this.state.name} remarks={this.state.remarks} contractdoc={this.state.contractdoc} />
                </Paper> : ""}
                <Fab color="primary" aria-label="add" onClick={this.change_contract_file_add} style={{ zIndex:1000, height: "34px", width: "36px", position: "absolute", bottom: "-10px", right: "-10px" }}>
                    <AddIcon style={{ fontSize: "20px" }} />
                </Fab>

                {this.props.file_tab === "f4" ? <FormControlLabel style={{ zIndex:1000, height: "34px", width: "36px", position: "absolute", bottom: "-20px", right: "50px" }} control={<Tooltip title="すべて表示"><Switch
                    checked={this.props.contract_files_all_disp}
                    onChange={() => this.onChangeDisp()}
                    color="primary"
                /></Tooltip>} label="" /> : ""}


                <Modal isOpen={this.props.file_tab === "f3" && this.props.contract_file_update_disp} size="xl" toggle={() => this.props.change_contract_file_update_disp(false)} fade={false} >
                    <ModalHeader toggle={() => this.props.change_contract_file_update_disp(false)}>
                        公開ファイル
                    </ModalHeader>
                    <ModalBody style={{height:750}}>

                                <PublicFileIndex fileid={this.state.fileid} name={this.state.name} remarks={this.state.remarks} />

                    </ModalBody>
                </Modal>
            </div >


        );
    }


}



const mapStateToProps = (state: any) => {
    return {

        room_details: state.roomsReducer.room_details,
        contract_file_update_disp: state.files.contract_file_update_disp,
        responsedetail: state.responsesReducer.responsedetail,
        file_tab: state.files.file_tab,
        response_tabs: state.responsesReducer.response_tabs,
        rent_contract_details: state.contracts.rent_contract_details,
        contract_files_all: state.files.contract_files_all,
        contract_files_all_disp: state.files.contract_files_all_disp,
        // response_rooms: state.roomsReducer.response_rooms,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_files(state: any) {
            dispatch(Files.get_files(state))
        },
        change_contract_file_update_disp(state: any) {
            dispatch(Files.change_contract_file_update_disp(state))
        },
        change_file_tab(state: any) {
            dispatch(Files.change_file_tab(state))
        },
        add_rent_limited_public_pages(state: any) {
            dispatch(Files.add_rent_limited_public_pages(state))
        },
        change_contract_files(state: any) {
            dispatch(Files.change_contract_files(state))
        },
        change_contract_files_all_disp(state: any) {
            dispatch(Files.change_contract_files_all_disp(state))
        },
        // delete_introduction(state: any) {
        //     dispatch(IntroductionList.delete_introduction(state))
        // },
        // update_open_introductions(state: any) {
        //     dispatch(IntroductionList.update_open_introductions(state))
        // },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);