const authentication = require('../../../react-azure-adb2c2').default;


export const list = (value: any) => {

    // let url = `http://localhost:5000/v1/rent/hearing/sheets/detail/` + value;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/hearing/sheets/detail/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const download = (values: any) => {

    const url = process.env.REACT_APP_API_LARAVEL_API_URL + '/api/hearingsheets/' + values

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // console.log(token)
    // alert(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token ,'Accept' : 'application/pdf', },

    }).then(res => res.blob() )
    //.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
    
}