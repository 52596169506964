import { connect } from 'react-redux';
import React, { createContext, useContext, useState, useEffect, useCallback, useReducer } from 'react';

import ReactDOM from 'react-dom';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { ThemeContext } from './ApprovaIndex';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Chip from '@mui/material/Chip';
import { LaptopWindowsOutlined } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';


const authentication = require('react-azure-adb2c2').default;

// Table data as a list of array.


function App(props) {

    const [detail, setDetail] = useState([]);
    const [approval, setApproval] = useState([]);
    const [authorizer, setAuthorizer] = useState([]);
    const [approval_comment, setApprovalComment] = useState([]);
    const [authorizer_now, setAuthorizerNow] = useState([]);
    
    const [comments, setComments] = useState([]);
  
    const token = authentication.getAccessToken();
  



    const handleComment = async(application_id, path_no) => {
        // alert(application_id)
        // alert(path_no)

        const values = {application_id:application_id, path_no:path_no, comment:comments}

        var f = await fetch(`http://localhost:5000/v1/flw/approval/comments/insert`, { method: 'post', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        var d = await f.json()

        d.comment_user_name = props.users_select.filter(a=>String(a.value) == String(d.user_id))[0].label

        // todo をコピーして、新しいArrayインスタンスを作成する。
        const newApproval_comment = [...approval_comment];

        setApprovalComment(newApproval_comment.concat(d))
        // setDetail(d)

        setComments([])

    };

    const handleApproval = async(application_id, path_no, approval_flg) => {
        // alert(application_id)
        // alert(path_no)
        // alert("この先のｄｂ更新処理からすること。この先はテストしていない。")

        const values = {application_id:application_id, path_no:path_no, approval_flg:approval_flg, user_id:props.user_details.id, comment:comments}

        
        var f = await fetch(`http://localhost:5000/v1/flw/authorizers/update`, { method: 'put', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        var d = await f.json()

        setComments([])

        getData(application_id)

    };


    const getData = async (id) => {
        // alert("ws")

        // 申請内容
        var f = await fetch(`http://localhost:5000/v1/flw/applications/detail/` + id, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        var d = await f.json()
        // console.log("detail")
        // console.log(d)
        setDetail(d)

        // 承認フロー
        f = await fetch(`http://localhost:5000/v1/flw/approvals/detail/` + id, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        d = await f.json()
        setApproval(d)

        // 承認者
        f = await fetch(`http://localhost:5000/v1/flw/authorizers/detail/` + id, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        d = await f.json()
        setAuthorizer(d)

        // コメント
        f = await fetch(`http://localhost:5000/v1/flw/approval/comments/detail/` + id, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        d = await f.json()
        setApprovalComment(d)

        // 現在の承認者リスト
        f = await fetch(`http://localhost:5000/v1/flw/authorizers/now/` + id, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        d = await f.json()
        setAuthorizerNow(d)
        // console.log(d)


    }

    useEffect(() => {
        getData(props.application_id)
        
    }, [])


        return (
            <div>

                    
                {detail != "" ? <Grid container spacing={3}>
                        <Grid xs={6} style={{ padding: 5 }}>
                            <div style={{fontSize:16}}>
                                {"【" + detail[0].form_name + "】"}{detail[0].user_name_all}（{moment(detail[0].created_at).utc().format("YYYY-MM-DD HH:mm:ss")}）
                            </div>
                            <div style={{fontSize:16, marginLeft:10}}>
                            {detail[0].title}
                            </div>
                        </Grid>
                        <Grid xs={6} style={{ padding: 5 }}>
                            {/* 0:未承認　1:承認　2:差し戻し　3:却下　4:再申請　5：申請者によるキャンセル*/}
                            {(() => {
                                if (detail[0].approval_flg == 0) {
                                  return <Alert severity="info">進行中</Alert>
                                } else if (detail[0].approval_flg == 1) {
                                  return <Alert severity="success">承認済み</Alert>
                                } else if (detail[0].approval_flg == 2) {
                                  return <Alert severity="error">差し戻し</Alert>
                                } else if (detail[0].approval_flg == 3) {
                                  return <Alert severity="warning">却下</Alert>
                                } else if (detail[0].approval_flg == 4) {
                                  return <Alert severity="info">再申請</Alert>
                                }
                              })()}
                            
                        </Grid>
                        <Grid xs={6} style={{ padding: 5 }}>
                    <table>
                    <colgroup width='30%' />
                    <colgroup width='70%' />

                    {
                        detail.map(function (value, key) {
                            if(value.form_value != ",,,,,,,,,,,,,"){
                            return (
                                <tr><th>{value.formitem_name}</th><td>{
                                    value.formitem_id == 9 && value.form_value ? value.form_value.split(",")[0] + "年" + value.form_value.split(",")[1] + "月" : 
                                    value.formitem_id == 11 && value.form_value ? value.tatemono_name + " " + value.room_name : 
                                    value.formitem_id == 13 && value.form_value && props.users_select ? props.users_select.filter(a=> a.value == value.form_value)[0].label : 
                                    value.formitem_id == 14 && value.form_value ? (value.tatemono_name ? value.tatemono_name + " " : "") + (value.room_name ? value.room_name + " " : "") + (value.tantou_user_name ? value.tantou_user_name : "") : 
                                    value.formitem_id == 15 && value.form_value ? <div>
                                        {value.form_value.split(",")[0] ? <div>{value.form_value.split(",")[0]}</div> : ""}
                                        {value.form_value.split(",")[1] ? <div>{value.form_value.split(",")[1]}</div> : ""}
                                        {value.form_value.split(",")[2] ? <div>{"売価(月額):" + value.form_value.split(",")[2]}</div> : ""}
                                        {value.form_value.split(",")[3] ? <div>{"原価(月額):" + value.form_value.split(",")[3]}</div> : ""}
                                        {value.form_value.split(",")[4] ? <div>{"売価(年額):" + value.form_value.split(",")[4]}</div> : ""}
                                        {value.form_value.split(",")[5] ? <div>{"原価(年額):" + value.form_value.split(",")[5]}</div> : ""}
                                        {value.form_value.split(",")[6] ? <div>{"業者:" + value.form_value.split(",")[6]}</div> : ""}
                                        {value.form_value.split(",")[7] ? <div>{"開始日:" + value.form_value.split(",")[7]}</div> : ""}
                                    </div>
                                    : 
                                    value.formitem_id == 2 && value.form_value ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: value.form_value.length ? value.form_value.replace(/\r?\n/g, '<br/>') : '' }}></span>
                                    
                                    :
                                    value.form_value} {value.formitem_id == 3 ? value.choice : ""}</td></tr>
                            );
                            }
                        })

                    }
                    </table>
                        </Grid>
                        <Grid xs={6} style={{ padding: 5 }}>

                        <Timeline style={{marginLeft: -window.innerWidth/2 + 90}}>
                    {
                        approval.map(function (value, key) {
                            if(authorizer.filter(a=> a.path_no == value.path_no).length > 0){
                            return <TimelineItem>
                                <TimelineSeparator>
                                <TimelineDot color={detail[0].status_path > value.path_no ? "primary" : detail[0].status_path == value.path_no ? "secondary" : ""} style={{ opacity:detail[0].status_path >= value.path_no ? 1 : 0.5}} />
                                {approval.length > key + 1 ? <TimelineConnector color={detail[0].status_path > value.path_no ? "secondary" : ""} /> : ""}
                                </TimelineSeparator>
                                <TimelineContent>
                                    {/* <span>{detail[0].approval_flg}_ </span>
                                    <span>{detail[0].status_path}_ </span>
                                    <span>{value.path_no}_ </span>
                                    <span>{value.approval_flg}_</span> */}
                                {<Chip label={value.approval_flg == 4 ? "申請者による再申請" : value.all_approval == 1 ? "誰か一人が承認" : "すべての承認が必要"} style={{ fontSize: 14,  opacity:detail[0].status_path >= value.path_no ? 1 : 0.5 }} variant="outlined" />}
                                {
                                    authorizer.filter(a=> a.path_no == value.path_no).map(function (value2, key) {// 0:未承認　1:承認　2:差し戻し　3:却下　4:再申請　5：申請者によるキャンセル
                                        return <div style={{marginLeft:15, marginTop:3, fontSize:14, opacity:detail[0].status_path >= value.path_no ? 1 : 0.5}}>
                                            {value2.approval_flg <= 5 ? 
                                                <Chip label={value2.approval_flg == 0 ? "未" : 
                                                // value2.approval_flg == 1 ? "承" :
                                                value2.approval_flg == 1 ? value.approval_type_name.charAt(0) :
                                                value2.approval_flg == 2 ? "差" :
                                                value2.approval_flg == 3 ? "却" :
                                                value2.approval_flg == 4 ? "再申請" :
                                                value2.approval_flg == 5 ? "申請者によるキャンセル" : ""
                                                } 
                                            color={value2.approval_flg == 4 || value2.approval_flg == 5 ? "default" : value2.approval_flg == 1 || value2.approval_flg == 2 ? "primary" : "secondary"} 
                                            variant={value2.approval_flg == 0 ? "outlined" : "default"} size="small"
                                            /> : ""}
                                            <span style={{marginLeft:5}}>{value.approval_flg != 4 && value2.approval_flg != 5 ? value.approval_type_name : "申請"}者：{value2.authorizers_name}{value2.approval_at ? "(" + moment(value2.approval_at).utc().format("YYYY-MM-DD HH:mm:ss") + ")" : ""}</span>

                                            </div>
                                    })
                                }
                                {
                                    approval_comment.filter(a=> a.path_no == value.path_no).map(function (value2, key) {// 0:未承認　1:承認　2:差し戻し　3:却下　4:再申請　5：申請者によるキャンセル
                                        return <div style={{marginLeft:45, marginTop:10}}>
                                                
                                                {value2.comment_user_name}：{value2.comment}{value2.comment_at ? "(" + moment(value2.comment_at).utc().format("YYYY-MM-DD HH:mm:ss") + ")" : ""}
                                            </div>
                                    })
                                }
                                {/* <span>aaaa:{value.approval_flg}</span> */}
                                {
                                    detail[0].status_path == value.path_no && value.approval_flg == 0 ? <div style={{marginLeft:15, marginTop:10}}>
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label="コメント"
                                            multiline
                                            rows={5}
                                            name="comments"
                                            onChange={(e)=>setComments(e.target.value)}
                                            value={comments}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        {/* <Button style={{ float: "right", margin: 5 }} variant="contained" color="" onClick={() => dispatch({application_id:value.application_id, path_no:value.path_no})}>コメントする</Button> */}
                                        <Button style={{ float: "right", margin: 5 }} variant="contained" onClick={() => handleComment(value.application_id, value.path_no)}>コメントする</Button>
                                        {/*未承認かつ　現在の承認者なら、承認、差し戻し、却下ボタン　*/}
                                        {authorizer_now.filter(a=>a.user_id == props.user_details.id && a.authorizer_approval_flg == 0).length > 0 ? <Button style={{ float: "right", margin: 5, backgroundColor:"#ffc107", color:"white" }} variant="contained" color="secondary" onClick={() => handleApproval(value.application_id, value.path_no, 3)}>却下</Button> : ""}
                                        {authorizer_now.filter(a=>a.user_id == props.user_details.id && a.authorizer_approval_flg == 0).length > 0 ? <Button style={{ float: "right", margin: 5, backgroundColor:"#5cb85c", color:"white" }} variant="contained" onClick={() => handleApproval(value.application_id, value.path_no, 2)}>差戻</Button> : ""}
                                        {authorizer_now.filter(a=>a.user_id == props.user_details.id && a.authorizer_approval_flg == 0).length > 0 ? <Button style={{ float: "right", margin: 5 }} variant="contained" color="primary" onClick={() => handleApproval(value.application_id, value.path_no, 1)}>承認</Button> : ""}
                                    </div>:""
                                }
                                </TimelineContent>
                            </TimelineItem>
                            }
                        })
                    }
                        </Timeline>
                        </Grid>
                    </Grid> : ""}

                </div>
        );
};

const mapStateToProps = (state) => {
    return {
      user_details: state.masterReducer.user_details,
      users_select: state.masterReducer.users_select,
    }
  }

export default connect(mapStateToProps)(App);