import React, { Component } from 'react';

import { connect } from 'react-redux';

import ReactDataGrid from 'react-data-grid';
//import { actionCreators } from '../../Store/MailData';

import "react-datepicker/dist/react-datepicker.css";
//import { actionCreators } from '../../Store/WeatherForecasts';

import { reduxForm } from 'redux-form';
import '../../Css/Table.css';
// import * as AutoKana from 'vanilla-autokana';
let autokana1;



// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (!values.name1) {
        errors.name1 = "名前を入力してください。";
    }
    if (!values.kana1) {
        errors.kana1 = "カナを入力してください。";
    }

    if (values.mail1) {
        if (!values.mail1.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            errors.mail1 = "メールアドレスの形式が正しくありません。";
        }
    }
    if (values.mail2) {
        if (!values.mail2.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            errors.mail2 = "メールアドレスの形式が正しくありません。";
        }
    }

    if (values.tel1) {
        if (!values.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel1.match(/^\d{7,13}$/)) {
            errors.tel1 = "電話番号の形式が正しくありません。";
        }
    }

    if (values.tel2) {
        if (!values.tel2.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel2.match(/^\d{7,13}$/)) {
            errors.tel2 = "電話番号の形式が正しくありません。";
        }
    }

    if (values.fax) {
        if (!values.fax.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.fax.match(/^\d{7,13}$/)) {
            errors.fax = "FAXの形式が正しくありません。";
        }
    }

    if (values.post) {
        //if (!values.post.match(/^\d{3}-\d{4}$/)) {
        //    errors.post = "郵便番号の形式が正しくありません。";
        //}
    }
    return errors;
};

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, style, meta: { touched, error } }) => (
//     <div >
//         <input {...input} type={type} id={id} placeholder={placeholder} style={{ width: "80" }} className={touched && error ? 'alert-danger ' + className : className} />
//         {touched && error && <span className="text-danger">{error}</span>}
//     </div>
// );

const columns = [{ key: 'id', name: '項目名', width: 100 }, { key: 'title', name: '請求', width: 100 }, { key: 'title', name: '金額', width: 100 }, { key: 'title', name: '税', width: 50 }, { key: 'title', name: '日割', width: 100 }];
const rows = [{ id: 1, title: 'Title 1' }];
const rowGetter = rowNumber => rows[rowNumber];

class ChinryoTsukiEditComponent extends Component {
   
    constructor(props) {
        super(props);
      
        this.state = {
            customerinfo:this.props.forecasts,
            startDate: new Date()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }
    
  componentDidMount() {
    // This method is called when the component is first added to the document
      this.ensureDataFetched();
      //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.ensureDataFetched();
  }

  ensureDataFetched() {
      //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
      //console.log(startDateIndex)

      //this.props.requestWeatherForecasts(0);

    }


    onChangeName(property, event) {

        if (property === "tatemonoKana") {
            this.props.change(property, autokana1.getFurigana())
        } else if (property === "kana2") {
            
        }


    }

    render() {

       
        //moment.locale('ja')
        return (
            <div>
            <ReactDataGrid
                columns={columns}
                rowGetter={rowGetter}
                rowsCount={rows.length}
                minHeight={200}
                style={{width:200}}
                />
            </div>

    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

ChinryoTsukiEditComponent = reduxForm({
    form: 'roomform', // a unique identifier for this form
    validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(ChinryoTsukiEditComponent)

export default connect(mapStateToProps, mapDispatchToProps)(ChinryoTsukiEditComponent);