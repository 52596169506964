import React, { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment'
import * as Customers from './Saga/Customers';

import '../../Css/Table.css';

import { Button } from 'reactstrap';

interface Props {
    // get_customer_logs: any,
    // dispatchRequest: any,
    // customerList: any,
    customerno: number,
    change_customerloding0: any,
    customerdetailData: any,
    // customerlist: [],
    change_customerloding1: any,
    responsedetail: any,
    responseUpdate: any,
    change_customerloding2: any,
    // toggle: any,
    // customerNewDispSet: any,
    // customerReset: any,
    customereditchange: any,
    customeredit: number,
    customerdetail: any,
    edit: number,
    select: number,
    handleSelectCustomer: any,
    handleCustomerreset: any,
    get_rent_m_agents: any,
    select_customer: any,
    rent_m_agents: any,
}


interface State {
    // state types
}








class CustomerInfoDisp extends Component<Props, State> {



    constructor(props: Readonly<Props>) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        this.handleonClick = this.handleonClick.bind(this);
        this.props.get_rent_m_agents()
    }


    age(birthDate: any) {
        birthDate = new Date(birthDate);
        // 文字列に分解
        const y2 = birthDate.getFullYear().toString().padStart(4, '0');
        const m2 = (birthDate.getMonth() + 1).toString().padStart(2, '0');
        const d2 = birthDate.getDate().toString().padStart(2, '0');

        // 今日の日付
        const today = new Date();
        const y1 = today.getFullYear().toString().padStart(4, '0');
        const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
        const d1 = today.getDate().toString().padStart(2, '0');

        // 引き算
        const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
        return age;

    }

    // toggle() {
    //     this.setState(prevState => ({
    //         modal: !prevState.modal
    //     }));
    //     }


    // toggleselect() {
    //     if (this.props.customerno === 0) {
    //         this.props.customerNewDispSet(0)
    //     } else { 
    //     this.setState(prevState => ({
    //         modalselect: !prevState.modalselect
    //     }));
    // }
    // }

    // customerreset() {

    //         this.props.customerReset(this.props.customerno)



    //     this.props.customereditchange(1)

    // }


    handleonClick() {

        if (this.props.customerno === -1) {
            this.props.change_customerloding0(0)
            let customerdetail: { [key: string]: number; } = {};
            customerdetail.customerid = this.props.customerdetail.id;
            customerdetail.no = 0;
            this.props.customerdetailData(customerdetail)
            //this.props.responseUpdate(this.props.responsedetail);
        } else if (this.props.customerno === 1) {
            this.props.change_customerloding1(0)
            this.props.responsedetail.rent_customer_id = this.props.customerdetail.id;
            this.props.responseUpdate(this.props.responsedetail);

        } else if (this.props.customerno === 2) {
            this.props.change_customerloding2(0)
            this.props.responsedetail.rent_customer_id2 = this.props.customerdetail.id;
            this.props.responseUpdate(this.props.responsedetail);

        }
    }
    render() {

        // if (this.props.customerdetail.id > 0) {

        return (
            <div>

                <table>
                    <colgroup style={{ width: "20%" }} />
                    <colgroup style={{ width: "30%" }} />
                    <colgroup style={{ width: "20%" }} />
                    <colgroup style={{ width: "30%" }} />
                    <tbody>
                        <tr >
                            <th>契約形態</th>
                            <td colSpan={3}>
                                {String(this.props.customerdetail.type) === "0" ? "個人" : String(this.props.customerdetail.type) === "1" ? "法人" : ""}
                                {/* {this.props.edit !== 0 ? <Button color="primary" onClick={this.props.handleCustomerreset} className="float-right" size="sm">新規</Button> : ""} */}
                                {this.props.edit !== 0 ? <Button color="success" onClick={() => this.props.customereditchange(1)} className="float-right" style={{ marginRight: '5px' }} size="sm">編集</Button> : ""}
                                {this.props.customeredit !== 0 ? <Button color="success" onClick={() => this.props.select_customer(this.props.customerdetail)} className="float-right" size="sm">この顧客情報を選択</Button> : ""}
                            </td>
                        </tr>
                        <tr>
                            <th>お客様名</th>
                            <td colSpan={3}>
                                <div style={{ fontSize: 9 }}>{this.props.customerdetail.kana1}</div>
                                <span style={{ fontWeight: "bold" }}>{this.props.customerdetail.name1}{this.props.customerdetail.memo ? '　(' + this.props.customerdetail.memo + ')' : ''}</span>
                            </td>

                        </tr>
                        <tr style={{ display: (String(this.props.customerdetail.type) === "1") ? '' : 'none' }}>
                            <th>入居者名</th>
                            <td colSpan={3}>
                                <div style={{ fontSize: 9 }}>{this.props.customerdetail.kana2}</div>
                                {this.props.customerdetail.name2}

                            </td>

                        </tr>
                        <tr style={{ display: (this.props.customerdetail.office_name !== "" && this.props.customerdetail.office_name !== null) ? '' : 'none' }}>
                            <th>勤務先</th>
                            <td colSpan={3}>
                                {this.props.customerdetail.office_name}
                            </td>

                        </tr>

                        <tr style={{ display: this.props.customerdetail.executive ? '' : 'none' }}>
                            <th>代表者名</th>
                            <td colSpan={3}>
                                {this.props.customerdetail.executive}
                            </td>

                        </tr>

                        <tr style={{ display: this.props.customerdetail.rent_m_agent_id ? '' : 'none' }}>
                            <th>代行会社</th>
                            <td colSpan={3}>
                                {this.props.customerdetail.rent_m_agent_id !== 5 ? this.props.rent_m_agents.filter((a: any) => a.id === this.props.customerdetail.rent_m_agent_id)[0] ? this.props.rent_m_agents.filter((a: any) => a.id === this.props.customerdetail.rent_m_agent_id)[0].name : "" : this.props.customerdetail.agent_etc}
                            </td>

                        </tr>



                        <tr style={{ display: (this.props.customerdetail.sex !== null || this.props.customerdetail.birthday !== null) ? '' : 'none' }}>
                            <th>性別</th>
                            <td>{String(this.props.customerdetail.sex) === "1" ? "男" : String(this.props.customerdetail.sex) === "2" ? "女" : ""}</td>
                            <th>生年月日</th>
                            <td>{this.props.customerdetail.birthday != null ? moment(this.props.customerdetail.birthday).format("YYYY/MM/DD") : ""}
                                {this.props.customerdetail.birthday !== null ? "(" + this.age(this.props.customerdetail.birthday) + "歳)" : ""}



                            </td>
                        </tr>
                        <tr style={{ display: (this.props.customerdetail.post !== null && this.props.customerdetail.post !== "") ? '' : 'none' }}>
                            <th>郵便番号</th>
                            <td colSpan={3}>
                                <div className="form-inline">
                                    {this.props.customerdetail.post}
                                </div>
                            </td>

                        </tr>
                        <tr style={{ display: (this.props.customerdetail.add1 !== null && this.props.customerdetail.add1 !== "") ? '' : 'none' }}>
                            <th>住所1</th>
                            <td colSpan={3}>{this.props.customerdetail.add1}
                            </td>
                        </tr>
                        <tr style={{ display: (this.props.customerdetail.add2 !== null && this.props.customerdetail.add2 !== "") ? '' : 'none' }}>
                            <th>住所2</th>
                            <td colSpan={3}>{this.props.customerdetail.add2}
                            </td>
                        </tr>
                        <tr style={{ display: (this.props.customerdetail.tel1 !== null && this.props.customerdetail.tel1 !== "") ? '' : 'none' }}>
                            <th>TEL1</th>
                            <td colSpan={3}>{this.props.customerdetail.tel1}{this.props.customerdetail.tel1_remarks ? ' (' + this.props.customerdetail.tel1_remarks + ')' : ''}</td>
                        </tr>
                        <tr style={{ display: (this.props.customerdetail.tel2 !== null && this.props.customerdetail.tel2 !== "") ? '' : 'none' }}>
                            <th>TEL2</th>
                            <td colSpan={3}>{this.props.customerdetail.tel2}{this.props.customerdetail.tel2_remarks ? ' (' + this.props.customerdetail.tel2_remarks + ')' : ''}</td>
                        </tr>
                        <tr style={{ display: (this.props.customerdetail.fax !== null && this.props.customerdetail.fax !== "") ? '' : 'none' }}>
                            <th>Fax</th>
                            <td colSpan={3}>{this.props.customerdetail.fax}{this.props.customerdetail.fax_remarks ? ' (' + this.props.customerdetail.fax_remarks + ')' : ''}</td>
                        </tr>
                        <tr style={{ display: (this.props.customerdetail.mail1 !== null && this.props.customerdetail.mail1 !== "") ? '' : 'none' }}>
                            <th>メール1</th>
                            <td colSpan={3}>{this.props.customerdetail.mail1}{this.props.customerdetail.mail1_remarks ? ' (' + this.props.customerdetail.mail1_remarks + ')' : ''}</td>
                        </tr>
                        <tr style={{ display: (this.props.customerdetail.mail2 !== null && this.props.customerdetail.mail2 !== "") ? '' : 'none' }}>
                            <th>メール2</th>
                            <td colSpan={3}>{this.props.customerdetail.mail2}{this.props.customerdetail.mail2_remarks ? ' (' + this.props.customerdetail.mail2_remarks + ')' : ''}</td>
                        </tr>
                        <tr style={{ display: (this.props.customerdetail.remarks !== null && this.props.customerdetail.remarks !== "") ? '' : 'none' }}>
                            <th>お客様備考</th>
                            <td colSpan={3}>{this.props.customerdetail.remarks}
                            </td>

                        </tr>
                    </tbody>
                </table>



                {/* <Modal isOpen={this.state.modalselect} toggle={this.toggleselect} size="lg" fade={false}>
                        <CustomerList
                            toggle={this.toggleselect} customerno={this.props.customerno}  id={this.props.id} onSubmit={this.props.customerUpdate}
                        />
                    </Modal> */}




            </div>
        );
        //     } else {
        //     return (
        //         <div>
        //          {/* 入居者登録  <Button color="success" onClick={this.toggleselect} className="float-right" size="sm">選択</Button>
        //             <Modal isOpen={this.state.modalselect} toggle={this.toggleselect} size="lg" fade={false}>
        //                 <CustomerList
        //                     toggle={this.toggleselect} customerno={this.props.customerno} id={this.props.id} onSubmit={this.props.customerUpdate}
        //                 />
        //             </Modal> */}
        //         </div>
        //     );

        // }

    }
}

//containers
const mapStateToProps = (state: any) => {
    return {
        customeredit: state.customersReducer.customeredit,
        // customerdetail: state.customersReducer.customerdetail
        rent_m_agents: state.customersReducer.rent_m_agents,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        //customer1editchange() {
        //    dispatch(customer1editchange())
        //},

        select_customer(state: any) {
            dispatch(Customers.select_customer(state))
        },
        // customerReset(state:any) {
        //     dispatch(Customers.customerReset(state))
        // },
        change_customerloding(state: number) {
            dispatch(Customers.change_customerloding(state))
        },
        customerdetailData(state: any) {
            dispatch(Customers.customerDetail(state))
        },

        get_rent_m_agents(state: any) {
            dispatch(Customers.get_rent_m_agents(state))
        },


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoDisp);
