import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';



import { call, put, takeEvery } from 'redux-saga/effects';
import * as TmSetsubiApi from '../Api/TmSetsubiApi';
import * as SearchRoomsApi from '../Api/SearchRoomsApi';
import * as TRoomSetsubiApi from '../Api/TRoomSetsubiApi';
import * as TTatemonoImgApi from '../Api/TTatemonoImgApi';
import * as TRoomImgApi from '../Api/TRoomImgApi';
import * as TChinryouApi from '../Api/TChinryouApi';
import * as RoomWebCampaignApi from '../Api/RoomWebCampaignApi';

export interface RoomsState {
    room_setsubi: any;
    setsubi_master: any;
    rooms: any;
    room_details: any;
    room_campaign: any;
    response_rooms: any;
    rooms_tatemono_image_list: any;
    rooms_room_image_list: any;
    rooms_chinryou_list: any;
}

const initialState: RoomsState = {
    room_setsubi: [],
    setsubi_master: [],
    rooms: [],
    room_details: [],
    room_campaign: [],
    response_rooms: [],
    // rentAgents: [],
    // rentMReasons: [],
    rooms_tatemono_image_list: [],
    rooms_room_image_list: [],
    rooms_chinryou_list: [],


}



///action 
const actionCreator = actionCreatorFactory();

export const get_setsubi_master = actionCreator('GET_SETSUBI_MASTER');
export const get_setsubi_master_success = actionCreator("GET_SETSUBI_MASTER_SUCCESS");
export const get_setsubi_master_failure = actionCreator("GET_SETSUBI_MASTER_FAILURE");

export const get_rooms = actionCreator('GET_ROOMS');
export const get_rooms_success = actionCreator("GET_ROOMS_SUCCESS");
export const get_rooms_failure = actionCreator("GET_ROOMS_FAILURE");


export const get_response_rooms = actionCreator<any>('GET_RESPONSE_ROOMS');
export const get_response_rooms_success = actionCreator<any>("GET_RESPONSE_ROOMS_SUCCESS");


export const get_room_details = actionCreator('GET_ROOM_DETAILS');
export const get_room_details_success = actionCreator<any>("GET_ROOM_DETAILS_SUCCESS");
export const get_room_details_failure = actionCreator("GET_ROOM_DETAILS_FAILURE");

export const get_room_campaign = actionCreator('GET_ROOM_CAMPAIGN');
export const change_room_campaign = actionCreator('CHANGE_ROOM_CAMPAIGN');

export const get_room_setsubi = actionCreator('GET_ROOM_SETSUBI');
export const change_room_setsubi = actionCreator('CHANGE_ROOM_SETSUBI');

export const get_rooms_tatemono_image_list = actionCreator('GET_ROOMS_TATEMONO_IMAGE_LIST');
export const change_rooms_tatemono_image_list = actionCreator('CHANGE_ROOMS_TATEMONO_IMAGE_LIST');

export const get_rooms_room_image_list = actionCreator('GET_ROOMS_ROOM_IMAGE_LIST');
export const change_rooms_room_image_list = actionCreator('CHANGE_ROOMS_ROOM_IMAGE_LIST');

export const get_rooms_chinryou_list = actionCreator('GET_ROOMS_CHINRYOU_LIST');
export const change_rooms_chinryou_list = actionCreator('CHANGE_ROOMS_CHINRYOU_LIST');

//  const getUsers = actionCreator('USERS_REQUEST');
// export const getUsersSuccess = actionCreator("USERS_SUCCESS");
// export const getUsersFailure = actionCreator("USERS_FAILURE");

// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const roomsReducer = reducerWithInitialState(initialState)
    .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))
    .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))
    .case(change_room_campaign, (state, payload) => ({ ...state, room_campaign: payload }))

    .case(get_response_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

    .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }))

    .case(change_room_setsubi, (state, payload) => ({ ...state, room_setsubi: payload }))

    .case(change_rooms_tatemono_image_list, (state, payload) => ({ ...state, rooms_tatemono_image_list: payload }))
    .case(change_rooms_room_image_list, (state, payload) => ({ ...state, rooms_room_image_list: payload }))
    .case(change_rooms_chinryou_list, (state, payload) => ({ ...state, rooms_chinryou_list: payload }))
// .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const roomsSaga = [takeEvery('GET_SETSUBI_MASTER', handleRequestSetsubiMaster),
takeEvery('GET_ROOMS', handleGetRoomsRequest),
takeEvery('GET_ROOM_DETAILS', handleGetRoomDetailsRequest),
takeEvery('GET_ROOM_CAMPAIGN', handle_get_room_campaign),
takeEvery('GET_RESPONSE_ROOMS', handle_get_response_rooms),

takeEvery('GET_ROOM_SETSUBI', handle_get_room_setsubi),

takeEvery('GET_ROOMS_TATEMONO_IMAGE_LIST', handle_get_rooms_tatemono_image_list),
takeEvery('GET_ROOMS_ROOM_IMAGE_LIST', handle_get_rooms_room_image_list),
takeEvery('GET_ROOMS_CHINRYOU_LIST', handle_get_rooms_chinryou_list),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handleRequestSetsubiMaster(action: any) {

    const { payload, error } = yield call(TmSetsubiApi.get_setsubi, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_setsubi_master_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handleGetRoomsRequest(action: any) {

    const { payload, error } = yield call(SearchRoomsApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(get_rooms_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handleGetRoomDetailsRequest(action: any) {
    yield put(get_room_details_success([]));
    const { payload, error } = yield call(SearchRoomsApi.detail, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        console.log(payload)

        yield put(get_room_details_success(payload));

    } else {
        alert("エラー")
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_room_campaign(action: any) {

    const { payload, error } = yield call(RoomWebCampaignApi.detail, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(change_room_campaign(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_get_response_rooms(action: any) {

    const { payload, error } = yield call(SearchRoomsApi.response_list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(get_response_rooms_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_room_setsubi(action: any) {

    const { payload, error } = yield call(TRoomSetsubiApi.get_setsubi, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(change_room_setsubi(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_rooms_tatemono_image_list(action: any) {

    const { payload, error } = yield call(TTatemonoImgApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(change_rooms_tatemono_image_list(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_rooms_room_image_list(action: any) {

    const { payload, error } = yield call(TRoomImgApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(change_rooms_room_image_list(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_rooms_chinryou_list(action: any) {

    const { payload, error } = yield call(TChinryouApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(change_rooms_chinryou_list(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}