import React, { Component } from 'react';
import LoadingComponent from '../Layout/LoadingComponent';
import { connect } from 'react-redux';
// import * as Sales from './Saga/Sales';
import '../../Css/Grid.css';
import { Editors } from "react-data-grid-addons";

const ReactDataGrid = require('react-data-grid');



interface Props {


    rent_sales_list: any,
    rent_m_sale_accounts: any,
    onGridRowsUpdated: any,

}


interface State {
    columns: any,
    refresh: any
    // state types
}







// const style = {
//     border: "solid #000",
//     height: "200"
// }
//<span type="button" className="btn btn-primary" target="_blank" href={`/tradedetails/` + props.row.id}>詳細</span>
// const DetailLinkFormatter = ({value:any, ...props }) => {

//     if (props.row.flg === 3) {
//         return <a type="button" rel="noreferrer" className="btn btn-primary" target="_blank" href={`/customer/` + props.row.id}>詳細</a>;
//     } else {
//         return value;
//     }
//     //return <ProgressBar now={value} label={`${value}%`} />;
// };

const { DropDownEditor } = Editors;
const issueTypes = [
    { id: "bug", title: "", value: "Bug", text: "" },
    { id: "epic", title: "", value: "Epic", text: "" },
    { id: "story", title: "", value: "Story", text: "" }
];








class SalesGridComponent extends Component<Props, State>{
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            refresh: false,
            // rows:this.props.customerlist,
            columns: [

                { key: 'rentMSaleAccountName', name: '項目名', resizable: true, editor: this.IssueTypeEditor },
                {
                    key: 'amount', name: '売上額', width: 100, resizable: true, editable: true
                },
                { key: 'remarks', name: '備考', resizable: true, editable: true },
            ]
        };
        //         this.getRowCount = this.getRowCount.bind(this);
        // this.refresh = this.refresh.bind(this);
        // this.refresh()
    }


    IssueTypeEditor = <DropDownEditor options={this.props.rent_m_sale_accounts} />;
    componentWillMount() {
        // this.props.customerList()

    }


    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.rent_sales_list.length === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", backgroundColor: "#ddd", height: 200, padding: "50px" }} > <h3>{message}</h3></div>

        );
    };
    //   getRowCount() {
    //     let count = this.props.rent_sales_list.length;
    //     if(this.state.refresh && count > 0) {
    //       count--; // hack for update data-grid
    //       this.setState({
    //         refresh: false
    //       });
    //     }

    //     return count;
    //   }
    //   refresh() {
    //     this.setState({
    //       refresh: true
    //     });
    //   }
    render() {


        return (
            <div>
                {}
                <ReactDataGrid

                    enableCellSelect={true}
                    columns={this.state.columns}

                    rowGetter={(i: number) => this.props.rent_sales_list[i]}
                    rowsCount={this.props.rent_sales_list.length}
                    //rowsCount={this.props.customerlist.length}
                    onGridRowsUpdated={this.props.onGridRowsUpdated}
                    headerRenderer={true}
                    dragable={true}
                    emptyRowsView={this.EmptyRowsView}
                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    // onRowClick={this.onRowClick}
                    // onGridRowsUpdated={this.onGridRowsUpdated}
                    //onCellSelected={this.getSelectedCell}
                    minHeight={300} />
            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        // rent_sales_list: state.sales.rent_sales_list,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        // customerList(state:any) {
        //     dispatch(Customers.customerList(state))
        // }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesGridComponent);