// import * as React from 'react';
import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


// import Board from './Board';

const graph_type_list = [
    {id: 1, sub: true, name: "横棒グラフ"}, 
    {id: 2, sub: true, name: "縦棒グラフ"}, 
    {id: 3, sub: false, name: "円グラフ"}, 
    {id: 4, sub: false, name: "折れ線グラフ"}, 
    {id: 5, sub: false, name: "クロス集計表"}, 
    {id: 6, sub: false, name: "表"}, 
    {id: 7, sub: false, name: "面グラフ"}, 
    {id: 8, sub: false, name: "曲線グラフ"}, 
    {id: 9, sub: true, name: "曲線面グラフ"}, 
]
const graph_type_sub_list = [
    {id: 1, name: "集合"}, 
    {id: 2, name: "積み上げ"}, 
    {id: 3, name: "100%積み上げ"}, 
]

const class_type_list = [
    {id: 1, sub: false, name: "案内経路"}, 
    {id: 2, sub: false, name: "提案内容"}, 
    {id: 3, sub: false, name: "売上"}, 
    {id: 4, sub: true, name: "売上日"}, 
    {id: 5, true: false, name: "粗利計上月"}, 

]
const class_type_sub_list = [
    {id: 1, name: "年単位"}, 
    {id: 2, name: "四半期単位"}, 
    {id: 3, name: "月単位"}, 
    {id: 4, name: "週単位"}, 
    {id: 5, name: "1時間単位"}, 
    {id: 6, name: "分単位"}, 
]


function App(props) {

    // 登録ID
    const [graph_id, setGraphId] = useState(0);
    

    // 選択グラフ
    const [graph_type, setGraphType] = useState(1);
    const [graph_type_sub, setGraphTypeSub] = useState(1);

    // 選択クラス　大中小
    const [class_type1, setClassType1] = useState({id: 0, disp:1, sort:0, class_type_id: 0, class_type_sub_id: 0});
    const [class_type2, setClassType2] = useState({id: 0, disp:0, sort:0, class_type_id: 0, class_type_sub_id: 0});
    const [class_type3, setClassType3] = useState({id: 0, disp:0, sort:0, class_type_id: 0, class_type_sub_id: 0});

    const handleOpen = async(id) => {

        setGraphId(id)

        // setCreateDialogOpen(true)

    };

    const handleClose = async(id) => {

        setGraphId(0)

        // setCreateDialogOpen(false)

    };

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card style={{marginBottom:5}}>
                        <CardHeader title="グラフの種類"></CardHeader>
                        <CardContent>
                            {graph_type_list.length ? <Autocomplete
                            options={graph_type_list}
                            style={{width:200, float: "left"}}
                            size={"small"}
                            getOptionLabel={(option) => option.name}
                            value={graph_type_list.filter(a=>a.id == graph_type)[0]}
                            onChange={(e, newValue) => {
                                setGraphType(newValue ? newValue.id : 0);
                            }}
                            renderInput={(params) => <TextField
                                {...params} label="グラフの種類" />}

                            /> : ""}

                            {graph_type_list.filter(a=>a.id == graph_type)[0].sub}
                            {graph_type_list.length && graph_type > 0 && graph_type_list.filter(a=>a.id == graph_type)[0].sub ? <Select

                            style={{ width: 200, float: "left" }}
                            name="graph_type_sub"
                            value={graph_type_sub}
                            onChange={(e)=> {setGraphTypeSub(e.target.value)}}
                            label="タイプ"
                            size="small"
                            margin='dense'
                        >
                            {graph_type_sub_list.map(function (value) {
                            return <MenuItem value={value.id}>{value.name}</MenuItem>
                            })}

                        </Select> : ""}
                        </CardContent>
                    </Card>
                    <Card style={{marginBottom:5}}>
                        <CardHeader title="分類する項目"></CardHeader>
                        <CardContent>
                            {class_type_list.length && class_type1.disp ? <Autocomplete
                            options={class_type_list}
                            style={{width:200, float: "left"}}
                            size={"small"}
                            getOptionLabel={(option) => option.name}
                            value={class_type_list.filter(a=>a.id == class_type1.class_type_id)[0]}
                            onChange={(e, newValue)=> {
                                let class_type = class_type1
                                class_type.class_type_id = newValue.id
                                setClassType1({id: class_type.id, disp: class_type.disp, sort: class_type.sort, class_type_id: class_type.class_type_id, class_type_sub_id: class_type.class_type_sub_id})
                            }}

                                
                                

                            renderInput={(params) => <TextField
                                {...params} label="項目" />}

                            /> : ""}
                            {class_type1.class_type_id}aa
                            {class_type1.class_type_id > 0 ? class_type_list.filter(a=>a.id == class_type1.class_type_id)[0].sub ? "true" :"false" : ""}

                            {class_type_list.length && class_type1.class_type_id > 0 && class_type_list.filter(a=>a.id == class_type1.class_type_id)[0].sub ? <Select

                            style={{ width: 200, float: "left" }}
                            name="class_type_sub_id"
                            value={class_type1.class_type_sub_id}
                            // onChange={(e)=> {
                            //     let class_type = class_type1
                            //     class_type.class_type_sub_id = e.target.value
                            //     setClassType1(class_type)}}
                            label="タイプ"
                            size="small"
                            margin='dense'
                        >
                            {class_type_sub_list.map(function (value) {
                            return <MenuItem value={value.id}>{value.name}</MenuItem>
                            })}

                        </Select> : ""}
                        </CardContent>
                    </Card>
                    <Card style={{marginBottom:5}}>
                        <CardHeader title="集計方法"></CardHeader>
                    </Card>
                    <Card style={{marginBottom:5}}><CardHeader title="条件"></CardHeader></Card>
                    <Card style={{marginBottom:5}}><CardHeader title="ソート"></CardHeader></Card>

                </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>
                
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
      user_details: state.masterReducer.user_details,
      users_select: state.masterReducer.users_select,
    }
  }

export default connect(mapStateToProps)(App);