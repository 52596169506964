import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';


import * as Contracts from './Saga/Contracts';

import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import * as Responses from '../ResponseDetail/Saga/Responses';

import Select from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import MaskedInput from 'react-text-mask';
var moment = require('moment-timezone');


const phoneNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];


const mobileNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
function TelMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" ? mobileNumberMask : phoneNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.t_parking_keiyaku_list[rowIndex][column.key]}
  </Cell>;

const TatemonoName = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.t_parking_keiyaku_list[rowIndex].selectcontract ? props.rent_contract_details.tatemono_name : ""}
  </Cell>;
const RoomName = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.t_parking_keiyaku_list[rowIndex].selectcontract ? props.rent_contract_details.room_name : ""}
  </Cell>;

const ContractDate = ({ column, props, rowIndex }) => <Cell style={{ whiteSpace: "nowrap" }} >
  {props.t_parking_keiyaku_list[rowIndex].selectcontract ? moment(props.rent_contract_details.contract_date).format('YYYY/MM/DD') : ""
  }

</Cell>;

const Sex = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      style={{ width: 30 }}
      name="sex"
      value={props.rent_contract_editor_forms.family[rowIndex][column.key] ? props.rent_contract_editor_forms.family[rowIndex][column.key] : 1}
    // value={props.rent_contract_editor_forms.family[rowIndex][column.key]}
    // defaultValue={this.props.rent_contract_editor_forms.room_id ? { value: this.props.rent_contract_editor_forms.room_id, label: this.props.rent_contract_editor_forms.room_name } : {}}
    // value={this.props.rent_contract_editor_forms.room_id}
    // onChange={this.handleChange}
    // label="部屋名"
    >
      <MenuItem value={1}>
        男
                </MenuItem>
      <MenuItem value={2}>
        女
                </MenuItem>
    </Select> : props.rent_contract_editor_forms.family[rowIndex][column.key] === 1 ? "男" : "女"}
  </Cell>;

const Birthday = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <TextField
      id="date"
      // label="生年月日"
      type="date"
      name="birthday"
      style={{ width: 130 }}
      // onChange={this.handleChange}
      value={props.rent_contract_editor_forms.family[rowIndex][column.key]}
      // className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
    /> :
      props.rent_contract_editor_forms.family[rowIndex][column.key] ? <div style={{ lineHeight: 1 }}> <div>{moment(props.rent_contract_editor_forms.family[rowIndex][column.key]).format("YYYY年M月D日")}</div>  <div>{"(" + age(props.rent_contract_editor_forms.family[rowIndex][column.key]) + "歳)"}</div> </div> : ""}
  </Cell >


function age(birthDate) {
  birthDate = new Date(birthDate);
  // 文字列に分解
  const y2 = birthDate.getFullYear().toString().padStart(4, '0');
  const m2 = (birthDate.getMonth() + 1).toString().padStart(2, '0');
  const d2 = birthDate.getDate().toString().padStart(2, '0');

  // 今日の日付
  const today = new Date();
  const y1 = today.getFullYear().toString().padStart(4, '0');
  const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
  const d1 = today.getDate().toString().padStart(2, '0');

  // 引き算
  const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
  return age;

}

const Color = ({ column, props, rowIndex, change_coloumn }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.t_parking_keiyaku_list[rowIndex].selectcontract ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="color"
      value={props.t_parking_keiyaku_list[rowIndex][column.key]}
      onChange={(e) => change_coloumn({ row: rowIndex, name: column.key, value: e.target.value })}
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      // value={monies[rowIndex][column.key]}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.t_parking_keiyaku_list[rowIndex][column.key]}

  </Cell>;

const Text = ({ column, props, rowIndex, change_coloumn }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.t_parking_keiyaku_list[rowIndex].selectcontract ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="color"
      value={props.t_parking_keiyaku_list[rowIndex][column.key]}
      onChange={(e) => change_coloumn({ row: rowIndex, name: column.key, value: e.target.value })}
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      // value={monies[rowIndex][column.key]}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.t_parking_keiyaku_list[rowIndex][column.key]}

  </Cell>;

const Workplace = ({ column, props, rowIndex, family }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="workplace"
      // onChange={this.handleChange}
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      // value={monies[rowIndex][column.key]}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.rent_contract_editor_forms.family[rowIndex].workplace}

  </Cell>;

const Tel = ({ column, props, rowIndex, family }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ?
      <TextField
        // variant="outlined"
        name="tel"
        // label="電話番号1"
        type="tel"
        value={props.rent_contract_editor_forms.family[rowIndex].tel}
        // onChange={this.handleChange}
        id="formatted-numberformat-input"
        style={{ float: "left", width: "100%", paddingRight: "5px" }}

        InputProps={{
          inputComponent: TelMaskCustom,
        }}
      // InputLabelProps={{
      //   shrink: true,
      // }}
      />


      //   <TextField
      //   id="outlined-basic"
      //   //   className={classes.textField}
      //   label=""
      //   margin="normal"
      //   name="tel"
      //   // onChange={this.handleChange}
      //   inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      //   // value={monies[rowIndex][column.key]}
      //   style={{ width: "80px", marginTop: 0 }}

      // />
      : props.rent_contract_editor_forms.family[rowIndex].tel}

  </Cell>;

const Nationality = ({ column, props, rowIndex, family }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="nationality"
      // onChange={this.handleChange}
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      // value={monies[rowIndex][column.key]}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.rent_contract_editor_forms.family[rowIndex].nationality}

  </Cell>;

const Remarks = ({ column, props, rowIndex, family }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="remarks"
      // onChange={this.handleChange}
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      // value={monies[rowIndex][column.key]}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.rent_contract_editor_forms.family[rowIndex].remarks}

  </Cell>;

const Name = ({ column, props, rowIndex, family }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {/* <FormControl variant="outlined" style={{ marginTop: 25 }} > */}
    {/* <InputLabel id="demo-simple-select-outlined-label">部屋名</InputLabel> */}
    {props.t_parking_keiyaku_list[rowIndex].selectcontract ? (props.rent_contract_details.name2 ? <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      style={{ width: 120 }}
      name="name"
    // defaultValue={this.props.rent_contract_editor_forms.room_id ? { value: this.props.rent_contract_editor_forms.room_id, label: this.props.rent_contract_editor_forms.room_name } : {}}
    // value={this.props.rent_contract_editor_forms.room_id}
    // onChange={this.handleChange}
    // label=""
    >
      <MenuItem value="">
        {props.rent_contract_details.name1}
      </MenuItem>
      <MenuItem value="">
        {props.rent_contract_details.name2}
      </MenuItem>

    </Select> : props.rent_contract_details.name1) : ""}
    {/* </FormControl> */}
  </Cell>;

const SelectContract = ({ column, props, rowIndex, change_coloumn }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.t_parking_keiyaku_list[rowIndex].kuushitsu_joukyou_no === 1 || 
    (props.rent_contract_details.parkings && props.rent_contract_details.parkings.filter(a=>a.p_id == props.t_parking_keiyaku_list[rowIndex].p_id && a.pa_id == props.t_parking_keiyaku_list[rowIndex].pa_id).length > 0) ? 
      (props.t_parking_keiyaku_list[rowIndex].selectcontract ? 
        <Tooltip title="申込" >
          <IconButton size="small" aria-label="delete" onClick={() => change_coloumn({ row: rowIndex, name: "selectcontract", value: !props.t_parking_keiyaku_list[rowIndex].selectcontract })}>
            <CheckCircleIcon color="primary" />
          </IconButton>
        </Tooltip>
        : 
        <Tooltip title="申込" >
          <IconButton size="small" aria-label="delete" onClick={() => change_coloumn({ row: rowIndex, name: "selectcontract", value: !props.t_parking_keiyaku_list[rowIndex].selectcontract })}>
            <IndeterminateCheckBoxIcon color="secondary" />
          </IconButton>
        </Tooltip>) 
    : 
    ""}
  </Cell>;

const Nyuukyo = ({ column, props, rowIndex, monies }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.t_parking_keiyaku_list[rowIndex].nyuukyo_kanou_year + '/' + props.t_parking_keiyaku_list[rowIndex].nyuukyo_kanou_month + '/' + props.t_parking_keiyaku_list[rowIndex].nyuukyo_kanou_day}
  </Cell>;




var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns = [
  { key: 'selectcontract', name: '契約', width: 50 },
  { key: 'p_name', name: '駐車場名', width: 200 },
  {
    key: 'pa_name', name: '区画番号', width: 80,

  },
  { key: 'kuushitsu_joukyou_name', name: '募集状況', width: 100 },
  { key: 'nyuukyo_kanou', name: '入居可能日', width: 90 },
  { key: 'tatemono_name', name: '建物名', width: 110 },
  { key: 'room_name', name: '部屋名', width: 50 },
  { key: 'name', name: '契約者名', width: 90 },
  { key: 'contract_date', name: '契約日', width: 90 },

  { key: 'color', name: '色', width: 80 },
  { key: 'bango', name: '番号', width: 100 },
  { key: 'maker', name: 'メーカー', width: 100 },
  { key: 'syasyu', name: '車種', width: 100 },
  { key: 'remarks', name: '備考', width: 100 },
]







class CustomerList extends Component {
  constructor(props) {
    super(props);


    // this.props.rent_m_contract_follows_disp.map(async function (value) {
    //   if (value.disp) {
    //     columns.push({ key: value.key_name, name: value.name, company: value.company, width: 80 })
    //   }
    // })
    console.log(columns)

    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},

      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };
    // this.props.get_contract_follow_list()
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

    this.props.get_t_parking_keiyaku_list()

    this.add_new = this.add_new.bind(this);

    this.change_coloumn = this.change_coloumn.bind(this);


  }





  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }



  add_new() {
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    // let family = rent_contract_editor_forms.family.map(function (value) {
    //   return value
    // })

    rent_contract_editor_forms.family.push({
      name: "",
      relations: "",
      sex: 0,
      birthday: null,
      workplace: '',
      tel: '',
      nationality: '',
      remarks: '',
    })
    // rent_contract_editor_forms.family = family
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms)

    this.setState({ reflash: !this.state.reflash })
  }





  change_coloumn(set) {
    let t_parking_keiyaku_list = this.props.t_parking_keiyaku_list
    // let family = rent_contract_editor_forms.family.map(function (value) {
    //   return value
    // })

    t_parking_keiyaku_list[set.row][set.name] = set.value

    // rent_contract_editor_forms.family = family
    this.props.change_t_parking_keiyaku_list(t_parking_keiyaku_list)

    this.setState({ reflash: !this.state.reflash })
  }


  render() {
    let hiwari = []

    let paringimg_p_id = ""
    let paringimg = []
    this.props.t_parking_keiyaku_list.map(function (value) {

      if (value.p_id === paringimg_p_id) {

      } else {
        paringimg_p_id = value.p_id

        paringimg.push(value)
      }

    })
    console.log(paringimg)
    return (
      <div id="contract_car" >



        <Grid container spacing={2}>

          {paringimg.map(function (value) {
            return <Grid xs={6}>
              <Card style={{ margin: 5 }}>
                <CardContent>
                  {/* <Col lg="12" className="item-outer" > */}
                  <div style={{ fontSize: 18, fontWeight: "bold" }}> {value.p_name}</div>
                  <img src={"https://img.global-center.co.jp/v1/parking/" + value.p_id + "/3/1/400/400/0"} alt="図面" />

                </CardContent>
              </Card >
            </Grid>
          })}


          < Grid xs={12}>

            {<div style={{ marginTop: 10, marginBottom: 10 }}><Table
              rowHeight={40}
              rowsCount={this.props.t_parking_keiyaku_list.length}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
              touchScrollEnabled={true}
              width={window.innerWidth - 200}
              height={this.props.t_parking_keiyaku_list.length * 40 + 60}
              // width={window.innerWidth - 110}
              // height={window.innerHeight - 250}
              headerHeight={40}>
              {this.state.columns.map(function (value, i) {
                return <Column
                  columnKey={value.key}
                  header={<SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={this.state.colSortDirs[value.key]}
                    style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                  // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                  cell={({ rowIndex, ...props }) => (

                    value.key === "selectcontract" ? <SelectContract column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></SelectContract>
                      : value.key === "color" ? <Color column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Color>
                        : value.key === "bango" ? <Text column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Text>

                          : value.key === "maker" ? <Text column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Text>

                            : value.key === "syasyu" ? <Text column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Text>

                              : value.key === "remarks" ? <Text column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Text>

                                : value.key === "nyuukyo_kanou" ? <Nyuukyo column={value} props={this.props} rowIndex={rowIndex} ></Nyuukyo>

                                  : value.key === "name" ? <Name column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Name>
                                    : value.key === "tatemono_name" ? <TatemonoName column={value} props={this.props} rowIndex={rowIndex}></TatemonoName>
                                      : value.key === "room_name" ? <RoomName column={value} props={this.props} rowIndex={rowIndex}></RoomName>
                                        : value.key === "contract_date" ? <ContractDate column={value} props={this.props} rowIndex={rowIndex}></ContractDate>



                                          //     : value.key === "relations" ? <Relations column={value} props={this.props} rowIndex={rowIndex} ></Relations>

                                          //       : value.key === "name" ? <Name column={value} props={this.props} rowIndex={rowIndex} ></Name>
                                          //         : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Delete>

                                          //           : value.key === "workplace" ? <Workplace column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Workplace>
                                          //             : value.key === "tel" ? <Tel column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Tel>
                                          //               : value.key === "nationality" ? <Nationality column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Nationality>
                                          //                 : value.key === "remarks" ? <Remarks column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Remarks>


                                          :
                                          <Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

                  )}

                  width={value.width}
                  isResizable={true}


                />
              }, this)}

            </Table > </div>
            }


          </Grid>
        </Grid>
      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_family_editor_disp: state.contracts.rent_contract_family_editor_disp,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
    t_parking_keiyaku_list: state.contracts.t_parking_keiyaku_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {


    change_responseid(state) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state) {
      dispatch(Responses.change_modalResponseDetail(state))
    },

    change_rent_contract_editor_forms(state) {
      dispatch(Contracts.change_rent_contract_editor_forms(state))
    },
    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    get_t_parking_keiyaku_list(state) {
      dispatch(Contracts.get_t_parking_keiyaku_list(state))
    },
    change_t_parking_keiyaku_list(state) {
      dispatch(Contracts.change_t_parking_keiyaku_list(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);