import React, { Component } from 'react';

import { connect } from 'react-redux';

import LifeLineEdit from './LifeLineEditComponent';
import LifeLineDisp from './LifeLineDispComponent';
import Loading from '../Layout/LoadingComponent';

import * as Rooms from './Saga/Rooms';




class LifeLineComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lifelineedit : 0,
            mode: '1',
            //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
        };
   

    }


    render() {
       
    return (
        <div>
            {this.state.lifelineedit === 0 ? <LifeLineDisp roomdetail={this.props.roomdetail} lifelineeditchange={this.props.lifelineeditchange} /> : this.props.roomdetail.tatemonoId ? <LifeLineEdit roomdetail={this.props.roomdetail} closed={1} lifelineeditchange={this.props.lifelineeditchange} />:<Loading /> }
      　</div>
 
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        tatemonoedit: state.roomsReducer.tatemonoedit,


    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        get_room_details(state) {
            dispatch(Rooms.get_room_details(state))
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LifeLineComponent);
