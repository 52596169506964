import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as CustomerContacts from './Saga/CustomerContacts';
import SelectM from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
// import './Css/Contracts.css';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

// const selectStyles = {
//   menu: base => ({
//     ...base,
//     zIndex: 0
//   })
// };


// const RenderSelectInput = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="店舗を選択"
//   />
// )

// const RenderSelectUsers = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="追客担当を選択"
//   />
// )

let timer

class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,
      reflash: false,
      ownshop_operation: true,
      ownshop_contract: true,
      focus_pet: false
    };


    // this.props.change_contract_follow_list_form(formData);

    // this.handleChangeSectionId = this.handleChangeSectionId.bind(this);


    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.handle_search_tatemono = this.handle_search_tatemono.bind(this);
   

    // visit_editor_forms.id=this.props.
    // this.change_tatemono = this.change_tatemono.bind(this);
    let visit_editor_forms = this.props.visit_editor_forms
    let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms
    let rent_customer_contacts_calendar_forms = this.props.rent_customer_contacts_calendar_forms
    // visit_editor_forms.rent_response_id = rent_customer_contacts_forms.rent_response_id
    visit_editor_forms.rent_response_id = this.props.fromMapOpen === 3 ? rent_customer_contacts_calendar_forms.rent_response_id : rent_customer_contacts_forms.rent_response_id
    this.props.change_visit_editor_forms(visit_editor_forms);
  }


  // change_tatemono(e, value) {
  //   // console.log(value)
  //   let visit_editor_forms = this.props.visit_editor_forms


  //   if (value) {
  //     visit_editor_forms.tatemono_id = value.value
  //     visit_editor_forms.tatemono_name = value.label
  //     visit_editor_forms.tatemono_kana = this.props.tm_tatemono_list.filter(a => a.tatemono_id === value.value)[0] ? this.props.tm_tatemono_list.filter(a => a.tatemono_id === value.value)[0].tatemono_kana : ""

  //   } else {
  //     visit_editor_forms.tatemono_id = 0
  //     visit_editor_forms.tatemono_name = ''
  //     visit_editor_forms.tatemono_kana = ''
  //   }
  //   this.props.change_visit_editor_forms(visit_editor_forms);
  // }

  // handleChangeSectionId(e, newValue) {


  //   let contract_follow_list_form = this.props.contract_follow_list_form
  //   contract_follow_list_form.section_id = newValue ? newValue.value : 0
  //   this.props.change_contract_follow_list_form(contract_follow_list_form);
  //   this.props.get_contract_follow_list()
  // }


  handleChange = (event) => {

    // alert(event.target.name)
    let visit_editor_forms = this.props.visit_editor_forms

    visit_editor_forms[event.target.name] = event.target.value;


    if (event.target.name === "room_id") {
      if (this.props.customer_contacts_tm_room_list.filter(a => a.room_id === event.target.value)[0]) {
        let room_name = this.props.customer_contacts_tm_room_list.filter(a => a.room_id === event.target.value)[0].room_name
        visit_editor_forms.room_name = room_name;
      } else {
        visit_editor_forms.room_name = "";
      }
    }


    this.props.change_visit_editor_forms({});
    this.props.change_visit_editor_forms(visit_editor_forms);
    // if (event.target.name === "condition") {


    // }
    this.setState({ reflash: !this.state.reflash });
  }


  handleSelectChange = (event, value) => {
    // console.log(event.target)
    console.log(value)
    let visit_editor_forms = this.props.visit_editor_forms

    if (value && value.name) {
      visit_editor_forms[value.name] = value.value;



      if (value.name === "tatemono_id") {
        visit_editor_forms.tatemono_name = value.label;
        visit_editor_forms.room_name = '';
        visit_editor_forms.room_id = -1;
        this.props.get_customer_contacts_tm_room_list(value.value)

      }

      this.props.change_visit_editor_forms({});
      this.props.change_visit_editor_forms(visit_editor_forms);

      this.setState({ reflash: !this.state.reflash });
    }
  }








  handle_search_tatemono(e) {

    if (e.target.value) {
      let name = e.target.value
      clearTimeout(timer);
      timer = setTimeout(() => {

        this.props.get_customer_contacts_tm_tatemono_list(name)
      }, 400);
    }
    // }
    // this.setState({ reflash: !this.state.reflash });

  }






  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")

    // this.props.get_contract_follow_list({ section_id: this.props.user_details.amSectionId })
  }



  saveSelectValue = (e, id) => {
    let data = {}
    data.id = id;
    data.value = e.target.value

    this.props.saveSelectValue(data)
  }

  // handleSectionIdChange(sectionId) {
  //   alert()
  //   let formData =   this.state.formData
  //   formData.sectionId=sectionId
  //     this.setState(formData);



  toggle_select_comp() {
    this.setState({ modal_select_comp: !this.state.modal_select_comp })
  }



  render() {

    const { handleSubmit } = this.props;


    return (

      <Card style={{ margin: 5 }}>
        <CardContent>


          <Grid container>



            <Grid xs={8} style={{ padding: 5 }}>

              <Autocomplete
                id="tatemono_id"
                // options={this.props.users_select}
                options={this.props.customer_contacts_tm_tatemono_list.length === 0 && this.props.visit_editor_forms.tatemono_id ? [{ value: this.props.visit_editor_forms.tatemono_id, label: this.props.visit_editor_forms.tatemono_name, name: "tatemono_id" }]
                  : this.props.customer_contacts_tm_tatemono_list.map((value) => ({
                    value: value.tatemono_id,
                    label: value.tatemono_name ? value.tatemono_name + (value.tatemono_kana ? "(" + value.tatemono_kana + ")" : "") : "",
                    name: "tatemono_id"
                  }))}
                getOptionLabel={(option) => option.label}
                // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
                // value={this.props.rent_customer_editor_froms.name1}
                style={{ width: "100%", marginTop: 20 }}
                name="tatemono_id"
                loading={this.props.customer_contacts_tm_tatemono_list_loading}
                defaultValue={this.props.visit_editor_forms.tatemono_id ? { value: this.props.visit_editor_forms.tatemono_id, label: this.props.visit_editor_forms.tatemono_name + (this.props.visit_editor_forms.tatemono_kana ? "(" + this.props.visit_editor_forms.tatemono_kana + ")" : "") } : {}}
                // open={false}
                filterSelectedOptions={false}

                // value={this.props.contract_follow_list_form.contract_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.contract_user_id)[0] : null}
                onChange={this.handleSelectChange}
                renderInput={(params) => <TextField
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>

                        {this.props.customer_contacts_tm_tatemono_list_loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}

                  onChange={this.handle_search_tatemono}
                  label={"物件名:" + (this.props.visit_editor_forms.tatemono_kana ? this.props.visit_editor_forms.tatemono_kana : "")} />}

              />



            </Grid>


            <Grid xs={4} >

              <FormControl variant="outlined" style={{ marginTop: 25 }} >
                <InputLabel id="demo-simple-select-outlined-label">部屋名</InputLabel>
                <SelectM
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={{ width: 120 }}
                  name="room_id"
                  // defaultValue={this.props.rent_contract_editor_forms.room_id ? { value: this.props.rent_contract_editor_forms.room_id, label: this.props.rent_contract_editor_forms.room_name } : {}}
                  value={this.props.visit_editor_forms.room_id}
                  onChange={this.handleChange}
                  label={this.props.visit_editor_forms.tatemono_id && this.props.customer_contacts_tm_room_list.length === 0 ? "検索中" : "部屋名"}
                  disabled={!this.props.visit_editor_forms.tatemono_id || this.props.customer_contacts_tm_room_list.length === 0}
                >
                  <MenuItem value="" >
                    -
                  </MenuItem>
                  {this.props.visit_editor_forms.tatemono_id && this.props.customer_contacts_tm_room_list.length > 0 ? this.props.customer_contacts_tm_room_list.map(function (value) {
                    return <MenuItem value={value.room_id} > {value.room_name}({value.kuushitsu_joukyou_name})</MenuItem>
                  }) : <MenuItem value="" >
                    -
                  </MenuItem>}

                </SelectM>
              </FormControl>




            </Grid>





            <Grid xs={12} style={{ marginTop: 20 }}>
              <TextField
                id="outlined-multiline-static"
                label="備考"
                value={this.props.visit_editor_forms.remarks}
                name="remarks"
                onChange={this.handleChange}
                multiline
                rows={4}
                style={{ width: '90%', margin: 10 }}
                variant="outlined"
              />
            </Grid>


          </Grid >

        </CardContent>

      </Card >



    );
  }
}





const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    user_details: state.masterReducer.user_details,
    // csv_loading: state.Adjustment.csv_loading,
    // search_list: state.Adjustment.search_list,
    // seisan_follow_list: state.Adjustment.seisan_follow_list,
    // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
    // seisan_follow_search: state.Adjustment.seisan_follow_search,
    // tantou: state.Adjustment.tantou,
    // rent_m_contract_follows: state.ContractFollow.rent_m_contract_follows,
    // rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    rent_contract_details: state.contracts.rent_contract_details,
    customer_contacts_tm_tatemono_list: state.customerContactsReducer.customer_contacts_tm_tatemono_list,
    customer_contacts_tm_tatemono_list_loading: state.customerContactsReducer.customer_contacts_tm_tatemono_list_loading,
    customer_contacts_tm_room_list: state.customerContactsReducer.customer_contacts_tm_room_list,
    responsedetail: state.responsesReducer.responsedetail,
    rent_customer_contacts_forms: state.customerContactsReducer.rent_customer_contacts_forms,
    rent_customer_contacts_calendar_forms: state.customerContactsReducer.rent_customer_contacts_calendar_forms,

    visit_editor_forms: state.customerContactsReducer.visit_editor_forms,
    fromMapOpen: state.mapsearch.fromMapOpen,

    // tm_kanri_corp_list: state.contracts.tm_kanri_corp_list,
    // tm_room_list: state.contracts.tm_room_list,
    // rent_m_contract_types: state.contracts.rent_m_contract_types,
    // rent_m_traders: state.contracts.rent_m_traders,

  }
}
function mapDispatchToProps(dispatch) {
  return {

    get_customer_contacts_tm_tatemono_list(state) {
      dispatch(CustomerContacts.get_customer_contacts_tm_tatemono_list(state))
    },
    get_customer_contacts_tm_room_list(state) {
      dispatch(CustomerContacts.get_customer_contacts_tm_room_list(state))
    },
    change_visit_editor_forms(state) {
      dispatch(CustomerContacts.change_visit_editor_forms(state))
    },

    // change_rent_contract_editor_forms(state) {
    //   dispatch(Contracts.change_rent_contract_editor_forms(state))
    // },
    // get_tm_room_list(state) {
    //   dispatch(Contracts.get_tm_room_list(state))
    // },
    // change_tm_room_list(state) {
    //   dispatch(Contracts.change_tm_room_list(state))
    // },
    // change_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.change_rent_m_contract_follow_settings(state))
    // },
    // change_contract_follow_list_form(state) {
    //   dispatch(ContractFollow.change_contract_follow_list_form(state))
    // },
    // get_contract_follow_list(state) {
    //   dispatch(ContractFollow.get_contract_follow_list(state))
    // },
    // search_contract_follow_list(state) {
    //   dispatch(ContractFollow.search_contract_follow_list(state))
    // },
    // update_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.update_rent_m_contract_follow_settings(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);
