import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
// import './Css/Grid.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ja from "date-fns/locale/ja";
import NumberFormat from 'react-number-format';
import Checkbox from '@mui/material/Checkbox';
import * as Contracts from './Saga/Contracts';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as Responses from '../ResponseDetail/Saga/Responses';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import * as Contracts from './Saga/Contracts';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import withStyles from '@mui/styles/withStyles';
var moment = require('moment-timezone');
let columns = []
// Table data as a list of array.

// Custom cell implementation with special prop


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      style={{ textAlign: 'right' }}

      onValueChange={values => {

        onChange({

          target: {
            value: parseInt(values.value),
          }
        });
      }}
      thousandSeparator

    />
  );
}
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',

    },
    '& label': {
      color: 'green',

    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
      color: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green',
        color: 'green',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
        color: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
        color: 'green',
      },
    },
  },
})(TextField);


const CssTextField2 = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'pink',

    },
    '& label': {
      color: 'pink',

    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'pink',
      color: 'pink',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'pink',
        color: 'pink',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
        color: 'pink',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'pink',
        color: 'pink',
      },
    },
  },
})(TextField);
// const TatemonoName = ({ column, props, rowIndex }) =>
//   <Cell style={{ whiteSpace: "nowrap" }}><HomeWorkIcon color="primary" onClick={() => props.togglesheet(props.contract_follow_search_list[rowIndex].tatemono_id, props.contract_follow_search_list[rowIndex].room_id)} /> {props.contract_follow_search_list[rowIndex][column.key_name]}</Cell>;
// const RoomName = ({ column, props, rowIndex }) =>
//   <Cell style={{ whiteSpace: "nowrap" }} >{props.contract_follow_search_list[rowIndex][column.key_name]}</Cell>;


// const Date = ({ column, props, rowIndex }) =>
//   <Cell >

//     {props.contract_follow_search_list[rowIndex][column.key_name] ? moment(props.contract_follow_search_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}


//   </Cell>;

// const ContractDate = ({ column, props, rowIndex, contractdetail }) =>
//   <Cell >
//     <IconButton onClick={() => contractdetail(props.contract_follow_search_list[rowIndex].rent_response_id, props.contract_follow_search_list[rowIndex].rent_contract_id)}><HomeWorkIcon color="primary" /></IconButton>
//     {props.contract_follow_search_list[rowIndex][column.key_name] ? moment(props.contract_follow_search_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}


//   </Cell>;
// const DateCheck = ({ column, props, rowIndex }) =>
//   <Cell style={{ whiteSpace: "nowrap", background: props.contract_follow_search_list[rowIndex][column.key_name] ? "#f5f5f5" : "pink", width: "100%", height: "100%" }} >{props.contract_follow_search_list[rowIndex][column.key_name] ? moment(props.contract_follow_search_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}</Cell>;


const Base = ({ column, props, rowIndex, change_monies }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_editor_forms.rent_contract_monies[rowIndex][column.key] ? props.rent_contract_editor_forms.rent_contract_monies[rowIndex][column.key] : ""}
  </Cell>;

const Tax = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} />
    : ((monies[rowIndex].rent_m_account_id == 1 && (props.rent_contract_editor_forms.coungting == 1 || props.rent_contract_editor_forms.coungting == 0)) || monies[rowIndex].rent_m_account_id == 2 || monies[rowIndex].rent_m_account_id == 9 || monies[rowIndex].rent_m_account_id == 10 || monies[rowIndex].rent_m_account_id == 11 || monies[rowIndex].rent_m_account_id == 17 || monies[rowIndex].rent_m_account_id == 21 || monies[rowIndex].rent_m_account_id == 33 || monies[rowIndex].rent_m_account_id == 34 || monies[rowIndex].rent_m_account_id == 35) ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} />
      : monies[rowIndex].rent_m_etc_id > 0 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} />//
        : (props.rent_contract_editor_forms.hiwari_free || monies[rowIndex].account_group !== 5) ?
          <Cell style={{ width: "100%", height: "100%", borderBottom: "1px solid #a9a9a9", textAlign: "right", background: monies[rowIndex].is_free_rent ? "#98fb98" : "", }} onClick={(e) => change_monies(e, monies[rowIndex], 'taxes')}>
            {monies[rowIndex].tax_amount ? monies[rowIndex].tax_amount.toLocaleString() : "-"}
          </Cell>
          : <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%", textAlign: "right" }} > {monies[rowIndex].tax_amount ? monies[rowIndex].tax_amount.toLocaleString() : "-"} </Cell>

const Hiwari = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} />
    : monies[rowIndex].rent_m_etc_id > 0 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} />
      : <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "100%", background: monies[rowIndex].is_free_rent ? "#98fb98" : "", width: "100%", borderBottom: "1px solid #a9a9a9", textAlign: "right" }} onClick={(e) => change_monies(e, monies[rowIndex], 'is_daily_rate_set')}>
        {monies[rowIndex].is_daily_rate ? (moment(props.rent_contract_editor_forms.contract_date).endOf('month').format('D') - moment(props.rent_contract_editor_forms.contract_date).format('D') + 1) + '/' + moment(props.rent_contract_editor_forms.contract_date).endOf('month').format('D') : "-"}
      </Cell>;




const edit_options = [
  { value: 0, name: "-" },
  { value: 1, name: "フリーレント" },
  { value: 2, name: "契約時請求しない" },
  { value: 6, name: "契約書非掲載" }
]

const edit_options_nashi = [
  { value: 0, name: "-" },
  { value: 3, name: "無料表示" },
  { value: 4, name: "0円表示" },
  { value: 5, name: "空白表示" },
  { value: 6, name: "契約書非掲載" },
]


const Freerent = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} />
    : monies[rowIndex].rent_m_account_id === 4 && props.rent_contract_editor_forms.edit_options ?

      <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px dotted  #a9a9a9", width: "100%", height: "100%", background: monies[rowIndex].is_free_rent ? "#98fb98" : "" }} >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={monies[rowIndex].is_free_rent ? monies[rowIndex].is_free_rent : -1}
          // defaultValue={0}
          onChange={(e) => change_monies(e, monies[rowIndex], 'is_free_rent')}
        >
          {edit_options_nashi.map(function (value) {//込み　無し
            return <MenuItem value={value.value}>{value.name}</MenuItem>
          })}

        </Select>
      </Cell>


      : (monies[rowIndex].rent_m_etc_id !== 1) && props.rent_contract_editor_forms.edit_options ?

        <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px dotted  #a9a9a9", width: "100%", height: "100%", background: monies[rowIndex].is_free_rent ? "#98fb98" : "" }} >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={monies[rowIndex].is_free_rent ? monies[rowIndex].is_free_rent : -1}
            // defaultValue={0}
            onChange={(e) => change_monies(e, monies[rowIndex], 'is_free_rent')}
          >
            {

              (monies[rowIndex].rent_m_etc_id === 2 || monies[rowIndex].rent_m_etc_id === 3) ? edit_options_nashi.map(function (value) {//込み　無し
                return <MenuItem value={value.value}>{value.name}</MenuItem>
              }) : edit_options.map(function (value) {//
                return <MenuItem value={value.value}>{value.name}</MenuItem>
              })}


          </Select>
        </Cell>


        : monies[rowIndex].rent_m_etc_id > 0 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} />//



          : <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "100%", borderBottom: "1px solid #a9a9a9", background: monies[rowIndex].is_free_rent ? "#98fb98" : "", }} onClick={(e) => change_monies(e, monies[rowIndex], 'is_free_rent_set')}>
            {monies[rowIndex].is_free_rent ? "フリーレント" : "-"}
          </Cell>;

const Billing = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%", textAlign: "right", fontWeight: "bold" }} >{monies[rowIndex].amount_n.toLocaleString()}</Cell>
    : <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "100%", borderBottom: "1px solid #a9a9a9", background: monies[rowIndex].is_free_rent ? "#98fb98" : "" }} onClick={(e) => change_monies(e, monies[rowIndex], 'billing_set')}>
      {monies[rowIndex] && monies[rowIndex].billing ? <CheckIcon /> : "　"}
    </Cell>;




const Amount = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%", textAlign: "right", fontWeight: "bold" }} >{monies[rowIndex][column.key].toLocaleString()}</Cell>
    : monies[rowIndex].rent_m_etc_id > 0 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} />
      : (props.rent_contract_editor_forms.hiwari_free || monies[rowIndex].account_group !== 5) ?
        <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "100%", background: monies[rowIndex].is_free_rent ? "#98fb98" : "", borderBottom: "1px solid #a9a9a9", }} >

          <TextField
            // type="number"
            label=""
            value={monies[rowIndex][column.key]}
            onChange={(e) => change_monies(e, monies[rowIndex], 'amount')}

            id="formatted-numberformat-input"
            // type="number"
            style={{ width: "60px", marginTop: 0 }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              min: 0, style: { textAlign: 'right' }
            }}
            variant='standard'
          />


        </Cell>
        : <Cell style={{ background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", textAlign: "right", width: "100%", height: "100%" }} >{monies[rowIndex][column.key].toLocaleString()}


        </Cell>


  ;



const Remarks = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%", textAlign: "right", fontWeight: "bold" }} ></Cell>
    : <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "100%", background: monies[rowIndex].is_free_rent ? "#98fb98" : "" }} >

      <TextField

        label=""
        value={monies[rowIndex][column.key]}
        onChange={(e) => change_monies(e, monies[rowIndex], 'remarks')}
        id="formatted-numberformat-input"
        // type="number"
        style={{ width: "80px", marginTop: 0 }}
        InputProps={{
          min: 0, style: { textAlign: 'right' }
        }}
        variant='standard'
      />

      {/* <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="tatemono_name"
      // onChange={this.handleChange}
      inputProps={{ min: 0, style: { textAlign: 'right' } }} // the change is here
      value={monies[rowIndex][column.key]}
      style={{ width: "70px", marginTop: 0 }}

    /> */}

    </Cell>;


const all_seikyuu = [

  { value: -1, name: "請求" },
  { value: 1, name: "実費" },

  { value: 2, name: "込み" },
  { value: 3, name: "無し" }
]

const suidou_seikyuu = [

  { value: -1, name: "定額" },
  { value: 1, name: "実費" },
  { value: 2, name: "込み" },

]

const kyoueki_seikyuu = [

  { value: -1, name: "請求" },
  { value: 2, name: "込み" },

]

const parking_seikyuu = [

  { value: -1, name: "請求" },
  { value: 2, name: "込み" },
  { value: 3, name: "無し" },
]

const none_seikyuu = [
  { value: -1, name: "請求" },
  { value: 3, name: "無し" }
]

const koushin_seikyuu = [
  { value: -1, name: "請求" },
  { value: 3, name: "無し" }
]
const seisan_seikyuu = [
  { value: -1, name: "" },
  { value: 1, name: "実費" },
  { value: 2, name: "込み" }
]
const key_seikyuu = [
  { value: -1, name: "請求" },
  { value: 2, name: "込み" },
  { value: 3, name: "無し" },
]
const hoken_seikyuu = [
  { value: -1, name: "請求" },
  { value: 2, name: "込み" },
  { value: 3, name: "無し" },
  { value: 4, name: "WEB" }
]

const one_seikyuu = [
  { value: -1, name: "請求" },
  { value: 1, name: "実費" },
  { value: 2, name: "込み" },
  { value: 3, name: "無し" },
]
const Seikyuu = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} />
    : monies[rowIndex].rent_m_account_id === 33 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} />//保証会社保証料
      : monies[rowIndex].rent_m_account_id === 7 || monies[rowIndex].rent_m_account_id === 46 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} />//浄水器　シャワー
        : monies[rowIndex].account_group == 5 && !props.rent_contract_editor_forms.hiwari_free && monies[rowIndex].rent_m_account_id == 3 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} > {suidou_seikyuu.filter(a => a.value == monies[rowIndex].rent_m_etc_id)[0] ? suidou_seikyuu.filter(a => a.value == monies[rowIndex].rent_m_etc_id)[0].name : ""}</Cell>
          : monies[rowIndex].rent_m_account_id == 19 || monies[rowIndex].rent_m_account_id == 21 || monies[rowIndex].rent_m_account_id == 27 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} > </Cell>
            : monies[rowIndex].rent_m_account_id == 1 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} > 請求</Cell>
              : monies[rowIndex].account_group == 5 && !props.rent_contract_editor_forms.hiwari_free ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} > {all_seikyuu.filter(a => a.value == monies[rowIndex].rent_m_etc_id)[0] ? all_seikyuu.filter(a => a.value == monies[rowIndex].rent_m_etc_id)[0].name : ""}</Cell>
                : <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "" }} >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monies[rowIndex].rent_m_etc_id ? monies[rowIndex].rent_m_etc_id : -1}
                    // defaultValue={0}
                    onChange={(e) => change_monies(e, monies[rowIndex], 'rent_m_etc_id')}
                    variant='standard'
                  >
                    {monies[rowIndex].account_group === 3 || monies[rowIndex].rent_m_account_id === 9 || monies[rowIndex].rent_m_account_id === 10 || monies[rowIndex].rent_m_account_id === 12 ? koushin_seikyuu.map(function (value) {
                      return <MenuItem value={value.value}>{value.name}</MenuItem>
                    })
                      : monies[rowIndex].rent_m_account_id === 2 ? kyoueki_seikyuu.map(function (value) {//共益費
                        return <MenuItem value={value.value}>{value.name}</MenuItem>

                      })
                        : monies[rowIndex].rent_m_account_id === 4 || monies[rowIndex].rent_m_account_id === 5 || monies[rowIndex].rent_m_account_id === 6 ? parking_seikyuu.map(function (value) {//駐車場
                          return <MenuItem value={value.value}>{value.name}</MenuItem>

                        })
                          : monies[rowIndex].rent_m_account_id === 3 ? suidou_seikyuu.map(function (value) {//水道
                            return <MenuItem value={value.value}>{value.name}</MenuItem>

                          }) : monies[rowIndex].rent_m_account_id === 11 ? hoken_seikyuu.map(function (value) {//保険
                            return <MenuItem value={value.value}>{value.name}</MenuItem>
                          }) : monies[rowIndex].rent_m_account_id === 14 ? key_seikyuu.map(function (value) {//鍵
                            return <MenuItem value={value.value}>{value.name}</MenuItem>
                          }) : monies[rowIndex].rent_m_account_id === 17 ? seisan_seikyuu.map(function (value) {//退去時精算
                            return <MenuItem value={value.value}>{value.name}</MenuItem>
                          }) : monies[rowIndex].rent_m_account_id === 12 ? none_seikyuu.map(function (value) {//仲介手数料
                            return <MenuItem value={value.value}>{value.name}</MenuItem>
                          }) : monies[rowIndex].rent_m_account_id === 9 ? none_seikyuu.map(function (value) {//敷金
                            return <MenuItem value={value.value}>{value.name}</MenuItem>
                          }) : monies[rowIndex].rent_m_account_id === 10 ? none_seikyuu.map(function (value) {//礼金
                            return <MenuItem value={value.value}>{value.name}</MenuItem>

                          }) : monies[rowIndex].account_group === 2 ? one_seikyuu.map(function (value) {//一時金その他
                            return <MenuItem value={value.value}>{value.name}</MenuItem>
                          }) : all_seikyuu.map(function (value) {
                            return <MenuItem value={value.value}>{value.name}</MenuItem>
                          })}


                  </Select>
                </Cell>;


const Delete = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} />

    : monies[rowIndex].rent_m_account_id === 1
      || monies[rowIndex].rent_m_account_id === 2
      || monies[rowIndex].rent_m_account_id === 3
      || monies[rowIndex].rent_m_account_id === 4
      || monies[rowIndex].rent_m_account_id === 9
      || monies[rowIndex].rent_m_account_id === 10
      || monies[rowIndex].rent_m_account_id === 11
      || monies[rowIndex].rent_m_account_id === 12
      || monies[rowIndex].rent_m_account_id === 14
      || monies[rowIndex].rent_m_account_id === 16
      || monies[rowIndex].rent_m_account_id === 17 ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%" }} />
      : monies[rowIndex].account_group == 5 && !props.rent_contract_editor_forms.hiwari_free ? <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px solid #a9a9a9", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", width: "100%", height: "100%" }} />
        : <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px solid #a9a9a9", width: "100%", height: "100%", background: monies[rowIndex].is_free_rent ? "#98fb98" : "" }} >
          <Tooltip title="削除" >
            <IconButton size="small" aria-label="delete" onClick={(e) => change_monies(e, monies[rowIndex], 'delete')}>
              <DeleteForeverIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </Cell>;

const Koumoku = ({ column, props, rowIndex, monies, change_monies }) =>

  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} >{monies[rowIndex].name}</Cell> :
    monies[rowIndex].account_group == 5 && !props.rent_contract_editor_forms.hiwari_free ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", width: "100%", height: "100%", borderBottom: "1px solid #a9a9a9" }} >{monies[rowIndex].name}</Cell>
      : (monies[rowIndex].account_group === 3 || monies[rowIndex].account_group === 4) ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#f0f8ff", width: "100%", height: "100%" }} >{monies[rowIndex].name}</Cell>
        : (monies[rowIndex].rent_m_account_id === 1 || monies[rowIndex].rent_m_account_id === 2 || monies[rowIndex].rent_m_account_id === 3 || monies[rowIndex].rent_m_account_id === 4 || monies[rowIndex].rent_m_account_id === 9 || monies[rowIndex].rent_m_account_id === 10 || monies[rowIndex].rent_m_account_id === 11 || monies[rowIndex].rent_m_account_id === 12 || monies[rowIndex].rent_m_account_id === 14) ? <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", width: "100%", height: "100%", borderBottom: "1px solid #a9a9a9" }} >{monies[rowIndex].name}</Cell>
          : <Cell style={{ whiteSpace: "nowrap", background: monies[rowIndex].is_free_rent ? "#98fb98" : "", borderBottom: "1px dotted  #a9a9a9", borderTop: "1px solid #a9a9a9", width: '100%' }} >
            {/* {monies[rowIndex].name} */}

            {props.tm_chinryou_koumoku.length > 0 ? <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ width: '100%' }}
              value={monies[rowIndex].rent_m_account_id}
              // defaultValue={0}
              onChange={(e) => change_monies(e, monies[rowIndex], 'rent_m_account_id')}
            >
              <MenuItem value={0}>　-　</MenuItem>

              {props.tm_chinryou_koumoku.sort((a, b) => a.order_no - b.order_no).filter(a => (a.account_group === monies[rowIndex].account_group || (a.account_group === 1 && monies[rowIndex].account_group === 5)) && a.rent_m_account_id !== 1 && a.rent_m_account_id !== 2 && a.rent_m_account_id !== 3 && a.rent_m_account_id !== 4 && a.rent_m_account_id !== 9 && a.rent_m_account_id !== 10 && a.rent_m_account_id !== 11 && a.rent_m_account_id !== 12 && a.rent_m_account_id !== 14).map(function (value) {
                if (!monies.filter(b => b.account_group === value.account_group && b.rent_m_account_id === value.rent_m_account_id && b.key !== monies[rowIndex].key)[0] && value.rent_m_account_id != 23) {
                  return <MenuItem value={value.rent_m_account_id}>{value.name}</MenuItem>
                }
              })}


            </Select> : ""}




          </Cell>

const month = [
  { value: -1, name: "　-　" },
  { value: 1, name: "1ヵ月" },
  { value: 2, name: "2ヵ月" },
  { value: 3, name: "3ヵ月" },
  { value: 4, name: "4ヵ月" },
  { value: 5, name: "5ヵ月" },
  { value: 6, name: "6ヵ月" },
]
const Month = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} />
    : monies[rowIndex].rent_m_etc_id !== -1 || (monies[rowIndex].rent_m_account_id !== 9 && monies[rowIndex].rent_m_account_id !== 10 && monies[rowIndex].rent_m_account_id !== 19 && monies[rowIndex].rent_m_account_id !== 21 && monies[rowIndex].rent_m_account_id !== 27 && monies[rowIndex].rent_m_account_id !== 34 && monies[rowIndex].rent_m_account_id !== 35) ? <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px solid  #a9a9a9", background: "#dcdcdc", width: "100%", height: "100%" }} />//保証会社保証料
      : <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px dotted  #a9a9a9", }} >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={monies[rowIndex].amount_month ? monies[rowIndex].amount_month : -1}
          // defaultValue={0}
          onChange={(e) => change_monies(e, monies[rowIndex], 'amount_month_set')}
          variant='standard'
        >
          {month.map(function (value) {
            return <MenuItem value={value.value}>{value.name}</MenuItem>
          })}


        </Select>
      </Cell>;

const EachYear = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} />
    : monies[rowIndex].rent_m_account_id !== 16 && monies[rowIndex].rent_m_account_id !== 59 ? <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px solid  #a9a9a9", background: "#dcdcdc", width: "100%", height: "100%" }} />//保証会社保証料
      : <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px dotted  #a9a9a9", }} >

        <TextField
          // type="number"
          label=""
          value={monies[rowIndex][column.key]}
          onChange={(e) => change_monies(e, monies[rowIndex], 'each_year')}

          id="formatted-numberformat-input"
          // type="number"
          style={{ width: "40px", marginTop: 0 }}
          InputProps={{
            inputComponent: NumberFormatCustom,
            min: 0, style: { textAlign: 'right' }
          }}
          variant='standard'
        />

      </Cell>;






const works = [
  { value: 0, name: " - " },
  { value: 1, name: "自社" },
  { value: 2, name: "他社" },
  { value: 3, name: "家主" }
]
const Works = ({ column, props, rowIndex, monies, change_monies }) =>
  monies[rowIndex].name === "合計" || monies[rowIndex].name === "小計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} />
    : monies[rowIndex].rent_m_etc_id > 0 ? <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px solid #a9a9a9", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", width: "100%", height: "100%" }} />
      : (monies[rowIndex].rent_m_account_id !== 11 && monies[rowIndex].rent_m_account_id !== 14 && monies[rowIndex].rent_m_account_id !== 15 && monies[rowIndex].rent_m_account_id !== 22 && monies[rowIndex].rent_m_account_id !== 25) ? <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px solid #a9a9a9", background: monies[rowIndex].is_free_rent ? "#98fb98" : "#dcdcdc", width: "100%", height: "100%" }} />
        : <Cell style={{ whiteSpace: "nowrap", borderBottom: "1px solid #a9a9a9", background: monies[rowIndex].is_free_rent ? "#98fb98" : "", }} >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={monies[rowIndex].works ? monies[rowIndex].works : 0}
            // defaultValue={0}

            onChange={(e) => change_monies(e, monies[rowIndex], 'works_set')}
            variant='standard'
          >
            {works.map(function (value) {
              return <MenuItem value={value.value}>{value.name}</MenuItem>
            })}


          </Select>
        </Cell>;


var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const hiwari_k_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 65,

  },
  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },
  { key: 'is_daily_rate', name: '日割', width: 50 },
  { key: 'is_free_rent', name: 'フリーレント', width: 80 },
  { key: 'remarks', name: '備考', width: 100 },
  { key: 'delete', name: '削除', width: 60 },


]
const hiwari_n_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 65,

  },
  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },
  { key: 'is_daily_rate', name: '日割', width: 50 },
  { key: 'billing', name: '入居者', width: 50 },
  { key: 'is_free_rent', name: 'フリーレント', width: 80 },
  { key: 'remarks', name: '備考', width: 100 },
  { key: 'delete', name: '削除', width: 60 },


]




const month_k_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 65,

  },
  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },
  { key: 'is_free_rent', name: 'フリーレント', width: 80 },
  { key: 'remarks', name: '備考', width: 100 },
  { key: 'delete', name: '削除', width: 60 },


]
const month_n_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 65,

  },
  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },

  { key: 'billing', name: '入居者', width: 50 },
  { key: 'is_free_rent', name: 'フリーレント', width: 80 },
  { key: 'remarks', name: '備考', width: 100 },
  { key: 'delete', name: '削除', width: 60 },


]



const onetime_k_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 65,

  },
  { key: 'amount_month', name: '月請求', width: 70 },
  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },
  { key: 'works', name: '取扱', width: 60 },
  { key: 'remarks', name: '備考', width: 100 },
  { key: 'delete', name: '削除', width: 60 },


]
const onetime_n_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 70,

  },
  { key: 'amount_month', name: '月請求', width: 65 },
  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },


  { key: 'works', name: '取扱', width: 60 },
  { key: 'billing', name: '入居者', width: 50 },
  { key: 'remarks', name: '備考', width: 100 },

  { key: 'delete', name: '削除', width: 60 },


]



const koushin_k_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 65,

  },

  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },
  { key: 'each_year', name: '年ごと', width: 60 },
  { key: 'remarks', name: '備考', width: 100 },
  { key: 'delete', name: '削除', width: 60 },


]
const koushin_n_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 65,

  },
  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },

  { key: 'each_year', name: '年ごと', width: 60 },
  { key: 'remarks', name: '備考', width: 100 },


  { key: 'delete', name: '削除', width: 60 },


]


const taikyo_k_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 65,

  },
  { key: 'amount_month', name: '月請求', width: 65 },
  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },

  { key: 'remarks', name: '備考', width: 100 },
  { key: 'delete', name: '削除', width: 60 },


]
const taikyo_n_columns = [
  { key: 'name', name: '項目名', width: 110 },
  {
    key: 'rent_m_etc_id', name: '請求', width: 65,

  },
  { key: 'amount_month', name: '月請求', width: 65 },
  { key: 'amount', name: '金額', width: 80 },
  { key: 'tax', name: '税', width: 50 },


  { key: 'remarks', name: '備考', width: 100 },

  { key: 'delete', name: '削除', width: 60 },


]


// function hankaku2Zenkaku(str) {
//   alert(str)
//   // if (str) {
//   //   return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
//   //     return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
//   //   });
//   // } else {
//   return str
//   // }
// }
class CustomerList extends Component {
  constructor(props) {
    super(props);


    // this.props.rent_m_contract_follows_disp.map(async function (value) {
    //   if (value.disp) {
    //     columns.push({ key: value.key_name, name: value.name, company: value.company, width: 80 })
    //   }
    // })
    console.log(columns)

    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},
      numberformat: 0,
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };
    // this.props.get_contract_follow_list()
    // this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);



    this.handleChange = this.handleChange.bind(this);
    this.handleChange_application_fee = this.handleChange_application_fee.bind(this);


    this.change_monies = this.change_monies.bind(this);
    this.add_monies = this.add_monies.bind(this);
    this.change_application_fee_date = this.change_application_fee_date.bind(this);
    this.change_billing_closing_date = this.change_billing_closing_date.bind(this);
    this.change_billing_closing_date_nyuukyosha = this.change_billing_closing_date_nyuukyosha.bind(this);


  }


  change_monies(e, monies, key) {
    console.log(e)
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    // rent_contract_editor_forms.rent_contract_monies = rent_contract_editor_forms.rent_contract_monies.map(function (value) {
    //   return value
    // })

    if (key === 'rent_m_account_id') {
      // alert(monies.key)
      rent_contract_editor_forms.rent_contract_monies = rent_contract_editor_forms.rent_contract_monies.map(function (value) {
        // alert(value.key)
        // alert(value.name)
        if (value.key === monies.key) {
          value.rent_m_account_id = e.target.value
        }
        return value

      })

    } else if (key === 'delete') {
      rent_contract_editor_forms.rent_contract_monies = rent_contract_editor_forms.rent_contract_monies.filter(a => !(a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id))
    } else if (key === 'amount_month_set') {

      rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].amount = rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 1 && a.rent_m_account_id === 1)[0].amount * (e.target.value > 0 ? e.target.value : 0)
      rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].amount_month = e.target.value

    } else if (key === 'is_daily_rate_set') {

      if (rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].is_daily_rate) {
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].is_daily_rate = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].amount = rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 1 && a.rent_m_account_id === monies.rent_m_account_id)[0].amount
      } else {
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].is_daily_rate = 1
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].amount = Math.round(rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 1 && a.rent_m_account_id === monies.rent_m_account_id)[0].amount * (moment(rent_contract_editor_forms.contract_date).endOf('month').format('D') - moment(rent_contract_editor_forms.contract_date).format('D') + 1) / moment(rent_contract_editor_forms.contract_date).endOf('month').format('D'))

      }

    } else if (key === 'is_free_rent_set') {

      if (rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].is_free_rent) {
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].is_free_rent = 0
      } else {
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].is_free_rent = 1
      }

    } else if (key === 'works_set') {

      rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].works = e.target.value

    } else if (key === 'billing_set') {
      // alert(e.target.value)
      if (rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].billing) {
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].billing = 0
      } else {
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].billing = 1
      }
    } else if (key === 'taxes') {

      if (rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].tax) {

        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].tax = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].tax_amount = 0
      } else {

        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].tax = 1
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].tax_amount = Math.round(rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0].amount * 0.1)

      }
    } else if (key === 'amount') {
      // 使用例if(!isNaN(num)){
      if (!isNaN(e.target.value)) {
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0][key] = e.target.value
      } else {
        alert("半角数字に変更してください")
      }

    } else if (key === 'each_year') {
      // 使用例if(!isNaN(num)){
      if (!isNaN(e.target.value)) {
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0][key] = e.target.value
      } else {
        alert("半角数字に変更してください")
      }


    } else if (key === 'is_free_rent') {
      // 使用例

      rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0][key] = e.target.value



    } else if (key === 'rent_m_etc_id') {
      rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0][key] = e.target.value
      if (e.target.value > 0) {
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['amount'] = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['amount_month'] = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['each_month'] = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['each_year'] = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['tax'] = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['tax_amount'] = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['sales_amount'] = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['is_daily_rate'] = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['works'] = 0
        rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0]['is_free_rent'] = 0
      }
    } else {
      // 使用例

      rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === monies.account_group && a.rent_m_account_id === monies.rent_m_account_id)[0][key] = e.target.value
    }


    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms)

    this.setState({ reflesh: !this.state.reflesh })

  }



  add_monies(account_group, id) {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    if (id) {


      let rent_m_money_templates = this.props.rent_m_money_templates.filter(a => a.id === id)[0]

      if (!rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === account_group && a.rent_m_account_id === rent_m_money_templates.rent_m_account_id)[0]) {


        //最高key
        // let max = rent_contract_editor_forms.rent_contract_monies.reduce((a, b) => a.key > b.key ? a : b).key
        let max = rent_contract_editor_forms.rent_contract_monies.reduce((prev, current) => {
          return prev.key > current.key ? prev.key : current.key;
        }, 0);

        let month_set = rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 1 && a.rent_m_account_id === 1)[0].amount

        rent_contract_editor_forms.rent_contract_monies.push({
          key: max + 1,
          account_group: rent_m_money_templates.account_group,
          amount: rent_m_money_templates.amount_month ? month_set * rent_m_money_templates.amount_month : rent_m_money_templates.amount,
          amount_month: rent_m_money_templates.amount_month,
          created_at: moment(),
          created_user: 0,
          display_name: null,
          each_month: rent_m_money_templates.each_month,
          each_year: rent_m_money_templates.each_year,
          id: 0,
          is_daily_rate: rent_m_money_templates.is_daily_rate,
          is_free_rent: 0,
          billing: 0,
          name: rent_m_money_templates.name,
          remarks: rent_m_money_templates.remarks,
          rent_contract_id: this.props.rent_contract_editor_forms.rent_contract_id,
          rent_m_account_id: rent_m_money_templates.rent_m_account_id,
          rent_m_etc_id: rent_m_money_templates.rent_m_etc_id,
          sales_amount: rent_m_money_templates.sales_amount,
          tax: rent_m_money_templates.tax,
          tax_amount: rent_m_money_templates.tax ? Math.round(rent_m_money_templates.amount * 0.1) : 0,

          updated_at: moment(),
          updated_user: 0,
          works: rent_m_money_templates.rent_m_account_id == 11 || rent_m_money_templates.rent_m_account_id == 14 || rent_m_money_templates.rent_m_account_id == 15 || rent_m_money_templates.rent_m_account_id == 22 || rent_m_money_templates.rent_m_account_id == 25 ? 1 : 0
        })
      } else {
        let rent_contract_monies = []
        if (id === 5) {//仲介手数料



          // let update_money = rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === account_group && a.rent_m_account_id === rent_m_money_templates.rent_m_account_id)[0]
          let sum_month = 0
          rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 1).map(function (sum_month_value) {
            if (sum_month_value.rent_m_account_id === 1 || sum_month_value.rent_m_account_id === 4 || sum_month_value.rent_m_account_id === 5 || sum_month_value.rent_m_account_id === 6) {
              sum_month = sum_month + sum_month_value.amount

            }
          })


          rent_contract_editor_forms.rent_contract_monies.map(function (value) {

            if (value.account_group === account_group && value.rent_m_account_id === rent_m_money_templates.rent_m_account_id) {
              rent_contract_monies.push({
                key: value.key,
                account_group: rent_m_money_templates.account_group,
                amount: sum_month,
                amount_month: rent_m_money_templates.amount_month,
                billing: rent_m_money_templates.billing,
                created_at: moment(),
                created_user: 0,
                display_name: null,
                each_month: rent_m_money_templates.each_month,
                each_year: rent_m_money_templates.each_year,
                id: 0,
                is_daily_rate: rent_m_money_templates.is_daily_rate,
                is_free_rent: rent_m_money_templates.is_free_rent,
                name: rent_m_money_templates.name,
                remarks: rent_m_money_templates.remarks,
                rent_contract_id: this.props.rent_contract_editor_forms.rent_contract_id,
                rent_m_account_id: rent_m_money_templates.rent_m_account_id,
                rent_m_etc_id: rent_m_money_templates.rent_m_etc_id,
                sales_amount: rent_m_money_templates.sales_amount,
                tax: rent_m_money_templates.tax,
                tax_amount: rent_m_money_templates.tax ? Math.round(sum_month * 0.1) : 0,
                updated_at: moment(),
                updated_user: 0,
                works: 0
              })
            } else {
              rent_contract_monies.push(value)
            }
          }, this)


        } else {



          rent_contract_editor_forms.rent_contract_monies.map(function (value) {

            if (value.account_group === account_group && value.rent_m_account_id === rent_m_money_templates.rent_m_account_id) {
              rent_contract_monies.push({
                key: value.key,
                account_group: rent_m_money_templates.account_group,
                amount: rent_m_money_templates.amount,
                amount_month: rent_m_money_templates.amount_month,
                billing: rent_m_money_templates.billing,
                created_at: moment(),
                created_user: 0,
                display_name: null,
                each_month: rent_m_money_templates.each_month,
                each_year: rent_m_money_templates.each_year,
                id: 0,
                is_daily_rate: rent_m_money_templates.is_daily_rate,
                is_free_rent: rent_m_money_templates.is_free_rent,
                name: rent_m_money_templates.name,
                remarks: rent_m_money_templates.remarks,
                rent_contract_id: this.props.rent_contract_editor_forms.rent_contract_id,
                rent_m_account_id: rent_m_money_templates.rent_m_account_id,
                rent_m_etc_id: rent_m_money_templates.rent_m_etc_id,
                sales_amount: rent_m_money_templates.sales_amount,
                tax: rent_m_money_templates.tax,
                tax_amount: rent_m_money_templates.tax ? Math.round(rent_m_money_templates.amount * 0.1) : 0,
                updated_at: moment(),
                updated_user: 0,
                works: rent_m_money_templates.rent_m_account_id == 11 || rent_m_money_templates.rent_m_account_id == 14 || rent_m_money_templates.rent_m_account_id == 15 || rent_m_money_templates.rent_m_account_id == 22 || rent_m_money_templates.rent_m_account_id == 25 ? 1 : 0
              })
            } else {
              rent_contract_monies.push(value)
            }
          }, this)
        }


        rent_contract_editor_forms.rent_contract_monies = rent_contract_monies
      }
    } else {
      if (!rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === account_group && a.rent_m_account_id === 0)[0]) {

        //最高key
        // let max = rent_contract_editor_forms.rent_contract_monies.reduce((a, b) => a.key > b.key ? a : b).key
        let max = rent_contract_editor_forms.rent_contract_monies.reduce((prev, current) => {
          return prev.key > current.key ? prev.key : current.key;
        }, 0);

        let rent_contract_monies = []

        rent_contract_editor_forms.rent_contract_monies.map(function (value) {
          rent_contract_monies.push(value)
        })

        rent_contract_monies.push({
          key: max + 1,
          account_group: account_group,
          amount: 0,
          amount_month: 0,
          billing: 0,
          created_at: moment(),
          created_user: 1154,
          display_name: null,
          each_month: 0,
          each_year: 0,
          id: 0,
          is_daily_rate: 1,
          is_free_rent: 0,
          name: "",
          remarks: "",
          rent_contract_id: this.props.rent_contract_editor_forms.rent_contract_id,
          rent_m_account_id: 0,
          rent_m_etc_id: 0,
          sales_amount: 0,
          tax: 0,
          tax_amount: 0,
          updated_at: moment(),
          updated_user: 1154,
          works: 0
        })


        rent_contract_editor_forms.rent_contract_monies = rent_contract_monies
      }
    }

    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms)

    this.setState({ reflesh: !this.state.reflesh })
  }
  // _onColumnResizeEndCallback(newColumnWidth, columnKey) {

  //   let columnsset = this.props.rent_m_contract_follows_disp.map(function (value) {
  //     if (value.key_name === columnKey) {
  //       value.width = newColumnWidth
  //     }
  //     return value
  //   })

  //   this.setState({ columns: columnsset })

  // }





  // handleChange = name => event => {
  //   this.setState({
  //     [name]: event.target.value,
  //   });
  // };


  handleChange = (event) => {
    // console.log(event.target)
    // alert(event.target.name)
    // alert(event.target.value)
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms


    if (event.target.name === "application_fee_payor") {
      rent_contract_editor_forms.application_fee_payor = !rent_contract_editor_forms.application_fee_payor;


    } else if (event.target.name === "receipt") {
      rent_contract_editor_forms.receipt = !rent_contract_editor_forms.receipt;

    } else if (event.target.name === "edit_options") {
      rent_contract_editor_forms.edit_options = !rent_contract_editor_forms.edit_options;
    } else if (event.target.name === "hiwari_free") {
      rent_contract_editor_forms.hiwari_free = !rent_contract_editor_forms.hiwari_free;

    } else {
      rent_contract_editor_forms[event.target.name] = event.target.value;
    }



    // this.props.change_rent_contract_editor_forms({});

    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);


    // this.props.check_rent_contract_editor_forms();

    // if (event.target.name === "condition") {


    // }
    this.setState({ reflash: !this.state.reflash });
  }
  handleChange_application_fee = (event) => {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    rent_contract_editor_forms.application_fee = event.target.value;
    rent_contract_editor_forms.application_fee_date = rent_contract_editor_forms.application_fee_date ? (event.target.value > 0 ? rent_contract_editor_forms.application_fee_date : null) : event.target.value > 0 ? moment().format("YYYY/MM/DD") : null
    // this.props.change_rent_contract_editor_forms({});
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    // if (event.target.name === "condition") {
    // this.props.check_rent_contract_editor_forms();

    // }
    this.setState({ reflash: !this.state.reflash });
  }


  change_application_fee_date = date => {
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.application_fee_date = date
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    this.setState({ reflesh: !this.props.reflesh })

  };

  change_billing_closing_date = date => {
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.billing_closing_date = date
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    // this.props.check_rent_contract_editor_forms();
    this.setState({ reflesh: !this.props.reflesh })

  };

  change_billing_closing_date_nyuukyosha = date => {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.billing_closing_date_nyuukyosha = date
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    // this.props.check_rent_contract_editor_forms();
    this.setState({ reflesh: !this.props.reflesh })

  };


  render() {

    let hiwari_columns = this.props.rent_contract_editor_forms.contract_form ? hiwari_n_columns : hiwari_k_columns
    let month_columns = this.props.rent_contract_editor_forms.contract_form ? month_n_columns : month_k_columns
    let onetime_columns = this.props.rent_contract_editor_forms.contract_form ? onetime_n_columns : onetime_k_columns
    let koushin_columns = this.props.rent_contract_editor_forms.contract_form ? koushin_n_columns : koushin_k_columns
    let taikyo_columns = this.props.rent_contract_editor_forms.contract_form ? taikyo_n_columns : taikyo_k_columns

    let hiwari = this.props.rent_contract_editor_forms.rent_contract_monies ? this.props.rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 5) : []

    let hiwari_goukei = 0
    let hiwari_goukei_n = 0
    hiwari.filter(a => a.is_free_rent === 0).map(async function (value) {
      if (value.billing) {
        hiwari_goukei_n = hiwari_goukei_n + (value.amount ? parseInt(value.amount) : 0) + (value.tax_amount ? parseInt(value.tax_amount) : 0)
      } else {
        hiwari_goukei = hiwari_goukei + (value.amount ? parseInt(value.amount) : 0) + (value.tax_amount ? parseInt(value.tax_amount) : 0)
      }
    })
    hiwari.sort((a, b) => {
      //priceの昇順
      if (!a.order_no) return 1;
      if (a.order_no > b.order_no) return 1;
      if (a.order_no < b.order_no) return -1;
      // //noの降順
      // if (a.no > b.no) return -1;
      // if (a.no < b.no) return 1;
      return 0;
    });
    hiwari.push({ name: "小計", amount: hiwari_goukei, amount_n: hiwari_goukei_n })

    let month = this.props.rent_contract_editor_forms.rent_contract_monies ? this.props.rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 1) : []

    let month_goukei = 0
    let month_goukei_all = 0
    let month_goukei_n = 0
    let month_goukei_n_all = 0
    month.map(async function (value) {
      if (value.billing) {
        if (value.is_free_rent === 0) {
          month_goukei_n = month_goukei_n + (value.amount ? parseInt(value.amount) : 0) + (value.tax_amount ? parseInt(value.tax_amount) : 0)
        }
        month_goukei_n_all = month_goukei_n_all + (value.amount ? parseInt(value.amount) : 0) + (value.tax_amount ? parseInt(value.tax_amount) : 0)
      } else {
        if (value.is_free_rent === 0) {
          month_goukei = month_goukei + (value.amount ? parseInt(value.amount) : 0) + (value.tax_amount ? parseInt(value.tax_amount) : 0)
        }

        month_goukei_all = month_goukei_all + (value.amount ? parseInt(value.amount) : 0) + (value.tax_amount ? parseInt(value.tax_amount) : 0)
      }

    })

    //priceの昇順
    month.sort((a, b) => {
      //priceの昇順
      if (!a.order_no) return 1;
      if (a.order_no > b.order_no) return 1;
      if (a.order_no < b.order_no) return -1;
      // //noの降順
      // if (a.no > b.no) return -1;
      // if (a.no < b.no) return 1;
      return 0;
    });

    month.push({ name: "小計", amount: month_goukei, amount_n: month_goukei_n })


    let onetime = this.props.rent_contract_editor_forms.rent_contract_monies ? this.props.rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 2) : []

    let onetime_goukei = 0
    let onetime_goukei_n = 0
    onetime.map(async function (value) {
      if (value.billing) {
        onetime_goukei_n = onetime_goukei_n + (value.amount ? parseInt(value.amount) : 0) + (value.tax_amount ? parseInt(value.tax_amount) : 0)
      } else {
        onetime_goukei = onetime_goukei + (value.amount ? parseInt(value.amount) : 0) + (value.tax_amount ? parseInt(value.tax_amount) : 0)
      }
    })
    onetime.sort((a, b) => {
      //priceの昇順
      if (!a.order_no) return 1;
      if (a.order_no > b.order_no) return 1;
      if (a.order_no < b.order_no) return -1;
      // //noの降順
      // if (a.no > b.no) return -1;
      // if (a.no < b.no) return 1;
      return 0;
    });
    onetime.push({ name: "合計", amount: onetime_goukei, amount_n: onetime_goukei_n })


    let amount_sum = onetime_goukei + month_goukei + month_goukei_all * this.props.rent_contract_editor_forms.pay_nextmonth + hiwari_goukei - (this.props.rent_contract_editor_forms.application_fee_payor ? 0 : this.props.rent_contract_editor_forms.application_fee)
    let amount_n_sum = onetime_goukei_n + month_goukei_n + month_goukei_n_all * this.props.rent_contract_editor_forms.pay_nextmonth + hiwari_goukei_n - (this.props.rent_contract_editor_forms.application_fee_payor ? this.props.rent_contract_editor_forms.application_fee : 0)

    let koushin = this.props.rent_contract_editor_forms.rent_contract_monies ? this.props.rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 3) : []
    koushin.sort((a, b) => {
      //priceの昇順
      if (a.order_no > b.order_no) return 1;
      if (a.order_no < b.order_no) return -1;
      // //noの降順
      // if (a.no > b.no) return -1;
      // if (a.no < b.no) return 1;
      return 0;
    });
    let taikyo = this.props.rent_contract_editor_forms.rent_contract_monies ? this.props.rent_contract_editor_forms.rent_contract_monies.filter(a => a.account_group === 4) : []
    taikyo.sort((a, b) => {
      //priceの昇順
      if (a.order_no > b.order_no) return 1;
      if (a.order_no < b.order_no) return -1;
      // //noの降順
      // if (a.no > b.no) return -1;
      // if (a.no < b.no) return 1;
      return 0;
    });

    // if (this.myRef.current) {
    //   console.log(this.myRef.current.getBoundingClientRect())
    //   alert()
    // }
    return (
      <div id="contract_money_month" >




        {this.props.contract_check_message ? <Alert severity="error">

          <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.contract_check_message.length ? this.props.contract_check_message.replace(/\r?\n/g, '<br/>') : '' }}></span>


          <Button onClick={this.props.set_rent_contract_checks} variant="contained" size="small" color="secondary" style={{ float: "right", marginLeft: 5, marginTop: 8 }}>
            反映
          </Button>
        </Alert> : ""}

        <span>
          <FormControlLabel
            style={{ float: "right", marginLeft: 5, marginTop: 8 }}

            control={
              <Checkbox

                name="edit_options"
                checked={this.props.rent_contract_editor_forms.edit_options ? true : false}
                onChange={this.handleChange}
                value="1"
                color="primary"
              />
            }
            label="編集オプション"
          />
          {(this.props.rent_contract_editor_forms.tatemono_id > 0 && this.props.rent_contract_editor_forms.room_id != -1) ? <Button onClick={this.props.set_contracts_boshu_chinryou} variant="contained" size="small" color="primary" style={{ float: "right", marginLeft: 5, marginTop: 8 }}>
            物件情報から反映
          </Button > : ""
          }
        </span>




        {/* <Divider /> */}
        {
          Number(moment(this.props.rent_contract_editor_forms.contract_date).format('D')) !== 1 ? <span>
            <Chip color="primary" style={{ float: "left", marginTop: 5 }} avatar={<Avatar><b>{moment(this.props.rent_contract_editor_forms.contract_date).format("M")}</b></Avatar>} label="月" />

            <FormControlLabel
              style={{ marginLeft: 5 }}

              control={
                <Checkbox

                  name="hiwari_free"
                  checked={this.props.rent_contract_editor_forms.hiwari_free ? true : false}
                  onChange={this.handleChange}
                  value="1"
                  color="primary"
                />
              }
              label="日割りを自由入力"
            />

            {this.props.rent_contract_editor_forms.hiwari_free ? <Tooltip title="追加" style={{ float: "right" }}>
              <IconButton aria-label="add" onClick={() => this.add_monies(5, 0)} size="large">
                <AddBoxIcon color="primary" />
              </IconButton>
            </Tooltip> : ""}



            <div style={{ marginTop: 2, marginBottom: 10 }}><Table
              rowHeight={40}
              rowsCount={hiwari.length}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
              touchScrollEnabled={true}
              width="100%"
              height={hiwari.length * 40 + 42}
              // width={window.innerWidth - 110}
              // height={window.innerHeight - 250}
              headerHeight={40}>
              {hiwari_columns.map(function (value, i) {
                return <Column
                  columnKey={value.key_name}
                  header={<SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={this.state.colSortDirs[value.key_name]}
                    style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                  // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                  cell={({ rowIndex, ...props }) => (

                    value.key === "name" ? <Koumoku column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Koumoku>
                      : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Seikyuu>


                        : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Amount>
                          : value.key === "tax" ? <Tax column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Tax>
                            : value.key === "is_daily_rate" ? <Hiwari column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Hiwari>
                              : value.key === "is_free_rent" ? <Freerent column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Freerent>
                                : value.key === "billing" ? <Billing column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Billing>

                                  : value.key === "remarks" ? <Remarks column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Remarks>



                                    : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Delete>

                                      : <Base column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} change_monies={this.change_monies}></Base>

                  )}

                  width={value.width}
                  isResizable={true}


                />
              }, this)}

            </Table > </div></span> : ""
        }

        <Chip style={{ float: "left", marginTop: 8 }} color="primary" avatar={<Avatar><b>{Number(moment(this.props.rent_contract_editor_forms.contract_date).format("D")) === 1 ? moment(this.props.rent_contract_editor_forms.contract_date).format("M") : moment(this.props.rent_contract_editor_forms.contract_date).add('months', 1).format("M")}</b></Avatar>} label="月" />
        <b style={{ float: "left", color: "red", marginLeft: 3, marginTop: 15 }}>毎月賃料のためフリーレントでも全額入力必要</b>
        <FormControl size="small" style={{ marginLeft: 10, marginBottom: 2, marginTop:10, float: "left" }}>
          <InputLabel >プラス月請求</InputLabel>
          <Select
            style={{ width: 100 }}
            value={this.props.rent_contract_editor_forms.pay_nextmonth}
            // defaultValue={0}
            name="pay_nextmonth"
            onChange={this.handleChange}
            variant="standard"
          >
            <MenuItem value={0}>-</MenuItem>
            <MenuItem value={1}>1ヵ月</MenuItem>
            <MenuItem value={2}>2ヵ月</MenuItem>
            <MenuItem value={3}>3ヵ月</MenuItem>
            <MenuItem value={4}>4ヵ月</MenuItem>
            <MenuItem value={5}>5ヵ月</MenuItem>
            <MenuItem value={6}>6ヵ月</MenuItem>
          </Select>
        </FormControl >

        <Tooltip title="追加" style={{ float: "right" }}>
          <IconButton aria-label="add" onClick={() => this.add_monies(1, 0)} size="large">
            <AddBoxIcon color="primary" />
          </IconButton>
        </Tooltip>


        <Button onClick={() => this.add_monies(1, 2)} variant="outlined" size="small" color="primary" style={{ float: "right", marginLeft: 5, marginTop: 8 }}>
          シャワー
        </Button>
        <Button onClick={() => this.add_monies(1, 1)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8 }}>
          浄水器
        </Button>




        {
          this.props.rent_contract_editor_forms.rent_contract_monies ? <div style={{ marginTop: 10, marginBottom: 10 }}> <Table

            rowHeight={40}
            rowsCount={month.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            width="100%"
            height={month.length * 40 + 42}
            // width={window.innerWidth - 110}
            // height={window.innerHeight - 250}
            headerHeight={40}>
            {month_columns.map(function (value, i) {
              return <Column
                columnKey={value.key_name}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key_name]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  value.key === "name" ? <Koumoku column={value} props={this.props} rowIndex={rowIndex} monies={month} change_monies={this.change_monies}></Koumoku>
                    : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={month} change_monies={this.change_monies}></Seikyuu>

                      : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={month} change_monies={this.change_monies}></Amount>
                        : value.key === "tax" ? <Tax column={value} props={this.props} rowIndex={rowIndex} monies={month} change_monies={this.change_monies}></Tax>
                          : value.key === "is_free_rent" ? <Freerent column={value} props={this.props} rowIndex={rowIndex} monies={month} change_monies={this.change_monies}></Freerent>
                            : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={month} change_monies={this.change_monies}></Delete>
                              : value.key === "billing" ? <Billing column={value} props={this.props} rowIndex={rowIndex} monies={month} change_monies={this.change_monies}></Billing>
                                : value.key === "remarks" ? <Remarks column={value} props={this.props} rowIndex={rowIndex} monies={month} change_monies={this.change_monies}></Remarks>

                                  : <Base column={value} props={this.props} rowIndex={rowIndex} monies={month}></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table > </div> : ""
        }

        <Chip style={{ float: "left", marginTop: 5 }} color="primary" label="一時金" />
        <Tooltip title="追加" style={{ float: "right" }}>
          <IconButton aria-label="add" onClick={() => this.add_monies(2, 0)} size="large">
            <AddBoxIcon color="primary" />
          </IconButton>
        </Tooltip>


        <Button onClick={() => this.add_monies(2, 17)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8, marginLeft: 5 }}>
          保ファ2年
        </Button>
        <Button onClick={() => this.add_monies(2, 16)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8, marginLeft: 5 }}>
          保ファ1年
        </Button>
        <Button onClick={() => this.add_monies(2, 15)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8, marginLeft: 5 }}>
          保単2年
        </Button>

        <Button onClick={() => this.add_monies(2, 14)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8, marginLeft: 5 }}>
          保単1年
        </Button>
        <Button onClick={() => this.add_monies(2, 13)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8, marginLeft: 5 }}>
          鍵交換
        </Button>
        <Button onClick={() => this.add_monies(2, 12)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8, marginLeft: 5 }}>
          抗菌
        </Button>
        <Button onClick={() => this.add_monies(2, 11)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8, marginLeft: 5 }}>
          消毒
        </Button>
        <Button onClick={() => this.add_monies(2, 8)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8, marginLeft: 5 }}>
          安心
        </Button>

        <Button onClick={() => this.add_monies(2, 5)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8 }}>
          仲介手数料
        </Button>
        <div style={{ clear: "both" }}></div>

        {
          this.props.rent_contract_editor_forms.rent_contract_monies ? <div style={{ marginTop: 10 }}><Table

            rowHeight={40}
            rowsCount={onetime.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            width="100%"
            height={onetime.length * 40 + 42}
            // width={window.innerWidth - 110}
            // height={window.innerHeight - 250}
            headerHeight={40}>
            {onetime_columns.map(function (value, i) {
              return <Column
                columnKey={value.key_name}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key_name]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  value.key === "name" ? <Koumoku column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_monies={this.change_monies}></Koumoku>
                    : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_monies={this.change_monies}></Seikyuu>
                      : value.key === "amount_month" ? <Month column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_monies={this.change_monies}></Month>
                        : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_amount={this.change_amount} change_monies={this.change_monies}></Amount>
                          : value.key === "tax" ? <Tax column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_monies={this.change_monies}></Tax>
                            : value.key === "works" ? <Works column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_monies={this.change_monies}></Works>
                              : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_monies={this.change_monies}></Delete>
                                : value.key === "billing" ? <Billing column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_monies={this.change_monies}></Billing>
                                  : value.key === "remarks" ? <Remarks column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_monies={this.change_monies}></Remarks>
                                    : <Base column={value} props={this.props} rowIndex={rowIndex} monies={onetime} change_monies={this.change_monies}></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table ></div> : ""
        }
        {/* <Divider /> */}



        <Card style={{ margin: 5 }}>
          <CardContent>
            <Grid
              justifyContent="space-between" // Add it here :)
              container

            >
              <Grid item xs={12}>

                <TextField
                  style={{ float: "left" }}
                  id="application_fee"
                  label="申込金"
                  name="application_fee"
                  // type="number"
                  // className={classes.textField}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    min: 0, style: { textAlign: 'right' },
                    endAdornment: <InputAdornment position="end">円</InputAdornment>,
                  }}

                  value={this.props.rent_contract_editor_forms.application_fee}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  onChange={this.handleChange_application_fee}
                  variant='standard'
                />


                <TextField
                  // fullWidth
                  id="application_fee_date"
                  label="申込金受取日"
                  type="date"
                  name="application_fee_date"
                  size="small"
                  // style={{marginLeft:20}}
                  value={moment(this.props.rent_contract_editor_forms.application_fee_date).format("YYYY-MM-DD")}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  variant="standard"
                  onChange={this.handleChange}
                  style={{ marginLeft: 20, marginRight: 20, width: 170, marginTop:19 }}
                  // helperText="申込金受取日を選択してください"
                />

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


                  <KeyboardDatePicker

                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline2"
                    label="申込金受取日"
                    style={{ marginLeft: 10, width: 150 }}
                    autoOk={true}
                    InputLabelProps={{ shrink: this.props.rent_contract_editor_forms.application_fee_date }}
                    // error={true}
                    // helperText="申込金受取日を選択してください"
                    //   showTodayButton={true}
                    name="application_fee_date"
                    value={this.props.rent_contract_editor_forms.application_fee_date ? this.props.rent_contract_editor_forms.application_fee_date : null}
                    onChange={this.change_application_fee_date}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    invalidDateMessage={"日付の形式が違います"}
                  />


                </MuiPickersUtilsProvider> */}


                <FormControlLabel


                  control={
                    <Checkbox
                      style={{ float: "left" }}
                      name="application_fee_payor"
                      checked={this.props.rent_contract_editor_forms.application_fee_payor ? true : false}
                      onChange={this.handleChange}
                      value="1"
                      color="primary"
                    />
                  }
                  label="入居者負担"
                />
              </Grid>
              <Grid>
                <CssTextField
                  id="contractSum"
                  label="契約者合計"
                  // value={String(this.props.estimateMonies1.reduce((p, x) => p + ((x.billing === 0 && x.rentMAccountId !== -1 && x.isFreeRent === 0) ? (x.amount + x.taxAmount) : 0), 0) + this.props.estimateMonies2.reduce((p, x) => p + ((x.billing === 0 && x.rentMAccountId !== -1) ? (x.amount + x.taxAmount) : 0), 0)
                  //   + this.props.estimateMonies5.reduce((p, x) => p + ((x.billing === 0 && x.rentMAccountId !== -1 && x.isFreeRent === 0) ? (x.amount + x.taxAmount) : 0), 0)
                  //   + this.props.estimateMonies1.reduce((p, x) => p + ((x.billing === 0 && x.rentMAccountId !== -1) ? (x.amount + x.taxAmount) : 0), 0) * this.props.estimateData.payNextmonth
                  //   - (this.props.estimateData.applicationFeePayor === 0 ? this.props.estimateData.applicationFee : 0)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + "円"}
                  style={{ marginTop: "16px" }}
                  // defaultValue={
                  value={amount_sum}

                  //   String(this.props.estimateMonies1.reduce((p, x) => p + x.amount+x.taxAmount, 0)+this.props.estimateMonies2.reduce((p, x) => p + x.amount+x.taxAmount, 0)+this.props.estimateMonies5.reduce((p, x) => p + x.amount+x.taxAmount, 0)-this.props.estimateData.applicationFee).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')+"円"}
                  // // className={classes.textField}
                  // margin="normal"
                  InputProps={{
                    readOnly: true,
                    inputComponent: NumberFormatCustom,
                    endAdornment: <InputAdornment position="end">円</InputAdornment>,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                // style={{float:"left"}}
                />

                {this.props.rent_contract_editor_forms.contract_form ? <CssTextField2
                  id="contractSum"
                  label="入居者合計"
                  value={amount_n_sum}

                  // value={

                  // String(this.props.estimateMonies1.reduce((p, x) => p + ((x.billing === 1 && x.rentMAccountId !== -1 && x.isFreeRent === 0) ? (x.amount + x.taxAmount) : 0), 0) + this.props.estimateMonies2.reduce((p, x) => p + ((x.billing === 1 && x.rentMAccountId !== -1) ? (x.amount + x.taxAmount) : 0), 0) + this.props.estimateMonies5.reduce((p, x) => p + ((x.billing === 1 && x.rentMAccountId !== -1 && x.isFreeRent === 0) ? (x.amount + x.taxAmount) : 0), 0)
                  //   + this.props.estimateMonies1.reduce((p, x) => p + ((x.billing === 1 && x.rentMAccountId !== -1) ? (x.amount + x.taxAmount) : 0), 0) * this.props.estimateData.payNextmonth
                  //   - (this.props.estimateData.applicationFeePayor === 1 ? this.props.estimateData.applicationFee : 0)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + "円"}
                  // className={classes.textField}
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                    inputComponent: NumberFormatCustom,
                    endAdornment: <InputAdornment position="end">円</InputAdornment>,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // className={classes.textField}

                  variant="outlined"
                  style={{ marginLeft: "10px" }}
                /> : ""}


              </Grid>


              <Grid item xs={12}>

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


                  <KeyboardDatePicker

                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline2"
                    label="請求締め日"

                    style={{ marginLeft: 0, width: 200 }}
                    autoOk={true}
                    InputLabelProps={{ shrink: this.props.rent_contract_editor_forms.billing_closing_date }}
                    // error={true}
                    // helperText="申込金受取日を選択してください"
                    //   showTodayButton={true}
                    name="billing_closing_date"
                    value={this.props.rent_contract_editor_forms.billing_closing_date ? this.props.rent_contract_editor_forms.billing_closing_date : null}
                    onChange={this.change_billing_closing_date}



                    // error={true}
                    helperText="請求締め日を選択してください"
                    //   showTodayButton={true}
                    // name="billing_closing_date"
                    // value={this.props.rent_contract_editor_forms.billing_closing_date}
                    // onChange={this.handleApplicationDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    invalidDateMessage={"日付の形式が違います"}
                  />


                </MuiPickersUtilsProvider> */}

                <TextField
                  // fullWidth
                  id="billing_closing_date"
                  label="請求締め日"
                  type="date"
                  name="billing_closing_date"
                  size="small"
                  // style={{marginLeft:20}}
                  value={moment(this.props.rent_contract_editor_forms.billing_closing_date).format("YYYY-MM-DD")}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  variant="standard"
                  onChange={this.handleChange}
                  style={{ marginLeft: 10, marginRight: 20, marginTop:20, marginBottom:20, width: 170 }}
                  helperText="請求締め日を選択してください"
                />



                {this.props.rent_contract_editor_forms.contract_form ? <>
                
                  <TextField
                    // fullWidth
                    id="billing_closing_date_nyuukyosha"
                    label="請求締め日(入居者)"
                    type="date"
                    name="billing_closing_date_nyuukyosha"
                    size="small"
                    // style={{marginLeft:20}}
                    value={moment(this.props.rent_contract_editor_forms.billing_closing_date_nyuukyosha).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    onChange={this.handleChange}
                    style={{ marginLeft: 10, marginRight: 20, marginTop:20, marginBottom:20, width: 170 }}
                    helperText="入居者の請求締め日を選択してください"
                  />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


                  <KeyboardDatePicker

                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline2"
                    label="請求締め日(入居者)"

                    style={{ marginLeft: 30, width: 200 }}
                    autoOk={true}
                    InputLabelProps={{ shrink: this.props.rent_contract_editor_forms.billing_closing_date_nyuukyosha }}
                    // error={true}
                    // helperText="申込金受取日を選択してください"
                    //   showTodayButton={true}
                    name="billing_closing_date"
                    value={this.props.rent_contract_editor_forms.billing_closing_date_nyuukyosha ? this.props.rent_contract_editor_forms.billing_closing_date_nyuukyosha : null}
                    onChange={this.change_billing_closing_date_nyuukyosha}



                    // error={true}
                    helperText="入居者の請求締め日を選択してください"
                    //   showTodayButton={true}
                    // name="billing_closing_date"
                    // value={this.props.rent_contract_editor_forms.billing_closing_date}
                    // onChange={this.handleApplicationDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    invalidDateMessage={"日付の形式が違います"}
                  />


                </MuiPickersUtilsProvider> */}
                </> : ""}

              </Grid>
              <Grid item xs={12}>

                <FormControlLabel


                  control={
                    <Checkbox
                      style={{ float: "left" }}
                      name="receipt"
                      checked={this.props.rent_contract_editor_forms.receipt ? true : false}
                      onChange={this.handleChange}
                      value="1"
                      color="primary"
                    />
                  }
                  label="領収書必要"
                />

                {(this.props.rent_contract_editor_forms.division === 3 || this.props.rent_contract_editor_forms.division === 6) ? <FormControl variant="outlined" >
                  <InputLabel id="demo-simple-select-outlined-label">オーナー支払方法</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    style={{ width: 120 }}
                    name="owner_payment_way"
                    value={this.props.rent_contract_editor_forms.owner_payment_way}
                    onChange={this.handleChange}
                    label="オーナー支払方法"
                    margin="dense"
                    size="small"
                  >

                    <MenuItem value={0}>-</MenuItem>
                    <MenuItem value={1}>振込</MenuItem>
                    <MenuItem value={2}>現金</MenuItem>

                  </Select>




                </FormControl> : ""}
              </Grid>
              <Grid item xs={12}>

                <TextField
                  id="outlined-multiline-static"
                  label="請求書備考"
                  name="estimate_remarks"
                  value={this.props.rent_contract_editor_forms.estimate_remarks}
                  multiline
                  rows={4}
                  onChange={this.handleChange}
                  style={{ width: 400 }}
                  variant="outlined"
                />

              </Grid>




            </Grid>
          </CardContent>
        </Card>

        {/* <Divider /> */}


        <Chip style={{ float: "left", marginTop: 5 }} color="secondary" label="更新時" />
        <Button onClick={() => this.add_monies(3, 18)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8 }}>
          継続保証料
        </Button>
        <Button onClick={() => this.add_monies(3, 19)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8 }}>
          更新料
        </Button>
        {
          this.props.rent_contract_editor_forms.rent_contract_monies ? <div style={{ marginTop: 10 }}><Table

            rowHeight={40}
            rowsCount={koushin.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            width="100%"
            height={koushin.length * 40 + 42}
            // width={window.innerWidth - 110}
            // height={window.innerHeight - 250}
            headerHeight={40}>
            {koushin_columns.map(function (value, i) {
              return <Column
                columnKey={value.key_name}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key_name]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  value.key === "name" ? <Koumoku column={value} props={this.props} rowIndex={rowIndex} monies={koushin} change_monies={this.change_monies}></Koumoku>
                    : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={koushin} change_monies={this.change_monies}></Seikyuu>

                      : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={koushin} change_monies={this.change_monies}></Amount>
                        : value.key === "tax" ? <Tax column={value} props={this.props} rowIndex={rowIndex} monies={koushin} change_monies={this.change_monies}></Tax>
                          : value.key === "remarks" ? <Remarks column={value} props={this.props} rowIndex={rowIndex} monies={koushin} change_monies={this.change_monies}></Remarks>

                            : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={koushin} change_monies={this.change_monies}></Delete>
                              : value.key === "each_year" ? <EachYear column={value} props={this.props} rowIndex={rowIndex} monies={koushin} change_monies={this.change_monies}></EachYear>
                                : <Base column={value} props={this.props} rowIndex={rowIndex} monies={koushin} change_monies={this.change_monies}></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table ></div> : ""
        }

        <Chip style={{ float: "left", marginTop: 5 }} color="secondary" label="退去精算" />
        <Button onClick={() => this.add_monies(4, 22)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8 }}>
          退去時美装代
        </Button>
        <Button onClick={() => this.add_monies(4, 23)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8 }}>
          実費
        </Button>
        <Button onClick={() => this.add_monies(4, 20)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8 }}>
          敷引1
        </Button>
        <Button onClick={() => this.add_monies(4, 21)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8 }}>
          敷引2
        </Button>
        <Button onClick={() => this.add_monies(4, 24)} variant="outlined" size="small" color="primary" style={{ float: "right", marginTop: 8 }}>
          込み
        </Button>
        {
          this.props.rent_contract_editor_forms.rent_contract_monies ? <div style={{ marginTop: 10 }}><Table

            rowHeight={40}
            rowsCount={taikyo.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            width="100%"
            height={taikyo.length * 40 + 42}
            // width={window.innerWidth - 110}
            // height={window.innerHeight - 250}
            headerHeight={40}>
            {taikyo_columns.map(function (value, i) {
              return <Column
                columnKey={value.key_name}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key_name]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  value.key === "name" ? <Koumoku column={value} props={this.props} rowIndex={rowIndex} monies={taikyo} change_monies={this.change_monies}></Koumoku>
                    : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={taikyo} change_monies={this.change_monies}></Seikyuu>
                      : value.key === "amount_month" ? <Month column={value} props={this.props} rowIndex={rowIndex} monies={taikyo} change_monies={this.change_monies}></Month>

                        : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={taikyo} change_monies={this.change_monies}></Amount>
                          : value.key === "tax" ? <Tax column={value} props={this.props} rowIndex={rowIndex} monies={taikyo} change_monies={this.change_monies}></Tax>

                            : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={taikyo} change_monies={this.change_monies}></Delete>
                              : value.key === "remarks" ? <Remarks column={value} props={this.props} rowIndex={rowIndex} monies={taikyo} change_monies={this.change_monies}></Remarks>
                                : <Base column={value} props={this.props} rowIndex={rowIndex} monies={taikyo} change_monies={this.change_monies}></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table ></div> : ""
        }




        <Card style={{ margin: 5 }}>
          <CardContent>
            <Grid
              justifyContent="space-between" // Add it here :)
              container
            >
              <Grid item xs={12}>

                <FormControl variant="outlined" >
                  <InputLabel id="demo-simple-select-outlined-label">違約金</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    style={{ width: 200 }}
                    name="contract_penalty"
                    value={this.props.rent_contract_editor_forms.contract_penalty}
                    onChange={this.handleChange}
                    label="違約金"
                    margin="dense"
                    size="small"
                  >
                    <MenuItem value={0}>-</MenuItem>
                    {this.props.m_penalties.map(function (value) {
                      return <MenuItem value={value.id}>{value.penalty_name}</MenuItem>
                    })}



                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    rent_contract_details: state.contracts.rent_contract_details,
    tm_chinryou_koumoku: state.masterReducer.tm_chinryou_koumoku,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
    m_penalties: state.contracts.m_penalties,
    contract_check_message: state.contracts.contract_check_message,
    rent_m_money_templates: state.contracts.rent_m_money_templates,
  }

}
function mapDispatchToProps(dispatch) {
  return {


    change_responseid(state) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state) {
      dispatch(Responses.change_modalResponseDetail(state))
    },

    change_rent_contract_id(state) {
      dispatch(Contracts.change_rent_contract_id(state))
    },
    change_rent_contract_editor_disp(state) {
      dispatch(Contracts.change_rent_contract_editor_disp(state))
    },
    change_rent_contract_editor_forms(state) {
      dispatch(Contracts.change_rent_contract_editor_forms(state))
    },
    check_rent_contract_editor_forms(state) {
      dispatch(Contracts.check_rent_contract_editor_forms(state))
    },
    set_contracts_boshu_chinryou(state) {
      dispatch(Contracts.set_contracts_boshu_chinryou(state))
    },
    set_rent_contract_checks(state) {
      dispatch(Contracts.set_rent_contract_checks(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);