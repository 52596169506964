import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import '../../Css/Grid.css';
import Loading from '../Layout/LoadingComponent';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import './Css/Grid.css'



interface Props {
    rent_responses_duplicate_list: any
    rent_responses_duplicate_loading: any
}

interface State {
    modalmail: boolean,
    modalselect: boolean,
    modalMatching: boolean,
    mailMatchingId: number,
    refresh: boolean,
    modalMailDelete: boolean,
    columns: any,
    colSortDirs: any,


}



const columns = [
    // { key: 'selected', name: '', width: 40 },
    { key: 'detail', name: '詳細', width: 50 },
    { key: 'media', name: '内容', width: 120 },
    { key: 'date', name: '反響日時', width: 150},
    { key: 'name', name: 'お客様名', width: 200},
    { key: 'tel1', name: '連絡先', width: 120 },
    { key: 'email1', name: 'メール', width: 200 },
    { key: 'building_name', name: '反響内容', width: 150 },
    
    // { key: 'delete', name: '削除', width: 60 },
];



var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
  };
  
  
//, backgroundColor:props.rent_responses_duplicate_list[rowIndex].selected == true ? "#ffc0cb" : "#c8fac8"
  const Base = ({ column, props, rowIndex }: any) => 
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_responses_duplicate_list[rowIndex][column.key] ? props.rent_responses_duplicate_list[rowIndex][column.key] : ""}
    {/* {props.rent_responses_duplicate_list} */}
  </Cell>;

const ResponseDate = ({ column, props, rowIndex }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.rent_responses_duplicate_list[rowIndex][column.key] ? moment(props.rent_responses_duplicate_list[rowIndex][column.key]).utc().format("YYYY年MM月DD日 H:mm") : ""}
</Cell>;

const Media = ({ column, props, rowIndex }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
    {
        (() => {

                const media = props.rent_responses_duplicate_list[rowIndex].rent_m_medium_id === 1 ? <span className="badge badge-primary">アパマン</span>
                    : props.rent_responses_duplicate_list[rowIndex].rent_m_medium_id === 14 ? <span className="badge badge-success">スーモ</span>
                        : props.rent_responses_duplicate_list[rowIndex].rent_m_medium_id === 16 ? <span className="badge badge-warning">ホームズ</span>
                            : props.rent_responses_duplicate_list[rowIndex].rent_m_medium_id === 21 ? <span className="badge badge-danger">アットホーム</span>
                                : props.rent_responses_duplicate_list[rowIndex].rent_m_medium_id === 29 ? <span className="badge badge-light">すまいズ</span>
                                    : props.rent_responses_duplicate_list[rowIndex].rent_m_medium_id === 2 ? <span className="badge badge-info">CLASO.</span>
                                        : ""

                const shop = props.rent_responses_duplicate_list[rowIndex].section_id === 1 ? <span className="badge badge-success">番</span>
                    : props.rent_responses_duplicate_list[rowIndex].section_id === 2 ? <span className="badge badge-warning">レ</span>
                        : props.rent_responses_duplicate_list[rowIndex].section_id === 3 ? <span className="badge badge-danger">駅</span>
                            : props.rent_responses_duplicate_list[rowIndex].section_id === 4 ? <span className="badge badge-dark">東</span>
                                : props.rent_responses_duplicate_list[rowIndex].section_id === 6 ? <span className="badge badge-light">公</span>
                                    : props.rent_responses_duplicate_list[rowIndex].section_id === 17 ? <span className="badge badge-info">サ</span>
                                        : props.rent_responses_duplicate_list[rowIndex].section_id === 47 ? <span className="badge badge-secondary">通</span>
                                            : ""


                return <span>{shop} {media}</span>
        
        })()
    }
</Cell>;



const Detail = ({ column, props, rowIndex, openHankyo }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
    {
        (() => {
          
          return (
            <IconButton
              style={{padding:2}}
              onClick={(e)=>openHankyo(props.rent_responses_duplicate_list[rowIndex].id)}
              size="large"><OpenInNewIcon /></IconButton>
          );
            // return <Button variant="contained" color='primary' size="small" onClick={(e)=>openHankyo(props.rent_responses_duplicate_list[rowIndex].id)} >反響詳細</Button>
        })()
    }
</Cell>;


class CustomerDuplicateList extends Component<Props, State>{
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            // height: window.innerHeight,
            modalselect: false,
            modalMatching: false,
            mailMatchingId: 0,
            modalmail: false,
            refresh: false,
            modalMailDelete: false,
            columns: columns,
            colSortDirs: {},
        };

        this.refresh = this.refresh.bind(this);


        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this._onSortChange = this._onSortChange.bind(this);

        // this.RowClick = this.RowClick.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);


        // alert(this.props.mail_id)
    }


 


    refresh() {
        this.setState({
            refresh: true
        });
    }

    _onColumnResizeEndCallback(newColumnWidth:any, columnKey:any) {

        let columnsset = this.state.columns.map(function (value:any) {
          if (value.key === columnKey) {
            value.width = newColumnWidth
          }
          return value
        })
    
        this.setState({ columns: columnsset })
    
      }

    _onSortChange(columnKey: any, sortDir: any) {

        // alert(columnKey)
        // alert(_defaultSortIndexes.indexOf(columnKey))
        let rent_responses_duplicate_list = this.props.rent_responses_duplicate_list;
        rent_responses_duplicate_list.sort((indexA:any, indexB:any) => {

          var valueA = indexA[columnKey];
          var valueB = indexB[columnKey];
          var sortVal = 0;
          if (valueA > valueB) {
            sortVal = 1;
          }
          if (valueA < valueB) {
            sortVal = -1;
          }
    
          if (!valueA && !valueB) {
            sortVal = 0
          }
          if (!valueA) {
            sortVal = -1
          }
          if (!valueB) {
            sortVal = 1
          }
    
    
          if (sortVal !== 0 && sortDir === SortTypes.DESC) {
            sortVal = sortVal * -1;
          }
    
          return sortVal;
        });
        // this.props.get_rent_responses_duplicate_list_success(rent_responses_duplicate_list)
        this.setState({
          // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
          colSortDirs: {
            [columnKey]: sortDir,
          },
        });
    }



    openHankyo(id:any){
        window.open(`/customer/` + id, '_blank', 'noopener')
    }

    _rowClassNameGetter(index:any){//#ffb8b8
        
      if(this.props.rent_responses_duplicate_list && this.props.rent_responses_duplicate_list[index]) {
        return "normal"
          
      }else{
        return "normal"
      }
    }


    render() {

        return (
            <div>
            {this.props.rent_responses_duplicate_loading ? <div style={{height:400}}><Loading /></div> :
            <Table
              rowHeight={40}
              rowsCount={this.props.rent_responses_duplicate_list.length}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
              touchScrollEnabled={true}
              width={((window.innerWidth-60)/2)-20}
              height={400}
              headerHeight={40}
            //   rowClassNameGetter={"aaaaa"}
            //   onRowClick={this.RowClick}
              className='gridAttentionNormalList'
              rowClassNameGetter={this._rowClassNameGetter}
                // rowClassNameGetter = {'normal'}
              >

              {this.state.columns.map((value:any, i:any) => {
                return <Column
                  columnKey={value.key}
                  header={<Cell 
                    // onSortChange={this._onSortChange}
                    // sortDir={this.state.colSortDirs[value.key_name]}
                    // style={{ whiteSpace: "nowrap" }}
                    >{value.name}</Cell>}

                  // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                  cell={({ rowIndex, ...props }) => (


                    value.key === "disp_format" ? <Base column={value} props={this.props} rowIndex={rowIndex}></Base>
                    : value.key === "date" ? <ResponseDate column={value} props={this.props} rowIndex={rowIndex}></ResponseDate>
                    : value.key === "media" ? <Media column={value} props={this.props} rowIndex={rowIndex}></Media>
                    : value.key === "detail" ? <Detail column={value} props={this.props} rowIndex={rowIndex} openHankyo={this.openHankyo}></Detail>
                    // : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} togglemodalMailDeleteID={this.togglemodalMailDeleteID}></Delete>
                      : <Base column={value} props={this.props} rowIndex={rowIndex}></Base>

                  )}

                  width={value.width}
                  isResizable={true}
                //   fixed={value.frozen}

                />
              }, this.props)}


            </Table >
            }






            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
  return {
    // responselist: state.responsesReducer.responselist,
    // responselist_loading: state.responsesReducer.responselist_loading,
    // window_height: state.masterReducer.window_height,
    // response_matching_select_list: state.responsesReducer.response_matching_select_list,
    // response_matching_select_list_loading: state.responsesReducer.rent_responses_duplicate_list_select_loading

    rent_responses_duplicate_list: state.customersReducer.rent_responses_duplicate_list,
    rent_responses_duplicate_loading: state.customersReducer.rent_responses_duplicate_loading,
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    // get_response_matching_select_list_success(state: any) {
    //   dispatch(Responses.get_response_matching_select_list_success(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDuplicateList);