const now = new Date()

export default [
  {
    id: 0,
    title: '終日打ち合わせ',
    allDay: true,
    start: new Date('2019/07/1'),
    end: new Date('2019/07/1'),
  },
  {
    id: 1,
    title: '河西さん研修',
    allDay: true,
    start: new Date('2019/07/08 00:00'),
    end: new Date('2019/07/08 00:00'),
  },

  {
    id: 2,
    title: '地域貢献活動',
    start: new Date('2019/07/26 16:00'),
    end: new Date('2019/07/26 16:30'),
  },

  {
    id: 3,
    title: '環境整備点検',
    allDay: true,
    start: new Date('2020/10/30'),
    end: new Date('2020/10/30'),
  },
  {
    id: 4,
    title: '経営会',
    start: new Date('2020/10/30 10:00'),
    end: new Date('2020/10/30 12:00'),
  },
  {
    id: 5,
    hexColor: "ff69b4",
    title: '高山さん研修',
    start: new Date('2020/10/28 10:00'),
    end: new Date('2020/10/28 12:00'),
    desc: '高山さん研修があります。',
  },
  {
    id: 6,
    title: '第二四半期表彰式',
    start: new Date('2019/07/16 16:00'),
    end: new Date('2019/07/16 18:30'),
  },
  // {
  //   id: 14,
  //   title: 'Today',
  //   start: new Date(new Date().setHours(new Date().getHours() - 3)),
  //   end: new Date(new Date().setHours(new Date().getHours() + 3)),
  // },
  // {
  //   id: 15,
  //   title: 'Point in Time Event',
  //   start: now,
  //   end: now,
  // },
]