const authentication = require('../../../react-azure-adb2c2').default;

export const get_setsubi = (values: any) => {

    const url = process.env.REACT_APP_API_ROOMS_EX_URL + `/api/v1/t/room/setsubi/list/` + values.tatemono_id + "/" + values.room_id;
    // const url = `http://localhost:5000/api/v1/t/room/setsubi/list/` + values.tatemono_id + "/" + values.room_id;

    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}
