import React, { Component } from 'react'
import { connect } from 'react-redux'
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import * as CustomerContacts from './Saga/CustomerContacts';
import * as Responses from '../ResponseDetail/Saga/Responses';
var moment = require('moment-timezone');
class ContactsButtons extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

        this.onClickRaiten = this.onClickRaiten.bind(this);

        this.props.change_customer_contacts_response_end(this.props.responsedetail.end_response_date ? true : false)
    }

    onClickRaiten(){

        if(this.props.responsedetail.student_independent == 0){
            // 社会人か学生が選ばれてない場合は反響入力画面を開く
            this.props.open_response_detail_dialog()
            // this.props.open_response_edit()
            
        }else{
            this.props.toggle({
                id: 0,
                user_id: this.props.user_details.id,
                rent_response_id: this.props.responsedetail.id,
                section_id: this.props.responsedetail.section_id,
                way: "3",
                returning: 0,
                going: null,
                remarks: "",
                date: null,
                intend: 0,
                intend_time: 0,
                intend_time2: 0,
                going_time: 0,
                going_user_id: 0,
                is_application: 0,
                rent_m_going_reason_id: 0,
                going_section: -1,
                online: 0,
                no_coming: 0,
                is_prepare: 0,
                agent_name: "",
                agent_etc: "",
            })
        }
        
    }


    render() {
        return (
            <div>
                <span className="float-left">対応状況</span>
                {!this.props.customer_contacts_response_end ? <span>

                    <button type="button" className="btn btn-sm btn-success float-left" style={{ marginRight: '5px', marginLeft: '5px' }} onClick={this.props.togglescheduler}>カレンダー</button>
                    {/* <button type="button" className="btn btn-sm btn-warning float-left" style={{ marginRight: '5px' }} onClick={this.props.togglereserve}>来店予約</button> */}
                    {this.props.customer_contacts_timeline ? <button
                        type="button"
                        style={{ marginRight: '5px' }}
                        className="btn btn-sm btn-warning float-left"
                        onClick={() => this.props.change_customer_contacts_timeline(false)}>リスト</button> : <button
                            type="button"
                            style={{ marginRight: '5px' }}
                            className="btn btn-sm btn-info float-left"
                            onClick={() => this.props.change_customer_contacts_timeline(true)}>タイムライン</button>}
                    <button
                        type="button"
                        className="btn btn-sm btn-primary float-left"
                        style={{ marginRight: '5px' }}
                        onClick={() => this.onClickRaiten()}>来店</button>
                    <button
                        type="button"
                        className="btn btn-sm btn-success float-left"
                        onClick={() => this.props.toggle({
                            id: 0,
                            user_id: this.props.user_details.id,
                            rent_response_id: this.props.responsedetail.id,
                            section_id: this.props.responsedetail.section_id,
                            way: "1",
                            returning: 0,
                            going: 0,
                            remarks: "",
                            date: null,
                            intend: 0,
                            intend_time: 0,
                            intend_time2: 0
                        })}>追客</button>




                    {//mPositionId　4:店長5:副店長10:課長(神原さん)　1191:株屋根 1262:福井　　　　　　　×26:反響対策課37:新入社員55:web戦略課
                    }
                    {(this.props.user_details.mPositionId === 4 || this.props.user_details.mPositionId === 5 || this.props.user_details.mPositionId === 10 || 
                    this.props.user_details.id === 1191 || this.props.user_details.id === 1262 || 
                    this.props.user_details.amSectionId === 10) ? <button
                        type="button"
                        className="btn btn-sm btn-danger float-right"
                        onClick={this.props.toggleend}>追客終了</button> : ""}

                    <button
                        type="button"
                        style={{ marginRight: '5px' }}
                        disabled={this.props.customer_details.mail1 ? false: true}
                        className="btn btn-sm btn-primary float-right"
                        onClick={this.props.toggleautosends} >自動追客</button>
                </span>
                    : <div class="alert alert-dark" role="alert">追客終了：{moment(this.props.responsedetail.end_response_date).format('YYYY年MM月DD日')}　
                        {this.props.responsedetail.end_response_reason > 0 && this.props.rent_m_response_end_reasons.length > 0 ? this.props.rent_m_response_end_reasons.filter(a=>a.id == this.props.responsedetail.end_response_reason)[0].name : ""}  {this.props.responsedetail.end_response_remarks}

                        {this.props.toggle_end_cancel_confirm ? <Alert severity="error">本当に取消しますか？
                            <Button variant="contained" color="secondary" style={{ margin: 10 }} onClick={this.props.toggle_end_cancel_set}>
                                取り消す
                            </Button> <Button variant="contained" style={{ margin: 10 }} onClick={this.props.toggle_end_cancel}>閉じる</Button></Alert> : <button
                                type="button"
                                className="btn btn-sm btn-danger float-right"
                                onClick={this.props.toggle_end_cancel}>追客終了取消</button>}</div>




                }




            </div>
        )
    }
}
// export default connect()(ContactsButtons)


const mapStateToProps = state => {
    return {
        users: state.masterReducer.users,
        // users_select: state.masterReducer.users_select,
        section_id: state.masterReducer.section_id,
        user_details: state.masterReducer.user_details,
        customer_contacts_response_end: state.customerContactsReducer.customer_contacts_response_end,
        // customer_contacts_calendar: state.customerContactsReducer.customer_contacts_calendar,
        responsedetail: state.responsesReducer.responsedetail,
        rent_m_response_end_reasons: state.responsesReducer.rent_m_response_end_reasons,
        customer_contacts_timeline: state.customerContactsReducer.customer_contacts_timeline,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
        customer_details: state.customersReducer.customer_details,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_customer_contacts_response_end(state) {
            dispatch(CustomerContacts.change_customer_contacts_response_end(state))
        },
        change_customer_contacts_timeline(state) {
            dispatch(CustomerContacts.change_customer_contacts_timeline(state))
        },
        change_response_detail_dialog_disp(state) {
            dispatch(Responses.change_response_detail_dialog_disp(state))
        },
        change_rent_responses_editor(state) {
            dispatch(Responses.change_rent_responses_editor(state))
        },
        open_response_detail_dialog(state) {
            dispatch(Responses.open_response_detail_dialog(state))
        },
        open_response_edit(state) {
            dispatch(Responses.open_response_edit(state))
        },
        // delete_rent_customer_contacts(state) {
        //     dispatch(CustomerContacts.delete_rent_customer_contacts(state))
        // },
        // submit(state) {

        //     dispatch(submit(state))
        // },
        // change_rent_responses_end(state) {
        //     dispatch(CustomerContacts.change_rent_responses_end(state))
        // },
        // add_auto_send_modal(state) {
        //     dispatch(CustomerContacts.add_auto_send_modal(state))
        // },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactsButtons);