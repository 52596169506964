
const authentication = require('../../../react-azure-adb2c2').default;

var moment = require('moment-timezone');



export const update = (values: any) => {
  values.rent_customer_contacts.intend = values.rent_customer_contacts.intend == true ? 1 : 0;
  values.rent_customer_contacts.going = values.rent_customer_contacts.going == true ? 1 : values.rent_customer_contacts.going;

  console.log(values)

  const token = authentication.getAccessToken();
  // const url = `http://localhost:5000/v1/rent/customer/contacts/allsendline`;
  const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/customer/contacts/allsendline`;

  // alert(JSON.stringify(values))

  // alert(url)
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  })
    .then(res => res.json())
    .then(payload2 => ({ payload2 }))
    .catch(error2 => ({ error2 }));


}


export const list = (values: any) => {
    
  // const url = `http://localhost:5000/v1/rent/customer/contacts/line/` + values;
  const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/customer/contacts/line/` + values;

  const token = authentication.getAccessToken();
  //const search = values ? values : {};
  // alert(url)

  return fetch(url, {
      method: 'get',
      // body: JSON.stringify(values),

      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  }).then(res => res.json())
      .then(payload => ({ payload }))
      .catch(error => ({ error }));

}

export function contact_delete(id: number) {

  const token = authentication.getAccessToken();


  // const url = `http://localhost:5000/v1/rent/customer/contacts/delete/` + id;
  // const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/customer/contacts/delete/` + id;

  const url = process.env.REACT_APP_API_CRM_DELETE_URL + `/v1/rent/customer/contacts/delete/` + id;

  // alert(url)
  return fetch(url, {
    method: 'get',

    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  })
    .then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));

}