import React, { Component } from 'react';

import { connect } from 'react-redux';

import ja from "date-fns/locale/ja";

import "react-datepicker/dist/react-datepicker.css";

import '../../Css/Table.css';

import * as Calendarsaga from './Saga/Calendar';

import Grid from '@mui/material/Grid';


import Button from '@mui/material/Button';

import moment from 'moment';
import StoreIcon from '@mui/icons-material/Store';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Event from '@mui/icons-material/Event';

import Today from '@mui/icons-material/Today';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import NavigateNext from '@mui/icons-material/NavigateNext';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import './Css/Calendar.css';


class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,

    };

    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeDateButton = this.onChangeDateButton.bind(this);

  }

  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")


  }


  onChangeDate(value) {
    if(!value){
      return
    }

    let calendar_all_store_list_form = this.props.calendar_all_store_list_form
    calendar_all_store_list_form.start = moment(value).startOf('day').format('YYYY/MM/DD HH:mm:ss')
    calendar_all_store_list_form.end = moment(value).endOf('day').format('YYYY/MM/DD HH:mm:ss')
    this.props.change_calendar_all_store_list_form(calendar_all_store_list_form);

    if(calendar_all_store_list_form.start != 'Invalid date' && calendar_all_store_list_form.end != 'Invalid date'){
      this.props.get_calendar_list();
    }


    this.setState({
      refresh: !this.state.refresh    //再描画
    });
  }

  onChangeDateButton(value) {

    let calendar_all_store_list_form = this.props.calendar_all_store_list_form

    if (value == 0) {
      calendar_all_store_list_form.start = moment().startOf('day').format('YYYY/MM/DD HH:mm:ss')
      calendar_all_store_list_form.end = moment().endOf('day').format('YYYY/MM/DD HH:mm:ss')
    } else {
      calendar_all_store_list_form.start = moment(calendar_all_store_list_form.start).add(value, "days").startOf('day').format('YYYY/MM/DD HH:mm:ss')
      calendar_all_store_list_form.end = moment(calendar_all_store_list_form.end).add(value, "days").endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }


    this.props.change_calendar_all_store_list_form(calendar_all_store_list_form);

    this.props.get_calendar_list();

    this.setState({
      refresh: !this.state.refresh    //再描画
    });
  }



  onChangeType(type) {
    this.props.change_calendar_type(type)

    // let calendar_list_form = this.props.calendar_list_form

    // if(type == 0){// 店舗別
    //   calendar_list_form.section_id = this.props.user_details.amSectionId
    //   calendar_list_form.start = moment().add(-6, 'days').startOf('day').format('YYYY/MM/DD HH:mm:ss')
    //   calendar_list_form.end = moment().add(6, 'days').endOf('day').format('YYYY/MM/DD HH:mm:ss')
    // }else if(type == 1){// 全店
    //   calendar_list_form.section_id = 0
    //   calendar_list_form.start = moment().format('YYYY-MM-DD HH:mm:ss')
    //   calendar_list_form.end = moment().add(1, 'd').add(-1, 's').format('YYYY-MM-DD HH:mm:ss')
    // }else if(type == 2){// 担当別
    //   calendar_list_form.section_id = this.props.user_details.amSectionId
    //   calendar_list_form.start = moment().format('YYYY-MM-DD HH:mm:ss')
    //   calendar_list_form.end = moment().add(1, 'd').add(-1, 's').format('YYYY-MM-DD HH:mm:ss')
    // }

    // this.props.change_calendar_list_form(calendar_list_form);

    this.props.get_calendar_list()

    this.setState({
      change: !this.state.change
    });

  }

  render() {



    return (
      <Grid container style={{ width: 170 }}>

        <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 0 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<StoreIcon />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(0)} >今週</Button></Grid>
        <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 1 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<EventNoteIcon />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(1)} >全店</Button></Grid>
        <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 2 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<PermContactCalendarIcon />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(2)} >担当別</Button></Grid>
        <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 3 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<Event />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(3)} >日別</Button></Grid>


        <Grid xs={12} style={{ padding: "0px", textAlign:"center", marginBottom:15, marginTop:15 }}>
          {/* <div style={{border:"1px solid #dddddd", borderRadius:"10px"}}> */}
          <Typography variant="h6">
            {this.props.calendar_all_store_list_form.start != 'Invalid date' && moment(this.props.calendar_all_store_list_form.start).format("YYYY年M月D日(ddd)")}
          </Typography>

          {/* </div> */}
        </Grid>

        {/* <Grid xs={12} style={{ padding: 5, }} className='search_calendar'>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
          <StaticDatePicker
            className='search_calendar'
            variant="static"
            value={this.props.calendar_all_store_list_form.start}
            onChange={this.onChangeDate}
            inputFormat="yyyy年MM月dd日"
            leftArrowButtonText="前月を表示"
            rightArrowButtonText="次月を表示"
            toolbarTitle="日付選択"
            showToolbar={false}
            okText="選択"
            toolbarFormat="yyyy年MM月dd日"
            // renderInput={(params) => <TextField {...params} />}
              // PaperProps={{ sx: styles.paperprops }}
              // DialogProps={{ sx: styles.mobiledialogprops }}
          />
          </LocalizationProvider>
        </Grid> */}

        <Grid xs={12} style={{ padding: "10px 10px 0px 10px"}}>

          <TextField
              fullWidth
              id="start"
              label="日付"
              type="date"
              name="start"
              size="small"
              // style={{marginLeft:20}}
              value={moment(this.props.calendar_all_store_list_form.start).format("YYYY-MM-DD")}
              InputLabelProps={{
                  shrink: true,
              }}
              variant="standard"
              // onChange={this.onChangeDate}
              onChange={e => {
                  this.onChangeDate(e.target.value)
                }
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    this.onChangeDate(e.target.value)
                }
              }}
          />

          {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>

            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy/MM/dd(EE)"
              margin="dense"
              id="date-picker-inline1"
              label="日付"
              // showTodayButton={true}
              style={{ float: "left", marginTop: "0" }}
              autoOk={true}
              InputLabelProps={{ shrink: true }}
              name="date"
              value={this.props.calendar_all_store_list_form.start}
              onChange={this.onChangeDate}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    this.onChangeDate(e.target.value)
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage={"日付の形式が違います"}
            />

          </MuiPickersUtilsProvider> */}

        </Grid>

        <Grid xs={12} style={{ padding: 5 }}>
          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(-7)}
            size="large"><DoubleArrow fontSize="small" style={{ transform: "rotate(180deg)" }} /></IconButton>

          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(-1)}
            size="large"><NavigateNext fontSize="medium" style={{ transform: "rotate(180deg)" }} /></IconButton>
          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(0)}
            size="large"><Today fontSize="small" /></IconButton>
          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(1)}
            size="large"><NavigateNext fontSize="medium" /></IconButton>

          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(7)}
            size="large"><DoubleArrow fontSize="small" /></IconButton>
        </Grid>

      </Grid >
    );
  }
}




//<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>
//    <ResponseInsertComponent
//        toggle={this.toggle} onSubmit={this.props.customerUpdate}
//    />
//</Modal>


const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users: state.masterReducer.users,
    users_select: state.masterReducer.users_select,
    section_select: state.masterReducer.section_select,

    calendar_list: state.calendar.calendar_list,
    calendar_all_store_list_form: state.calendar.calendar_all_store_list_form,
    calendar_type: state.calendar.calendar_type,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },
    change_calendar_all_store_list_form(state) {
      dispatch(Calendarsaga.change_calendar_all_store_list_form(state))
    },
    change_calendar_type(state) {
      dispatch(Calendarsaga.change_calendar_type(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);

// ResponseListSearch = reduxForm({
//   form: 'formset', // a unique identifier for this form
//   onSubmit: submit
//   //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ResponseListSearch)
