import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Calendarsaga from './Saga/Calendar';
import * as Task from '../Task/Saga/Task';

import * as ContractFollow from '../ContractFollow/Saga/ContractFollow';
import * as CustomerContacts from '../CustomerContacts/Saga/CustomerContacts';

import TextField from '@mui/material/TextField';

import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

var moment = require('moment-timezone');


class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reflesh: false,
      open: false,
      event_type: 0
    }

    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handle_change_section_id = this.handle_change_section_id.bind(this);
    this.handle_change_user_id = this.handle_change_user_id.bind(this);
    this.change_date = this.change_date.bind(this);
    // this.props.get_calendar_list();


  }


  handleUpdate(id, del) {

    // let calendar_free_detail = this.props.calendar_free_detail
    // calendar_free_detail["is_deleted"] = delflg
    // this.props.change_calendar_free_detail(calendar_free_detail)

    if (id) {
      this.props.update_calendar_free_detail(del)
    } else {
      this.props.insert_calendar_free_detail(del)
    }

    this.setState({
      change: !this.state.change
    });

    this.props.change_calendar_free_dialog(false)
  }

  onChange(state) {
    // console.log(this.props.claso_blog_places)
    let calendar_free_detail = this.props.calendar_free_detail

    calendar_free_detail[state.target.name] = state.target.value

    this.props.change_calendar_free_detail(calendar_free_detail)

    this.setState({
      change: !this.state.change
    });

  }

  handle_change_section_id(e, newValue) {

    let calendar_free_detail = this.props.calendar_free_detail
    calendar_free_detail.section_id = newValue ? newValue.tenpo_no : 0
    this.props.change_calendar_free_detail(calendar_free_detail)

    this.setState({
      change: !this.state.change
    });
  }

  handle_change_user_id(e, newValue) {

    let calendar_free_detail = this.props.calendar_free_detail
    calendar_free_detail.user_id = newValue ? newValue.id : 0
    this.props.change_calendar_free_detail(calendar_free_detail)

    this.setState({
      change: !this.state.change
    });
  }

  change_date(e) {

    let calendar_free_detail = this.props.calendar_free_detail

    calendar_free_detail.start_at = moment(e).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm:ss')
    calendar_free_detail.end_at = moment(e).tz("Asia/Tokyo").endOf("day").format('YYYY/MM/DD HH:mm:ss')

    this.props.change_calendar_free_detail(calendar_free_detail)
    this.setState({
      change: !this.state.change
    });
  }


  render() {
    return (
      <div>

        <ModalHeader toggle={(e) => { this.props.change_calendar_free_dialog(false);this.props.change_rent_tasks_modal(false) }}>
          <span></span>
          {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
        </ModalHeader>


        <ModalBody>



          <Grid container spacing={1}>

            <Grid justifyContent="space-between" item xs={12}>

              <FormControl>
                  {this.props.calendar_search_store_list.length > 0 ? <Autocomplete
                    id="combo-box-demo"

                    options={this.props.calendar_search_store_list.length === 0 && this.props.calendar_free_detail.section_id ? [this.props.calendar_search_store_list.filter(a => String(a.tenpo_no) === String(this.props.calendar_free_detail.section_id))[0]]
                      : this.props.calendar_search_store_list}
      
                    getOptionLabel={(option) => option.tenpo_name ? option.tenpo_name : ''}
                    value={this.props.calendar_free_detail.section_id ? this.props.calendar_search_store_list.filter(a => String(a.tenpo_no) === String(this.props.calendar_free_detail.section_id))[0] : {}}
                    style={{ width: 200 }}
                    onChange={this.handle_change_section_id}
                    size="small"
                    renderInput={(params) => <TextField variant="outlined" 
                      {...params} label="部署を選択" />}

                  /> : ""}


              </FormControl>
              <FormControl>
                {this.props.users.length > 0 && this.props.user_details ? <Autocomplete
                  id="combo-box-demo"

                  options={this.props.users.length === 0 && this.props.calendar_free_detail.user_id ? [this.props.users.filter(a => String(a.id) === String(this.props.calendar_free_detail.user_id))[0]]
                    : this.props.users}

                  getOptionLabel={(option) => option.userNameAll ? option.userNameAll : ''}
                  // name="ownerId"
                  value={this.props.calendar_free_detail.user_id ? this.props.users.filter(a => String(a.id) === String(this.props.calendar_free_detail.user_id))[0] : {}}



                  // value={this.props.rent_task_detail.user_id}
                  // defaultValue={this.props.contract_follow_editer.user_id}
                  // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                  style={{ width: 200 }}
                  onChange={this.handle_change_user_id}
                  size="small"
                  renderInput={(params) => <TextField variant="outlined"
                    {...params} label="担当を選択" />}

                /> : ""}


              </FormControl>

            </Grid>
            <Grid justifyContent="space-between" item xs={12}>

              <TextField
                  id="start_at"
                  label="日付"
                  type="date"
                  name="start_at"
                  size="small"
                  onChange={this.onChange}
                  value={moment(this.props.calendar_free_detail.start_at).format("YYYY-MM-DD")}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  variant="standard"
              />
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                <KeyboardDatePicker

                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd(EE)"
                  margin="normal"
                  id="date-picker-inline2"
                  label="日付"
                  style={{ marginLeft: "15px", marginTop: "0", width: 150, float: "left", marginRight: 5 }}
                  autoOk={true}
                  // disabled={true}
                  // error={true}
                  // helperText="申込日を選択してください"
                  showTodayButton={true}
                  name="contractionDateEnd"
                  value={this.props.calendar_free_detail.start_at ? moment(this.props.calendar_free_detail.start_at) : null}
                  InputLabelProps={{ shrink: !!this.props.calendar_free_detail.start_at }}
                  onChange={this.change_date}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}

                  invalidDateMessage={"日付を入力してください。"}
                />


              </MuiPickersUtilsProvider> */}

            </Grid>
            <Grid justifyContent="space-between" item xs={12}>

              <TextField
                id="outlined-multiline-flexible"
                label="内容"
                multiline
                rows={5}
                name="remarks"
                onChange={this.onChange}
                value={this.props.calendar_free_detail.remarks}
                // style={{ width: "300px", marginTop: "10px" }}
                variant="outlined"
                fullWidth

              />
            </Grid>
            <Grid justifyContent="space-between" item xs={12}>
              <RadioGroup row aria-label="position" name="color" onChange={this.onChange} value={this.props.calendar_free_detail.color} >
                <FormControlLabel control={<Radio value={"#F9A7A7"} style={{ color: "#F9A7A7" }} />} label="" />
                <FormControlLabel control={<Radio value={"#DAA3E8"} style={{ color: "#DAA3E8" }} />} label="" />
                <FormControlLabel control={<Radio value={"#A9B9F7"} style={{ color: "#A9B9F7" }} />} label="" />
                <FormControlLabel control={<Radio value={"#F2EF7C"} style={{ color: "#F2EF7C" }} />} label="" />
                {/* <FormControlLabel control={<Radio value={"#ffff7f"} style={{color:"#ffff7f"}} />} label="" /> */}
                <FormControlLabel control={<Radio value={"#FFDFAB"} style={{ color: "#FFDFAB" }} />} label="" />
                <FormControlLabel control={<Radio value={"#E2E2E2"} style={{ color: "#E2E2E2" }} />} label="" />
                <FormControlLabel control={<Radio value={"#D9ED8E"} style={{ color: "#D9ED8E" }} />} label="" />
                <FormControlLabel control={<Radio value={"#BDEBF9"} style={{ color: "#BDEBF9" }} />} label="" />
              </RadioGroup>
            </Grid>
          </Grid>














        </ModalBody>


        <ModalFooter>

          {/* <Button variant="contained" color="primary" onClick={() => this.handleUpdate(this.props.calendar_free_detail.id, 0)} style={{ backgroundColor: "#5cb85c", marginRight: 5 }}>保存</Button>
            {this.props.calendar_free_detail.id ? <Button variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button> : ""} */}


          <div style={{ height: 28, marginBottom: 30 }}>
          {this.state.modal_del == true ? "" : <Button variant="contained" color="primary" onClick={() => this.handleUpdate(this.props.calendar_free_detail.id, 0)} style={{float: "right" }}>保存</Button>}
          {this.state.modal_del == true ? "" : this.props.calendar_free_detail.completed_at ? <Button variant="contained" color="success" startIcon={<RemoveCircleIcon />} style={{ float: "right", backgroundColor:"#ed6c03", color:"white", marginRight: 5 }} onClick={()=>this.handleUpdate(this.props.calendar_free_detail.id, 4)}>未完了にする</Button> : <Button variant="contained" color="success" startIcon={<CheckCircleIcon />} style={{ float:"right", backgroundColor:"#5cb85c", color:"white", marginRight: 5 }} onClick={()=>this.handleUpdate(this.props.calendar_free_detail.id, 3)}>完了済にする</Button>}



            {this.state.modal_del == true ? <Alert severity="error" style={{ margin: 5, width: "100%" }} action={<span><Button variant="contained" style={{ float: "right", marginRight: 5 }} onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button><Button variant="contained" color="secondary" style={{ float: "right", marginRight: 5 }} onClick={() => this.handleUpdate(this.props.calendar_free_detail.id, 1)}>削除</Button></span>}><span>削除してよろしいですか？</span></Alert> : this.props.calendar_free_detail.id ? <Button variant="contained" color="secondary" style={{ float: "right", marginRight: 190 }} onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button> : ""}



          </div>

        </ModalFooter>







{/* {this.props.calendar_free_detail.completed_at} */}
      </div>
    );
  }
};




const mapStateToProps = (state) => {
  return {
    users: state.masterReducer.users,
    user_details: state.masterReducer.user_details,
    all_store: state.calendar.all_store,
    calendar_all_store_list: state.calendar.calendar_all_store_list,

    calendar_event_type: state.calendar.calendar_event_type,
    contract_follow_edit_modal: state.contractfollow.contract_follow_edit_modal,
    customer_contacts_modal: state.customerContactsReducer.customer_contacts_modal,
    calendar_precalendar: state.calendar.calendar_precalendar,
    calendar_list: state.calendar.calendar_list,
    calendar_staff_list_form: state.calendar.calendar_staff_list_form,


    calendar_free_dialog: state.calendar.calendar_free_dialog,
    calendar_free_detail: state.calendar.calendar_free_detail,

    calendar_search_store_list: state.calendar.calendar_search_store_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },
    change_contract_follow_edit_modal(state) {
      dispatch(ContractFollow.change_contract_follow_edit_modal(state))
    },
    change_customer_contacts_modal(state) {
      dispatch(CustomerContacts.change_customer_contacts_modal(state))
    },
    change_calendar_list(state) {
      dispatch(Calendarsaga.change_calendar_list(state))
    },
    change_rent_task_detail(state) {
      dispatch(Calendarsaga.change_rent_task_detail(state))
    },
    change_calendar_precalendar(state) {
      dispatch(Calendarsaga.change_calendar_precalendar(state))
    },
    change_calendar_free_dialog(state) {
      dispatch(Calendarsaga.change_calendar_free_dialog(state))
    },
    change_calendar_free_detail(state) {
      dispatch(Calendarsaga.change_calendar_free_detail(state))
    },
    update_calendar_free_detail(state) {
      dispatch(Calendarsaga.update_calendar_free_detail(state))
    },
    insert_calendar_free_detail(state) {
      dispatch(Calendarsaga.insert_calendar_free_detail(state))
    },
    change_rent_tasks_modal(state) {
      dispatch(Task.change_rent_tasks_modal(state))
    },
    // get_calendar_list(state) {
    //   dispatch(Calendarsaga.get_calendar_list(state))
    // },
    // change_calendar_list_form(state) {
    //   dispatch(Calendarsaga.change_calendar_list_form(state))
    // },
    // change_calendar_list(state) {
    //   dispatch(Calendarsaga.change_calendar_list(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);