import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as Contracts from './Saga/Contracts';
import * as Customers from '../Customers/Saga/Customers';
import CustomerAttentionList from '../Customers/CustomerAttentionList';
import { Row, Col } from 'reactstrap';
import Button from '@mui/material/Button';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DescriptionIcon from '@mui/icons-material/Description';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import IconButton from '@mui/material/IconButton';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';

const moment = require('moment-timezone');
class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.numPages = this.numPages.bind(this);
    this.handleOpen = this.handleOpen.bind(this);

    if(this.props.rent_contract_details_new && 
      (
        (this.props.rent_contract_details_new.contractor_name1 && this.props.rent_contract_details_new.contractor_name1.replace(' ', '').replace('　', '')) || 
        (this.props.rent_contract_details_new.contractor_kana1 && this.props.rent_contract_details_new.contractor_kana1.replace(' ', '').replace('　', '')) || 
        (this.props.rent_contract_details_new.contractor_tel1 && this.props.rent_contract_details_new.contractor_tel1.replace('null', '').replace(/[^0-9]/g, '') != "") || 
        (this.props.rent_contract_details_new.contractor_mail1 && this.props.rent_contract_details_new.contractor_mail1.replace(' ', '').replace('　', ''))
      )){
        
          this.props.get_attentions_matching_list({
            name1: this.props.rent_contract_details_new.contractor_name1,
            kana1: this.props.rent_contract_details_new.contractor_kana1,
            tel1: this.props.rent_contract_details_new.contractor_tel1,
            tel2: "",
            mail1: this.props.rent_contract_details_new.contractor_mail1,
            mail2: "",
          })
    }

  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }
  handleOpen() {
    this.props.check_rent_emergency_editor_disp({ editor: "rent_emergency_editor_disp", disp: true })
  }

  open_room_detail_window(building_id, room_id, counting) {
    if(counting == -1 || counting == 2){//駐車場
      window.open(process.env.REACT_APP_HOMESTATION4_URL+`/manage/parking/detail/index?p_id=` + building_id, '_blank', 'noopener')
    }else{
      window.open(process.env.REACT_APP_HOMESTATION4_URL+`/manage/room/detail/index?t_id=` + building_id + `&r_id=` + room_id, '_blank', 'noopener')
    }
    
  }


  onClickAttentionDisp(detail) {

    this.setState({ attention_disp: true })
  }

  render() {


    return (
      <div>
        {/* <span style={{fontSize:15, color:"#757575"}}><DescriptionIcon /> お申込み情報</span>
        <Button size="small" style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.handleOpen()}>
          編集
        </Button> */}
        {/* <Button size="small" style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.props.check_rent_contract_editor_disp({ editor: "rent_contract_editor_disp", disp: true })}>
          編集
        </Button> */}

        <table className='basicList' style={{ marginBottom: 10 }}>
          <colgroup width='20%' />
          <colgroup width='30%' />
          <colgroup width='20%' />
          <colgroup width='30%' />
          <tr>
            <th style={{textAlign:"center"}} colSpan={4}>お申込み情報<Button size="small" style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.handleOpen()}>
          編集
        </Button></th>
          </tr>
          <tr>
            <th>物件</th>
            <td colSpan={3}>
              {this.props.rent_contract_details_new.tatemono_name}
              {this.props.rent_contract_details_new.room_name ? " " + this.props.rent_contract_details_new.room_name : ""}
              {this.props.rent_contract_details_new.room_name2 ? " " + this.props.rent_contract_details_new.room_name2 : ""}
              {this.props.rent_contract_details_new.room_name3 ? " " + this.props.rent_contract_details_new.room_name3 : ""}
              <IconButton style={{marginLeft:10, padding:5, color:'#17a2b8'}} size="large"><HomeWorkIcon size="small" onClick={()=>this.open_room_detail_window(this.props.rent_contract_details_new.tatemono_id, this.props.rent_contract_details_new.room_id, this.props.rent_contract_details_new.counting)}  /></IconButton>
            </td>
          </tr>
          <tr>
            <th>申込日</th>
            <td>{this.props.rent_contract_details_new.application_date ? moment(this.props.rent_contract_details_new.application_date).format("YYYY年MM月DD日"): ""}</td>
            <th>契約日</th>
            <td>{this.props.rent_contract_details_new.contract_date ? moment(this.props.rent_contract_details_new.contract_date).format("YYYY日M月D日"): ""}</td>
          </tr>
        </table>

        {this.props.rent_contract_details_new.car_name1 || this.props.rent_contract_details_new.car_name2 || this.props.rent_contract_details_new.car_name3 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='20%' />
            <colgroup width='80%' />
            {this.props.rent_contract_details_new.car_name1 ?
              <tr>
                {/* <th>区画</th>
                <td>
                  {this.props.rent_contract_details_new.car_area_no1}
                </td> */}
                <th>車両情報</th>
                <td>
                  {this.props.rent_contract_details_new.car_name1}/{this.props.rent_contract_details_new.car_color1}/{this.props.rent_contract_details_new.car_no1}
                </td>
              </tr> : ""}
            {this.props.rent_contract_details_new.car_name2 ?
              <tr>
                {/* <th>区画</th>
                <td>
                  {this.props.rent_contract_details_new.car_area_no2}
                </td> */}
                <th>車両情報</th>
                <td>
                  {this.props.rent_contract_details_new.car_name2} {this.props.rent_contract_details_new.car_color2} {this.props.rent_contract_details_new.car_no2}
                </td>
              </tr> : ""}
            {this.props.rent_contract_details_new.car_name3 ?
              <tr>
                {/* <th>区画</th>
                <td>
                  {this.props.rent_contract_details_new.car_area_no3}
                </td> */}
                <th>詳細</th>
                <td>
                  {this.props.rent_contract_details_new.car_name3} {this.props.rent_contract_details_new.car_color3} {this.props.rent_contract_details_new.car_no3}
                </td>
              </tr> : ""}
          </table> : ""}

        {this.props.rent_contract_details_new.apply_payment_type || this.props.rent_contract_details_new.month_payment_type ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='20%' />
            <colgroup width='30%' />
            <colgroup width='20%' />
            <colgroup width='30%' />
            <tr>
              <th>初期費用</th>
              <td>
                {this.props.rent_contract_details_new.apply_payment_type == 1 ? "口座振込" :
                  this.props.rent_contract_details_new.apply_payment_type == 2 ? "カード支払" :
                    this.props.rent_contract_details_new.apply_payment_type == 3 ? "口座振替" : ""}
              </td>
              <th>月額使用料</th>
              <td>
                {this.props.rent_contract_details_new.month_payment_type == 1 ? "口座振込" :
                  this.props.rent_contract_details_new.month_payment_type == 2 ? "カード支払" :
                    this.props.rent_contract_details_new.month_payment_type == 3 ? "口座振替" : ""}
              </td>
            </tr>

          </table> : ""}


        {this.props.rent_contract_details_new.contractor_name1 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='20%' />
            <colgroup width='30%' />
            <colgroup width='20%' />
            <colgroup width='30%' />

            <tr>
              <th>契約者名</th>
              <td colSpan="3" >
                <div style={{ fontSize: 10 }}>{this.props.rent_contract_details_new.contractor_kana1}</div>
                <div>{this.props.rent_contract_details_new.contractor_name1}</div>
                {this.props.attentions_matching_list.length > 0 ? <span class="badge badge-danger" onClick={()=>this.onClickAttentionDisp(this.props.rent_contract_details_new)}>信用情報マッチングあり</span>:""}
              </td>
            </tr>
            {this.props.rent_contract_details_new.type == 0 ?
              <tr>
                <th>性別</th>
                <td colSpan="1">{this.props.rent_contract_details_new.contractor_sex == 1 ? "男性" : this.props.rent_contract_details_new.contractor_sex == 0 ? "女性" : ""}</td>
                <th >生年月日</th>
                <td colSpan="1">
                  {this.props.rent_contract_details_new.contractor_birthday ? moment(this.props.rent_contract_details_new.contractor_birthday).format("YYYY-MM-DD") : ""}
                  {this.props.rent_contract_details_new.contractor_birthday ? "(" + String(moment().diff(moment(this.props.rent_contract_details_new.contractor_birthday, 'YYYY-MM-DD'), 'years')) + "歳)": ""}</td>
              </tr> : ""}
            <tr>
              <th>住所</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.contractor_post}</div>
                {this.props.rent_contract_details_new.contractor_add1}{this.props.rent_contract_details_new.contractor_add2}
              </td>
            </tr>
            <tr>
              <th>{this.props.rent_contract_details_new.type == 0 ? "携帯電話" : "TEL"}</th>
              <td colSpan={this.props.rent_contract_details_new.type == 0 ? "3" : "1"} >
                {this.props.rent_contract_details_new.contractor_tel1}
              </td>
              {this.props.rent_contract_details_new.type == 1 ? <th>FAX</th> : ""}
              {this.props.rent_contract_details_new.type == 1 ? <td>{this.props.rent_contract_details_new.contractor_fax}</td> : ""}
            </tr>

            {this.props.rent_contract_details_new.type == 1 ?
              <tr>
                <th>担当部課</th>
                <td colSpan="1" >
                  {this.props.rent_contract_details_new.contractor_section}
                </td>
                <th>担当者</th>
                <td colSpan="1" >
                  {this.props.rent_contract_details_new.contractor_contact}
                </td>
              </tr> : ""}
            {this.props.rent_contract_details_new.type == 1 ?
              <tr>
                <th>勤務先 郵送先</th>
                <td colSpan="3" >
                  {this.props.rent_contract_details_new.contractor_office_destination}
                </td>
              </tr> : ""}

            {this.props.rent_contract_details_new.type == 0 ?
              <tr>
                <th>メールアドレス</th>
                <td colSpan="3" >
                  {this.props.rent_contract_details_new.contractor_mail1}
                </td>
              </tr> : ""}
            <tr>
              <th>勤務先名</th>
              <td colSpan="3" >
                {this.props.rent_contract_details_new.contractor_office_name}
              </td>
            </tr>
            <tr>
              <th>勤務先名住所</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.contractor_office_post}</div>
                <div>{this.props.rent_contract_details_new.contractor_office_add}</div>
              </td>
            </tr>
            <tr>
              <th>勤務先TEL</th>
              <td colSpan={this.props.rent_contract_details_new.type == 1 ? "1" : "3"} >
                {this.props.rent_contract_details_new.contractor_office_tel}
              </td>

              {this.props.rent_contract_details_new.type == 1 ? <th>勤務先FAX</th> : ""}
              {this.props.rent_contract_details_new.type == 1 ? <td>{this.props.rent_contract_details_new.contractor_office_fax}</td> : ""}
            </tr>
            {this.props.rent_contract_details_new.type == 1 ?
              <tr>
                <th>勤務先 部課/役職</th>
                <td colSpan="1" >
                  {this.props.rent_contract_details_new.contractor_office_section}
                </td>
                <th>勤務先 担当者</th>
                <td colSpan="1" >
                  {this.props.rent_contract_details_new.contractor_office_contact}
                </td>
              </tr> : ""}
            {this.props.rent_contract_details_new.type == 0 ?
              <tr>
                <th>業種</th>
                <td colSpan="3" >
                  {this.props.rent_contract_details_new.contractor_rent_m_job_category_id > 0 && this.props.rent_m_job_categorys.length ? this.props.rent_m_job_categorys.filter(a => a.id == this.props.rent_contract_details_new.contractor_rent_m_job_category_id)[0].name : ""}
                  {this.props.rent_contract_details_new.contractor_office_job}
                </td>
              </tr> : ""}

            {this.props.rent_contract_details_new.type == 0 ?
              <tr>
                <th >住居</th>
                <td colSpan="1">
                  {(() => {
                    if (this.props.rent_contract_details_new.contractor_myhome === 1) {
                      return "持家";
                    } else if (this.props.rent_contract_details_new.contractor_myhome === 2) {
                      return "借家";
                    } else if (this.props.rent_contract_details_new.contractor_myhome === 3) {
                      return "親と同居";
                    } else {
                      return "";
                    }
                  })()}
                </td>
                <th >引越理由</th>
                <td colSpan="1">
                  {this.props.rent_contract_details_new.rent_m_reason_name}
                  {this.props.rent_contract_details_new.reason_remarks}
                </td>
              </tr> : ""}

            {this.props.rent_contract_details_new.type == 0 ?
              <tr>
                <th>勤続年数</th>
                <td colSpan="1">{this.props.rent_contract_details_new.contractor_workyears ? this.props.rent_contract_details_new.contractor_workyears + "年" : ""}</td>
                <th >年収</th>
                <td colSpan="1">{this.props.rent_contract_details_new.contractor_income ? this.props.rent_contract_details_new.contractor_income + "万円" : ""}</td>
              </tr> : ""}

            {this.props.rent_contract_details_new.type == 1 ?
              <tr>
                <th>代行会社</th>
                <td colSpan="3" >
                  {this.props.rent_contract_details_new.agent_name}
                </td>
              </tr> : ""}
            {this.props.rent_contract_details_new.type == 1 ?
              <tr>
                <th>代行会社TEL</th>
                <td colSpan="1" >
                  {this.props.rent_contract_details_new.agent_tel}
                </td>
                <th>代行会社FAX</th>
                <td colSpan="1" >
                  {this.props.rent_contract_details_new.agent_fax}
                </td>
              </tr> : ""}
            {this.props.rent_contract_details_new.type == 1 ?
              <tr>
                <th>代行会社 担当部課</th>
                <td colSpan="1" >
                  {this.props.rent_contract_details_new.agent_section}
                </td>
                <th>代行会社 担当者</th>
                <td colSpan="1" >
                  {this.props.rent_contract_details_new.agent_staff}
                </td>
              </tr> : ""}

          </table> : ""}



        {this.props.rent_contract_details_new.cosigner1_name1 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='20%' />
            <colgroup width='30%' />
            <colgroup width='20%' />
            <colgroup width='30%' />

            <tr>
              <th>{this.props.rent_contract_details_new.cosigner == 1 || this.props.rent_contract_details_new.cosigner == 2 ? "連帯保証人" : "緊急連絡先"}</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.cosigner1_kana1}</div>
                <div>{this.props.rent_contract_details_new.cosigner1_name1}</div>
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td colSpan="1">{this.props.rent_contract_details_new.cosigner1_sex == 1 ? "男性" : this.props.rent_contract_details_new.cosigner1_sex == 0 ? "女性" : ""}</td>
              <th >生年月日</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.cosigner1_birthday ? moment(this.props.rent_contract_details_new.cosigner1_birthday).format("YYYY-MM-DD") : ""}
                {this.props.rent_contract_details_new.cosigner1_birthday ? "(" + String(moment().diff(moment(this.props.rent_contract_details_new.cosigner1_birthday, 'YYYY-MM-DD'), 'years')) + "歳)" : ""}</td>
            </tr>
            <tr>
              <th>住所</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.cosigner1_post}</div>
                {this.props.rent_contract_details_new.cosigner1_add1}{this.props.rent_contract_details_new.cosigner1_add2}
              </td>
            </tr>
            <tr>
              <th>TEL/携帯電話</th>
              <td colSpan="3" >
                {this.props.rent_contract_details_new.cosigner1_tel1}
              </td>
            </tr>
            <tr>
              <th>続柄</th>
              <td colSpan="1">
                {(this.props.rent_contract_details_new.cosigner1_rent_m_relation_id && this.props.rent_m_relations.length > 0) ? this.props.rent_m_relations.filter(a => String(a.id) == String(this.props.rent_contract_details_new.cosigner1_rent_m_relation_id))[0].name : ""}
              </td>
              <th >住居</th>
              <td colSpan="1">
                {(() => {
                  if (this.props.rent_contract_details_new.cosigner1_myhome === 1) {
                    return "持家";
                  } else if (this.props.rent_contract_details_new.cosigner1_myhome === 2) {
                    return "借家";
                  } else if (this.props.rent_contract_details_new.cosigner1_myhome === 3) {
                    return "親と同居";
                  } else {
                    return "";
                  }
                })()}
              </td>
            </tr>
            <tr>
              <th>勤続年数</th>
              <td colSpan="1">{this.props.rent_contract_details_new.cosigner1_workyears ? this.props.rent_contract_details_new.cosigner1_workyears + "年" : ""}</td>
              <th >年収</th>
              <td colSpan="1">{this.props.rent_contract_details_new.cosigner1_income ? this.props.rent_contract_details_new.cosigner1_income + "万円" : ""}</td>
            </tr>
            <tr>
              <th>勤務先名</th>
              <td colSpan="3" >
                {this.props.rent_contract_details_new.cosigner1_office_name}
              </td>
            </tr>
            <tr>
              <th>勤務先名住所</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.cosigner1_office_post}</div>
                <div>{this.props.rent_contract_details_new.cosigner1_office_add}</div>
              </td>
            </tr>
            <tr>
              <th>勤務先TEL</th>
              <td colSpan="3" >
                {this.props.rent_contract_details_new.cosigner1_office_tel}
              </td>
            </tr>
            <tr>
              <th>業種</th>
              <td colSpan="3" >
                {this.props.rent_contract_details_new.cosigner1_rent_m_job_category_id > 0 && this.props.rent_m_job_categorys.length ? this.props.rent_m_job_categorys.filter(a => a.id == this.props.rent_contract_details_new.cosigner1_rent_m_job_category_id)[0].name : ""}
                {this.props.rent_contract_details_new.cosigner1_office_job}
              </td>
            </tr>
          </table> : ""}


        {this.props.rent_contract_details_new.cosigner2_name1 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='20%' />
            <colgroup width='30%' />
            <colgroup width='20%' />
            <colgroup width='30%' />
            <tr>
              <th>連帯保証人</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.cosigner2_kana1}</div>
                <div>{this.props.rent_contract_details_new.cosigner2_name1}</div>
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td colSpan="1">{this.props.rent_contract_details_new.cosigner2_sex == 1 ? "男性" : this.props.rent_contract_details_new.cosigner2_sex == 0 ? "女性" : ""}</td>
              <th >生年月日</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.cosigner2_birthday ? moment(this.props.rent_contract_details_new.cosigner2_birthday).format("YYYY-MM-DD") : ""}
                {this.props.rent_contract_details_new.cosigner2_birthday ? "(" + String(moment().diff(moment(this.props.rent_contract_details_new.cosigner2_birthday, 'YYYY-MM-DD'), 'years')) + "歳)" : ""}</td>
            </tr>
            <tr>
              <th>住所</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.cosigner2_post}</div>
                {this.props.rent_contract_details_new.cosigner2_add1}{this.props.rent_contract_details_new.cosigner2_add2}
              </td>
            </tr>
            <tr>
              <th>TEL/携帯電話</th>
              <td colSpan="3" >
                {this.props.rent_contract_details_new.cosigner2_tel1}
              </td>
            </tr>
            <tr>
              <th>続柄</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.cosigner2_rent_m_relation_id > 0 && this.props.rent_m_relations.length ? this.props.rent_m_relations.filter(a => a.id == this.props.rent_contract_details_new.cosigner2_rent_m_relation_id)[0].name : ""}
              </td>
              <th >住居</th>
              <td colSpan="1">
                {(() => {
                  if (this.props.rent_contract_details_new.cosigner2_myhome === 1) {
                    return "持家";
                  } else if (this.props.rent_contract_details_new.cosigner2_myhome === 2) {
                    return "借家";
                  } else if (this.props.rent_contract_details_new.cosigner2_myhome === 3) {
                    return "親と同居";
                  } else {
                    return "";
                  }
                })()}
              </td>
            </tr>
            <tr>
              <th>勤続年数</th>
              <td colSpan="1">{this.props.rent_contract_details_new.cosigner2_workyears ? this.props.rent_contract_details_new.cosigner2_workyears + "年" : ""}</td>
              <th >年収</th>
              <td colSpan="1">{this.props.rent_contract_details_new.cosigner2_income ? this.props.rent_contract_details_new.cosigner2_income + "万円" : ""}</td>
            </tr>
            <tr>
              <th>勤務先名</th>
              <td colSpan="3" >
                {this.props.rent_contract_details_new.cosigner2_office_name}
              </td>
            </tr>
            <tr>
              <th>勤務先名住所</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.cosigner2_office_post}</div>
                <div>{this.props.rent_contract_details_new.cosigner2_office_add}</div>
              </td>
            </tr>
            <tr>
              <th>勤務先TEL</th>
              <td colSpan="3" >
                {this.props.rent_contract_details_new.cosigner2_office_tel}
              </td>
            </tr>
            <tr>
              <th>業種</th>
              <td colSpan="3" >
                {this.props.rent_contract_details_new.cosigner2_rent_m_job_category_id > 0 && this.props.rent_m_job_categorys.length ? this.props.rent_m_job_categorys.filter(a => a.id == this.props.rent_contract_details_new.cosigner2_rent_m_job_category_id)[0].name : ""}
                {this.props.rent_contract_details_new.cosigner2_office_job}
              </td>
            </tr>
          </table> : ""}

        {this.props.rent_contract_details_new.renter1_name1 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <tr>
              <th>入居者1</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.renter1_kana1}</div>
                <div>{this.props.rent_contract_details_new.renter1_name1}</div>
              </td>
              <th>続柄</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter1_rent_m_relation_id > 0 && this.props.rent_m_relations.length ? this.props.rent_m_relations.filter(a => a.id == this.props.rent_contract_details_new.renter1_rent_m_relation_id)[0].name : ""}
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td colSpan="1">{this.props.rent_contract_details_new.renter1_sex == 1 ? "男性" : this.props.rent_contract_details_new.renter1_sex == 0 ? "女性" : ""}</td>
              <th >生年月日</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter1_birthday ? moment(this.props.rent_contract_details_new.renter1_birthday).format("YYYY-MM-DD") : ""}
                {this.props.rent_contract_details_new.renter1_birthday ? "(" + String(moment().diff(moment(this.props.rent_contract_details_new.renter1_birthday, 'YYYY-MM-DD'), 'years')) + "歳)" : ""}</td>
              <th>携帯電話</th>
              <td colSpan="1" >
                {this.props.rent_contract_details_new.renter1_tel1}
              </td>
            </tr>
            <tr>
              <th colSpan="2">勤務先/学校名</th>
              <td colSpan="4" >
                {this.props.rent_contract_details_new.renter1_office_name}
              </td>
            </tr>
          </table> : ""}

        {this.props.rent_contract_details_new.renter2_name1 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <tr>
              <th>入居者2</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.renter2_kana1}</div>
                <div>{this.props.rent_contract_details_new.renter2_name1}</div>
              </td>
              <th>続柄</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter2_rent_m_relation_id > 0 && this.props.rent_m_relations.length ? this.props.rent_m_relations.filter(a => a.id == this.props.rent_contract_details_new.renter2_rent_m_relation_id)[0].name : ""}
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td colSpan="1">{this.props.rent_contract_details_new.renter2_sex == 1 ? "男性" : this.props.rent_contract_details_new.renter2_sex == 0 ? "女性" : ""}</td>
              <th >生年月日</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter2_birthday ? moment(this.props.rent_contract_details_new.renter2_birthday).format("YYYY-MM-DD") : ""}
                {this.props.rent_contract_details_new.renter2_birthday ? "(" + String(moment().diff(moment(this.props.rent_contract_details_new.renter2_birthday, 'YYYY-MM-DD'), 'years')) + "歳)" : ""}</td>
              <th>携帯電話</th>
              <td colSpan="1" >
                {this.props.rent_contract_details_new.renter2_tel1}
              </td>
            </tr>
            <tr>
              <th colSpan="2">勤務先/学校名</th>
              <td colSpan="4" >
                {this.props.rent_contract_details_new.renter2_office_name}
              </td>
            </tr>
          </table> : ""}

        {this.props.rent_contract_details_new.renter3_name1 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <tr>
              <th>入居者3</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.renter3_kana1}</div>
                <div>{this.props.rent_contract_details_new.renter3_name1}</div>
              </td>
              <th>続柄</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter3_rent_m_relation_id > 0 && this.props.rent_m_relations.length ? this.props.rent_m_relations.filter(a => a.id == this.props.rent_contract_details_new.renter3_rent_m_relation_id)[0].name : ""}
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td colSpan="1">{this.props.rent_contract_details_new.renter3_sex == 1 ? "男性" : this.props.rent_contract_details_new.renter3_sex == 0 ? "女性" : ""}</td>
              <th >生年月日</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter3_birthday ? moment(this.props.rent_contract_details_new.renter3_birthday).format("YYYY-MM-DD") : ""}
                {this.props.rent_contract_details_new.renter3_birthday ? "(" + String(moment().diff(moment(this.props.rent_contract_details_new.renter3_birthday, 'YYYY-MM-DD'), 'years')) + "歳)" : ""}</td>
              <th>携帯電話</th>
              <td colSpan="1" >
                {this.props.rent_contract_details_new.renter3_tel1}
              </td>
            </tr>
            <tr>
              <th colSpan="2">勤務先/学校名</th>
              <td colSpan="4" >
                {this.props.rent_contract_details_new.renter3_office_name}
              </td>
            </tr>
          </table> : ""}

        {this.props.rent_contract_details_new.renter4_name1 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <tr>
              <th>入居者4</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.renter4_kana1}</div>
                <div>{this.props.rent_contract_details_new.renter4_name1}</div>
              </td>
              <th>続柄</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter4_rent_m_relation_id > 0 && this.props.rent_m_relations.length ? this.props.rent_m_relations.filter(a => a.id == this.props.rent_contract_details_new.renter4_rent_m_relation_id)[0].name : ""}
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td colSpan="1">{this.props.rent_contract_details_new.renter4_sex == 1 ? "男性" : this.props.rent_contract_details_new.renter4_sex == 0 ? "女性" : ""}</td>
              <th >生年月日</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter4_birthday ? moment(this.props.rent_contract_details_new.renter4_birthday).format("YYYY-MM-DD") : ""}
                {this.props.rent_contract_details_new.renter4_birthday ? "(" + String(moment().diff(moment(this.props.rent_contract_details_new.renter4_birthday, 'YYYY-MM-DD'), 'years')) + "歳)" : ""}</td>
              <th>携帯電話</th>
              <td colSpan="1" >
                {this.props.rent_contract_details_new.renter4_tel1}
              </td>
            </tr>
            <tr>
              <th colSpan="2">勤務先/学校名</th>
              <td colSpan="4" >
                {this.props.rent_contract_details_new.renter4_office_name}
              </td>
            </tr>
          </table> : ""}

        {this.props.rent_contract_details_new.renter5_name1 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <tr>
              <th>入居者5</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.renter5_kana1}</div>
                <div>{this.props.rent_contract_details_new.renter5_name1}</div>
              </td>
              <th>続柄</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter5_rent_m_relation_id > 0 && this.props.rent_m_relations.length ? this.props.rent_m_relations.filter(a => a.id == this.props.rent_contract_details_new.renter5_rent_m_relation_id)[0].name : ""}
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td colSpan="1">{this.props.rent_contract_details_new.renter5_sex == 1 ? "男性" : this.props.rent_contract_details_new.renter5_sex == 0 ? "女性" : ""}</td>
              <th >生年月日</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter5_birthday ? moment(this.props.rent_contract_details_new.renter5_birthday).format("YYYY-MM-DD") : ""}
                {this.props.rent_contract_details_new.renter5_birthday ? "(" + String(moment().diff(moment(this.props.rent_contract_details_new.renter5_birthday, 'YYYY-MM-DD'), 'years')) + "歳)" : ""}</td>
              <th>携帯電話</th>
              <td colSpan="1" >
                {this.props.rent_contract_details_new.renter5_tel1}
              </td>
            </tr>
            <tr>
              <th colSpan="2">勤務先/学校名</th>
              <td colSpan="4" >
                {this.props.rent_contract_details_new.renter5_office_name}
              </td>
            </tr>
          </table> : ""}

        {this.props.rent_contract_details_new.renter6_name1 ?
          <table className='basicList' style={{ marginBottom: 10 }}>
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <colgroup width='15%' />
            <colgroup width='18%' />
            <tr>
              <th>入居者6</th>
              <td colSpan="3" >
                <div style={{ fontSize: 9 }}>{this.props.rent_contract_details_new.renter6_kana1}</div>
                <div>{this.props.rent_contract_details_new.renter6_name1}</div>
              </td>
              <th>続柄</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter6_rent_m_relation_id > 0 && this.props.rent_m_relations.length ? this.props.rent_m_relations.filter(a => a.id == this.props.rent_contract_details_new.renter6_rent_m_relation_id)[0].name : ""}
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td colSpan="1">{this.props.rent_contract_details_new.renter6_sex == 1 ? "男性" : this.props.rent_contract_details_new.renter6_sex == 0 ? "女性" : ""}</td>
              <th >生年月日</th>
              <td colSpan="1">
                {this.props.rent_contract_details_new.renter6_birthday ? moment(this.props.rent_contract_details_new.renter6_birthday).format("YYYY-MM-DD") : ""}
                {this.props.rent_contract_details_new.renter6_birthday ? "(" + String(moment().diff(moment(this.props.rent_contract_details_new.renter6_birthday, 'YYYY-MM-DD'), 'years')) + "歳)" : ""}</td>
              <th>携帯電話</th>
              <td colSpan="1" >
                {this.props.rent_contract_details_new.renter6_tel1}
              </td>
            </tr>
            <tr>
              <th colSpan="2">勤務先/学校名</th>
              <td colSpan="4" >
                {this.props.rent_contract_details_new.renter6_office_name}
              </td>
            </tr>
          </table> : ""}

        {this.props.rent_contract_details_new.dog || this.props.rent_contract_details_new.cat ?
          <table className='basicList' style={{ marginTop: 10 }}>
            <colgroup width='20%' />
            <colgroup width='30%' />
            <colgroup width='20%' />
            <colgroup width='30%' />
            {this.props.rent_contract_details_new.dog ?
              <tr>
                <th>犬飼育数</th>
                <td>
                  {this.props.rent_contract_details_new.dog_count}匹
                </td>
                <th>備考</th>
                <td>
                  {this.props.rent_contract_details_new.dog_remarks}
                </td>
              </tr> : ""}

            {this.props.rent_contract_details_new.cat ?
              <tr>
                <th>猫飼育数</th>
                <td>
                  {this.props.rent_contract_details_new.cat_count}匹
                </td>
                <th>備考</th>
                <td>
                  {this.props.rent_contract_details_new.cat_remarks}
                </td>
              </tr> : ""}
          </table> : ""}

        {this.props.rent_contract_details_new.contractor_remarks ?
          <table className='basicList' style={{ marginTop: 10 }}>
            <colgroup width='20%' />
            <colgroup width='30%' />
            <colgroup width='20%' />
            <colgroup width='30%' />
            <tr>
              <th>備考</th>
              <td colSpan="3">
                {this.props.rent_contract_details_new.contractor_remarks}
              </td>

            </tr>
          </table> : ""}


          <Modal isOpen={this.state.attention_disp} toggle={()=>this.setState({ attention_disp: false })}
                    size="xxl" fade={false}

                >
              <ModalHeader toggle={()=>this.setState({ attention_disp: false })}>信用情報</ModalHeader>
              <ModalBody>

                  <CustomerAttentionList grid_width={window.innerWidth-100} />


              </ModalBody>

          </Modal>

      </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,

    rent_m_relations: state.contracts.rent_m_relations,
    rent_m_job_categorys: state.contracts.rent_m_job_categorys,

    attentions_matching_list: state.customersReducer.attentions_matching_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    check_rent_emergency_editor_disp(state) {
      dispatch(Contracts.check_rent_emergency_editor_disp(state))
    },
    get_attentions_matching_list(state) {
      dispatch(Customers.get_attentions_matching_list(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Contracts.change_contract_detail_edit_disp(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
