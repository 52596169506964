import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import * as MapMypage from './../Saga/MapMypage';

// const useStyles = makeStyles(theme => ({
//   root: {
//     margin: 'auto',
//   },
//   cardHeader: {
//     padding: theme.spacing(1, 2),
//   },
//   list: {
//     width: 300,
//     height: 330,
//     backgroundColor: theme.palette.background.paper,
//     overflow: 'auto',
//   },
//   button: {
//     margin: theme.spacing(0.5, 0),
//   },
// }));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}















function TransferList(props) {
  // const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  //   const result = props.introductions.filter(function( value ) {
  //     console.log(props.select_rooms)
  //     if(props.select_rooms.filter(a=>a.tatemonoId===value.tatemonoId && a.roomId===value.roomId)[0]){

  //     }else{
  //     return value ;
  //   }
  // });
  // console.log(result)

  const [left, setLeft] = React.useState(props.open_rooms);
  // console.log(props.open_rooms)
  const [right, setRight] = React.useState(props.select_rooms);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    let list = right.concat(leftChecked)

    setRight(list);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    props.change_select_rooms(list)
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    console.log(not(right, rightChecked))
    props.change_select_rooms(not(right, rightChecked))
  };

  const handleOpenSheet = (tid, rid) => {
    let map_mypage_room_detail_disp = props.map_mypage_room_detail_disp
    map_mypage_room_detail_disp = true
    props.change_map_mypage_room_detail_disp(map_mypage_room_detail_disp)

    let map_mypage_room_detail_disp_id = props.map_mypage_room_detail_disp_id
    map_mypage_room_detail_disp_id.tatemono_id = tid
    map_mypage_room_detail_disp_id.room_id = rid
    props.change_map_mypage_room_detail_disp_id(map_mypage_room_detail_disp_id)

  };



  const customList = (title, items) => (
    <Card>
      <CardHeader
        // className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} 選択中`}
      />
      <Divider />
      <List 
      // className={classes.list} 
      style={{width: 300, height: 330, overflow: 'auto'}}
      dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-all-item-${value}-label`;


          return (
            <ListItem key={index} 
            style={{paddingRight:10}}
            role="listitem">
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  onClick={handleToggle(value)}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.tatemono_name ? value.tatemono_name : ""} ${value.room_name ? value.room_name: ""}`  }
                secondary={`${value.status === 1 ? "問い合わせ物件" : value.status === 3 ? "自動送信" : value.status === 4 ? "自動除外" : value.status === 5 ? "添付ファイル" : ""} ${value.kuushitsu_joukyou_name}/${value.kanri_corp_name}/${value.existlist}`} />
              
              <ListItemIcon style={{minWidth:0}}>
              <button className="btn btn-sm btn-primary" style={{ width: "25px", fontSize: 10, padding: 0, marginRight:5 }} onClick={()=>handleOpenSheet(value.tatemono_id, value.room_id)}>詳細</button>
              </ListItemIcon>

            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" 
    // className={classes.root}
    >
      <Grid item>{customList('全選択', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            // className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            // className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('全選択', right)}</Grid>
    </Grid>
  );
}



const mapStateToProps = (state) => {
  return {
    rooms: state.roomsReducer.rooms,
    introductions: state.introductionlist.introductions,
    select_rooms: state.mapmypage.select_rooms,
    open_rooms: state.mapmypage.open_rooms,
    // response_rooms: state.roomsReducer.response_rooms,
    contacts_loading: state.customerContactsReducer.contacts_loading,
    rent_introduction_headers: state.mapmypage.rent_introduction_headers,
    rent_introduction_headers_list: state.mapmypage.rent_introduction_headers_list,
    map_mypage_introductions: state.mapmypage.map_mypage_introductions,
    map_mypage_room_detail_disp: state.mapmypage.map_mypage_room_detail_disp,
    map_mypage_room_detail_disp_id: state.mapmypage.map_mypage_room_detail_disp_id,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_select_rooms(state) {
      dispatch(MapMypage.change_select_rooms(state))
    },
    change_map_mypage_room_detail_disp(state) {
      dispatch(MapMypage.change_map_mypage_room_detail_disp(state))
    },
    change_map_mypage_room_detail_disp_id(state) {
      dispatch(MapMypage.change_map_mypage_room_detail_disp_id(state))
    },
    // insert_introductions(state) {
    //     dispatch(IntroductionList.insert_introductions(state))
    // },
    // delete_introduction(state:any) {
    //     dispatch(IntroductionList.delete_introduction(state))
    // },
    // update_introductions(state:any) {
    //     dispatch(IntroductionList.update_introductions(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferList);