
const authentication = require('../../../react-azure-adb2c2').default;



export const add_file = (payload: any) => {
    //   alert(payload.id)
    var formData = new FormData();
    formData.append(payload.id, payload.file[0]);



    console.log(payload.file)
    console.log(formData.getAll(payload.id))
    // alert(payload.id)

    let url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductionFileUpload/`;
    // alert(url)

    const token = authentication.getAccessToken();
    // alert(JSON.stringify(formData))
    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}