import React, { Component } from 'react';
import { connect } from 'react-redux';

import EstimateComponent from '../Estimates/Index';
import SalesComponent from '../Sales/SalesComponent';

import * as Responses from './Saga/Responses';
import * as Mail from '../Mail/Saga/Mail';
import * as Files from '../Files/Saga/Files';


import * as MypageLog from '../MypageLog/Saga/MypageLog';
import ResponseDetails from './ResponseDetailsComponent';

import ContractsComponent from '../Contracts/Index';
import ContractManagementComponent from '../ContractManagement/ContractManagementComponent';
import * as Contacts from '../CustomerContacts/Saga/CustomerContacts';
import * as Customers from './../Customers/Saga/Customers';
import * as RoomList from './../RoomList/Saga/RoomList';
import * as Line from './../Line/Saga/Line';
import CloseIcon from '@mui/icons-material/Close';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
// import * as Introductions from './../IntroductionList/Saga/IntroductionList';

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import "react-tabs/style/react-tabs.css";
// import "../../Css/Tabs.css";
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
class ResponseComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            responseTabs: this.props.responseTabs ? this.props.responseTabs : "r1",
        }
        props.change_response_tabs(this.props.responseTabs ? this.props.responseTabs : "r1")

        props.change_file_tab(this.props.responseTabs ? this.props.responseTabs == "r4" ? "f4" : "f2" : "f2")

        // alert(this.props.responseid)
        const rentResponseId = props.responseid ? props.responseid : props.match ? props.match.params.id : 0;
        props.change_responseid(rentResponseId)

        const responsesSearch = props.responsesSearch ? props.responsesSearch : false;
        let response = { rent_response_id: 0, responses_search: false };
        response.rent_response_id = rentResponseId;
        response.responses_search = responsesSearch;

        props.responsedetailData(response)
        props.get_rent_mypage_logs(rentResponseId);



        // props.get_introductions(rentResponseId)
        props.get_rent_customer_contacts(rentResponseId)
        props.get_responses_search_list(rentResponseId)
        this.props.get_response_mails(rentResponseId)
        this.props.get_files(rentResponseId)
        this.customersubmit = this.customersubmit.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        // this.onMypage = this.onMypage.bind(this);

        // props.get_introductions(rentResponseId);
        // props.get_line_user_details_response(rentResponseId)

        // props.check_customer_contacts_raiten(rentResponseId)

    }




    componentWillMount() {

    }


    customersubmit(value) {

        this.props.update_customerdetail(value)

    }


    handleTabChange = (event, newValue) => {

        this.setState({ responseTabs: newValue });
        this.props.change_response_tabs(newValue)

        this.props.change_file_tab(newValue ? newValue == "r4" ? "f4" : "f2" : "f2")
    };



    toggle_modalResponseDetail() {
        // alert(this.props.responsesReducer.rent_responses_free_edit_disp ? "true": "false")

        //準備シートを開いていない時だけ閉じる
        if(!this.props.rent_responses_free_edit_disp){
            this.props.change_modalResponseDetail(false)
        }else{
            alert("準備シートの内容が編集中です。\n修正が終わったら、修正フォームを閉じてください。")
        }
        
    }

    componentDidMount() {
        // alert()
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }
    
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        if(this.props.rent_responses_free_edit_disp){
            // this.props.change_modalResponseDetail(false)
    
        // if (isModified) {
          const message = '修正中の内容があります。本当にページを閉じますか？';
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        }
    };

    render() {

        return (
            <div>
                <Paper square={false}>
                    <Tabs
                        value={this.props.response_tabs}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabChange}
                        aria-label="disabled tabs example"
                        style={{ float: "left" }}
                    >
                        <Tab label="お客様詳細" value="r1" />
                        <Tab label="契約" value="r4" />
                        {/* <Tab label="見積・契約金" value="r2" /> */}
                        {/* {this.props.responsedetail.rentContractId > 0 ? <Tab label="売上" value="r3" /> : ""}
                        {this.props.responsedetail.rentContractId > 0 ? <Tab label="契約" value="r4" /> : ""}
                        {this.props.responsedetail.rentContractId > 0 ? <Tab label="進捗管理" value="r5" /> : ""} */}
                    </Tabs>
                    {this.props.response_modal === true ? <BottomNavigationAction label="Nearby" style={{ float: "right", paddingTop: 12, paddingBottom: 0, paddingRight: 0 }} onClick={() => this.toggle_modalResponseDetail()} icon={<CloseIcon />} /> : ""}

                    < div style={{ clear: "both" }}></div>






                    <div style={{
                        background: "#9fb6cc", borderRadius: "0px 0px 4px 4px"
                    }}>
                        {this.props.response_tabs === "r1" ? <ResponseDetails
                            rentResponseId={this.props.rentResponseId ? this.props.rentResponseId : this.props.match ? this.props.match.params.id : 0}
                            responsedetail={this.props.responsesSearch === true ? this.props.responsedetail2 : this.props.responsedetail}
                            customerdetail={this.props.customerdetail}
                            // customer_contacts={this.props.customer_contacts} 
                            responses_search_list_loading={this.props.responses_search_list_loading}
                            responses_search_list={this.props.responses_search_list}
                            customerNewDisp={this.props.customerNewDisp}
                            customerdetail_search={this.props.customerdetail_search}
                            customerloding={this.props.customerloding}
                            customersearchdisp={this.props.customersearchdisp}
                            customerloding_search={this.props.customerloding_search}
                            delete_map_introduction={this.props.delete_map_introduction}
                            responseloding={this.props.responseloding}
                            responseid={this.props.responseid}
                            responseedit={this.props.responseedit}
                            customeredit={this.props.customeredit}
                            // customer_contacts_calendar={this.props.customer_contacts_calendar}
                            customersubmit={this.customersubmit}
                            selectTatemono={this.selectTatemono}
                            // response_mail_loading={this.props.response_mail_loading} 
                            // response_mails={this.props.response_mails}
                            rent_mypage_logs={this.props.rent_mypage_logs}
                            rent_mypage_logs_loading={this.props.rent_mypage_logs_loading}
                            onMypage={this.onMypage}
                            line_user_details={this.props.line_user_details_response}
                            lines={this.props.lines}
                            response_detail_loading={this.props.response_detail_loading}
                        /> : ""
                        }

                        {(this.props.response_tabs === "r4" && this.props.responsedetail.rent_contract_id ? <ContractsComponent /> : this.props.response_tabs === "r4" ? <ContractsComponent /> : '')
                            // this.state.responseTabs === "r2" ? <EstimateComponent
                            //     rentResponseId={this.props.rentResponseId ? this.props.rentResponseId : this.props.match.params.id}
                            // /> : ""
                        }



                    </div>
                </Paper>
            </div >
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        responsedetail: state.responsesReducer.responsedetail,

        responsedetail2: state.responsesReducer.responsedetail2,
        customerdetail: state.customersReducer.customerdetail,
        // customer_contacts: state.customerContactsReducer.customer_contacts,
        customer_contacts_calendar: state.customerContactsReducer.customer_contacts_calendar,
        responses_search_list_loading: state.responsesReducer.responses_search_list_loading,
        responses_search_list: state.responsesReducer.responses_search_list,

        customerNewDisp: state.customersReducer.customerNewDisp,
        customerdetail_search: state.customersReducer.customerdetail_search,
        customerloding: state.customersReducer.customerloding,
        customersearchdisp: state.customersReducer.customersearchdisp,
        customerloding_search: state.customersReducer.customerloding_search,
        // response_mails: state.mailReducer.response_mails,
        responseloding: state.responsesReducer.responseloding,
        responseid: state.responsesReducer.responseid,
        responseedit: state.responsesReducer.responseedit,
        customeredit: state.customersReducer.customeredit,
        // change_response_response_mail_loadingmail_loading: state.mailReducer.response_mail_loading,
        rent_mypage_logs: state.mypagelog.rent_mypage_logs,
        rent_mypage_logs_loading: state.mypagelog.rent_mypage_logs_loading,
        line_user_details_response: state.line.line_user_details_response,
        response_detail_loading: state.responsesReducer.response_detail_loading,
        response_tabs: state.responsesReducer.response_tabs,
        rent_responses_free_edit_disp: state.responsesReducer.rent_responses_free_edit_disp,
        modalResponseDetail: state.responsesReducer.modalResponseDetail,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_responseid(id) {
            dispatch(Responses.change_responseid(id))
        },
        responsedetailData(state) {
            dispatch(Responses.responsedetailData(state))
        },
        // get_introductions(state) {
        //     dispatch(Introductions.get_introductions({ responseId: state }))
        // },
        get_rent_customer_contacts(state) {
            dispatch(Contacts.get_rent_customer_contacts(state))
        },
        check_customer_contacts_raiten(state) {
            dispatch(Contacts.check_customer_contacts_raiten(state))
        },
        get_responses_search_list(state) {
            dispatch(Responses.get_responses_search_list(state))
        },
        update_customerdetail(state) {
            dispatch(Customers.update_customerdetail(state))
        },
        // insert_introductions(state) {
        //     dispatch(RoomList.insert_introductions(state))
        // },

        update_introductions(state) {
            dispatch(RoomList.update_introductions(state))
        },
        get_response_mails(state) {
            dispatch(Mail.get_response_mails(state))
        },
        get_rent_mypage_logs(state) {
            dispatch(MypageLog.get_rent_mypage_logs(state))
        },
        // get_response_lines(state) {
        //     dispatch(Line.get_response_lines(state))
        // },
        // get_line_user_details_response(state) {
        //     dispatch(Line.get_line_user_details_response(state))
        // },
        get_files(state) {
            dispatch(Files.get_files(state))
        },
        change_file_tab(state) {
            dispatch(Files.change_file_tab(state))
        },
        get_rent_m_medea(state) {
            dispatch(Responses.get_rent_m_medea(state))
        },
        change_response_tabs(state) {
            dispatch(Responses.change_response_tabs(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseComponent);
