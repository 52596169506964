import { ComposedChart, XAxis, YAxis, Tooltip, CartesianGrid, Bar } from 'recharts';
import { ThemeContext } from './Index';

function App() {

    return (
        <ThemeContext.Consumer>
            {(value) => {
                const { data } = value;
                return data.length ? (<ComposedChart　　//グラフ全体のサイズや位置、データを指定。場合によってmarginで上下左右の位置を指定する必要あり。
                    width={500}  //グラフ全体の幅を指定
                    height={200}  //グラフ全体の高さを指定
                    // layout="vertical" //グラフのX軸とY軸を入れ替え
                    data={data}   //Array型のデータを指定
                    margin={{ top: 20, right: 60, bottom: 0, left: 50 }}  //marginを指定
                >
                    <XAxis  //X軸に関する設定
                        type="category" //データタイプをcategoryに変更
                        dataKey="rent_m_way_id"  //Array型のデータの、Y軸に表示したい値のキーを指定
                        tickFormatter={(unixTime) => unixTime == 1 ? "TEL" : unixTime == 2 ? "メール" : unixTime == 3 ? "社宅" : unixTime == 4 ? "紹介" : unixTime == 5 ? "飛込" : unixTime == 6 ? "LINE" : unixTime == 7 ? "SNS" : ""}
                    />
                    <YAxis //Y軸に関する設定
                        type="number" //データタイプをnumberに変更。デフォルトではcategoryになっている
                        // domain={['dataMin 0', 'dataMax + 10']} //軸の表示領域を指定
                        domain={[0, 'dataMax + 10']} //軸の表示領域を指定
                    />
                    <Tooltip />
                    <CartesianGrid  //グラフのグリッドを指定
                        stroke="#f5f5f5"  //グリッド線の色を指定
                    />
                    <Bar
                        dataKey="sum"
                        barSize={20}
                        stroke="rgba(34, 80, 162, 0.2)"
                        fillOpacity={1}
                        fill="#2250A2"
                    />
                </ComposedChart>) : ""

            }}
        </ThemeContext.Consumer>
    );

}

export default App;