import React, { Component } from 'react';

import { connect } from 'react-redux';


import Select from 'react-select';

import { Field, reduxForm, submit } from 'redux-form';
import '../../Css/Table.css';
//import ResponseAddComponent from './ResponseAddComponent';
import { withRouter } from 'react-router';


const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 0
    })
};


const RenderSelectInput = ({ input, options, name, id }) => (
    <Select
        {...input}
        id={id}
        name={name}
        options={options}
        value={input.value}
        onChange={(a) => input.onChange(a ? a.value : 0)}
        onBlur={(a) => input.onBlur(a ? a.value : 0)}

        placeholder="管理会社を選択"
    />
)

const RenderSelectUsers = ({ input, options, name, id }) => (
    <Select
        {...input}
        id={id}
        name={name}
        options={options}
        value={input.value}
        onChange={(a) => input.onChange(a ? a.value : 0)}
        onBlur={(a) => input.onBlur(a ? a.value : 0)}

        placeholder="追客担当を選択"
    />
)



class TatemonoListSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalselect: false,
            modalcalender: false,

        };

        this.props.initialize({ kanriCorpNo: 1 });//フォームに初期値を反映させる

    }

    componentDidMount() {

        //  this.props.responseList({sectionId:this.props.masterReducer.user_details.amSectionId})
    }
    toggle() {
        //this.setState(prevState => ({
        //    modal: !prevState.modal
        //}));
        // this.props.customerNewDispSet(0)
        // let matching={}


        // this.props.change_matching(matching)
        // this.props.history.push('/CustomerNew');

    }






    render() {

        const { handleSubmit } = this.props;


        return (
            <div>
                <form onSubmit={handleSubmit}  >
                    <table>

                        <tbody>

                            <tr >

                                <td colSpan="2" >
                                    <div className="form-inline">
                                        <Field
                                            name="tatemonoName"
                                            component="input"
                                            type="text"
                                            placeholder="建物名"
                                            className="form-control"
                                            size="20"
                                        />




                                        <div class="searchform">
                                            <Field

                                                name="kanriCorpNo"
                                                options={this.props.masterReducer.kanricorps}
                                                component={RenderSelectInput} />
                                        </div>


                                        <label key="1" style={{ padding: 5 }}>
                                            <Field name="isTatemono" component="input" type="radio" checked={this.state.isTatemono === "1" || !this.state.isTatemono} value="1" onChange={() => this.setState({ "isTatemono": "1" })} />
                                      建物</label>
                                        <label key="2" style={{ padding: 5 }}>
                                            <Field name="isTatemono" component="input" type="radio" checked={this.state.isTatemono === "0"} value="0" onChange={() => this.setState({ "isTatemono": "0" })} />
                                      駐車場</label>




                                        <button
                                            type="submit"
                                            className="btn btn-primary "
                                        >検索</button>



                                        <button
                                            onClick={this.props.toggleoutput}
                                            style={{ marginLeft: "10px" }}
                                            className="btn btn-success"
                                        >印刷</button>
                                    </div>
                                </td>



                            </tr>

                        </tbody>
                    </table>



                </form>




            </div>
        );
    }
}




//<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>
//    <ResponseInsertComponent
//        toggle={this.toggle} onSubmit={this.props.customerUpdate}
//    />
//</Modal>


TatemonoListSearch = connect(
    ({ masterReducer, responselist }) => ({ masterReducer, responselist }),
    // state => ({
    //   mapsearch: state.mapsearchReducer,

    // }),
    {
    }

)(TatemonoListSearch)



TatemonoListSearch = reduxForm({
    form: 'tatemonoserchform', // a unique identifier for this form
    onSubmit: submit
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(TatemonoListSearch)

export default withRouter(TatemonoListSearch)