import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import * as Contracts from './Saga/Contracts';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import * as Contracts from './Saga/Contracts';
import Select from '@mui/material/Select';
var moment = require('moment-timezone');

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_details.parkings[rowIndex][column.key]}
  </Cell>;


const Amount = ({ column, props, rowIndex, monies }) =>
  monies[rowIndex].name === "合計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} >{monies[rowIndex][column.key]}</Cell> : monies[rowIndex].rent_m_etc_id ? <Cell style={{ whiteSpace: "nowrap", background: "#CCC", width: "100%", height: "100%" }} /> : <Cell style={{ whiteSpace: "nowrap" }} >
    <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="tatemono_name"
      // onChange={this.handleChange}
      inputProps={{ min: 0, style: { textAlign: 'right' } }} // the change is here
      value={monies[rowIndex][column.key]}
      style={{ width: "70px", marginTop: 0 }}

    />

  </Cell>;
const all_seikyuu = [
  { value: -1, name: "請求" },
  { value: 1, name: "定額" },
  { value: 2, name: "実費" },
  { value: 3, name: "込み" }
]
const koushin_seikyuu = [
  { value: -1, name: "請求" },
  { value: 3, name: "無し" }
]
const seisan_seikyuu = [
  { value: 1, name: "実費" },
  { value: 2, name: "込み" }
]
const Seikyuu = ({ column, props, rowIndex, monies }) =>
  monies[rowIndex].name === "合計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} /> : <Cell style={{ whiteSpace: "nowrap" }} >
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={monies[rowIndex].rent_m_etc_id ? monies[rowIndex].rent_m_etc_id : -1}
    // defaultValue={0}
    // onChange={handleChange}
    >
      {monies[rowIndex].account_group === 3 ? koushin_seikyuu.map(function (value) {
        return <MenuItem value={value.value}>{value.name}</MenuItem>
      }) : monies[rowIndex].rent_m_account_id === 17 ? seisan_seikyuu.map(function (value) {
        return <MenuItem value={value.value}>{value.name}</MenuItem>
      }) : all_seikyuu.map(function (value) {
        return <MenuItem value={value.value}>{value.name}</MenuItem>
      })}


    </Select>
  </Cell>;


const Delete = ({ column, props, rowIndex, monies }) =>
  monies[rowIndex].name === "合計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} /> : monies[rowIndex].rent_m_account_id === 1 || monies[rowIndex].rent_m_account_id === 2 || monies[rowIndex].rent_m_account_id === 3 || monies[rowIndex].rent_m_account_id === 4 || monies[rowIndex].rent_m_account_id === 9 || monies[rowIndex].rent_m_account_id === 10 ? <Cell style={{ whiteSpace: "nowrap", background: "#CCC", width: "100%", height: "100%" }} /> : <Cell style={{ whiteSpace: "nowrap" }} >
    <Tooltip title="削除" >
      <IconButton size="small" aria-label="delete" onClick={() => this.props.change_rent_contract_editor_disp(false)}>
        <DeleteForeverIcon color="secondary" />
      </IconButton>
    </Tooltip>
  </Cell>;

const Koumoku = ({ column, props, rowIndex, monies }) =>

  monies[rowIndex].name === "合計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} >合計</Cell> :
    (monies[rowIndex].account_group === 3 || monies[rowIndex].account_group === 4) ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} >{monies[rowIndex].name}</Cell>
      : (monies[rowIndex].rent_m_account_id === 1 || monies[rowIndex].rent_m_account_id === 2 || monies[rowIndex].rent_m_account_id === 3 || monies[rowIndex].rent_m_account_id === 4 || monies[rowIndex].rent_m_account_id === 9 || monies[rowIndex].rent_m_account_id === 10 || monies[rowIndex].rent_m_account_id === 11 || monies[rowIndex].rent_m_account_id === 12 || monies[rowIndex].rent_m_account_id === 14) ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} >{monies[rowIndex].name}</Cell>
        : <Cell style={{ whiteSpace: "nowrap" }} >
          {props.tm_chinryou_koumoku.length > 0 ? <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={monies[rowIndex].rent_m_account_id}
          // defaultValue={0}
          // onChange={handleChange}
          >
            {props.tm_chinryou_koumoku.map(function (value) {
              return <MenuItem value={value.Chinryou_Koumoku_No}>{value.Chinryou_Koumoku_Name}</MenuItem>
            })}


          </Select> : ""}
        </Cell>



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns = [
  { key: 'p_name', name: '駐車場名', width: 140 },
  {
    key: 'pa_name', name: '区画', width: 65,

  },
  { key: 'color', name: '色', width: 60 },
  { key: 'bango', name: '番号', width: 90 },
  { key: 'maker', name: 'メーカー', width: 60 },
  { key: 'syasyu', name: '車種', width: 90 },
  { key: 'remarks', name: '備考', width: 90 },


]







class CustomerList extends Component {
  constructor(props) {
    super(props);


    // this.props.rent_m_contract_follows_disp.map(async function (value) {
    //   if (value.disp) {
    //     columns.push({ key: value.key_name, name: value.name, company: value.company, width: 80 })
    //   }
    // })
    console.log(columns)

    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };
    // this.props.get_contract_follow_list()
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);



    // this.contractdetail = this.contractdetail.bind(this);


  }





  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }











  render() {
    let hiwari = []

    return (
      <div id="contract_car" >


        <Button style={{ float: "right", margin: 5 }} size="small" variant="outlined" color="primary"
          onClick={() => this.props.change_rent_contract_car_editor_disp(true)}
        >
          編集
      </Button>


        {
          this.props.rent_contract_details.parkings ? <div style={{ marginTop: 10, marginBottom: 10 }}><Table
            rowHeight={40}
            rowsCount={this.props.rent_contract_details.parkings.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            width="100%"
            height={this.props.rent_contract_details.parkings.length * 40 + 42}
            // width={window.innerWidth - 110}
            // height={window.innerHeight - 250}
            headerHeight={40}>
            {this.state.columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  // value.key === "name" ? <Koumoku column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Koumoku>
                  //   : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Seikyuu>

                  //     : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Amount>
                  //       : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Delete>

                  // :
                  <Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table > </div> : ""
        }




      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    rent_contract_details: state.contracts.rent_contract_details,
    tm_chinryou_koumoku: state.masterReducer.tm_chinryou_koumoku,

  }
}
function mapDispatchToProps(dispatch) {
  return {


    // change_responseid(state) {
    //   dispatch(Responses.change_responseid(state))
    // },
    // change_modalResponseDetail(state) {
    //   dispatch(Responses.change_modalResponseDetail(state))
    // },

    // change_rent_contract_id(state) {
    //   dispatch(Contracts.change_rent_contract_id(state))
    // },
    change_rent_contract_car_editor_disp(state) {
      dispatch(Contracts.change_rent_contract_car_editor_disp(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);