
const authentication = require('../../../react-azure-adb2c2').default;




export const list = (values: any) => {
    let tatemonoId = values.tatemonoId
    if (values.isTatemono == 0 && tatemonoId.indexOf('SysP') === -1) {
        tatemonoId = "SysP" + values.tatemonoId
    }

    let url = process.env.REACT_APP_API_BASE_URL + `/api/TShutokus/` + tatemonoId;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const detail = (values: any) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/TShutokuDetails/` + values.tatemonoId + '/' + values.shutokuNo;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};







