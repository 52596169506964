import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
// import CorporatesDetailDialog from "./CorporatesDetailDialog";
import './Css/Grid.css'

// import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import { Table, Column, Cell } from 'fixed-data-table-2';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, Checkbox } from '@mui/material';

// import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
// import MaterialUiSelect from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardTimePicker,
//     KeyboardDatePicker,

// } from '@material-ui/pickers';

// import ja from "date-fns/locale/ja";
// import DateFnsUtils from '@date-io/date-fns';
// import CountComing from './Gadgets/CountComing';
// import ResponseCount from './Gadgets/ResponseCount/Index';

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';
import Radio from '@mui/material/Radio';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const styles = {
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },

}

const headers = [
    { label: "対応日", key: "taiou_date" },
    { label: "担当", key: "user_name" },
    { label: "法人名", key: "name" },
    { label: "対応方法", key: "rent_m_corporate_way_name" },
    { label: "対応内容", key: "remarks" },
  ];

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};
var SortTypes2 = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
function reverseSortDirection2(sortDir) {
  return sortDir === SortTypes2.DESC ? SortTypes2.ASC : SortTypes2.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class SortHeaderCell2 extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes2.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection2(this.props.sortDir) :
          SortTypes2.DESC
      );
    }
  }
}

class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modal_del: false,
            modalselect: false,
            columnWidths: {
                id: 100,
                name: 250,
                kana: 100,
                post: 100,
                add: 300,
                rent_corporate_office_id: 70,
                rent_corporate_office_name: 100,

                counting: 50,
                rent_customer_id: 100,
                rent_m_corporate_id: 80,
                name1:250,
                office_rent_m_corporate_id: 80,
                name2: 150,
                office: 80,
                office_name: 250,
                tenpo_name: 100,
                user_name_all: 100,
                contract_id: 100,
                office_rent_corporate_office_id: 70,
                office_rent_corporate_office_name: 100,



              },
            is_select_name_all: false,
            is_select_office_all: false,
            colSortDirs: {},
            colSortDirs2: {},
        };

        

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        this.handleCorpSearch = this.handleCorpSearch.bind(this);
        this.handleChangeCorporate = this.handleChangeCorporate.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.handleCustomerSearch = this.handleCustomerSearch.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleSelectNameAll = this.handleSelectNameAll.bind(this);

        // this.props.get_rent_m_corporates_list_replace()
        // this.props.get_rent_customer_list_replace()
        // this.props.get_rent_m_corporates_list()

        // this._defaultSortIndexes = [];
        // var size = this._dataList.getSize();
        // for (var index = 0; index < size; index++) {
        // this._defaultSortIndexes.push(index);
        // }

        // this.state = {
        // sortedDataList: this._dataList,
        
        // };

        this._onSortChange = this._onSortChange.bind(this);
        this._onSortChange2 = this._onSortChange2.bind(this);
        this._rowClassNameGetterCorporate = this._rowClassNameGetterCorporate.bind(this);
        this._rowClassNameGetterCustomer = this._rowClassNameGetterCustomer.bind(this);


    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
          columnWidths: {
            ...columnWidths,
            [columnKey]: newColumnWidth,
          },
        }));
    }


    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }


    handleCorpSearch(state) {

        this.props.get_rent_m_corporates_list_replace()
        // this.setState({
        //     search: state.target.value
        // });

        this.setState({ reflash: !this.state.reflash });
    }

    handleChangeCorporate(id, office_id){
        let search_data_replace =  this.props.search_data_replace
        search_data_replace.select_m_corporate_id = id
        search_data_replace.select_corporate_office_id = office_id

        this.props.change_search_data_replace(search_data_replace);
        this.setState({
            change: !this.state.change
        });
    }

    onChangeSearch(state) {

        let search_data_replace = this.props.search_data_replace
        search_data_replace[state.target.name] = state.target.value
        this.props.change_search_data_replace(search_data_replace);

        this.setState({ reflash: !this.state.reflash });
    }

    handleCustomerSearch(state) {

        this.props.get_rent_customer_list_replace_search()

        this.setState({ reflash: !this.state.reflash });
    }

    handleSelectName(e, rent_customer_id){

        let rent_customer_list_replace_search = this.props.rent_customer_list_replace_search
    
        let index = rent_customer_list_replace_search.findIndex(e => e.rent_customer_id == rent_customer_id)
        // console.log(this.props.claso_blog_places)
    
        rent_customer_list_replace_search[index][e.target.name] = e.target.checked
    

        this.props.change_rent_customer_list_replace_search(rent_customer_list_replace_search);
        this.setState({
            change: !this.state.change
        });
    }
    
    handleSelectNameAll(e, select_state){

        let rent_customer_list_replace_search = this.props.rent_customer_list_replace_search

        rent_customer_list_replace_search = rent_customer_list_replace_search.map(function (value) {
            value[e.target.name.replace("_all", "")] = !select_state
            return value
        })
    

        this.props.change_rent_customer_list_replace_search(rent_customer_list_replace_search);

            this.setState({
                [e.target.name]: !select_state,
                change: !this.state.change
            });
    }

    handleUpdate() {

        let search_data_replace =  this.props.search_data_replace
        
        if(!search_data_replace.select_m_corporate_id){
            alert("法人を選択してください。")
            return
        }

        this.props.update_rent_customer_list_replace()

        this.setState({
            change: !this.state.change
        });

    }

    handleCorporateOpen(id) {
        window.open(`/Corporate/` + id, '_blank', 'noopener')
        // this.setState({ 
        //     "relationowner_id" : id,
        //     "modal_relationowner": true 
        // })
    }

    _onSortChange(columnKey, sortDir) {

        let rent_m_corporates_list_replace = this.props.rent_m_corporates_list_replace;
        rent_m_corporates_list_replace.sort((indexA, indexB) => {
          // alert(indexA)
    
          var valueA = indexA[columnKey];
          var valueB = indexB[columnKey];
          var sortVal = 0;
          if (valueA > valueB) {
            sortVal = 1;
          }
          if (valueA < valueB) {
            sortVal = -1;
          }
    
          if (!valueA && !valueB) {
            sortVal = 0
          }
          if (!valueA) {
            sortVal = -1
          }
          if (!valueB) {
            sortVal = 1
          }
    
    
          if (sortVal !== 0 && sortDir === SortTypes.DESC) {
            sortVal = sortVal * -1;
          }
    
          return sortVal;
        });
        this.props.change_rent_m_corporates_list_replace(rent_m_corporates_list_replace)
        this.setState({
          // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
          colSortDirs: {
            [columnKey]: sortDir,
          },
        });
      }
    
      _onSortChange2(columnKey, sortDir) {

        let rent_customer_list_replace_search = this.props.rent_customer_list_replace_search;
        rent_customer_list_replace_search.sort((indexA, indexB) => {
          // alert(indexA)
    
          var valueA = indexA[columnKey];
          var valueB = indexB[columnKey];
          var sortVal = 0;
          if (valueA > valueB) {
            sortVal = 1;
          }
          if (valueA < valueB) {
            sortVal = -1;
          }
    
          if (!valueA && !valueB) {
            sortVal = 0
          }
          if (!valueA) {
            sortVal = -1
          }
          if (!valueB) {
            sortVal = 1
          }
    
    
          if (sortVal !== 0 && sortDir === SortTypes2.DESC) {
            sortVal = sortVal * -1;
          }
    
          return sortVal;
        });
        this.props.change_rent_customer_list_replace_search(rent_customer_list_replace_search)
        this.setState({
          colSortDirs2: {
            [columnKey]: sortDir,
          },
        });
      }

      _rowClassNameGetterCorporate(index){
        
        if(this.props.rent_m_corporates_list_replace && this.props.rent_m_corporates_list_replace[index]) {
        //   return this.props.owner_eigyou[index].type == 1 ? "line" : 
        //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
        //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
            return "normal"
        }else{
          return ""
        }
    }

    _rowClassNameGetterCustomer(index){
        
      if(this.props.rent_customer_list_replace_search && this.props.rent_customer_list_replace_search[index]) {
      //   return this.props.owner_eigyou[index].type == 1 ? "line" : 
      //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
      //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
          return "normal"
      }else{
        return ""
      }
    }


    render() {

        return (
            <Card style={{ padding: "10px",backgroundColor: "#e9f2f8" }}>

                
                <Grid container spacing={1}>
                    <Grid justifyContent="space-between" item xs={5}>

                <Card>
                    <CardContent>

                    <TextField 
                      style={{ width: "300" }} 
                      onChange={this.onChangeSearch} 
                      size="small" 
                      id="corpsearch" 
                      label="検索" 
                      name="m_corporate_name" 
                      variant="outlined" 
                      InputLabelProps={{ shrink: true }} 
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                            // エンターキー押下時の処理
                            this.handleCorpSearch(e)
                        }
                    }}
                    />
                    <Button variant="contained" onClick={this.handleCorpSearch} color="primary" style={{ marginLeft: 5 }}>検索</Button>
                    <Table 
                        className="gridCorporateNormalList"
                        rowHeight={34} 
                        rowsCount={this.props.rent_m_corporates_list_replace.length} 
                        height={window.innerHeight - 190} 
                        headerHeight={30} 
                        width={(window.innerWidth / (12/5)) - 65}
                        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                        isColumnResizing={false}
                        rowClassNameGetter={this._rowClassNameGetterCorporate}
                    >

                        <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><Radio style={{marginLeft:-5, marginTop:-5}} size="small" checked={this.props.search_data_replace.select_m_corporate_id == this.props.rent_m_corporates_list_replace[rowIndex].id && this.props.search_data_replace.select_corporate_office_id == this.props.rent_m_corporates_list_replace[rowIndex].rent_corporate_office_id} onChange={()=>this.handleChangeCorporate(this.props.rent_m_corporates_list_replace[rowIndex].id, this.props.rent_m_corporates_list_replace[rowIndex].rent_corporate_office_id)} value={this.props.rent_m_corporates_list_replace[rowIndex].id} name="radio-buttons" /></Cell>)} width={40} />
                        <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} aria-label="edit" size="small" onClick={() => { this.handleCorporateOpen(this.props.rent_m_corporates_list_replace[rowIndex].id) }}><EditIcon /></IconButton></Cell>)} width={40} />
                        <Column width={this.state.columnWidths.id} isResizable={true} columnKey="id" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>法人名ID</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list_replace[rowIndex].id}</Cell>)} />
                        <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.name} style={{ whiteSpace: "nowrap" }}>法人名</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list_replace[rowIndex].name}</Cell>)} />
                        <Column width={this.state.columnWidths.rent_corporate_office_id} isResizable={true} columnKey="rent_corporate_office_id" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.rent_corporate_office_id} style={{ whiteSpace: "nowrap" }}>支店ID</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list_replace[rowIndex].rent_corporate_office_id}</Cell>)} />
                        <Column width={this.state.columnWidths.rent_corporate_office_name} isResizable={true} columnKey="rent_corporate_office_name" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.rent_corporate_office_name} style={{ whiteSpace: "nowrap" }}>支店名</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list_replace[rowIndex].rent_corporate_office_name}</Cell>)} />
                        
                        
                        {/* <Column width={this.state.columnWidths.kana} isResizable={true} columnKey="kana" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.kana} style={{ whiteSpace: "nowrap" }}>カナ</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list_replace[rowIndex].kana}</Cell>)} /> */}
                        {/* <Column width={this.state.columnWidths.post} isResizable={true} columnKey="post" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.post} style={{ whiteSpace: "nowrap" }}>〒</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list_replace[rowIndex].post}</Cell>)} />
                        <Column width={this.state.columnWidths.add} isResizable={true} columnKey="add" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.add} style={{ whiteSpace: "nowrap" }}>住所</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list_replace[rowIndex].add1}{this.props.rent_m_corporates_list_replace[rowIndex].add2}</Cell>)} /> */}

                    </Table>

                    </CardContent>
                </Card>


                    </Grid>

                    <Grid justifyContent="space-between" item xs={7}>

                <Card>
                    <CardContent>

                    <TextField 
                      style={{ width: "300" }} 
                      onChange={this.onChangeSearch} 
                      size="small" 
                      id="corpsearch" 
                      label="検索" 
                      name="customer_name" 
                      variant="outlined" 
                      InputLabelProps={{ shrink: true }} 
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                            // エンターキー押下時の処理
                            this.handleCustomerSearch(e)
                        }
                    }}
                    />
                    <Button variant="contained" onClick={this.handleCustomerSearch} color="primary" style={{ marginLeft: 5 }}>検索</Button>
                    <Button variant="contained" onClick={this.handleUpdate} style={{ float: "right", marginTop: 5, marginRight: 5, backgroundColor: "#5cb85c", color:"white" }}>置き換え</Button>
                    {/* <RadioGroup row aria-label="position" name="type" onChange={this.handleChange} value={this.props.search_data_replace.type} >
                        <FormControlLabel control={<Radio value={0} />} label="契約者" />
                        <FormControlLabel control={<Radio value={1} />} label="勤務先" />
                    </RadioGroup> */}
                    
                    {this.props.rent_customer_list_replace_search ? <Table 
                        className="gridCorporateNormalList"
                        rowHeight={34} 
                        rowsCount={this.props.rent_customer_list_replace_search.length} 
                        height={window.innerHeight - 190} 
                        headerHeight={30} 
                        width={(window.innerWidth / (12/7)) - 65}
                        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                        isColumnResizing={false}
                        rowClassNameGetter={this._rowClassNameGetterCustomer}
                    >
                        <Column header={<Cell><Checkbox style={{marginLeft:-8, marginTop:-12}} checked={this.state.is_select_name_all} onChange={(e) => this.handleSelectNameAll(e, this.state.is_select_name_all)} name="is_select_name_all" /></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><Checkbox style={{marginLeft:-8, marginTop:-10}} checked={this.props.rent_customer_list_replace_search[rowIndex].is_select_name} onChange={(e) => this.handleSelectName(e, this.props.rent_customer_list_replace_search[rowIndex].rent_customer_id)} name="is_select_name" /></Cell>)} width={40} />
                        {/* <Column width={this.state.columnWidths.rent_customer_id} isResizable={true} columnKey="rent_customer_id" header={<Cell>rent_customer_id</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].rent_customer_id}</Cell>)} /> */}
                        <Column width={this.state.columnWidths.counting} isResizable={true} columnKey="counting" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.counting} style={{ whiteSpace: "nowrap" }}>種別</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].section_id == 36 ? <span className="badge badge-danger">M</span> : this.props.rent_customer_list_replace_search[rowIndex].counting == 2 ? <span className="badge badge-warning">P</span> : ""}</Cell>)} />
                        <Column width={this.state.columnWidths.rent_m_corporate_id} isResizable={true} columnKey="rent_m_corporate_id" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.rent_m_corporate_id} style={{ whiteSpace: "nowrap" }}>契約者ID</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].rent_m_corporate_id}</Cell>)} />
                        <Column width={this.state.columnWidths.name1} isResizable={true} columnKey="name1" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.name1} style={{ whiteSpace: "nowrap" }}>契約者名</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].name1}</Cell>)} />
                        <Column width={this.state.columnWidths.rent_corporate_office_id} isResizable={true} columnKey="rent_corporate_office_id" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.rent_corporate_office_id} style={{ whiteSpace: "nowrap" }}>支店ID</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].rent_corporate_office_id}</Cell>)} />
                        <Column width={this.state.columnWidths.rent_corporate_office_name} isResizable={true} columnKey="rent_corporate_office_name" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.rent_corporate_office_name} style={{ whiteSpace: "nowrap" }}>支店名</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].rent_corporate_office_name}</Cell>)} />
                        
                        
                        <Column width={this.state.columnWidths.name2} isResizable={true} columnKey="name2" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.name2} style={{ whiteSpace: "nowrap" }}>入居者</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].name2}</Cell>)} />
                        
                        <Column header={<Cell><Checkbox style={{marginLeft:-8, marginTop:-12}} checked={this.state.is_select_office_all} onChange={(e) => this.handleSelectNameAll(e, this.state.is_select_office_all)} name="is_select_office_all" /></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><Checkbox style={{marginLeft:-8, marginTop:-10}} checked={this.props.rent_customer_list_replace_search[rowIndex].is_select_office} onChange={(e) => this.handleSelectName(e, this.props.rent_customer_list_replace_search[rowIndex].rent_customer_id)} name="is_select_office" /></Cell>)} width={40} />
                        <Column width={this.state.columnWidths.office_rent_m_corporate_id} isResizable={true} columnKey="office_rent_m_corporate_id" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.office_rent_m_corporate_id} style={{ whiteSpace: "nowrap" }}>勤務先ID</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].office_rent_m_corporate_id}</Cell>)} />
                        <Column width={this.state.columnWidths.office_name} isResizable={true} columnKey="office_name" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.office_name} style={{ whiteSpace: "nowrap" }}>勤務先名</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].office_name}</Cell>)} />
                        <Column width={this.state.columnWidths.office_rent_corporate_office_id} isResizable={true} columnKey="office_rent_corporate_office_id" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.office_rent_corporate_office_id} style={{ whiteSpace: "nowrap" }}>支店ID</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].office_rent_corporate_office_id}</Cell>)} />
                        <Column width={this.state.columnWidths.office_rent_corporate_office_name} isResizable={true} columnKey="office_rent_corporate_office_name" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.office_rent_corporate_office_name} style={{ whiteSpace: "nowrap" }}>支店名</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].office_rent_corporate_office_name}</Cell>)} />
                        
                        {/* <Column width={this.state.columnWidths.office} isResizable={true} columnKey="office" header={<Cell>勤務先ID</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].office}</Cell>)} /> */}
                        <Column width={this.state.columnWidths.tenpo_name} isResizable={true} columnKey="tenpo_name" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.tenpo_name} style={{ whiteSpace: "nowrap" }}>店舗</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].tenpo_name}</Cell>)} />
                        <Column width={this.state.columnWidths.user_name_all} isResizable={true} columnKey="user_name_all" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.user_name_all} style={{ whiteSpace: "nowrap" }}>担当</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].user_name_all}</Cell>)} />
                        <Column width={this.state.columnWidths.contract_id} isResizable={true} columnKey="contract_id" header={<SortHeaderCell2 onSortChange={this._onSortChange2} sortDir={this.state.colSortDirs2.contract_id} style={{ whiteSpace: "nowrap" }}>契約ID</SortHeaderCell2>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_customer_list_replace_search[rowIndex].rent_contract_id}</Cell>)} />
                    </Table> : <CircularProgress size={30} />}

                    </CardContent>
                </Card>
                
                
                
                    </Grid>

                </Grid>

            </Card>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates_list_replace: state.corporation.rent_m_corporates_list_replace,
        rent_m_corporates_list_replace_search: state.corporation.rent_m_corporates_list_replace_search,
        rent_customer_list_replace: state.corporation.rent_customer_list_replace,
        rent_customer_list_replace_search: state.corporation.rent_customer_list_replace_search,
        search_data_replace: state.corporation.search_data_replace,
        customer_search: state.corporation.customer_search,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_rent_m_corporates_list_replace(state) {
            dispatch(Corporation.get_rent_m_corporates_list_replace(state))
        },
        get_rent_m_corporates_list_replace_search(state) {
            dispatch(Corporation.get_rent_m_corporates_list_replace_search(state))
        },
        get_rent_customer_list_replace(state) {
            dispatch(Corporation.get_rent_customer_list_replace(state))
        },
        get_rent_customer_list_replace_search(state) {
            dispatch(Corporation.get_rent_customer_list_replace_search(state))
        },
        change_rent_customer_list_replace_search(state) {
            dispatch(Corporation.change_rent_customer_list_replace_search(state))
        },
        change_search_data_replace(state) {
            dispatch(Corporation.change_search_data_replace(state))
        },
        update_rent_customer_list_replace(state) {
            dispatch(Corporation.update_rent_customer_list_replace(state))
        },

        change_rent_m_corporates_list_replace_search(state) {
            dispatch(Corporation.change_rent_m_corporates_list_replace_search(state))
        },
        change_rent_customer_list_replace_search(state) {
            dispatch(Corporation.change_rent_customer_list_replace_search(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




