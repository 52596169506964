import React, { Component, useRef } from 'react';

import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';


class ContractsFileUploadComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deletealert: false,
      uploadFiles:undefined,
    };

    // this.props.change_contract_file_remarks(this.props.remarks)


    this.onDrop = this.onDrop.bind(this);
  }


  onDrop = (files) => {
    console.log(files)
    const file = files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        base64: reader.result,
        name: file.name,
      })
    }

    let sendfile = [];
    sendfile.file = files;
    // sendfile.id = this.props.introduction_file_id;
    if(files){
        console.log('ファイル変更')
        const blob = new Blob([files[0]], { type: files[0].type });
        console.log('Blob:', blob);
        this.props.file_upload(blob)    
        this.setState({uploadFiles:files})
    }
    // this.props.change_contract_file_data(files)


    // this.props.add_introduction_file(sendfile)
  };
  render() {


    return (

      <div >
          <div style={{ textAlign: "center" }}>

            <div style={{ height: "100%", margin: 5 }}>
                <Dropzone

                onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (

                  <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5" }}>

                    <input {...getInputProps()} />

                    <div style={{ height: 100 }}>
                      <p>{this.state.name ? this.state.name : "ファイル添付　クリックまたはドラッグ&ドロップ"}</p>
                      <ul style={{ padding: 0, marginTop: 3 }}  >

                      </ul>

                    </div>

                  </div>

                )}
              </Dropzone>

            </div>
          </div >
      </div >

    );

  }
}




//コンテナ
const mapStateToProps = state => {
  return {
    
  }
}
function mapDispatchToProps(dispatch) {
  return {
    // change_contract_file_data(state) {
    //   dispatch(Files.change_contract_file_data(state))
    // },
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(ContractsFileUploadComponent);
