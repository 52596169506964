import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as Search from './Saga/Search';

import * as Responses from './../ResponseDetail/Saga/Responses';


import { Field } from 'redux-form';
import '../../Css/Table.css';

let hiddenFlg;




export class CheckboxGroup extends React.Component {

    checkboxGroup() {
        let { label, required, options, input, meta, request_chomei } = this.props;
        console.log(options)
        // alert()
        return options.map((option, index) => {
            //console.log(input.value)

            return (
                <div className="checkbox eq" key={option.id}>
                    <label for={"chomei" + option.id}>
                        <input type="checkbox"
                            id={"chomei" + option.id}
                            name={`${input.name}[${option.id}]`}
                            value={option.id}
                            checked={request_chomei ? (request_chomei.split(',').filter(a => a === String(option.id))[0] ? true : false) : false}

                            onChange={(event) => {

                                // let newValue = [...input.value];
                                // if (event.target.checked) {
                                //     newValue.push(option.id);
                                // } else {
                                //     //newValue.splice(newValue.indexOf(index+1), 1);
                                //     newValue = newValue.filter(function (item) {
                                //         return item !== option.id;
                                //     });
                                // }

                                return input.onChange(option.id);
                            }}
                        />
                        <span>{option.townName}</span>
                    </label>
                </div>)
        });
    }


    render() {
        return (
            <div>
                {this.checkboxGroup()}
            </div>
        )
    }
}


class ChomeiEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reflesh: false
            // gasu: this.props.responsedetail.gasu,
            // denki: this.props.responsedetail.denki,
            // ih: this.props.responsedetail.ih,
            // gasuka: this.props.responsedetail.gasuka,
            // denkika: this.props.responsedetail.denkika,
            // ihka: this.props.responsedetail.ihka,
            // eakon: this.props.responsedetail.eakon,
        };

        hiddenFlg = this.props.hiddenFlg; //0表示　1非表示

        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.set_chomeis = this.set_chomeis.bind(this);
        // this.props.get_chomei();

        // this.onChange = this.onChange.bind(this);

    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得

    }


    componentDidMount() {
        this.ensureDataFetched();


    }

    componentDidUpdate() {
        this.ensureDataFetched();
    }

    ensureDataFetched() {

    }
    set_chomeis(value) {



        let rent_responses_editor = this.props.rent_responses_editor

        let request_chomei = rent_responses_editor.request_chomei ? rent_responses_editor.request_chomei.split(',') :[]

        if (request_chomei.filter(a => a === String(value))[0]) {

            rent_responses_editor.request_chomei = request_chomei.filter(a => a !== String(value)).join(',')

        } else {
            request_chomei.push(String(value))
            console.log(request_chomei)

            rent_responses_editor.request_chomei = request_chomei.join(',')
            //     rent_responses_editor.request_shougakkou = request_shougakkou ? request_shougakkou.push(value).join(',') : value

        }
        this.props.change_rent_responses_editor(rent_responses_editor)

        this.setState({ reflesh: !this.state.reflesh })
    }
    render() {

        //    console.log(this.props.setsubi_master)

        const hiddenSetsubi = [];


        const { handleSetsubiSubmit } = this.props;
        return (

            <div>

                {/* <button className="btn btn-sm btn-light float-right" style={{ display: (this.props.notclose === 1) ? 'none' : '' }} onClick={this.props.setsubieditchange}>閉じる</button> */}
                <table>
                    <colgroup width='20%' />
                    <colgroup width='80%' />
                    <tbody>


                        <tr >
                            <th>あ行</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={this.props.chomeis.slice(1, 21)} />
                            </td>
                        </tr>
                        <tr >
                            <th>か行</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={this.props.chomeis.slice(21, 77)} />
                            </td>
                        </tr>
                        <tr >
                            <th>さ行</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={this.props.chomeis.slice(77, 109)} />
                            </td>
                        </tr>
                        <tr >
                            <th>た行</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={this.props.chomeis.slice(109, 130)} />
                            </td>
                        </tr>
                        <tr >
                            <th>な行</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={this.props.chomeis.slice(130, 143)} />
                            </td>
                        </tr>
                        <tr >
                            <th>は行</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={this.props.chomeis.slice(143, 165)} />
                            </td>
                        </tr>
                        <tr >
                            <th>ま行</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={this.props.chomeis.slice(165, 185)} />
                            </td>
                        </tr>
                        <tr >
                            <th>や行</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={this.props.chomeis.slice(185, 190)} />
                            </td>
                        </tr>
                        <tr >
                            <th>ら行</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={this.props.chomeis.slice(190, 192)} />
                            </td>
                        </tr>

                        <tr >
                            <th>その他</th>
                            <td>
                                <Field name="checkChomei" request_chomei={this.props.rent_responses_editor.request_chomei} onChange={(e) => this.set_chomeis(e)} component={CheckboxGroup} options={[{ id: -1, townName: "東かがわ市" }, { id: -2, townName: "さぬき市" }]} />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* {this.props.edit===false?"":<button className="btn btn-primary float-right" onClick={this.handleFormSubmit}>保存</button>} */}

            </div>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        setsubi_master: state.roomsReducer.setsubi_master,

        chomeis: state.masterReducer.chomeis,

        rent_responses_editor: state.responsesReducer.rent_responses_editor,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {


        get_chomei(state) {
            dispatch(Search.get_chomei(state))
        },

        change_rent_responses_editor(state) {
            dispatch(Responses.change_rent_responses_editor(state))
        },

    };
}

// ChomeiEdit = reduxForm({
//     form: 'chomeiform', // a unique identifier for this form
//     // validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ChomeiEdit)

export default connect(mapStateToProps, mapDispatchToProps)(ChomeiEdit);