const authentication = require('../../../react-azure-adb2c2').default;
// export function update( values: any) {

// console.log(values)

export function getlist(values: any) {

    //     if(!!values.sectionId){
    //     values.sectionId=values.sectionId.value;
    // }
    // alert(JSON.stringify(values))
    const token = authentication.getAccessToken();

    // const url = `http://localhost:5000/api/v1/rent/responses`
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses`
    // const url = `http://localhost:90/laravelapi/public/api/usere`
    const search = values ? values : {};
    // alert(JSON.stringify(search))
    // alert(url)



    // search.sectionId=search.sectionId.value;
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}

export function getlist_syokai(values: any) {

    //     if(!!values.sectionId){
    //     values.sectionId=values.sectionId.value;
    // }
    // alert(JSON.stringify(values))
    const token = authentication.getAccessToken();

    // const url = `http://localhost:5000/api/v1/rent/responses/syokai`
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/syokai`

    const search = values ? values : {};
    // alert(JSON.stringify(search))
    // alert(url)



    // search.sectionId=search.sectionId.value;
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}


export function matcing_list(value: any) {

    const token = authentication.getAccessToken();

    // const url = `http://localhost:5000/api/v1/response/matcing/mails/list/` + value
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/response/matcing/mails/list/` + value
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}


export function matcing_select_list(value: any) {

    const token = authentication.getAccessToken();

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/response/matcing/mails/select/` + value


    // const url = `http://localhost:5000/api/v1/response/matcing/mails/select/` + value
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}




export function getdetail(values: any) {

    const token = authentication.getAccessToken();
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentResponses/` + values.responseId;

    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => res.json())
        //.then(payload => ({ payload }))
        .catch(error => ({ error }));
}



export function merge_response_mails(values: any) {

    const token = authentication.getAccessToken();

    const url = process.env.REACT_APP_API_BASE_URL + `/response/api/MatchingSelectList/` + values.responseId;

    // const search = values ? values : {};
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values.response_matching_select_list),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}



export function intend_list(values: any) {

    const token = authentication.getAccessToken();

    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContactsIntend/`;

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values.response_matching_select_list),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}