
const authentication = require('../../../react-azure-adb2c2').default;



export const detail = (values: any) => {

    const url = process.env.REACT_APP_API_ROOMS_URL + `/api/SearchRooms/` + values.tatemonoId + '/' + values.roomId;

    const token = authentication.getAccessToken();
    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    }).then(res => res.json())
        .then(payloads => ({ payloads }))
        .catch(errors => ({ errors }));


}


export const update = (values: any) => {

    const token = authentication.getAccessToken();
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContacts/` + values.id;
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}

export function insert(values: any) {

    const token = authentication.getAccessToken();
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContacts/`;


    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}