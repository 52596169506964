import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './Css/OtherGrid.css';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

var moment = require('moment-timezone');
moment.locale("ja", {
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});
var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

var test_data = [
  { id: 0, shop: "番町店", date: "2020-01-01", name: "テスト１", tantou: "高橋", uriagename: "インセンティブ", uriage: "仲介料", uriagegaku: "20000", bikou: "あああ", created_at: "2020-01-01", created_user: "高橋" },
  { id: 1, shop: "サンフラワー店", date: "2020-01-02", name: "テスト２", tantou: "高橋", uriagename: "駐車場", uriage: "仲介料", uriagegaku: "3000", bikou: "", created_at: "2020-01-01", created_user: "高橋" }
]

const columns =
  [
    { key: 'date', name: '売上日', width: 100, resizable: true },
    { key: 'shop', name: '店舗', width: 150, resizable: true },
    { key: 'uriagename', name: '売上名', width: 300, resizable: true },
    { key: 'name', name: 'お客様名', width: 200, resizable: true },
    { key: 'tantou', name: '担当', width: 100, resizable: true },
    { key: 'uriage', name: '売上', width: 150, resizable: true },
    { key: 'uriagegaku', name: '売上額', width: 130, resizable: true },
    { key: 'bikou', name: '備考', width: 300, resizable: true },
    { key: 'created_at', name: '登録日', width: 100, resizable: true },
    { key: 'created_user', name: '登録者', width: 100, resizable: true },

  ]

const Base = ({ column, props, rowIndex, detail_open, select_row }) =>
  (() => {
    if (props[rowIndex][column.key]) {
      return <div style={{
        cursor: "pointer",
        backgroundColor: props[rowIndex]['id'] == select_row ? "#87cefa" : ""
      }} onClick={() => detail_open(props[rowIndex]["id"])}>
        <Cell style={{ whiteSpace: "nowrap", fontSize: 16 }} >
          {props[rowIndex][column.key]}
        </Cell>
      </div>
    } else {
      return <div style={{
        cursor: "pointer",
        backgroundColor: props[rowIndex]['id'] == select_row ? "#87cefa" : ""
      }} onClick={() => detail_open(props[rowIndex]["id"])}>　</div>
    }
  })()

const Dates = ({ column, props, rowIndex, detail_open, select_row }) =>
  <div style={{
    cursor: "pointer",
    backgroundColor: props[rowIndex]['id'] == select_row ? "#87cefa" : ""
  }} onClick={() => detail_open(rowIndex)}>
    <Cell style={{ whiteSpace: "nowrap", fontSize: 16, cursor: "pointer" }} >
      {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).utc().format('YYYY-MM-DD') : ""}
    </Cell>
  </div>

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},
      select_row: 123456,
    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.detail_open = this.detail_open.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {
    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })
    this.setState({ columns: columnsset })
  }





  _onSortChange(columnKey, sortDir) {
    let seisan_follow_search_list = test_data;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new s DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }




  detail_open(id) {
    this.props.form_toggle();
    this.setState({ select_row: id });
  }

  render() {

    return (
      <div id="othersale">
        <Table
          rowHeight={30}
          rowsCount={test_data.length}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 110}
          height={window.innerHeight - 320}
          headerHeight={30}
        >

          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              cell={({ rowIndex, ...props }) => (
                value.key === "date" ? <Dates column={value} props={test_data} rowIndex={rowIndex} detail_open={this.detail_open} select_row={this.state.select_row}></Dates>
                  : <Base column={value} props={test_data} rowIndex={rowIndex} detail_open={this.detail_open} select_row={this.state.select_row}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}
            />
          }, this)}
        </Table >
      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
  }
}
function mapDispatchToProps(dispatch) {
  return {

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);