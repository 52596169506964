import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Modal,ModalHeader,ModalFooter,ModalBody} from 'reactstrap';
import * as MapMypage from './Saga/MapMypage';
import * as MapMypageTemplate from '../MapMypageTemplate/Saga/MapMypageTemplate';
//import { actionCreators } from '../../Store/MailData';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
// import makeStyles from '@mui/styles/makeStyles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CommentIcon from '@mui/icons-material/Comment';
import TextureIcon from '@mui/icons-material/Texture';
// import CircleIcon from '@mui/icons-material/Circle';
import CircleIcon from '@mui/icons-material/PanoramaFishEye';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import '../../Css/Table.css';
import MapMypageTemplateIndex from '../MapMypageTemplate/Index';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';
import TrainIcon from '@mui/icons-material/Train';
import StoreIcon from '@mui/icons-material/Store';
//import './Css/Search.css';
// import * as AutoKana from 'vanilla-autokana';






// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',

//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: '60.33%',
//     flexShrink: 0,
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary,
//   },
// }));
// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));


const kaigyo = (str) => {
  return str.split("\n").map((m,i) => <span key={i}>{m}<br/></span>)
};

const R = Math.PI / 180;

function distance(lat1, lng1, lat2, lng2) {
  lat1 *= R;
  lng1 *= R;
  lat2 *= R;
  lng2 *= R;
  // alert(6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2)))
  return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}

function line_distance(list) {
  let dis = 0
  // alert(list.length)
  for(let i = 1; i<list.length; i++){
    let dis2 = distance(list[i-1].lat, list[i-1].lng, list[i].lat, list[i].lng)
    // alert(dis2)
    dis = dis + dis2
  }
  return dis

}

function App(props) {
  // const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  const [savealert, setSavealert] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
    props.change_map_disp(!props.map_disp)
    setSavealert(false);
    props.change_rent_introduction_point_headers(props.rent_introduction_headers.rent_introduction_point_headers.filter(a => a.id === id)[0])
    if (isExpanded === false) {
      props.change_rent_introduction_point_headers([])
    }
  };



  const clear_rent_introduction_point_details = () => {
    let rent_introduction_point_headers = props.rent_introduction_point_headers
    rent_introduction_point_headers.rent_introduction_point_details = []
    props.change_rent_introduction_point_headers(rent_introduction_point_headers)
    props.change_map_disp(!props.map_disp)
  };


  const handleNumberSave = () => {


    var setnumber = props.rent_introduction_headers.rent_introduction_point_headers.filter(function (x, i, self) {
      return (self.findIndex(function (v2) {
        return (parseInt(x.tatemonoId) === parseInt(v2.tatemonoId))
      }) === i);
    });

    var setnumber2 = setnumber.filter(function (x, i, self) {
      return (self.findIndex(function (v2) {
        return (parseInt(x.numbering) === parseInt(v2.numbering))
      }) === i);
    });

    if (setnumber.length !== setnumber2.length) {

      setSavealert(true);
      return
    }

    setSavealert(false);
    props.update_rent_introduction_headers(1)
  };



  const mypageOpen = (id) => {


    window.open("https://rooms.global-center.co.jp/rent/" + props.responsedetail.response_code + "/" + id)
  }

  const [open, setOpen] = React.useState(false)
  const toggle = () => setOpen(!open)

  const [template_open, setTemplateOpen] = React.useState(false)
  const template_toggle = () => setTemplateOpen(!template_open)


  // onChange(event){
  const onChange = (event) => {
    let rent_introduction_point_headers = props.rent_introduction_point_headers
    if(event.target.name == "point_color"){
      rent_introduction_point_headers.rent_introduction_point_details[0][event.target.name]=event.target.value
    }else{
      // rent_introduction_point_headers[event.target.name]=event.target.value
    }

    props.change_rent_introduction_point_headers(rent_introduction_point_headers)
    console.log(event)

    setRefresh(!refresh);
    props.change_map_disp(!props.map_disp)

    // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}
//  alert(event.target.value)
  }

  const handleClose = () =>{

    props.change_rent_introduction_point_template_disp(false);
    props.change_rent_introduction_point_templates([])

  }

  const onChangePoint = (event) => {
    let rent_introduction_point_headers= props.rent_introduction_point_headers

    if(event.target.name == "link_url" || event.target.name == "point_color"){
      rent_introduction_point_headers.rent_introduction_point_details[0][event.target.name]=event.target.value
    }else if(event.target.name == "distance_is_no_disp"){
      rent_introduction_point_headers.rent_introduction_point_details[0][event.target.name]=event.target.checked ? 1 : 0
    }else{
      rent_introduction_point_headers[event.target.name]=event.target.value
    }

     props.change_rent_introduction_point_headers(rent_introduction_point_headers)
    console.log(event)
    setRefresh(!refresh);
    props.change_map_disp(!props.map_disp)
  }

  const onChangeIcon = (icon) =>{
    let rent_introduction_point_headers = props.rent_introduction_point_headers

    rent_introduction_point_headers.rent_introduction_point_details[0]["point_icon"] = icon

    props.change_rent_introduction_point_headers(rent_introduction_point_headers)
    setRefresh(!refresh);
    props.change_map_disp(!props.map_disp)
  }


  return (
    <div style={{ backgroundColor: "White", height: "100%", width: 250, textAlign: "left", paddingLeft: 8 }}>

      {props.rent_introduction_headers.name}
      {props.rent_introduction_headers.id ? <BottomNavigation
        //  value={props.map_settings_flg}
        onChange={(event, newValue) => {
          props.change_map_settings_flg(newValue);
        }}
        showLabels
      //   className={classes.root}
      >
        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_headers(1)} value={1} label="ポイント" icon={<LocationOnIcon />} />
        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_headers(3)} value={3} label="コメント" icon={<CommentIcon />} />




      </BottomNavigation> : ""}
      {props.rent_introduction_headers.id ? <BottomNavigation
        value={props.map_settings_flg}
        onChange={(event, newValue) => {
          props.change_map_settings_flg(newValue);
        }}
        showLabels
      //   className={classes.root}
      >


        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_headers(2)} value={2} label="範囲" icon={<TextureIcon />} />
        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_headers(4)} value={4} label="線" icon={<ShowChartIcon />} />
        <BottomNavigationAction onClick={() => props.insert_rent_introduction_point_headers(5)} value={5} label="円" icon={<CircleIcon />} />


      </BottomNavigation> : ""}

      <div>
        {props.rent_introduction_headers.rent_introduction_point_headers ? props.rent_introduction_headers.rent_introduction_point_headers.map((value, index) => {

          return (
            <Accordion expanded={props.rent_introduction_point_headers.id === value.id} onChange={handleChange(value.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography style={{display: 'inline-block'}}>
                  {value.type === 1 ? <LocationOnIcon /> : value.type === 2 ? <TextureIcon /> : value.type === 3 ? <CommentIcon /> : value.type === 4 ? <ShowChartIcon /> : <CircleIcon />} {value.contents}</Typography>
                {value.type == 5 || value.type == 4 ? <Typography style={{display: 'inline-block'}}>
                  {value.type == 5 && value.rent_introduction_point_details.length == 2 ? " 半径:" + (Math.round(distance(parseFloat(value.rent_introduction_point_details[0].lat), parseFloat(value.rent_introduction_point_details[0].lng),parseFloat(value.rent_introduction_point_details[1].lat),parseFloat(value.rent_introduction_point_details[1].lng))*10)/10) +"㎞" : ""}
                  {value.type == 4 && value.rent_introduction_point_details.length >= 2 ? " 距離" + (Math.round(line_distance(value.rent_introduction_point_details)*10)/10) +"㎞" : ""}

                </Typography>:""}



              </AccordionSummary>
              {/* <AccordionDetails> */}
              {/* <Typography>


  {(props.rent_introduction_point_headers.type===1 || props.rent_introduction_point_headers.type===3)?<FormGroup>
  <FormControlLabel
        control={
            
         <Switch
            checked={props.map_settings_edit===1?true:false}
            onChange={()=>props.change_map_settings_edit(1)}
            value={1}
            color="primary"
          />
        }
        label="移動"
      />

  <FormControlLabel
          control={
              
            <Switch
              checked={props.map_settings_edit===2?true:false}
              onChange={()=>props.change_map_settings_edit(2)}
              value={2}
              color="primary"
            />
          }
          label="編集"
        />
       
      

  </FormGroup>:""}

    </Typography> */}

              {
                //1ポイント 3コメント
                value.type === 1 || value.type === 3 ?


                <div style={{background:"#FFF",padding:"5px", textAlign:"left"}}>
        
        {value.type===1 ? <div>
          <RadioGroup style={{paddingLeft:10}} row aria-label="position" name="point_color" onChange={onChangePoint} value={value.rent_introduction_point_details[0].point_color == null ? "red" : value.rent_introduction_point_details[0].point_color} >
            <FormControlLabel control={<Radio value={"red"} style={{color:"red"}} />} label="" />
            <FormControlLabel control={<Radio value={"blue"} style={{color:"blue"}} />} label="" />
            <FormControlLabel control={<Radio value={"darkorange"} style={{color:"darkorange"}} />} label="" />
          </RadioGroup>
        
        <div>
          <IconButton onClick={()=>onChangeIcon(0)} style={{padding:2}} size="large"><LocationOnIcon style={{color:value.rent_introduction_point_details[0].point_icon == 0 ? value.rent_introduction_point_details[0].point_color == null ? "red" : value.rent_introduction_point_details[0].point_color : ""}} fontSize='large' /></IconButton>
          <IconButton onClick={()=>onChangeIcon(1)} style={{padding:2}} size="large"><ApartmentIcon style={{color:value.rent_introduction_point_details[0].point_icon == 1 ? value.rent_introduction_point_details[0].point_color == null ? "red" : value.rent_introduction_point_details[0].point_color : ""}} fontSize='large' /></IconButton>
          <IconButton onClick={()=>onChangeIcon(2)} style={{padding:2}} size="large"><HomeIcon style={{color:value.rent_introduction_point_details[0].point_icon == 2 ? value.rent_introduction_point_details[0].point_color == null ? "red" : value.rent_introduction_point_details[0].point_color : ""}} fontSize='large' /></IconButton>
          <IconButton onClick={()=>onChangeIcon(3)} style={{padding:2}} size="large"><TrainIcon style={{color:value.rent_introduction_point_details[0].point_icon == 3 ? value.rent_introduction_point_details[0].point_color == null ? "red" : value.rent_introduction_point_details[0].point_color : ""}} fontSize='large' /></IconButton>
          <IconButton onClick={()=>onChangeIcon(4)} style={{padding:2}} size="large"><StoreIcon style={{color:value.rent_introduction_point_details[0].point_icon == 4 ? value.rent_introduction_point_details[0].point_color == null ? "red" : value.rent_introduction_point_details[0].point_color : ""}} fontSize='large' /></IconButton>
        </div>
        </div> : ""}

        {value.type===1 ? 
          <div>
            <FormControlLabel control={<Checkbox style={{marginLeft:10}} checked={value.rent_introduction_point_details[0].distance_is_no_disp == 1} value={1} onChange={onChangePoint} name="distance_is_no_disp" />} label="距離表示しない" />
            <TextField name="contents" size="small" onChange={onChangePoint} value={value.contents} label={"ポイント名"} variant="outlined" fullWidth/>
          </div>:
        
          <TextField name="contents" onChange={onChangePoint} value={value.contents} label={"コメント"} variant="outlined" fullWidth multiline minRows={3} maxRows={3} />}
        
        {value.rent_introduction_point_details[0] ? <TextField size="small" id="outlined-basic" name="link_url" onChange={onChangePoint} value={value.rent_introduction_point_details[0].link_url} label={"リンク先"} variant="outlined" fullWidth style={{marginTop:10}}
        />:""}

   
    </div>


                :""
              }

              {
                // props.rent_introduction_headers.rent_introduction_point_headers.filter(a => a.id === id)[0]
                (value.type === 4 || value.type === 2 || value.type === 5) && props.rent_introduction_point_headers && props.rent_introduction_point_headers.rent_introduction_point_details ? props.rent_introduction_point_headers.rent_introduction_point_details.length > 0 ? 
                <RadioGroup style={{marginLeft:20}} row aria-label="position" name="point_color" onChange={onChange} value={props.rent_introduction_point_headers.rent_introduction_point_details[0].point_color == null ? "red" : props.rent_introduction_point_headers.rent_introduction_point_details[0].point_color} >
                  <FormControlLabel control={<Radio value={"red"} style={{color:"red"}} />} label="" />
                  <FormControlLabel control={<Radio value={"blue"} style={{color:"blue"}} />} label="" />
                  <FormControlLabel control={<Radio value={"darkorange"} style={{color:"darkorange"}} />} label="" />
                  <FormControlLabel control={<Radio value={"green"} style={{color:"green"}} />} label="" />
                  <FormControlLabel control={<Radio value={"hotpink"} style={{color:"hotpink"}} />} label="" />
                </RadioGroup>
                :"":""
              }

              {/* </AccordionDetails> */}
              <Button size="small"
                color="secondary"
                variant="contained"
                type="button"
                style={{ margin: "5px" }}
                onClick={() => props.delete_rent_introduction_point_headers(props.rent_introduction_point_headers.id)}
              >
                削除
              </Button>


              {(props.rent_introduction_point_headers.type === 2 || props.rent_introduction_point_headers.type === 4) ? <Button size="small"
                //  color="secondary"
                variant="contained"
                type="button"
                style={{ margin: "5px" }}
                onClick={clear_rent_introduction_point_details}
              >
                クリア
              </Button> : ""}
              {/* {savealert?<Alert color="danger">
      登録できませんでした。<br/>
        重複している番号があります！
        </Alert>:""} */}
              {/* <Button size="small" 
         color="primary"
         variant="contained"
         type="button"
         style={{float:"right",margin:"5px"}}
         onClick={()=>props.update_rent_introduction_point_headers()}
         >
            保存
          </Button> */}
            </Accordion>
          );

        }) : ""}

      </div>
      <div>

      {props.rent_introduction_headers.id ? <Button size="small"
              color="primary"
              variant="contained"
              type="button"
              style={{ margin: "5px", marginTop:10 }}
              onClick={() => {props.change_rent_introduction_point_template_disp(true);props.change_select_section_id(props.user_details.amSectionId)}}
              startIcon={<AddCircleIcon/>}
            >
              テンプレートを選択・編集
      </Button>:""}

      {props.rent_introduction_headers.id && props.rent_introduction_headers.rent_introduction_point_headers.length > 0 ? <Button size="small"
              color="primary"
              variant="contained"
              type="button"
              style={{ margin: "5px", marginTop:10 }}
              onClick={template_toggle}
              startIcon={<SaveAltIcon/>}
            >
              テンプレートに保存
      </Button>:""}

      <Modal isOpen={props.rent_introduction_point_template_disp} toggle={handleClose} zIndex={3000} size="xxl" fade={false}>
          <ModalHeader>
            テンプレート
            <IconButton
              aria-label="close"
              style={{position:'absolute', right:1, top:1}}
              onClick={handleClose}
              size="large">
              <CloseIcon />
            </IconButton>
          </ModalHeader>

          <ModalBody  >
            <MapMypageTemplateIndex />
          </ModalBody>
          <ModalFooter>
            {props.rent_introduction_point_templates.section_id == props.user_details.amSectionId && props.rent_introduction_point_templates.rent_introduction_point_template_headers ? <Button variant="contained" color="primary" onClick={() => props.update_rent_introduction_point_templates(1)} style={{ backgroundColor: "#5cb85c", marginRight: 5 }}>保存</Button>:""}
            {props.rent_introduction_point_templates.section_id == props.user_details.amSectionId && props.rent_introduction_point_templates.rent_introduction_point_template_headers ? <Button variant="contained" color="secondary" onClick={toggle}>削除</Button> : ""}
          </ModalFooter>
        </Modal>
        
        <Modal isOpen={open} fade={false} zIndex={3001} toggle={toggle} className="modal-dialog-centered">
          <ModalBody>
              <div>本当に削除してよろしいですか？</div>
          </ModalBody>
          <ModalFooter>
          
              <Button variant="contained" color="secondary" onClick={() => {props.delete_rent_introduction_point_templates(props.rent_introduction_point_templates.id);toggle()}}>削除</Button>
              <Button variant="contained" style={{ marginLeft: 3 }} onClick={toggle}>閉じる</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={template_open} fade={false} zIndex={3001} toggle={template_toggle} className="modal-dialog-centered">
          <ModalBody>
              <div>テンプレートとして保存します。</div>
              <div style={{fontSize:11}}>※ポイント等を修正中の場合は、保存してから行ってください。</div>
          </ModalBody>
          <ModalFooter>
          
              <Button variant="contained" color="secondary" onClick={() => {props.insert_rent_introduction_point_template(props.rent_introduction_headers.id);template_toggle()}}>保存</Button>
              <Button variant="contained" style={{ marginLeft: 3 }} onClick={template_toggle}>閉じる</Button>
          </ModalFooter>
        </Modal>

      </div>
    </div>
  );
}
















// class ControlledExpansionPanels extends Component {





//     constructor(props) {
//         super(props);

//         this.state = {
//             isSetRoomName: false,


//         };

//         Bosyuflg = 1;



//         this.handleFormSubmit = this.handleFormSubmit.bind(this);
//         //this.handleClick = this.handleClick.bind(this);
//         this.onChange = this.onChange.bind(this);

//         // this.props.initialize({
//         //     shubetsu_1:true, 
//         //     shubetsu_2:true, 
//         //     shubetsu_7:true,
//         //     shubetsu_21: true,
//         //     kubun_1: true,
//         //     boshuOnly:true});//フォームに初期値を反映させる
//     }




//     handleFormSubmit(event) {
//         event.preventDefault();

//         // form値取得
//         //const params = {
//         //    name1: event.target.name1.value,
//         //    kana1: event.target.kana1.value,
//         //};

//         //alert(JSON.stringify(params, null, '  '));
//     }

//     onChange(e) {
//         console.log(e.target.value);
//         this.setState({ name1: e.target.value });
//     }

//   componentDidMount() {
//     // This method is called when the component is first added to the document
//       this.ensureDataFetched();
//       //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
//   }

//   componentDidUpdate() {
//     // This method is called when the route parameters change
//     this.ensureDataFetched();
//   }

//   ensureDataFetched() {
//       //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
//       //console.log(startDateIndex)

//       //this.props.requestWeatherForecasts(0);

//     }


//     render() {

//         //moment.locale('ja')
//         return (
//             <div style={{backgroundColor:"White",height:"100%", width:250, textAlign:"left", paddingLeft:8}}>

// {/* <ControlledExpansionPanels rent_introduction_headers={this.props.rent_introduction_headers}/> */}





//             </div>
//     );
//   }
// } 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
  return {

    map_settings_flg: state.mapmypage.map_settings_flg,
    map_settings_edit: state.mapmypage.map_settings_edit,
    rent_introduction_headers: state.mapmypage.rent_introduction_headers,
    map_disp: state.mapmypage.map_disp,

    rent_introduction_point_headers: state.mapmypage.rent_introduction_point_headers,
    rent_introduction_point_template_disp: state.mapmypage.rent_introduction_point_template_disp,
    rent_introduction_point_templates: state.mapmypagetemplate.rent_introduction_point_templates,

    user_details: state.masterReducer.user_details,
    
    //firebasetoken: state.firebaseReducer.firebasetoken,
    //firebasedata: state.firebaseReducer.firebasedata,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_map_settings_flg(state) {
      dispatch(MapMypage.change_map_settings_flg(state))
    },
    change_map_settings_edit(state) {
      dispatch(MapMypage.change_map_settings_edit(state))
    },
    insert_rent_introduction_point_headers(state) {
      dispatch(MapMypage.insert_rent_introduction_point_headers(state))
    },
    change_rent_introduction_point_headers(state) {
      dispatch(MapMypage.change_rent_introduction_point_headers(state))
    },
    change_map_disp(state) {
      dispatch(MapMypage.change_map_disp(state))
    },
    // update_rent_introduction_point_headers(state) {
    //   dispatch(MapMypage.update_rent_introduction_point_headers(state))
    // },
    delete_rent_introduction_point_headers(state) {
      dispatch(MapMypage.delete_rent_introduction_point_headers(state))
    },
    insert_rent_introduction_point_template(state) {
      dispatch(MapMypage.insert_rent_introduction_point_template(state))
    },
    change_rent_introduction_point_template_disp(state) {
      dispatch(MapMypage.change_rent_introduction_point_template_disp(state))
    },
    change_select_section_id(state) {
      dispatch(MapMypageTemplate.change_select_section_id(state))
    },
    change_rent_introduction_point_templates(state) {
      dispatch(MapMypageTemplate.change_rent_introduction_point_templates(state))
    },
    update_rent_introduction_point_templates(state) {
      dispatch(MapMypageTemplate.update_rent_introduction_point_templates(state))
    },
    delete_rent_introduction_point_templates(state) {
      dispatch(MapMypageTemplate.delete_rent_introduction_point_templates(state))
    },
    //loadTodos(state) {
    //    dispatch(loadTodos(state))
    //}
  };
}

// App = reduxForm({
//     form: 'mapsearchform', // a unique identifier for this form
//     validate,//バリデーション

//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(App)
// App = withStyles(useStyles)(App);  
export default connect(mapStateToProps, mapDispatchToProps)(App);
