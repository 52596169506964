
const authentication = require('../../../react-azure-adb2c2').default;


export const search_list = (values: any) => {
    // const url = `http://localhost:5000/v1/rent/corporate/offices/search`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/corporate/offices/search`;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}