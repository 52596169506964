import React, { Component } from 'react';

import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';


import { Field } from 'redux-form';
import '../../Css/Table.css';

let hiddenFlg;




export  class CheckboxGroup extends React.Component {

    checkboxGroup() {
        let {label, required, options, input, meta} = this.props;

        return options.map((option, index) => {
            //console.log(input.value)

            return (
            <div className="checkbox eq" key={option.setsubiNo}>
                <label for={"setsubi" + option.setsubiNo}>
                    <input type="checkbox"
                            id={"setsubi" + option.setsubiNo}
                           name={`${input.name}[${option.setsubiNo}]`}
                           value={option.setsubiNo}
                            checked={input.value.indexOf(option.setsubiNo) !== -1}
                           onChange={(event) => {
                            
                               let newValue = [...input.value];
                               if (event.target.checked) {
                                   newValue.push(option.setsubiNo);
                               } else {
                                   //newValue.splice(newValue.indexOf(index+1), 1);
                                    newValue = newValue.filter(function( item ) {
                                        return item !== option.setsubiNo;
                                    });
                               }
                               
                               return input.onChange(newValue);
                           }}/>
                    <span>{option.setsubiName}</span>
                </label>
            </div>)
        });
    }

                                 
    render() {
        return (
            <div>
                {this.checkboxGroup()}
            </div>
            )
    }
}


class SetsubiEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gasu: this.props.responsedetail.gasu,
            denki: this.props.responsedetail.denki,
            ih: this.props.responsedetail.ih,
            gasuka: this.props.responsedetail.gasuka,
            denkika: this.props.responsedetail.denkika,
            ihka: this.props.responsedetail.ihka,
            eakon: this.props.responsedetail.eakon,
        };

        hiddenFlg = this.props.hiddenFlg; //0表示　1非表示

        this.handleFormSubmit = this.handleFormSubmit.bind(this);




        // this.onChange = this.onChange.bind(this);

    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }


    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
        
     
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);
    }

    render() {

       console.log(this.props.setsubi_master)

        const hiddenSetsubi = [];
        const group1 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 1) {
                if(hiddenFlg === 1 && (item.setsubiNo === 61 || item.setsubiNo === 62 || item.setsubiNo === 74 || item.setsubiNo === 75 || item.setsubiNo === 78)){
                    hiddenSetsubi.push(item);
                    return  false;
                }else{
                    return true;
                }
            }
            return  false;
          });
          const group2 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 2) {
                if(hiddenFlg === 1 && (item.setsubiNo === 10 || item.setsubiNo === 13 || item.setsubiNo === 14 || item.setsubiNo === 15 || item.setsubiNo === 130 || item.setsubiNo === 144 || item.setsubiNo === 145)){
                    hiddenSetsubi.push(item);
                    return  false;
                }else{
                    return true;
                }
            }
            return  false;
          });
          const group3 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 3) {
                if(item.setsubiNo !== 24 && item.setsubiNo !== 23 && item.setsubiNo !== 22 && item.setsubiNo !== 95 &&
                    item.setsubiNo !== 27 && item.setsubiNo !== 28 && item.setsubiNo !== 93 && item.setsubiNo !== 122 &&
                    item.setsubiNo !== 29 && item.setsubiNo !== 30 && item.setsubiNo !== 59 && item.setsubiNo !== 92 &&
                    item.setsubiNo !== 26 && item.setsubiNo !== 25 && item.setsubiNo !== 94 &&
                    item.setsubiNo !== 31 && item.setsubiNo !== 32 &&
                    item.setsubiNo !== 123 && item.setsubiNo !== 124){
                    if(hiddenFlg === 1 && (item.setsubiNo === 33 || item.setsubiNo === 34 || item.setsubiNo === 81 || item.setsubiNo === 82)){
                        hiddenSetsubi.push(item);
                        return  false;
                    }else{
                        return true;
                    }
                }else{
                    return  false;
                }
            }
            return  false;
          });
          const group4 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 4) {
                if(hiddenFlg === 1 && (item.setsubiNo === 84 || item.setsubiNo === 98 || item.setsubiNo === 100 || item.setsubiNo === 101 || item.setsubiNo === 139 || item.setsubiNo === 140)){
                    hiddenSetsubi.push(item);
                    return  false;
                }else{
                    return true;
                }
            }
            return false;
          });
          const group5 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 5) {
                if(item.setsubiNo !== 58 && item.setsubiNo !== 7 && item.setsubiNo !== 8 && item.setsubiNo !== 119 && item.setsubiNo !== 151 && item.setsubiNo !== 121 && item.setsubiNo !== 115){
                    if(hiddenFlg === 1 && (item.setsubiNo === 80)){
                        hiddenSetsubi.push(item);
                        return  false;
                    }else{
                        return true;
                    }
                }else{
                    return  false;
                }

            }else{
                return  false;
            }
          });
          const group6 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 6) return true;return false;
          });
          const group8 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 8) {
                if(hiddenFlg === 1 && (item.setsubiNo === 40 || item.setsubiNo === 83 || item.setsubiNo === 116 || item.setsubiNo === 117 || item.setsubiNo === 134 || item.setsubiNo === 137 || item.setsubiNo === 138 || item.setsubiNo === 152)){
                    hiddenSetsubi.push(item);
                    return  false;
                }else{
                    return true;
                }
            }
            return false;
          });
          const group9 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 9) {
                if(hiddenFlg === 1 && (item.setsubiNo === 48 || item.setsubiNo === 55 || item.setsubiNo === 150 || item.setsubiNo === 155 || item.setsubiNo === 156 || item.setsubiNo === 157)){
                    hiddenSetsubi.push(item);
                    return  false;
                }else{
                    return true;
                }
            }
            return false;
          });

          const group10 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 10) {
                if(hiddenFlg === 1 && (item.setsubiNo === 19 || item.setsubiNo === 63 || item.setsubiNo === 64 || item.setsubiNo === 65 || item.setsubiNo === 66 || item.setsubiNo === 67 || item.setsubiNo === 68)){
                    hiddenSetsubi.push(item);
                    return  false;
                }else{
                    return true;
                }
            }
            return false;
          });
          const group11 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 11) {
                if(hiddenFlg === 1 && (item.setsubiNo === 71 || item.setsubiNo === 72 || item.setsubiNo === 73)){
                    hiddenSetsubi.push(item);
                    return  false;
                }else{
                    return true;
                }
            }
            return false;
          });
          const group12 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 12) return true;return false;
          });
          const group13 = this.props.setsubi_master.filter(function(item, index){
            if (item.setsubiGroupNo === 13) return true;return false;
          });
// console.log(group1);
// console.log(hiddenSetsubi);
// alert();
        //moment.locale('ja')
        const { handleSetsubiSubmit } = this.props;
      return (

          <div>
 {/* <form onSubmit={handleSetsubiSubmit}  >    */}
                <button className="btn btn-sm btn-light float-right" style={{ display: (this.props.notclose === 1) ? 'none' : '' }} onClick={this.props.setsubieditchange}>閉じる</button>
            <table>
                <colgroup width='20%' />
                <colgroup width='80%' />
                <tbody>

                    <tr >
                        <th>キッチン</th>
                        <td>
                            <div className="seldiv">
                                <label for="gasu">
                                    <Field name="gasu" id="gasu" component="input" type="checkbox" value="gasu" onClick={()=>this.setState({"gasu" : !this.state.gasu})} />
                                    <span>ガスコンロ付</span>
                                    <Field name="sel_gas"
                                      style={{ width: 40, display: this.state.gasu === true ? "" : "none"}}
                                      className="sellist"
                                      component="select">
                                        <option value="24">1口</option>
                                        <option value="23">2口</option>
                                        <option value="22">3口</option>
                                        <option value="95">口数不明</option>
                                    </Field>
                                </label>
                            </div>
                            <div className="seldiv">
                                <label for="denki">
                                    <Field name="denki" id="denki" component="input" type="checkbox" value="denki" onClick={()=>this.setState({"denki" : !this.state.denki})} />
                                    <span>電気コンロ付</span>
                                    <Field name="sel_denki"
                                      style={{ width: 40, display: this.state.denki === true ? "" : "none"}}
                                      className="sellist"
                                      component="select">
                                        <option value="27">1口</option>
                                        <option value="28">2口</option>
                                        <option value="93">3口</option>
                                        <option value="122">口数不明</option>
                                    </Field>
                                </label>
                            </div>
                            <div className="seldiv">
                                <label for="ih">
                                    <Field name="ih" id="ih" component="input" type="checkbox" value="ih" onClick={()=>this.setState({"ih" : !this.state.ih})} />
                                    <span>IHヒーター付</span>
                                    <Field name="sel_ih"
                                      style={{ width: 40, display: this.state.ih === true ? "" : "none"}}
                                      className="sellist"
                                      component="select">
                                        <option value="29">1口</option>
                                        <option value="30">2口</option>
                                        <option value="59">3口</option>
                                        <option value="92">口数不明</option>
                                    </Field>
                                </label>
                            </div>
                            <div className="seldiv">
                                <label for="gasuka">
                                    <Field name="gasuka" id="gasuka" component="input" type="checkbox" value="gasuka" onClick={()=>this.setState({"gasuka" : !this.state.gasuka})} />
                                    <span>ガスコンロ設置可</span>
                                    <Field name="sel_gaska"
                                      style={{ width: 40, display: this.state.gasuka === true ? "" : "none"}}
                                      className="sellist"
                                      component="select">
                                        <option value="26">1口</option>
                                        <option value="25">2口</option>
                                        <option value="94">口数不明</option>
                                    </Field>
                                </label>
                            </div>
                            <div className="seldiv">
                                <label for="denkika">
                                    <Field name="denkika" id="denkika" component="input" type="checkbox" value="denkika" onClick={()=>this.setState({"denkika" : !this.state.denkika})} />
                                    <span>電気コンロ設置可</span>
                                    <Field name="sel_denkika"
                                      style={{ width: 40, display: this.state.denkika === true ? "" : "none"}}
                                      className="sellist"
                                      component="select">
                                        <option value="31">1口</option>
                                        <option value="32">2口</option>
                                    </Field>
                                </label>
                            </div>
                            <div className="seldiv">
                                <label for="ihka">
                                    <Field name="ihka" id="ihka" component="input" type="checkbox" value="ihka" onClick={()=>this.setState({"ihka" : !this.state.ihka})} />
                                    <span>IH設置可</span>
                                    <Field name="sel_ihka"
                                          style={{ width: 40, display: this.state.ihka === true ? "" : "none"}}
                                          className="sellist"
                                          component="select">
                                        <option value="123">1口</option>
                                        <option value="124">2口</option>
                                    </Field>
                                </label>
                            </div>

                            <div>
                                <Field name="checkGroup" component={CheckboxGroup} options={group3} />
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <th>冷暖房</th>
                        <td>
                            <div className="seldiv">
                                <label for="eakon">
                                    <Field name="eakon" id="eakon" component="input" type="checkbox" value="eakon" onClick={()=>this.setState({"eakon" : !this.state.eakon})} />
                                    <span>エアコン</span>
                                    <Field name="sel_eakon"
                                        style={{ width: 50, display: this.state.eakon === true ? "" : "none"}}
                                        className="sellist"
                                        component="select">
                                        <option value="58">1台付</option>
                                        <option value="7">2台付</option>
                                        <option value="8">3台付</option>
                                        <option value="119">4台付</option>
                                        <option value="151">5台付</option>
                                        <option value="121">6台付</option>
                                        <option value="115">台数不明</option>
                                    </Field>
                                </label>
                            </div>
                            
                            <div>
                                <Field name="checkGroup" component={CheckboxGroup} options={group5} />
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <th>給湯・浴室</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group1} />
                        </td>
                    </tr>
                    <tr >
                        <th>トイレ</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group10} />
                        </td>
                    </tr>
                    <tr >
                        <th>アンテナ・ＴＶ</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group2} />
                        </td>
                    </tr>
                    <tr >
                        <th>洗濯場</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group11} />
                        </td>
                    </tr>
                    <tr >
                        <th>部屋設備</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group4} />
                        </td>
                    </tr>
                    <tr >
                        <th>セキュリティ</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group12} />
                        </td>
                    </tr>
                    <tr >
                        <th>駐車場</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group6} />
                        </td>
                    </tr>
                    <tr >
                        <th>その他</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group8} />
                        </td>
                    </tr>
                    <tr >
                        <th>条件</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group9} />
                        </td>
                    </tr>
                    <tr >
                        <th>特集</th>
                        <td>
                            <Field name="checkGroup" component={CheckboxGroup} options={group13} />
                        </td>
                    </tr>
                </tbody>
              </table>
               {
                    hiddenSetsubi.map((value) =>
                        <Field id={"setsubi" + value.setsubiNo} name={"setsubi[" + value.setsubiNo + "]"} component="input" type="hidden" value={value.setsubiNo} />
                    )
                }
              {this.props.edit===false?"":<button className="btn btn-primary float-right" onClick={this.handleFormSubmit}>保存</button>}
           {/* </form> */}
            </div>
    );
  }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        setsubi_master: state.roomsReducer.setsubi_master,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {


        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

// SetsubiEdit = reduxForm({
//     form: 'roomform', // a unique identifier for this form
//     validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(SetsubiEdit)

export default connect(mapStateToProps, mapDispatchToProps)(SetsubiEdit);