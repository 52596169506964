import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import IndexComponent from '../Task/IndexComponent';


// import { customerList,  customerNewDispSet } from '../../Store/Customers';
import * as DashBoard from './Saga/DashBoard';
const Scrollbars = require('react-custom-scrollbars');
//const authentication = require('react-azure-adb2c').default;
interface Props {

    change_sales_sheet_form: any,
    sales_sheet_form: any,
    section_select: any,
    // customerNewDispSet: any,
    // customerNewDisp:number,
    // customerno:number,
    // customerList:any,
    // toggle:any,
    // get_rent_contract_accounting: any,
    // banks: any,
    // get_banks: any
}



interface State {
    reflash: boolean
    // state types
}

class Sales extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            // setintend: this.props.contactstate.intend === 0 || this.props.contactstate.intend === false ? false : true,
            reflash: false,
        };

        // this.setTenpo = this.setTenpo.bind(this);
        // this.setStart_date = this.setStart_date.bind(this);
        // this.setEnd_date = this.setEnd_date.bind(this);


    }

    componentWillMount() {

    }



    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <div style={{ margin: 0 }}>

                    <Card >
                        <IndexComponent />
                    </Card>
            </div >
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        sales_sheet_form: state.dashboard.sales_sheet_form,
        section_select: state.masterReducer.section_select,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        change_sales_sheet_form(state: any) {
            dispatch(DashBoard.change_sales_sheet_form(state))
        },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sales);




