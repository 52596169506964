import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';

import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import Button from '@mui/material/Button';
import "react-input-range/lib/css/index.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import IconButton from '@mui/material/IconButton';
const moment = require('moment-timezone');
class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.numPages = this.numPages.bind(this);
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }

  open_room_detail_window(building_id, room_id, counting) {
    if(counting == -1 || counting == 2){//駐車場
      window.open(process.env.REACT_APP_HOMESTATION4_URL+`/manage/parking/detail/index?p_id=` + building_id, '_blank', 'noopener')
    }else{
      window.open(process.env.REACT_APP_HOMESTATION4_URL+`/manage/room/detail/index?t_id=` + building_id + `&r_id=` + room_id, '_blank', 'noopener')
    }
    
  }

  render() {
    let tatemono_name = ""


    if (
      (this.props.rent_contract_details_new.tatemono_name ? this.props.rent_contract_details_new.tatemono_name : "") + 
      (this.props.rent_contract_details_new.room_name ? this.props.rent_contract_details_new.room_name : "") + 
      (this.props.rent_contract_details_new.room_name2 ? ","+this.props.rent_contract_details_new.room_name2 : "") + 
      (this.props.rent_contract_details_new.room_name3 ? ","+this.props.rent_contract_details_new.room_name3 : "") !== 
      (this.props.rent_contract_details_old.tatemono_name ? this.props.rent_contract_details_old.tatemono_name : "") + 
      (this.props.rent_contract_details_old.room_name ? this.props.rent_contract_details_old.room_name : "") + 
      (this.props.rent_contract_details_old.room_name2 ? "," + this.props.rent_contract_details_old.room_name2 : "") + 
      (this.props.rent_contract_details_old.room_name3 ? "," + this.props.rent_contract_details_old.room_name3 : "")
      ) {
      tatemono_name = <span>
        <div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.tatemono_name + " " + this.props.rent_contract_details_old.room_name + (this.props.rent_contract_details_old.room_name2 ?  ", " + this.props.rent_contract_details_old.room_name2 : "")　+ (this.props.rent_contract_details_old.room_name3 ? ", " + this.props.rent_contract_details_old.room_name3 : "")} <IconButton style={{marginLeft:10, padding:5, color:'#17a2b8'}} size="large"><HomeWorkIcon size="small" onClick={()=>this.open_room_detail_window(this.props.rent_contract_details_old.tatemono_id, this.props.rent_contract_details_old.room_id, this.props.rent_contract_details_old.counting)}  /></IconButton></div>
          <ArrowDropDownIcon /> 
        <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.tatemono_name + " " + this.props.rent_contract_details_new.room_name + (this.props.rent_contract_details_new.room_name2 ?  ", " + this.props.rent_contract_details_new.room_name2 : "")　+ (this.props.rent_contract_details_new.room_name3 ? ", " + this.props.rent_contract_details_new.room_name3 : "")} <IconButton style={{marginLeft:10, padding:5, color:'#17a2b8'}} size="large"><HomeWorkIcon size="small" onClick={()=>this.open_room_detail_window(this.props.rent_contract_details_new.tatemono_id, this.props.rent_contract_details_new.room_id, this.props.rent_contract_details_new.counting)}  /></IconButton></div>
      </span>
    } else {
      tatemono_name = <span>{(this.props.rent_contract_details_new.tatemono_name ? this.props.rent_contract_details_new.tatemono_name : "") + " " + (this.props.rent_contract_details_new.room_name ? this.props.rent_contract_details_new.room_name : "") + (this.props.rent_contract_details_new.room_name2 ?  ", " + this.props.rent_contract_details_new.room_name2 : "")　+ (this.props.rent_contract_details_new.room_name3 ? ", " + this.props.rent_contract_details_new.room_name3 : "")} <IconButton style={{marginLeft:10, padding:5, color:'#17a2b8'}} size="large"><HomeWorkIcon size="small" onClick={()=>this.open_room_detail_window(this.props.rent_contract_details_new.tatemono_id, this.props.rent_contract_details_new.room_id, this.props.rent_contract_details_new.counting)}  /></IconButton></span>
    }

    let contract_date = ""

    if (this.props.rent_contract_details_new.contract_date !== this.props.rent_contract_details_old.contract_date) {
      contract_date = <span><div style={{ color: "red", fontWeight: "bold" }}>{moment(this.props.rent_contract_details_old.contract_date).format("YYYY年M月D日")}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{moment(this.props.rent_contract_details_new.contract_date).format("YYYY年M月D日")}</div></span>

    } else {
      contract_date = moment(this.props.rent_contract_details_new.contract_date).format("YYYY年M月D日")
    }
    let staff_name = ""

    if (this.props.rent_contract_details_new.staff_name !== this.props.rent_contract_details_old.staff_name) {
      staff_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.staff_name}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.staff_name}</div></span>

    } else {
      staff_name = this.props.rent_contract_details_new.staff_name
    }



    let rent_m_contract_type_name = ""

    if (this.props.rent_contract_details_new.rent_m_contract_type_name !== this.props.rent_contract_details_old.rent_m_contract_type_name) {
      rent_m_contract_type_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.rent_m_contract_type_name}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.rent_m_contract_type_name}</div></span>

    } else {
      rent_m_contract_type_name = this.props.rent_contract_details_new.rent_m_contract_type_name
    }

    let trader_name = ""
    let trader_name_new = this.props.rent_contract_details_new.traders === 7 ? this.props.rent_contract_details_new.traders_etc : this.props.rent_contract_details_new.trader_name
    let trader_name_old = this.props.rent_contract_details_old.traders === 7 ? this.props.rent_contract_details_old.traders_etc : this.props.rent_contract_details_old.trader_name
    if (trader_name_new !== trader_name_old) {
      trader_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{trader_name_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{trader_name_new}</div></span>

    } else {
      trader_name = trader_name_new
    }


    let division_name = ""
    let division_new = this.props.rent_contract_details_new.division === 1 ? "サブリース" : this.props.rent_contract_details_new.division === 2 ? "管理" : this.props.rent_contract_details_new.division === 3 ? "一般" : this.props.rent_contract_details_new.division === 4 ? "業物" : this.props.rent_contract_details_new.division === 5 ? "業物折半" : this.props.rent_contract_details_new.division === 6 ? "専任" : ""
    let division_old = this.props.rent_contract_details_old.division === 1 ? "サブリース" : this.props.rent_contract_details_old.division === 2 ? "管理" : this.props.rent_contract_details_old.division === 3 ? "一般" : this.props.rent_contract_details_old.division === 4 ? "業物" : this.props.rent_contract_details_old.division === 5 ? "業物折半" : this.props.rent_contract_details_old.division === 6 ? "専任" : ""
    if (division_new !== division_old) {
      division_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{division_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{division_new}</div></span>

    } else {
      division_name = division_new
    }

    let kanri_corp_name = ""
    if (this.props.rent_contract_details_new.kanri_corp_name !== this.props.rent_contract_details_old.kanri_corp_name) {
      kanri_corp_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.kanri_corp_name}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.kanri_corp_name}</div></span>

    } else {
      kanri_corp_name = this.props.rent_contract_details_new.kanri_corp_name
    }
    let pets_name = ""
    let pets_name_old = this.props.rent_contract_details_old.pets ? "有 " + this.props.rent_contract_details_old.pet_type : "無"
    let pets_name_new = this.props.rent_contract_details_new.pets ? "有 " + this.props.rent_contract_details_new.pet_type : "無"
    if (pets_name_old !== pets_name_new) {
      pets_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{pets_name_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{pets_name_new}</div></span>

    } else {
      pets_name = pets_name_new
    }

    let cosigner_name = ""
    let cosigner_name_old = this.props.rent_contract_details_old.cosigner === 1 ? "連帯保証人1人" : this.props.rent_contract_details_old.cosigner === 2 ? "連帯保証人2人" : this.props.rent_contract_details_old.cosigner === 3 ? "緊急連絡先" : "無"
    let cosigner_name_new = this.props.rent_contract_details_new.cosigner === 1 ? "連帯保証人1人" : this.props.rent_contract_details_new.cosigner === 2 ? "連帯保証人2人" : this.props.rent_contract_details_new.cosigner === 3 ? "緊急連絡先" : "無"
    if (cosigner_name_old !== cosigner_name_new) {
      cosigner_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{cosigner_name_old}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{cosigner_name_new}</div></span>

    } else {
      cosigner_name = cosigner_name_new
    }

    let number_of_renters = ""
    if (this.props.rent_contract_details_old.number_of_renters !== this.props.rent_contract_details_new.number_of_renters) {
      number_of_renters = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.number_of_renters > 0 ? this.props.rent_contract_details_old.number_of_renters + "人" : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.number_of_renters > 0 ? this.props.rent_contract_details_new.number_of_renters + "人" : "-"}</div></span>

    } else {
      number_of_renters = this.props.rent_contract_details_new.number_of_renters > 0 ? this.props.rent_contract_details_new.number_of_renters + "人" : "-"
    }



    let number_of_cars = ""
    if (this.props.rent_contract_details_old.number_of_cars !== this.props.rent_contract_details_new.number_of_cars) {
      number_of_cars = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.number_of_cars === 0 ? "無" : this.props.rent_contract_details_old.number_of_cars > 0 ? this.props.rent_contract_details_old.number_of_cars + "台" : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_old.number_of_cars === 0 ? "無" : this.props.rent_contract_details_new.number_of_cars > 0 ? this.props.rent_contract_details_new.number_of_cars + "台" : "-"}</div></span>

    } else {
      number_of_cars = this.props.rent_contract_details_old.number_of_cars === 0 ? "無" : this.props.rent_contract_details_new.number_of_cars > 0 ? this.props.rent_contract_details_new.number_of_cars + "台" : "-"

    }

    let rent_m_guarantee_name = ""
    if (this.props.rent_contract_details_old.rent_m_guarantee_name !== this.props.rent_contract_details_new.rent_m_guarantee_name) {
      rent_m_guarantee_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.rent_m_guarantee_name}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.rent_m_guarantee_name}</div></span>

    } else {
      rent_m_guarantee_name = this.props.rent_contract_details_new.rent_m_guarantee_name

    }
    let teikishakka = ""
    if (("," + this.props.rent_contract_details_new.room_setsubi + ",").indexOf(',55,') !== -1) {
      teikishakka = "(定期借家)"
    }

    return (

      <div>
        <table className='basicList'>
          <tr>
            <th>建物名</th>
            <td colSpan="5" >
              <b>{tatemono_name}</b>
              {(this.props.user_details.amSectionId==10 || this.props.rent_contract_details.workflow_id==0 || this.props.rent_contract_details.parking_application_id>0)? <Button size="small" style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.props.check_rent_contract_editor_disp({ editor: "rent_contract_editor_disp", disp: true })}>
                編集
              </Button>:<i style={{ float: "right" }}>作成依頼済みのためHS4から編集してください。</i>} 
              {/* <button className="btn btn-sm btn-success float-right" style={{ display: (this.props.notedit === 1) ? 'none' : '' }} onClick={() => this.props.change_contract_detail_edit_disp(true)}>編集</button>*/}
            

              <h5 style={{ display: "inline"}}><span className="badge badge-warning" style={{ float: "right", marginRight:20, marginTop:5 }}>{this.props.rent_contract_details_new.is_important_contract ? "重要契約":""}</span></h5>
              
            </td>  </tr>
          <tr>
            <th>管理区分</th>
            <td colSpan="2">{division_name}</td>
            <th >管理会社</th>
            <td colSpan="2">{kanri_corp_name}</td>
          </tr>
          <tr>
            <th >契約日</th>
            <td colSpan="2">{contract_date}</td>
            <th>担当</th>
            <td colSpan="2">{staff_name}</td>
          </tr>
          <tr>
            <th>契約内容</th>
            <td colSpan="2">{rent_m_contract_type_name}{teikishakka}</td>
            <th>保証会社</th>
            <td colSpan="2">{rent_m_guarantee_name}</td>
          </tr>

          <tr>
            <th>ペット</th>
            <td colSpan="2">{pets_name}</td>
            <th>客付</th>
            <td colSpan="2">{trader_name}</td>
          </tr>
          <tr>
            <th>保証人</th>
            <td>{cosigner_name}</td>
            <th>入居人数</th>
            <td>{number_of_renters}</td>
            <th>駐車台数</th>
            <td>{number_of_cars}</td>
          </tr>

        </table>
      </div>

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    user_details: state.masterReducer.user_details,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Contracts.change_contract_detail_edit_disp(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
