import React, { Component } from 'react';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import '../../Css/Table.css';

let count = 0;

const setsubiGroup = [

    { id: 1, value: "給湯・浴室" }
    , { id: 2, value: "アンテナ・ＴＶ" }
    , { id: 3, value: "キッチン" }
    , { id: 4, value: "部屋設備" }
    , { id: 5, value: "冷暖房" }
    , { id: 6, value: "駐車場" }



    , { id: 10, value: "トイレ" }
    , { id: 11, value: "洗濯場" }
    , { id: 12, value: "セキュリティ" }
    , { id: 8, value: "その他" }
    , { id: 9, value: "条件" }
    , { id: 13, value: "特集" }
];


class SetsubiEditComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerinfo: this.props.forecasts,
            startDate: new Date()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }




    render() {

        let list = []
        let stsubi = ""
        let stsubilist = []
        let title = ""



        if (this.props.room_setsubi) {
            for (let setsubikey in setsubiGroup) {
                stsubilist = []

                title = <th rowSpan={Math.ceil(this.props.room_setsubi.filter(a => a.setsubi_group_no === setsubiGroup[setsubikey].id).length / 2)}>{setsubiGroup[setsubikey].value}</th>
                for (let step = 0; step < Math.ceil(this.props.room_setsubi.filter(a => a.setsubi_group_no === setsubiGroup[setsubikey].id).length / 2); step++) {
                    stsubi = this.props.room_setsubi.filter(a => a.setsubi_group_no === setsubiGroup[setsubikey].id).slice(step * 2, (step + 1) * 2).map(function (x, key) {
                        return <td key={key}>{x.setsubi_name}</td>
                    })
                    stsubi = step === 0 ? <tr>{title}{stsubi}{stsubi.length === 1 ? <td></td> : ""}</tr> : <tr>{stsubi}{stsubi.length === 1 ? <td></td> : ""}</tr>
                    stsubilist.push(stsubi)
                }
                list.push(stsubilist)


            }


        }
        return (
            <div>
                {/* <button className="btn btn-sm btn-success float-right" style={{ display: (this.props.notedit === 1) ? 'none' : '' }} onClick={this.props.setsubieditchange}>編集</button> */}
                <table className="disp_table">
                    <colgroup width='33%' />
                    <colgroup width='33%' />
                    <colgroup width='34%' />
                    <tbody><tr>
                        <th colSpan={3}>設備</th>
                    </tr>
                        {list}
                    </tbody>
                </table>

            </div>

        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        room_details: state.roomsReducer.room_details,
        room_setsubi: state.roomsReducer.room_setsubi,
        // setsubi_master: state.roomsReducer.setsubi_master,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {


        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

SetsubiEditComponent = reduxForm({
    form: 'roomform', // a unique identifier for this form
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(SetsubiEditComponent)

export default connect(mapStateToProps, mapDispatchToProps)(SetsubiEditComponent);