import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
// import { makeStyles } from '@mui/material/styles';
import * as IntroductionListEdit from '../Saga/MapMypage';
// import RoomDocComponent from '../Rooms/RoomDocComponent';
// import * as Responses from '../../Store/Responses';
// import '../../Css/GridRooms.css';
// import LoadingComponent from '../Layout/LoadingComponent';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';
import Alert from '@mui/material/Alert';
// import SelectList from './SelectList';
// import Button from '@mui/material/Button';



const link = (str) => {
  const regexp_url = /https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+/g;
  const regexp_makeLink = (url) => {
    return `<a href=${url} target="_blank" rel="noopener noreferrer">${url}</a>`;
  }
  const replacedString = str.replace(/\r?\n/g, '<br />').replace(regexp_url, regexp_makeLink);



  return (
    <p dangerouslySetInnerHTML={{__html: replacedString}}></p>
  )
};




class App extends Component {
  constructor(props) {
    super(props);
    this.state = {


      remarks: ""


    }

    this.change_comment = this.change_comment.bind(this);
    this.update_comment_set = this.update_comment_set.bind(this);

  }

  componentWillReceiveProps() {
    this.setState({
      remarks: this.props.rent_introduction_details.remarks
    })
    //this.props.dispatchRequest()
  }


  change_comment(e) {
    console.log(e.target.value)
    this.setState({ remarks: e.target.value })

  }

  update_comment_set(e) {

    // this.setState({ remarks: e.target.value })
    this.props.update_comment({ id: this.props.rent_introduction_details.id, remarks: this.state.remarks })
  }


  render() {

    return (
      <div>


        {this.props.remarks_id === this.props.rent_introduction_details.id ? <div><TextField
          id="outlined-multiline-static"
          label="スタッフコメント"
          multiline
          rows={4}
          value={this.state.remarks}
          variant="outlined"
          onChange={this.change_comment}
        />     <Button size="small"
          color="primary"
          variant="contained"
          type="button"

          onClick={() => this.update_comment_set()}
        >
            保存
        </Button></div> : this.props.rent_introduction_details.remarks ? <Alert severity="info" style={{ textAlign: "left", width: "300px" }} icon={<ChatIcon fontSize="inherit" />} >{link(this.props.rent_introduction_details.remarks)}</Alert> : ""}
      </div>


    );
  }


}



const mapStateToProps = (state) => {
  return {
    rooms: state.roomsReducer.rooms,
    introductions: state.introductionlist.introductions,
    rent_introduction_headers_list: state.mapmypage.rent_introduction_headers_list,
    rent_introduction_headers: state.mapmypage.rent_introduction_headers,
    // responseid: state.customersReducer.responseid,
    // response_rooms: state.roomsReducer.response_rooms,
    contacts_loading: state.customerContactsReducer.contacts_loading,
    remarks_id: state.mapmypage.remarks_id,


  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_rent_introduction_headers(state) {
      dispatch(IntroductionListEdit.change_rent_introduction_headers(state))
    },
    change_remarks_id(state) {
      dispatch(IntroductionListEdit.change_remarks_id(state))
    },
    update_comment(state) {
      dispatch(IntroductionListEdit.update_comment(state))
    },
    // delete_introduction(state:any) {
    //     dispatch(IntroductionList.delete_introduction(state))
    // },
    // update_introductions(state:any) {
    //     dispatch(IntroductionList.update_introductions(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);