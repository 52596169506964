import React, { Component } from 'react';

import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';

//import { actionCreators } from '../../Store/WeatherForecasts';

import { Field, reduxForm } from 'redux-form';
// import { Button, Modal } from 'reactstrap';
import '../../Css/Table.css';
import * as AutoKana from 'vanilla-autokana';
let autokana1;
let Bosyuflg;



// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (!values.tatemonoName) {
        errors.tatemonoName = "建物名を入力してください。";
    }


    if (Bosyuflg === 1) {//募集
        if (!values.tatemonoKana) {
            errors.tatemonoKana = "カナを入力してください。";
        }
        if (!values.zip1) {
            errors.zip1 = "入力してください。";
        }
        if (!values.zip2) {
            errors.zip1 = "入力してください。";
        }
        if (!values.todoufuken) {
            errors.otherAddress = "住所を入力してください。";
        }
        if (!values.shikuchoson) {
            errors.otherAddress = "住所を入力してください。";
        }
        if (!values.choiki) {
            errors.otherAddress = "住所を入力してください。";
        }
        if (!values.otherAddress) {
            errors.otherAddress = "住所を入力してください。";
        }
        if (values.tatemonoShubetsuNo <= 0) {
            errors.tatemonoShubetsuNo = "建物種別を入力してください。";
        }
        if (values.kouzouNo <= 0) {
            errors.kouzouNo = "構造を入力してください。";
        }
        if (!values.shunkouYear) {
            errors.shunkouYear = "竣工日を入力してください。";
        }
        if (!values.shunkouMonth) {
            errors.shunkouYear = "竣工日を入力してください。";
        }
        if (!values.soukosuu) {
            errors.soukosuu = "総戸数を入力してください。";
        }
        if (!values.kaidateChijou) {
            errors.kaidateChika = "階建を入力してください。";
        }
        if (!values.kaidateChika) {
            errors.kaidateChika = "階建を入力してください。";
        }
        if (values.kanriCorpNo <= 0) {
            errors.kanriCorpNo = "管理会社を入力してください。";
        }
        if (values.shop <= 0) {
            errors.shop = "担当店舗を入力してください。";
        }
        if (values.area <= 0) {
            errors.area = "エリアを入力してください。";
        }

    }

    return errors;
};

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, style, meta: { touched, error } }) => (
//     <div >
//         <input {...input} type={type} id={id} placeholder={placeholder} style={{ width: "80" }} className={touched && error ? 'alert-danger ' + className : className} />
//         {touched && error && <span className="text-danger">{error}</span>}
//     </div>
// );


export class RenderField extends React.Component {
    render() {
        const { className, id, name, type, style, input, disabled, onBlur, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};



class TatemonoEditComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSetSoukosuu: this.props.roomdetail.soukosuu > 0 ? false : true,
            isSetKaidateChijou: this.props.roomdetail.kaidateChijou !== null ? false : true,
            isSetKaidateChika: this.props.roomdetail.kaidateChika !== null ? false : true,
            isSetKanriCorpNo: this.props.roomdetail.kanriCorpNo > 0 ? false : true,
            isSetShunkouYear: this.props.roomdetail.shunkouYear !== null ? false : true,
            isSetShunkouMonth: this.props.roomdetail.shunkouMonth !== null ? false : true,
            isSetShunkouDay: this.props.roomdetail.shunkouDay !== null ? false : true,
            isSetTodoufuken: this.props.roomdetail.todoufuken !== null ? false : true,
            isSetShikuchoson: this.props.roomdetail.shikuchoson !== null ? false : true,
            isSetChoiki: this.props.roomdetail.choiki !== null ? false : true,
            isSetOtherAddress: this.props.roomdetail.otherAddress !== null ? false : true,
            isSetTatemonoShubetsuNo: this.props.roomdetail.tatemonoShubetsuNo > 0 ? false : true,
            isSetKouzouNo: this.props.roomdetail.kouzouNo > 0 ? false : true,
            isSetZip1: this.props.roomdetail.zip1 !== null ? false : true,
            isSetZip2: this.props.roomdetail.zip2 !== null ? false : true,
            isSetTatemonoName: this.props.roomdetail.tatemonoName !== null ? false : true,
            isSetTatemonoKana: this.props.roomdetail.tatemonoKana !== null ? false : true,
            isSetArea: this.props.roomdetail.area > 0 ? false : true,
            isSetShop: this.props.roomdetail.shop > 0 ? false : true,

        };

        Bosyuflg = 1;

        this.valueCheck = this.valueCheck.bind(this);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.props.initialize(this.props.roomdetail);//フォームに初期値を反映させる
    }

    //valueCheck(property, event) {
    valueCheck(event) {

        //this.setState({isSet: !!event.target.value});
        //this.setState({ [property]: !!event.target.value });

        // ネストされたオブジェクトのdataまでアクセスしておく
        //var data = this.state.data;

        // eventが発火したname属性名ごとに値を処理
        switch (event.target.name) {
            case 'isSetTatemonoName':
                this.setState({ isSetTatemonoName: !!event.target.value });
                //data.isSetTatemonoName = !!event.target.value;
                break;
            case 'isSetSoukosuu':
                this.setState({ isSetSoukosuu: !!event.target.value });
                //data.isSetSoukosuu = !!event.target.value;
                break;
        }

        // // 状態を更新
        // this.setState({
        //     data: data
        // });


    }


    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
        autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }


    onChangeName(property, event) {

        if (property === "tatemonoKana") {
            this.props.change(property, autokana1.getFurigana())
        } else if (property === "kana2") {

        }


    }

    render() {

        const year = [""];
        const day = [""];

        var hiduke = new Date();
        for (var i = 1926; i <= hiduke.getFullYear(); i++) {
            year.push(i);
        }
        for (var i2 = 1; i2 <= 31; i2++) {
            day.push(i2);
        }

        //moment.locale('ja')
        return (
            <div>
                <button className="btn btn-sm btn-light float-right" style={{ display: (this.props.notclose === 1) ? 'none' : '' }} onClick={this.props.tatemonoeditchange}>閉じる</button>
                <table>
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <colgroup width='20%' />
                    <colgroup width='30%' />

                    <tbody>
                        <tr >
                            <th>建物名</th>
                            <td colSpan="3">
                                <Field
                                    name="tatemonoName"
                                    id="tatemonoName"
                                    type="text"
                                    onChange={this.onChangeName.bind(this, "tatemonoKana")}
                                    placeholder=""
                                    className={this.state.isSetTatemonoName ? "alert-danger form-control" : "form-control"}
                                    onBlur={e => this.setState({ "isSetTatemonoName": !e.target.value })}
                                    component={RenderField}
                                />
                            </td>
                        </tr>
                        <tr >
                            <th>建物カナ</th>
                            <td colSpan="3">
                                <Field
                                    name="tatemonoKana"
                                    id="tatemonoKana"
                                    type="text"
                                    placeholder=""
                                    className={this.state.isSetTatemonoKana ? "alert-danger form-control" : "form-control"}
                                    onBlur={e => this.setState({ "isSetTatemonoKana": !e.target.value })}
                                    component={RenderField}
                                />
                            </td>
                        </tr>
                        <tr >
                            <th>郵便番号</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="zip1"
                                        id="zip1"
                                        type="text"
                                        placeholder=""
                                        component={RenderField}
                                        className={this.state.isSetZip1 ? "alert-danger form-control" : "form-control"}
                                        onBlur={e => this.setState({ "isSetZip1": !e.target.value })}
                                        style={{ width: 120 }}
                                    />-
                            <Field
                                        name="zip2"
                                        id="zip2"
                                        type="text"
                                        placeholder=""
                                        component={RenderField}
                                        className={this.state.isSetZip2 ? "alert-danger form-control" : "form-control"}
                                        onBlur={e => this.setState({ "isSetZip2": !e.target.value })}
                                        style={{ width: 120 }}
                                    />
                                    <button class="btn zip_btn" type="button" onclick="">住所検索</button>
                                </div>
                            </td>
                        </tr>
                        <tr >
                            <th>住所</th>
                            <td colSpan="3">
                                <Field
                                    name="todoufuken"
                                    id="todoufuken"
                                    placeholder=""
                                    component={RenderField}
                                    className={this.state.isSetTodoufuken ? "alert-danger form-control" : "form-control"}
                                    onBlur={e => this.setState({ "isSetTodoufuken": !e.target.value })}
                                    type="select"
                                >
                                    <option value="">-</option>
                                </Field>
                                <Field
                                    name="shikuchoson"
                                    id="shikuchoson"
                                    placeholder=""
                                    component={RenderField}
                                    className={this.state.isSetShikuchoson ? "alert-danger form-control" : "form-control"}
                                    onBlur={e => this.setState({ "isSetShikuchoson": !e.target.value })}
                                    type="select"
                                >
                                    <option value="">-</option>
                                </Field>
                                <Field
                                    name="choiki"
                                    id="choiki"
                                    placeholder=""
                                    component={RenderField}
                                    className={this.state.isSetChoiki ? "alert-danger form-control" : "form-control"}
                                    onBlur={e => this.setState({ "isSetChoiki": !e.target.value })}
                                    type="select"
                                >
                                    <option value="">-</option>
                                </Field>
                                <Field
                                    name="otherAddress"
                                    id="otherAddress"
                                    type="text"
                                    placeholder=""
                                    component={RenderField}
                                    className={this.state.isSetOtherAddress ? "alert-danger form-control" : "form-control"}
                                    onBlur={e => this.setState({ "isSetOtherAddress": !e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr >
                            <th>建物種別</th>
                            <td>
                                <Field
                                    name="tatemonoShubetsuNo"
                                    id="tatemonoShubetsuNo"
                                    placeholder=""
                                    component={RenderField}
                                    className={this.state.isSetTatemonoShubetsuNo ? "alert-danger form-control" : "form-control"}
                                    onBlur={e => this.setState({ "isSetTatemonoShubetsuNo": e.target.value > 0 ? false : true })}
                                    type="select"
                                >
                                    <option value="0">-</option>
                                </Field>
                            </td>
                            <th>構造</th>
                            <td>
                                <Field
                                    name="kouzouNo"
                                    id="kouzouNo"
                                    placeholder=""
                                    component={RenderField}
                                    className={this.state.isSetKouzouNo ? "alert-danger form-control" : "form-control"}
                                    onBlur={e => this.setState({ "isSetKouzouNo": e.target.value > 0 ? false : true })}
                                    type="select"
                                >
                                    <option value="0">-</option>
                                </Field>
                            </td>
                        </tr>
                        <tr >
                            <th>建物面積</th>
                            <td >
                                <div className="form-inline">
                                    <Field
                                        name="tatemonoMenseki"
                                        id="tatemonoMenseki"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 80 }}
                                    />㎡
                            </div>
                            </td>
                            <th>延床面積</th>
                            <td>
                                <div className="form-inline">
                                    <Field
                                        name="nobeyukaMenseki"
                                        id="kouzouNo"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 80 }}
                                    />㎡
                            </div>
                            </td>
                        </tr>
                        <tr >
                            <th>竣工日</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="shunkouYear"
                                        id="shunkouYear"
                                        placeholder=""
                                        component={RenderField}
                                        className={this.state.isSetShunkouYear ? "alert-danger form-control" : "form-control"}
                                        onBlur={e => this.setState({ "isSetShunkouYear": e.target.value > 0 ? false : true })}
                                        type="select"
                                        style={{ width: 100 }}
                                    >
                                        {year.map((value) => <option value={value}>{value}</option>)}
                                    </Field>年
                            <Field
                                        name="shunkouMonth"
                                        id="shunkouMonth"
                                        placeholder=""
                                        component={RenderField}
                                        className={this.state.isSetShunkouMonth ? "alert-danger form-control" : "form-control"}
                                        onBlur={e => this.setState({ "isSetShunkouMonth": e.target.value > 0 ? false : true })}
                                        type="select"
                                        style={{ width: 80 }}
                                    >
                                        {["", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value) => <option value={value}>{value}</option>)}
                                    </Field>月
                            <Field
                                        name="shunkouDay"
                                        id="shunkouDay"
                                        type="text"
                                        placeholder=""
                                        className={"form-control"}
                                        component="select"
                                        style={{ width: 80 }}
                                    >
                                        {day.map((value) => <option value={value}>{value}</option>)}
                                    </Field>日
                            </div>
                            </td>
                        </tr>
                        <tr >
                            <th>総戸数</th>
                            <td>
                                <div className="form-inline">
                                    <Field
                                        name="soukosuu"
                                        id="soukosuu"
                                        type="number"
                                        placeholder=""
                                        component={RenderField}
                                        style={{ width: 80 }}
                                        className={this.state.isSetSoukosuu ? "alert-danger form-control" : "form-control"}
                                        onBlur={e => this.setState({ "isSetSoukosuu": !e.target.value })}
                                    //onBlur={e => alert(e.target.value)}
                                    // onBlur={this.valueCheck}
                                    //onBlur={this.valueCheck}
                                    //onBlur={this.valueCheck.bind(this, "isSetSoukosuu")}
                                    />戸
                            </div>
                            </td>
                            <th>階建</th>
                            <td>
                                <div className="form-inline">
                                    地上：<Field
                                        name="kaidateChijou"
                                        id="kaidateChijou"
                                        type="number"
                                        placeholder=""
                                        component={RenderField}
                                        className={this.state.isSetKaidateChijou ? "alert-danger form-control" : "form-control"}
                                        onBlur={e => this.setState({ "isSetKaidateChijou": !e.target.value })}
                                        style={{ width: 60 }}
                                    />階

                            地下：<Field
                                        name="kaidateChika"
                                        id="kaidateChika"
                                        type="number"
                                        placeholder=""
                                        component={RenderField}
                                        className={this.state.isSetKaidateChika ? "alert-danger form-control" : "form-control"}
                                        onBlur={e => this.setState({ "isSetKaidateChika": !e.target.value })}
                                        style={{ width: 60 }}
                                    />階
                            </div>
                            </td>
                        </tr>
                        <tr >
                            <th>管理会社</th>
                            <td colSpan="3">
                                <Field
                                    name="kanriCorpNo"
                                    id="kanriCorpNo"
                                    placeholder=""
                                    component={RenderField}
                                    className={this.state.isSetKanriCorpNo ? "alert-danger form-control" : "form-control"}
                                    onBlur={e => this.setState({ "isSetKanriCorpNo": e.target.value > 0 ? false : true })}
                                    type="select"

                                >
                                    <option value="-1">-</option>
                                </Field>
                            </td>
                        </tr>









                        <tr >
                            <th>交通1</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="koutsuu1"
                                        id="koutsuu1"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 130 }}
                                    />から徒歩約
                            <Field
                                        name="koutsuu1Min"
                                        id="koutsuu1Min"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 80 }}
                                    />分以内
                            </div>
                            </td>
                        </tr>
                        <tr >
                            <th>交通2</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="koutsuu2"
                                        id="koutsuu2"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 130 }}
                                    />から徒歩約
                            <Field
                                        name="koutsuu2Min"
                                        id="koutsuu2Min"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 80 }}
                                    />分以内
                            </div>
                            </td>
                        </tr>
                        <tr >
                            <th>交通3</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="koutsuu3"
                                        id="koutsuu3"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 130 }}
                                    />から徒歩約
                            <Field
                                        name="koutsuu3Min"
                                        id="koutsuu3Min"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 80 }}
                                    />分以内
                            </div>
                            </td>
                        </tr>
                        <tr >
                            <th>小学校</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="shougakkou"
                                        id="shougakkou"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 130 }}
                                    />から徒歩約
                            <Field
                                        name="shougakkouMin"
                                        id="shougakkouMin"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 80 }}
                                    />分以内
                            </div>
                            </td>
                        </tr>
                        <tr >
                            <th>中学校</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="chuugakkou"
                                        id="chuugakkou"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 130 }}
                                    />から徒歩約
                            <Field
                                        name="chuugakkouMin"
                                        id="chuugakkouMin"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="input"
                                        style={{ width: 80 }}
                                    />分以内
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th>エレベーター</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="haveElevator"
                                        id="haveElevator"
                                        type="text"
                                        placeholder=""
                                        className="form-control"
                                        component="select"
                                        style={{ width: 100 }}
                                    >
                                        <option value="0">なし</option>
                                        <option value="1">あり</option>
                                    </Field>
                                </div>
                            </td>
                        </tr>
                        <tr >
                            <th>担当店舗</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="shop"
                                        id="shop"
                                        placeholder=""
                                        component={RenderField}
                                        className={this.state.isSetShop ? "alert-danger form-control" : "form-control"}
                                        onBlur={e => this.setState({ "isSetShop": e.target.value > 0 ? false : true })}
                                        type="select"
                                    >
                                        <option value="0">-</option>
                                        <option value="1">高松番町店</option>
                                        <option value="2">レインボーロード店</option>
                                        <option value="3">高松駅前店</option>
                                        <option value="4">高松東店</option>
                                        <option value="5">賃貸管理部</option>
                                        <option value="6">高松中央公園店</option>
                                        <option value="18">サンフラワー</option>
                                    </Field>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>エリア</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    <Field
                                        name="area"
                                        id="area"
                                        placeholder=""
                                        component={RenderField}
                                        className={this.state.isSetArea ? "alert-danger form-control" : "form-control"}
                                        onBlur={e => this.setState({ "isSetArea": e.target.value > 0 ? false : true })}
                                        type="select"
                                    >
                                        <option value="0">-</option>
                                        <option value="1">緑</option>
                                        <option value="2">黄</option>
                                        <option value="3">赤</option>
                                        <option value="4">ピンク</option>
                                        <option value="5">オレンジ</option>
                                        <option value="6">青</option>
                                        <option value="7">黒</option>
                                        <option value="8">白</option>
                                        <option value="9">紫</option>
                                    </Field>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button className="btn btn-primary float-right" onClick={this.handleFormSubmit}>保存</button>
            </div>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

TatemonoEditComponent = reduxForm({
    form: 'tatemonoeditform', // a unique identifier for this form
    validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(TatemonoEditComponent)

export default connect(mapStateToProps, mapDispatchToProps)(TatemonoEditComponent);