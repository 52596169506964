import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Line from './Saga/Line';
// import './Css/Images.css';
import Carousel, { Modal, ModalGateway } from 'react-images';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
const authentication = require('../../react-azure-adb2c2').default;
var moment = require('moment-timezone');

const Balloon = styled.div`
    position:relative;

    padding:0px;
    margin: 15px 0 0 0;
    margin-bottom: 0;

`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;

`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 0;
    max-width: 250px;
    height:270px;
    border-radius: 12px;
    margin-bottom: 2px;
    text-align: left;
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

// const MsgArea = styled.p`
//     margin:0px;
//     word-wrap: break-word;
// `;

const BodyImg = styled.img`
    border-radius: 12px;
    height:270px;
    margin:0;
`;

// export default class LineTextComponent extends React.Component {
class LineRightImgComponent extends Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],
            bloburl: "",
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            images: [],
            currentIndex: 0,
            modalIsOpen: false,

        };
        this.imagebox = this.imagebox.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentWillMount() {



        const token = authentication.getAccessToken();
        let url = ""
        if(this.props.msg.rent_line_template_id > 0){
            url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/template/docs/download/` + this.props.msg.file_id
        }else{
            url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/getsendfile/` + this.props.msg.file_id
        }
        
        // const url = `http://localhost:5000/v1/lines/getfile/` + this.props.msg.file_id

        var bloburl
        fetch(url, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => response.blob()).then(images => {

            // Then create a local URL for that image and print it 
            bloburl = URL.createObjectURL(images)
            // alert(bloburl)
            console.log(bloburl)
            this.setState({
                bloburl: bloburl
            })

        });

    }

    imagebox(index) {

        let images = [];

        images.push({ src: this.state.bloburl })



        this.setState({ "images": images })
        if (index >= 0) {
            this.setState({ currentIndex: 0 })
            this.setState({ modalIsOpen: true });
        }
    }
    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    };
    render() {
        return (
            <div>
                <Balloon className="col-xs-12">

                    <Chatting>
                        <Time>
                            {!(this.props.msg.rent_line_template_id > 0) && <div><IconButton
                                style={{color:"white"}}
                                onClick={() => this.props.download_line_file(this.props.msg)}
                                size="large"><DownloadIcon /></IconButton></div>}
                            <div>{this.props.users && this.props.msg.user_id > 0 && this.props.users.filter(a=>a.id == parseInt(this.props.msg.user_id)).length > 0 ? this.props.users.filter(a=>a.id == parseInt(this.props.msg.user_id))[0].nickname : ""}</div>
                            <div>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</div>
                        </Time>
                        <Says>

                            <BodyImg src={this.state.bloburl} onClick={() => this.imagebox(0)} />

                        </Says>
                    </Chatting>
                </Balloon>
                <ModalGateway >
                    {this.state.modalIsOpen ? (
                        <Modal onClose={this.toggleModal} >
                            <Carousel views={this.state.images}
                                currentIndex={this.state.currentIndex}

                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        );
    }
}
//コンテナ
const mapStateToProps = state => {
    return {
        users: state.masterReducer.users,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        download_line_file(state) {
            dispatch(Line.download_line_file(state))
        },

        // get_firestore_listen(state) {
        //     dispatch(Line.get_firestore_listen(state))
        // },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(LineRightImgComponent);