import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import '../../Css/Grid.css';
import LoadingComponent from '../Layout/LoadingComponent';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import ResponseComponent from "../ResponseDetail/ResponseComponent";
const ReactDataGrid = require('react-data-grid');

//<span type="button" className="btn btn-primary" target="_blank" href={`/tradedetails/` + props.row.id}>詳細</span>
const DetailLinkFormatter = ({ value, ...props }: any) => {

  if (props.row.flg === 3) {
    if (props.row.request === 1) {
      return "";
    } else {
      return <a role="button" rel="noreferrer noopener" className="btn btn-primary" target="_blank" href={`/customer/` + props.row.id} >詳細</a>;
    }
    // return "";
  } else {
    return value;
  }
  //return <ProgressBar now={value} label={`${value}%`} />;
};
const ColorFormatter = (value: any) => {
  return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>担当者</span> {value}</div >;

};

const DetailFormatter = ({ value, ...props }: any) => {

  if (props.row.id > 0) {
    const media = props.row.rentMMediumId === 1 ? <span className="badge badge-primary">アパマン</span>
      : props.row.rentMMediumId === 14 ? <span className="badge badge-success">スーモ</span>
        : props.row.rentMMediumId === 16 ? <span className="badge badge-warning">ホームズ</span>
          : props.row.rentMMediumId === 21 ? <span className="badge badge-danger">アットホーム</span>
            : props.row.rentMMediumId === 29 ? <span className="badge badge-light">すまいズ</span>
              : props.row.rentMMediumId === 2 ? <span className="badge badge-info">CLASO.</span>
                : ""

    const shop = props.row.sectionId === 1 ? <span className="badge badge-success">番</span>
      : props.row.sectionId === 2 ? <span className="badge badge-warning">レ</span>
        : props.row.sectionId === 3 ? <span className="badge badge-danger">駅</span>
          : props.row.sectionId === 4 ? <span className="badge badge-dark">東</span>
            : props.row.sectionId === 6 ? <span className="badge badge-light">公</span>
              : props.row.sectionId === 17 ? <span className="badge badge-info">サ</span>
                : props.row.sectionId === 47 ? <span className="badge badge-secondary">通</span>
                  : ""

    return <span>{shop} <span className="badge badge-warning">登録済み</span> {media}</span>
  } else {

    const media = props.row.rentMMediumId === 1 ? <span className="badge badge-primary">アパマン</span>
      : props.row.rentMMediumId === 14 ? <span className="badge badge-success">スーモ</span>
        : props.row.rentMMediumId === 16 ? <span className="badge badge-warning">ホームズ</span>
          : props.row.rentMMediumId === 21 ? <span className="badge badge-danger">アットホーム</span>
            : props.row.rentMMediumId === 29 ? <span className="badge badge-light">すまいズ</span>
              : props.row.rentMMediumId === 2 ? <span className="badge badge-info">CLASO.</span>
                : ""






    const shop = props.row.sectionId === 1 ? <span className="badge badge-success">番</span>
      : props.row.sectionId === 2 ? <span className="badge badge-warning">レ</span>
        : props.row.sectionId === 3 ? <span className="badge badge-danger">駅</span>
          : props.row.sectionId === 4 ? <span className="badge badge-dark">東</span>
            : props.row.sectionId === 6 ? <span className="badge badge-light">公</span>
              : props.row.sectionId === 17 ? <span className="badge badge-info">サ</span>
                : props.row.sectionId === 47 ? <span className="badge badge-secondary">通</span>
                  : ""


    return <span>{shop} {media}</span>
  }
};


const CheckFormatter = ({ value, ...props }: any) => {

  return value === true ? <i className="fas fa-check" style={{ color: "green" }}></i> : ""

};




// export class CustomHeader extends React.Component {
//     render() {
//         return (
//             <div >
//                 <div className="border-bottoms">お客様名</div>
//                 <div className="border-bottoms">I m custome Header</div>
//                 <div className="border-bottoms">I m custome Header</div>
//             </div>
//                 )
//     }
// };

const RowRenderer = ({ renderBaseRow, ...props }: any) => {

  //const color = props.idx % 2 ? "green" : "blue";
  // const border = props.idx % 3 === 2 ? "border-bottoms" : "";
  let backgroundColor = props.row.endResponseDate != null ? "row-highlight-gray" : "";
  backgroundColor = props.row.selected === true ? "row-highlight-pink" : backgroundColor;


  return <span className={backgroundColor}>{renderBaseRow(props)}</span>;

  // const backgroundColor = props.row.request===1? border+" row-highlight-pink" : border+"";

  //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
  // return <span className={border}>{renderBaseRow(props)}</span>;



};
interface Props {
  row: any,
  mail_id: number,
  responses_search_list: any,
  responses_search_list_loading: any,
}

interface State {
  modalmail: boolean,
  modalselect: boolean,
  mailid: number,
  modalMatching: boolean,
  mailMatchingId: number,
  refresh: boolean,
  modalResponseDetail: boolean,
  rentResponseId: number
  // customerdetail:any,

  // responsedetail:any,
  // state types
}

class checkBoxFormatter extends Component<Props, State>{
  constructor(props: Readonly<Props>) {
    super(props);

  }
  render() {

    console.log(this.props.row.id)
    return (this.props.row.flg === 2 ?
      <div>
        <input type="checkbox" id={"scales" + this.props.row.id} className="react-grid-checkbox" name={"scales" + this.props.row.id} />
        <label htmlFor={"scales" + this.props.row.id} className="react-grid-checkbox-label">
        </label>
      </div> : ""
    )
  }
}

const columns = [
  // { key: 'selected', name: '', width: 40, resizable: true, formatter: CheckFormatter },
  { key: 'media', name: '内容', resizable: true, width: 130, formatter: DetailFormatter },
  { key: 'date', name: '反響日', width: 150, resizable: true },
  // { key: 'mainmediaName', name: 'メディア',  width: 100, resizable: true},
  //{ key: 'col2', name: 'タイトル', width: 300, resizable: true, headerRenderer: <CustomHeader /> },
  { key: 'name', name: 'お客様名', width: 120, resizable: true },
  { key: 'tel1', name: '連絡先', width: 100, resizable: true },
  { key: 'email1', name: 'メール', width: 150, resizable: true },
  { key: 'buildingName', name: '反響内容', width: 100, resizable: true },
  // { key: 'detail', name: '詳細', width: 100, resizable: true },
  // { key: 'col5', name: '備考', resizable: true, formatter: DetailLinkFormatter},
];






class ResponsesSearchGridComponent extends Component<Props, State>{
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      // height: window.innerHeight,
      modalselect: false,
      mailid: this.props.mail_id,
      modalMatching: false,
      mailMatchingId: 0,
      modalmail: false,
      refresh: false,
      modalResponseDetail: false,
      rentResponseId: 0
    };
    // this.onScroll = this.onScroll.bind(this);
    this.getCellActions = this.getCellActions.bind(this);
    // this.toggleMatching = this.toggleMatching.bind(this);
    // this.togglemail = this.togglemail.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getRowCount = this.getRowCount.bind(this);
    // alert(this.props.mail_id)
  }


  onRowClick = (rowIdx: any, row: any) => {

    if (row) {
      // this.toggleResponseDetail(row.id)
      window.open(`/customer/` + row.id, '_blank', 'noopener')


    }


  }


  EmptyRowsView = () => {
    const message = "検索結果がありません。";

    return (
      this.props.responses_search_list_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", backgroundColor: "#ddd", height: 200, padding: "50px" }} > <h3>{message}</h3></div>

    );
  };


  getCellActions(column: any, row: any) {

    if (column.key === "detail") {
      return [{
        icon: <button className="btn btn-sm btn-primary" style={{ width: "80px" }} >反響詳細</button>,

        callback: () => {
          window.open(`/customer/` + row.id, '_blank', 'noopener')
          // this.togglemail(row.mailAllReceiveId)
          // this.toggleMatching(row.id)

        }
      }
      ]
    } else {
      return {}
    }

  }




  getRowCount() {

    let count = this.props.responses_search_list.length;

    if (this.state.refresh && count > 0) {
      count--; // hack for update data-grid
      this.setState({
        refresh: false
      });
    }

    return count;
  }

  refresh() {
    this.setState({
      refresh: true
    });
  }
  toggleResponseDetail = (id: number) => {

    this.setState(prevState => ({
      modalResponseDetail: !prevState.modalResponseDetail,
      rentResponseId: id
    }));
  }
  toggleResponseDetailchange = () => {

    this.setState(prevState => ({
      modalResponseDetail: !prevState.modalResponseDetail,

    }));
  }
  render() {

    return (
      <div>
        {/* <EventListener target="window" onResize={this.handleResize} /> */}
        <ReactDataGrid

          scrollToRowIndex={20}
          enableCellSelect={true}
          columns={columns}
          rowGetter={(i: number) => this.props.responses_search_list[i]}
          rowsCount={this.getRowCount()}
          headerRenderer={true}
          getCellActions={this.getCellActions}
          onRowClick={this.onRowClick}

          //headerRowHeight={64}
          dragable={true}
          minHeight={200}
          // onColumnResize={(idx, width) =>
          //     console.log(`Column ${idx} has been resized to ${width}`)
          // }
          // togglemail={this.togglemail}
          // scrollToRowIndex={100}
          emptyRowsView={this.EmptyRowsView}
          rowRenderer={RowRenderer}
        // onScroll = {this.onScroll}
        // minHeight={this.state.height-170} 


        />

        <Modal isOpen={this.state.modalResponseDetail} toggle={this.toggleResponseDetailchange}
          size="xxl" fade={false}

        >

          <ModalHeader toggle={this.toggleResponseDetailchange}>反響詳細</ModalHeader>
          <ModalBody>

            <ResponseComponent rentResponseId={this.state.rentResponseId} responsesSearch={true} />

          </ModalBody>

        </Modal>
        {/* <Modal isOpen={this.state.modalmail} toggle={()=>this.togglemail(1)} size="lg" fade={false}>
               
               <ModalHeader toggle={()=>this.togglemail(1)}>メール詳細</ModalHeader>
               <ModalBody>
               <MailDetail toggle={1} mailid={this.state.mailid} />
               </ModalBody>
               </Modal> */}
        {/* <Modal isOpen={this.state.modalmail} toggle={()=>this.toggleMatching} size="lg" fade={false}>
                <ModalHeader toggle={()=>this.togglemail}>メール詳細</ModalHeader>
      

                </Modal> */}





      </div>
    );
  }


}



const mapStateToProps = (state: any) => {
  return {

  }
}
function mapDispatchToProps(dispatch: any) {
  return {

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesSearchGridComponent);