import React, { Component } from 'react';
import { connect } from 'react-redux';
import TemplateAnalyticsComponent from "./TemplateAnalyticsComponent";

import SpeedAnalyticsComponent from "./SpeedAnalyticsComponent";
import AgeAnalyticsComponent from "./AgeAnalyticsComponent";

import CountComingAnalyticsComponent from "./CountComingAnalyticsComponent";

// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from '../../Store/Monies';
interface Props {
    customerNewDispSet: any,
    customerNewDisp:number,
    customerno:number,
    customerList:any,
    toggle:any,
    get_rent_contract_accounting:any,
    banks:any,
    get_banks:any
}



interface State {

    // state types
  }

class Index extends Component<Props, State> {
    componentWillMount() {
        this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
//     aa(state:any){
// console.log(state)
//     }

    submit(state:any){ 
        console.log(state)
     
        this.props.get_banks(state)
    }

    csv(){
        
        this.props.get_rent_contract_accounting(this.props.banks);
            }
    render() {
 
        return (
            <div className="row" >
               


                <div className="item-outer col-lg-6">
                    <div className="item">
                        <TemplateAnalyticsComponent customerno={this.props.customerno} toggle={this.props.toggle} />
                    </div>
                </div>
                <div className="item-outer col-lg-6">
                    <div className="item">
                        <SpeedAnalyticsComponent customerno={this.props.customerno} toggle={this.props.toggle} />
                    </div>
                </div>
                <div className="item-outer col-lg-6">
                    <div className="item">
                        <AgeAnalyticsComponent customerno={this.props.customerno} toggle={this.props.toggle} />
                    </div>
                </div>
                <div className="item-outer col-lg-6">
                    <div className="item">
                        <CountComingAnalyticsComponent customerno={this.props.customerno} toggle={this.props.toggle} />
                    </div>
                </div>
            </div>
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state:any) => {
    return {
        customerNewDisp: state.customersReducer.customerNewDisp,
        banks:state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch:any) {
    return {
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state:any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state:any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);




