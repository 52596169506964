import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as StockRooms from './Saga/StockRooms';
// import * as Mail from '../Mail/Saga/Mail';
// import * as Responses from '../../Store/Responses';
import '../../Css/GridRooms.css';
import LoadingComponent from '../Layout/LoadingComponent';
import moment from 'moment';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
// import ReactDataGrid from "react-data-grid"
const ReactDataGrid = require('react-data-grid');



// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {
    stock_rooms: any,
    stock_rooms_loading: boolean,
    delete_stock_rooms: any
    // rent_response_id: number,
    // get_rooms: any,
    // toggle: any,
    // contacts_loading: number,
    // selectsheet: any,
    // mailadd: any,
    // add_mail_room: any,
    // togglesearch: any,
    // introductions: any,
    // introductions_loading: number,
    // onMypage: any,
    // delete_introduction: any,
    // togglefileup: any,
    // estimate: any,
}


interface State {
    columns: any,
    modaldelete: boolean,
    rent_introduction_id: number,

    // state types
}
// const MailLinkFormatter = (payload:any) => {

//     return <a role="button"  className="btn btn-primary" target="_blank" onClick={this.mailadd} >追加</a>;

// };
const DateFormatter = (payload: any) => {
    if (payload.value) {
        return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
    } else {
        return "";
    }
};

const gaikanFormatter = (payload: any) => {
    let url = "";

    url = "https://img.global-center.co.jp/v2/tatemono/" + payload.row.tatemono_id + "/1/1/520/780/0";

    return (<img src={url} style={{ width: "auto", maxHeight: 35 }} />)
};

const MadoriFormatter = (payload: any) => {
    if (payload.row.status === 5) {
        return ""
    } else {
        const url = "https://img.global-center.co.jp/v2/rooms/" + payload.row.tatemonoId + "/" + payload.row.roomId + "/8/1/520/780/0";
        return <div><div><img style={{ width: 50, height: "auto", maxHeight: 50 }} src={url} /></div><div>{payload.row.madoriName}</div></div>
    }
};







const RowRenderer = ({ renderBaseRow, ...props }: any) => {
    //console.log(props)
    //const color = props.idx % 2 ? "green" : "blue";


    let backgroundColor = props.row.kuushitsuJoukyouNo === 4 ? " row-highlight-gray" : "";
    backgroundColor = props.row.status === 4 ? " row-highlight-gray" : backgroundColor;
    //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
    return <span className={backgroundColor}>{renderBaseRow(props)}</span>;



};



class IntroductionsGridComponent extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);


        // this.onRowClick = this.onRowClick.bind(this);
        this.mailadd = this.mailadd.bind(this);
        this.getCellActions = this.getCellActions.bind(this);

        this.toggledelete = this.toggledelete.bind(this);
        this.delete_introductions = this.delete_introductions.bind(this);





        this.state = {
            modaldelete: false,
            rent_introduction_id: 0,

            columns: [

                { key: 'tatemono_id', name: '外観', width: 50, resizable: true, formatter: gaikanFormatter },
                // { key: 'madoriName', name: '間取', width: 60, resizable: true, formatter: MadoriFormatter },
                // { key: 'statusset', name: 'ステータス', width: 90, resizable: true, formatter: this.StatusFormatter },
                { key: 'tatemono_name', name: '物件名', width: 180, resizable: true },
                { key: 'room_name', name: '部屋名', width: 70, resizable: true },
                { key: 'delete', name: '削除', width: 40, resizable: true },
                // { key: 'chinRyou', name: '賃料', width: 120, resizable: true, formatter: ChinRyouFormatter },
                // { key: 'chinRyouK', name: '契約時', width: 100, resizable: true, formatter: KeiyakuFormatter },
                // { key: 'delete', name: '削除', width: 60, resizable: true },



                // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
            ]
        }



    }






    componentWillMount() {

        // this.props.get_rooms()
        // this.props.customerList()

    }
    getSelectedCell(event: any) {
        alert()
    }

    // onRowClick = (rowIdx:any, row:any) => { 

    //     if(row)
    //     this.props.toggle(row);




    // }

    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.stock_rooms_loading === true ? <LoadingComponent /> : <div style={{ textAlign: "center", padding: "100px" }} > <h3>{message}</h3></div>

        );
    };

    mailadd = (tatemonoId: any, roomId: any) => {

        // this.props.add_mail_room({ window: window, id: 131, tatemonoId: tatemonoId, roomId: roomId })
        // this.props.togglesearch();
    }


    toggledelete = () => {

        this.setState(prevState => ({
            modaldelete: !prevState.modaldelete,

        }));
    }

    delete_introductions() {
        // alert(this.props.rent_response_id)
        // this.props.delete_introduction({ rentIntroductionId: this.state.rent_introduction_id, rent_response_id: this.props.rent_response_id })
        this.toggledelete()
    }
    getCellActions(column: any, row: any) {
        // console.log(row.way)

        // const cellActions = {

        //   coming: comingActions
        // };
        if (column.key === "delete") {

            return [
                {
                    icon: <RemoveShoppingCartIcon style={{ color: "red" }} />,
                    callback: () => {

                        this.props.delete_stock_rooms(row.id)
                        // this.toggledelete()
                        // this.setState({ rent_introduction_id: row.id })

                    }
                }

            ]
        }
        // if( column.key==="roomName" ){
        //     let status;
        //     if(row.status===1){
        //         status=<div className="badge badge-success">初回問合</div> 
        //       }else if(row.status===3){
        //         status= <div><div className="clearfix"></div><div className="badge badge-info">自動送信</div> </div>
        //       }
        //       let publish;
        //       if(row.onMypage===1){
        //         publish=<div><div className="clearfix"></div><button type="button" className="btn btn-sm btn-danger" style={{width:"70px",margin:"2px"}}>公開取消</button></div>

        //       }else{
        //         publish= <div><div className="clearfix"></div><button type="button" className="btn btn-sm btn-success" style={{width:"70px",margin:"2px"}}>公開する</button></div>
        //       }
        // return [
        //     {
        //         // icon: <button className="btn btn-sm btn-success"  style={{width:"50px"}}>追加</button>,
        //         icon:<button type="button" className="btn btn-sm btn-success" style={{width:"70px",margin:"2px"}}>詳細</button>,
        //         callback: () => {

        //             this.mailadd(row.tatemonoId,row.roomId)


        //         }
        //       }
        //       ,{
        //     // icon: <button className="btn btn-sm btn-success"  style={{width:"50px"}}>追加</button>,
        //     icon:publish,
        //     callback: () => {

        //         this.mailadd(row.tatemonoId,row.roomId)


        //     }
        //   }
        //     ]

        // }else{
        //     return "";
        // }

    }

    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div >

                <ReactDataGrid
                    // ref={node => this.grid = node}
                    enableCellSelect={false}
                    columns={this.state.columns}
                    rowGetter={(i: number) => this.props.stock_rooms[i]}
                    rowHeight={35}
                    headerRowHeight={35}
                    rowsCount={this.props.stock_rooms.length}
                    getCellActions={this.getCellActions}
                    // headerRenderer={true}
                    dragable={true}
                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    width={300}
                    minHeight={260}
                    emptyRowsView={this.EmptyRowsView}
                    rowRenderer={RowRenderer}
                // onRowClick={this.props.selectsheet}
                //onCellSelected={this.getSelectedCell}
                />






                <Modal isOpen={this.state.modaldelete} toggle={this.toggledelete} size="lg" fade={false}>

                    <ModalHeader toggle={this.toggledelete}>リストから削除</ModalHeader>
                    <ModalBody>
                        対象物件を紹介リストから削除します。

                        自動送信・自動送信除外を削除した場合は再度自動送信リストに加わります。
               </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.delete_introductions}>削除する</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggledelete}>キャンセル</button>
                    </ModalFooter>
                </Modal>


            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        // rooms: state.roomlist.rooms,
        stock_rooms: state.StockRooms.stock_rooms,
        stock_rooms_loading: state.StockRooms.stock_rooms_loading,
        // responseid: state.customersReducer.responseid,
        // responsedetail: state.customersReducer.responsedetail,
        // introductions_loading: state.roomlist.introductions_loading
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        delete_stock_rooms(state: any) {
            dispatch(StockRooms.delete_stock_rooms(state))
        },
        // add_mail_room(state:any) {
        //     dispatch(Mail.add_mail_room(state))
        // },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionsGridComponent);