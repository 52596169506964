
const authentication = require('../../../react-azure-adb2c2').default;



export const download = (values: any) => {


    let url = process.env.REACT_APP_REPORT_API_URL + `/api/Estimate/` + values;


    const token = authentication.getAccessToken();




    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(response => response.blob())
        .then(payloaddoc => ({ payloaddoc }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(errordoc => ({ errordoc }));






};