const authentication = require('../../../react-azure-adb2c2').default;
// export function update( values: any) {



export function change_status(value: any) {

    const token = authentication.getAccessToken();

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/looked/` + value
    // const url = `http://localhost:5000/api/v1/rent/responses/looked/` + value
    // alert(url)
    // alert(JSON.stringify(search))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        // 

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}



export function linebind(search: any) {

    const token = authentication.getAccessToken();
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/linebind/`
    // const url = `http://localhost:5000/api/v1/rent/responses/linebind/`
    // alert(url)
    // alert(JSON.stringify(search))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        // 

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
