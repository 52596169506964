import React, { Component } from 'react';
import * as Responses from './Saga/ResponseList';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import styled from 'styled-components';
var moment = require('moment-timezone');



const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 10px;
    max-width: 250px;
    border-radius: 12px;
    background: #7fff97;
    margin-bottom: 2px;
    text-align: left;
    &:after {
        content: "";
        position: absolute;
        top: 3px; 
        right: -19px;
        border: 8px solid transparent;
        border-left: 18px solid #7fff97;
        -ms-transform: rotate(-35deg);
        -webkit-transform: rotate(-35deg);
        transform: rotate(-35deg);
    }
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const MsgArea = styled.p`
    margin:0px;
    word-wrap: break-word;
    whiteSpace:pre-line;
`;

const DeleteButton = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    top: 0;

`;



// export default class LineTextComponent extends React.Component {
class LineTextComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: '1',
            messages: [],

            date_time: moment(this.props.msg.date).tz("Asia/Tokyo").format('MM/DD HH:mm'),

        };
    }

    onDeleteMessage(no){
        // let rent_line_template_detail = this.props.rent_line_template_detail
    
        // rent_line_template_detail = rent_line_template_detail.filter(a=>a.no != no)

        // this.props.change_rent_line_template_detail(rent_line_template_detail)
// alert(no)
        this.props.delete_rent_line_template_detail(no)

        this.setState({
            refresh: !this.state.refresh
        });
    }

    render() {
        return (
            <Balloon className="col-xs-12">
                <Chatting>
                    {this.props.line_template_edit_open ? <DeleteButton>
                        <IconButton onClick={()=>this.onDeleteMessage(this.props.msg.no)} size="large"><CloseIcon /></IconButton>
                    </DeleteButton>:""}
                    {/* <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time> */}
                    <Says>
                        
                        <MsgArea >  <span dangerouslySetInnerHTML={{ __html: this.props.msg.message_text.replace(/\r?\n/g, '<br/>') }} /></MsgArea>
                    </Says>
                </Chatting>
            </Balloon>
        );
    }
}




//コンテナ
const mapStateToProps = state => {
    return {

        rent_line_template_detail: state.responselist.rent_line_template_detail,
        line_template_edit_open: state.responselist.line_template_edit_open,

    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_rent_line_template_detail(state) {
            dispatch(Responses.change_rent_line_template_detail(state))
        },
        delete_rent_line_template_detail(state) {
            dispatch(Responses.delete_rent_line_template_detail(state))
        },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(LineTextComponent);
