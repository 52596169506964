
const authentication = require('../../../react-azure-adb2c2').default;
export const list = (values: any) => {
    
    // const url = `http://localhost:5000/v1/rent/line/schedules/` + values;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/schedules/` + values;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(url)

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

// export const update = (value: any) => {
//     // alert( JSON.stringify(value))

//     let url = `http://localhost:5000/v1/rent/line/templates/update/`+ value.rent_line_template.id;
//     // let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/templates/update/`+ value.rent_line_template.id;
    

//     const token = authentication.getAccessToken();

//     return fetch(url, {
//         method: 'put',
//         body: JSON.stringify(value),

//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//     }).then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// };

export const insert = (values: any) => {
    // alert(JSON.stringify(values))
// console.log(values)
    // const url = `http://localhost:5000/v1/rent/line/schedules/insert/`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/schedules/insert/`;


    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export function schedule_delete(id: number) {

    const token = authentication.getAccessToken();
  
    // const url = `http://localhost:5000/v1/rent/line/schedules/delete/` + id;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/schedules/delete/` + id;
  
    // alert(url)
    return fetch(url, {
      method: 'get',
  
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
      .then(res => res.json())
      .then(payload => ({ payload }))
      .catch(error => ({ error }));
  
  }


