import React, { Component } from 'react';
import { connect } from 'react-redux';


import MypageLogGridComponent from './MypageLogGridComponent';
import MypageLogGraphComponent from './MypageLogGraphComponent';
// import RentCalendarComponent from '../Calendar/RentCalendarComponent';
class ContactsComponet extends Component {
    constructor(props) {
        super(props);
    
     
        // this.state = {
        //     mode: '2',
        //     modalselect: false,
        //     modalscheduler:false,
        //    comingdetail :{
        //         id: -1,
        //         userId: props.user_details.id,
        //       date:new Date()
        //     }
        // };
        
        // this.handleClick = this.handleClick.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.handleFormSubmit = this.handleFormSubmit.bind(this);
        // this.toggledelete = this.toggledelete.bind(this);
        // this.delete = this.delete.bind(this);
    }
//     componentWillMount() {
     
//         this.setState ({
  
//            comingdetail :{
//                 id: -1,
//                 userId: this.props.user_details.id,
//               date:new Date()
//             }
//         });
 
// }
    
   




   
    render() {
        console.log(this.props.customer_contacts)

    return (
        <div>

                <MypageLogGridComponent rent_mypage_logs={this.props.rent_mypage_logs} rent_mypage_logs_loading={this.props.rent_mypage_logs_loading} />
          
                {this.props.rent_mypage_logs.length > 0 ? <MypageLogGraphComponent /> : ""}

     </div>
    );
  }
}


const mapStateToProps = state => {
    return {
        rent_mypage_logs:state.mypagelog.rent_mypage_logs
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_rent_mypage_logs(state) {
        //    dispatch(MypageLog.get_rent_mypage_logs(state))
        // },
        // update_rent_customer_contacts(state) {
        //     dispatch(CustomerContacts.update_rent_customer_contacts(state))
        //  },
        //  delete_rent_customer_contacts(state) {
        //     dispatch(CustomerContacts.delete_rent_customer_contacts(state))
        //  },
        // submit(state) {
        
        //     dispatch(submit(state))
        //  },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactsComponet);