import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import * as Files from './Saga/Files';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';

var moment = require('moment-timezone');

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_limited_public_pages_list[rowIndex][column.key]}
  </Cell>;

const CreatedAt = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {moment(props.rent_limited_public_pages_list[rowIndex][column.key]).format('YYYY-MM-DD HH:mm:ss')}
  </Cell>;

const PageLimited = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {moment(props.rent_limited_public_pages_list[rowIndex][column.key]).format('YYYY-MM-DD')}
  </Cell>;

const PName = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <IconButton style={{marginTop:-5}} onClick={(e) => { props.download_application_files(props.rent_limited_public_pages_list[rowIndex]) }} aria-label="edit" size="small">
      <GetAppIcon />
    </IconButton>
  </Cell>;

const Count = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_limited_public_docs_list.filter(a=>a.rent_limited_public_page_id == props.rent_limited_public_pages_list[rowIndex].id).length}
  </Cell>;

// const Edit = ({ column, props, rowIndex, file_remarks_edit_open }) =>
//   <Cell style={{ whiteSpace: "nowrap" }} >
//     <IconButton style={{marginTop:-5}} onClick={(e)=>{file_remarks_edit_open({ fileid: props.rent_limited_public_pages_list[rowIndex].id, name: props.rent_limited_public_pages_list[rowIndex].name, remarks: props.public_files[rowIndex].remarks ? props.public_files[rowIndex].remarks : "" }) }} aria-label="edit" size="small">
//       <EditIcon />
//     </IconButton>
//   </Cell>;

const Disp = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <Button variant="contained" style={{}} size="small" onClick={() => props.download_disp_limited_public_docs(props.rent_limited_public_pages_list[rowIndex])} color="primary">
      表示
    </Button>
  </Cell >;

const Edit = ({ column, props, rowIndex, handlePageSelect }) =>
<Cell style={{ whiteSpace: "nowrap" }} >
  <IconButton style={{marginTop:-5}} onClick={(e)=>{props.change_contract_file_update_disp(true);handlePageSelect(props.rent_limited_public_pages_list[rowIndex].id)}} aria-label="edit" size="small">
    <EditIcon />
  </IconButton>
</Cell >;


var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


// const columns = [
//   { key: 'p_name', name: 'DL', width: 50 },
//   { key: 'name', name: 'ファイル名', width: 200 },
//   { key: 'remarks', name: '備考', width: 150 },
//   { key: 'disp', name: '表示', width: 80 },
//   // { key: 'edit', name: '編集', width: 60 },
//   { key: 'created_at', name: 'アップロード日時', width: 150 },
//   { key: 'page_limited', name: '公開期限', width: 100 },
//   { key: 'page_name', name: 'ページ名', width: 200 },
//   { key: 'page_remarks', name: 'ページ備考', width: 220 },

// ]


const columns = [

  { key: 'edit', name: '編集', width: 50 },
  { key: 'name', name: 'ページ名', width: 200 },
  { key: 'limited', name: '公開期限', width: 100 },
  { key: 'count', name: '件数', width: 50 },
  { key: 'remarks', name: '備考', width: 300 },
  
]


class CustomerList extends Component {
  constructor(props) {
    super(props);


    // this.props.rent_m_contract_follows_disp.map(async function (value) {
    //   if (value.disp) {
    //     columns.push({ key: value.key_name, name: value.name, company: value.company, width: 80 })
    //   }
    // })
    console.log(columns)

    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };
    // this.props.get_contract_follow_list()
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this.handlePageSelect = this.handlePageSelect.bind(this);


    // this.contractdetail = this.contractdetail.bind(this);


  }





  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }


  handlePageSelect(id){
    let rent_limited_public_pages = []
    rent_limited_public_pages = this.props.rent_limited_public_pages_list.filter((a) => a.id === id)[0]
    this.props.change_rent_limited_public_pages(rent_limited_public_pages)
    // this.props.change_rent_limited_public_page_id(this.props.rent_limited_public_page_id == id ? 0 : id)
  }



  render() {

    return (
      <div id="contract_car" >

        {
          this.props.rent_limited_public_pages_list ? <div style={{ marginBottom: 10 }}><Table
            rowHeight={40}
            rowsCount={this.props.rent_limited_public_pages_list.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            // width="100%"
            // height={this.props.rent_limited_public_pages_list.length * 40 + 42}
            // width={window.innerWidth - 110}
            // height={window.innerHeight - 250}
            width={(window.innerWidth / 2) - 75}
            height={350}
            headerHeight={40}>
            {this.state.columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  value.key === "disp" ? <Disp column={value} props={this.props} rowIndex={rowIndex} ></Disp>
                    //   : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Seikyuu>

                    //     : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Amount>
                    //       : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Delete>
                    : value.key === "p_name" ? <PName column={value} props={this.props} rowIndex={rowIndex} ></PName>
                    : value.key === "created_at" ? <CreatedAt column={value} props={this.props} rowIndex={rowIndex} ></CreatedAt>
                    : value.key === "limited" ? <PageLimited column={value} props={this.props} rowIndex={rowIndex} ></PageLimited>
                    : value.key === "count" ? <Count column={value} props={this.props} rowIndex={rowIndex} ></Count>
                    : value.key === "edit" ? <Edit column={value} props={this.props} rowIndex={rowIndex} handlePageSelect={this.handlePageSelect} ></Edit>
                    : <Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table > </div> : ""
        }

        {/* {
          this.props.rent_limited_public_docs_list ? <div style={{ marginBottom: 10 }}><Table
            rowHeight={40}
            rowsCount={this.props.rent_limited_public_docs_list.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}

            width={(window.innerWidth / 2) - 75}
            height={350}
            headerHeight={40}>
            {this.state.columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                cell={({ rowIndex, ...props }) => (

                  value.key === "disp" ? <Disp column={value} props={this.props} rowIndex={rowIndex} ></Disp>
                    : value.key === "p_name" ? <PName column={value} props={this.props} rowIndex={rowIndex} ></PName>
                    : value.key === "created_at" ? <CreatedAt column={value} props={this.props} rowIndex={rowIndex} ></CreatedAt>
                    : value.key === "page_limited" ? <PageLimited column={value} props={this.props} rowIndex={rowIndex} ></PageLimited>

                    : value.key === "edit" ? <Edit column={value} props={this.props} rowIndex={rowIndex} file_remarks_edit_open={this.props.file_remarks_edit_open} ></Edit>
                    : <Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table > </div> : ""
        } */}




      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    rent_limited_public_docs_list: state.files.rent_limited_public_docs_list,
    rent_limited_public_pages_list: state.files.rent_limited_public_pages_list,

  }
}
function mapDispatchToProps(dispatch) {
  return {

    download_disp_limited_public_docs(state) {
      dispatch(Files.download_disp_limited_public_docs(state))
    },
    download_application_files(state) {
      dispatch(Files.download_application_files(state))
    },
    change_contract_file_update_disp(state) {
      dispatch(Files.change_contract_file_update_disp(state))
    },
    change_rent_limited_public_pages(state) {
      dispatch(Files.change_rent_limited_public_pages(state))
    },
    // change_rent_limited_public_page_id(state) {
    //   dispatch(Files.change_rent_limited_public_page_id(state))
    // },
    // change_responseid(state) {
    //   dispatch(Files.change_responseid(state))
    // },
    // change_modalResponseDetail(state) {
    //   dispatch(Files.change_modalResponseDetail(state))
    // },

    // change_rent_contract_id(state) {
    //   dispatch(Files.change_rent_contract_id(state))
    // },
    // change_rent_contract_money_edit_disp(state) {
    //   dispatch(Files.change_rent_contract_money_edit_disp(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);