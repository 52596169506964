import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styled from 'styled-components';

var moment = require('moment-timezone');
const Balloon = styled.div`
position:relative;
    padding:0px;
    margin-top:15px;
`;
const Faceicon = styled.div`
    float: left;
        margin-right: -50px;
        width: 40px;
`;
const FaceiconImg = styled.img`
    width: 100%;
        height: auto;
        border-radius: 50%;
`;
const DisplayName = styled.div`
    margin:0 0 0 50px;
    font-size:9px;
    font-weight:bold;
    color:#FFF;
text-align:left;
`;
const Chatting = styled.div`
    width: 100%;
        text-align: left;
`;
const Say = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 50px;
    padding: 5px;
    max-width: 250px;
    border-radius: 12px;
    margin-bottom: 2px;
`;

const SayP = styled.p`
    margin:0；
    height:270px;
`;

const MsgArea = styled.p`
    margin: 0;
        padding: 0;
word-wrap: break-word;
`;
const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;bottom: 0px;
    font-size:10px;
    color:#FFF;
`;

const Right = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0px;
    font-size:10px;
    color:#FFF;
    width:100px;
`;

const Download = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 5px;
    font-size:10px;
    color:#FFF;
`;



export default class LineImgComponent extends React.Component {
    constructor(props) {
        super(props)
        const dt = new Date(this.props.msg.date)
        this.state = {
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),

        };

    }

    render() {
        return (
            <Balloon className="col-xs-12" >
                <Faceicon><AccountCircleIcon style={{fontSize:40, color:"#b7cdda"}} /></Faceicon>
                <DisplayName>{this.props.msg.display_name}</DisplayName>
                <Chatting>
                    <Say>
                        <div>
                            <img src={"https://stickershop.line-scdn.net/stickershop/v1/sticker/"+this.props.msg.message_text+"/iPhone/sticker_key@2x.png"} />
                        </div>
                    </Say>
                    <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time>
                </Chatting>
            </Balloon>
        );
    }
}
