import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, } from 'reactstrap';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
import * as DashBoard from './Saga/DashBoard';
const Scrollbars = require('react-custom-scrollbars');
//const authentication = require('react-azure-adb2c').default;
interface Props {
    download_sales_sheet: any,
    change_sales_sheet_form: any,
    sales_sheet_form: any,
    section_select: any,
    // customerNewDispSet: any,
    // customerNewDisp:number,
    // customerno:number,
    // customerList:any,
    // toggle:any,
    // get_rent_contract_accounting: any,
    // banks: any,
    // get_banks: any
}



interface State {
    reflash: boolean
    // state types
}

class Sales extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            // setintend: this.props.contactstate.intend === 0 || this.props.contactstate.intend === false ? false : true,
            reflash: false,
        };

        this.setTenpo = this.setTenpo.bind(this);
        this.setStart_date = this.setStart_date.bind(this);
        this.setEnd_date = this.setEnd_date.bind(this);


    }

    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state: any) {
        console.log(state)

        // this.props.get_banks(state)
    }
    setTenpo = (e: any) => {
        // alert(e)
        let sales_sheet_form = this.props.sales_sheet_form

        sales_sheet_form.section_id = e
        // setTenpo(e: any) {

        this.setState({ reflash: !this.state.reflash });
    }

    setStart_date(e: any) {
        // alert(e)

        let sales_sheet_form = this.props.sales_sheet_form

        sales_sheet_form.start = e
        this.setState({ reflash: !this.state.reflash });
        // alert(e.target.value);
    }

    setEnd_date(e: any) {

        let sales_sheet_form = this.props.sales_sheet_form

        sales_sheet_form.end = e
        this.setState({ reflash: !this.state.reflash });
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <div style={{ margin: 5 }}>
                <Grid container>

                    <Card >
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}

                            <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
                                <Row style={{ margin: 0 }}>
                                    <Col lg="3" style={{ padding: 3 }}>

                                        <TextField
                                            id="start"
                                            label="開始日"
                                            type="date"
                                            name="start"
                                            size="small"
                                            // onChange={this.onChange}
                                            onChange={(e) => {
                                                this.setStart_date(e.target.value);
                                            }}
                                            value={moment(this.props.sales_sheet_form.start).format("YYYY-MM-DD")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        />

                                    </Col>
                                    <Col lg="3" style={{ padding: 3 }}>

                                        <TextField
                                            id="end"
                                            label="終了日"
                                            type="date"
                                            name="end"
                                            size="small"
                                            // onChange={this.onChange}
                                            onChange={(e) => {
                                                this.setEnd_date(e.target.value);
                                            }}
                                            value={moment(this.props.sales_sheet_form.end).format("YYYY-MM-DD")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        />



                                    </Col>
                                    <Col lg="6" style={{ padding: 3 }}>
                                        {this.props.section_select.length ? <Autocomplete
                                            // fullWidth
                                            // options={tenpolist.filter(a=> a.tenpo_no != -1)}
                                            options={this.props.section_select.filter((a: any) => a.value == -1 || a.value == 1 || a.value == 2 || a.value == 3 || a.value == 4 || a.value == 6 || a.value == 17 || a.value == 47 || a.value == 26 || a.value == 37 || a.value == 55)}
                                            getOptionLabel={(option: any) => option.label}
                                            // defaultValue={tenpo.filter(a => a.value === this.props.user_details.amSectionId)[0]}
                                            defaultValue={this.props.section_select.filter((a: any) => a.value === 1)[0]}
                                            value={this.props.section_select.filter((a: any) => a.value === this.props.sales_sheet_form.section_id)[0]}
                                            // style={{ width: 200 }}
                                            onChange={(e, newValue) => {
                                                this.setTenpo(newValue ? newValue.value : -1);
                                            }}
                                            size="small"
                                            renderInput={(params) => <TextField style={{width:250, marginTop:5}}
                                                {...params} label="店舗を選択" />}

                                        /> : ""}
                                    </Col>
                                </Row>
                            </div>

                        {/* </MuiPickersUtilsProvider> */}
                        <Button variant="contained" onClick={this.props.download_sales_sheet} disabled={true} color="primary" style={{ float: "right", margin: 5 }}>
                            日報
                            {/*   */}
                        </Button>
                    </Card>


                </Grid>
            </div >
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        sales_sheet_form: state.dashboard.sales_sheet_form,
        section_select: state.masterReducer.section_select,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        change_sales_sheet_form(state: any) {
            dispatch(DashBoard.change_sales_sheet_form(state))
        },
        download_sales_sheet(state: any) {
            dispatch(DashBoard.download_sales_sheet(state))
        },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sales);




