import React, { Component } from 'react';

import { LineChart, Line, ComposedChart, XAxis, YAxis, Tooltip, CartesianGrid, Bar, BarChart, Legend, LabelList } from 'recharts';
import moment from 'moment'



const data = [
  {
    name: '番町店 ', uvname: '20代', uv: 4000, uv2: 1000, pvname: '30代', pv: 2400, amtname: '40代', amt: 2400,
  },
  {
    name: 'レインボー', uvname: '20代', uv: 3000, pvname: '30代', pv: 1398, amtname: '40代', amt: 2210,
  },
  {
    name: '駅前', uvname: '20代', uv: 2000, pvname: '30代', pv: 8, amtname: '40代', amt: 2290,
  },
  {
    name: '東', uvname: '20代', uv: 2780, pvname: '30代', pv: 3908, amtname: '40代', amt: 2000,
  },
  {
    name: '中央公園', uvname: '20代', uv: 18, pvname: '30代', pv: 4800, amtname: '40代', amt: 2181,
  },
  {
    name: 'サンフラワー', uvname: '20代', uv: 2390, pvname: '30代', pv: 3800, amtname: '40代', amt: 2500,
  },
  {
    name: '中央通り', uvname: '20代', uv: 3490, pvname: '30代', pv: 4300, amtname: '40代', amt: 2100,
  },
];
const renderCustomizedLabel = (props) => {
  const {
    x, y, width, height, value,
  } = props;
  const radius = 15;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
      <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    </g>
  );
};

const renderCustomizedLabel2 = (props) => {
  const {
    x, y, width, height, value,
  } = props;
  const radius = 15;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
      <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    </g>
  );
};
const renderCustomizedLabel3 = (props) => {
  const {
    x, y, width, height, value,
  } = props;
  const radius = 15;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
      <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    </g>
  );
};
class AgeAnalyticsComponent extends Component {
  render() {
    return (
      <div>
        年代
        <BarChart
          width={900}
          height={300}
          data={data}
          margin={{
            top: 5, right: 30, left: 20, bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />

          <YAxis />

          <Tooltip />

          {/* <Legend /> */}
          <Bar dataKey="uv" fill="#8884d8" stackId="uv" minPointSize={10} />
          <Bar dataKey="uv2" fill="#82ca9d" stackId="uv" minPointSize={10} >
            <LabelList dataKey="uvname" content={renderCustomizedLabel3} />
          </Bar>

          <Bar dataKey="pv" fill="#8884d8" minPointSize={5}>
            <LabelList dataKey="pvname" content={renderCustomizedLabel} />
          </Bar>


          <Bar dataKey="amt" fill="#82ca9d" minPointSize={10} >
            <LabelList dataKey="amtname" content={renderCustomizedLabel} />
          </Bar>

        </BarChart>
      </div>
    );
  }
}

export default AgeAnalyticsComponent;