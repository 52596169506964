import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import * as CalendarSaga from './Saga/Calendar';
import Meetings from '../Meetings/Index';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, FormLabel, FormGroup, Button, IconButton, Radio, RadioGroup, ListItemIcon, ListItemSecondaryAction } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OwnerSearchIndex from "./OwnerSearchIndex";
import MaskedInput from 'react-text-mask';
import DateTimeComponentTime from "./DateTimeComponentTime";
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Fab from '@mui/material/Fab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
var moment = require('moment-timezone');



function timeMask(value) {
    const chars = value.split('');
    const hours = [
      /[0-2]/,
      chars[0] === '2' ? /[0-3]/ : /[0-9]/,
    ];
    const minutes = [/[0-5]/, /[0-9]/];
  
    return hours.concat(':').concat(minutes);
  }
  
  
  const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref}
        mask={timeMask}
        showMask
      />
    );
  });


  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
   
  ];
class EigyoDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kashikata: false,
            kingaku: false,
            kouji: false,
            maintenance: false,
            claim: false,
            sonota: false,

            modal_del: false,
            modal_search: false,

            focus_start: false,
            focus_end: false,

            start_at_err: false,
            end_at_err: false,

            kanri_only:true,
        };

        // this.handleChange = this.handleChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleTmOwnerIdChange = this.handleTmOwnerIdChange.bind(this);
        this.handleTmOwnerIdChange2 = this.handleTmOwnerIdChange2.bind(this);
        this.handleUserIdChange = this.handleUserIdChange.bind(this);
        this.onChangeTogetherId = this.onChangeTogetherId.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleOwnerSearchClose = this.handleOwnerSearchClose.bind(this);

        this.focus_start = this.focus_start.bind(this);
        this.blur_start = this.blur_start.bind(this);
        this.focus_end = this.focus_end.bind(this);
        this.blur_end = this.blur_end.bind(this);
        this.check_validation = this.check_validation.bind(this);
        this.change_datetime_select = this.change_datetime_select.bind(this);
        this.change_datetime = this.change_datetime.bind(this);
        this.handleKanriOnly = this.handleKanriOnly.bind(this);
        this.OnChangeOutaiNaiyouId = this.OnChangeOutaiNaiyouId.bind(this);
        this.OnChangeNewPlanTatemonoIdButton = this.OnChangeNewPlanTatemonoIdButton.bind(this);
    }

    handleKanriOnly() {
        // alert(state.target.value)
        this.setState({ 
            kanri_only: !this.state.kanri_only
        })
    }

    onChangeDate(value) {

        let eigyou_detail = this.props.eigyou_detail
        eigyou_detail.yoyaku_date = moment(value).format("YYYY/MM/DD")
        this.props.change_eigyou_detail(eigyou_detail)
        this.setState({
            change: !this.state.change
        });

        this.check_validation()
    }

    // OnChangeOutaiNaiyouId(id){
    OnChangeOutaiNaiyouId(e, value) {

        let eigyou_detail = this.props.eigyou_detail
        if (value) {
            eigyou_detail.outai_naiyou_id = value

        } else {
            eigyou_detail.outai_naiyou_id = []
        }

        this.props.change_eigyou_detail(eigyou_detail);
        this.setState({ change: !this.state.change });

        this.check_validation()



        // let eigyou_detail = this.props.eigyou_detail


        // if (eigyou_detail["outai_naiyou_id"] && eigyou_detail["outai_naiyou_id"].split(",").includes(String(id))) {

        //     //削除
        //     eigyou_detail["outai_naiyou_id"] = eigyou_detail["outai_naiyou_id"].split(",").filter(item => item != String(id)).join(',')
        // } else {

        //     let ary = eigyou_detail["outai_naiyou_id"] ? eigyou_detail["outai_naiyou_id"].split(",") : []

        //     ary.push(id)

        //     ary = ary.filter(Boolean)

        //     eigyou_detail["outai_naiyou_id"] = ary.join(',')
        // }

        

        // this.props.change_eigyou_detail(eigyou_detail)
        // this.setState({
        //     change: !this.state.change
        // });

        // this.check_validation()
    }

    onChange(state) {
        // console.log(this.props.claso_blog_places)
        let eigyou_detail = this.props.eigyou_detail

        if (state.target.name == "suggestions" || state.target.name == "outai_naiyou_id") {

            if (eigyou_detail[state.target.name] && eigyou_detail[state.target.name].split(",").includes(state.target.value)) {

                //削除
                eigyou_detail[state.target.name] = eigyou_detail[state.target.name].split(",").filter(item => item != state.target.value).join(',')
            } else {

                let ary = eigyou_detail[state.target.name] ? eigyou_detail[state.target.name].split(",") : []

                ary.push(state.target.value)

                ary = ary.filter(Boolean)

                eigyou_detail[state.target.name] = ary.join(',')
            }
        }else if (eigyou_detail.type == 2 && state.target.name == "no_count") {    
            eigyou_detail[state.target.name] = state.target.checked
        }else{
            eigyou_detail[state.target.name] = state.target.value
        }

        

        this.props.change_eigyou_detail(eigyou_detail)
        this.setState({
            change: !this.state.change
        });

        this.check_validation()

    }
    handleTmOwnerIdChange(e, value) {
        let eigyou_detail = this.props.eigyou_detail
        if (value) {
            eigyou_detail.owner_id = value.owner_id
        } else {
            eigyou_detail.owner_id = 0
        }

        if(eigyou_detail.owner_id > 0){
            this.props.get_eigyou_owner_room(eigyou_detail.owner_id)
        }

        this.props.change_eigyou_detail(eigyou_detail);
        this.setState({ change: !this.state.change });

        this.check_validation()
    }

    handleTmOwnerIdChange2(e, value) {
        let eigyou_detail = this.props.eigyou_detail
        if (value && value.id) {
            eigyou_detail.owner_id = value.id
        } else {
            eigyou_detail.owner_id = 0
        }

        if(eigyou_detail.owner_id > 0){
            this.props.get_eigyou_owner_room(eigyou_detail.owner_id)
        }

        this.props.change_eigyou_detail(eigyou_detail);
        this.setState({ change: !this.state.change });

        this.check_validation()
    }


    handleUserIdChange(e, value) {
        let eigyou_detail = this.props.eigyou_detail
        if (value) {
            eigyou_detail.tantou_user_id = value.value

        } else {
            eigyou_detail.tantou_user_id = 0
        }

        this.props.change_eigyou_detail(eigyou_detail);
        this.setState({ change: !this.state.change });

        this.check_validation()
    }

    handleUpdate(id, delflg) {

        let eigyou_detail = this.props.eigyou_detail

        this.props.change_eigyou_detail(eigyou_detail)

        if (id) {
            this.props.update_eigyou_detail()
        } else {
            this.props.insert_eigyou_detail()
        }

        this.setState({
            change: !this.state.change
        });

        // this.props.handleClose()

    }

    handleDelete(id) {

        this.props.delete_eigyou_detail()

        this.setState({
            change: !this.state.change,
            modal_del: false
        });

        // this.props.handleClose()

    }

    handleOpenBuilding(id, parking){
        if(parking == 1){
            window.open(process.env.REACT_APP_HOMESTATION4_URL + `/manage/parking/detail/index?p_id=` + id.replace('SysP', ""), '_blank', 'noopener')
        }else{
            window.open(process.env.REACT_APP_HOMESTATION4_URL + `/manage/building/detail/index?t_id=` + id, '_blank', 'noopener')
        }
    }

    handleOwnerSearchClose(){
        this.setState({ 
            modal_search: false,
        })
    }

    handleOwnerOpen(id) {
        window.open(process.env.REACT_APP_PM_URL + `/Owner/` + id, '_blank', 'noopener')
    }

    handleCorporateOpen(id) {
        window.open(process.env.REACT_APP_PM_URL +`/Corporate/` + id, '_blank', 'noopener')
    }

    componentDidMount() {
        this.check_validation()
    }

    focus_start(e) {

        this.check_validation()
        this.setState({ focus_start: true })
    }
    blur_start(e) {

    setTimeout(() => {
        this.setState({ focus_start: false })
    }, 200)

    }
    focus_end(e) {
        console.log(e.target)

        this.setState({ focus_end: true })
    }
    blur_end(e) {
        setTimeout(() => {
            this.setState({ focus_end: false })
        }, 200)

    }

    check_validation() {
    // alert()
        let eigyou_detail = this.props.eigyou_detail
        let validation = true
        let validation_text = ''
        
        validation_text = eigyou_detail.yoyaku_date ? validation_text + "" : validation_text + "対応日を選択してください。" 

        validation_text = eigyou_detail.owner_id > 0 ? validation_text + "" : validation_text + "オーナーまたは法人を選択してください。"

        validation_text = eigyou_detail.raiten_kbn_no > 0 ? validation_text + "" : validation_text + "対応区分を選択してください。"

        // validation_text = eigyou_detail.outai_naiyou_no > 0 ? validation_text + "" : validation_text + "対応内容を選択してください。"

        
        if(eigyou_detail.start_at.replace('__:__', "") || eigyou_detail.end_at.replace('__:__', "")){
            
            if(eigyou_detail.start_at == '__:__' || eigyou_detail.end_at == '__:__'){
                validation_text += "時間を正しく入力してください。"
            }else if(eigyou_detail.yoyaku_date && moment(moment(eigyou_detail.yoyaku_date).format("YYYY/MM/DD") + " " + eigyou_detail.start_at) >= moment(moment(eigyou_detail.yoyaku_date).format("YYYY/MM/DD") + " " + eigyou_detail.end_at)) {
                 validation_text += "開始時間より終了時間があとになるように入力してください。"
            }
        }


    
        if (validation_text != "") {
            validation = false
        }
        
    
        this.setState({ validation: validation, validation_text: validation_text })
    
    }

    change_datetime_select(name, value) {
        // alert(name)
        // alert(moment(value).format('HH:mm'))
        this.setState({ 
          refresh: !this.props.refresh,
          [name]: moment(value).format('HH:mm'),
          [name == "start_at" ? "start_at_err" : "end_at_err"]: false,
        })
        this.check_validation()
    }

    change_datetime(e) {
        // alert(e.target.value)
        console.log(e.target.value)
    
        let eigyou_detail = this.props.eigyou_detail

        const regex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
        // 時間チェックして
        if(regex.test(e.target.value)){ //OK
              this.setState({ 
                [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: false,
              })
    
          eigyou_detail[e.target.name] = e.target.value
          this.props.change_eigyou_detail(eigyou_detail)
    
        }else{// エラー
        // alert(e.target.name)
            eigyou_detail[e.target.name] = e.target.value
            console.log(e.target.value)
            this.props.change_eigyou_detail(eigyou_detail)

            if(e.target.value == "__:__"){
                this.setState({ 
                    [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: false,
                })
            }else{
                this.setState({ 
                    [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: true,
                })
            }

        }
    
        this.setState({ 
          refresh: !this.props.refresh,
          [e.target.name]: e.target.value,
        })
    
        this.check_validation()
    }

    onChangeTogetherId(e, value) {
        // console.log(value)
        let eigyou_detail = this.props.eigyou_detail
        if (value) {
            eigyou_detail.together_user_id = value

        } else {
            eigyou_detail.together_user_id = []
        }

        this.props.change_eigyou_detail(eigyou_detail);
        this.setState({ change: !this.state.change });

        this.check_validation()
    }

    OnChangeNewPlanTatemonoIdButton(flg, tatemono_id){

        let t_owner_plan_counts_list = [...this.props.t_owner_plan_counts_list]
        
        
        let new_plan_array = t_owner_plan_counts_list

        if(!new_plan_array){
            new_plan_array = [
                {
                    owner_id: this.props.eigyou_detail.owner_id,
                    eigyou_no: this.props.eigyou_detail.eigyou_no,
                    tm_owner_plan_id:this.state.edit_new_plan_id, 
                    tatemono_id:[], 
                    tatemono_id_minus:[]
                }
            ]
        }

        if(new_plan_array.filter(a=>a.tm_owner_plan_id == this.state.edit_new_plan_id).length == 0){
            new_plan_array.push({owner_id: this.props.eigyou_detail.owner_id, eigyou_no: this.props.eigyou_detail.eigyou_no, tm_owner_plan_id:this.state.edit_new_plan_id, tatemono_id:[], tatemono_id_minus:[]})
        }

        for(let i=0; i<new_plan_array.length; i++){
            // alert(new_plan_array[i].plan_id + "_" + this.state.edit_new_plan_id)
            if(new_plan_array[i].tm_owner_plan_id == this.state.edit_new_plan_id){
                if(flg == 0){//プラス
                    // alert("プラス")
                    let tatemono_id_list = new_plan_array[i].tatemono_id

                    if(tatemono_id_list.filter(a=>a == tatemono_id).length == 0){
                        new_plan_array[i].tatemono_id.push(tatemono_id)
                    }else{
                        new_plan_array[i].tatemono_id = tatemono_id_list.filter(a=>a != tatemono_id)
                    }
                }else{//マイナス
                    // alert("マイナス")
                    let tatemono_id_minus_list = new_plan_array[i].tatemono_id_minus

                    if(tatemono_id_minus_list.filter(a=>a == tatemono_id).length == 0){
                        new_plan_array[i].tatemono_id_minus.push(tatemono_id)
                    }else{
                        new_plan_array[i].tatemono_id_minus = tatemono_id_minus_list.filter(a=>a != tatemono_id)
                    }
                }

            }
        }


        this.props.change_t_owner_plan_counts_list(new_plan_array)

    }

    render() {
        return <>
        <DialogTitle style={{borderBottom: 'solid 1px lightgray', padding:5}}>
            <div style={{float: 'left', margin:10}}>
                {this.props.eigyou_detail.type == 1 ? <AccountBoxIcon fontSize="large" />:<AccountBoxIcon fontSize="large" />}
                <span style={{fontSize: 18, fontWeight: 'bold', verticalAlign: 'bottom'}}>営業対応</span>
            </div>
            <IconButton
                aria-label="settings"
                onClick={this.props.onClose}
                style={{float: 'right'}}
                size="large">
                <CloseIcon />
            </IconButton>

        </DialogTitle>
        <DialogContent style={{padding:10}}>
        <Grid container>
            <Grid justifyContent="space-between" item xs={3} style={{padding:5}}>

                <Grid container spacing={1}>
                    <Grid justifyContent="space-between"  xs={12} style={{ display: "flex", padding:5}}>

                        <TextField
                            id="yoyaku_date"
                            label="対応日"
                            type="date"
                            name="yoyaku_date"
                            size="small"
                            onChange={this.onChange}
                            value={moment(this.props.eigyou_detail.yoyaku_date).format("YYYY-MM-DD")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            error={!this.props.eigyou_detail.yoyaku_date}
                        />
                        
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>

                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd(EE)"
                                margin="normal"
                                id="date-picker-inline1"
                                label="対応日"
                                // showTodayButton={true}
                                style={{ float: "left", marginTop: "0", width:150 }}
                                autoOk={true}
                                InputLabelProps={{ shrink: true }}
                                name="yoyaku_date"
                                value={this.props.eigyou_detail.yoyaku_date}
                                onChange={this.onChangeDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                invalidDateMessage={"日付の形式が違います"}
                                error={!this.props.eigyou_detail.yoyaku_date}
                            /> */}

                            <div  style={{position: "relative"}}>
                                <TextField
                                    label="開始"
                                    style={{ width: 70, float: "left" }}
                                    onChange={this.change_datetime}
                                    name = "start_at"
                                    // onChange={handleChange}
                                    // id="formatted-text-mask-input"
                                    onFocus={this.focus_start}
                                    onBlur={this.blur_start}
                                    InputProps={{ inputComponent: TextMaskCustom }}
                                    value={this.props.eigyou_detail.start_at}
                                    // value={this.props.eigyou_detail.start_at}
                                    // error={this.state.start_at_err}
                                    size="small"
                                    error={this.state.start_at_err || (this.props.eigyou_detail.no_count != 1 && this.props.eigyou_detail.start_at.replace('__:__', "") == "")}
                                />
                                {this.state.focus_start ? <DateTimeComponentTime start={true} start_time={null} change_datetime_select={this.change_datetime_select} change_detail={this.props.change_eigyou_detail} yoyaku_date={moment(this.props.change_eigyou_detail.yoyaku_date).format("YYYY/MM/DD")} detail={this.props.eigyou_detail} name={"start_at"} /> : ""}
                            </div>
                            <div>–</div>
                            <div  style={{position: "relative"}}>
                                <TextField
                                    label="終了"
                                    style={{ width: 70, float: "left" }}
                                    onChange={this.change_datetime}
                                    name = "end_at"
                                    // onChange={handleChange}
                                    // id="formatted-text-mask-input"
                                    onFocus={this.focus_end}
                                    onBlur={this.blur_end}
                                    InputProps={{ inputComponent: TextMaskCustom }}
                                    // value={this.props.eigyou_detail.end_at}
                                    value={this.props.eigyou_detail.end_at}
                                    // error={this.state.end_at_err}
                                    size="small"
                                    error={this.state.end_at_err || (this.props.eigyou_detail.no_count != 1 && this.props.eigyou_detail.end_at.replace('__:__', "") == "")}
                                />
                                {this.state.focus_end ? <DateTimeComponentTime start={false} start_time={this.props.eigyou_detail.start_at} change_datetime_select={this.change_datetime_select} change_detail={this.props.change_eigyou_detail} yoyaku_date={moment(this.props.change_eigyou_detail.yoyaku_date).format("YYYY/MM/DD")} detail={this.props.eigyou_detail} name={"end_at"} /> : ""}
                            </div>



                            {/* </MuiPickersUtilsProvider> */}






                    </Grid>
                    {this.props.eigyou_detail.type == 1 ? <Grid justifyContent="space-between" item xs={6} style={{padding:5}}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-outlined-label">カウント</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                name="no_count"
                                onChange={this.onChange}
                                value={this.props.eigyou_detail.no_count}
                                // onChange={handleChange}
                                label="カウント"
                            >
                                <MenuItem value={0}>完了</MenuItem>
                                <MenuItem value={1}>予定</MenuItem>
                                <MenuItem value={2}>カウントなし</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>:<Grid justifyContent="space-between" item xs={6} style={{padding:5}}>
                            <FormControl>
                                {/* <FormLabel></FormLabel> */}
                                <FormGroup row>
                                    <FormControlLabel control={<Checkbox checked={this.props.eigyou_detail.no_count} value={this.props.eigyou_detail.no_count} onChange={this.onChange} name="no_count" />} label="予定" />
                                </FormGroup>
                            </FormControl>
                        </Grid>}

                    <Grid justifyContent="space-between" item xs={6} style={{padding:5}}>
                        <Autocomplete
                                        id="combo-box-demo"
                                        // options={this.props.users_select.map((value) => ({
                                        //     value: value.value,
                                        //     label: value.label
                                        //     }))}
                                        // getOptionLabel={(option) => option.label}
                                        options={this.props.users_select.length === 0 && this.props.eigyou_detail.tantou_user_id ? [this.props.users_select.filter(a => a.value == this.props.eigyou_detail.tantou_user_id)[0]]
                                            : this.props.users_select}
                                        getOptionLabel={(option) => option.label ? option.label : ''}
                                        fullWidth
                                        name="userId"
                                        loading={this.props.users_loading}

                                        value={this.props.eigyou_detail.tantou_user_id ? this.props.users_select.filter(a => a.value == this.props.eigyou_detail.tantou_user_id)[0] : {}}

                                        onChange={this.handleUserIdChange}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            name="userId"
                                            error={!this.props.eigyou_detail.tantou_user_id}
                                            // error={!this.props.rent_responses_editor.user_id}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>

                                                        {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            // onChange={this.handleChange}
                                            label="対応担当" />}

                                    /> 
                        {/* <TextField name="staff" onChange={this.onChange} variant="outlined" label="対応担当" /> */}
                    </Grid>
                    
                    {!this.props.eigyou_detail.eigyou_no ? <Grid justifyContent="space-between" item xs={12} style={{padding:5}}>
                        <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>対象</FormLabel>
                        <RadioGroup size="small" row name="type" onChange={this.onChange} value={parseInt(this.props.eigyou_detail.type)} >
                            <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={1} />} label="オーナー" />
                            <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={2} />} label="法人課" />
                        </RadioGroup>
                    </Grid>:""}

                    {this.props.eigyou_detail.type == 1 ? <Grid justifyContent="space-between" item xs={9} style={{padding:5}}>

                        <Autocomplete
                                        id="combo-box-demo"
                                        options={this.props.tm_owners.length === 0 && this.props.eigyou_detail.owner_id ? [this.props.tm_owners.filter(a => a.owner_id === this.props.eigyou_detail.owner_id)[0]]
                                            : this.props.tm_owners}
             
                                        getOptionLabel={(option) => option.owner_name ? option.owner_name : ''}

                                        fullWidth
                                        name="ownerId"
                                        loading={this.props.users_loading}
                                        disabled={this.props.eigyou_detail.eigyou_no}
                                        value={this.props.eigyou_detail.owner_id ? this.props.tm_owners.filter(a => a.owner_id === this.props.eigyou_detail.owner_id)[0] : {}}

                                        onChange={this.handleTmOwnerIdChange}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            size='small'
                                            variant="outlined"
                                            name="userId"
                                            error={!this.props.eigyou_detail.owner_id}
                                            // error={!this.props.rent_responses_editor.user_id}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>

                                                        {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            // onChange={this.handleChange}
                                            label="オーナー" />}

                                    />
                    </Grid>:""}
                    {this.props.eigyou_detail.type == 1 ? <Grid justifyContent="space-between" item xs={3} style={{padding:5}}>
                        <IconButton
                            style={{padding:3}}
                            disabled={!(this.props.eigyou_detail.owner_id > 0)}
                            onClick={()=>this.handleOwnerOpen(this.props.eigyou_detail.owner_id)}
                            size="large">
                            <OpenInNewIcon />
                        </IconButton>
                        {this.props.eigyou_detail.eigyou_no ? "" :<IconButton
                            style={{padding:3}}
                            onClick={(e) => { this.setState({ "modal_search": true }) }}
                            size="large">
                            <SearchIcon />
                        </IconButton>}
                    </Grid>:""}
                    {this.props.eigyou_detail.type == 2 ? <Grid justifyContent="space-between" item xs={10} style={{padding:5}}>

                        {this.props.eigyou_rent_m_corporate_all_list.length > 0 ? <Autocomplete
                            id="combo-box-demo"
                            options={this.props.eigyou_rent_m_corporate_all_list.length === 0 && this.props.eigyou_detail.owner_id ? [this.props.eigyou_rent_m_corporate_all_list.filter(a => parseInt(a.id) === parseInt(this.props.eigyou_detail.owner_id))[0]]
                                : this.props.eigyou_rent_m_corporate_all_list}
    
                            getOptionLabel={(option) => option.name ? option.name : ''}

                            fullWidth
                            name="ownerId"
                            loading={this.props.users_loading}

                            value={this.props.eigyou_detail.owner_id ? this.props.eigyou_rent_m_corporate_all_list.filter(a => parseInt(a.id) === parseInt(this.props.eigyou_detail.owner_id))[0] : {}}

                            onChange={this.handleTmOwnerIdChange2}
                            renderInput={(params) => <TextField
                                {...params}
                                variant="outlined"
                                name="userId"
                                size="small"
                                // error={!this.props.rent_responses_editor.user_id}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>

                                            {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                // onChange={this.handleChange}
                                label="法人" />}

                        />:""}
                    </Grid>:""}
                    {this.props.eigyou_detail.type == 2 ? <Grid justifyContent="space-between" item xs={2} style={{padding:5}}>
                        <IconButton
                            disabled={!(this.props.eigyou_detail.owner_id > 0)}
                            onClick={()=>this.handleCorporateOpen(this.props.eigyou_detail.owner_id)}
                            size="large">
                            <OpenInNewIcon />
                        </IconButton>
                    </Grid>:""}
                    
                    {this.props.eigyou_detail.type == 1 ? <Grid justifyContent="space-between" item xs={6} style={{padding:5}}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-outlined-label">対応区分</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                name="raiten_kbn_no"
                                onChange={this.onChange}
                                value={this.props.eigyou_detail.raiten_kbn_no}
                                // onChange={handleChange}
                                label="対応区分"
                                error={!this.props.eigyou_detail.raiten_kbn_no}
                            >
                                <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={1}>アポ訪問</MenuItem>
                                <MenuItem value={7}>アポ来店</MenuItem>
                                <MenuItem value={3}>訪問</MenuItem>
                                <MenuItem value={2}>来店</MenuItem>
                                <MenuItem value={4}>電話</MenuItem>
                                <MenuItem value={5}>メール</MenuItem>
                                <MenuItem value={8}>LINE</MenuItem>
                                <MenuItem value={6}>FAX</MenuItem>
                                <MenuItem value={9}>現場調査</MenuItem>
                            </Select>
                        </FormControl></Grid>:""}
                    {this.props.eigyou_detail.type == 1 ? <Grid justifyContent="space-between" item xs={6} style={{padding:5}}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-outlined-label">対応種別</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                name="outai_shubetsu_no"
                                onChange={this.onChange}
                                value={this.props.eigyou_detail.outai_shubetsu_no}
                                // onChange={handleChange}
                                label="対応種別"
                            >
                                <MenuItem value={1}>単独</MenuItem>
                                <MenuItem value={2}>店舗同行</MenuItem>
                                <MenuItem value={3}>上司同行</MenuItem>
                                <MenuItem value={4}>その他</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>:<Grid justifyContent="space-between" item xs={6} style={{padding:5}}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-outlined-label">対応区分</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                name="raiten_kbn_no"
                                onChange={this.onChange}
                                value={this.props.eigyou_detail.raiten_kbn_no}
                                // onChange={handleChange}
                                label="対応区分"
                            >
                                <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={1}>企業訪問</MenuItem>
                                <MenuItem value={2}>電話</MenuItem>
                                <MenuItem value={3}>メール</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>}
                    {/* {this.props.eigyou_detail.type == 1 ? <Grid justify="space-between" item xs={12} style={{paddingBottom:20}}>
                        <List component="nav" style={{height:200,overflow: 'auto'}}>
                        {this.props.tm_owner_outai_naiyou_list.sort((a, b) => {return a.checked < b.checked ? -1 : 1;}).map((value, index) => {
                                return <ListItem button style={{padding:0, backgroundColor:this.props.eigyou_detail.outai_naiyou_id && this.props.eigyou_detail.outai_naiyou_id.split(',').includes(String(value.value)) ? "#e4e3e3": ""}} onClick={()=>this.OnChangeOutaiNaiyouId(value.value)}>
                                    <ListItemIcon>
                                        <Checkbox checked={this.props.eigyou_detail.outai_naiyou_id ? this.props.eigyou_detail.outai_naiyou_id.split(',').includes(String(value.value)) : false} value={value.value} name="outai_naiyou_id"  style={{padding:0}} />
                                    </ListItemIcon>
                                    <ListItemText primary={value.label} />
                                </ListItem>
                        })}


                        </List>
                    </Grid>:""} */}
                    {this.props.eigyou_detail.type == 1 ? <Grid justifyContent="space-between" item xs={12} style={{padding:5}}>
                        {this.props.users && this.props.eigyou_detail.together_user_id ? <>
                            <Autocomplete
                                multiple
                                // options={top100Films}
                                // options={this.props.users_select.length === 0 && this.props.eigyou_detail.together_user_id ? [this.props.users_select.filter(a => a.value == this.props.eigyou_detail.together_user_id)[0]]
                                //     : this.props.users_select}
                                options={this.state.kanri_only ? this.props.users.filter(a=>a.am_section_id == 5 || a.am_section_id == 7 || a.am_section_id == 34 || a.am_section_id == 45): this.props.users}
                                getOptionLabel={(option) => option.user_name_all}
                                // defaultValue={[top100Films[1]]}
                                filterSelectedOptions
                                onChange={this.onChangeTogetherId}
                                value={this.props.eigyou_detail.together_user_id}
                                size="small"
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="同行者"
                                    variant='standard'
                                    // placeholder="同行者"
                                    error={(this.props.eigyou_detail.outai_shubetsu_no == 2 || this.props.eigyou_detail.outai_shubetsu_no == 3) && this.props.eigyou_detail.together_user_id.length == 0}
                                />
                                )}
                            />
                            <FormControlLabel style={{width:"100%", marginBottom:0, marginLeft:0}} control={<Checkbox color="primary" checked={this.state.kanri_only} onChange={this.handleKanriOnly} name="kanri_only" />} label="管理部のみ表示" />
                        </>
                        :""}
                    </Grid>:""}
                    {this.props.eigyou_detail.type == 1 ? <Grid justifyContent="space-between" item xs={12} style={{marginBottom:10, padding:5}}>
                        {this.props.eigyou_detail.outai_naiyou_id ? <>
                            <Autocomplete
                                multiple
                                options={this.props.tm_owner_outai_naiyou_list}
                                getOptionLabel={(option) => option.label}
                                // defaultValue={[top100Films[1]]}
                                filterSelectedOptions
                                onChange={this.OnChangeOutaiNaiyouId}
                                value={this.props.eigyou_detail.outai_naiyou_id}
                                size="small"
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="応対内容"
                                    variant='standard'
                                    error={this.props.eigyou_detail.type == 1 && this.props.eigyou_detail.outai_naiyou_id.length == 0}
                                    // placeholder="同行者"
                                />
                                )}
                            />
                        </>
                        :""}
                    </Grid>:""}
                    {this.props.eigyou_detail.type == 1 ? 

                        <>
                        <Grid justify="space-between" item xs={12} style={{marginBottom:10, height:300, overflow: 'auto'}}>
                            {this.props.tm_owner_plan_list.map(function (value, index) {

                            if(value.is_deleted == 0 || (this.props.t_owner_plan_counts_list && this.props.t_owner_plan_counts_list.filter(a=>a.tm_owner_plan_id == value.id).length > 0)){
                                return <div >



                                <List component="nav" className='bukken_list' aria-label="secondary mailbox folder" >
                                    <ListItem 
                                        secondaryAction={
                                            <Button size="small" edge="end" disabled={value.is_deleted} onClick = {(e) => { this.setState({ "new_plan_disp": true, edit_new_plan_id: value.id}) }} variant="contained" color="primary">物件選択</Button>
                                            }
                                        style={{padding:0}}
                                    >
                                        <ListItemText primary={
                                        <>
                                            {/* <IconButton disabled={value.is_deleted} onClick = {(e) => { this.setState({ "new_plan_disp": true, edit_new_plan_id: value.id}) }} style={{padding:6, marginRight:3}}><AddCircleIcon style={{color:"#3f51b5"}} /></IconButton>
                                            <IconButton disabled={value.is_deleted} onClick = {(e) => { this.setState({ "new_plan_disp_remove": true, edit_new_plan_id: value.id}) }} style={{padding:6, marginRight:3}}><RemoveCircleIcon style={{color:"#f50057"}} /></IconButton> */}
                                            <span style={{fontSize:16}}>{value.name}</span>
                                        </>} />
                                    </ListItem>

                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.props.t_owner_plan_counts_list && this.props.t_owner_plan_counts_list.length > 0 ? this.props.t_owner_plan_counts_list.filter(a=>a.tm_owner_plan_id == value.id).map(function (value2, index2) {
                                                    return value2.tatemono_id.length > 0 ? value2.tatemono_id.map(function (value3, index3) {
                                                        return <ListItem style={{padding:0, paddingLeft:20}}>
                                                            <ListItemText primary={<>{this.props.tm_tatemono_list_master.filter(a=>a.Tatemono_Id == value3).length > 0 ? <>
                                                                {/* <AddCircleOutlineIcon color='primary' /> */}
                                                                {this.props.tm_tatemono_list_master.filter(a=>a.Tatemono_Id == value3)[0].Tatemono_Name}</>: ""}</>} />
                                                        </ListItem>
                                                    },this):""
                                                },this):""}
                                            {/* {this.props.t_owner_plan_counts_list && this.props.t_owner_plan_counts_list.length > 0 ? this.props.t_owner_plan_counts_list.filter(a=>a.tm_owner_plan_id == value.id).map(function (value2, index2) {
                                                    return value2.tatemono_id_minus.length > 0 ? value2.tatemono_id_minus.map(function (value3, index3) {
                                                        return <ListItem style={{padding:0, paddingLeft:20}}>
                                                            <ListItemText primary={<>{this.props.tm_tatemono_list_master.filter(a=>a.Tatemono_Id == value3).length > 0 ? <><RemoveCircleOutlineIcon style={{color:"#f50057"}} />{this.props.tm_tatemono_list_master.filter(a=>a.Tatemono_Id == value3)[0].Tatemono_Name}</>: ""}</>} />
                                                        </ListItem>
                                                    },this):""
                                                },this):""} */}
                                        </List>
                                    </Collapse>
    
                                </List>
                                    </div>
                            }else{
                                return ""
                            }
                            },this)}

                        </Grid>
                        
                        </>
                    :""}
                    
                    {this.props.eigyou_detail.type == 2 ? <Grid justifyContent="space-between" item xs={6} style={{padding:5}}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-outlined-label">対応内容</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                name="outai_naiyou_no"
                                onChange={this.onChange}
                                value={this.props.eigyou_detail.outai_naiyou_no}
                                // onChange={handleChange}
                                label="対応内容"
                            >
                                <MenuItem value={1}>提案</MenuItem>
                                <MenuItem value={2}>定期</MenuItem>
                                <MenuItem value={3}>クレーム</MenuItem>
                                <MenuItem value={4}>その他</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>:""}


                    {/* {this.props.eigyou_detail.type == 1 && this.props.eigyou_detail.outai_naiyou_no === 1 ? <Grid justify="space-between" item xs={12}>
                        <FormControl>
                            <FormLabel>提案内容</FormLabel>
                            <FormGroup row>

                                <FormControlLabel control={<Checkbox checked={this.props.eigyou_detail.suggestions ? this.props.eigyou_detail.suggestions.split(',').includes("貸方") : false} value={"貸方"} onChange={this.onChange} name="suggestions" />} label="貸方" />
                                <FormControlLabel control={<Checkbox checked={this.props.eigyou_detail.suggestions ? this.props.eigyou_detail.suggestions.split(',').includes("金額") : false} value={"金額"} onChange={this.onChange} name="suggestions" />} label="金額" />
                                <FormControlLabel control={<Checkbox checked={this.props.eigyou_detail.suggestions ? this.props.eigyou_detail.suggestions.split(',').includes("工事") : false} value={"工事"} onChange={this.onChange} name="suggestions" />} label="工事" />
                                <FormControlLabel control={<Checkbox checked={this.props.eigyou_detail.suggestions ? this.props.eigyou_detail.suggestions.split(',').includes("共用部メンテ") : false} value={"共用部メンテ"} onChange={this.onChange} name="suggestions" />} label="共用部メンテ" />
                                <FormControlLabel control={<Checkbox checked={this.props.eigyou_detail.suggestions ? this.props.eigyou_detail.suggestions.split(',').includes("クレーム") : false} value={"クレーム"} onChange={this.onChange} name="suggestions" />} label="クレーム" />
                                <FormControlLabel control={<Checkbox checked={this.props.eigyou_detail.suggestions ? this.props.eigyou_detail.suggestions.split(',').includes("その他") : false} value={"その他"} onChange={this.onChange} name="suggestions" />} label="その他" />
                            </FormGroup>
                        </FormControl>
                    </Grid>:""} */}
                    {/* {this.props.eigyou_detail.type == 1 ? <Grid justify="space-between" item xs={4}>
                        <FormControl variant="outlined" style={{width: 200}}>
                            <InputLabel id="demo-simple-select-outlined-label">対応種別</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                name="outai_shubetsu_no"
                                onChange={this.onChange}
                                value={this.props.eigyou_detail.outai_shubetsu_no}
                                // onChange={handleChange}
                                label="対応種別"
                            >
                                <MenuItem value={1}>単独</MenuItem>
                                <MenuItem value={2}>店舗同行</MenuItem>
                                <MenuItem value={3}>上司同行</MenuItem>
                                <MenuItem value={4}>その他</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>:""} */}
                    {this.props.eigyou_detail.type == 2 ? <Grid justifyContent="space-between" item xs={12} style={{padding:5}}>
                            <TextField 
                                size="small"
                                name="staff" 
                                onChange={this.onChange} 
                                variant="outlined" 
                                label="担当者" 
                                style={{width: "100%"}} 
                                value={this.props.eigyou_detail.staff}
                            />
                    </Grid>:""}
            
                </Grid>
            </Grid>
            <Grid justifyContent="space-between" item xs={4} style={{padding:5}}>

                <Grid container spacing={1}>
                    {this.props.eigyou_detail.type == 1 ? <Grid justifyContent="space-between" item xs={12} style={{padding:5}}>
                            <TextField 
                                size="small"
                                name="responder" 
                                onChange={this.onChange} 
                                variant="outlined" 
                                label="面談者" 
                                style={{width: "100%"}} 
                                value={this.props.eigyou_detail.responder}
                                error={!this.props.eigyou_detail.responder}
                            />
                    </Grid>:""}
                    <Grid justifyContent="space-between" item xs={12} style={{padding:5}}>
                            <TextField 
                                name="naiyou" 
                                onChange={this.onChange} 
                                variant="outlined" 
                                // label="内容" 
                                label={this.props.eigyou_detail.type == 1 ? (this.props.eigyou_detail.komatte || this.props.eigyou_detail.promise) ? "交渉内容"  : "内容"  : "内容" }
                                multiline 
                                // rows={this.props.eigyou_detail.type == 1 ? 7 : 25} 
                                rows={this.props.eigyou_detail.type == 1 ? (this.props.eigyou_detail.komatte || this.props.eigyou_detail.promise) ? 7 : 25 : 25} 
                                
                                style={{width: "100%"}} 
                                value={this.props.eigyou_detail.naiyou}
                                error={!this.props.eigyou_detail.naiyou}
                            />
                    </Grid>
                    {this.props.eigyou_detail.type == 1 && (this.props.eigyou_detail.komatte || this.props.eigyou_detail.promise) ? <Grid justifyContent="space-between" item xs={12} style={{padding:5}}>
                            <TextField 
                                name="komatte" 
                                onChange={this.onChange} 
                                variant="outlined" 
                                label="交渉結果" 
                                multiline 
                                rows={7} 
                                style={{width: "100%"}}
                                value={this.props.eigyou_detail.komatte}
                            />
                    </Grid>:""}
                    {this.props.eigyou_detail.type == 1 && (this.props.eigyou_detail.komatte || this.props.eigyou_detail.promise) ? <Grid justifyContent="space-between" item xs={12} style={{padding:5}}>
                            <TextField 
                                name="promise" 
                                onChange={this.onChange} 
                                variant="outlined" 
                                label="約束" 
                                multiline 
                                rows={7} 
                                style={{width: "100%"}}
                                value={this.props.eigyou_detail.promise}
                            />
                    </Grid>:""}
                </Grid>
            </Grid>
            <Grid justifyContent="space-between" item xs={3} style={{padding:5}}>
                {this.props.eigyou_detail.type == 1 && this.props.eigyou_detail.eigyou_no ?<div className='example'>
                    <div  style={{ width: "100%", height: window.innerHeight-200-20, background: "#FFF" }}>
                        <Meetings meeting_category={1} meeting_details_disp={true} owner_id={this.props.eigyou_detail.owner_id} eigyou_no={this.props.eigyou_detail.eigyou_no} />
                    </div>
                </div>:""}
            </Grid>
            <Grid justifyContent="space-between" item xs={2} style={{padding:5}}>
                <Grid container spacing={1}>
                
                {this.props.eigyou_detail.type == 1 ? <Grid justifyContent="space-between" item xs={12} style={{padding:5}}>
                            <InputLabel style={{marginBottom:0}}>所有物件</InputLabel>
                            {this.props.eigyou_owner_room && this.props.eigyou_owner_room.length > 0 ? <List component="nav" aria-label="secondary mailbox folder" style={{height:450, marginBottom:5, padding:0, position: 'relative', overflow: 'auto',}}>
                                {this.props.eigyou_owner_room.map(function(value, key){
                                    return (
                                        <ListItem style={{padding:0}}
                                            // selected={selectedIndex === 2}
                                            // onClick={(event) => handleListItemClick(event, 2)}
                                        >
                                            {/* <ListItemText primary={value.tatemono_name} /> */}
                                            <ListItemText primary={<><IconButton
                                                style={{padding:1, marginRight:3}}
                                                onClick={(e) => {this.handleOpenBuilding(value.tatemono_id, value.is_parking || value.tatemono_id.indexOf('SysP') != -1 ? 1 : 0)}}
                                                size="large"><OpenInNewIcon /></IconButton>{value.tatemono_name}</>} />
                                        </ListItem>
                                    );
                                },this)}
                            </List>
                            : ""}
                    </Grid>:""}
                </Grid>
            </Grid>
        </Grid>
            </DialogContent>
            <DialogActions style={{borderTop: 'solid 1px lightgray'}}>
            <Grid container spacing={1}>
                <Grid justifyContent="space-between" item xs={2}>
                    {this.props.eigyou_detail.type == 1 && this.props.eigyou_detail.eigyou_no ? 
                    <Tooltip style={{fontSize: "12px", color:"black", backgroundColor: "white", border:"1px solid #6c757d", padding:"1px 3px", marginTop:"-5px"}} title=
                        {this.props.eigyou_detail.confirmers ? this.props.eigyou_detail.confirmers.split(',').map(function (item, key) {
                            return (
                                item != "" ? 
                              <div>{this.props.users.filter(a=>a.id == parseInt(item))[0].nickname}</div>
                              :
                              ""
                            )
                          }, this) : ""
                        }
                        
                    >
                    <Fab onClick={()=>this.props.update_eigyou_detail_confirmers(this.props.user_details.id)} variant="extended" size="small" style={{backgroundColor:"white", border:"grey"}} color="primary" aria-label="add">
                        <CheckCircleIcon style={{color:this.props.eigyou_detail.confirmers ? "#f50057" : "grey"}} sx={{ mr: 1 }} /><span style={{color:"black"}}>
                            {this.props.eigyou_detail.confirmers ? this.props.eigyou_detail.confirmers.split(",").length: ""}</span>
                    </Fab></Tooltip>:""}
                    
                </Grid>
                <Grid justifyContent="space-between" item xs={10}>
                    {!(this.props.eigyou_detail.yoyaku_date) ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>対応日を選択してください。</Alert> : 
                    !(this.props.eigyou_detail.owner_id > 0) ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>オーナーまたは法人を選択してください。</Alert> : 
                    !(this.props.eigyou_detail.tantou_user_id) ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>対応担当を選択してください。</Alert> : 
                    !(this.props.eigyou_detail.raiten_kbn_no > 0) ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>対応区分を選択してください。</Alert> : 
                    
                    (this.props.eigyou_detail.outai_shubetsu_no == 1 && this.props.eigyou_detail.together_user_id.length > 0) ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>対応種別が単独の場合、同行者は選択できません。</Alert> :
                    ((this.props.eigyou_detail.outai_shubetsu_no == 2 || this.props.eigyou_detail.outai_shubetsu_no == 3) && this.props.eigyou_detail.together_user_id.length == 0) ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>同行者を選択してください。</Alert> :
                    (this.props.eigyou_detail.type == 1 && this.props.eigyou_detail.outai_naiyou_id.length == 0) ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>応対内容を選択してください。</Alert> :
                    

                    (this.props.eigyou_detail.type == 1 && this.props.eigyou_detail.responder == "") ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>面談者を入力してください。</Alert> : 
                    (this.props.eigyou_detail.naiyou == "") ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>内容を入力してください。</Alert> : 
                    !(this.props.eigyou_detail.no_count == 1) && (this.props.eigyou_detail.start_at == '__:__' || this.props.eigyou_detail.end_at == '__:__' || this.props.eigyou_detail.start_at == '' || this.props.eigyou_detail.end_at == '') ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>時間を入力してください。</Alert> :

                    ((this.props.eigyou_detail.start_at.replace('__:__', "") || this.props.eigyou_detail.end_at.replace('__:__', "")) && (this.props.eigyou_detail.start_at == '__:__' || this.props.eigyou_detail.end_at == '__:__') ) ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>時間を正しく入力してください。</Alert> :
                    ((this.props.eigyou_detail.start_at.replace('__:__', "") || this.props.eigyou_detail.end_at.replace('__:__', "")) && (this.props.eigyou_detail.yoyaku_date && moment(moment(this.props.eigyou_detail.yoyaku_date).format("YYYY/MM/DD") + " " + this.props.eigyou_detail.start_at) >= moment(moment(this.props.eigyou_detail.yoyaku_date).format("YYYY/MM/DD") + " " + this.props.eigyou_detail.end_at)) ) ?<Alert severity="error" style={{ margin: 5, width:"100%" }}>開始時間より終了時間があとになるように入力してください。</Alert> :

                        
                    this.state.start_at_err || this.state.end_at_err ? <Alert severity="error" style={{ margin: 5, width:"100%" }}>開始時間より終了時間があとになるように入力してください。</Alert>
                    :
                    <div row>
                        <div className="col-12" style={{textAlign: 'right', marginBottom: 10}}>
                            <Button variant="contained" color="primary" style={{marginRight: 10}} onClick={() => this.handleUpdate(this.props.eigyou_detail.eigyou_no, 0)} startIcon={<SaveAltIcon/>}>登録</Button>
                            {this.props.eigyou_detail.eigyou_no ? <Button variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }} startIcon={<DeleteForeverIcon/>}>削除</Button>:""}
                        </div>
                    </div>}
                </Grid>

                </Grid>
            </DialogActions>



            <Dialog onClose={(e) => { this.setState({ "modal_del": false }) }} aria-labelledby="simple-dialog-title" open={this.state.modal_del}>
                <DialogContent>
                    <span>本当に削除してよろしいですか</span>
                </DialogContent>
                <DialogActions>
                        <div row>
                            <div className="col-12" style={{textAlign: 'right', marginBottom: 10}}>
                                <Button variant="contained" color="secondary" style={{marginRight: 10}} onClick={() => this.handleDelete()} startIcon={<DeleteForeverIcon/>}>削除</Button>

                                <Button variant="contained" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "modal_del": false }) }} startIcon={<HighlightOffIcon/>}>閉じる</Button>
                            </div>
                        </div>
                </DialogActions>
            </Dialog>

            <Dialog onClose={this.handleOwnerSearchClose} aria-labelledby="simple-dialog-title" maxWidth="md" open={this.state.modal_search}>
                <DialogContent style={{padding:0}}>
                    <OwnerSearchIndex handleOwnerSearchClose={this.handleOwnerSearchClose} />
                </DialogContent>
                {/* <DialogActions>
                        <div row>
                            <div className="col-12" style={{textAlign: 'right', marginBottom: 10}}>
                                <Button variant="contained" color="secondary" style={{marginRight: 10}} onClick={() => this.handleDelete(this.props.eigyou_detail.eigyou_no)} startIcon={<DeleteForeverIcon/>}>削除</Button>

                                <Button variant="contained" color="" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "modal_search": false }) }} startIcon={<HighlightOffIcon/>}>閉じる</Button>
                            </div>
                        </div>
                </DialogActions> */}
            </Dialog>

            <Dialog onClose={(e) => { this.setState({ "new_plan_disp": false, edit_new_plan_id:0 }) }} aria-labelledby="simple-dialog-title" maxWidth="md" open={this.state.new_plan_disp}>
                <DialogTitle>{this.props.tm_owner_plan_list.filter(a=>a.id == this.state.edit_new_plan_id).length > 0 ? this.props.tm_owner_plan_list.filter(a=>a.id == this.state.edit_new_plan_id)[0].name:""}（プラス）</DialogTitle>
                <DialogContent style={{padding:0}}>
                    <List component="nav" className='bukken_list' aria-label="secondary mailbox folder" style={{height:500, overflow: 'auto',}}>

                        {this.props.eigyou_owner_room ? this.props.eigyou_owner_room.filter(a=>!(a.is_parking || a.tatemono_id.indexOf('SysP') != -1)).map(function(value, key){
                            return <ListItem style={{padding:0}}
                            >

                            <ListItemButton onClick={()=>this.OnChangeNewPlanTatemonoIdButton(0, value.tatemono_id)}  dense>
                            <ListItemIcon>
                                <Checkbox
                                edge="start"
                                checked={this.props.t_owner_plan_counts_list && this.props.t_owner_plan_counts_list.length > 0 ? this.props.t_owner_plan_counts_list.filter(a=>a.tm_owner_plan_id == this.state.edit_new_plan_id).length > 0 ? this.props.t_owner_plan_counts_list.filter(a=>a.tm_owner_plan_id == this.state.edit_new_plan_id)[0].tatemono_id.filter(a=>a == value.tatemono_id).length > 0 :false : false}
                                // onClick={this.OnChangeNewPlanTatemonoId}
                                // tabIndex={-1}
                                disableRipple
                                color='primary'
                                name={"new_plan_tatemono_id"+value.tatemono_id}
                                // inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText primary={value.tatemono_name}/>
                            </ListItemButton>
                            </ListItem>
                        },this):""}
                    </List>
                </DialogContent>
            </Dialog>
            
            <Dialog onClose={(e) => { this.setState({ "new_plan_disp_remove": false, edit_new_plan_id:0 }) }} aria-labelledby="simple-dialog-title" maxWidth="md" open={this.state.new_plan_disp_remove}>
                <DialogTitle>{this.props.tm_owner_plan_list.filter(a=>a.id == this.state.edit_new_plan_id).length > 0 ? this.props.tm_owner_plan_list.filter(a=>a.id == this.state.edit_new_plan_id)[0].name:""}（マイナス）</DialogTitle>
                <DialogContent style={{padding:0}}>
                    <List component="nav" className='bukken_list' aria-label="secondary mailbox folder" style={{height:500, overflow: 'auto',}}>

                        {this.props.eigyou_owner_room ? this.props.eigyou_owner_room.filter(a=>!(a.is_parking || a.tatemono_id.indexOf('SysP') != -1)).map(function(value, key){
                            return <ListItem style={{padding:0}}
                            >

                            <ListItemButton onClick={()=>this.OnChangeNewPlanTatemonoIdButton(1, value.tatemono_id)}  dense>
                            <ListItemIcon>
                                <Checkbox
                                edge="start"
                                checked={this.props.t_owner_plan_counts_list && this.props.t_owner_plan_counts_list.length > 0 ? this.props.t_owner_plan_counts_list.filter(a=>a.tm_owner_plan_id == this.state.edit_new_plan_id).length > 0 ? this.props.t_owner_plan_counts_list.filter(a=>a.tm_owner_plan_id == this.state.edit_new_plan_id)[0].tatemono_id_minus.filter(a=>a == value.tatemono_id).length > 0 :false : false}
                                // onClick={this.OnChangeNewPlanTatemonoId}
                                // tabIndex={-1}
                                disableRipple
                                style={{color:"#f50057"}}
                                name={"new_plan_tatemono_id"+value.tatemono_id}
                                // inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText primary={value.tatemono_name}/>
                            </ListItemButton>
                            </ListItem>
                        },this):""}
                    </List>
                </DialogContent>
            </Dialog>


            </>;
    }
}

const mapStateToProps = (state) => {
    return {
        eigyou_detail: state.calendar.eigyou_detail,
        eigyou_owner_room: state.calendar.eigyou_owner_room,

        // tm_owner_list: state.ownerlist.tm_owner_list,
        // tm_owner_all_list: state.owner.tm_owner_all_list,
        tm_owners: state.calendar.tm_owners,
        users_select: state.masterReducer.users_select,
        users: state.calendar.users,
        user_details: state.masterReducer.user_details,
        eigyou_rent_m_corporate_all_list: state.calendar.eigyou_rent_m_corporate_all_list,
        tm_owner_outai_naiyou_list: state.calendar.tm_owner_outai_naiyou_list,

        tm_owner_plan_list: state.calendar.tm_owner_plan_list,
        t_owner_plan_counts_list: state.calendar.t_owner_plan_counts_list,
        tm_tatemono_list_master: state.calendar.tm_tatemono_list_master,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_eigyou_detail(state) {
            dispatch(CalendarSaga.change_eigyou_detail(state))
        },
        update_eigyou_detail(state) {
            dispatch(CalendarSaga.update_eigyou_detail(state))
        },
        insert_eigyou_detail(state) {
            dispatch(CalendarSaga.insert_eigyou_detail(state))
        },
        delete_eigyou_detail(state) {
            dispatch(CalendarSaga.delete_eigyou_detail(state))
        },
        get_eigyou_owner_room(state) {
            dispatch(CalendarSaga.get_eigyou_owner_room(state))
        },
        update_eigyou_detail_confirmers(state) {
            dispatch(CalendarSaga.update_eigyou_detail_confirmers(state))
        },
        change_t_owner_plan_counts_list(state) {
            dispatch(CalendarSaga.change_t_owner_plan_counts_list(state))
        },

    };
} 

// export default AddressGrid;
export default connect(mapStateToProps, mapDispatchToProps)(EigyoDialog);

