import React, { Component } from 'react';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))


class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <div>
    
                        <table>
                            <colgroup style={{ width: "40%" }} />
                            <colgroup style={{ width: "60%" }} />
                            {/* <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} /> */}
                            <tbody>
                                <tr >
                                    <th>代表者</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.president}{this.props.rent_m_corporates.position ? "(" + this.props.rent_m_corporates.position + ")" : ""}</td>
                                </tr>
                                <tr >
                                    <th>資本金</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.capital}</td>
                                </tr>
                                <tr >
                                    <th>設立日</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.establish}</td>
                                </tr>
                                <tr >
                                    <th>創業日</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.start_of_business}</td>
                                </tr>
                                <tr >
                                    <th>本社所在地</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.post}{this.props.rent_m_corporates.add1}{this.props.rent_m_corporates.add2}</td>
                                </tr>
                                <tr >
                                    <th>本社連絡先</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.tel1}</td>
                                </tr>
                                <tr >
                                    <th>事業所数</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.office}</td>
                                </tr>
                                <tr >
                                    <th>従業員数</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.employee}</td>
                                </tr>
                                <tr >
                                    <th>HP</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.url}</td>
                                </tr>
                                <tr >
                                    <th>事業内容</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.vision}</td>
                                </tr>
                            </tbody>
                        </table>

                </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates: state.corporation.rent_m_corporates,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




