import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as Mail from './Saga/Mail';

import MailDetailFilesComponent from './MailDetailFilesComponent';
import Loading from '../Layout/LoadingComponent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


class MailDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: "",
            subject: "",
            from: "",
            contents: "",

            tab: 0,
        };
        this.change_mail_new = this.change_mail_new.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentWillMount() {
        console.log(this.props.mailid)


        this.props.get_mail_details(this.props.mailid)




    }



    change_mail_new() {
        let maildetail = {};
        // console.log(this.props.customerdetail)

        maildetail.toMail = this.props.mail_details.fromMail;
        maildetail.toMailall = this.props.mail_details.fromMail;
        if (this.props.mail_details.mailAccountId === 6) {
            maildetail.fromMail = "sun@global-center.co.jp";
            maildetail.fromName = "グローバルセンターサンフラワー店";
            maildetail.mailAccountId = 6;
        } else if (this.props.mail_details.mailAccountId === 11) {
            maildetail.fromMail = "cd@global-center.co.jp";
            maildetail.fromName = "グローバルセンター中央通り店";
            maildetail.mailAccountId = 11;
        } else if (this.props.mail_details.mailAccountId === 1) {
            maildetail.fromMail = "info@global-center.co.jp";
            maildetail.fromName = "アパマンショップ高松番町店";
            maildetail.mailAccountId = 1;
        } else if (this.props.mail_details.mailAccountId === 2) {
            maildetail.fromMail = "rainbow@global-center.co.jp";
            maildetail.fromName = "アパマンショップレインボーロード店";
            maildetail.mailAccountId = 2;
        } else if (this.props.mail_details.mailAccountId === 3) {
            maildetail.fromMail = "takamatsu@global-center.co.jp";
            maildetail.fromName = "アパマンショップ高松駅前店";
            maildetail.mailAccountId = 3;
        } else if (this.props.mail_details.mailAccountId === 4) {
            maildetail.fromMail = "east@global-center.co.jp";
            maildetail.fromName = "アパマンショップ高松東店";
            maildetail.mailAccountId = 4;
        } else if (this.props.mail_details.mailAccountId === 5) {
            maildetail.fromMail = "park@global-center.co.jp";
            maildetail.fromName = "アパマンショップ中央公園店";
            maildetail.mailAccountId = 5;
        } else if (this.props.mail_details.mailAccountId === 10) {
            maildetail.fromMail = "ooshika@global-center.co.jp";
            maildetail.fromName = "グローバルセンター";
            maildetail.mailAccountId = 10;
        } else {
            maildetail.fromMail = "web@global-center.co.jp";
            maildetail.fromName = "グローバルセンター";
            maildetail.mailAccountId = 12;
        }










        //     maildetail.toMail = this.props.mail_details.fromMail;
        //     maildetail.toMailall = this.props.mail_details.fromMail;
        //     if(this.props.mail_details.mailAccountId===6){
        //     maildetail.fromMail = "sun@global-center.co.jp";
        //     maildetail.fromName = "sun@global-center.co.jp";
        //     maildetail.mailAccountId = 6;
        // }else if(this.props.mail_details.mailAccountId===11){
        //     maildetail.fromMail = "cd@global-center.co.jp";
        //     maildetail.fromName = "cd@global-center.co.jp";
        //     maildetail.mailAccountId = 11;
        // }else{
        //     maildetail.fromMail = "web@global-center.co.jp";
        //     maildetail.fromName = "web@global-center.co.jp";
        //     maildetail.mailAccountId = 10;
        // }


        maildetail.isSended = 1;//下書き
        maildetail.rentResponseId = this.props.rent_response_id;
        maildetail.returnedMailId = this.props.mailid;
        this.props.toggle();
        this.props.change_mail_new(maildetail)
        this.props.get_response_mails(this.props.rent_response_id)

    }


    handleTabChange = (event, newValue) => {
    
        this.setState({ tab: newValue });
    };









    render() {
        let today = new Date(this.props.mail_details.date);
        let date = today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let body_html = this.props.mail_details.htmlUtf8 == null ? this.props.mail_details.bodyUtf8 : this.props.mail_details.htmlUtf8;

        // let body_html = this.props.mail_details.htmlUtf8;
        let body_text = this.props.mail_details.bodyUtf8
        let opendate = "";
        let opentime = "";
        if (this.props.mail_details.openedAt !== null) {
            let openAt = new Date(this.props.mail_details.openedAt);
            opendate = openAt.getFullYear() + "/" + (openAt.getMonth() + 1) + "/" + openAt.getDate();
            opentime = openAt.getHours() + ":" + openAt.getMinutes() + ":" + openAt.getSeconds();
        }
        if (body_html != null && this.props.mail_details.htmlUtf8 == null) {
            body_html = body_html.replace(/\r\n/g, "<br />").replace(/(\n|\r)/g, "<br />");
        }
        if(body_text){
            body_text = body_text.replace(/\r\n/g, "<br />").replace(/(\n|\r)/g, "<br />");
        }
        console.log(this.props.mail_details.bodyUtf8);

        return (
            <div style={{}}>
                {this.props.get_mail_details_loading === 1 ? <Loading /> : <div style={{ borderBottom: "1px solid #dcdcdc", padding: 15 }}>
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>{this.props.mail_details.subject}</div>
                    <div>{date} {time} 　　　 {opendate !== "" ? <span className="badge badge-secondary">既　読</span> : ""} {opendate + opentime}</div>
                    <div>From【{this.props.mail_details.fromName}】 {(this.props.mail_details.isSended === 0 && this.props.mail_details.requestRentMMediaId === null) ? <button className="btn btn-sm btn-warning" style={{ width: "50px", marginTop: "4px" }} onClick={this.change_mail_new}>返信</button> : ""}　　To【{this.props.mail_details.toMail}】{this.props.mail_details.cc ? "Cc【" + this.props.mail_details.cc + "】" : ""}　　{this.props.mail_details.sendType==0?<span className="badge badge-dark">HTML形式</span>:this.props.mail_details.sendType==1?<span className="badge badge-dark">テキスト形式</span>:<span className="badge badge-dark">HTML・テキスト形式</span>}</div>


                </div>}

                <MailDetailFilesComponent mailid={this.props.mailid} />


                {/* {this.props.get_mail_details_loading === 1 ? <Loading /> : <div style={{ padding: 15, overflow: "auto", height: 650 }} dangerouslySetInnerHTML={{ __html: body }}>

                </div>} */}
                {parseInt(this.props.mail_details.sendType) == 2 ? <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={this.state.tab} onChange={this.handleTabChange} aria-label="basic tabs example"

                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {parseInt(this.props.mail_details.sendType) == 0 || parseInt(this.props.mail_details.sendType) == 2 ? <Tab label="HTML形式" value={0} /> :null}
                        {parseInt(this.props.mail_details.sendType) == 1 || parseInt(this.props.mail_details.sendType) == 2 ? <Tab label="テキスト形式" value={1} />:null}

                    </Tabs>
                </Box>:""}

                {parseInt(this.props.mail_details.sendType) == 0 ? 
                <iframe
                        style={{ paddingLeft: 15, width: "100%", height: 650 }}
                        srcdoc={body_html}
                        frameborder={0}
                        // src="srcdocが使えなかった場合に表示するURL"
                    ></iframe>:""}

                {parseInt(this.props.mail_details.sendType) == 1 ? <iframe
                        style={{ paddingLeft: 15, width: "100%", height: 650 }}
                        srcdoc={body_text}
                        frameborder={0}
                        // src="srcdocが使えなかった場合に表示するURL"
                    ></iframe>:""}

                {parseInt(this.props.mail_details.sendType) == 2 ? 
                    this.state.tab == 0 ? 
                        <iframe
                            style={{ paddingLeft: 15, width: "100%", height: 650 }}
                            srcdoc={body_html}
                            frameborder={0}
                            // src="srcdocが使えなかった場合に表示するURL"
                        ></iframe>
                        :<iframe
                            style={{ paddingLeft: 15, width: "100%", height: 650 }}
                            srcdoc={body_text}
                            frameborder={0}
                            // src="srcdocが使えなかった場合に表示するURL"
                        ></iframe>
                    :""
                }

            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = state => {
    return {

        mail_details: state.mailReducer.mail_details,

        get_mail_details_loading: state.mailReducer.get_mail_details_loading
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_mail_details(state) {
            dispatch(Mail.get_mail_details(state))
        },
        change_mail_new(state) {
            dispatch(Mail.change_mail_new(state))
        },
        get_response_mails(state) {
            dispatch(Mail.get_response_mails(state))
        },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(MailDetail);




