import React, { Component } from 'react';
import { connect } from 'react-redux';
import Settings from './Settings';
// import RoomList from './RoomList';
// import Building from './Building';
import Map from './Map';
// import styled from 'styled-components'

import IntroductionListEdit from './IntroductionList/Index';



import {
  change_showRight, change_showLeft,
  change_showFlag, change_map_disp
} from './Saga/MapMypage';
import { add_mail_room } from '../Mail/Saga/Mail';


// const point= [
//   {
//     lat: 34.355185 ,
//     lng: 134.046989,
//     // name: ''
//   },
//   {
//     lat: 34.339829, 
//     lng: 134.035230, 
//     // name: ''
//   },
//   {
//     lat: 34.336144, 
//     lng: 134.053831, 
//     name: '高松市中心部'
//   },
//   {
//     lat: 34.351265, 
//     lng: 134.057135, 
//     name: ''
//   }


// ]
// const point2= [
//   {
//     lat: 34.355185 ,
//     lng: 134.046989,
//     // name: ''
//   },
//   {
//     lat: 34.339829, 
//     lng: 134.035230, 
//     // name: ''
//   },
//   {
//     lat: 34.336144, 
//     lng: 134.053831, 
//     name: '高松市'
//   },



// ]
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bounds: null,
      point: 0,
      disp: false,
    }

    // props.get_response_rooms({rentResponseId:props.rent_response_id});
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.selectTatemono = this.selectTatemono.bind(this);
    this.mapchange = this.mapchange.bind(this);

    // this.props.get_response_map_rooms_success([]);
  }

  mapchange() {
    // this.setState({disp:!this.state.disp})
    this.props.change_map_disp(!this.props.map_disp)
    // this.setState({disp:0})
  }

  // selectTatemono(tatemonoId, roomId) {
  //   // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}
  //   if (this.props.mail) {
  //     this.props.add_mail_room({ window: window, id: 131, tatemonoId: tatemonoId, roomId: roomId, rentResponseId: this.props.rent_response_id })
  //   } else {
  //     let insertdata = {
  //       rentResponseId: this.props.rent_response_id,
  //       tatemonoId: tatemonoId,
  //       status: 2,

  //       roomId: roomId,

  //     };


  //     this.props.insert_map_introductions(insertdata)
  //   }
  // }


  _onChildClick = (key, childProps) => {
    alert(key)
    // this.props.onCenterChange([childProps.lat, childProps.lng]);
  }

  handleSubmit(state) {
    console.log(state)

    if (this.state.bounds) {
      state.max_lat = this.state.bounds[0];
      state.min_lat = this.state.bounds[2];
      state.max_lng = this.state.bounds[3];
      state.min_lng = this.state.bounds[1];
      state.rentResponseId = this.props.rent_response_id;
      // this.props.get_response_map_rooms(state);

    }
    // this.props.actions.get_response_rooms(state);

  }
  render() {
    // const {handleChange} = this.props;
    //moment.locale('ja')

    return (

      <div className="item" style={{ position: "relative", height: window.innerHeight - 50, overflow: "hidden", paddingTop: 5 }}>
        {/* <div style={{ position:"absolute", zIndex:100, Top:0, left:-209}}>
  <div style={{backgroundColor:"#66CCFF", width:40, height:500, textAlign:"left", paddingLeft:7}}>
    <div className="fa fa-search" style={{fontSize:26, color:"White"}} onClick={()=>this.props.change_showFlag(!this.props.mapsearch.showFlag)}></div>
  </div>
</div> */}


        {/* <div className="search_menu" style={{ position:"absolute", zIndex:100, Top:0, left:-269, height:850, overflowY:"scroll", display: this.props.mapsearch.showFlag ? '' : 'none' }}> */}
        <div className="search_menu" style={{ position: "absolute", zIndex: 100, Top: 0, left: 0, height: window.innerHeight - 50, overflowY: "scroll", display: this.props.mapsearch.showLeft ? "" : "none" }}>

          <Settings mapchange={this.mapchange} onSubmit={this.handleSubmit} onChange={this.handleSubmit} />

        </div>

        <div style={{ height: window.innerHeight - 50, position: "absolute", zIndex: 5, Top: 5, Bottom: 5, left: 0, right: 0 }}>
          {this.props.mapmypage.map_disp ? <Map key={1} rent_response_id={this.props.rent_response_id} bounds={(bounds) => this.setState({ bounds: bounds })} /> : <Map key={2} rent_response_id={this.props.rent_response_id} tatemono_list={this.props.mapsearch.response_rooms} bounds={(bounds) => this.setState({ bounds: bounds })} />}
        </div>



        <div style={{ position: "absolute", zIndex: 100, Top: 0, right: -50, width: 400, height: window.innerHeight, background: "#FFF" }}>
          <div className="search_menu" style={{ position: "absolute", zIndex: 100, Top: 0, right: 0, width: 400, height: window.innerHeight - 50, overflowY: "scroll" }}>

            <div style={{ float: "left", width: 350 }}>
              <IntroductionListEdit />
            </div>


          </div>
        </div>


      </div>

    )
  }
}

// const Outer = styled.div`
// height:620; 
// position:absolute; 
// zIndex:5; 
// Top:10; 
// left:10; 
// right:10;`

//containers

// ({mapsearchReducer}) => ({mapsearchReducer}),

export default connect(
  ({ mapsearch, mapmypage }) => ({ mapsearch, mapmypage }),
  // state => ({
  //   mapsearch: state.mapsearchReducer,

  // }),
  {

    // get_response_map_rooms_success,
    change_showRight,
    change_showLeft,
    change_showFlag,

    // insert_map_introductions,
    add_mail_room,
    change_map_disp,
  }

)(App)
// const mapStateToProps = (state) => {
//   return {
//     response_rooms: state.mapsearchReducer.response_rooms,

//   }
// }
// function mapDispatchToProps(dispatch) {
//   return {

//       get_response_rooms(state) {
//           dispatch(Rooms.get_response_rooms(state))
//       },
//   };
// }
// export default connect(mapStateToProps, mapDispatchToProps)(App);


