import React, { Component, useRef } from 'react';

import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import * as ResponseList from './Saga/ResponseList';
import Loading from '../Layout/LoadingComponent';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';


import './Css/FileUp.css'



// タイマー用
let timer;


class FileUpComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deletealert: false,
      contractdoc:this.props.contractdoc,

      disp_err : false,

    };


    this.onDrop = this.onDrop.bind(this);

    this.change_deletealert = this.change_deletealert.bind(this);


  }


  onDrop = (files) => {
    console.log(files)
    const file = files[0]
    const reader = new FileReader()

    if(file.type.indexOf('image') == -1) {
      this.setState({ disp_err: true })
      return
    }else{
      this.setState({ disp_err: false })
    }
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        base64: reader.result,
        name: file.name,
      })
    }

    let sendfile = [];
    sendfile.file = files;
    this.props.change_line_file_data(files)

    // this.props.upload_line_template_file();
    // this.props.change_file_update_disp(false)
  };


  change_deletealert() {
    this.setState({ deletealert: true })
  }





  render() {


    return (

      <div >



        {this.props.fileid > 0 ? <div style={{ textAlign: "center" }}>
          {this.props.name}


          <Button variant="contained" style={{ float: "right" }} onClick={() => this.props.edit_contract_file_remarks(this.props.fileid)} color="primary">保存</Button>


        </div > :
          <div style={{ textAlign: "center" }}>
            <span style={{color:"red"}}>※登録した画像は、WEB上に公開されます。<br/>個人情報等の画像は登録しないでください。</span>
            <div style={{ height: "100%", margin: 5 }}>
              {this.props.get_mail_detail_files_loading === 1 ? <Loading /> : <Dropzone

                onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (

                  <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5" }}>

                    <input {...getInputProps()} />

                    <div style={{ height: 100 }}>
                      <p>{this.state.name ? this.state.name : "ファイル添付　クリックまたはドラッグ&ドロップ"}</p>
                      <ul style={{ padding: 0, marginTop: 3 }}  >
                        {/* {this.props.mail_detail_files.map(file => (
            <li className="filename" key={file.id} >
              <span onClick={(e)=>this.downloadfile(e,file)}>{file.name}</span> <span onClick={(e)=>this.deletefile(e,file)} className="fas fa-times float-right" />
            
                </li>))} */}

                      </ul>

                    </div>

                  </div>

                )}
              </Dropzone>}


              {this.props.type == 0 ? <TextField
                label="リンク先"
                // multiline
                // rows={4}
                onChange={(a) => this.props.change_rent_line_template_url(a.target.value)}
                style={{ width: "100%", marginTop:10 }}
                // defaultValue="Default Value"
                variant="outlined"
                value={this.props.rent_line_template_url}
                />:""}
            </div>


            {this.state.disp_err ? <Alert severity="error" style={{ margin: 5, width:"90%"}} ><span>画像のみ送信可能です。</span></Alert>:
            !this.props.line_file_data ? <Alert severity="error" style={{ margin: 5, width:"90%"}} ><span>画像を選択してください。</span></Alert>:
            this.props.type == 0 && this.props.rent_line_template_url == "" ? <Alert severity="error" style={{ margin: 5, width:"90%"}} ><span>リンク先を入力してください。</span></Alert>:
            <Button variant="contained" onClick={()=>{this.props.upload_line_template_file(this.props.type);this.props.change_file_update_disp(false)}} color="primary">保存</Button>}
           
            
          </div >
        }
      </div >

    );

  }
}




//コンテナ
const mapStateToProps = state => {
  return {

    rent_line_template_url: state.responselist.rent_line_template_url,
    line_file_data: state.responselist.line_file_data,
    file_tab: state.files.file_tab



  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_line_file_data(state) {
      dispatch(ResponseList.change_line_file_data(state))
    },
    upload_line_template_file(state) {
      dispatch(ResponseList.upload_line_template_file(state))
    },
    change_file_update_disp(state) {
      dispatch(ResponseList.change_file_update_disp(state))
    },
    change_rent_line_template_url(state) {
      dispatch(ResponseList.change_rent_line_template_url(state))
    },

  };
}


export default connect(mapStateToProps, mapDispatchToProps)(FileUpComponent);
