import React, { Component } from 'react';

import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';

import "react-datepicker/dist/react-datepicker.css";

import { reduxForm } from 'redux-form';
import * as Estimates from './Saga/Estimates';
import TextField from '@mui/material/TextField';

import 'bootstrap/dist/css/bootstrap.css';
import '../../Css/Table.css';
import moment from 'moment';


import ja from "date-fns/locale/ja";





class CustomerInfoEdit extends Component {

  constructor(props) {
    super(props);


    // if (props.customerdetail.name1) {
    props.initialize(props.customerdetail);//フォームに初期値を反映させる
    // } else {


    //     var customerdetail = {
    //         id:0,
    //         name1: "",
    //         kana1: "",
    //         name2: "",
    //         kana2: "",
    //         type: "0",
    //         sex: "1",
    //     }
    //     props.initialize(customerdetail);//フォームに初期値を反映させる
    // }
    this.state = {
      formData: {
        email: '',
        password: '',
        tatemonoName: '',
        applicationDate: null,
        contractionDate: null,
      },
      submitted: false,
      open: false,
    }


    // this.onChangeShowHide = this.onChangeShowHide.bind(this);
    // this.onChangeSex= this.onChangeSex.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);

    this.handleClosingDateChange = this.handleClosingDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    //    this.elements = null;
    //this.props.initialize(this.props.forecasts);

  }


  handleChange = (event) => {
    // alert(event.target.name)
    console.log(event)

    let estimateData = this.props.estimateData

    estimateData[event.target.name] = event.target.value
    // }
    this.props.change_estimateData(estimateData)
    this.setState({ change: true })
    this.props.check_estimates()
  }

  // handleSubmit = () => {
  //     this.setState({ submitted: true }, () => {
  //         setTimeout(() => this.setState({ submitted: false }), 5000);
  //     });
  // }

  componentWillMount() {

    console.log(this.props)
    if (this.props.customerdetail) {
      this.props.customerdetail.customerno = this.props.customerno;


    }

  }

  componentDidMount() {


  }



  componentDidUpdate() {
    // This method is called when the route parameters change
    //this.ensureDataFetched();

  }





  handleClosingDateChange = date => {

    let estimateData = this.props.estimateData

    estimateData.billingClosingDate = date
    // }
    this.props.change_estimateData(estimateData)
    this.setState({ change: true })
    // let formData =   this.state.formData
    // formData.contractionDate=date
    //   this.setState(formData);
    //   this.setState({open:false})
  };


  handleCustomerChange(event) {
    event.persist();
    console.log(event.target.value)
    // const change = this.state.customerdetail;
    // change[event.target.name] = event.target.value
    // this.setState({customerdetail: change})
    let change = this.props.customerdetail;
    change[event.target.name] = event.target.value;

    console.log(this.state.customerdetail)

  }
  handleSelectCustomer(state) {
    this.setState({ customerdetail: state })


    this.props.change_customerloding(1)
    this.props.change_customeredit(0)
  }

  handleCustomerreset() {

    this.setState({
      customerdetail: {
        id: 0,
        type: "0",
        sex: "1"
      }
    })
    this.props.change_customeredit(1)
  }



  open() {
    this.setState({ open: true })
  }

  close() {
    this.setState({ open: false })
  }






  render() {

    // const { customersubmit,handleSubmit } = this.props;

    // const year = [""];
    // const day = [""];

    // var hiduke = new Date(); 
    // for (var i = 1926; i <= hiduke.getFullYear(); i++) {
    //     year.push(i);
    // }
    // for (var i2= 1; i2 <= 31; i2++) {
    //     day.push(i2);
    // }

    // const { formData, submitted } = this.state;
    // console.log(formData)
    return (
      // <ValidatorForm
      //         ref="form"
      //         // onSubmit={this.handleSubmit}
      //         onError={errors => console.log(errors)}
      //     >

      <div>

        <Grid
          justifyContent="space-between" // Add it here :)
          container

        >


          {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}
            <Grid
            // justify="space-between" // Add it here :)
            // item

            >

              <TextField
                id="billingClosingDate"
                label="請求締日"
                type="date"
                name="billingClosingDate"
                size="small"
                onChange={this.handleChange}
                value={moment(this.props.estimateData.billingClosingDate).format("YYYY-MM-DD")}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ float: "left", marginRight:20 }}
                variant="standard"
              />

              {/* <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-inline1"
                label="請求締日"
                //   showTodayButton={true}
                onClose={this.close}
                open={this.state.open}
                onOpen={this.open}
                error={false}
                helperText="請求締日を選択してください"
                //   errorMessages={['契約日を選択してください']}
                name="billingClosingDate"
                value={this.props.estimateData.billingClosingDate}
                onChange={this.handleClosingDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                //   minDateMessage="契約日を選択してください"
                // minDate={new Date("2000-01-01")}
                invalidDateMessage={"日付の形式が違います"}
              /> */}
            </Grid>

          {/* </MuiPickersUtilsProvider> */}
        </Grid>



        <Grid
          // justify="space-between" // Add it here :)
          container
        // spacing={24}
        >
          <Grid
            // justify="space-between" // Add it here :)
            item
            xs={12}

          >
            <TextField
              id="outlined-multiline-static"
              label="備考"
              multiline
              rows="4"
              // defaultValue=""
              name="remarks"
              value={this.props.estimateData.remarks}
              style={{ width: "300px", float: "left" }}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
            //   onKeyDown={this.handleKeyDown}
            />

          </Grid>



        </Grid>

        {/* </ValidatorForm> */}
      </div>
    );
  }
}









const mapStateToProps = state => {
  return {
    customerNewDispSet: state.customersReducer.customerNewDispSet,
    customerlist: state.customersReducer.customerlist,
    formvalue: state.form,
    estimateData: state.estimates.estimateData,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_estimateData(state) {
      dispatch(Estimates.change_estimateData(state))
    },
    update_estimateMonies(state) {
      dispatch(Estimates.update_estimateMonies(state))
    },

    check_estimates() {
      dispatch(Estimates.check_estimates())
    },
    // update_customerdetail() {
    //     dispatch(Customers.update_customerdetail())
    // },
    // customerList(state) {
    //     dispatch(Customers.customerList(state))
    // },

  };
}



CustomerInfoEdit = connect(
  mapStateToProps, mapDispatchToProps
)(CustomerInfoEdit);

CustomerInfoEdit = reduxForm({
  form: 'customerform', // a unique identifier for this form
  // validate//バリデーション

  //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(CustomerInfoEdit)

export default CustomerInfoEdit


