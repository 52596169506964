
const authentication = require('../../../react-azure-adb2c2').default;

export const upload = (values: any) => {


    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/docs/uploadfile/`
    // const url = `http://localhost:5000/api/v1/rent/contract/docs/uploadfile/`

    const token = authentication.getAccessToken();


    // alert(url)
    var formData = new FormData();
    formData.append("files", values.file[0]);
    formData.append("rent_contract_id", values.contract_id);
    formData.append("remarks", values.remarks);
    console.log(formData)
    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));






};


export const update_doc = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/docs/update/` + values.fileid
    // const url = `http://localhost:5000/api/v1/rent/contract/docs/update/` + values.fileid

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


export const deletefile_doc = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/docs/delete/` + values
    // const url = `http://localhost:5000/api/v1/rent/contract/docs/delete/` + values

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}