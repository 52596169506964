import { CommonState } from './Types';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

const initialState: CommonState = {

    limitmodal:false,
}



///action
const actionCreator = actionCreatorFactory();

export const change_limitmodal = actionCreator<any>('CHANGE_LIMITMODAL');


export const commonReducer = reducerWithInitialState(initialState)
.case(change_limitmodal, (state, payload) => ({ ...state, limitmodal: payload }));

     