import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, } from 'react-router-dom'
import { Router, Route, } from 'react-router';
import { useLocation } from "react-router-dom"
import Layout from './Components/Layout/LayoutComponent';
import PdfLayout from './Components/Layout/PdfLayoutComponent';
import ResponseList from './Components/ResponseList/ResponseListComponent';



import ResponseAddComponent from './Components/ResponseDetail/ResponseAddComponent';
import ResponseComponent from './Components/ResponseDetail/ResponseComponent';

import RoomInfoComponent from './Components/Rooms/RoomInfoComponent';
import RoomDocComponent from './Components/Rooms/RoomDocComponent';


import EntrustedList from './Components/Entrusted/Index';
import MailComponent from './Components/Mail/MailComponent';


import CustomerContactsComponent from './Components/CustomerContacts/ContactsComponent';
import PdfDisplayComponent from './Components/Pdf/PdfDisplayComponent';
import ImagesComponent from './Components/Images/ImagesComponent';
import ContractManagementComponent from './Components/ContractManagement/ContractManagementComponent';
// import ExcelComponent from './Components/Excel/ExcelComponent';
// import MailTemplateComponent from './Components/MailTemplate/Index';
import MailTemplateComponent from './Components/MailTemplate/MailTemplateComponent';

// import SearchComponent from './Components/Search/SearchComponent';
import CkEditorComponent from './Components/MailTemplate/CkEditorComponent';
import MapDispComponent from './Components/Map/MapDispComponent';

import SearchComponent from './Components/ResponseList/ResponseSearchComponent';
// import BillingSearchComponent from './Components/Billing/BillingSearchComponent';
import SchedulerComponent from './Components/Schedule/SchedulerComponent';

import SampleComponent from './Components/Calendar/SampleComponent';
import ResponseAnalytics from './Components/ResponseAnalytics/Index';

import Entrusted from './Components/Entrusted/Index';

import Rent from './Components/Layout/RentComponent';

import DashBoard from './Components/DashBoard/Index';
import Graph from './Components/Graph/Index';

import ContractFollow from './Components/ContractFollow/Index';

import Corporation from './Components/Corporation/Index';
import CorporationContactsList from './Components/Corporation/CorporatesContactsList';
import Corporate from './Components/Corporation/CorporatesTop';

import ResponseListTableComponent from './Components/ResponseList/Index';
import ResponseFollowSheet from './Components/ResponseFollowSheet/Index';
import OtherSales from './Components/OtherSales/Index';

import YoyakuParking from './Components/YoyakuParking/Index';


// import TenantInfoComponent from './Components/Tenant/TenantInfoComponent';
// import TenantList from './Components/TenantList/TenantListComponent';



import Contracts from './Components/Contracts/Index';


import StockRooms from './Components/StockRooms/Index';
// import TcBlog from './Components/TcBlog/Index';
import Calendar from './Components/Calendar/IndexCalendar';
import CalendarAll from './Components/Calendar/AllStoreIndex';

import Approval from './Components/WorkFlow/ApprovaIndex';

import FileDisp from './Components/Contracts/FileDispIndex';



const App: React.FC = () => {

    return (
        <Layout >

            <Route exact path='/' component={ResponseListTableComponent} />
            <Route path='/CustomerNew' component={ResponseAddComponent} />
            <Route path='/Customer/:id' component={ResponseComponent} />
            <Route path='/contracts/:rent_contract_id' component={Contracts} />


            <Route path='/Tatemono/:tid/:rid' component={RoomInfoComponent} />
            <Route path='/RoomDoc/:tid/:rid' component={RoomDocComponent} />


            <Route path='/EntrustedList' component={EntrustedList} />
            <Route path='/Mail' component={MailComponent} />


            <Route path='/CustomerContacts' component={CustomerContactsComponent} />
            <Route path='/Pdf' component={PdfDisplayComponent} />
            <Route path='/Images' component={ImagesComponent} />
            <Route path='/ContractManagementComponent' component={ContractManagementComponent} />

            <Route path='/Schedule' component={SchedulerComponent} />
            <Route path='/Calendar' component={Calendar} />
            <Route path='/CalendarAll' component={CalendarAll} />

            <Route path='/MailTemplate' component={MailTemplateComponent} />


            <Route path='/CkEditorComponent' component={CkEditorComponent} />
            <Route path='/Map' component={MapDispComponent} />

            <Route path='/Search' component={SearchComponent} />

            <Route path='/Rent' component={Rent} />

            <Route path='/ResponseAnalytics' component={ResponseAnalytics} />
            <Route path='/Entrusted' component={Entrusted} />


            <Route path='/DashBoard' component={DashBoard} />
            <Route path='/Graph' component={Graph} />

            <Route path='/contractfollow' component={ContractFollow} />




            <Route path='/StockRooms' component={StockRooms} />

            <Route path='/Corporation' component={Corporation} />
            <Route path='/CorporationContactsList' component={CorporationContactsList} />
            <Route exact path='/Corporate/:id' component={Corporate} />

            <Route path='/ResponseList' component={ResponseList} />
            <Route path='/ResponseFollowSheet' component={ResponseFollowSheet} />
            <Route path='/OtherSales' component={OtherSales} />

            <Route path='/Approval' component={Approval} />

            <Route path='/YoyakuParking' component={YoyakuParking} />

            <Route path='/FileDisp/:type/:id' component={FileDisp} />{/* 1:申込ファイル 2:社内ファイル 3:公開ファイル 4:契約書添付ファイル 5:契約書添付 */}






        </Layout>
    );
}






export default App;
