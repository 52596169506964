import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as Responses from './Saga/ResponseList';
import '../../Css/Grid.css';
import './Css/Line.css';

import { Button } from 'reactstrap';

import TextField from '@mui/material/TextField';


import 'fixed-data-table-2/dist/fixed-data-table.css'


import ResponseLineDispComponent from './ResponseLineDispComponent';
import IconButton from '@mui/material/IconButton';

import Alert from '@mui/material/Alert';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';





class ResponseLineSendComponent extends Component {
  constructor(props) {
    super(props);

    var data = new Date()
    data.setMonth(data.getMonth() - 1)
    this.state = {
      // height: window.innerHeight,
      refresh: false,
      text: "",
      modal_del: false,

      anchorEl: null,
      disp_detail: false,

      disp_err: false,
    };

    // this.togglemail = this.togglemail.bind(this);
    this.refresh = this.refresh.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.change_date = this.change_date.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.hendleAdd = this.hendleAdd.bind(this);

    // alert(this.props.mail_id)
  }





  refresh() {
    this.setState({
      refresh: !this.state.refresh
    });
  }
  handleChange = (event) => {

    let rent_line_schedule = this.props.rent_line_schedule

    if (event.target.name == "schedule_at") {
      rent_line_schedule[event.target.name] = event.target.value.replace("T", " ")
    } else {
      rent_line_schedule[event.target.name] = event.target.value
    }


    this.props.change_rent_line_schedule(rent_line_schedule)

    this.refresh()
  }

  handleChangeSelect = (event) => {

    let rent_line_schedule = this.props.rent_line_schedule

    rent_line_schedule["intend_time"] = event.value

    this.props.change_rent_line_schedule(rent_line_schedule)

    this.refresh()
  }

  change_date = (value) => {
    let rent_line_schedule = this.props.rent_line_schedule

    rent_line_schedule.schedule_at = value

    this.props.change_rent_line_schedule(rent_line_schedule)
    this.refresh()
  }

  hendleAdd() {



    let rent_line_schedule = this.props.rent_line_schedule




    if (moment().add(30, 's').format('YYYY/MM/DD HH:mm') >= moment(rent_line_schedule.schedule_at).format('YYYY/MM/DD HH:mm')) {
      // 過去
      this.setState({
        disp_err: true,
      });
      return
    }



    this.props.update_line_send()
    this.props.change_line_schedule_open(false)


  }

  render() {

    return (
      <div>
        <div>
          <div style={{ padding: 3 }}>
            {/* <i className="fas fa-times" onClick={() => this.props.change_line_schedule_open(false)} style={{ float: "right", margin: 12 }} ></i> */}
            <IconButton
              aria-label="close"
              style={{ position: 'absolute', right: 1, top: 1 }}
              onClick={() => this.props.change_line_schedule_open(false)}
              size="large">
              <CloseIcon />
            </IconButton>
            <TextField
              id="datetime-local"
              label="送信予約日時"
              type="datetime-local"
              name="schedule_at"
              value={moment(this.props.rent_line_schedule.schedule_at).format("YYYY-MM-DDTHH:mm")}
              style={{ width: 200, marginBottom: 5 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleChange}
            />


            <Button style={{ marginLeft: 10, marginTop: 10 }} onClick={() => this.hendleAdd()} variant="contained" size="small" color="primary">登録</Button>
            {this.state.disp_err ? <Alert severity="error" style={{ margin: 5, width: "90%" }} ><span>送信日時が正しく選択されていません。</span></Alert> : ""}
          </div>
          <div style={{ width: 300, height: 280, border: "1px solid gray", marginLeft: 20 }} className="item-line" id="item-line">
            <ResponseLineDispComponent rent_line_template_detail={this.props.rent_line_schedule_template_detail} />
          </div>

        </div >


      </div>
    );
  }


}



const mapStateToProps = (state) => {
  return {
    response_search_list: state.responselist.response_search_list,

    line_schedule_detail: state.responselist.line_schedule_detail,

    rent_line_templates_list: state.responselist.rent_line_templates_list,
    rent_line_template: state.responselist.rent_line_template,
    rent_line_schedule_template_detail: state.responselist.rent_line_schedule_template_detail,
    line_schedule_open: state.responselist.line_schedule_open,
    rent_line_schedule: state.responselist.rent_line_schedule,

    user_details: state.masterReducer.user_details,


  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_line_schedule_detail(state) {
      dispatch(Responses.change_line_schedule_detail(state))
    },

    get_rent_line_templates_list(state) {
      dispatch(Responses.get_rent_line_templates_list(state))
    },
    get_rent_line_template_detail(state) {
      dispatch(Responses.get_rent_line_template_detail(state))
    },
    change_rent_line_template_detail(state) {
      dispatch(Responses.change_rent_line_template_detail(state))
    },
    change_rent_line_template(state) {
      dispatch(Responses.change_rent_line_template(state))
    },
    insert_rent_line_template(state) {
      dispatch(Responses.insert_rent_line_template(state))
    },
    update_rent_line_template(state) {
      dispatch(Responses.update_rent_line_template(state))
    },



    change_line_template_open(state) {
      dispatch(Responses.change_line_template_open(state))
    },
    change_line_schedule_open(state) {
      dispatch(Responses.change_line_schedule_open(state))
    },
    update_line_send(state) {
      dispatch(Responses.update_line_send(state))
    },
    change_rent_line_schedule(state) {
      dispatch(Responses.change_rent_line_schedule(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseLineSendComponent);