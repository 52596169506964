
const authentication = require('../../../react-azure-adb2c2').default;





export const update = (values: any) => {
    // alert(JSON.stringify(values))

    // const url = `http://localhost:5000/v1/rent/customers/update/` + values.rent_customer_id;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/customers/update/` + values.rent_customer_id//これ
    // alert(url)
    // const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/customers/update/` + values.rent_customer_id;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


export const list = (values: any) => {


    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomersList/`;


    const token = authentication.getAccessToken();
    const search = values ? values.payload : {};

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};














export const detail = (Id: number) => {

    // const url = `http://localhost:5000/v1/rent/customers/detail/` + Id;

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/customers/detail/` + Id;



    const token = authentication.getAccessToken();

    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}






export const get_details_by_rent_response_id = (rent_response_id: number) => {

    // const url = `http://localhost:5000/v1/rent/customers/responsedetails/` + rent_response_id;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/customers/responsedetails/` + rent_response_id;

    const token = authentication.getAccessToken();

    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}



export const get_details_by_rent_contract_id = (rent_contract_id: number) => {

    // const url = `http://localhost:5000/v1/rent/customers/contractdetails/` + rent_contract_id;

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/customers/contractdetails/` + rent_contract_id;



    const token = authentication.getAccessToken();

    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}