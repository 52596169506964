import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as Contracts from './Saga/Contracts';
import "react-datepicker/dist/react-datepicker.css";
import './Css/Contracts.css';
//import ResponseAddComponent from './ResponseAddComponent';
import Autocomplete from '@mui/material/Autocomplete';
// import SelectCompo from "./Select";
// import UnSelectCompo from "./UnSelect";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import SelectM from '@mui/material/Select';
import MaskedInput from 'react-text-mask';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import historykana from 'historykana'
import * as historyKana from 'historykana';

let timer

const phoneNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];


const mobileNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const postNumberMask = [

  /[0-9]/,
  /\d/,
  /\d/,

  "-",

  /\d/,
  /\d/,
  /\d/,
  /\d/,

];

function hankaku2Zenkaku(str) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}





function TelMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" ? mobileNumberMask : phoneNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}


function PostMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={postNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}
// const selectStyles = {
//   menu: base => ({
//     ...base,
//     zIndex: 0
//   })
// };


// const RenderSelectInput = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="店舗を選択"
//   />
// )

// const RenderSelectUsers = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="追客担当を選択"
//   />
// )

var name1_history = []
var name2_history = []

class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,
      reflash: false,
      ownshop_operation: true,
      ownshop_contract: true,

    };


    // this.props.change_contract_follow_list_form(formData);




    this.handleChange = this.handleChange.bind(this);



    this.handleCorpChange = this.handleCorpChange.bind(this);

    this.handleCorpChangeSet = this.handleCorpChangeSet.bind(this);
    this.handleWorkChangeSet = this.handleWorkChangeSet.bind(this);
    this.handle_agent_change = this.handle_agent_change.bind(this);




  }

  handleCorpChange(e) {
    // console.log(event)
    // alert(e.target.value)
    // let contract_follow_list_form = this.props.contract_follow_list_form
    // contract_follow_list_form[event.target.name] = event.target.value;
    if (e.target.value) {
      let name = e.target.value
      clearTimeout(timer);
      timer = setTimeout(() => {

        this.props.get_rent_m_corporates(name)
      }, 400);
    }
    // }
    // this.setState({ reflash: !this.state.reflash });

  }

  handleCorpChangeSet(e, value) {
    console.log(value)
    let rent_contract_req_editor_forms = this.props.rent_contract_req_editor_forms


    if (value) {
      rent_contract_req_editor_forms.rent_m_corporate_id = value.value
      rent_contract_req_editor_forms.name1 = value.label
      rent_contract_req_editor_forms.kana1 = this.props.rent_m_corporates.filter(a => a.id === parseInt(value.value))[0] ? this.props.rent_m_corporates.filter(a => a.id === value.value)[0].kana : ""
      rent_contract_req_editor_forms.office_rent_m_corporate_id = rent_contract_req_editor_forms.office_rent_m_corporate_id ? rent_contract_req_editor_forms.office_rent_m_corporate_id : value.value
      rent_contract_req_editor_forms.office_name = rent_contract_req_editor_forms.office_name ? rent_contract_req_editor_forms.office_name : value.label
    } else {
      rent_contract_req_editor_forms.rent_m_corporate_id = 0
      rent_contract_req_editor_forms.name1 = ''
      rent_contract_req_editor_forms.kana1 = ''
      // rent_contract_req_editor_froms.office_rent_m_corporate_id = 0
      // rent_contract_req_editor_froms.office_name = ''
    }
    this.props.change_rent_contract_req_editor_forms(rent_contract_req_editor_forms);
    this.setState({ reflash: !this.state.reflash });
  }


  handleWorkChangeSet(e, value) {
    console.log(value)
    let rent_contract_req_editor_forms = this.props.rent_contract_req_editor_forms


    if (value) {

      rent_contract_req_editor_forms.office_rent_m_corporate_id = value.value
      rent_contract_req_editor_forms.office_name = value.label
    } else {

      rent_contract_req_editor_forms.office_rent_m_corporate_id = 0
      rent_contract_req_editor_forms.office_name = ''
    }
    this.props.change_rent_contract_req_editor_forms(rent_contract_req_editor_forms);
    this.setState({ reflash: !this.state.reflash });
  }

  handle_agent_change(e, value) {
    console.log(value)
    let rent_contract_req_editor_forms = this.props.rent_contract_req_editor_forms


    if (value) {

      rent_contract_req_editor_forms.rent_m_agent_id = value.value
      if (value.value === 5) {
        rent_contract_req_editor_forms.agent_etc = ''
      }
    } else {

      rent_contract_req_editor_forms.rent_m_agent_id = 0
      rent_contract_req_editor_forms.agent_etc = ''
    }
    this.props.change_rent_contract_req_editor_forms(rent_contract_req_editor_forms);
    this.setState({ reflash: !this.state.reflash });
  }
  componentDidMount() {

    // autokana2 = AutoKana.bind('#name2', '#kana2', { katakana: true });


  }
  handleChange = (event) => {
    console.log(event)
    // alert(event.target.name)
    // alert(event.target.value)
    let rent_contract_req_editor_forms = this.props.rent_contract_req_editor_forms

    if (event.target.name === "student_independent" || event.target.name === "alone_family") {
      rent_contract_req_editor_forms[event.target.name] = parseInt(event.target.value);
      rent_contract_req_editor_forms.rent_m_reason_id = 0;
      rent_contract_req_editor_forms.rent_m_school_id = 0;
      rent_contract_req_editor_forms.highschool = '';
      rent_contract_req_editor_forms.gakunen = 0;
      if (rent_contract_req_editor_forms.student_independent === 2) {
        rent_contract_req_editor_forms.alone_family = 0
      }
    } else if (event.target.name === "contract_form") {
      rent_contract_req_editor_forms[event.target.name] = parseInt(event.target.value);
      rent_contract_req_editor_forms.name1 = ''
      rent_contract_req_editor_forms.kana1 = ''
      rent_contract_req_editor_forms.name2 = ''
      rent_contract_req_editor_forms.kana2 = ''
      rent_contract_req_editor_forms.rent_m_corporate_id = 0
      rent_contract_req_editor_forms.rent_m_agent_id = 0
      rent_contract_req_editor_forms.agent_etc = ''
    } else if (event.target.name === "sex") {
      rent_contract_req_editor_forms[event.target.name] = parseInt(event.target.value);

    } else {
      rent_contract_req_editor_forms[event.target.name] = event.target.value;
    }

    if (event.target.name === 'name1') {

      name1_history.push(event.target.value)
      console.log(name1_history)
      console.log(historyKana(name1_history))
      rent_contract_req_editor_forms.kana1 = historyKana(name1_history);
    }

    if (event.target.name === 'name2') {
      name2_history.push(event.target.value)

      // console.log(name1_history)
      // console.log(historykana(name1_history))
      rent_contract_req_editor_forms.kana2 = historyKana(name2_history);
    }
    // if (event.target.name === 'name2') {
    //   rent_customer_editor_froms.kana2 = autokana2.getFurigana();
    // }
    // if (event.target.name === "condition") {
    //   formData.contractionDateStart = new Date();
    //   formData.contractionDateEnd = null;
    // }
    this.props.change_rent_contract_req_editor_forms(rent_contract_req_editor_forms);
    // if (event.target.name === "condition") {


    // }
    this.setState({ reflash: !this.state.reflash });
  }











  age(birthDate) {
    birthDate = new Date(birthDate);
    // 文字列に分解
    const y2 = birthDate.getFullYear().toString().padStart(4, '0');
    const m2 = (birthDate.getMonth() + 1).toString().padStart(2, '0');
    const d2 = birthDate.getDate().toString().padStart(2, '0');

    // 今日の日付
    const today = new Date();
    const y1 = today.getFullYear().toString().padStart(4, '0');
    const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
    const d1 = today.getDate().toString().padStart(2, '0');

    // 引き算
    const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
    return age;

  }
  render() {


    return (
      <div id="contractfollow" >


        {
          <Grid container spacing={3}>

            <Grid
              justifyContent="space-between" // Add it here :)
              item
              xs={6}
            >

              {/* <RadioGroup aria-label="gender" name="gender1" > */}
              <FormControlLabel value="0" name="contract_form" onChange={this.handleChange} checked={this.props.rent_contract_req_editor_forms.contract_form === 0 ? true : false} control={<Radio />} label="個人" />
              <FormControlLabel value="1" name="contract_form" onChange={this.handleChange} checked={this.props.rent_contract_req_editor_forms.contract_form === 1 ? true : false} control={<Radio />} label="法人" />
              {/* </RadioGroup> */}
            </Grid>



            {this.props.rent_contract_req_editor_forms.contract_form === 1 ? <Grid xs={11} style={{ padding: 5 }}>

              <Autocomplete
                id="combo-box-demo"
                // options={this.props.users_select}
                options={this.props.rent_m_corporates.length === 0 && this.props.rent_contract_req_editor_forms.rent_m_corporate_id ? [{ value: this.props.rent_contract_req_editor_forms.rent_m_corporate_id, label: this.props.rent_contract_req_editor_forms.name1 }]
                  : this.props.rent_m_corporates.map((value) => ({
                    value: value.id,
                    label: value.name ? value.name : ""
                  }))}
                getOptionLabel={(option) => option.label ? option.label : ''}
                // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
                // value={this.props.rent_customer_editor_froms.name1}
                style={{ width: "100%" }}
                name="rent_m_corporates_id"
                loading={this.props.rent_m_corporates_loading}
                defaultValue={this.props.rent_contract_req_editor_forms.rent_m_corporate_id ? { value: this.props.rent_contract_req_editor_forms.rent_m_corporate_id, label: this.props.rent_contract_req_editor_forms.name1 } : {}}
                // open={false}
                // value={this.props.contract_follow_list_form.contract_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.contract_user_id)[0] : null}
                onChange={this.handleCorpChangeSet}
                renderInput={(params) => <TextField
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>

                        {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={this.handleCorpChange}
                  label={"法人名:" + (this.props.rent_contract_req_editor_forms.kana1 ? this.props.rent_contract_req_editor_forms.kana1 : "")} />}

              />



            </Grid> : ""}
            {this.props.rent_contract_req_editor_forms.contract_form === 1 ? <Grid xs={1} style={{ padding: 5 }}>
              <Tooltip title="法人追加" aria-label="add">
                <IconButton
                  aria-label="delete"
                  onClick={() => this.props.change_rent_m_corporate_editor(true)}
                  size="large">
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </Grid> : ""}

            {
              this.props.rent_contract_req_editor_forms.contract_form === 1 ? <Grid xs={8} style={{ padding: 5 }}>
                <TextField
                  id="name1"
                  //   className={classes.textField}
                  label="代表者名"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  name="executive"
                  value={this.props.rent_contract_req_editor_forms.executive}
                  style={{ float: "left", width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> : ""}
            {this.props.rent_contract_req_editor_forms.contract_form === 1 ? <Grid xs={4} style={{ padding: 5 }}>

            </Grid> : ""}
            {
              this.props.rent_contract_req_editor_forms.contract_form === 1 ? <Grid xs={8} style={{ marginTop: 5 }}>
                {/* <FormControl style={{ marginLeft: "10px" }}> */}
                {this.props.rent_m_agents ? <Autocomplete
                  id="combo-box-demo"
                  // options={this.props.users_select}
                  options={this.props.rent_m_agents.map((value) => ({
                    value: value.id,
                    label: value.name
                  }))}
                  getOptionLabel={(option) => option.label ? option.label : ''}
                  // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
                  // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                  style={{ width: "100%" }}
                  name="rent_m_agent_id"
                  value={this.props.rent_contract_req_editor_forms.rent_m_agent_id ? this.props.rent_m_agents.filter(a => a.id === this.props.rent_contract_req_editor_forms.rent_m_agent_id).map((value) => ({
                    value: value.id,
                    label: value.name
                  }))[0] : {}}
                  onChange={this.handle_agent_change}
                  renderInput={(params) => <TextField
                    variant="filled"
                    {...params} label="代行会社" />}

                /> : ""}
                {/* </FormControl> */}
              </Grid> : ""}
            {
              this.props.rent_contract_req_editor_forms.contract_form === 1 ? <Grid xs={4} style={{ padding: 5 }}>
                {this.props.rent_contract_req_editor_forms.rent_m_agent_id === 5 ? <TextField
                  id="agent_etc"
                  //   className={classes.textField}
                  label="代行会社その他"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  name="agent_etc"
                  value={this.props.rent_contract_req_editor_forms.agent_etc}
                  style={{ float: "left", width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> : ""}
              </Grid> : ""}









            {
              this.props.rent_contract_req_editor_forms.contract_form !== 1 ? <Grid xs={12} style={{ padding: 5 }}>
                <TextField
                  id="name1"
                  //   className={classes.textField}
                  label="お客様名"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  name="name1"
                  error={this.props.rent_contract_req_editor_forms.name1 ? false : true}
                  value={this.props.rent_contract_req_editor_forms.name1}
                  style={{ float: "left", width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> : ""}
            {this.props.rent_contract_req_editor_forms.contract_form !== 1 ? <Grid xs={12} style={{ padding: 5 }}>
              <TextField
                id="outlined-basic"
                variant="filled"
                //   className={classes.textField}
                label="お客様名カナ"
                margin="normal"
                name="kana1"
                error={this.props.rent_contract_req_editor_forms.kana1 ? false : true}
                onChange={this.handleChange}

                value={this.props.rent_contract_req_editor_forms.kana1}
                style={{ float: "left", width: "100%", paddingRight: "5px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> : ""}


            {this.props.rent_contract_req_editor_forms.contract_form === 1 ? <Grid xs={12} style={{ padding: 5, marginTop: 5 }}>
              <TextField
                id="name2"
                //   className={classes.textField}
                label="入居者名"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                error={this.props.rent_contract_req_editor_forms.contract_form === 1 && this.props.rent_contract_req_editor_forms.name2 ? false : true}
                name="name2"
                value={this.props.rent_contract_req_editor_forms.name2}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> : ""}
            {this.props.rent_contract_req_editor_forms.contract_form === 1 ? <Grid xs={12} style={{ padding: 5 }}>
              <TextField
                id="outlined-basic"
                variant="filled"
                //   className={classes.textField}
                label="入居者名カナ"
                margin="normal"
                name="kana2"
                onChange={this.handleChange}
                error={this.props.rent_contract_req_editor_forms.contract_form === 1 && this.props.rent_contract_req_editor_forms.kana2 ? false : true}
                value={this.props.rent_contract_req_editor_forms.kana2}
                style={{ float: "left", width: "100%", paddingRight: "5px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> : ""}

            <Grid xs={6} style={{ padding: 10 }}>
              <FormControlLabel value="1" name="sex" onChange={this.handleChange} checked={!this.props.rent_contract_req_editor_forms.sex || this.props.rent_contract_req_editor_forms.sex === 1 ? true : false} control={<Radio />} label="男性" />
              <FormControlLabel value="2" name="sex" onChange={this.handleChange} checked={this.props.rent_contract_req_editor_forms.sex === 2 ? true : false} control={<Radio />} label="女性" />

            </Grid>


            <Grid xs={6} style={{ padding: 10, verticalAlign: "bottom" }}>
              <TextField
                id="date"
                label="生年月日"
                type="date"
                name="birthday"
                onChange={this.handleChange}
                // defaultValue={null}
                value={this.props.rent_contract_req_editor_forms.birthday}
                error={this.props.rent_contract_req_editor_forms.birthday ? false : true}
                // className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {this.props.rent_contract_req_editor_forms.birthday ? "　(" + this.age(this.props.rent_contract_req_editor_forms.birthday) + "歳)" : ""}
            </Grid>

            <Grid xs={11} style={{ padding: 5 }}>
              <Autocomplete
                id="combo-box-demo"
                // options={this.props.users_select}
                // options={this.props.rent_m_corporates.map((value) => ({
                //   value: value.id,
                //   label: value.name ? value.name : ""
                // }))}
                options={this.props.rent_m_corporates.length === 0 && this.props.rent_contract_req_editor_forms.office_rent_m_corporate_id ? [{ value: this.props.rent_contract_req_editor_forms.office_rent_m_corporate_id, label: this.props.rent_contract_req_editor_forms.office_name }]
                  : this.props.rent_m_corporates.map((value) => ({
                    value: value.id,
                    label: value.name ? value.name : ""
                  }))}
                getOptionLabel={(option) => option.label ? option.label : ''}
                // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
                // value={this.props.rent_customer_editor_froms.name1}
                style={{ width: "100%" }}
                name="office_rent_m_corporates_id"
                loading={this.props.rent_m_corporates_loading}

                defaultValue={this.props.rent_contract_req_editor_forms.office_rent_m_corporate_id ? { value: this.props.rent_contract_req_editor_forms.office_rent_m_corporate_id, label: this.props.rent_contract_req_editor_forms.office_name } : {}}

                // open={false}
                // value={this.props.contract_follow_list_form.contract_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.contract_user_id)[0] : null}
                onChange={this.handleWorkChangeSet}
                renderInput={(params) => <TextField
                  {...params}
                  variant="outlined"
                  error={this.props.rent_contract_req_editor_forms.office_rent_m_corporate_id ? false : true}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>

                        {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={this.handleCorpChange}
                  label="勤務先" />}

              />
            </Grid>
            <Grid xs={1} style={{ padding: 5 }}>
              <Tooltip title="法人追加" aria-label="add">
                <IconButton
                  aria-label="delete"
                  onClick={() => this.props.change_rent_m_corporate_editor(true)}
                  size="large">
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </Grid>




            <Grid xs={12} style={{ padding: 5 }}>
              <TextField
                variant="outlined"
                name="post"
                label="郵便番号"
                type="tel"
                value={this.props.rent_contract_req_editor_forms.post}
                onChange={this.handleChange}
                id="formatted-numberformat-input"
                style={{ float: "left", width: 200, paddingRight: "5px" }}
                error={this.props.rent_contract_req_editor_forms.post ? false : true}
                InputProps={{
                  inputComponent: PostMaskCustom,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />   <Tooltip title="郵便番号から住所を検索" aria-label="add" onClick={this.props.get_rent_customer_zip_address}>
                <IconButton aria-label="delete" size="large">
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </Grid>


            <Grid xs={12} style={{ padding: 5 }}>
              <TextField
                id="outlined-basic"
                //   className={classes.textField}
                label="住所1"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                error={this.props.rent_contract_req_editor_forms.add1 ? false : true}
                name="add1"
                value={this.props.rent_contract_req_editor_forms.add1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid xs={12} style={{ padding: 5 }}>
              <TextField
                id="outlined-basic"
                //   className={classes.textField}
                label="住所2"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="add2"
                value={this.props.rent_contract_req_editor_forms.add2}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid xs={4} style={{ padding: 10 }}>

              <TextField
                variant="outlined"
                name="tel1"
                label="電話番号1"
                type="tel"
                value={this.props.rent_contract_req_editor_forms.tel1}
                onChange={this.handleChange}
                id="formatted-numberformat-input"
                style={{ float: "left", width: "100%", paddingRight: "5px" }}
                error={this.props.rent_contract_req_editor_forms.tel1 ? false : true}
                InputProps={{
                  inputComponent: TelMaskCustom,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {/* <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="電話番号1"
              margin="normal"
              name="tel1"
              onChange={this.handleChange}

              value={this.props.rent_customer_editor_froms.tel1}
              style={{ float: "left", width: "100%", paddingRight: "5px" }}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}

            </Grid>

            <Grid xs={8} style={{ padding: 10 }}>


              <TextField
                id="outlined-basic"
                variant="filled"
                //   className={classes.textField}
                label="電話番号1備考"
                margin="normal"
                name="tel1_remarks"
                onChange={this.handleChange}

                value={this.props.rent_contract_req_editor_forms.tel1_remarks}
                style={{ float: "left", width: "100%", paddingRight: "5px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

            </Grid>







            <Grid
              xs={12}
            >
              <FormControlLabel value={1} name="student_independent" error={this.props.rent_contract_req_editor_forms.student_independent ? false : true} onChange={this.handleChange} checked={this.props.rent_contract_req_editor_forms.student_independent === 1 ? true : false} control={<Radio />} label="社会人" />
              <FormControlLabel value={2} name="student_independent" error={this.props.rent_contract_req_editor_forms.student_independent ? false : true} onChange={this.handleChange} checked={this.props.rent_contract_req_editor_forms.student_independent === 2 ? true : false} control={<Radio />} label="学生" />

            </Grid>

            {this.props.rent_contract_req_editor_forms.student_independent === 1 ? <Grid
              xs={12}
            >
              <FormControlLabel value={1} name="alone_family" onChange={this.handleChange} checked={this.props.rent_contract_req_editor_forms.alone_family === 1 ? true : false} control={<Radio />} label="単身" />
              <FormControlLabel value={2} name="alone_family" onChange={this.handleChange} checked={this.props.rent_contract_req_editor_forms.alone_family === 2 ? true : false} control={<Radio />} label="ファミリー" />

            </Grid> : ""}


            {this.props.rent_contract_req_editor_forms.student_independent === 2 ? <Grid xs={4} style={{ marginTop: 5 }}>
              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label">学校名</InputLabel>
                <SelectM
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={{ width: 200 }}
                  name="rent_m_school_id"
                  error={this.props.rent_contract_req_editor_forms.rent_m_school_id > 0 ? false : true}
                  value={this.props.rent_contract_req_editor_forms.rent_m_school_id}
                  onChange={this.handleChange}
                  label="学校名"
                >
                  <MenuItem value={0}>
                    -
                </MenuItem>
                  {this.props.rent_m_schools.map(function (value) {
                    return <MenuItem value={value.id}>{value.name}</MenuItem>
                  })}

                </SelectM>
              </FormControl>
            </Grid> : ""}


            {this.props.rent_contract_req_editor_forms.student_independent === 2 ? <Grid xs={4} style={{ marginTop: 5 }}>
              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label">学年</InputLabel>
                <SelectM
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={{ width: 200 }}
                  name="gakunen"
                  value={this.props.rent_contract_req_editor_forms.gakunen}
                  error={this.props.rent_contract_req_editor_forms.gakunen > 0 ? false : true}
                  onChange={this.handleChange}
                  label="学年"
                >
                  <MenuItem value={0}>
                    -
                </MenuItem>
                  <MenuItem value={1}>新入学</MenuItem>
                  <MenuItem value={2}>1回生</MenuItem>
                  <MenuItem value={3}>1回生</MenuItem>
                  <MenuItem value={4}>1回生</MenuItem>
                  <MenuItem value={5}>1回生</MenuItem>
                  <MenuItem value={6}>院生</MenuItem>

                </SelectM>
              </FormControl>
            </Grid> : ""}



            {this.props.rent_contract_req_editor_forms.student_independent === 2 ? <Grid xs={4} style={{ marginTop: 5 }}>
              <TextField
                id="highschool"
                variant="filled"
                //   className={classes.textField}
                label="出身校"
                margin="normal"
                name="highschool"
                onChange={this.handleChange}
                error={this.props.rent_contract_req_editor_forms.highschool ? false : true}
                value={this.props.rent_contract_req_editor_forms.highschool}
                style={{ float: "left", width: "100%", paddingRight: "5px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />不明の場合は不明と入力
            </Grid> : ""}




            {(this.props.rent_contract_req_editor_forms.student_independent === 2 || this.props.rent_contract_req_editor_forms.alone_family > 0) ? <Grid xs={12} style={{ marginTop: 5 }}>
              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label">引越理由</InputLabel>
                <SelectM
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={{ width: 200 }}
                  name="rent_m_reason_id"
                  value={this.props.rent_contract_req_editor_forms.rent_m_reason_id}
                  error={this.props.rent_contract_req_editor_forms.rent_m_reason_id > 0 ? false : true}
                  onChange={this.handleChange}
                  label="引越理由"
                >
                  <MenuItem value={0}>
                    -
                </MenuItem>
                  {this.props.rent_contract_req_editor_forms.student_independent === 2 ? this.props.rent_m_reasons.filter(a => a.reason_division === 1 || a.reason_division === 2).map(function (value) {
                    return <MenuItem value={value.id}>{value.name}</MenuItem>
                  }) : (this.props.rent_contract_req_editor_forms.student_independent === 1 && this.props.rent_contract_req_editor_forms.alone_family === 1) ? this.props.rent_m_reasons.filter(a => a.reason_division === 1 || a.reason_division === 3).map(function (value) {
                    return <MenuItem value={value.id}>{value.name}</MenuItem>
                  }) : (this.props.rent_contract_req_editor_forms.student_independent === 1 && this.props.rent_contract_req_editor_forms.alone_family === 2) ? this.props.rent_m_reasons.filter(a => a.reason_division === 1 || a.reason_division === 3 || a.reason_division === 4).map(function (value) {
                    return <MenuItem value={value.id}>{value.name}</MenuItem>
                  }) : ""}
                </SelectM>
              </FormControl>
            </Grid> : ""}



          </Grid>
        }

      </div >
    );
  }
}





const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    user_details: state.masterReducer.user_details,
    rent_m_agents: state.contracts.rent_m_agents,
    // csv_loading: state.Adjustment.csv_loading,
    // search_list: state.Adjustment.search_list,
    // seisan_follow_list: state.Adjustment.seisan_follow_list,
    // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
    // seisan_follow_search: state.Adjustment.seisan_follow_search,
    // tantou: state.Adjustment.tantou,
    // rent_m_contract_follows: state.ContractFollow.rent_m_contract_follows,
    // rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_customer_editor_disp: state.contracts.rent_customer_editor_disp,
    rent_customer_editor_forms: state.contracts.rent_customer_editor_forms,
    rent_m_corporates: state.contracts.rent_m_corporates,
    rent_m_corporates_loading: state.contracts.rent_m_corporates_loading,
    rent_m_corporate_editor: state.contracts.rent_m_corporate_editor,
    rent_m_reasons: state.contracts.rent_m_reasons,
    rent_m_schools: state.contracts.rent_m_schools,
    rent_contract_req_editor_forms: state.contracts.rent_contract_req_editor_forms,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_rent_customer_editor_disp(state) {
      dispatch(Contracts.change_rent_customer_editor_disp(state))
    },
    change_rent_customer_editor_forms(state) {
      dispatch(Contracts.change_rent_customer_editor_forms(state))
    },
    get_rent_customer_zip_address(state) {
      dispatch(Contracts.get_rent_customer_zip_address(state))
    },
    get_rent_m_corporates(state) {
      dispatch(Contracts.get_rent_m_corporates(state))
    },
    change_rent_m_corporate_editor(state) {
      dispatch(Contracts.change_rent_m_corporate_editor(state))
    },
    change_rent_contract_req_editor_forms(state) {
      dispatch(Contracts.change_rent_contract_req_editor_forms(state))
    },
    // change_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.change_rent_m_contract_follow_settings(state))
    // },
    // change_contract_follow_list_form(state) {
    //   dispatch(ContractFollow.change_contract_follow_list_form(state))
    // },
    // get_contract_follow_list(state) {
    //   dispatch(ContractFollow.get_contract_follow_list(state))
    // },
    // search_contract_follow_list(state) {
    //   dispatch(ContractFollow.search_contract_follow_list(state))
    // },
    // update_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.update_rent_m_contract_follow_settings(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);
