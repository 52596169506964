import React, { Component ,useRef} from 'react';

import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
// import RoomSearchListComponent from '../RoomList/Index';
import * as IntroductionList from './Saga/IntroductionList';
import Grid from '@mui/material/Grid';
import Loading from '../Layout/LoadingComponent';
import { Document, Page } from 'react-pdf';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import  './Css/FileUp.css'

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Select from '@mui/material/Select';
import { Button } from 'reactstrap';


// タイマー用
let timer;


class FileUpComponent extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages : [],
            scrollTop : 0,
            old_id: 0,
            back_date: "",
            modalsearch:false,
            modal: false,
            modalselect: false,
            files: [],
            mailid : 0,
            modalTemplateSelect:false,
            textareaHeight:0,
            modalNoSubject:false,
            tooltipOpen: false,
            modalDelete: false,
            modalmap: false,
          
            base64:null,

            introduction_details:this.props.introduction_details,
           
        };
        
 
console.log(this.props.introduction_details)
// alert(this.props.introduction_details)
// this.props.insert_introduction_files({rentResponseId:this.props.rent_response_id})

        this.toggletooltip = this.toggletooltip.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);

   this.save = this.save.bind(this);
        // props.get_mails()
        
        this.onDrop = this.onDrop.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        
        this.downloadfile = this.downloadfile.bind(this);
        this.togglesearch = this.togglesearch.bind(this);
        this.togglemap = this.togglemap.bind(this);
        this.toggleestimates = this.toggleestimates.bind(this);
        
        this.toggleTemplateSelect = this.toggleTemplateSelect.bind(this);

        this.selectTatemono = this.selectTatemono.bind(this);
        this.deletefile = this.deletefile.bind(this);

        this.delete_draft = this.delete_draft.bind(this);
        this.toggleNoSubject = this.toggleNoSubject.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);

        
    }


    onDrop = (files) => {console.log(files)
      const file = files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.setState({
          base64: reader.result,
          name: file.name,
        })
      }
      // usePdf({
      //   file: files,
      //   // onDocumentComplete,
      //   // page,
      //   canvasEl:this.canvasEl
      // });
    let sendfile=[];
    sendfile.file=files;
    sendfile.id=this.props.introduction_file_id;
    this.props.change_introduction_pdf(sendfile)


        // this.props.add_introduction_file(sendfile)
      };

    
    
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleselect =(id)=> {
      
         this.setState(prevState => ({
             modalselect: !prevState.modalselect,
             mailid: id
         }));
    }

    togglesearch(){
    
      this.setState(prevState => ({
          modalsearch: !prevState.modalsearch
      }));
      this.props.draggable===1?this.props.change_draggable(2):this.props.change_draggable(1)



  }

  togglemap(){
    
    this.setState(prevState => ({
        modalmap: !prevState.modalmap
    }));
    // this.props.draggable===1?this.props.change_draggable(2):this.props.change_draggable(1)
}
toggleestimates(){
    
  this.setState(prevState => ({
      modalestimates: !prevState.modalestimates
  }));
  // this.props.draggable===1?this.props.change_draggable(2):this.props.change_draggable(1)
}

  toggleTemplateSelect(){
    this.setState(prevState => ({
      modalTemplateSelect: !prevState.modalTemplateSelect
  }));

  }
  toggleNoSubject(){
    this.setState(prevState => ({
      modalNoSubject: !prevState.modalNoSubject
  }));

  }
    save = () => {
     let introduction_details =  this.state.introduction_details
     introduction_details.rentResponseId=this.props.rent_response_id
     console.log(introduction_details)

      this.props.insert_introduction_files(introduction_details)
      this.props.togglefileupsave()

    };


    handlefrommail(state){
      this.props.send_mail_details.fromMail=state.target.value;
   
      
    }
    
   


    handleChange(state){
      // console.log(state)
      
      // console.log(state.target.name)
//       console.log(state.target.value)
// let send_mail_details = this.props.send_mail_details;
// // alert(state.target.value)
let introduction_details = this.state.introduction_details
introduction_details[state.target.name]=state.target.value;
this.setState({introduction_details:introduction_details})
//       clearTimeout(timer);
  
     
//           timer = setTimeout(function () { 
//             // console.log(send_mail_details)
//           // alert(send_mail_details[state.target.name])
//             this.props.update_send_mail_editor_state(send_mail_details)
          
//           }.bind(this), 1500);
//           // this.forceUpdate();
    }

    handleEditorChange(state){
      // console.log(state.target.name)
      // console.log(state.target.value)
      this.props.send_mail_details.body=state;
  
      clearTimeout(timer);
  
     
          timer = setTimeout(function () {
     if(this.props.mail_new>=1){
            this.props.update_send_mail_editor_state(this.props.send_mail_details)
          }
          }.bind(this), 1500);
     
    }

    downloadfile(e,file){ 
      e.stopPropagation();
     
      this.props.get_mail_file(file)
// alert(state)
    }
    deletefile(e,file){
      e.stopPropagation();
      this.props.delete_mail_file(file.id)
// alert(state)
    }

    selectTatemono= (tatemonoId,roomId) => {
// alert(this.props.responseid)
      this.props.add_mail_room({window:window,id:131,tatemonoId:tatemonoId,roomId:roomId,rentResponseId:this.props.responseid})
      // this.props.togglesearch();
    }

    delete_draft(){

      // alert(this.props.send_mail_details.id)
      let send_mail_details = this.props.send_mail_details;
send_mail_details.isDeleted=true;
console.log(send_mail_details)

      this.props.delete_draft(send_mail_details)
      this.setState(({modalDelete: false}));
      // this.props.delete_draft(this.props.send_mail_details.id)
    }
    toggleDelete(){
      this.setState(prevState => ({
        modalDelete: !prevState.modalDelete
    }));
  
    }

    onDocumentLoadSuccess(e){
     
      const importPDFCanvas = document.querySelector('.react-pdf__Page canvas');

      const pdfAsImageSrc = importPDFCanvas.toDataURL();


      var bin = atob(pdfAsImageSrc.replace(/^.*,/, ''));
      // バイナリデータ化
      var buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
          buffer[i] = bin.charCodeAt(i);
      }
      // ファイルオブジェクト生成(この例ではjpegファイル)
     const file= new File([buffer.buffer], this.props.introduction_file_id+".jpg", {type: "image/jpeg"});
     let sendfile=[];
     let fileset=[]
     fileset.push(file)
     sendfile.file=fileset;
     sendfile.id=this.props.introduction_file_id;

     this.props.change_introduction_pdf_img(sendfile)
        //  this.props.add_introduction_file(sendfile)
     console.log(file)
    }

    toggletooltip() {
      this.setState({
        tooltipOpen: !this.state.tooltipOpen
      });
    }
    render() {

        // const files = this.props.send_files.map(file => (
        //     <li className="filename" key={file.name}>
        //       {file.name} <span className="fas fa-times float-right"/>

        //     </li>
      
        //   ));
          console.log(this.props.mail_detail_files)
          
        console.log(this.props.getmails)
        return (
        
          <div >
           
         


  

           
        
        <div className="col-sm-6 float-left" style={{textAlign:"center"}}>
       
          <div  className="col-sm-12" style={{height:"100%"}}>
          {this.props.get_mail_detail_files_loading===1? <Loading /> :<Dropzone 
         
                onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (

            <div {...getRootProps({className: 'dropzone'})} style={{background:"#f5f5f5"}}>
            
              <input {...getInputProps()} />
              
              <div style={{height:100}}>
              <p>ファイル添付　クリックまたはドラッグ</p>
                <ul style={{padding:0, marginTop:3}}  >
                {/* {this.props.mail_detail_files.map(file => (
            <li className="filename" key={file.id} >
              <span onClick={(e)=>this.downloadfile(e,file)}>{file.name}</span> <span onClick={(e)=>this.deletefile(e,file)} className="fas fa-times float-right" />
            
                </li>))} */}
                
                </ul>
            
              </div>
            
            </div>

        )}
      </Dropzone>}
      
      </div>
      <div className="col-sm-12" style={{padding:"5px"}}>
      <Document file={this.state.base64}  noData="" loading={<Loading/>}  >
          <Page pageNumber={1} onRenderSuccess ={this.onDocumentLoadSuccess} height={200} width={200}/>
        </Document>
        {this.props.introduction_pdf===null?<img src={process.env.REACT_APP_OPENROOM_API_URL + "/api/img/card/"+this.state.introduction_details.id+"?"+(new Date()).getTime()} alt="pdfimg" height={200} width={200}/>:""}
   
        {this.state.introduction_details.id>0?<Button
        size="sm"
                    color="primary"
                    variant="contained"
                    type="button"
                    style={{float:"right"}}
                    onClick={()=>this.props.get_introduction_file(this.state.introduction_details.id)}
                >ダウンロード
                </Button>:""}
        </div>
      </div>
      <div className="col-sm-6 float-left">
      <ValidatorForm>
      <Grid container>
  <Grid item xs={12}>
      <TextValidator
          id="outlined-basic"
         
          label="物件名"
          margin="normal"
         
          onChange={this.handleChange}
          name="tatemonoName"
          value={this.state.introduction_details.tatemonoName}
          style={{float:"left",width:"200px",marginTop:0,textAlign: "right"}}
          validators={['required']}
          errorMessages={['物件名を入力してください']}
        />
 <TextValidator
          id="outlined-basic"
        //   className={classes.textField}
          label="部屋名"
          margin="normal"
         
          onChange={this.handleChange}
          name="roomName"
          value={this.state.introduction_details.roomName}
          style={{float:"left",width:"80px",marginTop:0,marginLeft:5}}
          validators={['required']}
          errorMessages={['部屋名を入力してください']}
        />
       </Grid>

       <Grid item xs={12}>
<TextValidator
          id="outlined-basic"
        //   className={classes.textField}
          label="敷金"
          margin="normal"
         type="number"
          onChange={this.handleChange}
          name="shikikinAmount"
          value={this.state.introduction_details.shikikinAmount===0?"":this.state.introduction_details.shikikinAmount}
          style={{float:"left",width:"80px",marginTop:0,marginLeft:5}}
       
        />

<FormControl    style={{float:"left",marginLeft:"10px"}}>
        <InputLabel id="demo-simple-select-helper-label">区分</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={this.state.introduction_details.shikikinFlg===0?1:this.state.introduction_details.shikikinFlg}
     
          onChange={this.handleChange}
          name="shikikinFlg"
        >
          
          <MenuItem value={1}>月</MenuItem>
          <MenuItem value={2}>金額</MenuItem>
          <MenuItem value={3}>無し</MenuItem>
     
        </Select>

        

      </FormControl>
        </Grid>
        

        <Grid item xs={12}>
<TextValidator
          id="outlined-basic"
        //   className={classes.textField}
          label="礼金"
          margin="normal"
         type="number"
          onChange={this.handleChange}
          name="reikinAmount"
          value={this.state.introduction_details.reikinAmount===0?"":this.state.introduction_details.reikinAmount}
          style={{float:"left",width:"80px",marginTop:0,marginLeft:5}}
       
        />

<FormControl    style={{float:"left",marginLeft:"10px"}}>
        <InputLabel id="demo-simple-select-helper-label">区分</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={this.state.introduction_details.reikinFlg===0?1:this.state.introduction_details.reikinFlg}
     
          onChange={this.handleChange}
          name="reikinFlg"
        >
          
          <MenuItem value={1}>月</MenuItem>
          <MenuItem value={2}>金額</MenuItem>
          <MenuItem value={3}>無し</MenuItem>
     
        </Select>

        

      </FormControl>
        </Grid>



       <Grid item xs={12}>
<TextValidator
          id="outlined-basic"
        //   className={classes.textField}
          label="賃料"
          margin="normal"
          type="number"
          onChange={this.handleChange}
          name="chinryouAmount"
          value={this.state.introduction_details.chinryouAmount===0?"":this.state.introduction_details.chinryouAmount}
          style={{width:"100px",marginTop:0,marginLeft:5}}
          // validators={['required']}
          // errorMessages={['部屋名を入力してください']}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
        />
        </Grid>
        <Grid item xs={12}>
 <TextValidator
          id="outlined-basic"
        //   className={classes.textField}
          label="共益費"
          margin="normal"
          type="number"
          onChange={this.handleChange}
          name="kyouekihiAmount"
          value={this.state.introduction_details.kyouekihiAmount===0?"":this.state.introduction_details.kyouekihiAmount}
          style={{width:"100px",marginTop:0,marginLeft:5}}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
        />
  </Grid>     
  <Grid item xs={12}>
<TextValidator
          id="outlined-basic"
        //   className={classes.textField}
          label="水道代"
          margin="normal"
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
          type="number"
          onChange={this.handleChange}
          name="suidouAmount"
          value={this.state.introduction_details.suidouAmount===0?"":this.state.introduction_details.suidouAmount}
          style={{float:"left",width:"100px",marginTop:0,marginLeft:5}}
        
          // validators={['required']}
          // errorMessages={['部屋名を入力してください']}
        />
        <FormControl    style={{float:"left",marginLeft:"10px",marginTop:"17px"}}>
        { 
        /* <InputLabel id="demo-simple-select-helper-label"></InputLabel> */}
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={this.state.introduction_details.suidouFlg===0?1:this.state.introduction_details.suidouFlg}
     
          onChange={this.handleChange}
          name="suidouFlg"
        >
          
          <MenuItem value={1}>実費</MenuItem>
          <MenuItem value={2}>定額</MenuItem>
          <MenuItem value={3}>込み</MenuItem>
     
        </Select>

        

      </FormControl>
</Grid>
<Grid item xs={12}>
<TextValidator
          id="outlined-basic numberform"
         
        //   className={classes.textField}
          label="駐車料"
          margin="normal"
          type="number"
          onChange={this.handleChange}
          name="parkingAmount"
          value={this.state.introduction_details.parkingAmount===0?"":this.state.introduction_details.parkingAmount}
          style={{float:"left",width:"100px",marginTop:0,marginLeft:5}}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
          // validators={['required']}
          // errorMessages={['部屋名を入力してください']}
        />

<FormControl    style={{float:"left",marginLeft:"10px"}}>
        <InputLabel id="demo-simple-select-helper-label">区分</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={this.state.introduction_details.parkingFlg?0:this.state.introduction_details.parkingFlg}
     
          onChange={this.handleChange}
          name="parkingFlg"
        >
          
          <MenuItem value={0}>-</MenuItem>
          <MenuItem value={2}>込み</MenuItem>
          <MenuItem value={3}>無し</MenuItem>
     
        </Select>

        

      </FormControl>
        </Grid>
        </Grid>
</ValidatorForm>

</div>
    
                      
                        









                      
      



<Button
                    color="primary"
                    variant="contained"
                    type="button"
                    style={{float:"right"}}
                    onClick={this.save}
                >保存
                </Button>


                        </div>
                 
    
    );
    
  }
}




//コンテナ
const mapStateToProps = state => {
    return {

      introduction_file_id: state.introductionlist.introduction_file_id,
      introduction_details: state.introductionlist.introduction_details,
      introduction_pdf: state.introductionlist.introduction_pdf,
        // send_mail_cc_disp:state.mailReducer.send_mail_cc_disp,
        // send_mail_bcc_disp:state.mailReducer.send_mail_bcc_disp,
        // mail_detail_files:state.mailReducer.mail_detail_files,
        // send_edit_contents:state.mailReducer.send_edit_contents,
        // mail_new:state.mailReducer.mail_new,
        // mail_minimum:state.mailReducer.mail_minimum,
        // responseid: state.responsesReducer.responseid,
        // editor_height: state.mailReducer.editor_height,
        // editor_width: state.mailReducer.editor_width,
        // send_mail_loading:state.mailReducer.send_mail_loading,
        // send_mail_alert:state.mailReducer.send_mail_alert,
        // get_mail_detail_files_loading:state.mailReducer.get_mail_detail_files_loading,
        // get_mail_details_loading:state.mailReducer.get_mail_details_loading,
        // draggable:state.masterReducer.draggable,
        
        
    }
}
function mapDispatchToProps(dispatch) {
    return {
      insert_introductions(state) {
        dispatch(IntroductionList.insert_introductions(state))
      },
insert_introduction_files(state) {
  dispatch(IntroductionList.insert_introduction_files(state))
},
add_introduction_file(state) {
  dispatch(IntroductionList.add_introduction_file(state))
},
change_introduction_pdf(state) {
  dispatch(IntroductionList.change_introduction_pdf(state))
},
change_introduction_pdf_img(state) {
  dispatch(IntroductionList.change_introduction_pdf_img(state))
},
get_introduction_details(state) {
  dispatch(IntroductionList.get_introduction_details(state))
},
get_introduction_file(state) {
  dispatch(IntroductionList.get_introduction_file(state))
},
    };
    }


export default connect(mapStateToProps, mapDispatchToProps)(FileUpComponent);
