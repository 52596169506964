import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import { Modal } from 'reactstrap';
import { Table, Column, Cell } from 'fixed-data-table-2';

import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import CorporatesTop from './CorporatesTop';
import CloseIcon from '@mui/icons-material/Close';

// import CountComing from './Gadgets/CountComing';
// import ResponseCount from './Gadgets/ResponseCount/Index';
import * as Responses from '../ResponseDetail/Saga/Responses';
import './Css/Grid.css'
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
// var CSVLink = require('react-csv');
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';

var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
  };

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

const useStyles = makeStyles(theme => ({
    dialogPaper: {

        height: '900px'
    },
}));

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))


const headers = [
    { label: "ID", key: "id" },
    { label: "法人名", key: "name" },
    { label: "カナ", key: "kana" },

    { label: "最終営業対応履歴日", key: "last_contact_at" },
    { label: "営業対応履歴数", key: "contact_count" },
    { label: "主要取引先", key: "main_corp" },
    { label: "その他取引先", key: "other_main_corp" },
    
    { label: "反響数", key: "response_no" },
    { label: "契約数", key: "contraction_no" },
    { label: "法人反響数", key: "corporate_response_no" },
    { label: "法人契約数", key: "corporate_contraction_no" },

    { label: "個人反響数", key: "parsonal_response_no" },
    { label: "個人契約数", key: "personal_contraction_no" },
    
    { label: "代表者", key: "president" },
    { label: "代表者役職", key: "position" },
    { label: "資本金", key: "capital" },
    { label: "設立年月日", key: "establish" },
    { label: "創業年月日", key: "start_of_business" },
    { label: "事業所数", key: "office" },
    { label: "従業員数", key: "employee" },
    { label: "HP", key: "url" },
    { label: "事業内容", key: "vision" },

    { label: "最終更新者", key: "updated_user_id" },
    { label: "最終更新日", key: "updated_at" },
    

];

class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modal_contacts: false,
            modal_replace: false,
            // modalselect: false,
            change: false,
            search: '',

            columnWidths: {
                id : 60,
                name: 250,
                kana: 250,
                post: 100,
                add: 300,

                president: 100,
                position: 100,
                capital: 100,
                establish: 100,
                start_of_business: 100,
                office: 100,
                employee: 100,
                url: 100,
                vision: 100,

                response_no: 60,
                contraction_no: 60,
                corporate_response_no: 60,
                corporate_contraction_no: 60,

                personal_response_no: 60,
                personal_contraction_no: 60,

                last_contact_at: 90,
                contact_count: 60,
                
                main_corp: 90,
                other_main_corp: 90,

                updated_user_id: 90,
                updated_at: 150,
            },

            colSortDirs: {},
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);

        this.handleOpen = this.handleOpen.bind(this);
        this.handleDetailOpen = this.handleDetailOpen.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
        this.RowClick = this.RowClick.bind(this);

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        this._onSortChange = this._onSortChange.bind(this);

    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            },
        }));
    }


    handleOpen(id) {
        // this.props.set_rent_m_corporates(id)

        let rent_m_corporates = this.props.rent_m_corporates
        rent_m_corporates.id = id
        this.props.change_rent_m_corporates(rent_m_corporates);
        this.setState({
            change: !this.state.change
        });

        this.props.change_rent_m_corporates_top_open(true)
    }

    handleDetailOpen() {
        // 新規
        let rent_m_corporates = { id: '', kubun: 1, name: "", kana: "", post: "", add1: "", add2: "", tel1: "", url: "", capital: "", establish: "", start_of_business: "", president: "", position: "", office: "", employee: "", vision: "", lat: "", lng: "", is_display: 0, }
        this.props.change_rent_m_corporates(rent_m_corporates)

        this.props.change_rent_m_corporates_detail_open(true)
    }

    handleClear() {
        let search_data_replace = { m_corporate_name: "", select_m_corporate_id: "", customer_name: "", }
        this.props.change_search_data_replace(search_data_replace);
        this.props.change_rent_m_corporates_list_replace([])
        this.props.change_rent_m_corporates_list_replace_search([])
        this.props.change_rent_customer_list_replace([])
        this.props.change_rent_customer_list_replace_search([])

    }

    _onSortChange(columnKey, sortDir) {

        let rent_m_corporates_list_search = this.props.rent_m_corporates_list;
        rent_m_corporates_list_search.sort((indexA, indexB) => {
          // alert(indexA)
    
          var valueA = indexA[columnKey];
          var valueB = indexB[columnKey];
          var sortVal = 0;
          if (valueA > valueB) {
            sortVal = 1;
          }
          if (valueA < valueB) {
            sortVal = -1;
          }
    
          if (!valueA && !valueB) {
            sortVal = 0
          }
          if (!valueA) {
            sortVal = -1
          }
          if (!valueB) {
            sortVal = 1
          }
    
    
          if (sortVal !== 0 && sortDir === SortTypes.DESC) {
            sortVal = sortVal * -1;
          }
    
          return sortVal;
        });
        this.props.change_rent_m_corporates_list_search(rent_m_corporates_list_search)
        this.setState({
          // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
          colSortDirs: {
            [columnKey]: sortDir,
          },
        });
      }
    
    RowClick(event,index){

        let rent_m_corporates = this.props.rent_m_corporates
        rent_m_corporates.id = this.props.rent_m_corporates_list[index].id
        this.props.change_rent_m_corporates(rent_m_corporates);
        this.setState({
            change: !this.state.change
        });

        this.props.change_rent_m_corporates_top_open(true)

    }

    _rowClassNameGetter(index){
        
        if(this.props.rent_m_corporates_list && this.props.rent_m_corporates_list[index]) {
        //   return this.props.owner_eigyou[index].type == 1 ? "line" : 
        //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
        //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
            return "normal"
        }else{
          return ""
        }
    }

    render() {

        return (
            <div style={{position: "relative", textAlign:"left"}}>

                <Table
                    className="gridCorporateNormalList"
                    rowHeight={34}
                    rowsCount={this.props.rent_m_corporates_list.length}
                    width={window.innerWidth - 340}
                    height={window.innerHeight - 60}
                    headerHeight={40}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    onRowClick={this.RowClick}
                    rowClassNameGetter={this._rowClassNameGetter}
                >

                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{ marginTop: -5 }} aria-label="edit" size="small" onClick={() => { this.handleOpen(this.props.rent_m_corporates_list[rowIndex].id) }}><EditIcon /></IconButton>{this.props.rent_m_corporates_list[rowIndex].id}</Cell>)} width={40} /> */}
                    <Column width={this.state.columnWidths.id} isResizable={true} columnKey="id" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>法人ID</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].id}</Cell>)} />
                    <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.name} style={{ whiteSpace: "nowrap" }}>法人名</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].name}</Cell>)} />
                    <Column width={this.state.columnWidths.kana} isResizable={true} columnKey="kana" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.kana} style={{ whiteSpace: "nowrap" }}>カナ</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].kana}</Cell>)} />

                    <Column width={this.state.columnWidths.response_no} isResizable={true} columnKey="response_no" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.response_no} style={{ whiteSpace: "nowrap" }}>反響数</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].response_no}</Cell>)} />
                    <Column width={this.state.columnWidths.contraction_no} isResizable={true} columnKey="contraction_no" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.contraction_no} style={{ whiteSpace: "nowrap" }}>契約数</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].contraction_no}</Cell>)} />
                    <Column width={this.state.columnWidths.corporate_response_no} isResizable={true} columnKey="corporate_response_no" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.corporate_response_no} style={{ whiteSpace: "nowrap" }}>法人反響数</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].corporate_response_no}</Cell>)} />
                    <Column width={this.state.columnWidths.corporate_contraction_no} isResizable={true} columnKey="corporate_contraction_no" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.corporate_contraction_no} style={{ whiteSpace: "nowrap" }}>法人契約数</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].corporate_contraction_no}</Cell>)} />
                    
                    <Column width={this.state.columnWidths.personal_response_no} isResizable={true} columnKey="personal_response_no" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.personal_response_no} style={{ whiteSpace: "nowrap" }}>個人反響数</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].response_no - this.props.rent_m_corporates_list[rowIndex].corporate_response_no}</Cell>)} />
                    <Column width={this.state.columnWidths.personal_contraction_no} isResizable={true} columnKey="personal_contraction_no" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.personal_contraction_no} style={{ whiteSpace: "nowrap" }}>個人契約数</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].contraction_no - this.props.rent_m_corporates_list[rowIndex].corporate_contraction_no}</Cell>)} />
                    
                    <Column width={this.state.columnWidths.contact_count} isResizable={true} columnKey="contact_count" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.contact_count} style={{ whiteSpace: "nowrap" }}>営業対応履歴件数</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].contact_count}</Cell>)} />
                    <Column width={this.state.columnWidths.last_contact_at} isResizable={true} columnKey="last_contact_at" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.last_contact_at} style={{ whiteSpace: "nowrap" }}>最終営業対応履歴日</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].last_contact_at ? moment(this.props.rent_m_corporates_list[rowIndex].last_contact_at).format("YYYY-MM-DD"): ""}</Cell>)} />
                    <Column width={this.state.columnWidths.main_corp} isResizable={true} columnKey="main_corp" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.main_corp} style={{ whiteSpace: "nowrap" }}>主要取引先</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].main_corp == 1 ? "穴吹ハウジング" : this.props.rent_m_corporates_list[rowIndex].main_corp == 2 ? "その他" : this.props.rent_m_corporates_list[rowIndex].main_corp == 3 ? "穴吹ハウジング or その他" : ""}</Cell>)} />
                    <Column width={this.state.columnWidths.other_main_corp} isResizable={true} columnKey="other_main_corp" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.other_main_corp} style={{ whiteSpace: "nowrap" }}>その他主要取引先</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].other_main_corp}</Cell>)} />

                    <Column width={this.state.columnWidths.president} isResizable={true} columnKey="president" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.president} style={{ whiteSpace: "nowrap" }}>代表者</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].president}</Cell>)} />
                    <Column width={this.state.columnWidths.position} isResizable={true} columnKey="position" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.position} style={{ whiteSpace: "nowrap" }}>代表者役職</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].position}</Cell>)} />
                    <Column width={this.state.columnWidths.capital} isResizable={true} columnKey="capital" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.capital} style={{ whiteSpace: "nowrap" }}>資本金</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].capital}</Cell>)} />
                    <Column width={this.state.columnWidths.establish} isResizable={true} columnKey="establish" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.establish} style={{ whiteSpace: "nowrap" }}>設立年月日</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].establish}</Cell>)} />
                    <Column width={this.state.columnWidths.start_of_business} isResizable={true} columnKey="start_of_business" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.start_of_business} style={{ whiteSpace: "nowrap" }}>創業年月日</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].start_of_business}</Cell>)} />
                    <Column width={this.state.columnWidths.office} isResizable={true} columnKey="office" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.office} style={{ whiteSpace: "nowrap" }}>事業所数</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].office}</Cell>)} />
                    <Column width={this.state.columnWidths.employee} isResizable={true} columnKey="employee" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.employee} style={{ whiteSpace: "nowrap" }}>従業員数</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].employee}</Cell>)} />
                    <Column width={this.state.columnWidths.url} isResizable={true} columnKey="url" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.url} style={{ whiteSpace: "nowrap" }}>HP</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].url}</Cell>)} />
                    <Column width={this.state.columnWidths.vision} isResizable={true} columnKey="vision" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.vision} style={{ whiteSpace: "nowrap" }}>事業内容</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].vision}</Cell>)} />

                    <Column width={this.state.columnWidths.updated_user_id} isResizable={true} columnKey="updated_user_id" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.updated_user_id} style={{ whiteSpace: "nowrap" }}>最終更新者</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].updated_user_id ? this.props.users.filter(a=>a.id == this.props.rent_m_corporates_list[rowIndex].updated_user_id)[0].userNameAll : ""}</Cell>)} />
                    <Column width={this.state.columnWidths.updated_at} isResizable={true} columnKey="updated_at" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.updated_at} style={{ whiteSpace: "nowrap" }}>最終更新日</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_m_corporates_list[rowIndex].updated_at ? moment(this.props.rent_m_corporates_list[rowIndex].updated_at).format("YYYY-MM-DD HH:mm:ss"): ""}</Cell>)} />
                </Table>

                <Modal fade={false} isOpen={this.props.rent_m_corporates_top_open} toggle={() => { this.props.change_rent_m_corporates_top_open(false) }} size="xxl" >

                    <div><IconButton
                        onClick={() => { this.props.change_rent_m_corporates_top_open(false) }}
                        style={{ float: "right", marginTop: 0, marginRight: 0 }}
                        size="large"><CloseIcon /></IconButton></div>

                    {/* <IconButton aria-label="edit" size="small" style={{width:40, float:"right", marginRight:0}} onClick={this.handleClose}><CloseIcon /></IconButton> */}
                    <CorporatesTop rent_m_corporate_id={this.props.rent_m_corporates.id}></CorporatesTop>



                    {/* <Button onClick={this.handleClose}>閉じる</Button> */}

                </Modal>

            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates_list: state.corporation.rent_m_corporates_list,
        rent_m_corporates_list_search: state.corporation.rent_m_corporates_list_search,
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_m_corporates_top_open: state.corporation.rent_m_corporates_top_open,
        search_data: state.corporation.search_data,
        modalResponseDetail: state.responsesReducer.modalResponseDetail,
        responseid: state.responsesReducer.responseid,
        rent_m_corporates_detail_open: state.corporation.rent_m_corporates_detail_open,

        user_details: state.masterReducer.user_details,
        users: state.masterReducer.users,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_search_data(state) {
            dispatch(Corporation.change_search_data(state))
        },
        get_rent_m_corporates_list(state) {
            dispatch(Corporation.get_rent_m_corporates_list(state))
        },
        change_rent_m_corporates_top_open(state) {
            dispatch(Corporation.change_rent_m_corporates_top_open(state))
        },
        insert_rent_m_corporates(state) {
            dispatch(Corporation.insert_rent_m_corporates(state))
        },
        update_rent_m_corporates(state) {
            dispatch(Corporation.update_rent_m_corporates(state))
        },
        change_rent_m_corporates(state) {
            dispatch(Corporation.change_rent_m_corporates(state))
        },
        set_rent_m_corporates(state) {
            dispatch(Corporation.set_rent_m_corporates(state))
        },
        get_rent_m_corporates_list_search(state) {
            dispatch(Corporation.get_rent_m_corporates_list_search(state))
        },
        change_responseid(state) {
            dispatch(Responses.change_responseid(state))
        },
        change_modalResponseDetail(state) {
            dispatch(Responses.change_modalResponseDetail(state))
        },


        change_search_data_replace(state) {
            dispatch(Corporation.change_search_data_replace(state))
        },
        change_rent_m_corporates_list_replace(state) {
            dispatch(Corporation.change_rent_m_corporates_list_replace(state))
        },
        change_rent_m_corporates_list_replace_search(state) {
            dispatch(Corporation.change_rent_m_corporates_list_replace_search(state))
        },
        change_rent_customer_list_replace(state) {
            dispatch(Corporation.change_rent_customer_list_replace(state))
        },
        change_rent_customer_list_replace_search(state) {
            dispatch(Corporation.change_rent_customer_list_replace_search(state))
        },

        change_rent_m_corporates_detail_open(state) {
            dispatch(Corporation.change_rent_m_corporates_detail_open(state))
        },

        change_rent_m_corporates_list_search(state) {
            dispatch(Corporation.change_rent_m_corporates_list_search(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




