const authentication = require('../../../react-azure-adb2c2').default;







export const update_settings = (values: any) => {


  const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/m/contract/follow/settings/update`;
  // let url = `http://localhost:5000/v1/rent/m/contract/follow/settings/update`;
  const token = authentication.getAccessToken();
  // console.log(JSON.stringify(values))
  // alert(JSON.stringify(values))
  // alert(url)

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));



  //  return  fetch(url, {
  //             method: 'get',
  //             // body: JSON.stringify(search),

  //             headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  //         }).then(res => res.json())
  //         .then(payload => ({ payload }))
  //             .catch(error => ({ error }));
  //       return  fetch(url, {
  //       method: 'get',
  //       // body: JSON.stringify(accountinglistset),

  //       headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  //   })
  //   .then(res => res.json())
  //   .then(payload => ({ payload }))
  //       .catch(error => ({ error }));






};



