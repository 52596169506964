import React, { Component } from 'react';

import { connect } from 'react-redux';

import DateComponent from './DateComponent';

import '../../Css/Table.css';
import TimeStateComponent from './TimeStateComponent';

import './Css/Reserve.css';


class Reserve extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     // gasu: this.props.responsedetail.gasu,
        //     // denki: this.props.responsedetail.denki,
       
        // };

      

        // this.onChange = this.onChange.bind(this);

    }
  


    componentDidMount() {
 
    
     
    }

    componentDidUpdate() {
    
    }

    ensureDataFetched() {
   
    }

    render() {
        //TestData
        const dateData2 = [["2019/9/4","2019/9/5","2019/9/6","2019/9/7","2019/9/8","2019/9/9","2019/9/10","2019/9/11","2019/9/12","2019/9/13","2019/9/14","2019/9/15","2019/9/16","2019/9/17"],
   [4,0,1,1,1,0,0,4,1,2,1,0,3,1],
   [4,0,1,1,1,0,0,4,1,2,1,0,3,0],
   [4,0,1,1,1,0,0,4,1,2,1,0,3,0],
   [4,0,1,1,1,0,0,4,1,2,1,0,2,1],
   [4,0,1,1,1,0,0,4,1,2,1,0,3,3],
   [4,0,1,1,1,0,0,4,1,2,1,0,1,1],
   [4,0,1,1,1,0,0,4,1,2,1,0,1,0],
   [4,0,1,1,1,0,0,4,1,2,1,0,0,0],
   [4,0,1,1,1,0,0,4,1,2,1,0,0,2],
   [4,0,1,1,1,0,0,4,1,2,1,0,0,0]
 ];
 const dateData1 = [["2019/9/25","2019/9/26","2019/9/27","2019/9/28","2019/9/29","2019/9/30","2019/10/1","2019/10/2","2019/10/3","2019/10/4","2019/10/5","2019/10/6","2019/10/7","2019/10/8"],
 [4,0,1,1,1,0,0,4,1,2,1,0,3,1],
 [4,0,1,1,1,0,0,4,1,2,1,0,3,0],
 [4,0,1,1,1,0,0,4,1,2,1,0,3,0],
 [4,0,1,1,1,0,0,4,1,2,1,0,2,1],
 [4,0,1,1,1,0,0,4,1,2,1,0,3,3],
 [4,0,1,1,1,0,0,4,1,2,1,0,1,1],
 [4,0,1,1,1,0,0,4,1,2,1,0,1,0],
 [4,0,1,1,1,0,0,4,1,2,1,0,0,0],
 [4,0,1,1,1,0,0,4,1,2,1,0,0,2],
 [4,0,1,1,1,0,0,4,1,2,1,0,0,0]
];
const dateData = [["2019/9/20","2019/9/21","2019/9/22","2019/9/23","2019/9/24","2019/9/25","2019/9/26","2019/9/27","2019/9/28","2019/9/29","2019/9/30","2019/10/1","2019/10/2","2019/10/3"],
[0,1,1,1,0,4,0,1,2,1,0,3,4,1],
[1,0,1,1,1,4,0,0,1,2,1,0,4,0],
[0,0,1,1,0,4,0,1,1,2,1,0,4,0],
[0,0,1,1,1,4,0,2,1,0,1,0,4,1],
[0,0,1,1,0,4,0,0,1,0,1,0,4,3],
[1,0,1,1,0,4,0,0,1,2,1,0,4,1],
[0,0,1,1,0,4,0,0,1,2,1,0,4,0],
[0,0,1,1,0,4,0,0,1,0,1,0,4,0],
[0,2,1,1,1,4,0,0,1,0,1,0,4,2],
[0,0,1,1,0,4,0,0,1,0,1,0,4,0]
];
   
//    dateData[0]
//    dateData[1]

        //月
        const year = new Date(dateData[0][0]).getFullYear();
        const month = new Date(dateData[0][0]).getMonth()+1;
        
        //今月日数
        let thisMonth;
        for(thisMonth=1; thisMonth<14; thisMonth++){
            if ((new Date(dateData[0][thisMonth]).getMonth()+1) != month){
                break;
            }
        }

        //来月日数
        let nextMonth;
        nextMonth = 14 - thisMonth;

        //日付
        const dateCells = dateData[0].map((datecell) =>
            <th><DateComponent date={new Date(datecell)} /></th>
        );
        //ステータス
        const stateCells1 = dateData[1].map((datecell)=>            
            <td rowSpan={datecell===4?10:1}  style={{ backgroundColor: (datecell === 4) ? '#EEEEEE' : '#FFFFFF' }} ><TimeStateComponent state={datecell}/></td>                     
        )
        const stateCells2 = dateData[2].map((datecell) =>
            datecell === 4 ? "" : <td><TimeStateComponent state={datecell}/></td>
        )
        const stateCells3 = dateData[3].map((datecell) =>
            datecell === 4 ? "" : <td><TimeStateComponent state={datecell}/></td>
        )
        const stateCells4 = dateData[4].map((datecell) =>
            datecell === 4 ? "" : <td><TimeStateComponent state={datecell}/></td>
        )
        const stateCells5 = dateData[5].map((datecell) =>
            datecell === 4 ? "" : <td><TimeStateComponent state={datecell}/></td>
        )
        const stateCells6 = dateData[6].map((datecell) =>
            datecell === 4 ? "" : <td><TimeStateComponent state={datecell}/></td>
        )
        const stateCells7 = dateData[7].map((datecell) =>
            datecell === 4 ? "" : <td><TimeStateComponent state={datecell}/></td>
        )
        const stateCells8 = dateData[8].map((datecell) =>
            datecell === 4 ? "" : <td><TimeStateComponent state={datecell}/></td>
        )
        const stateCells9 = dateData[9].map((datecell) =>
            datecell === 4 ? "" : <td><TimeStateComponent state={datecell}/></td>
        )
        const stateCells10 = dateData[10].map((datecell) =>
            datecell === 4 ? "" : <td><TimeStateComponent state={datecell}/></td>
        )

        return (

        <div>
{/* <DateComponent date={new Date()} width={100} height={100} fontSize={30}/> */}
{/* <p>今月の日数：{thisMonth}</p>
<p>来月の日数：{nextMonth}</p> */}
<table class="timeTable">
    <tr>
        <th class="np-cell" rowspan="2">≪ 前の一週間</th>
        <th class="month-cell" colspan={thisMonth}>{year}年{month}月</th>
        {/* {nextMonth !== 0 ? <th class="month-cell" colspan={nextMonth}>{year}年{month+1}月</th> : ""} */}
        {nextMonth === 0 ? "" : (nextMonth < 4 ? <th class="month-cell" colspan={nextMonth}></th> : <th class="month-cell" colspan={nextMonth}>{year}年{month+1}月</th>)}
        <th class="np-cell" rowspan="2">次の一週間 ≫</th>
    </tr>
    <tr>
        {dateCells}
    </tr>
    <tr class="border1">
        <th class="time-cell">9:00</th>
        {stateCells1}
        <th class="time-cell-l">9:00</th>
    </tr>
    <tr>
        <th class="time-cell">10:00</th>
        {stateCells2}
        <th class="time-cell-l">10:00</th>
    </tr>
    <tr>
        <th class="time-cell">11:00</th>
        {stateCells3}
        <th class="time-cell-l">11:00</th>
    </tr>
    <tr>
        <th class="time-cell">12:00</th>
        {stateCells4}
        <th class="time-cell-l">12:00</th>
    </tr>
    <tr>
        <th class="time-cell">13:00</th>
        {stateCells5}
        <th class="time-cell-l">13:00</th>
    </tr>
    <tr>
        <th class="time-cell">14:00</th>
        {stateCells6}
        <th class="time-cell-l">14:00</th>
    </tr>
    <tr>
        <th class="time-cell">15:00</th>
        {stateCells7}
        <th class="time-cell-l">15:00</th>
    </tr>
    <tr>
        <th class="time-cell">16:00</th>
        {stateCells8}
        <th class="time-cell-l">16:00</th>
    </tr>
    <tr>
        <th class="time-cell">17:00</th>
        {stateCells9}
        <th class="time-cell-l">17:00</th>
    </tr>
    <tr>
        <th class="time-cell">18:00</th>
        {stateCells10}
        <th class="time-cell-l">18:00</th>
    </tr>
</table>

        </div>
    );
  }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        // setsubi_master: state.roomsReducer.setsubi_maskter,

        // chomei_list: state.search.chomei_list,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {


      
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Reserve);