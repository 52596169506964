import React from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Line from './Saga/Line';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
var moment = require('moment-timezone');

const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin-top:15px;
`;
const Faceicon = styled.div`
    float: left;
        margin-right: -50px;
        width: 40px;
`;
const FaceiconImg = styled.img`
    width: 100%;
        height: auto;
        border-radius: 50%;
`;
const DisplayName = styled.div`
    margin:0 0 0 50px;
    font-size:9px;
    font-weight:bold;
    color:#FFF;
text-align:left;
`;
const Chatting = styled.div`
    width: 100%;
        text-align: left;
`;
const Say = styled.div`
    display: inline-block;
        position: relative; 
        margin: 0 0 0 50px;
        padding: 10px;
        max-width: 250px;
        border-radius: 12px;
        background: #edf1ee;
        margin-bottom: 2px;
    &:after{
        content: "";
        display: inline-block;
        position: absolute;
        top: 3px; 
        left: -19px;
        border: 8px solid transparent;
        border-right: 18px solid #edf1ee;
        -ms-transform: rotate(35deg);
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
}
`;
const SayP = styled.p`
    margin:0；
    height:270px;
`;
const BodyImg = styled.img`
    border-radius: 12px;
height:250px;
`;
const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;bottom: 0px;
    font-size:10px;
    color:#FFF;
`;

const Description = styled.div`
    font-size:10px;
`;
const List = styled.div`
    padding-top:10px;
    padding-bottom:5px;
    border-top:1px solid #dcdcdc;
    width:230px;
    margin:5px;
    margin-left:-10px !important;
`;
//export default
class LineImgComponent extends React.Component {
    constructor(props) {
        super(props)
        const dt = new Date(this.props.msg.date)
        this.state = {
            bloburl: "",
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            images: [],
            currentIndex: 0,
            modalIsOpen: false,
        };

    }




    render() {
        return (
            <div>
                <Balloon className="col-xs-12">
                    <DisplayName>{this.props.msg.display_name}</DisplayName>
                    <Chatting>

                        <Say>
                            {this.props.msg.message_text}
                            <Description>下記よりファイルをダウンロードしてください</Description>
                            <List className="row">
                                
                                <div className="col-lg-12" style={{ fontWeight: "bold", textAlign:"center" }}><Button startIcon={<GetAppIcon />} variant="outlined" color="primary" onClick={() => this.props.download_line_receive_file(this.props.msg)}>ダウンロード</Button></div>
                            </List>
                        </Say>
                        <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time>
                    </Chatting>
                </Balloon>

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        line_images: state.line.line_images

    }
}
function mapDispatchToProps(dispatch) {
    return {
        download_line_receive_file(state) {
            dispatch(Line.download_line_receive_file(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LineImgComponent);
