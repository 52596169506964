import React, { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment'
import * as Responses from './Saga/Responses';

import '../../Css/Table.css';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
class ResponseInfoDisp extends Component {

    constructor(props) {
        super(props);

        this.state = {

            startDate: new Date(),

        };

        // if(this.props.responsedetail.rent_hearing_sheet_id > 0){
        //     this.props.get_rent_hearing_sheets(this.props.responsedetail.rent_hearing_sheet_id)
        // }


    }

    componentDidMount() {



    }



    render() {
        const userid = this.props.responsedetail.userId;
        const user = this.props.users.filter(function (user) {
            return user.id === userid;
        });
        const primaryUser = this.props.users.filter(function (user) {
            return user.id === this.props.responsedetail.primaryUserId
        }, this);

        const firstContactSection = this.props.shops.filter(function (shop) {
            return shop.tenpoNo === this.props.responsedetail.firstContactSectionId
        }, this);
        const sectionId = this.props.responsedetail.sectionId;
        const shop = this.props.shops.filter(function (shops) {
            return shops.tenpoNo === sectionId;
        });
        const rentmmediumid = this.props.responsedetail.rentMMediumId;

        const rentmmedium = this.props.rentMMedeas.filter(function (rentmmedium) {
            return rentmmedium.id === rentmmediumid;
        });

        const rentmagentid = this.props.responsedetail.rentMAgentId;
        const rentmagent = this.props.rentAgents.filter(function (rentmagent) {
            return rentmagent.rentMAgentId === rentmagentid;
        });
        const rentmreasonid = this.props.responsedetail.rentMReasonId;
        const rentmreason = this.props.rentMReasons.filter(function (rentmreason) {
            return rentmreason.id === rentmreasonid;
        });


        console.log(this.props.responsedetail)

        return (

            <div>
                <table className='basicList'>
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <tbody>
                        <tr >
                            <th colSpan={4}><HowToRegIcon /> お客様設定条件
                                {this.props.responsedetail.rent_hearing_sheet_id > 0 ? <Button size="small" color="primary" variant="contained" type="button" style={{float:"right", background:"#31a744", color:"white"}} onClick={() => this.props.download_rent_hearing_sheet(this.props.responsedetail.rent_hearing_sheet_id)} startIcon={<PrintIcon/>}>印刷</Button>:""}
                            </th>
                        </tr>


                        

                        {this.props.responsedetail.rent_hearing_sheet_id > 0 ? <tr>
                            <th>氏名</th>
                            <td colSpan="3">
                                <div style={{fontSize:10}}>{this.props.rent_hearing_sheets.kana1}</div>
                                {this.props.rent_hearing_sheets.name1}
                            </td>
                        </tr>:""}

                        {this.props.responsedetail.rent_hearing_sheet_id > 0 ? <tr>
                            <th>性別</th>
                            <td>{this.props.rent_hearing_sheets.sex == 1 ? "男": this.props.rent_hearing_sheets.sex == 2 ? "女" : ""}</td>
                            <th>生年月日</th>
                            {/* <td>{this.props.rent_hearing_sheets.birthday != null ? moment(this.props.rent_hearing_sheets.birthday).format("YYYY-MM-DD") + "(" + String(moment(new Date()).year() - moment(new Date(this.props.rent_hearing_sheets.birthday)).year()) + "歳)" :""}</td> */}
                            <td>{this.props.rent_hearing_sheets.birthday != null ? moment(this.props.rent_hearing_sheets.birthday).format("YYYY-MM-DD") + "(" + moment().diff(moment(this.props.rent_hearing_sheets.birthday, 'YYYY/MM/DD'), 'years') + "歳)" :""}</td>
                        </tr>:""}

                        {this.props.responsedetail.rent_hearing_sheet_id > 0 ? <tr>
                            <th>住所</th>
                            <td colSpan="3">
                                <div style={{fontSize:10}}>{this.props.rent_hearing_sheets.post}</div>
                                {this.props.rent_hearing_sheets.add1}{this.props.rent_hearing_sheets.add2}{this.props.rent_hearing_sheets.add3}{this.props.rent_hearing_sheets.add4}
                            </td>
                        </tr>:""}







                        <tr style={{ display: this.props.responsedetail.mypage_request_contract_form ? '' : 'none' }}>
                            <th>契約形態</th>
                            <td>
                                法人契約希望
                            </td>

                        </tr>

                        <tr style={{ display: ((this.props.responsedetail.mypage_request_year > 0)) ? '' : 'none' }}>
                            <th>希望築年数</th>
                            <td >
                                {this.props.responsedetail.mypage_request_year + "年以内"}
                            </td>
                        </tr>





                        <tr style={{ display: ((this.props.responsedetail.mypage_request_from_cost != null || this.props.responsedetail.mypage_request_to_cost != null)) ? '' : 'none' }}>
                            <th>希望金額</th>
                            <td >
                                {this.props.responsedetail.mypage_request_from_cost != null ? this.props.responsedetail.mypage_request_from_cost + "円～" : ""}

                                {this.props.responsedetail.mypage_request_to_cost != null ? this.props.responsedetail.mypage_request_to_cost + "円" : ""}
                            </td>
                        </tr>

                        <tr style={{ display: ((this.props.responsedetail.mypage_rent_current > 0)) ? '' : 'none' }}>
                            <th>現在家賃</th>
                            <td >
                                {this.props.responsedetail.mypage_rent_current + "万円"}

                            </td>
                        </tr>
                        <tr style={{ display: ((this.props.responsedetail.mypage_initial_cost > 0)) ? '' : 'none' }}>
                            <th>初期費用</th>
                            <td >
                                {this.props.responsedetail.mypage_initial_cost + "万円"}

                            </td>
                        </tr>

                        <tr style={{ display: ((this.props.responsedetail.mypage_request_car > 0)) ? '' : 'none' }}>
                            <th>必要駐車場台数</th>
                            <td >
                                {this.props.responsedetail.mypage_request_car + "台"}

                            </td>
                        </tr>


                        <tr style={{ display: ((this.props.responsedetail.mypage_request_tatemono_types && this.props.responsedetail.mypage_request_tatemono_types.split(',').length > 0)) ? '' : 'none' }}>
                            <th>希望種別</th>
                            <td >
                                {

                                    this.props.responsedetail.mypage_request_tatemono_types ? this.props.responsedetail.mypage_request_tatemono_types.split(',').map(function (item) {
                                        return (
                                            <span>
                                                {item === "1" ? "アパート " : ""}
                                                {item === "2" ? "マンション " : ""}
                                                {item === "7" ? "戸建て " : ""}

                                            </span>
                                        )
                                    }) : ""
                                }
                            </td>
                        </tr>
                        <tr style={{ display: ((this.props.responsedetail.mypage_request_plans && this.props.responsedetail.mypage_request_plans.split(',').length > 0)) ? '' : 'none' }}>
                            <th>希望間取</th>
                            <td >
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "1")[0] ? "ワンルーム " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "2")[0] ? "1K " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "3")[0] ? "1DK " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "5")[0] ? "1LDK " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "8")[0] ? "2K " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "9")[0] ? "2DK " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "11")[0] ? "2LDK " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "14")[0] ? "3K " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "15")[0] ? "3DK " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "17")[0] ? "3LDK " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "20")[0] ? "4K " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "21")[0] ? "4DK " : "" : ""}
                                {this.props.responsedetail.mypage_request_plans ? this.props.responsedetail.mypage_request_plans.split(',').filter(a => a === "23")[0] ? "4LDK以上 " : "" : ""}


                            </td>
                        </tr>


                        <tr style={{ display: (this.props.responsedetail.mypage_request_means_train || this.props.responsedetail.mypage_request_means_bus || this.props.responsedetail.mypage_request_means_car || this.props.responsedetail.mypage_request_means_bicycle || this.props.responsedetail.mypage_request_means_walk) ? '' : 'none' }}>
                            <th>通勤手段</th>
                            <td >
                                {this.props.responsedetail.mypage_request_means_train ? "電車 " : ""}
                                {this.props.responsedetail.mypage_request_means_bus ? "バス " : ""}
                                {this.props.responsedetail.mypage_request_means_car ? "車 " : ""}
                                {this.props.responsedetail.mypage_request_means_bicycle ? "自転車 " : ""}
                                {this.props.responsedetail.mypage_request_means_walk ? "徒歩 " : ""}
                            </td>
                        </tr>

                        <tr style={{ display: ((this.props.responsedetail.mypage_stations && this.props.responsedetail.mypage_stations.split(',').length > 0)) ? '' : 'none' }}>
                            <th>駅</th>
                            <td >{this.props.responsedetail.mypage_stations ? this.props.responsedetail.mypage_stations.split(',').map(function (item) {
                                return (
                                    <span>
                                        {this.props.m_stations.filter(a => a.local_spot_id === parseInt(item))[0] ? this.props.m_stations.filter(a => a.local_spot_id === parseInt(item))[0].hs_name + " " : ""}
                                    </span>
                                )
                            }, this) : ""}

                            </td>
                        </tr>


                        <tr style={{ display: (this.props.responsedetail.mypage_request_moving_time > 0) ? '' : 'none' }}>
                            <th>引越時期</th>
                            <td >
                                {this.props.responsedetail.mypage_request_moving_time === 1 ? "今月中" : ""}
                                {this.props.responsedetail.mypage_request_moving_time === 2 ? "来月中" : ""}
                                {this.props.responsedetail.mypage_request_moving_time === 3 ? "2ヵ月後" : ""}
                                {this.props.responsedetail.mypage_request_moving_time === 4 ? "3ヵ月後" : ""}
                                {this.props.responsedetail.mypage_request_moving_time === 5 ? "4ヵ月後" : ""}
                                {this.props.responsedetail.mypage_request_moving_time === 6 ? "5ヵ月後" : ""}
                                {this.props.responsedetail.mypage_request_moving_time === 7 ? "半年後" : ""}
                                {this.props.responsedetail.mypage_request_moving_time === 8 ? "一年以内 " : ""}
                            </td>
                        </tr>


                        <tr style={{ display: ((this.props.responsedetail.mypage_student_independent > 0)) ? '' : 'none' }}>
                            <th>社会人・学生</th>
                            <td >
                                {String(this.props.responsedetail.mypage_student_independent) === "1" ? "社会人" : String(this.props.responsedetail.mypage_student_independent) === "2" ? "学生" : ""}
                            </td>
                            <th>{String(this.props.responsedetail.mypage_student_independent) === "1" ? "職業" : String(this.props.responsedetail.mypage_student_independent) === "2" ? "在学" : ""}</th>
                            <td >
                                {String(this.props.responsedetail.mypage_student_independent) === "1" && this.props.responsedetail.mypage_rent_m_job_id > 0 ? this.props.rent_m_jobs.filter(a=>a.id == parseInt(this.props.responsedetail.mypage_rent_m_job_id)).length> 0 ? this.props.rent_m_jobs.filter(a=>a.id == parseInt(this.props.responsedetail.mypage_rent_m_job_id))[0].name : "" : ""}
                                {String(this.props.responsedetail.mypage_student_independent) === "2" ? String(this.props.responsedetail.mypage_student_examinee) === "1" ? "在学中" : String(this.props.responsedetail.mypage_student_examinee) === "2" ? "受験生" : "" : ""}
                            </td>
                        </tr>
                        <tr style={{ display: (this.props.responsedetail.office_name || this.props.responsedetail.office_add) ? '' : 'none' }}>
                            <th>勤務先</th>
                            <td >
                                {this.props.responsedetail.office_name}
                            </td>
                            <th>住所</th>
                            <td >
                                {this.props.responsedetail.office_add}
                            </td>
                        </tr>
                        <tr style={{ display: String(this.props.responsedetail.mypage_request_rent_m_school_id) > 0 || this.props.responsedetail.mypage_request_gakunen > 0 ? '' : 'none' }}>
                            <th>学校名</th>
                            <td >
                                {this.props.rent_m_schools.filter(a=> a.id == parseInt(this.props.responsedetail.mypage_request_rent_m_school_id)).length > 0 ? this.props.rent_m_schools.filter(a=> a.id == parseInt(this.props.responsedetail.mypage_request_rent_m_school_id))[0].name : ""}
                            </td>
                            <th>学年</th>
                            <td >
                                {this.props.responsedetail.mypage_request_gakunen === 1 ? "新入学" : ""}
                                {this.props.responsedetail.mypage_request_gakunen === 2 ? "1回生" : ""}
                                {this.props.responsedetail.mypage_request_gakunen === 3 ? "2回生" : ""}
                                {this.props.responsedetail.mypage_request_gakunen === 4 ? "3回生" : ""}
                                {this.props.responsedetail.mypage_request_gakunen === 5 ? "4回生" : ""}
                                {this.props.responsedetail.mypage_request_gakunen === 6 ? "院生" : ""}
                            </td>
                        </tr>
                        <tr style={{ display: String(this.props.responsedetail.mypage_request_rent_m_highschool_id) > 0 ? '' : 'none' }}>
                            <th>出身校</th>
                            <td >
                                {this.props.responsedetail.mypage_request_rent_m_highschool_id}
                            </td>
                        </tr>

                        <tr style={{ display: ((this.props.responsedetail.mypage_alone_family > 0)) ? '' : 'none' }}>
                            <th>単身・ファミリー</th>
                            <td >
                                {String(this.props.responsedetail.mypage_alone_family) === "1" ? "単身" : String(this.props.responsedetail.mypage_alone_family) === "2" ? "ファミリー" : ""}
                            </td>
                        </tr>
                        <tr style={{ display: ((this.props.responsedetail.mypage_request_number_of_people > 0)) ? '' : 'none' }}>
                            <th>入居人数</th>
                            <td >
                                {String(this.props.responsedetail.mypage_request_number_of_people)}人
                                {this.props.responsedetail.mypage_request_number_of_people_adult > 0 ? "　大人" + String(this.props.responsedetail.mypage_request_number_of_people_adult) + "人" : ""}
                                {this.props.responsedetail.mypage_request_number_of_people_child > 0 ? "　子供" + String(this.props.responsedetail.mypage_request_number_of_people_child) + "人" : ""}
                            </td>
                            <th>駐車場</th>
                            <td >
                                {this.props.responsedetail.mypage_request_number_of_standardcar > 0 ? "普通：" + String(this.props.responsedetail.mypage_request_number_of_standardcar) + "台　" : ""}
                                {this.props.responsedetail.mypage_request_number_of_lightcar > 0 ? "軽：" + String(this.props.responsedetail.mypage_request_number_of_lightcar) + "台" : ""}
                            </td>
                        </tr>

                        <tr style={{ display: ((this.props.responsedetail.mypage_request_areas && this.props.responsedetail.mypage_request_areas.split(',').length > 0) || this.props.responsedetail.mypage_area_remark || this.props.responsedetail.mypage_request_areas_request > 0) ? '' : 'none' }}>
                            <th>地域</th>
                            <td colSpan="3">
                                {String(this.props.responsedetail.mypage_request_areas_request) === "1" ? "希望あり　" : ""}
                                {String(this.props.responsedetail.mypage_request_areas_request) === "2" ? "希望なし　" : ""}
                                {String(this.props.responsedetail.mypage_request_areas_request) === "3" ? "相談したい　" : ""}
                                {

                                    this.props.responsedetail.mypage_request_areas ? this.props.responsedetail.mypage_request_areas.split(',').map(function (item) {
                                        return (
                                            <span>
                                                {item === "1" ? "高松市中心部 " : ""}
                                                {item === "2" ? "レインボー・サンフラワー周辺 " : ""}
                                                {item === "3" ? "高松市南部 " : ""}
                                                {item === "4" ? "高松市東部 " : ""}
                                                {item === "5" ? "高松市西部 " : ""}
                                                {item === "6" ? "香川町　三木町 " : ""}
                                                {item === "7" ? "庵治町・牟礼町 " : ""}
                                                {item === "8" ? "高松市全域 " : ""}


                                            </span>
                                        )
                                    }) : ""
                                }





                                {this.props.responsedetail.mypage_area_remarks}
                            </td>
                        </tr>


                        <tr style={{ display: this.props.responsedetail.mypage_request_shougakkou ? '' : 'none' }}>
                            <th>小学校区</th>
                            <td colSpan="3">


                                {this.props.responsedetail.mypage_request_shougakkou && this.props.shougakkous.length > 0 ? this.props.responsedetail.mypage_request_shougakkou.split(',').map(function (item) {

                                    return this.props.shougakkous.filter(a => a.shougakkouNo === parseInt(item))[0].shougakkouName

                                }, this).join(',') : ""
                                }
                            </td>
                        </tr>

                        <tr style={{ display: this.props.responsedetail.mypage_request_chuugakkou ? '' : 'none' }}>
                            <th>中学校区</th>
                            <td colSpan="3">


                                {this.props.responsedetail.mypage_request_chuugakkou && this.props.chuugakkous.length > 0 ? this.props.responsedetail.mypage_request_chuugakkou.split(',').map(function (item) {

                                    return this.props.chuugakkous.filter(a => a.chuugakkouNo === parseInt(item))[0].chuugakkouName

                                }, this).join(',') : ""
                                }
                            </td>
                        </tr>





                        <tr style={{ display: this.props.responsedetail.mypage_request_remarks && this.props.responsedetail.mypage_response_requests && this.props.responsedetail.mypage_response_requests.split(',').length > 0 ? '' : 'none' }}>

                            <th>こだわり</th>
                            <td colSpan="3">


                                {this.props.responsedetail.mypage_response_requests ? this.props.responsedetail.mypage_response_requests.split(',').map(function (value, index) {
                                    
                                    for (var stationery in this) {
                                        if (this[stationery].setsubiNo === Number(value)) {
                                            if(this[stationery].setsubiNo === 104){
                                                return this[stationery].setsubiName;
                                            }else{
                                                return this[stationery].setsubiName;
                                            }
                                            
                                        }
                                    }
                                }, this.props.setsubi_master).join(',') : "" //第2引数にオブジェクトを指定
                                }


                                {this.props.responsedetail.mypage_request_remarks ? "(" + this.props.responsedetail.mypage_request_remarks + ")" : ""}
                                {String(this.props.responsedetail.mypage_request_bike) === "1" ? "(バイク：原付)" : String(this.props.responsedetail.mypage_request_bike) === "2" ? "(バイク：小型)" : String(this.props.responsedetail.mypage_request_bike) === "3" ? "(バイク：中型)"  : String(this.props.responsedetail.mypage_request_bike) === "4" ? "(バイク：大型)" : ""}
                            </td>
                        </tr>



                        <tr style={{ display: this.props.responsedetail.mypage_dog ? '' : 'none' }}>
                            <th>犬飼育</th>
                            <td colSpan="3">


                                犬{this.props.responsedetail.mypage_dog_count ? this.props.responsedetail.mypage_dog_count + "匹　" : ""}
                                {this.props.responsedetail.mypage_dog_remarks ? "(" + this.props.responsedetail.mypage_dog_remarks + ")" : ""}

                            </td>
                        </tr>
                        <tr style={{ display: this.props.responsedetail.mypage_cat ? '' : 'none' }}>
                            <th>猫飼育</th>
                            <td colSpan="3">


                                猫{this.props.responsedetail.mypage_cat_count ? this.props.responsedetail.mypage_cat_count + "匹　" : ""}
                                {this.props.responsedetail.mypage_cat_remarks ? "(" + this.props.responsedetail.mypage_cat_remarks + ")" : ""}

                            </td>
                        </tr>
                        <tr >
                            <th>編集日時</th>
                            <td >


                                {moment(this.props.responsedetail.mypage_edited_at).format("YYYY年MM月DD日 H:mm")}

                            </td>
                        </tr>

                    </tbody>
                </table>

            </div >
        );
    }
}




//containers
const mapStateToProps = state => {
    return {
        // responseedit: state.responsesReducer.responseedit,
        //customerdetail: state.customersReducer.customerdetail,
        // responsedetail: state.responsesReducer.responsedetail,
        rentMMedeas: state.masterReducer.rentMMedeas,
        users: state.masterReducer.users,
        rentAgents: state.masterReducer.rentAgents,
        rentMReasons: state.masterReducer.rentMReasons,
        shops: state.masterReducer.shops,
        setsubi_master: state.masterReducer.setsubi_master,
        chomeis: state.masterReducer.chomeis,
        shougakkous: state.masterReducer.shougakkous,
        chuugakkous: state.masterReducer.chuugakkous,
        m_stations: state.masterReducer.m_stations,

        rent_m_medea: state.responsesReducer.rent_m_medea,
        rent_m_reasons: state.responsesReducer.rent_m_reasons,
        rent_m_schools: state.responsesReducer.rent_m_schools,
        rent_m_jobs: state.responsesReducer.rent_m_jobs,

        rent_hearing_sheets: state.responsesReducer.rent_hearing_sheets,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        responseeditchange(state) {
            dispatch(Responses.responseeditchange(state))
        },
        get_rent_hearing_sheets(state) {
            dispatch(Responses.get_rent_hearing_sheets(state))
        },
        download_rent_hearing_sheet(state) {
            dispatch(Responses.download_rent_hearing_sheet(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseInfoDisp);




