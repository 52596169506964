import React, { Component } from 'react';

import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';

//import { actionCreators } from '../../Store/WeatherForecasts';

import { reduxForm } from 'redux-form';
// import { Button, Modal } from 'reactstrap';
import '../../Css/Table.css';
// import * as AutoKana from 'vanilla-autokana';




// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数


class TatemonoDispComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerinfo: this.props.forecasts,
            startDate: new Date()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        //this.onChange = this.onChange.bind(this);
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }




    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }

    openOwner(id){
        window.open(process.env.REACT_APP_API_PM_URL+`/Owner/` + id, '_blank', 'noopener')
    }


    render() {

        return (
            <div>
                {/* <button className="btn btn-sm btn-success float-right" style={{ display: (this.props.notedit === 1) ? 'none' : '' }} onClick={this.props.tatemonoeditchange}>編集</button> */}


                <table className="disp_table">
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <colgroup width='20%' />
                    <colgroup width='30%' />

                    <tbody>
                        {/* <tr >
                        <th>建物名</th>
                        <td colSpan="3">
                            <div style={{ fontSize: 5}}>{this.props.roomdetail.tatemonoKana}</div>
                            <b>{this.props.roomdetail.tatemonoName}</b>
                        </td>
                    </tr> */}
                        <tr >
                            <th>住所</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    {this.props.roomdetail.zip_1}-{this.props.roomdetail.zip_2} {this.props.roomdetail.todoufuken}{this.props.roomdetail.shikuchoson}{this.props.roomdetail.choiki}{this.props.roomdetail.other_address}
                                </div>
                            </td>
                        </tr>
                        <tr >
                            <th>建物種別</th>
                            <td>
                                {this.props.roomdetail.tatemono_shubetsu_name}
                            </td>
                            <th>構造</th>
                            <td>
                                {this.props.roomdetail.kouzou_name}
                            </td>
                        </tr>
                        <tr >
                            <th>建物面積</th>
                            <td >
                                {(() => {
                                    if (this.props.roomdetail.tatemono_menseki > 0) {
                                        return String(Math.floor(this.props.roomdetail.tatemono_menseki * 100) / 100) + "㎡";
                                    }
                                })()}
                            </td>
                            <th>延床面積</th>
                            <td>
                                {(() => {
                                    if (this.props.roomdetail.nobeyuka_menseki > 0) {
                                        return String(Math.floor(this.props.roomdetail.nobeyuka_menseki * 100) / 100)  + "㎡";
                                    }
                                })()}
                            </td>
                        </tr>
                        <tr >
                            <th>竣工日</th>
                            <td>
                                <div className="form-inline">
                                    {this.props.roomdetail.shunkou_year}年{this.props.roomdetail.shunkou_month}月{this.props.roomdetail.shunkou_day}日
                            </div>
                            </td>
                            <th>総戸数</th>
                            <td>
                                <div className="form-inline">
                                    {this.props.roomdetail.soukosuu}戸
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th>階建</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    地上：{this.props.roomdetail.kaidate_chijou}階
                            地下：{this.props.roomdetail.kaidate_chika}階
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th>管理会社</th>
                            <td colSpan="3">
                                {this.props.roomdetail.kanri_corp_name}
                            </td>
                        </tr>

                        <tr>
                            <th>交通1</th>
                            <td colSpan="3">
                                {(() => {
                                    if (this.props.roomdetail.koutsuu_1 !== "") {
                                        return this.props.roomdetail.koutsuu_1 + "から徒歩約" + this.props.roomdetail.koutsuu_1_min + "分以内";
                                    }
                                })()}
                            </td>
                        </tr>
                        <tr >
                            <th>交通2</th>
                            <td colSpan="3">
                                {(() => {
                                    if (this.props.roomdetail.koutsuu_2 !== "") {
                                        return this.props.roomdetail.koutsuu_2 + "から徒歩約" + this.props.roomdetail.koutsuu_2_min + "分以内";
                                    }
                                })()}
                            </td>
                        </tr>
                        <tr >
                            <th>交通3</th>
                            <td colSpan="3">
                                {(() => {
                                    if (this.props.roomdetail.koutsuu_3 !== "") {
                                        return this.props.roomdetail.koutsuu_3 + "から徒歩約" + this.props.roomdetail.koutsuu_3_min + "分以内";
                                    }
                                })()}
                            </td>
                        </tr>
                        <tr >
                            <th>小学校</th>
                            <td>
                                {(() => {
                                    if (this.props.roomdetail.shougakkou !== "") {
                                        return this.props.roomdetail.shougakkou;
                                    }
                                })()}
                            </td>
                            <th>中学校</th>
                            <td>
                                {(() => {
                                    if (this.props.roomdetail.chuugakkou !== "") {
                                        return this.props.roomdetail.chuugakkou;
                                    }
                                })()}
                            </td>
                        </tr>
                        <tr>
                            <th>エレベーター</th>
                            <td colSpan="3">
                                <div className="form-inline">
                                    {(() => {
                                        if (this.props.roomdetail.have_elevator === 0) {
                                            return "なし";
                                        } else if (this.props.roomdetail.have_elevator === 1) {
                                            return "あり";
                                        } else {
                                            return "";
                                        }
                                    })()}
                                </div>
                            </td>
                        </tr>
                        <tr >
                            <th>エリア</th>
                            <td>
                                <div className="form-inline">
                                    {this.props.roomdetail.area}
                                </div>
                            </td>
                            <th>担当店舗</th>
                            <td>
                                <div className="form-inline">
                                    {(() => {
                                        if (this.props.roomdetail.shop === 1) {
                                            return "高松番町店";
                                        } else if (this.props.roomdetail.shop === 2) {
                                            return "レインボーロード店";
                                        } else if (this.props.roomdetail.shop === 3) {
                                            return "高松駅前店";
                                        } else if (this.props.roomdetail.shop === 4) {
                                            return "高松東店";
                                        } else if (this.props.roomdetail.shop === 5) {
                                            return "賃貸管理部";
                                        } else if (this.props.roomdetail.shop === 6) {
                                            return "高松中央公園店";
                                        } else if (this.props.roomdetail.shop === 18) {
                                            return "サンフラワー店";
                                        } else {
                                            return "";
                                        }
                                    })()}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>ネット業者</th>
                            <td colSpan="3">
                                <div>{this.props.roomdetail.net_gyosha_name}</div>
                                <div>{this.props.roomdetail.net_gyosha_tel}</div>

                            </td>
                        </tr>
                        <tr>
                            <th>広告料</th>
                            <td colSpan="3">

                                <div> {this.props.roomdetail.koukoku_ryou}</div>
                                <div> {this.props.roomdetail.koukoku_bikou}</div>

                            </td>
                        </tr>
                        <tr>
                            <th>貸主</th>
                            <td >

                                <div>
                                    {this.props.roomdetail.kashinushi_name}
                                    <button className="btn btn-sm btn-primary" onClick={()=>this.openOwner(this.props.roomdetail.kashinushi_id)} style={{width:"40px",fontSize:11, padding: 0, marginLeft:3}}>詳細</button>
                                </div>
                                {(this.props.roomdetail.kanri_kubun_no !== 1 && this.props.roomdetail.kanri_kubun_no !== 4) ? <div>{this.props.roomdetail.kashinushi_tel}</div> : ""}

                            </td>
                            <th>担当</th>
                            <td >
                                {this.props.roomdetail.kashinushi_tantou_name}
                            </td>
                        </tr>





                    </tbody>
                </table>


            </div>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

TatemonoDispComponent = reduxForm({
    form: 'roomform', // a unique identifier for this form
    //validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(TatemonoDispComponent)

export default connect(mapStateToProps, mapDispatchToProps)(TatemonoDispComponent);