
const authentication = require('../../react-azure-adb2c2').default;




export const list = (values: any) => {


    let url = process.env.REACT_APP_API_ROOMS_URL + `/api/MPenalties/`;

    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};












