import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';
import ContractManagementGridComponent from './ContractManagementGridComponent.tsx';

// import * as Customers from '../../Store/Customers';
// import {  Row, Col } from 'reactstrap';



class ContractManagementComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
  
            value: 10 ,
            isOpen: false,
            dataLoaded: false,
            isFormInvalid: false,
            rows: [],
            cols: []
            //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
        };

    
    }


   
    render() {
       
    return (
   
        <div>
     
<ContractManagementGridComponent scale={this.state.value} rotate={this.state.rotate} page={this.state.page} numPages={this.numPages}/>  

     　</div>
 
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
     
        //customerdetail1: state.customersReducer.customerdetail1,
        //firebasetoken: state.firebaseReducer.firebasetoken,
     
    }
}
function mapDispatchToProps(dispatch) {
    return {
  
      
        // update_customerdetail(state) {
        //     dispatch(Customers.update_customerdetail(state))
        // },
      
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractManagementComponent);
