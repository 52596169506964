
const authentication = require('../../react-azure-adb2c2').default;

export const get_firebase_token = (values: any) => {
    // const url = `https://homestationapi.azurewebsites.net/api/c/userdetails/`;
    // const url = `https://localhost:44341/api/c/userdetails/`;
    const url = process.env.REACT_APP_GO_API_URL + `/firebase/token`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => res.json())
        .then(fire_payload => ({ fire_payload }))
        .catch(fire_error => ({ fire_error }));
}