import React, { Component } from 'react';

import { connect } from 'react-redux';

import TatemonoEdit from './TatemonoComponent';
import RoomEdit from './RoomComponent';
import MadoriEdit from './MadoriComponent';
import { withRouter } from 'react-router';

import SetsubiEdit from './SetsubiComponent';
import ChinryoEdit from './ChinryoComponent';
import LifeLineEdit from './LifeLineComponent';

import RoomInfoDisp from './RoomInfoDisp';
import * as Rooms from './Saga/Rooms';


import {  Row, Col } from 'reactstrap';

class RoomInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: '2',
            whichComponent: <TatemonoEdit />
        };
        
        this.handleClick = this.handleClick.bind(this);
     
        props.get_setsubi_master();

        const room={
tatemono_id:10,
room_id:1
        }
        props.get_room_details(room)
    }
    handleClick(event) {
        if (this.state.mode === 1) {
            this.setState({
                whichComponent: <TatemonoEdit />,
                mode: '2',
            });
        } else {
            this.setState({
                whichComponent: <RoomInfoDisp />,
                mode: '1',
            });
        }
    }
 
  

    render() {
        console.log(this.props.room_details);
    return (
   
        <Row>
            <Col lg="6" className="item-outer">
                <Col lg="12" className="item-outer">
                    <div className="item">
                        {<RoomEdit roomdetail={this.props.room_details} />}
                    </div>
                </Col>
                <Col lg="12" className="item-outer">
                    <div className="item">
                        {<MadoriEdit roomdetail={this.props.room_details} />}
                    </div>
                </Col>
                <Col lg="12" className="item-outer">
                    <div className="item">
                        {<ChinryoEdit roomdetail={this.props.room_details} />}
                    </div>
                </Col>
            </Col>
            <Col lg="6" className="item-outer">
                <Col lg="12" className="item-outer">
                    <div className="item">
                        { <TatemonoEdit roomdetail={this.props.room_details} />}
                    </div>
                </Col>
                <Col lg="12" className="item-outer">
                    <div className="item">
                        { <LifeLineEdit roomdetail={this.props.room_details} />}
                    </div>
                </Col>
                <Col lg="12" className="item-outer">
                    <div className="item">
                        {<SetsubiEdit roomdetail={this.props.room_details} setsubi_master={this.props.setsubi_master}/>}
                    </div>
                </Col>
            </Col>
            <Col lg="12" className="item-outer">
                
            </Col>
        </Row>
 
    );
  }
}





const mapStateToProps = (state) => {
    return {
        setsubi_master: state.roomsReducer.setsubi_master,
        room_details: state.roomsReducer.room_details,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        get_setsubi_master(state) {
            dispatch(Rooms.get_setsubi_master(state))
        },
        get_room_details(state) {
            dispatch(Rooms.get_room_details(state))
        },
        

    };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoomInfoComponent));
// export default connect(
// )(RoomInfo);
