
const authentication = require('../../../react-azure-adb2c2').default;

export const list = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/docs/list/` + values
    // const url = `http://localhost:5000/v1/rent/limited/public/docs/list/` + values
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(url)

    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const detail = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/docs/detail/` + values
    // const url = `http://localhost:5000/v1/rent/limited/public/docs/detail/` + values
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(url)

    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payloaddetail => ({ payloaddetail }))
        .catch(errordetail => ({ errordetail }));

}

export const upload = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/docs/uploadfile/`
    // const url = `http://localhost:5000/v1/rent/limited/public/docs/uploadfile/`


    const token = authentication.getAccessToken();

    // alert(url)
    var formData = new FormData();
    formData.append("files", values.file[0]);
    formData.append("rent_response_id", values.response_id);
    formData.append("remarks", values.remarks);
    formData.append("rent_limited_public_page_id", values.rent_limited_public_page_id);
    console.log(formData)
    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const download = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/docs/download/` + values
    // const url = `http://localhost:5000/v1/rent/limited/public/docs/download/` + values

    const token = authentication.getAccessToken();

    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(response => response.blob())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));
};

export const update = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/docs/update/` + values.fileid
    // const url = `http://localhost:5000/v1/rent/limited/public/docs/update/` + values.fileid

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const deletefile = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/limited/public/docs/delete/` + values
    // const url = `http://localhost:5000/v1/rent/limited/public/docs/delete/` + values

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}