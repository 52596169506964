import React, { Component } from 'react';
import { connect } from 'react-redux';

// import Loading from '../Layout/LoadingComponent';
import Dropzone from 'react-dropzone';


class ImagesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // date: "",
            img:""
        };
        this.onDrop = this.onDrop.bind(this);
       
    }

    componentWillMount() {
        // this.props.get_mail_detail_files(this.props.mailid)
     
    }

//     onClick = (id,name) => { 
// console.log(name)
//         // let mailfile: { [key: string]: any; } = {};
//        var  mailfile={};
//         mailfile.id=id;
//         mailfile.name=name;
//         this.props.get_mail_file(mailfile)
    
//     }
onDrop = (files) => {console.log(files)
 
 const url = URL.createObjectURL(files[0]);


 this.setState({img:url});

 alert(this.state.img)
    // let sendfile=[];
    // sendfile.file=files;
    // sendfile.id=this.props.send_mail_details.id;
        // this.props.add_mail_file(sendfile)
      };
    render() {

        return (
            <div>
            <Dropzone 
            
                onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (

            <div {...getRootProps({className: 'dropzone'})}>
            
              <input {...getInputProps()} />
              <p>ファイルをドラッグ</p>
              <div style={{backgroundSize: {
            // repeat: 'no-repeat',
            resizeMode: 'cover', // or 'stretch'
        } ,width:'100%',height:'500px',backgroundImage: `url(${this.state.img})`}}>
           
              </div>
            </div>

        )}
      </Dropzone>

            </div>
        );
    }
};


//export  default CustomerList;
const mapStateToProps = state => {
    return {

        mail_detail_files:state.mailReducer.mail_detail_files,
        mail_file:state.mailReducer.mail_file,
        get_mail_detail_files_loading:state.mailReducer.get_mail_detail_files_loading,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
 
        // get_mail_detail_files(state) {
        //    dispatch(Mail.get_mail_detail_files(state))
        // },
        // get_mail_file(state) {
        //     dispatch(Mail.get_mail_file(state))
        //  },
        
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ImagesComponent);




