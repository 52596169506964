import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
// import { Row, Col } from 'reactstrap';
import Button from '@mui/material/Button';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      reflash: false,
      modal: false,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };

    this.props.get_rent_m_specials()

    this.handleChange = this.handleChange.bind(this);
    this.handleAddSpecialEtc = this.handleAddSpecialEtc.bind(this);

  }



  handleChange = (event) => {

    // alert(event.target.name)
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms


    rent_contract_editor_forms[event.target.name] = event.target.value;

    // this.props.change_rent_contract_editor_forms({});
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    // if (event.target.name === "condition") {


    // }
    this.setState({ reflash: !this.state.reflash });
  }

  handleAddSpecialEtc(special) {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    rent_contract_editor_forms.special_contract_etc = rent_contract_editor_forms.special_contract_etc != null ? rent_contract_editor_forms.special_contract_etc + '\n' + special : special;

    // this.props.change_rent_contract_editor_forms({});
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);

    this.setState({
      modal: false,
      reflash: !this.state.reflash,
    });
  }

  render() {
    let ver = []
    for (var i = 1; i <= this.props.rent_contract_details.ver; i++) {
      ver.push(i)
    }
    return (
      <div>
        <IconButton
          aria-label="settings"
          style={{ float: "right" }}
          onClick={() => this.props.check_rent_contract_editor_disp({ editor: "rent_contract_special_editor_disp", disp: false })}
          size="large">
          <CancelIcon />
        </IconButton>
        <Grid>

          <TextField
            id="outlined-multiline-static"
            label="物件特約"
            value={this.props.rent_contract_editor_forms.special_contract_building}
            name="special_contract_building"
            onChange={this.handleChange}
            multiline
            rows={4}
            style={{ width: '100%', margin: 10 }}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

        </Grid>

        <Grid>

          <TextField
            id="outlined-multiline-static"
            label="契約特約"
            value={this.props.rent_contract_editor_forms.special_contract_conditions}
            name="special_contract_conditions"
            onChange={this.handleChange}
            multiline
            rows={4}
            style={{ width: '100%', margin: 10 }}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

        </Grid>
        <Grid>

          <TextField
            id="outlined-multiline-static"
            label="その他特約"
            value={this.props.rent_contract_editor_forms.special_contract_etc}
            name="special_contract_etc"
            onChange={this.handleChange}
            multiline
            rows={4}
            style={{ width: '100%', margin: 10 }}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

        </Grid>
        <Grid>

          <IconButton
            style={{ float: "right", marginTop: -65, marginRight: 25 }}
            onClick={(e) => { this.setState({ "modal": true }) }}
            size="large">
            <AddCircleOutlineIcon color="primary" fontSize="large" />
          </IconButton>

          <Modal isOpen={this.state.modal} fade={false} size="xl" toggle={(e) => { this.setState({ "modal": false }) }}>
            <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
              <span>特約編集</span>
              {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
            </ModalHeader>


            <ModalBody>
              {/* <IconButton aria-label="add" onClick={()=>this.handleAddSpecialEtc("aaaaaaaaaaaaaaaaa")}><AddCircleOutlineIcon color="primary" /></IconButton> */}
              <table>
                <colgroup width='10%' />
                <colgroup width='10%' />
                <colgroup width='5%' />
                <colgroup width='75%' />
                {this.props.rent_m_specials.map(function (item, i) {
                  return (
                    <tr>
                      <th>{item.category_name}</th>
                      <th>{item.title}</th>
                      <td><IconButton
                        aria-label="add"
                        onClick={() => this.handleAddSpecialEtc(item.contents)}
                        size="large"><AddCircleOutlineIcon color="primary" /></IconButton></td>
                      <td>{item.contents}</td>
                    </tr>
                  );

                }, this)}

              </table>



            </ModalBody>

          </Modal>



        </Grid>

        <Grid>

          <TextField
            id="outlined-multiline-static"
            label="退去時条文"
            value={this.props.rent_contract_editor_forms.special_contract_payoff}
            name="special_contract_payoff"
            onChange={this.handleChange}
            multiline
            rows={4}
            style={{ width: '100%', margin: 10 }}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

        </Grid>

        <Button style={{ float: "right", margin: 5 }} variant="contained" color="primary"
          onClick={this.props.update_rent_contracts}
        >
          保存
        </Button>
      </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_logs_new: state.contracts.rent_contract_logs_new,
    rent_contract_logs_old: state.contracts.rent_contract_logs_old,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,

    rent_m_specials: state.contracts.rent_m_specials,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_rent_contract_logs_new(state) {
      dispatch(Contracts.change_rent_contract_logs_new(state))
    },
    change_rent_contract_logs_old(state) {
      dispatch(Contracts.change_rent_contract_logs_old(state))
    },
    change_rent_contract_logs_ver(state) {
      dispatch(Contracts.change_rent_contract_logs_ver(state))
    },
    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    change_rent_contract_editor_forms(state) {
      dispatch(Contracts.change_rent_contract_editor_forms(state))
    },
    update_rent_contracts(state) {
      dispatch(Contracts.update_rent_contracts(state))
    },

    get_rent_m_specials(state) {
      dispatch(Contracts.get_rent_m_specials(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
