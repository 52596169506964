﻿import React from 'react'
// import { Document, Page } from 'react-pdf';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import '@styles/react-pdf.styl'

export default class PdfDisplayComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {page: 1, base64: null, name: null}
    // this.handleDocumentLoad = this.handleDocumentLoad.bind(this);

  }

  handleChange (e) {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        base64: reader.result,
        name: file.name,
      })
    }
  }

  handleDocumentLoad ({ numPages }) {
    this.setState({numPages})

    this.props.numPages(numPages);
  }



  render () {
    
    return <div className="component">
      PDFを添付してください:
      <input type="file" onChange={this.handleChange.bind(this) } />
      <Document 
        file={this.state.base64} style={{border: 'dotted 1px #aaa'}}
        onLoadSuccess={this.handleDocumentLoad.bind(this)}
      >
        <Page 
        // rotate={90}
        scale={this.props.scale/10}
        rotate={this.props.rotate}
          pageNumber={this.props.page}
          style={{border: 'solid 2px #000', height: 300}}
        />
      </Document>
      <div>{this.state.name}</div>
     
      
    </div>
  }
}