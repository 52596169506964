import React, { Component, forwardRef } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import 'react-select/dist/react-select.css';
import * as CustomerContacts from './Saga/CustomerContacts';
import "react-datepicker/dist/react-datepicker.css";
//import { actionCreators } from '../../Store/WeatherForecasts';
import DateTimeComponent from "../Calendar/DateTimeComponent";
import MaskedInput from 'react-text-mask';
import { Field, reduxForm } from 'redux-form';
import '../../Css/Table.css';
import './Css/Dialog.css';
import * as MapSearch from '../MapSearch/Saga/MapSearch';
import * as Master from '../../Store/Master';
import RoomSearchListComponent from '../RoomList/Index';
import MapSearchComponent from '../MapSearch/Index';
import Button from '@mui/material/Button';
import VisitEdit from './VisitEdit';
import VisitList from './VisitList';
import Alert from '@mui/material/Alert';
import MaterialUiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment-timezone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import * as Help from '../Help/Saga/Help';
import WebIcon from '@mui/icons-material/Web';
import InputAdornment from '@mui/material/InputAdornment';
import ContactsCalendarComponent from './ContactsCalendarComponent';
import Grid from '@mui/material/Grid';
import { DialogContent } from '@mui/material';
// import * as AutoKana from 'vanilla-autokana';
let autokana1;


// const styles = (theme) => ({
//     root: {
//         margin: 0,
//         padding: theme.spacing(2),
//     },
//     closeButton: {
//         position: 'absolute',
//         right: theme.spacing(1),
//         top: theme.spacing(1),
//         color: theme.palette.grey[500],
//     },
// });

const DialogTitle = ((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    // className={classes.closeButton}
                    style={{position:'absolute', right:1, top:1}}
                    onClick={onClose}
                    size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}

        </MuiDialogTitle>
    );
});





var top = 0
var left = 0

// var useStyles = makeStyles({
//     avatar: {
//         // backgroundColor: blue[100],
//         // color: blue[600],
//     },
//     dialog: {
//         position: 'absolute',
//         left: left,
//         top: top
//     }
// });


function TaskDialog(props) {



    // useStyles = makeStyles({
    //     avatar: {
    //         // backgroundColor: blue[100],
    //         // color: blue[600],
    //     },
    //     dialog: {
    //         //     position: 'absolute',
    //         //     left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
    //         //     top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),

    //         width: 700,
    //         height: 538
    //     }
    // });
    // const classes = useStyles();
    const { onClose, selectedValue, open, rent_customer_contacts_forms, delete_rent_visits, update_rent_visits, visit_editor_forms } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };




    return (
        <Dialog
            // classes={{
            //     paper: classes.dialog
            // }}
            onClose={handleClose} aria-labelledby="案内物件" open={open}>
            <DialogTitle>案内物件    </DialogTitle>
            <VisitEdit contactstate={rent_customer_contacts_forms} />
            <MuiDialogActions>
                <Button variant="contained" style={{ margin: 5 }} color="primary" onClick={update_rent_visits}>保存</Button>
                {visit_editor_forms.rent_visit_id ? <Button style={{ margin: 5 }} variant="contained" color="secondary" onClick={delete_rent_visits}>削除</Button> : ""}
                <Button variant="outlined" style={{ margin: 5 }} onClick={handleClose}>閉じる</Button>
            </MuiDialogActions>
        </Dialog>
    );
}



// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (!values.name1) {
        errors.name1 = "名前を入力してください。";
    }
    if (!values.kana1) {
        errors.kana1 = "カナを入力してください。";
    }

    if (values.mail1) {
        if (!values.mail1.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            errors.mail1 = "メールアドレスの形式が正しくありません。";
        }
    }
    if (values.mail2) {
        if (!values.mail2.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            errors.mail2 = "メールアドレスの形式が正しくありません。";
        }
    }

    if (values.tel1) {
        if (!values.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel1.match(/^\d{7,13}$/)) {
            errors.tel1 = "電話番号の形式が正しくありません。";
        }
    }

    if (values.tel2) {
        if (!values.tel2.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel2.match(/^\d{7,13}$/)) {
            errors.tel2 = "電話番号の形式が正しくありません。";
        }
    }

    if (values.fax) {
        if (!values.fax.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.fax.match(/^\d{7,13}$/)) {
            errors.fax = "FAXの形式が正しくありません。";
        }
    }

    if (values.post) {
        //if (!values.post.match(/^\d{3}-\d{4}$/)) {
        //    errors.post = "郵便番号の形式が正しくありません。";
        //}
    }
    return errors;
};

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, style, meta: { touched, error } }) => (
//     <div >
//         <input {...input} type={type} id={id} placeholder={placeholder} style={{ width: "80" }} className={touched && error ? 'alert-danger ' + className : className} />
//         {touched && error && <span className="text-danger">{error}</span>}
//     </div>
// );

export class RenderField extends React.Component {
    render() {
        const { className, type, input, disabled, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea className={className} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select className={className} {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input className={className} {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};

// const ReduxFormDateRange = (props) => {


//     console.log(props)
//     return (

//         <DatePicker

//             selected={props.input.value !== "" ? new Date(props.input.value) : ""}
//             //selected={moment(props.input.value, "YYYY-MM-DD").format("YYYY-MM-DD") || null}
//             onChange={props.input.onChange}
//             //showTimeSelect
//             dateFormat="yyyy/MM/dd"
//             className="form-control"
//         />
//     )
// }


const RenderSelectInput = ({ input, options, name, id }) => (

    <Select
        {...input}
        id={id}
        name={name}
        options={options}
        value={input.value}
        onChange={(a) => input.onChange(a.value)}
        onBlur={(a) => input.onBlur(a.value)}
    // placeholder="店舗を選択"
    />


)

function timeMask(value) {
    const chars = value.split('');
    const hours = [
      /[0-2]/,
      chars[0] === '2' ? /[0-3]/ : /[0-9]/,
    ];
    const minutes = [/[0-5]/, /[0-9]/];
  
    return hours.concat(':').concat(minutes);
  }
  
  
  const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref}
        mask={timeMask}
        showMask
      />
    );
  });

class ContactEditComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // setintend: this.props.contactstate.intend === 0 || this.props.contactstate.intend === false ? false : true,
            ownshop: true,
            reflesh: false,
            modaldelete: false,

            time_disp:false,

            start : moment(this.props.rent_customer_contacts_forms.start).format('HH:mm'),
            end : moment(this.props.rent_customer_contacts_forms.end).format('HH:mm'),
            start_at_err: false,
            end_at_err: false,
        };
        this.changeIntend = this.changeIntend.bind(this)

        this.change_ownshop = this.change_ownshop.bind(this)



        this.handleChange = this.handleChange.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.change_date = this.change_date.bind(this)
        this.change_user_id = this.change_user_id.bind(this)
        this.change_going_user_id = this.change_going_user_id.bind(this)
        // var contactdetail = {
        //     rentResponseId: this.props.responsedetail.id,
        //     sectionId: this.props.responsedetail.sectionId,

        // }
        // this.props.contactstate.way = String(this.props.contactstate.way);
        // this.props.contactstate.intend = this.props.contactstate.intend === 0 || this.props.contactstate.intend === false ? false : true;


        this.props.get_rent_visits(this.props.rent_customer_contacts_forms.id)

        this.toggledelete = this.toggledelete.bind(this)
        this.visit_card = this.visit_card.bind(this)

        this.open_customer_window = this.open_customer_window.bind(this)
        this.change_time_disp = this.change_time_disp.bind(this)

        this.change_datetime_start_time = this.change_datetime_start_time.bind(this);
        this.change_datetime_end_time = this.change_datetime_end_time.bind(this);

        this.change_date2 = this.change_date2.bind(this);
        this.change_datetime_start_time2 = this.change_datetime_start_time2.bind(this);

        this.togglemap = this.togglemap.bind(this);
        this.togglesearch = this.togglesearch.bind(this);

        this.focus_start = this.focus_start.bind(this);
        this.blur_start = this.blur_start.bind(this);
        this.focus_end = this.focus_end.bind(this);
        this.blur_end = this.blur_end.bind(this);

        this.change_datetime = this.change_datetime.bind(this);
        this.change_datetime_select = this.change_datetime_select.bind(this);
        // this.props.initialize(this.props.contactstate)

        // this.props.initialize(contactdetail);//フォームに初期値を反映させる
    }

    togglemap() {
        this.props.change_fromMapOpen(4)
        this.setState(prevState => ({
          modalmap: !prevState.modalmap
        }));
        this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)
    }
    togglesearch() {
        this.props.change_fromMapOpen(4)
        this.setState(prevState => ({
          modalsearch: !prevState.modalsearch
        }));
        this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)

    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();

    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }

    focus_start(e) {

    // this.check_validation()
    this.setState({ focus_start: true })
    }
    blur_start(e) {

    setTimeout(() => {
        this.setState({ focus_start: false })
    }, 200)

    }
    focus_end(e) {
    console.log(e.target)

    this.setState({ focus_end: true })
    }
    blur_end(e) {
    setTimeout(() => {
        this.setState({ focus_end: false })
    }, 200)

    }

    change_datetime_select(name, value) {
        // alert(name)
        // alert(moment(value).format('HH:mm'))
        this.setState({ 
          reflesh: !this.props.reflesh,
          [name]: moment(value).format('HH:mm'),
          [name == "start" ? "start_at_err" : "end_at_err"]: false,
        })
        // this.check_validation()
    }

    change_datetime(e) {
        // alert(e.target.value)
        console.log(e.target.value)
    
        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms
    
    
        const regex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
        // 時間チェックして
        if(regex.test(e.target.value)){ //OK
              this.setState({ 
                [e.target.name == "start" ? "start_at_err" : "end_at_err"]: false,
              })
    
              rent_customer_contacts_forms[e.target.name] = moment(moment(rent_customer_contacts_forms.start).format("YYYY/MM/DD ") + e.target.value).format('YYYY/MM/DD HH:mm')
          this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
    
        }else{// エラー
    
          this.setState({ 
            [e.target.name == "start" ? "start_at_err" : "end_at_err"]: true,
          })
    
        }
    
        this.setState({ 
          reflesh: !this.props.reflesh,
          [e.target.name]: e.target.value,
        })
    
        // this.check_validation()
      }


    handleChange = (e) => {


        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms

        if (e.target.name === "intend" || e.target.name === "online" || e.target.name === "no_coming" || e.target.name === "is_prepare") {

            rent_customer_contacts_forms[e.target.name] = rent_customer_contacts_forms[e.target.name] === 1 ? 0 : 1

            if (e.target.name === "intend"){
                rent_customer_contacts_forms.is_prepare = 0
                rent_customer_contacts_forms.going_time = 0
                rent_customer_contacts_forms.is_application = 0
                rent_customer_contacts_forms.rent_m_going_reason_id = 0

                if(e.target.checked == false && rent_customer_contacts_forms.no_coming){
                    rent_customer_contacts_forms.going = 0
                    rent_customer_contacts_forms.going_section = -1
                    rent_customer_contacts_forms.going_user_id = 0
                    rent_customer_contacts_forms.going_time = 0
                    rent_customer_contacts_forms.is_application = 1
                    rent_customer_contacts_forms.rent_m_going_reason_id = 0
                }
            }
            
            if (e.target.name === "no_coming" && e.target.checked){
                
                rent_customer_contacts_forms.going = 0
                rent_customer_contacts_forms.going_section = -1
                rent_customer_contacts_forms.going_user_id = 0
                rent_customer_contacts_forms.going_time = 0
                rent_customer_contacts_forms.is_application = 1
                rent_customer_contacts_forms.rent_m_going_reason_id = 0
                // this.props.change_rent_visits([])
            }
            
        } else if (e.target.name === "intend_time"){
            let H = 0;
            let m = 0;
            if (e.target.value == 1) {H = 0;m = 0;
            } else if (e.target.value == 2) {H = 0; m = 30;
            } else if (e.target.value == 3) {H = 1; m = 0;
            } else if (e.target.value == 4) {H = 1; m = 30;
            } else if (e.target.value == 5) {H = 2; m = 0;
            } else if (e.target.value == 6) {H = 2; m = 30;
            } else if (e.target.value == 7) {H = 3; m = 0;
            } else if (e.target.value == 8) {H = 3; m = 30;
            } else if (e.target.value == 9) {H = 4; m = 0;
            } else if (e.target.value == 10) {H = 4; m = 30;
            } else if (e.target.value == 11) {H = 5; m = 0;
            } else if (e.target.value == 12) {H = 5; m = 30;
            } else if (e.target.value == 13) {H = 6; m = 0;
            } else if (e.target.value == 14) {H = 6; m = 30;
            } else if (e.target.value == 15) {H = 7; m = 0;
            } else if (e.target.value == 16) {H = 7; m = 30;
            } else if (e.target.value == 17) {H = 8; m = 0;
            } else if (e.target.value == 18) {H = 8; m = 30;
            } else if (e.target.value == 19) {H = 9; m = 0;
            } else if (e.target.value == 20) {H = 9; m = 30;
            } else if (e.target.value == 21) {H = 10; m = 0;
            } else if (e.target.value == 22) {H = 10; m = 30;
            } else if (e.target.value == 23) {H = 11; m = 0;
            } else if (e.target.value == 24) {H = 11; m = 30;
            } else if (e.target.value == 25) {H = 12; m = 0;
            } else if (e.target.value == 26) {H = 12; m = 30;
            } else if (e.target.value == 27) {H = 13; m = 0;
            } else if (e.target.value == 28) {H = 13; m = 30;
            } else if (e.target.value == 29) {H = 14; m = 0;
            } else if (e.target.value == 30) {H = 14; m = 30;
            } else if (e.target.value == 31) {H = 15; m = 0;
            } else if (e.target.value == 32) {H = 15; m = 30;
            } else if (e.target.value == 33) {H = 16; m = 0;
            } else if (e.target.value == 34) {H = 16; m = 30;
            } else if (e.target.value == 35) {H = 17; m = 0;
            } else if (e.target.value == 36) {H = 17; m = 30;
            } else if (e.target.value == 37) {H = 18; m = 0;
            } else if (e.target.value == 38) {H = 18; m = 30;
            } else if (e.target.value == 39) {H = 19; m = 0;
            } else if (e.target.value == 40) {H = 19; m = 30;
            } else if (e.target.value == 41) {H = 20; m = 0;
            } else if (e.target.value == 42) {H = 20; m = 30;
            } else if (e.target.value == 43) {H = 21; m = 0;
            } else if (e.target.value == 44) {H = 21; m = 30;
            } else if (e.target.value == 45) {H = 22; m = 0;
            } else if (e.target.value == 46) {H = 22; m = 30;
            } else if (e.target.value == 47) {H = 23; m = 0;
            } else if (e.target.value == 48) {H = 23; m = 30;
            }
            rent_customer_contacts_forms.start = new Date(moment(rent_customer_contacts_forms.date + " " + H + ":" + m).format("YYYY/MM/DD HH:mm:ss"))

            rent_customer_contacts_forms[e.target.name] = e.target.value
        } else if (e.target.name === "intend_time2") {
            let H = 0;
            let m = 0;
            if (e.target.value == 1) {H = 0;m = 0;
            } else if (e.target.value == 2) {H = 0; m = 30;
            } else if (e.target.value == 3) {H = 1; m = 0;
            } else if (e.target.value == 4) {H = 1; m = 30;
            } else if (e.target.value == 5) {H = 2; m = 0;
            } else if (e.target.value == 6) {H = 2; m = 30;
            } else if (e.target.value == 7) {H = 3; m = 0;
            } else if (e.target.value == 8) {H = 3; m = 30;
            } else if (e.target.value == 9) {H = 4; m = 0;
            } else if (e.target.value == 10) {H = 4; m = 30;
            } else if (e.target.value == 11) {H = 5; m = 0;
            } else if (e.target.value == 12) {H = 5; m = 30;
            } else if (e.target.value == 13) {H = 6; m = 0;
            } else if (e.target.value == 14) {H = 6; m = 30;
            } else if (e.target.value == 15) {H = 7; m = 0;
            } else if (e.target.value == 16) {H = 7; m = 30;
            } else if (e.target.value == 17) {H = 8; m = 0;
            } else if (e.target.value == 18) {H = 8; m = 30;
            } else if (e.target.value == 19) {H = 9; m = 0;
            } else if (e.target.value == 20) {H = 9; m = 30;
            } else if (e.target.value == 21) {H = 10; m = 0;
            } else if (e.target.value == 22) {H = 10; m = 30;
            } else if (e.target.value == 23) {H = 11; m = 0;
            } else if (e.target.value == 24) {H = 11; m = 30;
            } else if (e.target.value == 25) {H = 12; m = 0;
            } else if (e.target.value == 26) {H = 12; m = 30;
            } else if (e.target.value == 27) {H = 13; m = 0;
            } else if (e.target.value == 28) {H = 13; m = 30;
            } else if (e.target.value == 29) {H = 14; m = 0;
            } else if (e.target.value == 30) {H = 14; m = 30;
            } else if (e.target.value == 31) {H = 15; m = 0;
            } else if (e.target.value == 32) {H = 15; m = 30;
            } else if (e.target.value == 33) {H = 16; m = 0;
            } else if (e.target.value == 34) {H = 16; m = 30;
            } else if (e.target.value == 35) {H = 17; m = 0;
            } else if (e.target.value == 36) {H = 17; m = 30;
            } else if (e.target.value == 37) {H = 18; m = 0;
            } else if (e.target.value == 38) {H = 18; m = 30;
            } else if (e.target.value == 39) {H = 19; m = 0;
            } else if (e.target.value == 40) {H = 19; m = 30;
            } else if (e.target.value == 41) {H = 20; m = 0;
            } else if (e.target.value == 42) {H = 20; m = 30;
            } else if (e.target.value == 43) {H = 21; m = 0;
            } else if (e.target.value == 44) {H = 21; m = 30;
            } else if (e.target.value == 45) {H = 22; m = 0;
            } else if (e.target.value == 46) {H = 22; m = 30;
            } else if (e.target.value == 47) {H = 23; m = 0;
            } else if (e.target.value == 48) {H = 23; m = 30;
            }
            rent_customer_contacts_forms.end = new Date(moment(rent_customer_contacts_forms.date + " " + H + ":" + m).format("YYYY/MM/DD HH:mm:ss"))

            rent_customer_contacts_forms[e.target.name] = e.target.value
        
        } else if (e.target.name === "date") {
            if(Number(rent_customer_contacts_forms.way) === 3){//来店
                rent_customer_contacts_forms[e.target.name] = moment(e.target.value).format("YYYY/MM/DD")
                rent_customer_contacts_forms.start = moment(moment(rent_customer_contacts_forms.date).format("YYYY/MM/DD") + " " + moment(rent_customer_contacts_forms.start).format("HH:mm")).format("YYYY/MM/DD HH:mm")
                rent_customer_contacts_forms.end = moment(moment(rent_customer_contacts_forms.date).format("YYYY/MM/DD") + " " + moment(rent_customer_contacts_forms.end).format("HH:mm")).format("YYYY/MM/DD HH:mm")
            }else{
                rent_customer_contacts_forms[e.target.name] = moment(moment(e.target.value).format("YYYY/MM/DD") + " " +  moment(rent_customer_contacts_forms.date).format("HH:mm")).format("YYYY/MM/DD HH:mm")
            }

        } else {
            if (e.target.name === "going"){
                if(e.target.value == 0){
                    rent_customer_contacts_forms.going_section = -1
                    rent_customer_contacts_forms.going_time = 0
                    rent_customer_contacts_forms.going_user_id = 0
                }
            }
            rent_customer_contacts_forms[e.target.name] = e.target.value
        }

        
        

        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)

        this.setState({ "reflesh": !this.state.reflesh })
    }

    handleChangeDate = (event) => {

        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms
    
        if(event.target.name == "date"){
            rent_customer_contacts_forms[event.target.name] = event.target.value.replace("T", " ")
        }else{
            rent_customer_contacts_forms[event.target.name] = event.target.value
        }
        
    
        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
    
        this.setState({ "reflesh": !this.state.reflesh })
      }

    change_user_id(e, newValue) {


        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms
        rent_customer_contacts_forms.user_id = newValue ? newValue.value : 0


        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)

        this.setState({ "reflesh": !this.state.reflesh })

    }



    change_date = (value) => {
        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms

        rent_customer_contacts_forms.date = value

        if(rent_customer_contacts_forms.start){
            rent_customer_contacts_forms.start = moment(moment(this.props.rent_customer_contacts_forms.date).format("YYYY/MM/DD") + " " + moment(this.props.rent_customer_contacts_forms.start).format("HH:mm")).format("YYYY/MM/DD HH:mm")
        }
        if(rent_customer_contacts_forms.end){
            rent_customer_contacts_forms.end = moment(moment(this.props.rent_customer_contacts_forms.date).format("YYYY/MM/DD") + " " +  moment(this.props.rent_customer_contacts_forms.end).format("HH:mm")).format("YYYY/MM/DD HH:mm")
        }


     
        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
        this.setState({ "reflesh": !this.state.reflesh })
    }


    change_ownshop() {
        this.setState({ "ownshop": !this.state.ownshop })


    }
    onChangeName(property, event) {

        if (property === "tatemonoKana") {
            this.props.change(property, autokana1.getFurigana())
        } else if (property === "kana2") {

        }


    }


    changeIntend(e) {


        this.props.contactstate.intendTime = 1;
        this.props.contactstate.intendTime2 = 1;

        this.setState({ "setintend": this.state.setintend === true ? false : true })
        // alert(e.target.value)
        // alert(this.state.setintend)
        // this.setState={setintend: e.target.value}
        // alert(this.state.setintend)
    }
    toggledelete() {


        this.setState(prevState => ({
            modaldelete: !prevState.modaldelete,

        }));
    }

    visit_card() {
        let card = this.props.rent_visits.map(function (value) {
            if (value.tatemono_id && value.room_id >= 0) {
                return value.tatemono_id + "-" + value.room_id
            }
        }).filter(n => n != undefined);
        if (card.length > 0) {
            window.open(process.env.REACT_APP_HOMESTATION4_URL+"/manage/room/sheet/rent/multi?req=1&rooms=" + card.join(','))
        } else {
            alert("印刷できる物件が選択されていません。部屋まで登録する必要があります。")
        }
    }
    open_customer_window(rent_response_id) {
        window.open(`/customer/` + rent_response_id, '_blank', 'noopener')
    }
    
    change_time_disp() {
        this.setState({ "time_disp": !this.state.time_disp })
    }

    change_datetime_start_time(e) {

        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms
    
        rent_customer_contacts_forms.start = moment(moment(this.props.rent_customer_contacts_forms.date).format("YYYY/MM/DD") + " " + e.target.value).format("YYYY/MM/DD HH:mm")
    
        // if (rent_customer_contacts_forms.end == null || !moment(rent_customer_contacts_forms.end).isValid() || moment(rent_customer_contacts_forms.end).isSame(moment(rent_customer_contacts_forms.start)) || moment(rent_customer_contacts_forms.end).isBefore(moment(rent_customer_contacts_forms.start))) {
        //     rent_customer_contacts_forms.end = e.target.value ? moment(rent_customer_contacts_forms.start).add(30, 'm') : null
        // }
        if (rent_customer_contacts_forms.end == null || (rent_customer_contacts_forms.start && rent_customer_contacts_forms.end && moment(rent_customer_contacts_forms.start) >= moment(rent_customer_contacts_forms.end))) {
            rent_customer_contacts_forms.end = moment(rent_customer_contacts_forms.start).add(30, 'm').format("YYYY/MM/DD HH:mm")
            if(moment(rent_customer_contacts_forms.date).format("YYYYMMDD") != moment(rent_customer_contacts_forms.end).format("YYYYMMDD")){
                rent_customer_contacts_forms.end = moment(rent_customer_contacts_forms.date).endOf('day').format("YYYY/MM/DD HH:mm")
            }
        }
    
        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)

        this.setState({ reflesh: !this.props.reflesh })
    }

    change_datetime_end_time(e) {

        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms

        rent_customer_contacts_forms.end = moment(moment(this.props.rent_customer_contacts_forms.date).format("YYYY/MM/DD") + " " + e.target.value).format("YYYY/MM/DD HH:mm")

        // if (rent_customer_contacts_forms.start == null || !moment(rent_customer_contacts_forms.start).isValid() || moment(rent_customer_contacts_forms.start).isSame(moment(rent_customer_contacts_forms.end)) || moment(rent_customer_contacts_forms.start).isAfter(moment(rent_customer_contacts_forms.end))) {
        //     rent_customer_contacts_forms.start = rent_customer_contacts_forms.end ? moment(rent_customer_contacts_forms.end).add(-30, 'm') : null
        // }
        if (rent_customer_contacts_forms.start == null || (rent_customer_contacts_forms.start && rent_customer_contacts_forms.end && moment(rent_customer_contacts_forms.start) >= moment(rent_customer_contacts_forms.end))) {
            rent_customer_contacts_forms.start = moment(rent_customer_contacts_forms.end).add(-30, 'm').format("YYYY/MM/DD HH:mm")
            if(moment(rent_customer_contacts_forms.date).format("YYYYMMDD") != moment(rent_customer_contacts_forms.start).format("YYYYMMDD")){
                rent_customer_contacts_forms.start = moment(rent_customer_contacts_forms.date).startOf('day').format("YYYY/MM/DD HH:mm")
            }
        }

        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
        this.setState({ reflesh: !this.props.reflesh })

    }

    change_date2(e) {

        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms
    
        rent_customer_contacts_forms.date = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD ") + moment(rent_customer_contacts_forms.date).format("HH:mm")
    
        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
        this.setState({ reflesh: !this.props.reflesh })

    }
    change_datetime_start_time2(e) {

        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms
    
        rent_customer_contacts_forms.date = moment(moment(this.props.rent_customer_contacts_forms.date).format("YYYY/MM/DD") + " " + e.target.value).format("YYYY/MM/DD HH:mm")
    
        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
        this.setState({ reflesh: !this.props.reflesh })
      }

    change_going_user_id(e, newValue) {


        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms
        rent_customer_contacts_forms.going_user_id = newValue ? newValue.value : 0

        this.setState({ "reflesh": !this.state.reflesh })

    }


    render() {


        const { handleSubmit } = this.props;
        console.log(this.props.responsedetail)

        var event = new Array()

        return (
            <Card style={{ padding: 10 }}>

                <DialogTitle id="customized-dialog-title" onClose={() => this.props.onClose(false)} >
                    <div style={{ fontWeight: "bold" }}>
                    {Number(this.props.rent_customer_contacts_forms.way) === 3 ? "来店登録" : "追客登録"}:
                    
                        {(() => {
                            let shop = "";
                            if (this.props.rent_customer_contacts_forms.section_id === 1) {
                                shop = <span className="badge badge-success">番</span>
                            } else if (this.props.rent_customer_contacts_forms.section_id === 2) {
                                shop = <span className="badge badge-warning">レ</span>
                            } else if (this.props.rent_customer_contacts_forms.section_id === 3) {
                                shop = <span className="badge badge-danger">駅</span>
                            } else if (this.props.rent_customer_contacts_forms.section_id === 4) {
                                shop = <span className="badge badge-dark">東</span>
                            } else if (this.props.rent_customer_contacts_forms.section_id === 6) {
                                shop = <span className="badge badge-light">公</span>
                            } else if (this.props.rent_customer_contacts_forms.section_id === 17) {
                                shop = <span className="badge badge-info">サ</span>
                            } else if (this.props.rent_customer_contacts_forms.section_id === 47) {
                                shop = <span className="badge badge-secondary">通</span>
                            }
                            return <span style={{ fontSize: "16px", marginRight:3, marginLeft:2 }}>{shop}</span>
                        })()}

                        {this.props.rent_customer_contacts_forms.title ? this.props.rent_customer_contacts_forms.title : ""}
                        
                        <IconButton
                            onClick={() => this.open_customer_window(this.props.rent_customer_contacts_forms.rent_response_id)}
                            size="large"><WebIcon color="primary" /></IconButton></div>
                    {this.props.rent_customer_contacts_forms.agent_name != null && this.props.rent_customer_contacts_forms.agent_name != "" ? <div style={{ fontSize: 14 }}>{this.props.rent_customer_contacts_forms.agent_name}</div> : ""}
                    {this.props.rent_customer_contacts_forms.agent_etc != null && this.props.rent_customer_contacts_forms.agent_etc != "" ? <div style={{ fontSize: 14 }}>{this.props.rent_customer_contacts_forms.agent_etc}</div> : ""}
                </DialogTitle>

                {/* <div style={{ overflowY: "auto", height: window.innerHeight > 850 ? "600px" : window.innerHeight - 240, }}> */}
                <div style={{ overflowY: "auto", height: window.innerHeight > 600 ? "400px" : "300px", }}>
                    <CardContent style={{ paddingBottom:0, paddingRight:5 }}>

            <Grid container spacing={1}>
                <Grid justifyContent="space-between" item xs={12}>


                    <Grid container spacing={1}>
                        <Grid justifyContent="space-between" item xs={12}>

                        <FormControl style={{ width: "160px" }}>
                            {this.props.users_select.length > 0 ? <Autocomplete
                                id="combo-box-demo"
                                options={this.state.ownshop ? this.props.users.filter(a => a.amSectionId === this.props.user_details.amSectionId).map((value) => ({
                                    value: value.id,
                                    label: value.userNameAll
                                })) : this.props.users_select}
                                getOptionLabel={(option) => option.label}
                                // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
                                // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                style={{ width: 150 }}
                                name="user_id"
                                value={this.props.rent_customer_contacts_forms.user_id ? this.props.users_select.filter(a => a.value === this.props.rent_customer_contacts_forms.user_id)[0] : null}
                                onChange={this.change_user_id}
                                size="small"
                                renderInput={(params) => <TextField variant="standard"
                                    {...params} label="担当を選択" />}

                            /> : ""}
                        </FormControl>

                        <FormControlLabel checked={this.state.ownshop} onChange={this.change_ownshop} control={<Checkbox name="checkedC" />} label="自店のみ" />

                    </Grid>

                    <Grid justifyContent="space-between" item xs={12}>
                        {/* <FormControlLabel　control={<Checkbox name="intend" onChange={this.changeIntend}/>} label="予定" /> */}
                        <FormControlLabel name="intend" value="1" onChange={this.handleChange} checked={parseInt(this.props.rent_customer_contacts_forms.intend) === 1 ? true : false} control={<Checkbox name="intend" />} label="予定" />
                        {Number(this.props.rent_customer_contacts_forms.way) === 3 && Number(this.props.rent_customer_contacts_forms.intend) === 1 ? <FormControlLabel style={{marginLeft:20}} name="is_prepare" value="1" onChange={this.handleChange} checked={parseInt(this.props.rent_customer_contacts_forms.is_prepare) === 1 ? true : false} control={<Checkbox name="is_prepare" />} label="事前準備" />:""}
                        {Number(this.props.rent_customer_contacts_forms.way) === 3 ? <FormControlLabel style={{marginLeft:20}} name="no_coming" value="1" onChange={this.handleChange} checked={parseInt(this.props.rent_customer_contacts_forms.no_coming) === 1 ? true : false} control={<Checkbox name="no_coming" />} label="見ず決め" />:""}
                    </Grid>
                    {this.props.rent_customer_contacts_forms.rent_m_response_status_id == 15 ? <Grid justifyContent="space-between" item xs={12}>
                        <TextField
                                id="datetime-local"
                                label="送信予約日時"
                                type="datetime-local"
                                name="line_schedule_date"
                                value={moment(this.props.rent_customer_contacts_forms.line_schedule_date).format("YYYY-MM-DDTHH:mm")}
                                style={{ width: 200, marginBottom:5 }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={this.handleChangeDate}
                        /></Grid>:""}


                    {this.props.rent_customer_contacts_forms.rent_m_response_status_id == 15 ? "" :
                    <Grid item xs={12} style={{ display: "flex",}}>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}

                            {Number(this.props.rent_customer_contacts_forms.way) === 3 ? 
                            <div style={{ display: "flex",}}>
                                <TextField
                                    id="date"
                                    label="反響日"
                                    type="date"
                                    name="date"
                                    size="small"
                                    // onChange={this.handleChange}
                                    onChange={(e)=>this.change_date(e.target.value)}
                                    error={!this.props.rent_customer_contacts_forms.date}
                                    value={moment(this.props.rent_customer_contacts_forms.date).format("YYYY-MM-DD")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                />
                            {/* <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd"
                                margin="normal"
                                id="date-picker-inline1"
                                label="対応日"
                                //   showTodayButton={true}
                                style={{ marginTop: "0", width: 130, marginRight:10 }}
                                autoOk={true}
                                InputLabelProps={{ shrink: !!this.props.rent_customer_contacts_forms.date }}
                                // error={true} moment(payload.value).utc().format("YYYY/MM/DD HH:mm")
                                // helperText="契約日を選択してください"
                                //   errorMessages={['契約日を選択してください']}
                                name="date"
                                value={moment(this.props.rent_customer_contacts_forms.date).format("YYYY/MM/DD")}
                                // value={new Date()}
                                // value={this.props.estimates.contractDate}
                                onChange={this.change_date}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                //   minDateMessage="契約日を選択してください"
                                // minDate={new Date("2000-01-01")}
                                invalidDateMessage={"日付の形式が違います"}
                            /> */}

                            <div style={{position: "relative", marginLeft:20}}>
                                <TextField
                                    label="開始時間"
                                    style={{ width: 65, }}
                                    onChange={this.change_datetime}
                                    name = "start"
                                    // onChange={handleChange}
                                    // id="formatted-text-mask-input"
                                    onFocus={this.focus_start}
                                    onBlur={this.blur_start}
                                    InputProps={{ inputComponent: TextMaskCustom }}
                                    // value={moment(this.props.rent_task_detail.start_at).format('HH:mm')}
                                    value={this.state.start}
                                    error={this.state.start_at_err}
                                    variant="standard"
                                    size="small"
                                />
                                {this.state.focus_start ? <DateTimeComponent start={true} start_time={null} change_datetime_select={this.change_datetime_select} change_detail={this.props.change_rent_customer_contacts_forms} detail={this.props.rent_customer_contacts_forms} name={"start"} /> : ""}
                            </div>
                            <div>　–　</div>
                            <div style={{position: "relative"}}>
                                <TextField
                                    label="終了時間"
                                    style={{ width: 65, }}
                                    onChange={this.change_datetime}
                                    name = "end"
                                    // onChange={handleChange}
                                    // id="formatted-text-mask-input"
                                    onFocus={this.focus_end}
                                    onBlur={this.blur_end}
                                    InputProps={{ inputComponent: TextMaskCustom }}
                                    value={this.state.end}
                                    error={this.state.end_at_err}
                                    variant="standard"
                                    size="small"
                                />
                                {this.state.focus_end ? <DateTimeComponent start={false} start_time={this.props.rent_customer_contacts_forms.start} change_datetime_select={this.change_datetime_select} change_detail={this.props.change_rent_customer_contacts_forms} detail={this.props.rent_customer_contacts_forms} name={"end"} /> : ""}
                            </div>
                            
                            </div>
                            :
                            <span>

                                <TextField
                                    id="date"
                                    label="反響日"
                                    type="date"
                                    name="date"
                                    size="small"
                                    // onChange={this.handleChange}
                                    onChange={(e)=>this.change_date2(e.target.value)}
                                    error={!this.props.rent_customer_contacts_forms.date}
                                    value={moment(this.props.rent_customer_contacts_forms.date).format("YYYY-MM-DD")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                />
                                {/* <KeyboardDatePicker

                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd(EE)"
                                    margin="normal"
                                    id="date-picker-inline2"
                                    label="日付"
                                    style={{ marginLeft: "15px", marginTop: "0", width: 150, float: "left", marginRight:5 }}
                                    autoOk={true}
                                    // disabled={true}
                                    // error={true}
                                    // helperText="申込日を選択してください"
                                    showTodayButton={true}
                                    name="contractionDateEnd"
                                    value={this.props.rent_customer_contacts_forms.date ? moment(this.props.rent_customer_contacts_forms.date) : null}
                                    InputLabelProps={{ shrink: !!this.props.rent_customer_contacts_forms.date }}
                                    onChange={this.change_date2}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}

                                    invalidDateMessage={"日付を入力してください。"}
                                /> */}


                                <TextField
                                    id="time"
                                    label="時間"
                                    type="time"
                                    // defaultValue="07:30"
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    inputProps={{
                                    step: 300, // 5 min
                                    }}
                                    onChange={this.change_datetime_start_time2}
                                    sx={{ width: 150 }}
                                    value={moment(this.props.rent_customer_contacts_forms.date).format("HH:mm") == "00:00" ? null : moment(this.props.rent_customer_contacts_forms.date).format("HH:mm")}
                                    style={{float: "left"}}
                                    variant="standard"
                                    size="small"
                                />

                                <div style={{ clear: "both" }}></div>
                            </span>
                            }

                        {/* </MuiPickersUtilsProvider> */}

                    </Grid>}
                    

                    {/* <IconButton onClick={this.change_time_disp}>
                                <AccessTimeIcon />
                            </IconButton>   */}
                    <Dialog onClose={this.change_time_disp} open={this.state.time_disp}>
                        <DialogTitle>
                            <ContactsCalendarComponent />
                            {/* {console.log(event.concat(this.props.rent_customer_contacts_forms))} */}
                        </DialogTitle>
                    </Dialog>



                    {parseInt(this.props.rent_customer_contacts_forms.way) !== 3 ? <Grid justifyContent="space-between" item xs={12}>
                        <FormLabel style={{ marginBottom: 0, fontSize: 9 }}>対応方法</FormLabel>
                        <RadioGroup row aria-label="position" name="way" >

                            <FormControlLabel value="1" name="way" disabled={this.props.rent_customer_contacts_forms.rent_m_response_status_id == 3 || this.props.rent_customer_contacts_forms.rent_m_response_status_id == 15} onChange={this.handleChange} checked={parseInt(this.props.rent_customer_contacts_forms.way) === 1 ? true : false} control={<Radio />} label="TEL" />
                            <FormControlLabel value="2" name="way" disabled={this.props.rent_customer_contacts_forms.rent_m_response_status_id == 3 || this.props.rent_customer_contacts_forms.rent_m_response_status_id == 15} onChange={this.handleChange} checked={parseInt(this.props.rent_customer_contacts_forms.way) === 2 ? true : false} control={<Radio />} label="メール" />
                            <FormControlLabel value="4" name="way" disabled={this.props.rent_customer_contacts_forms.rent_m_response_status_id == 3 || this.props.rent_customer_contacts_forms.rent_m_response_status_id == 15} onChange={this.handleChange} checked={parseInt(this.props.rent_customer_contacts_forms.way) === 4 ? true : false} control={<Radio />} label="LINE" />
                            <FormControlLabel value="5" name="way" disabled={this.props.rent_customer_contacts_forms.rent_m_response_status_id == 3 || this.props.rent_customer_contacts_forms.rent_m_response_status_id == 15} onChange={this.handleChange} checked={parseInt(this.props.rent_customer_contacts_forms.way) === 5 ? true : false} control={<Radio />} label="問合せ（マイページ）" />

                        </RadioGroup>
                    </Grid> : ""}

                    {Number(this.props.rent_customer_contacts_forms.way) === 3 ? <Grid justifyContent="space-between" item xs={12}>
                        <FormControlLabel name="online" value="1" onChange={this.handleChange} checked={parseInt(this.props.rent_customer_contacts_forms.online) === 1 ? true : false} control={<Checkbox name="online" />} label="オンライン物説" />
                        {/* <FormControlLabel style={{float:"left"}} name="going" value="1" onChange={this.handleChange} checked={parseInt(this.props.rent_customer_contacts_forms.going) === 1 ? true : false} control={<Checkbox name="going" />} label="案内" /> */}
                        <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 15 }}>案内</FormLabel>
                        <RadioGroup style={{ marginTop: 5, marginLeft: 10 }} row aria-label="position" name="going" onChange={this.handleChange} value={parseInt(this.props.rent_customer_contacts_forms.going)} >
                            <FormControlLabel disabled={parseInt(this.props.rent_customer_contacts_forms.no_coming) === 1} control={<Radio value={0} />} label="なし" />
                            <FormControlLabel disabled={parseInt(this.props.rent_customer_contacts_forms.no_coming) === 1} control={<Radio value={1} />} label="あり" />
                            <FormControlLabel disabled={parseInt(this.props.rent_customer_contacts_forms.no_coming) === 1} control={<Radio value={2} />} label="現地" />
                            <FormControlLabel disabled={parseInt(this.props.rent_customer_contacts_forms.no_coming) === 1} control={<Radio value={3} />} label="オンライン" />
                        </RadioGroup>

                        


                        <div style={{  }}>

                            {parseInt(this.props.rent_customer_contacts_forms.going) >= 1 ? <FormControl style={{  }} variant="outlined"  size="small">
                                <InputLabel>案内部署</InputLabel>
                                <MaterialUiSelect
                                    style={{ width: 100 }}
                                    name="going_section"
                                    value={this.props.rent_customer_contacts_forms.going_section}
                                    onChange={this.handleChange}
                                    label="案内部署"
                                >
                                    <MenuItem value={-1}>-</MenuItem>
                                    <MenuItem value={0}>自店</MenuItem>
                                    <MenuItem value={1}>他店</MenuItem>
                                    <MenuItem value={2}>他部署</MenuItem>
                                    <MenuItem value={3}>タクシー</MenuItem>
                                    <MenuItem value={4}>セルフ</MenuItem>
                                </MaterialUiSelect>
                            </FormControl>:""}

                            {parseInt(this.props.rent_customer_contacts_forms.going) >= 1 && !(this.props.rent_customer_contacts_forms.going_section == 3 || this.props.rent_customer_contacts_forms.going_section == 4) ? <FormControl style={{ width: "150px", marginLeft:4 }}>
                                {this.props.users_select.length > 0 ? <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.rent_customer_contacts_forms.going_section == 0 ? this.props.users.filter(a => a.amSectionId === this.props.user_details.amSectionId).map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll
                                    })) : this.props.users_select}
                                    getOptionLabel={(option) => option.label}
                                    // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
                                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                    style={{ width: 150 }}
                                    name="user_id"
                                    value={this.props.rent_customer_contacts_forms.going_user_id ? this.props.users_select.filter(a => a.value === this.props.rent_customer_contacts_forms.going_user_id)[0] : null}
                                    onChange={this.change_going_user_id}
                                    size='small'
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        {...params} label="案内担当を選択" />}

                                /> : ""}
                            </FormControl>:""}

                            {parseInt(this.props.rent_customer_contacts_forms.intend) === 0 && this.props.rent_customer_contacts_forms.going > 0 ? <TextField style={{width: 100, marginLeft: 5 }} size="small" label="案内時間" InputLabelProps={{ shrink: true, }} InputProps={{ endAdornment: <InputAdornment position="end" style={{ width: "30px" }}>分</InputAdornment>, }} onChange={this.handleChange} inputProps={{ type: 'number' }} value={this.props.rent_customer_contacts_forms.going_time} name="going_time" variant="outlined" />:""}
                        
                        </div>
                        <div style={{marginTop:10}}>
                            <FormLabel component="legend" fullWidth style={{ marginBottom: 0, fontSize: 15, marginTop:10 }}>成約</FormLabel>
                            <RadioGroup style={{ marginTop: 5, marginLeft: 10, float: "left" }} row aria-label="position" name="is_application" onChange={this.handleChange} value={parseInt(this.props.rent_customer_contacts_forms.is_application)} >
                                <FormControlLabel disabled={parseInt(this.props.rent_customer_contacts_forms.no_coming) === 1}  control={<Radio value={1} />} label="申込" />
                                <FormControlLabel disabled={parseInt(this.props.rent_customer_contacts_forms.no_coming) === 1}  control={<Radio value={2} />} label="未成約" />
                            </RadioGroup>
                            {parseInt(this.props.rent_customer_contacts_forms.intend) === 0 && parseInt(this.props.rent_customer_contacts_forms.is_application) == 2 ? <FormControl style={{ float: "left" }} variant="outlined" size="small">
                                <InputLabel>未成約理由</InputLabel>
                                <MaterialUiSelect
                                    style={{ width: 120 }}
                                    name="rent_m_going_reason_id"
                                    value={this.props.rent_customer_contacts_forms.rent_m_going_reason_id}
                                    onChange={this.handleChange}
                                    label="連帯保証人"

                                >
                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={1}>返事待ち</MenuItem>
                                    <MenuItem value={2}>再来予定</MenuItem>
                                    <MenuItem value={3}>時期</MenuItem>
                                    <MenuItem value={4}>他も見たい</MenuItem>
                                    <MenuItem value={5}>初期費用</MenuItem>
                                    <MenuItem value={6}>家賃</MenuItem>
                                    <MenuItem value={7}>相談する</MenuItem>
                                    <MenuItem value={8}>他社に行く</MenuItem>
                                    <MenuItem value={9}>物件が不満</MenuItem>
                                    <MenuItem value={10}>その他</MenuItem>
                                </MaterialUiSelect>
                            </FormControl> : ""}
                        </div>
                        <div style={{ clear:"both" }}>
                        </div>
                        <div>
                            {/* <Button style={{ margin: 2 }} variant="contained" color="primary" onClick={() => this.props.set_visit_edit({ disp: true, id: this.props.rent_customer_contacts_forms.id, rent_response_id: this.props.rent_customer_contacts_forms.rent_response_id, date: this.props.rent_customer_contacts_forms.date, user_id: this.props.rent_customer_contacts_forms.user_id })}><i class="fa fa-search "></i> 案内物件</Button> */}
                            <Button style={{ margin: 2 }} variant="contained" color="primary" onClick={() => this.togglesearch()}><i class="fa fa-search "></i> 案内物件</Button>
                            <Button style={{ margin: 2 }} variant="contained" color="primary" onClick={() => this.togglemap()}><i class="fa fa-search "></i> 地図検索</Button>
                            {this.props.rent_visits.length ? <Button style={{ margin: 2 }} variant="outlined" color="secondary" onClick={this.visit_card}> 案内用台帳印刷</Button> : ""}
                            {this.props.rent_visits.length ? <IconButton
                                aria-label="close"
                                onClick={() => this.props.change_manual_disp(true)}
                                size="large"><HelpOutlineIcon />  </IconButton> : ""}
                            {this.props.rent_visits.length ? <VisitList rent_visits={this.props.rent_visits} /> : ""}
                        </div>
                    </Grid> : ""}

                    <Grid justifyContent="space-between" item xs={12}>
                        <TextField
                            id="outlined-basic" 
                            onChange={this.handleChange} 
                            label="備考" 
                            multiline 
                            minRows={5} 
                            maxRows={100} 
                            InputLabelProps={{ shrink: true }} 
                            value={this.props.rent_customer_contacts_forms.remarks} 
                            name="remarks" variant="outlined" fullWidth />

                    </Grid>


                        </Grid>
                    </Grid>

                    {/* <Grid justify="space-between" item xs={4}>
                        <ContactsCalendarComponent />
                    </Grid> */}


                </Grid>
                </CardContent>
                </div>
                <CardContent style={{ padding: 10 }}>

                    {this.state.modaldelete ? (this.props.responsedetail.rent_m_way_id === 5 && this.props.customer_contacts.filter(a => a.way === 3).length === 1 && Number(this.props.rent_customer_contacts_forms.way) === 3 ?
                        <Alert severity="error">飛込では来店登録が1件必要なので削除できません。
                            <Button variant="contained" style={{ margin: 10 }} onClick={this.toggledelete}>閉じる</Button></Alert>
                        : <Alert severity="error">本当に削除しますか？
                            <Button variant="contained" color="secondary" style={{ margin: 10 }} onClick={()=>this.props.delete_rent_customer_contacts(this.props.rent_customer_contacts_forms.id)} disabled={this.props.customer_contacts_deleting}>
                                削除
                            </Button> <Button variant="contained" style={{ margin: 10 }} onClick={this.toggledelete}>閉じる</Button></Alert>) :

            // (Number(this.props.rent_customer_contacts_forms.way) === 3 && Number(this.props.rent_customer_contacts_forms.no_coming) !== 1 && !(this.props.rent_customer_contacts_forms.intend_time > 0 && this.props.rent_customer_contacts_forms.intend_time2 > 0)) ? <Alert severity="error">接客・案内時間を入力してください。</Alert> :
            (!(this.props.rent_customer_contacts_forms.user_id >0) ) ? <Alert severity="error">担当者を選択してください。</Alert> :
            (Number(this.props.rent_customer_contacts_forms.way) === 3 && Number(this.props.rent_customer_contacts_forms.no_coming) !== 1 && !(this.props.rent_customer_contacts_forms.start && this.props.rent_customer_contacts_forms.end)) ? <Alert severity="error">接客・案内時間を入力してください。</Alert> :
            (Number(this.props.rent_customer_contacts_forms.way) === 3 && Number(this.props.rent_customer_contacts_forms.no_coming) !== 1 && !(moment(this.props.rent_customer_contacts_forms.start) <= moment(this.props.rent_customer_contacts_forms.end))) ? <Alert severity="error">開始時間より終了時間があとになるように入力してください。</Alert> :
                (this.props.rent_customer_contacts_forms.going == null) ? <Alert severity="error">案内状況を選択してください。</Alert> :
                    (parseInt(this.props.rent_customer_contacts_forms.going) > 0 && this.props.rent_customer_contacts_forms.going_section == -1) ? <Alert severity="error">案内部署を選択してください。</Alert> :
                        (parseInt(this.props.rent_customer_contacts_forms.going) >= 1 && this.props.rent_visits.length === 0) ? <Alert severity="error">案内物件を入力してください。</Alert> :
                            (parseInt(this.props.rent_customer_contacts_forms.going) == 0 && parseInt(this.props.rent_customer_contacts_forms.intend) !== 1 && this.props.rent_visits.length > 0 && !this.props.rent_customer_contacts_forms.no_coming) ? <Alert severity="error">案内物件がある場合は、案内ありまたは現地案内を選択してください。</Alert> :
                                (parseInt(this.props.rent_customer_contacts_forms.going) >= 1 && parseInt(this.props.rent_customer_contacts_forms.intend) !== 1 && (this.props.rent_customer_contacts_forms.going_section == 0 || this.props.rent_customer_contacts_forms.going_section == 1 || this.props.rent_customer_contacts_forms.going_section == 2) && this.props.rent_customer_contacts_forms.going_user_id == 0) ? <Alert severity="error">案内担当を入力してください。</Alert> :
                                (parseInt(this.props.rent_customer_contacts_forms.going) >= 1 && parseInt(this.props.rent_customer_contacts_forms.intend) !== 1 && this.props.rent_customer_contacts_forms.going_time <= 0) ? <Alert severity="error">案内時間を入力してください。</Alert> :
                                    (Number(this.props.rent_customer_contacts_forms.way) === 3 && parseInt(this.props.rent_customer_contacts_forms.intend) !== 1 && !(parseInt(this.props.rent_customer_contacts_forms.is_application) > 0)) ? <Alert severity="error">成約状況（申込か未成約）入力してください。</Alert> :
                                        (parseInt(this.props.rent_customer_contacts_forms.intend) !== 1 && parseInt(this.props.rent_customer_contacts_forms.is_application) == 2 && !(parseInt(this.props.rent_customer_contacts_forms.rent_m_going_reason_id) > 0)) ? <Alert severity="error">未成約理由を入力してください。</Alert> :
                                            (parseInt(this.props.rent_customer_contacts_forms.going) >= 1 && parseInt(this.props.rent_customer_contacts_forms.intend) !== 1 && parseInt(this.props.rent_customer_contacts_forms.is_application) == 2 && parseInt(this.props.rent_customer_contacts_forms.rent_m_going_reason_id) == 10 && this.props.rent_customer_contacts_forms.remarks == "") ? <Alert severity="error">未成約理由を備考に入力してください。</Alert> : <span>
                                                {(Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : (this.props.rent_customer_contacts_forms.id ? <Button variant="contained" style={{ margin: 10, float: "right" }} color="secondary" onClick={this.toggledelete} disabled={this.props.customer_contacts_visit_deleting}>削除確認</Button> : "")}
                                                {(Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || 
                                                // Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || 
                                                Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || 
                                                (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || 
                                                Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || 
                                                // Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || 
                                                Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : <Button variant="contained" style={{ margin: 10, float: "right" }} color="primary" onClick={()=>this.props.add_rent_customer_contacts()} disabled={this.props.customer_contacts_saving}>保存</Button>}

                                            </span>}


                </CardContent>


                <TaskDialog open={this.props.visit_edit} onClose={() => this.props.set_visit_edit({ disp: false })} rent_customer_contacts_forms={this.props.rent_customer_contacts_forms} delete_rent_visits={this.props.delete_rent_visits} update_rent_visits={this.props.update_rent_visits} visit_editor_forms={this.props.visit_editor_forms} />


                <Dialog className="visit_search_dialog" open={this.state.modalmap} onClose={this.togglemap} maxWidth={false} fullWidth={true} fade={false}>
                    <DialogTitle onClose={this.togglemap}>地図検索</DialogTitle>
                    <DialogContent style={{padding:0}}>
                    <MapSearchComponent rent_response_id={this.props.responseid} responsedetail={this.props.responsedetail} selectsheet={this.selectsheet} />
                    </DialogContent>
                </Dialog>

                <Dialog className="visit_search_dialog" open={this.state.modalsearch} onClose={this.togglesearch} maxWidth={false} fullWidth={true} fade={false}>
                    <DialogTitle onClose={this.togglesearch}>物件検索{this.props.rent_customer_contacts_forms.rent_response_id}</DialogTitle>
                    {/* <ModalBody> */}

                    <RoomSearchListComponent selectTatemono={this.selectTatemono} rent_response_id={this.props.rent_customer_contacts_forms.rent_response_id} responsedetail={this.props.responsedetail} togglesearch={this.togglesearch} visit={true} />

                </Dialog>
            </Card>
        );
    }
}





const mapStateToProps = state => {
    return {
        // customerNewDispSet: state.customersReducer.customerNewDispSet,
        // customerlist: state.customersReducer.customerlist,
        // formvalue: state.form,
        users_select: state.masterReducer.users_select,
        users: state.masterReducer.users,
        user_details: state.masterReducer.user_details,


        visit_edit: state.customerContactsReducer.visit_edit,
        rent_visits: state.customerContactsReducer.rent_visits,
        responsedetail: state.responsesReducer.responsedetail,
        rent_customer_contacts: state.customerContactsReducer.rent_customer_contacts,
        rent_customer_contacts_forms: state.customerContactsReducer.rent_customer_contacts_forms,
        visit_editor_forms: state.customerContactsReducer.visit_editor_forms,


        customer_contacts: state.customerContactsReducer.customer_contacts,

        customer_contacts_visit_deleting: state.customerContactsReducer.customer_contacts_visit_deleting,
        customer_contacts_deleting: state.customerContactsReducer.customer_contacts_deleting,
        customer_contacts_saving: state.customerContactsReducer.customer_contacts_saving,

        draggable: state.masterReducer.draggable,
        responseid: state.responsesReducer.responseid,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        //customereditchange() {
        //    dispatch(customereditchange())
        //},
        change_visit_edit(state) {
            dispatch(CustomerContacts.change_visit_edit(state))
        },
        get_rent_visits(state) {
            dispatch(CustomerContacts.get_rent_visits(state))
        },
        set_visit_edit(state) {
            dispatch(CustomerContacts.set_visit_edit(state))
        },
        update_rent_visits(state) {
            dispatch(CustomerContacts.update_rent_visits(state))
        },
        delete_rent_visits(state) {
            dispatch(CustomerContacts.delete_rent_visits(state))
        },
        change_rent_customer_contacts(state) {
            dispatch(CustomerContacts.change_rent_customer_contacts(state))
        },
        change_rent_customer_contacts_forms(state) {
            dispatch(CustomerContacts.change_rent_customer_contacts_forms(state))
        },
        add_rent_customer_contacts(state) {
            dispatch(CustomerContacts.add_rent_customer_contacts(state))
        },
        delete_rent_customer_contacts(state) {
            dispatch(CustomerContacts.delete_rent_customer_contacts(state))
        },
        change_manual_disp(state) {
            dispatch(Help.change_manual_disp(state))
        },

        change_fromMapOpen(state) {
            dispatch(MapSearch.change_fromMapOpen(state))
        },
        change_draggable(state) {
            dispatch(Master.change_draggable(state))
        },
        change_rent_visits(state) {
            dispatch(CustomerContacts.change_rent_visits(state))
        },
    };
}



ContactEditComponent = connect(
    mapStateToProps, mapDispatchToProps
)(ContactEditComponent);

ContactEditComponent = reduxForm({
    form: 'contactform', // a unique identifier for this form
    validate//バリデーション

    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(ContactEditComponent)

export default ContactEditComponent

// export default reduxForm({
//     form: 'contactform', // a unique identifier for this form
//     validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ContactEditComponent)

// export default connect(mapStateToProps, mapDispatchToProps)(ContactEditComponent);