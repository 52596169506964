import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import './Css/Grid.css'

import { Table, Column, Cell } from 'fixed-data-table-2';

import 'fixed-data-table-2/dist/fixed-data-table.css';

import ChatIcon from '@mui/icons-material/Chat';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import * as Responses from '../ResponseDetail/Saga/Responses';

import withStyles from '@mui/styles/withStyles';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';


const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))

const hankyo = [
    { tenpo: "高松駅前店", tantou: "神原", hiduke: "2021-01-01", keiyakusya: "株式会社きんでん四国支社", okyakusama: "グローバル　太郎", keiro: "TEL", baitai: "紹介", jyoukyou: "成約済", },
    { tenpo: "高松中央公園店", tantou: "吉田", hiduke: "2021-01-01", keiyakusya: "グローバル二郎", okyakusama: "グローバル　太郎", keiro: "社宅斡旋", baitai: "リピート", jyoukyou: "追客終了", },
    { tenpo: "レインボーロード店", tantou: "景山", hidukke: "2021-01-01", keiyakusya: "株式会社きんでん四国支社", okyakusama: "グローバル　花子", keiro: "メール", baitai: "アパマン", jyoukyou: "来店済み", },
]
const raiten = [
    { tenpo: "高松駅前店", tantou: "神原", hiduke: "2021-01-01", keiyakusya: "株式会社きんでん四国支社", okyakusama: "グローバル　太郎", keiro: "TEL", baitai: "紹介", jyoukyou: "成約済", },
    { tenpo: "高松中央公園店", tantou: "吉田", hiduke: "2021-01-01", keiyakusya: "グローバル二郎", okyakusama: "グローバル　太郎", keiro: "社宅斡旋", baitai: "リピート", jyoukyou: "追客終了", },
    { tenpo: "レインボーロード店", tantou: "景山", hidukke: "2021-01-01", keiyakusya: "株式会社きんでん四国支社", okyakusama: "グローバル　花子", keiro: "メール", baitai: "アパマン", jyoukyou: "来店済み", },
]
const keiyaku = [
    { tenpo: "高松駅前店", tantou: "神原", hiduke: "2021-01-01", keiyakusya: "株式会社きんでん四国支社", okyakusama: "グローバル　太郎", bukken: "アレスト101", gousitu: "705", },
    { tenpo: "高松駅前店", tantou: "神原", hiduke: "2021-01-01", keiyakusya: "株式会社きんでん四国支社", okyakusama: "グローバル　太郎", bukken: "アレスト101", gousitu: "705", },
]
class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        this.responsedetail = this.responsedetail.bind(this);
        // this.props.get_m_corporations_list()
        this._rowClassNameGetterResponse = this._rowClassNameGetterResponse.bind(this);
        this._rowClassNameGetterComing = this._rowClassNameGetterComing.bind(this);
        this._rowClassNameGetterContract = this._rowClassNameGetterContract.bind(this);
        this.RowClickResponse = this.RowClickResponse.bind(this);
        this.RowClickComing = this.RowClickComing.bind(this);
        this.RowClickContract = this.RowClickContract.bind(this);
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }


    responsedetail(response_id) {
        this.props.change_modalResponseDetail(true)
        this.props.change_responseid(response_id)


    }


    RowClickResponse(event,index){
        window.open(`/customer/` + this.props.rent_responses_response_list[index].id, '_blank', 'noopener')
        // this.props.change_modalResponseDetail(true)
        // this.props.change_responseid(this.props.rent_responses_response_list[index].id)

    }
    RowClickComing(event,index){
        window.open(`/customer/` + this.props.rent_responses_coming_list[index].id, '_blank', 'noopener')
        // this.props.change_modalResponseDetail(true)
        // this.props.change_responseid(this.props.rent_responses_coming_list[index].id)

    }
    RowClickContract(event,index){
        window.open(`/customer/` + this.props.rent_responses_contract_list[index].id, '_blank', 'noopener')
        // this.props.change_modalResponseDetail(true)
        // this.props.change_responseid(this.props.rent_responses_contract_list[index].id)

    }

    _rowClassNameGetterResponse(index){
        
        if(this.props.rent_responses_response_list && this.props.rent_responses_response_list[index]) {
        //   return this.props.owner_eigyou[index].type == 1 ? "line" : 
        //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
        //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
            return "normal"
        }else{
          return ""
        }
    }
    _rowClassNameGetterComing(index){
        
        if(this.props.rent_responses_coming_list && this.props.rent_responses_coming_list[index]) {
            return "normal"
        }else{
          return ""
        }
    }
    _rowClassNameGetterContract(index){
        
        if(this.props.rent_responses_contract_list && this.props.rent_responses_contract_list[index]) {
            return "normal"
        }else{
          return ""
        }
    }

    render() {

        return (
            <div>
                <div style={{ float: "left", marginTop: 3 }} ><ChatIcon style={{ color: "#5cb85c" }}></ChatIcon> 反響履歴</div>
                <div style={{ float: "right" }}><Chip label={this.props.rent_responses_response_list.length + "件"} style={{ fontSize: 14 }} variant="outlined" /></div>
                <div style={{ clear: "both" }}></div>
                <Table 
                    className="gridCorporateNormalList"
                    rowHeight={34} 
                    rowsCount={this.props.rent_responses_response_list.length} 
                    height={200} 
                    headerHeight={30} 
                    width={(window.innerWidth / 2) - 75}
                    onRowClick={this.RowClickResponse}
                    rowClassNameGetter={this._rowClassNameGetterResponse}
                >
                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} aria-label="edit" size="small" onClick={() => this.responsedetail(this.props.rent_responses_response_list[rowIndex].id)}><EditIcon /></IconButton></Cell>)} width={40} /> */}
                    <Column header={<Cell>店舗</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.section_select && this.props.rent_responses_response_list[rowIndex].section_id ? this.props.section_select.filter((a) => a.value === this.props.rent_responses_response_list[rowIndex].section_id).length > 0 ? this.props.section_select.filter((a) => a.value === this.props.rent_responses_response_list[rowIndex].section_id)[0].label : "" : ""}</Cell>)} width={110} />
                    <Column header={<Cell>担当</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.users_select && this.props.rent_responses_response_list[rowIndex].user_id ? this.props.users_select.filter((a) => a.value === this.props.rent_responses_response_list[rowIndex].user_id).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_responses_response_list[rowIndex].user_id)[0].label : "" : ""}</Cell>)} width={90} />
                    <Column header={<Cell>日付</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.rent_responses_response_list[rowIndex].date).format("YYYY-MM-DD")}</Cell>)} width={90} />
                    <Column header={<Cell>契約者</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_response_list[rowIndex].name1}</Cell>)} width={180} />
                    <Column header={<Cell>お客様</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_response_list[rowIndex].name2}</Cell>)} width={180} />
                    <Column header={<Cell>反響経路</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_response_list[rowIndex].rent_m_way_name}</Cell>)} width={100} />
                    <Column header={<Cell>反響媒体</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_response_list[rowIndex].rent_m_medium_name}</Cell>)} width={100} />
                    <Column header={<Cell>状況</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_response_list[rowIndex].rent_contract_id > 0 ? (this.props.rent_responses_response_list[rowIndex].is_cancel ? "キャンセル" : "成約済み") : this.props.rent_responses_response_list[rowIndex].coming_response_count > 0 ? "来店済み" : this.props.rent_responses_response_list[rowIndex].end_response_date ? "追客終了" : ""}</Cell>)} width={100} />
                </Table>


                <div style={{ float: "left", marginTop: 15 }} ><ChatIcon style={{ color: "#5cb85c" }}></ChatIcon> 来店履歴</div>
                <div style={{ float: "right", marginTop: 15 }}><Chip label={this.props.rent_responses_coming_list.length + "件"} style={{ fontSize: 14 }} variant="outlined" /></div>
                <div style={{ clear: "both" }}></div>
                <Table 
                    className="gridCorporateNormalList"
                    rowHeight={34} 
                    rowsCount={this.props.rent_responses_coming_list.length} 
                    height={200} 
                    headerHeight={30} 
                    width={(window.innerWidth / 2) - 75}
                    onRowClick={this.RowClickComing}
                    rowClassNameGetter={this._rowClassNameGetterComing}
                >
                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} aria-label="edit" size="small" onClick={() => this.responsedetail(this.props.rent_responses_coming_list[rowIndex].id)}><EditIcon /></IconButton></Cell>)} width={40} /> */}
                    <Column header={<Cell>店舗</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.section_select && this.props.rent_responses_coming_list[rowIndex].section_id ? this.props.section_select.filter((a) => a.value === this.props.rent_responses_coming_list[rowIndex].section_id).length > 0 ? this.props.section_select.filter((a) => a.value === this.props.rent_responses_coming_list[rowIndex].section_id)[0].label : "" : ""}</Cell>)} width={110} />
                    <Column header={<Cell>担当</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.users_select && this.props.rent_responses_coming_list[rowIndex].user_id ? this.props.users_select.filter((a) => a.value === this.props.rent_responses_coming_list[rowIndex].user_id).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_responses_coming_list[rowIndex].user_id)[0].label : "" : ""}</Cell>)} width={90} />
                    <Column header={<Cell>日付</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.rent_responses_coming_list[rowIndex].date).format("YYYY-MM-DD")}</Cell>)} width={90} />
                    <Column header={<Cell>契約者</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_coming_list[rowIndex].name1}</Cell>)} width={180} />
                    <Column header={<Cell>お客様</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_coming_list[rowIndex].name2}</Cell>)} width={180} />
                    <Column header={<Cell>反響経路</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_coming_list[rowIndex].rent_m_way_name}</Cell>)} width={100} />
                    <Column header={<Cell>反響媒体</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_coming_list[rowIndex].rent_m_medium_name}</Cell>)} width={100} />
                    <Column header={<Cell>状況</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_coming_list[rowIndex].rent_contract_id > 0 ? "成約済み" : this.props.rent_responses_coming_list[rowIndex].coming_response_count > 0 ? "来店済み" : this.props.rent_responses_coming_list[rowIndex].end_response_date ? "追客終了" : ""}</Cell>)} width={100} />
                </Table>

                
                <div style={{ float: "left", marginTop: 15 }} ><ChatIcon style={{ color: "#5cb85c" }}></ChatIcon> 契約履歴</div>
                <div style={{ float: "right", marginTop: 15 }}><Chip label={this.props.rent_responses_contract_list.length + "件"} style={{ fontSize: 14 }} variant="outlined" /></div>
                <div style={{ clear: "both" }}></div>
                <Table 
                    className="gridCorporateNormalList"
                    rowHeight={34} 
                    rowsCount={this.props.rent_responses_contract_list.length} 
                    height={200} 
                    headerHeight={30} 
                    width={(window.innerWidth / 2) - 75}
                    onRowClick={this.RowClickContract}
                    rowClassNameGetter={this._rowClassNameGetterContract}
                >
                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} aria-label="edit" size="small" onClick={() => this.responsedetail(this.props.rent_responses_contract_list[rowIndex].id)}><EditIcon /></IconButton></Cell>)} width={40} /> */}
                    <Column header={<Cell>店舗</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.section_select && this.props.rent_responses_contract_list[rowIndex].section_id ? this.props.section_select.filter((a) => a.value === this.props.rent_responses_contract_list[rowIndex].section_id).length > 0 ? this.props.section_select.filter((a) => a.value === this.props.rent_responses_contract_list[rowIndex].section_id)[0].label : "" : ""}</Cell>)} width={110} />
                    <Column header={<Cell>担当</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.users_select && this.props.rent_responses_contract_list[rowIndex].user_id ? this.props.users_select.filter((a) => a.value === this.props.rent_responses_contract_list[rowIndex].user_id).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_responses_contract_list[rowIndex].user_id)[0].label : "" : ""}</Cell>)} width={90} />
                    <Column header={<Cell>日付</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.rent_responses_contract_list[rowIndex].date).format("YYYY-MM-DD")}</Cell>)} width={90} />
                    <Column header={<Cell>契約者</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_contract_list[rowIndex].name1}</Cell>)} width={180} />
                    <Column header={<Cell>お客様</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_contract_list[rowIndex].name2}</Cell>)} width={180} />
                    <Column header={<Cell>物件名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_contract_list[rowIndex].Tatemono_Name}</Cell>)} width={180} />
                    <Column header={<Cell>部屋名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_contract_list[rowIndex].Room_Name}</Cell>)} width={50} />
                </Table>

            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_responses_response_list: state.corporation.rent_responses_response_list,
        rent_responses_coming_list: state.corporation.rent_responses_coming_list,
        rent_responses_contract_list: state.corporation.rent_responses_contract_list,
        section_select: state.masterReducer.section_select,
        users_select: state.masterReducer.users_select,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_rent_responses_response_list(state) {
            dispatch(Corporation.get_rent_responses_response_list(state))
        },
        get_rent_responses_coming_list(state) {
            dispatch(Corporation.get_rent_responses_coming_list(state))
        },
        get_rent_responses_contract_list(state) {
            dispatch(Corporation.get_rent_responses_contract_list(state))
        },
        change_responseid(state) {
            dispatch(Responses.change_responseid(state))
        },
        change_modalResponseDetail(state) {
            dispatch(Responses.change_modalResponseDetail(state))
        },
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




