// import { connect } from 'react-redux';
// import Table from './ApplicationTable';
import React, { createContext, useContext, useState, useEffect, useCallback, useRef, createRef  } from "react"
// import { CSVLink, CSVDownload } from "react-csv";
import Button from '@mui/material/Button';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Grid from '@mui/material/Grid';
import moment from 'moment';
// import DateFnsUtils from '@date-io/date-fns';
import DateFnsUtils from '@mui/lab/AdapterDateFns';
import ja from "date-fns/locale/ja";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { Authentication } from '../../App'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { purple, red, grey } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ClearIcon from '@mui/icons-material/Clear';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
const authentication = require('react-azure-adb2c2').default;

const Circle  = styled.div`
display: flex;
align-items: center;
align-content: center;
flex-wrap: wrap;
background: #fbded8;
border-radius: 50%;
width: 34px;
height: 34px;
color: #96564a;
font-size: 14px;
text-align: center;
`;

const Circle1  = styled.div`
display: flex;
align-items: center;
align-content: center;
flex-wrap: wrap;
background: #c7d4e7;
border-radius: 50%;
width: 34px;
height: 34px;
color: #5c7194;
font-size: 14px;
text-align: center;
`;

const Circle2  = styled.div`
display: flex;
align-items: center;
align-content: center;
flex-wrap: wrap;
background: #dbe1d7;
border-radius: 50%;
width: 34px;
height: 34px;
color: #4b583c;
font-size: 14px;
text-align: center;
`;

const CircleJi  = styled.div`
display: block;
width: 100%;
font-size: 14px;
line-height: 1;
`;


export const ThemeContext = createContext();


const useStyles = makeStyles({
    contents: {
    //   display: "flex",
    //   alignItems: "center",
      justifyContent: "space-between",
    //   backgroundColor: "#ddd",
  
      "&:hover": {
        backgroundColor: "#eee",
  
        "& $icon": {
          display: "block",
          position: "absolute", 
          zIndex: 100, 
          bottom: 0, 
          right: 40
        },
      },
    },
  
    icon: {
      display: "none",
    },
  });

const url = process.env.REACT_APP_API_PM_URL
// const url = `http://localhost:5000`




export const MeetingsIndex= (props) => {

    const [myuserdetail, setMyUserDetail] = useState([]);//自分のユーザー情報
    const [userlist, setUserList] = useState([]);//ユーザーリスト

    const [meeting_category, setMeetingCategory] = useState([]);//meetingsのカテゴリ
    const [owner_id, setOwnerId] = useState([]);//owner_id
    const [eigyou_no, setEigyouNo] = useState([]);//eigyou_no
    const [meeting_detail, setMeetingDetail] = useState([]);//
    const [hs_meeting_id, setHsMeetingId] = useState(0);//
    const [reply_mode, setReplyMode] = useState(false);//
    const [comments, setComments] = useState("");
    const [edit_hs_meeting_detail_id, setEditMeetingDetailId] = useState(0);
    const [delete_modal, setDeleteModal] = useState(false);

    const token = authentication.getAccessToken();
    
      
    const getMyUserData = async () => {

        const f = await fetch(url + `/v1/users/detail`, { method: 'get', headers: { 'Authorization': 'Bearer ' + authentication.getAccessToken(), 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()
        setMyUserDetail(d)

        // if(d.am_section_id){
        //     getApplicationData(d.id, d.am_section_id)// 承認リスト
        // }
    }

    const getUsersListData = async () => {
        const f = await fetch(url + `/v1/users/list`, { method: 'get', headers: { 'Authorization': 'Bearer ' + authentication.getAccessToken(), 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()
        setUserList(d)
    }

    const getMeetingsData = async (category, owner_id, eigyou_no, hs_meeting_id) => {

        let search = {category:category , owner_id: owner_id, eigyou_no: eigyou_no, hs_meeting_id: hs_meeting_id}
        const f = await fetch(url+`/v1/hs/meeting/details/list`, { method: 'post', body: JSON.stringify(search), headers: { 'Authorization': 'Bearer ' + authentication.getAccessToken(), 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()
        setMeetingDetail(d)

        if(d.length > 0){
            setHsMeetingId(d[0].hs_meeting_id)


            const targetSection = document.getElementById('midoku');
            // 要素が存在する場合にのみスクロール操作を行う
            if (targetSection) {
                targetSection.scrollIntoView({
                    behavior: 'instant',
                    block: 'start',
                });
            }
        }else{
            setReplyMode(true)
        }


    }

    //コメント送信
    const handleComment = async() => {

        const values = {
            meeting_category:meeting_category, 
            owner_id:owner_id, 
            eigyou_no:eigyou_no, 
            hs_meeting_id:hs_meeting_id, 
            comment:comments
        }
// alert(hs_meeting_id)

        let api = ""
        if(meeting_category == 1){//オーナー
            api = `/v1/hs/meeting/details/insert`
        }else{//懸案
            api = `/v1/hs/meeting/details/insertkenan`
        }
        // alert(api)
        var f = await fetch(url+api, { method: 'post', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + authentication.getAccessToken(), 'Content-type': 'application/json; charset=UTF-8' } })
        var d = await f.json()

        // d.user_name_all = userlist.filter(a=>String(a.id) == String(d.user_id))[0].user_name_all
        setHsMeetingId(d.hs_meeting_id)
// console.log(meeting_detail)
        // todo をコピーして、新しいArrayインスタンスを作成する。
        const newMeetingDetail = [...meeting_detail];

        setMeetingDetail(newMeetingDetail.concat(d))
        // setDetail(d)

        setComments("")

    };

    //コメント修正
    const handleEditComment = async(index) => {

        // let meeting_detail_tmp = meeting_detail[index]

        const values = {
            meeting_category:meeting_category, 
            owner_id:owner_id, 
            eigyou_no:eigyou_no, 
            hs_meeting_id:hs_meeting_id, 
            hs_meeting_detail_id: edit_hs_meeting_detail_id,
            comment:comments,
            is_deleted: 0,
        }

        var f = await fetch(url+`/v1/hs/meeting/details/update/`+values.hs_meeting_detail_id, { method: 'put', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + authentication.getAccessToken(), 'Content-type': 'application/json; charset=UTF-8' } })
        var d = await f.json()

        let newMeetingDetail = [...meeting_detail];

        newMeetingDetail[index].is_updated = 1
        newMeetingDetail[index].remarks = comments

        setMeetingDetail(newMeetingDetail)
        setEditMeetingDetailId(0)
        setComments("")
        // if(newMeetingDetail.length>0){
        //     setReplyMode(false);
        // }
        


    };

    //コメント削除
    const handleDeleteComment = async(edit_hs_meeting_detail_id) => {

        const values = {
            meeting_category:meeting_category, 
            owner_id:owner_id, 
            eigyou_no:eigyou_no, 
            hs_meeting_id:hs_meeting_id, 
            hs_meeting_detail_id: edit_hs_meeting_detail_id,
            is_deleted: 1,
        }

        var f = await fetch(url+`/v1/hs/meeting/details/delete/`+values.hs_meeting_detail_id, { method: 'put', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + authentication.getAccessToken(), 'Content-type': 'application/json; charset=UTF-8' } })
        var d = await f.json()

        let newMeetingDetail = [...meeting_detail];

        // // newMeetingDetail[index].remarks = comments

        // setMeetingDetail(newMeetingDetail.filter(a=>a.hs_meeting_detail_id != edit_hs_meeting_detail_id))
        let idx = newMeetingDetail.findIndex(a=>a.hs_meeting_detail_id == edit_hs_meeting_detail_id)
        // let newMeetingDetail = [...meeting_detail];
        if(idx > -1){
            newMeetingDetail[idx].is_deleted = 1
        }

        setMeetingDetail(newMeetingDetail)
        setEditMeetingDetailId(0)
        setDeleteModal(false)
        setComments("")
        // alert(newMeetingDetail.filter(a=>a.hs_meeting_detail_id != edit_hs_meeting_detail_id).length)
        // if(newMeetingDetail.filter(a=>a.hs_meeting_detail_id != edit_hs_meeting_detail_id).length>0){//コメントが何もなくなったら、入力モード
        //     setReplyMode(false);
        // }else{
        //     setReplyMode(true);
        // }

    };

    //いいね
    const onClickConfirmers = async(index) => {

        // let owner_eigyou_detail = JSON.parse(JSON.stringify(state.owner.owner_eigyou_detail))
        let meeting_detail_tmp = meeting_detail[index]
    
        // いいね済み
        if (meeting_detail_tmp.confirmers && meeting_detail_tmp.confirmers.split(",").includes(String(myuserdetail.id))) {
            //削除
            meeting_detail_tmp.confirmers = meeting_detail_tmp.confirmers.split(",").filter(a => a != String(myuserdetail.id)).join(',')
    
        }else{
            let confirmers = meeting_detail_tmp.confirmers ? meeting_detail_tmp.confirmers.split(",") : []
            confirmers.push(myuserdetail.id)
            meeting_detail_tmp.confirmers = confirmers.join(',')
        }

        console.log("aaaaaa", meeting_detail_tmp)


        var f = await fetch(url+`/v1/hs/meeting/details/updateconfirmers/`+meeting_detail_tmp.hs_meeting_detail_id, { method: 'put', body: JSON.stringify(meeting_detail_tmp), headers: { 'Authorization': 'Bearer ' + authentication.getAccessToken(), 'Content-type': 'application/json; charset=UTF-8' } })
        var d = await f.json()



        let newMeetingDetail = [...meeting_detail];

        newMeetingDetail[index].confirmers = meeting_detail_tmp.confirmers

        setMeetingDetail(newMeetingDetail)

    }

    //修正
    const onClickEdit = async(id) => {
        // alert(id)
        setEditMeetingDetailId(id)
        setComments(meeting_detail.filter(a=>a.hs_meeting_detail_id == id)[0].remarks)
    }

    
    
    useEffect(() => {
        getMyUserData()// 自分の情報
        getUsersListData()// ユーザーリスト

        // setMeetingCategory(props.match.params.category)
        // setOwnerId(props.match.params.owner_id)
        // setEigyouNo(props.match.params.eigyou_no)

        // setMeetingCategory(1)
        // setOwnerId(10806)
        // setEigyouNo(18)
        // getMeetingsData(1, 10806, 18)

        setMeetingCategory(props.meeting_category)
        setOwnerId(props.owner_id)
        setEigyouNo(props.eigyou_no)
        setHsMeetingId(props.hs_meeting_id)
        getMeetingsData(props.meeting_category, props.owner_id, props.eigyou_no, props.hs_meeting_id)

        // onClickaaaa()

        // scrollBottomRef?.current?.scrollIntoView();

        // if(scrollBottomRef && scrollBottomRef.current) {
        // //         alert()
        //         // scrollBottomRef.current.scrollIntoView()
        // //     }
        // }
        // scrollToBottomOfList()

        

        

    }, [])
 
    useEffect(() => {

        // alert(props.match.params.id)
        // alert(props.owner_id)
        // if(props.meeting_details_disp){
        //     getMeetingsData(props.meeting_category, props.owner_id, props.eigyou_no)
        // }
        

    }, [props])

    // useEffect(() => {
    //     // alert(value)
    //     let list = []




    //     }
        
    // }, [value, application_list, start_date, end_date, form, approval_start_date, approval_end_date,title])

    // const onClickEdit = async(id) => {
    // const scrollToId5 = () => {
    //     alert()
    //     var container = document.querySelector('.example');
    //     var element = document.getElementById('5');
    //     container.scrollTop = element.offsetTop;
    // }

    
    
    // const onClickaaaa = ()=>{


    //     // setTimeout(() => {
    //         // window.scroll(0,600)
            
    //         var targetSection = document.getElementById("section");

            

    //         if(targetSection) {
    //             console.log(targetSection)
    //             alert()
    //             targetSection.scrollIntoView({
    //                 behavior: "smooth",
    //                 block: "start"
    //             });
    //         }
    //     // }, 1000)

        

    // }

    // const scrollToBottomOfList = useCallback(() => {

    //             // if(this.meeting_detail_div) {
    //     //     alert()
    //     //     this.meeting_detail_div.scrollIntoView()
    //     // }
        

    //     if(scrollBottomRef && scrollBottomRef.current) {
    //         scrollBottomRef.current.scrollIntoView({
    //               behavior: 'smooth',
    //               block: 'end',
    //             })
    //     }
    //     // ref!.current!.scrollIntoView({
    //     //   behavior: 'smooth',
    //     //   block: 'end',
    //     // })
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [ scrollBottomRef ])

    // const ScrollBottom = target => {
    //     if (target) {
    //       target.scrollIntoView({ behavior: "smooth", block: "end" });
    //     }
    //   };

    // const scrollToBottomOfList = ()=>{

    //     const target = document.getElementById("section");
    //     if (target) {
    //     target.addEventListener("transitionend", () => {
    //         ScrollBottom(target);
    //     });
    //     return () => {
    //         target.removeEventListener("transitionend", () => {
    //         ScrollBottom(target);
    //         });
    //     };
    //     }

    //         //             if(this.meeting_detail_div) {
    //         // alert()
    //         // this.meeting_detail_div.scrollIntoView()
    //     }
        
    //     var targetSection = document.getElementById("section");

            

    //         if(targetSection) {
    //             console.log(targetSection)
    //             alert(1)
    //             targetSection.scrollIntoView({
    //                 behavior: "smooth",
    //                 block: "start"
    //             });
    //         }
        // }


    const classes = useStyles();


    let midoku_disp=false

    return (
        <div className='meetings' style={{height:window.innerHeight-200-20, marginBottom:0, padding:0, position: 'relative', overflow: 'auto',border:"1px solid #d3d3d3"}}>
            <div className='example' style={{backgroundColor:"white"}}>
            {/* <button id="scrollButton" onClick={onClickaaaa}>セクションまでスクロール</button> */}
                {/* <button onClick={()=>scrollToId5()}>Scroll to ID 5</button> */}
                {/* <button onclick="scrollToId5()">Scroll to ID 5</button> */}
            {
                
                meeting_detail.map(function (value, key) {// 
                    return <>

                        {(() => {
                            if (value.notification_users && 
                                value.notification_users.split(",").includes(String(myuserdetail.id)) && 
                                (!value.read_users ||  !(value.read_users.split(",").includes(String(myuserdetail.id))))) {
                                if(!midoku_disp){
                                    midoku_disp = true
                                    return <div id="midoku" style={{width:"100%", borderBottom:"1px solid #d3d3d3", textAlign:"center", backgroundColor:"#c7c7c7", borderRadius:"10px"}}>ここから未読コメント</div>
                                }
                                
                            }

                        })()}
                    <div className={classes.contents} style={{display: "flex", padding:"10px 5px", width:"100%", borderBottom:"1px solid #d3d3d3", position: "relative", backgroundColor:value.is_deleted ? "#e0dcdc":"" }}>
                                
                                
                                <div style={{padding:2, width:"50px"}}>
                                    {value.user_id%3 == 0 ? <Circle><CircleJi>{value.user_name_all.split(' ').length > 1 ? value.user_name_all.split(' ')[0].substr(0, 1) + value.user_name_all.split(' ')[1].substr(0, 1) : value.user_name_all.substr(0, 2) }</CircleJi></Circle>:
                                    value.user_id%3 == 1 ? <Circle1><CircleJi>{value.user_name_all.split(' ').length > 1 ? value.user_name_all.split(' ')[0].substr(0, 1) + value.user_name_all.split(' ')[1].substr(0, 1) : value.user_name_all.substr(0, 2) }</CircleJi></Circle1>: 
                                    <Circle2><CircleJi>{value.user_name_all.split(' ').length > 1 ? value.user_name_all.split(' ')[0].substr(0, 1) + value.user_name_all.split(' ')[1].substr(0, 1) : value.user_name_all.substr(0, 2) }</CircleJi></Circle2>}
                                </div>
                                <div style={{padding:5, width:"100%", fontSize:12, position:  "relative"}}>
                                    <div style={{display: "flex", width:"100%"}}>
                                        <div style={{width:"50%", fontWeight:"bold", fontSize: "12px"}}>
                                            {/* {key + 1}: */}
                                            {value.user_name_all}
                                        </div>
                                        <div style={{width:"50%", textAlign:"right"}}>
                                            {value.is_deleted ? <span style={{fontStyle:"italic", fontFamily: "Times New Roman, 'ＭＳ Ｐゴシック'"}}>{"削除済み　"}</span> : value.is_updated ? "編集済み ": ""}{value.created_at ? moment(value.created_at).format("YYYY") == moment().format("YYYY") ? moment(value.created_at).format("MM/DD HH:mm") : moment(value.created_at).format("YYYY/MM/DD HH:mm") : ""}{}
                                        </div>
                                    </div>
                                    {value.is_deleted ? "":<div style={{display: "flex"}}><div style={{fontSize:"14px", width:"calc(100% - 20px)"}}>

                                        {edit_hs_meeting_detail_id == value.hs_meeting_detail_id && !delete_modal ?
                                        <>
                                            <TextField
                                                id="outlined-multiline-flexible"
                                                label="コメント"
                                                multiline
                                                // rows={5}
                                                maxRows={30}
                                                name="comments"
                                                onChange={(e)=>setComments(e.target.value)}
                                                value={comments}
                                                variant="outlined"
                                                fullWidth
                                                size='small'
                                                style={{marginTop:5}}
                                            />
                                            {/* <Fab disabled={comments == ""} style={{marginTop:3}} onClick={(e) => {handleEditComment(key)}} variant="extended" size="small" color='primary' aria-label="add">
                                                <CheckIcon sx={{ mr: 1 }} />
                                                完了
                                            </Fab> */}
                                            <Chip onClick={()=>handleEditComment(key)} style={{cursor: "pointer", marginTop:3}} avatar={<CheckIcon />} label={"完了"} size="small" />
                                            <Chip onClick={()=>{setEditMeetingDetailId(0);setComments("")}} style={{cursor: "pointer", marginTop:3, marginLeft:5}} avatar={<ClearIcon />} label={"キャンセル"} size="small" />
                                            {/* <IconButton onClick={()=>setEditMeetingDetailId(0)} style={{marginLeft:5}}><ClearIcon /></IconButton> */}
                                        </>
                                        :
                                        <span dangerouslySetInnerHTML={{ __html: value.remarks.replace(/\r?\n/g, '<br/>') }} />
                                        }
                                        {/* {value.remarks} */}
                                        
                                    </div>
                                    <div style={{width:"20px", textAlign:"center"}}>{value.read_users ? 
                                        
                                            <Tooltip style={{fontSize: "12px", color:"black", backgroundColor: "white", border:"1px solid #6c757d", padding:"1px 3px", marginTop:"-5px"}} title=
                                                {value.notification_users ? value.notification_users.split(',').map(function (item, key) {
                                                    return (
                                                        item != "" ? 
                                                    <div>
                                                        <span style={{width:"30px"}}>{value.read_users.split(",").filter(a=>a == item).length > 0 ? <VisibilityIcon style={{fontSize:"14px", color:"white"}} />:"　 "}</span>
                                                        {userlist.filter(a=>String(a.id) == String(item)).length ? userlist.filter(a=>String(a.id) == String(item))[0].user_name_all : ""}
                                                    </div>
                                                    :
                                                    ""
                                                    )
                                                }, this) : ""
                                                }
                                                
                                            >
                                                <VisibilityIcon style={{fontSize:"14px", color:"grey"}} /> 
                                            </Tooltip>

                                        
                                        
                                        :
                                        <Tooltip style={{fontSize: "12px", color:"black", backgroundColor: "white", border:"1px solid #6c757d", padding:"1px 3px", marginTop:"-5px"}} title="送信済み">
                                        <TaskAltIcon style={{fontSize:"14px", color:"grey"}}/></Tooltip>}
                                    </div>
                                    </div>}
                                    {!value.is_deleted && value.confirmers && value.confirmers.split(",").length > 0 ? <div style={{width:"100%", marginTop:3}} >
                                        <Tooltip style={{fontSize: "12px", color:"black", backgroundColor: "white", border:"1px solid #6c757d", padding:"1px 3px", marginTop:"-5px"}} title=
                                            {value.confirmers ? value.confirmers.split(',').map(function (item, key) {
                                                return (
                                                    item != "" ? 
                                                <div>
                                                    {/* {this.props.users.filter(a=>a.id == parseInt(item))[0].userNameAll} */}
                                                    {userlist.filter(a=>String(a.id) == String(item)).length ? userlist.filter(a=>String(a.id) == String(item))[0].user_name_all : ""}
                                                    {/* {item} */}
                                                    {/* {console.log("aaa",userlist)} */}
                                                </div>
                                                :
                                                ""
                                                )
                                            }, this) : ""
                                            }
                                            
                                        >
                                        <Chip onClick={()=>onClickConfirmers(key)} style={{cursor: "pointer"}} avatar={<CheckCircleIcon style={{color:"#f50057"}} />} label={value.confirmers ? value.confirmers.split(",").length: ""} size="small" />
                                        </Tooltip>
                                    </div>: ""}

                                    
                                </div>
                                {!value.is_deleted ? <div className={classes.icon} >
                                    
                                        {value.confirmers && value.confirmers.split(",").length > 0 ? "": 
                                            <IconButton onClick={()=>onClickConfirmers(key)} style={{padding:3}} size="large"><CheckCircleIcon /></IconButton>
                                            // <Chip onClick={()=>onClickConfirmers(key)} style={{cursor: "pointer"}} avatar={<CheckCircleIcon />} label={value.confirmers ? value.confirmers.split(",").length: ""} size="small" />
                                        }
                                        
                                        {/* <Fab onClick={()=>this.onClickConfirmers()} variant="extended" size="small" style={{backgroundColor:"white", border:"grey"}} color="primary" aria-label="add">
                                            <CheckCircleIcon style={{color:value.confirmers ? "#f50057" : "grey"}} sx={{ mr: 1 }} /><span style={{color:"black"}}>
                                                {value.confirmers ? value.confirmers.split(",").length: ""}</span>
                                        </Fab> */}
                                        {value.user_id == myuserdetail.id ? <>
                                            <IconButton
                                                onClick={()=>onClickEdit(value.hs_meeting_detail_id)}
                                                style={{padding:3}}
                                                size="large"><EditIcon /></IconButton>
                                            <IconButton
                                                onClick={(e) => { setDeleteModal(true);setEditMeetingDetailId(value.hs_meeting_detail_id);setComments("") }}
                                                style={{padding:3}}
                                                size="large"><DeleteIcon /></IconButton>
                                        </>
                                        :""}
                                    </div>:""}
                            </div>


                    </>;
                            
                })
                
            }

                { !midoku_disp ? <div id="midoku"></div>:""}


                <div style={{padding:5, width:"100%"}}>
                    {!edit_hs_meeting_detail_id ? 
                        <>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="コメント"
                                multiline
                                // rows={5}
                                maxRows={30}
                                name="comments"
                                onChange={(e)=>setComments(e.target.value)}
                                value={comments}
                                variant="outlined"
                                fullWidth
                                size='small'
                            />
                            {/* <Fab disabled={comments == ""} style={{marginTop:3}} onClick={(e) => {handleComment()}} variant="extended" size="small" color='primary' aria-label="add">
                                <SendIcon sx={{ mr: 1 }} />
                                送信
                            </Fab> */}

                            <Chip style={{marginTop:3, cursor: "pointer"}} disabled={comments == ""} onClick={(e) => {handleComment()}} color='primary' label={"送信"} size="small" />
                        </>
                        :""
                        // <Fab onClick={(e) => {
                        //     setReplyMode(true);setEditMeetingDetailId(0);setComments("");
                        // }} variant="extended" size="small" style={{color:"grey"}} aria-label="add">
                        //     <ReplyIcon sx={{ mr: 1 }} />
                        //     返信
                        // </Fab>
                }
                </div>



                <Dialog onClose={(e) => { setDeleteModal(false) }} aria-labelledby="simple-dialog-title" open={delete_modal}>
                    <DialogContent>
                        <span>本当に削除してよろしいですか</span>
                    </DialogContent>
                    <DialogActions>
                            <div row>
                                <div style={{textAlign: 'right', marginBottom: 10}}>
                                    <Button variant="contained" style={{marginRight: 10, backgroundColor:"#f50057"}} onClick={() => handleDeleteComment(edit_hs_meeting_detail_id)} startIcon={<DeleteForeverIcon/>}>削除</Button>

                                    <Button variant="contained" style={{ marginLeft: 3, backgroundColor:"grey" }} onClick={(e) => { setDeleteModal(false);setEditMeetingDetailId(0) }} startIcon={<HighlightOffIcon/>}>閉じる</Button>
                                </div>
                            </div>
                    </DialogActions>
                </Dialog>
            </div>
            {/* <div id="section">
                <h2>スクロール先のセクション</h2>
            </div> */}
            {/* <div ref={scrollBottomRef}/> */}
            {/* <div ref={ div => { this.meeting_detail_div = div }} /> */}
            {/* <div id="bottom-of-list" ref={ref} /> */}
        </div>
    );
}

export  default MeetingsIndex;
