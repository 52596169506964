import React, { Component } from 'react';

import { connect } from 'react-redux';
import "../../Css/Mail.css";
import * as Mail from './Saga/Mail';


class MailAddressDispComponent extends Component {
  constructor(props) {
    super(props);
    
    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
      tomails: [],
      ccmails: [],
      mails: this.props.send_mail_details.toMailall!=null?this.props.send_mail_details.toMailall.split(','):[],
    };

// if(this.props.mails){
//     this.props.mails.push(this.props.mails)
//   }
  }




    render() {
      // console.log(this.props.mails)
        let address = "";
        if(this.props.send_mail_details.toMailall){
        for(var i = 0; i < this.props.send_mail_details.toMailall.split(',').length; i++){
          address += this.props.send_mail_details.toMailall.split(',')[i] + ";";
          if (i >= 1) {
            break;
          }
        }
      }

        if(address === "") address = "To";

        return (
          <div onClick={()=>this.props.change_send_edit_contents(0)} className="address">
            {address}
            {/* {this.props.send_to_addresses.map((item) => (
              <span>{item},</span>
            ))} */}
            {this.props.send_mail_details.toMailall?this.props.send_mail_details.toMailall.split(',').length > 2 ? <span className="hoka">他{this.props.send_mail_details.toMailall.split(',').length - 2}人</span> : "":""}

          </div>
    );

  }
}




//コンテナ
const mapStateToProps = state => {
    return {


        // send_to_addresses:state.mailReducer.send_to_addresses,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
      change_send_edit_contents(state) {
        dispatch(Mail.change_send_edit_contents(state))
    },
    };
    }


export default connect(mapStateToProps, mapDispatchToProps)(MailAddressDispComponent);
