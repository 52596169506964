import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as Responses from './Saga/ResponseList';
import '../../Css/Grid.css';
import LoadingComponent from '../Layout/LoadingComponent';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import MailDetail from "../Mail/MailDetailComponent";
import moment from 'moment';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './Css/Grid.css'

// const style = {
//     border: "solid #000",
//     height:"200"
// }
//<span type="button" className="btn btn-primary" target="_blank" href={`/tradedetails/` + props.row.id}>詳細</span>
const DetailLinkFormatter = ({ value, ...props }: any) => {

    if (props.row.flg === 3) {
        if (props.row.request === 1) {
            return "";
        } else {
            return <a role="button" rel="noreferrer noopener" className="btn btn-primary" target="_blank" href={`/customer/` + props.row.id} >詳細</a>;
        }
        // return "";
    } else {
        return value;
    }
    //return <ProgressBar now={value} label={`${value}%`} />;
};
const ColorFormatter = (value: any) => {
    return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>担当者</span> {value}</div >;

};
//OK
const DetailFormatter = ({ value, ...props }: any) => {

    if (props.row.id > 0) {
        const media = props.row.rent_m_medium_id === 1 ? <span className="badge badge-primary">アパマン</span>
            : props.row.rent_m_medium_id === 14 ? <span className="badge badge-success">スーモ</span>
                : props.row.rent_m_medium_id === 16 ? <span className="badge badge-warning">ホームズ</span>
                    : props.row.rent_m_medium_id === 21 ? <span className="badge badge-danger">アットホーム</span>
                        : props.row.rent_m_medium_id === 29 ? <span className="badge badge-light">すまいズ</span>
                            : props.row.rent_m_medium_id === 2 ? <span className="badge badge-info">CLASO.</span>
                                : ""

        const shop = props.row.section_id === 1 ? <span className="badge badge-success">番</span>
            : props.row.section_id === 2 ? <span className="badge badge-warning">レ</span>
                : props.row.section_id === 3 ? <span className="badge badge-danger">駅</span>
                    : props.row.section_id === 4 ? <span className="badge badge-dark">東</span>
                        : props.row.section_id === 6 ? <span className="badge badge-light">公</span>
                            : props.row.section_id === 17 ? <span className="badge badge-info">サ</span>
                                : props.row.section_id === 47 ? <span className="badge badge-secondary">通</span>
                                    : ""

        return <span>{shop} <span className="badge badge-warning">登録済み</span> {media}</span>
    } else {

        const media = props.row.rent_m_medium_id === 1 ? <span className="badge badge-primary">アパマン</span>
            : props.row.rent_m_medium_id === 14 ? <span className="badge badge-success">スーモ</span>
                : props.row.rent_m_medium_id === 16 ? <span className="badge badge-warning">ホームズ</span>
                    : props.row.rent_m_medium_id === 21 ? <span className="badge badge-danger">アットホーム</span>
                        : props.row.rent_m_medium_id === 29 ? <span className="badge badge-light">すまいズ</span>
                            : props.row.rent_m_medium_id === 2 ? <span className="badge badge-info">CLASO.</span>
                                : ""






        const shop = props.row.section_id === 1 ? <span className="badge badge-success">番</span>
            : props.row.section_id === 2 ? <span className="badge badge-warning">レ</span>
                : props.row.section_id === 3 ? <span className="badge badge-danger">駅</span>
                    : props.row.section_id === 4 ? <span className="badge badge-dark">東</span>
                        : props.row.section_id === 6 ? <span className="badge badge-light">公</span>
                            : props.row.section_id === 17 ? <span className="badge badge-info">サ</span>
                                : props.row.section_id === 47 ? <span className="badge badge-secondary">通</span>
                                    : ""


        return <span>{shop} {media}</span>
    }
};

//OK
const CheckFormatter = ({ value, ...props }: any) => {

    return value === true ? <i className="fas fa-check" style={{ color: "green" }}></i> : ""

};

//OK
const DateFormatter = ({ value, ...props }: any) => {
    const time = value ? moment(new Date(value).setTime(new Date(value).getTime() - 1000 * 60 * 60 * 9)) : ""
    return time ? time.format("YYYY年MM月DD日 H:mm") : ""
    // return value === true ? <i className="fas fa-check" style={{ color: "green" }}></i> : ""

};


const RowRenderer = ({ renderBaseRow, ...props }: any) => {

    //const color = props.idx % 2 ? "green" : "blue";
    // const border = props.idx % 3 === 2 ? "border-bottoms" : "";
    let backgroundColor = props.row.id > 0 ? "row-highlight-green" : "";
    backgroundColor = props.row.selected === true ? "row-highlight-pink" : backgroundColor;


    return <span className={backgroundColor}>{renderBaseRow(props)}</span>;

    // const backgroundColor = props.row.request===1? border+" row-highlight-pink" : border+"";

    //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
    // return <span className={border}>{renderBaseRow(props)}</span>;



};
interface Props {
    row: any,
    mail_id: number,
    response_matching_list_loading: number,
    response_matching_list: any
    get_response_matching_list: any,
    selectedrow: any,
    select_response_matching: any,
    delete_mail: any,
    get_response_matching_list_success: any,
}

interface State {
    modalmail: boolean,
    modalselect: boolean,
    mailid: number,
    modalMatching: boolean,
    mailMatchingId: number,
    refresh: boolean,
    modalMailDelete: boolean,
    columns: any,
    colSortDirs: any,

    // customerdetail:any,

    // responsedetail:any,
    // state types
}

// class checkBoxFormatter extends Component<Props, State>{
//     constructor(props: Readonly<Props>) {
//         super(props);

//     }
//     render() {

//         console.log(this.props.row.id)
//         return (this.props.row.flg === 2 ?
//             <div>
//                 <input type="checkbox" id={"scales" + this.props.row.id} className="react-grid-checkbox" name={"scales" + this.props.row.id} />
//                 <label htmlFor={"scales" + this.props.row.id} className="react-grid-checkbox-label">
//                 </label>
//             </div> : ""
//         )
//     }
// }


//   const deleteFormatter = ({ value, ...props }:any) => {

//       return (props.row.mailAllReceiveId>0) ? "" :<div style={{background: '#CCC'}}>　</div>;


//   };

const columns2 = [
    { key: 'selected', name: '', width: 40, resizable: true, formatter: CheckFormatter },
    { key: 'media', name: '内容', resizable: true, width: 130, formatter: DetailFormatter },
    { key: 'date', name: '反響日時', width: 150, resizable: true, formatter: DateFormatter },
    // { key: 'mainmediaName', name: 'メディア',  width: 100, resizable: true},
    //{ key: 'col2', name: 'タイトル', width: 300, resizable: true, headerRenderer: <CustomHeader /> },
    { key: 'name', name: 'お客様名', width: 120, resizable: true },
    { key: 'tel1', name: '連絡先', width: 100, resizable: true },
    { key: 'email1', name: 'メール', width: 150, resizable: true },
    { key: 'building_name', name: '反響内容', width: 100, resizable: true },
    { key: 'detail', name: '詳細', width: 100, resizable: true },
    { key: 'delete', name: '削除', width: 60, resizable: true },
    // { key: 'col5', name: '備考', resizable: true, formatter: DetailLinkFormatter},
];

const columns = [
    { key: 'selected', name: '', width: 40 },
    { key: 'media', name: '内容', width: 130 },
    { key: 'date', name: '反響日時', width: 150},
    { key: 'name', name: 'お客様名', width: 120},
    { key: 'tel1', name: '連絡先', width: 100 },
    { key: 'email1', name: 'メール', width: 150 },
    { key: 'email2', name: 'メール', width: 150 },
    { key: 'building_name', name: '反響内容', width: 100 },
    { key: 'detail', name: '詳細', width: 100 },
    { key: 'delete', name: '削除', width: 60 },
];



var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
  };
  
  const Base = ({ column, props, rowIndex }: any) => 
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.response_matching_list[rowIndex][column.key] ? props.response_matching_list[rowIndex][column.key] : ""}
  </Cell>;

const ResponseDate = ({ column, props, rowIndex }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.response_matching_list[rowIndex][column.key] ? moment(props.response_matching_list[rowIndex][column.key]).utc().format("YYYY年MM月DD日 H:mm") : ""}
</Cell>;

const Check = ({ column, props, rowIndex, onChangeCheck }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
    <FormControlLabel checked={props.response_matching_list[rowIndex][column.key] == true ? true: false} onChange={(e)=>onChangeCheck(e, rowIndex)} control={<Checkbox name="checkedC" style={{marginLeft:2, marginTop:-5}} />} label="" />
</Cell>;

const Media = ({ column, props, rowIndex }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
    {
        (() => {
            if (props.response_matching_list[rowIndex].id > 0) {
                const media = props.response_matching_list[rowIndex].rent_m_medium_id === 1 ? <span className="badge badge-primary">アパマン</span>
                    : props.response_matching_list[rowIndex].rent_m_medium_id === 14 ? <span className="badge badge-success">スーモ</span>
                        : props.response_matching_list[rowIndex].rent_m_medium_id === 16 ? <span className="badge badge-warning">ホームズ</span>
                            : props.response_matching_list[rowIndex].rent_m_medium_id === 21 ? <span className="badge badge-danger">アットホーム</span>
                                : props.response_matching_list[rowIndex].rent_m_medium_id === 29 ? <span className="badge badge-light">すまいズ</span>
                                    : props.response_matching_list[rowIndex].rent_m_medium_id === 2 ? <span className="badge badge-info">CLASO.</span>
                                        : ""
                const shop = props.response_matching_list[rowIndex].section_id === 1 ? <span className="badge badge-success">番</span>
                    : props.response_matching_list[rowIndex].section_id === 2 ? <span className="badge badge-warning">レ</span>
                        : props.response_matching_list[rowIndex].section_id === 3 ? <span className="badge badge-danger">駅</span>
                            : props.response_matching_list[rowIndex].section_id === 4 ? <span className="badge badge-dark">東</span>
                                : props.response_matching_list[rowIndex].section_id === 6 ? <span className="badge badge-light">公</span>
                                    : props.response_matching_list[rowIndex].section_id === 17 ? <span className="badge badge-info">サ</span>
                                        : props.response_matching_list[rowIndex].section_id === 47 ? <span className="badge badge-secondary">通</span>
                                            : ""
                

                return <span>{shop} <span className="badge badge-warning">登録済み</span> {media}</span>

            } else {
                const media = props.response_matching_list[rowIndex].rent_m_medium_id === 1 ? <span className="badge badge-primary">アパマン</span>
                    : props.response_matching_list[rowIndex].rent_m_medium_id === 14 ? <span className="badge badge-success">スーモ</span>
                        : props.response_matching_list[rowIndex].rent_m_medium_id === 16 ? <span className="badge badge-warning">ホームズ</span>
                            : props.response_matching_list[rowIndex].rent_m_medium_id === 21 ? <span className="badge badge-danger">アットホーム</span>
                                : props.response_matching_list[rowIndex].rent_m_medium_id === 29 ? <span className="badge badge-light">すまいズ</span>
                                    : props.response_matching_list[rowIndex].rent_m_medium_id === 2 ? <span className="badge badge-info">CLASO.</span>
                                        : ""

                const shop = props.response_matching_list[rowIndex].section_id === 1 ? <span className="badge badge-success">番</span>
                    : props.response_matching_list[rowIndex].section_id === 2 ? <span className="badge badge-warning">レ</span>
                        : props.response_matching_list[rowIndex].section_id === 3 ? <span className="badge badge-danger">駅</span>
                            : props.response_matching_list[rowIndex].section_id === 4 ? <span className="badge badge-dark">東</span>
                                : props.response_matching_list[rowIndex].section_id === 6 ? <span className="badge badge-light">公</span>
                                    : props.response_matching_list[rowIndex].section_id === 17 ? <span className="badge badge-info">サ</span>
                                        : props.response_matching_list[rowIndex].section_id === 47 ? <span className="badge badge-secondary">通</span>
                                            : ""


                return <span>{shop} {media}</span>
            }
        })()
    }
</Cell>;



const Detail = ({ column, props, rowIndex, togglemail, openHankyo }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
    {
        (() => {
            if (props.response_matching_list[rowIndex].mail_all_receive_id  > 0) {

                return <Button style={{backgroundColor:"#28a745", color:"white"}} variant="contained" size="small" onClick={(e)=>togglemail(props.response_matching_list[rowIndex].mail_all_receive_id)}>メール詳細</Button>
            } else {
                return <Button variant="contained" color='primary' size="small" onClick={(e)=>openHankyo(props.response_matching_list[rowIndex].id)} >反響詳細</Button>
            }
        })()
    }
</Cell>;

const Delete = ({ column, props, rowIndex, togglemodalMailDeleteID }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
    {
        (() => {
            if (props.response_matching_list[rowIndex].mail_all_receive_id  > 0) {

                return (
                    <IconButton
                        onClick={(e)=>togglemodalMailDeleteID(props.response_matching_list[rowIndex].mail_all_receive_id)}
                        size="large"><ClearIcon /></IconButton>
                );
            } else {
                return ""
            }
        })()
    }
</Cell>;

class ResponseMatchingGridComponent extends Component<Props, State>{
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            // height: window.innerHeight,
            modalselect: false,
            mailid: this.props.mail_id,
            modalMatching: false,
            mailMatchingId: 0,
            modalmail: false,
            refresh: false,
            modalMailDelete: false,
            columns: columns,
            colSortDirs: {},
        };
        // this.onScroll = this.onScroll.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        this.toggleMatching = this.toggleMatching.bind(this);
        this.togglemail = this.togglemail.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getRowCount = this.getRowCount.bind(this);
        this.togglemodalMailDelete = this.togglemodalMailDelete.bind(this);
        this.deleteMail = this.deleteMail.bind(this);

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this._onSortChange = this._onSortChange.bind(this);
        this.onChangeCheck = this.onChangeCheck.bind(this);
        this.togglemodalMailDeleteID = this.togglemodalMailDeleteID.bind(this);
        // this.RowClick = this.RowClick.bind(this);
        // this._rowClassNameGetter = this._rowClassNameGetter.bind(this);


        // alert(this.props.mail_id)
    }


    onRowClick = (rowIdx: any, row: any) => {

        if (row) {
            this.props.response_matching_list.map((a: any) => a.selected = false)
            // row.selected = true;



            this.props.selectedrow(row.id, row.mail_all_receive_id)
            console.log(this.props.response_matching_list)
            let w = this.props.response_matching_list.map((q: any) => {
                if (q.id > 0 && q.id === row.id) return { ...q, selected: true };
                else if (q.mail_all_receive_id > 0 && q.mail_all_receive_id === row.mail_all_receive_id) return { ...q, selected: true };
                return { ...q, selected: false };
            })

            this.props.get_response_matching_list_success(w)


            // this.refresh()
        }
        //    console.log()
        //    this.props.selectedrow(row.mailAllReceiveId)
        //    this.props.selectedrow(row.id)
        // this.forceUpdate()

    }

    togglemail = (id: number) => {

        this.setState(prevState => ({
            modalmail: !prevState.modalmail,
            mailid: id
        }));
    }
    toggleMatching = (id: number) => {

        this.setState(prevState => ({
            modalMatching: !prevState.modalMatching,
            mailMatchingId: id
        }));
    }

    togglemodalMailDelete = () => {

        this.setState(prevState => ({
            modalMailDelete: !prevState.modalMailDelete,
        }));
        
    }

    togglemodalMailDeleteID = (id: any) => {
        this.setState(prevState => ({
            modalMailDelete: !prevState.modalMailDelete,
        }));

        if(id > 0){
            this.setState({
                mailid: id
            });
        }else{

        }
        
    }


    deleteMail = () => {
// alert(this.state.mailid)
        this.props.delete_mail(this.state.mailid)
        this.togglemodalMailDelete()
    }


    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.response_matching_list_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", backgroundColor: "#ddd", height: 200, padding: "50px" }} > <h3>{message}</h3></div>

        );
    };


    getCellActions(column: any, row: any) {


        // const cellActions = {

        //   coming: comingActions
        // };
        if (row.mail_all_receive_id > 0 && column.key === "detail") {
            return [{
                icon: <button className="btn btn-sm btn-success" style={{ width: "80px" }} >メール詳細</button>,

                callback: () => {
                    this.togglemail(row.mail_all_receive_id)
                    // this.toggleMatching(row.id)

                }
            }
            ]


        } else if (column.key === "detail") {
            return [{
                icon: <button className="btn btn-sm btn-primary" style={{ width: "80px" }} >反響詳細</button>,

                callback: () => {
                    window.open(`/customer/` + row.id, '_blank', 'noopener')

                }
            }
            ]
        } else if (row.mail_all_receive_id > 0 && column.key === "delete") {
            return [{
                icon: <i className="fas fa-times" />,

                callback: () => {
                    this.togglemodalMailDelete()
                    this.setState({
                        mailid: row.mail_all_receive_id
                    });
                }
            }
            ]
        } else {
            return {}
        }

    }




    getRowCount() {

        let count = this.props.response_matching_list.length;

        if (this.state.refresh && count > 0) {
            count--; // hack for update data-grid
            this.setState({
                refresh: false
            });
        }

        return count;
    }

    refresh() {
        this.setState({
            refresh: true
        });
    }

    _onColumnResizeEndCallback(newColumnWidth:any, columnKey:any) {

        let columnsset = this.state.columns.map(function (value:any) {
          if (value.key === columnKey) {
            value.width = newColumnWidth
          }
          return value
        })
    
        this.setState({ columns: columnsset })
    
      }

    _onSortChange(columnKey: any, sortDir: any) {

        // alert(columnKey)
        // alert(_defaultSortIndexes.indexOf(columnKey))
        let response_matching_list = this.props.response_matching_list;
        response_matching_list.sort((indexA:any, indexB:any) => {

          var valueA = indexA[columnKey];
          var valueB = indexB[columnKey];
          var sortVal = 0;
          if (valueA > valueB) {
            sortVal = 1;
          }
          if (valueA < valueB) {
            sortVal = -1;
          }
    
          if (!valueA && !valueB) {
            sortVal = 0
          }
          if (!valueA) {
            sortVal = -1
          }
          if (!valueB) {
            sortVal = 1
          }
    
    
          if (sortVal !== 0 && sortDir === SortTypes.DESC) {
            sortVal = sortVal * -1;
          }
    
          return sortVal;
        });
        this.props.get_response_matching_list_success(response_matching_list)
        this.setState({
          // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
          colSortDirs: {
            [columnKey]: sortDir,
          },
        });
    }

    onChangeCheck(e:any, idx:any){

        let response_matching_list = this.props.response_matching_list.map((value: any, index:any) => {
            if(index == idx && e.target.checked == true){
                value.selected = true
                this.props.selectedrow(value.id, value.mail_all_receive_id)
            }else{
                value.selected = false
            }
            return value
        })
        this.props.get_response_matching_list_success(response_matching_list)

        if(e.target.checked == false){
            this.props.selectedrow("", "")
        }
        

        this.setState({
            refresh: !this.state.refresh
        });
    }

    openHankyo(id:any){
        window.open(`/customer/` + id, '_blank', 'noopener')
    }




    render() {

        return (
            <div>




            <Table
              rowHeight={40}
              rowsCount={this.props.response_matching_list.length}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
              touchScrollEnabled={true}
              width={window.innerWidth- 200}
              height={400}
              headerHeight={40}

              >

              {this.state.columns.map((value:any, i:any) => {
                return <Column
                  columnKey={value.key}
                  header={<Cell 
                    // onSortChange={this._onSortChange}
                    // sortDir={this.state.colSortDirs[value.key_name]}
                    // style={{ whiteSpace: "nowrap" }}
                    >{value.name}</Cell>}

                  // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                  cell={({ rowIndex, ...props }) => (


                    value.key === "disp_format" ? <Base column={value} props={this.props} rowIndex={rowIndex}></Base>
                    : value.key === "date" ? <ResponseDate column={value} props={this.props} rowIndex={rowIndex}></ResponseDate>
                    : value.key === "media" ? <Media column={value} props={this.props} rowIndex={rowIndex}></Media>
                    : value.key === "selected" ? <Check column={value} props={this.props} rowIndex={rowIndex} onChangeCheck={this.onChangeCheck} ></Check>
                    : value.key === "detail" ? <Detail column={value} props={this.props} rowIndex={rowIndex} togglemail={this.togglemail} openHankyo={this.openHankyo}></Detail>
                    : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} togglemodalMailDeleteID={this.togglemodalMailDeleteID}></Delete>
                      : <Base c column={value} props={this.props} rowIndex={rowIndex}></Base>

                  )}

                  width={value.width}
                  isResizable={true}
                //   fixed={value.frozen}

                />
              }, this.props)}


            </Table >


                <Modal isOpen={this.state.modalmail} toggle={() => this.togglemail(1)} size="lg" fade={false}>

                    <ModalHeader toggle={() => this.togglemail(1)}>メール詳細</ModalHeader>
                    <ModalBody>
                        <MailDetail toggle={1} mailid={this.state.mailid} />
                    </ModalBody>
                </Modal>


                <Modal isOpen={this.state.modalMailDelete} toggle={this.togglemodalMailDelete} size="sm" fade={false}>

                    <ModalHeader toggle={this.togglemodalMailDelete}>削除する</ModalHeader>
                    <ModalBody>
                        削除するとメールと反響を結びつけられなくなります。<br />
              よろしいですか？
               </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.deleteMail}>削除する</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.togglemodalMailDelete}>キャンセル</button>
                    </ModalFooter>
                </Modal>




            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        responselist: state.responsesReducer.responselist,
        responselist_loading: state.responsesReducer.responselist_loading,
        window_height: state.masterReducer.window_height,
        response_matching_list: state.responsesReducer.response_matching_list,
        response_matching_list_loading: state.responsesReducer.response_matching_list_loading
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_response_matching_list(state: any) {
            dispatch(Responses.get_response_matching_list(state))
        },
        select_response_matching(state: any) {
            dispatch(Responses.get_response_matching_list(state))
        },
        delete_mail(state: any) {
            dispatch(Responses.delete_mail(state))
        },
        get_response_matching_list_success(state: any) {
            dispatch(Responses.get_response_matching_list_success(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseMatchingGridComponent);