
const authentication = require('../../../react-azure-adb2c2').default;
export const CreateContractFile = (values: any) => {
    // const url = `http://localhost:5000/api/v1/contractsheet/CreateContractFile/`+values.id;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/api/v1/contractsheet/CreateContractFile/` + values.id;
    const token = authentication.getAccessToken();
    // //const search = values ? values : {};
    // // alert(JSON.stringify(values))
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => { console.log(payload); return ({ payload }) })
        .catch(error => { console.log(error); return ({ error }) });

}

export const ResendContract = (values: any) => {
    // const url = `http://localhost:5000/api/v1/contractsheet/ResendContract/`+values.id + '/A4';
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + "/api/v1/contractsheet/ResendContract/" + values.id + '/A4';
    const token = authentication.getAccessToken();
    // //const search = values ? values : {};
    // // alert(JSON.stringify(values))
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}
export const ConfirmationSendContracts = (values: any) => {
    // const url = `http://localhost:5000/api/v1/contractsheet/testConfirmationSendContracts`;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + "/api/v1/contractsheet/testConfirmationSendContracts";
    const token = authentication.getAccessToken();
    // //const search = values ? values : {};
    // // alert(JSON.stringify(values))
    var formData = new FormData();
    // formData.append("files", values.files[0]);
    console.log(values.files)
    for (let i = 0; i < values.files.length; i++) {
        var newFile = new File([values.files[i].file], values.files[i].file_name+'.pdf', {type: 'application/pdf'});
        formData.append('files', newFile);
        // formData.append('rent_contract_id', JSON.stringify(values.files[i].rent_contract_id));
        // formData.append('id', values.files[i].rent_contract_id);
    }
    formData.append('text', JSON.stringify(values.text));
    formData.append('user_id', values.user_id);
    formData.append('mail', JSON.stringify(values.mail));
    return fetch(url, {
        method: 'post',
        // body: JSON.stringify(values),
        body: formData,
        headers: { 'Authorization': 'Bearer ' + token  },
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}



export const check_contract = (values: any) => {
    // const url = `http://localhost:5000/api/v1/contractsheet/checkContract/`+values;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/api/v1/contractsheet/checkContract/` + values;
    const token = authentication.getAccessToken();
    // //const search = values ? values : {};
    // // alert(JSON.stringify(values))
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => { console.log(payload); return ({ payload }) })
        .catch(error => { console.log(error); return ({ error }) });
}
export const cancel_electronic_contract = (values: any) => {
    // const url = `http://localhost:5000/api/v1/contractsheet/CancelElectronicContract/`+values;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/api/v1/contractsheet/CancelElectronicContract/` + values;
    const token = authentication.getAccessToken();
    // //const search = values ? values : {};
    // // alert(JSON.stringify(values))
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => { console.log(payload); return ({ payload }) })
        .catch(error => { console.log(error); return ({ error }) });
}
export const check_electronic_contract = (values: any) => {
    // const url = `http://localhost:5000/api/v1/contractsheet/CheckElectronicContract/`+values;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/api/v1/contractsheet/CheckElectronicContract/` + values;
    const token = authentication.getAccessToken();
    // //const search = values ? values : {};
    // // alert(JSON.stringify(values))
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => { console.log(payload); return ({ payload }) })
        .catch(error => { console.log(error); return ({ error }) });
}

export const remind_electronic_contract = (values: any) => {
    // const url = `http://localhost:5000/api/v1/contractsheet/RemindElectronicContract/`+values;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/api/v1/contractsheet/RemindElectronicContract/` + values;
    const token = authentication.getAccessToken();
    // //const search = values ? values : {};
    // // alert(JSON.stringify(values))
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => { console.log(payload); return ({ payload }) })
        .catch(error => { console.log(error); return ({ error }) });
}
export const download = (values: any) => {
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/documents/download/` + values
    // const url = `http://localhost:5000/api/v1/rent/contract/documents/download/` + values
    // const url = `http://localhost:5000/api/v1/rent/contract/docs/download/7552`
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),
        headers: { 'Authorization': 'Bearer ' + token },
    }).then(response => response.blob())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));
};

export const settlement_lock = (values: any) => {
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/contractsheet/sLock/` + values.id + '/' + values.state
    // const url = `http://localhost:5000/api/v1/contractsheet/sLock/` + values.id + '/' + values.state
    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => { console.log(payload); return ({ payload }) })
        .catch(error => { console.log(error); return ({ error }) });
}